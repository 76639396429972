import { useTranslation } from "react-i18next";
import { DayOfWeek } from "../Calendar/types/enum";
import capitalize from "../../utils/capitalize";
import clsx from "clsx";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getCalendarColor } from "../Calendar/utils/calendarColors";

const weekOptions = [
  { value: 1, label: "" },
  { value: 2, label: "2nd" },
  { value: 3, label: "3rd" },
  { value: 4, label: "4th" },
];

interface PropType {
  form: any;
  setForm: (value: any) => void;
}

function WeeklyRecurrent(props: PropType) {
  const { t } = useTranslation();
  const { form, setForm } = props;

  function handleSelectDays(day: string) {
    const isExist = form?.days?.find((item: string) => item == day);
    if (!isExist) {
      const temp: string[] = form?.days?.length ? [...form?.days] : [];
      temp.push(day);
      setForm({ ...form, days: temp });
    } else {
      const updated = form?.days?.filter((item) => item != day);
      setForm({ ...form, days: updated });
    }
  }

  return (
    <div className="mt-2">
      <div className="text-sm">{t("select_days")}</div>

      <div className="flex gap-5 mt-1">
        {Object.entries(DayOfWeek)?.map(([key, value]) => {
          const isSelected = form?.days?.find((item) => item == key);
          return (
            <div
              key={key}
              onClick={() => handleSelectDays(key)}
              className={clsx(
                "flex text-sm items-center justify-center w-[5px] h-[5px] cursor-pointer rounded-full p-5 hover:border",
                isSelected ? "border text-white" : ""
              )}
              style={{
                backgroundColor:
                  isSelected && getCalendarColor(form?.type, "dark"),
              }}
            >
              {capitalize(key?.slice(0, 3))}
            </div>
          );
        })}
      </div>

      <div className="mt-1">
        <div className="text-sm"> {t("repeat_for")} </div>

        <FormControl>
          <RadioGroup
            value={form?.noOfWeeks}
            onChange={(event) =>
              setForm({ ...form, noOfWeeks: Number(event?.target?.value) })
            }
          >
            <div className="flex flex-wrap">
              {weekOptions.map((option: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          ".css-hyxlzm": {
                            color: getCalendarColor(form?.type, "dark"),
                          },
                        }}
                      />
                    }
                    label={
                      <div className="text-sm capitalize">
                        {t("every")} {option?.label} {t("week")}
                      </div>
                    }
                  />
                );
              })}
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default WeeklyRecurrent;
