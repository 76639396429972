import { Avatar, Box, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { emit, socketListen } from "../../../Socket";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../../Constant/Constant";
import close from '../../../Assets/Close.png'
export default function BlockedContactsDrawer({ setShowBlockedContacts }) {
    const { t } = useTranslation();
    const [blockedContacts, setBlockedContacts] = useState([]);
    const [loading,setLoading]=useState(false);
    const { userData, formattedContact } = useSelector((state: any) => state.user);
    useEffect(() => {
        setLoading(true)
        emit('getBlockedContact', { user_id: userData?._id });
        socketListen("message", (data: any) => {
            if (data.type === "getBlockedContact") {
                setLoading(false);
                setBlockedContacts(data?.msg?.data)
            }
        })
    }, [])
    const unBlockContact = (item: any) => {
        emit("unblockRoom", {
            roomId: userData?.blockedRooms.find((el: any) => el?.pid === item?.pid?._id)?.room_Id,
        });
        setShowBlockedContacts(false);
    }
    return (
        <div>
            <Box sx={{ width: 400 }} role="presentation" >
                <div className="h-[100vh] ">
                    <div className="flex justify-between pl-2 border-b py-3 bg-gray-100 sticky top-0">
                        <div
                            className="flex cursor-pointer"
                            onClick={() => setShowBlockedContacts(false)}
                        >
                            <div>
                                <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
                            </div>
                            <div className="">{t("back")}</div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-center mx-auto my-3">{t("blocked_contacts")}</div>
                        <div className="text-xs textGray px-3">{t("block_message-info")}</div>
                        <hr className="my-2" />
                        <div>
                            {
                            loading?
                            <div className="flex mx-auto w-full justify-center my-4">
                                <CircularProgress style={{height:28,width:28,color:"#33ccff"}}/>
                            </div>
                            :
                            blockedContacts?.length > 0 && (
                                blockedContacts.map((item: any) => {
                                    return (
                                        <div className="px-2 my-2">
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-2 px-2">
                                                    <div>
                                                        {item?.pid?.profile_img ? (
                                                            <img
                                                                src={DefaultImageUrl + item?.pid?.profile_img}
                                                                alt="profile"
                                                                className="h-[50px] w-[50px] rounded-full"
                                                            />
                                                        ) : (
                                                            <Avatar sx={{ height: 50, width: 50 }} />
                                                        )}
                                                    </div>

                                                    <div className="pl-3">
                                                        {item &&
                                                            formattedContact[item?.pid?._id] !== undefined
                                                            ? (
                                                                <div className="flex  gap-2">
                                                                    <div className="lightBlack">
                                                                        {formattedContact[item?.pid?._id]?.firstName}
                                                                    </div>
                                                                    <div className="lightBlack">
                                                                        {formattedContact[item?.pid?._id]?.lastName}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="lightBlack">
                                                                    {item?.pid?.phone}
                                                                </div>
                                                            )}
                                                        <div className="textGray text-sm">{item?.pid?.phone}</div>
                                                    </div>
                                                </div>
                                                <div onClick={() => { unBlockContact(item) }}>
                                                    <img className="h-6 cursor-pointer w-6" src={close} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}