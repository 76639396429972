import { gql } from "@apollo/client";

export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFields on appointmentResponse {
    _id
    type
    title
    parent_id
    participants {
      _id
      accepted
      role
      firstName
      lastName
      phone
      profile_img
      left_at
    }
    date
    startDate
    isAllDay
    endTime
    endDate
    roomId
    time
    recursive
    description
    approvalReminderTime {
      _id
      Count
      Unit
    }
    attachment {
      type
      url
      name
      thumbnail
      duration
      uploadedAt
      mimeType
    }
    location {
      _id
      address
      latitude
      longitude
      countryOffset
      mapUrl
    }
    startTimeInMs
    daylyParams {
      dayOfWeeks
      everyWeek
    }
    monthlyParams {
      months
      twicePerMonth
      onDay
      onWeek {
        dayOfWeeks
        everyWeek
      }
    }
    hasComon
    isConfirmationNeeded
    roomType
    isApprovalNeeded
    message {
      _id
      roomId
      type
      fileURL
      isForwarded
      message
      fontStyle
      thumbnail
      duration
    }
  }
`;

export const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFields on scheduleResponse {
    _id
    parent_id
    type
    roomId
    startDate
    endDate
    time
    message {
      _id
      roomId
      type
      fileURL
      isForwarded
      message
      fontStyle
      thumbnail
      duration
    }
    isApprovalNeeded
    approvalReminderTime {
      Count
      Unit
    }
    recursive
    daylyParams {
      dayOfWeeks
      everyWeek
    }
    monthlyParams {
      months
      onDay
      onWeek {
        dayOfWeeks
        everyWeek
      }
      twicePerMonth
    }
    roomType
  }
`;

export const REMINDER_FRAGMENT = gql`
  fragment reminderFeilds on reminderResponse {
    _id
    type
    title
    participants {
      _id
      accepted
      role
      firstName
      lastName
      phone
      profile_img
      left_at
    }
    date
    startDate
    isAllDay
    endTime
    endDate
    roomId
    time
    recursive
    description
    approvalReminderTime {
      _id
      Count
      Unit
    }
    startTimeInMs
    daylyParams {
      dayOfWeeks
      everyWeek
    }
    monthlyParams {
      months
      twicePerMonth
      onDay
      onWeek {
        dayOfWeeks
        everyWeek
      }
    }
    hasComon
    isConfirmationNeeded
    roomType
  }
`;

export const FOLDER_FRAGMENT = gql`
  fragment folderFields on userFolder {
    _id
    name
    parent
    userId
    subFolders {
      _id
      name
      createdAt
      updatedAt
    }
    records {
      _id
      title
      createdAt
      updatedAt
    }
    createdAt
  }
`;

export const RECORD_FRAGMENT = gql`
  fragment recordFields on Record {
    _id
    firstName
    lastName
    title
    landLine
    company
    address
    mobile
    email
    comment
    parent
    reminderId
    isReminder
    customFields
    userId
    createdAt
  }
`;

export const CHAT_FRAGMENT = gql`
  fragment chatFields on Conversation {
    _id
    roomId
    type
    sender
    duration
    message
    fileURL
    thumbnail
    reply_msg {
      cid
      type
      sender
      message
      file_URL
      fontStyle
      index
      created_at
    }
    read_by {
      user_id
      read_at
    }
    favourite_by {
      user_id
      favourite_at
    }
    deleted {
      type
      cause
      user_id
      deleted_at
    }
    downloadBy {
      user_id
      device_unique
    }
    isForwarded
    PinBy {
      user_id
      pin_at
    }
    fontStyle
    isSent
    delivered_to {
      user_id
      delivered_at
    }
    deliveredToIds
    readByIds
    created_at
    updated_at
    inviteStatus
    refId
    receipts
  }
`;
