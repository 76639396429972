import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import { setActiveCallData, updateCallStatus } from "../Redux/ChatSlice";
import { CALL_STATUS } from "../Graphql/Mutations";
import userAvtar from "../Assets/Images/userAvatar.png";
import AudioVideo from "../Pages/Call/AudioVideo";
import { Avatar, CircularProgress } from "@mui/material";
import { DefaultImageUrl } from "../Constant/Constant";
import receiveMsgSound from "../Assets/sound/incall.mp3";
import { useTranslation } from "react-i18next";
import Loading from "react-loading";
export default function ShowNotificationBar({
  time,
  setTime,
  setCallEnd,
  callEnd,
}) {
  const { callisRunning } = useSelector((state: any) => state.chat);
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const callTime = useStopwatch();
  const [toastShow, setToastShow] = useState(false);
  const [profileUrl, setProfileUrl] = useState("");
  const [loading, setloading] = useState(false);

  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );

  const {
    rooms,
    joinedFromOtherDevice,
    NotificationRequest,
    activeCallData,
    isCallMinimized,
  } = useSelector((state: any) => state.chat);

  const roomId = activeCallData?.data?.roomId?._id;
  const GroupName = rooms?.filter((item: any) => item?._id === roomId);
  useEffect(() => {
    callTime.reset();
    callTime.start();
  }, [activeCallData]);

  useEffect(() => {
    if (callTime.minutes == 1 && callTime.seconds === 30 && !callisRunning) {
      dispatch(setActiveCallData(""));
    }
    // eslint-disable-next-line
  }, [callTime]);

  const acceptOrRejectCall = (type: any) => {
    setloading(true);
    changeCallStatus({
      variables: {
        changeCallStatus: {
          callId: activeCallData.data?.callId,
          status: type,
          userId: userData._id,
        },
      },
    })
      .then((res) => {
        const response = res?.data?.changeCallStatus;

        callTime.pause();
        if (type == "rejected") {
          dispatch(setActiveCallData(null));
        } else {
          dispatch(updateCallStatus(true));
          dispatch(
            setActiveCallData({
              ...activeCallData,
              ...(response?.Screen_status == "start" && {
                isScreenShare: true,
                sharer_uid: response?.sharer_uid,
              }),
            })
          );
        }
      })
      .finally(() => setloading(false));
  };

  return (
    <>
      {callisRunning && (
        <div
          className={`absolute z-[999] top-0 bottom-0 w-full h-full ${
            callisRunning && !isCallMinimized ? "block" : "hidden"
          }`}
        >
          <AudioVideo
            setTime={setTime}
            callEnd={callEnd}
            setCallEnd={setCallEnd}
          />
        </div>
      )}

      {activeCallData?.data?.origin?._id != userData?._id && (
        <>
          {(callTime.minutes < 1 || callTime.seconds < 30) &&
            activeCallData?.callType === "CALL" &&
            !callisRunning &&
            !joinedFromOtherDevice && (
              <div className="fixed-bar justify-between flex">
                <audio src={receiveMsgSound} autoPlay loop></audio>
                <div>
                  <div className="flex">
                    <div>
                      <img
                        src={
                          NotificationRequest
                            ? userAvtar
                            : DefaultImageUrl +
                              activeCallData?.data?.origin?.profile_img
                        }
                        alt={t("profile")}
                        className="h-[45px] w-[45px] rounded-full"
                      />
                    </div>
                    <div className=" text-left pl-3">
                      <div className="text-black">
                        {activeCallData?.data?.roomType !== "group"
                          ? formattedContact[activeCallData?.data?.origin?._id]
                            ? formattedContact[
                                activeCallData?.data?.origin?._id
                              ]?.firstName +
                              " " +
                              formattedContact[
                                activeCallData?.data?.origin?._id
                              ]?.lastName
                            : activeCallData?.data?.origin?.phone
                          : GroupName[0]?.name}
                      </div>
                      <div className="text-black bold">
                        {`${t("incoming")} ${activeCallData?.data?.type} ${t(
                          "call"
                        )}.`}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <div className="flex items-center h-full">
                      <CircularProgress size={24} />
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn bg-green-500 rounded text-white p-2 mr-4 my-2"
                        onClick={() => {
                          setToastShow(false);
                          // dispatch(updateCallStatus(true));
                          acceptOrRejectCall("accepted");
                          // callTime.pause();
                        }}
                      >
                        {t("accept")}
                      </button>
                      <button
                        className="btn bg-red-500 text-white rounded p-2"
                        onClick={() => {
                          setToastShow(false);
                          acceptOrRejectCall("rejected");
                          // callTime.pause();
                        }}
                      >
                        {t("reject")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
}
