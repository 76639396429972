import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  ReminderParticipantRole,
} from "../../../types/enum";
import { FormPropsType } from "../../../types/types";
import { ChangeEvent } from "react";
import generateShortUUID from "../../../../../utils/generateShortUUID";
import sortByAlphabet from "../../../../../utils/sortByAlphabet";
import { getCalendarColor } from "../../../utils/calendarColors";

function SelectParticipants(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm, roomParticipants, errors, setErrors } = props;
  const { activeRoomData } = useSelector((state: any) => state?.chat);
  const { comonUsers, userData, blockMember, formattedContact, userContacts } =
    useSelector((state: any) => state?.user);

  function filterOptions(hasComon?: boolean) {
    const Ids =
      form?.participants?.length > 0
        ? form?.participants?.map((item) => item?._id)
        : [];

    const filtered = [];

    if (
      form?.type != EventTypeEnum.CALLREMINDER ||
      !form?.hasComon ||
      (hasComon && !hasComon)
    ) {
      if (!form?.roomId) {
        const sorted = sortByAlphabet(comonUsers, "firstName");
        sorted?.forEach((item) => {
          if (item?.userId?._id != userData?._id) {
            const contact = formattedContact?.[item?.userId?._id];
            const label = contact?.firstName
              ? contact?.firstName + " " + contact?.lastName
              : item?.phone;

            if (
              !Ids?.includes(item?.userId?._id) &&
              !blockMember?.includes(item?.userId?._id)
            ) {
              filtered.push({
                label: `${label} - (${item?.phone})`,
                id: item?.userId?._id,
                user: item,
              });
            }
          }
        });
      } else {
        const sorted = sortByAlphabet(roomParticipants, "firstName");
        sorted?.forEach((item) => {
          const contact = formattedContact?.[item?.user_id];
          const label = contact?.firstName
            ? contact?.firstName + " " + contact?.lastName
            : item?.phone;

          if (
            !Ids?.includes(item?.user_id) &&
            !blockMember?.includes(item?.user_id)
          ) {
            filtered.push({
              label: `${label} - (${item?.phone})`,
              id: item?.user_id,
              user: item,
            });
          }
        });
      }
    } else {
      const nonComon = userContacts?.length
        ? [...userContacts]?.filter((item) => !item?.userId && item)
        : [];
      const sorted = sortByAlphabet(nonComon, "firstName");

      sorted?.forEach((item) => {
        const label = item?.firstName + " " + item?.lastName;

        filtered.push({
          label: `${label} - (${item?.phone})`,
          id: generateShortUUID(),
          user: {
            firstName: item?.firstName,
            lastName: item?.lastName,
            phone: item?.phone,
            userId: {
              _id: generateShortUUID(),
            },
          },
        });
      });
    }

    return filtered;
  }

  function handleChange(user: any) {
    const newErrors = { ...errors };

    if (user) {
      const userId = !form?.hasComon
        ? form?.roomId
          ? user?.user_id
          : user?.userId?._id
        : user?.userId?._id;

      const userToAdd = {
        _id: userId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: ReminderParticipantRole.USER,
        accepted: !form?.hasComon
          ? ParticipantAcceptStatus.PENDING
          : ParticipantAcceptStatus.ACCEPT,
        phone: user?.phone,
      };

      if (!form?.hasComon) {
        const temp = [...form?.participants];
        temp.push(userToAdd);
        setForm({ ...form, participants: temp });
      } else {
        const updated = form?.participants?.some(
          (item) => item?.role == ReminderParticipantRole.USER
        )
          ? form?.participants?.map((item) =>
              item?.role == ReminderParticipantRole.USER ? userToAdd : item
            )
          : [...form?.participants, userToAdd];
        setForm({ ...form, participants: updated });
      }

      delete newErrors.participants;
      setErrors(newErrors);
    }
  }

  function handleCheckbox(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;
    filterOptions(checked);
    setForm({
      ...form,
      participants: [
        {
          _id: userData?._id,
          role: ReminderParticipantRole.ADMIN,
          accepted: ParticipantAcceptStatus.ACCEPT,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          phone: userData?.phone,
          profile_img: userData?.profile_img,
        },
      ],
      hasComon: checked,
      isConfirmationNeeded: checked ? null : form?.isConfirmationNeeded,
    });
  }

  return (
    <div className="mb-2">
      <Autocomplete
        disablePortal
        clearOnBlur
        options={filterOptions()}
        onChange={(ev, value) => handleChange(value?.user)}
        // renderOption={(props, option) => (
        //   <li key={option?.user?.phone} {...props}>
        //     {option?.label} -{" "}
        //     <span className="text-sm ml-1">
        //       ({option?.user?.userId?.phone || option?.user?.phone})
        //     </span>
        //   </li>
        // )}
        renderInput={(params) => (
          <TextField {...params} label={t("select_participants")} />
        )}
      />

      {form?.type == EventTypeEnum.CALLREMINDER && !activeRoomData?._id && (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={handleCheckbox}
              checked={Boolean(form?.hasComon)}
              sx={{
                ".MuiSvgIcon-root": {
                  color: getCalendarColor(form?.type, "dark"),
                },
              }}
            />
          }
          label={<div className="text-sm">{t("choose_non_comon_member")}</div>}
        />
      )}
    </div>
  );
}

export default SelectParticipants;
