import React, { useState } from "react";
import favourite from "../../Assets/Images/Actions.png";
import desktop from "../../Assets/Images/desktop.png";
import Account from "../../Assets/Images/Account.png";
import chat from "../../Assets/Images/chat.png";
import security from "../../Assets/Images/security.png";
import notification from "../../Assets/Images/notification.png";
import storage from "../../Assets/Images/storage.png";
import theme from "../../Assets/Images/theme.png";
import blocked from "../../Assets/Images/blocked.png";
import star from "../../Assets/Images/star.png";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavouriteMessage from "./FavouriteMessage";
import WebDesktop from "./WebDesktop";
import AccountSetting from "./AccountSetting";
import { useTranslation } from "react-i18next";

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

function SettingHome(props: SimpleDialogProps) {
  const [sideTab, setSideTab] = useState<Number>(1);
  const { onClose, open } = props;
  const {t}=useTranslation();
  const handleClose = () => {
    onClose("");
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <div className="flex border-b-2 sticky">
          <div className="py-2 pl-4 cursor-pointer" onClick={handleClose}>
            <CloseIcon />
          </div>
          <div className="text-center py-3 w-full font-medium">{t("settings")}</div>
        </div>

        <div className="flex">
          <div className="w-[35%] border-r-2">
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100 cursor-pointer"
              onClick={() => setSideTab(1)}
            >
              <div>
                <img src={favourite} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
               {t("favourite_message")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(2)}
            >
              <div>
                <img src={desktop} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("web_desktop")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(3)}
            >
              <div>
                <img src={Account} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("account_setting")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(4)}
            >
              <div>
                <img src={chat} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("chat_setting")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(5)}
            >
              <div>
                <img src={security} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("security_setting")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(6)}
            >
              <div>
                <img src={notification} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("notification")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(7)}
            >
              <div>
                <img src={storage} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("storage_data")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(8)}
            >
              <div>
                <img src={theme} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("theme")}
              </div>
            </div>
            <div
              className="flex py-3 border-b px-3 hover:bg-gray-100  cursor-pointer"
              onClick={() => setSideTab(9)}
            >
              <div>
                <img src={blocked} className="w-6" alt="" />
              </div>
              <div className="pl-2 text-[15px] lightBlack font-semibold">
                {t("blocked_contact")}
              </div>
            </div>
          </div>

          <div className="w-[65%]">
            {sideTab === 1 && <FavouriteMessage />}

            {sideTab === 2 && <WebDesktop />}

            {sideTab === 3 && <AccountSetting />}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default SettingHome;
