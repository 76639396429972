import { IOption, IPollDetailsProps, IPollForm } from "./polls";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Drawer, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../../Constant/Constant";

const PollDetails = ({ show, setShow, polls }: IPollDetailsProps) => {
  const { t } = useTranslation();
  const { userData, formattedContact } = useSelector(
    (state: any) => state?.user
  );
  const { activeRoomData } = useSelector((state: any) => state?.chat);

  const getVoterName = (id: string) => {
    const participant = activeRoomData?.participants?.find(
      (part) => part?.user_id == id
    );
    const self = participant?.user_id == userData?._id;
    const contactD = formattedContact?.[participant?.user_id];

    const name = self
      ? t("you")
      : contactD?.firstName
      ? contactD?.firstName + " " + contactD?.lastName
      : participant?.phone;

    return {
      name,
      profile: participant?.profile_img,
    };
  };

  const getOptionVotes = (options: Array<IOption>) => {
    const uniqueVotes = new Set();
    options?.forEach((option) => {
      option?.value?.forEach((id) => uniqueVotes.add(id));
    });
    return uniqueVotes?.size;
  };

  return (
    <Drawer
      open={show}
      anchor="right"
      sx={{ "& .MuiDrawer-paper": { width: 500 } }}
    >
      <div className="sticky top-0 z-10">
        <div className="flex w-full items-center justify-between cursor-pointer p-4 h-[60px] bg-[#F3F9FC]">
          <div className="font-bold capitalize">{t("polldetails")}</div>
          <div>
            <IconButton onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div
        style={{ scrollbarWidth: "thin" }}
        className="p-4 h-full overflow-auto bg-gray-100"
      >
        <div className="grid gap-5">
          {polls?.map((poll: IPollForm, index: number) => {
            return (
              <div key={index}>
                <div className="bg-white rounded-md p-4">
                  <div className="font-semibold text-xl">{poll?.title}</div>
                  <div className="text-gray-500 text-sm mt-1">
                    {getOptionVotes(poll?.options)}{" "}
                    {/* {poll?.options?.reduce(
                      (acc, option) => acc + option?.value?.length,
                      0
                    )}{" "} */}
                    {t("of")} {activeRoomData?.participants?.length}{" "}
                    {t("participantvoted")}
                  </div>
                </div>

                {poll?.options?.map((option: IOption, key: number) => (
                  <div
                    key={key}
                    className="p-2 bg-white border-t border-gray-300"
                  >
                    <div className="bg-white rounded-md p-2">
                      <div className="flex justify-between items-center mb-3">
                        <div className="font-semibold">{option?.name}</div>
                        <div className="text-gray-500 text-sm">
                          {option?.value?.length} {t("votes")}
                        </div>
                      </div>

                      {option?.value?.length > 0 && (
                        <div
                          style={{ scrollbarWidth: "thin" }}
                          className="space-y-3 max-h-[300px] overflow-y-auto"
                        >
                          {option?.value?.map(
                            (voter: string, voterIdx: number) => {
                              return (
                                <div
                                  key={voterIdx}
                                  className="flex items-center space-x-3"
                                >
                                  <Avatar
                                    alt=""
                                    src={
                                      DefaultImageUrl +
                                      getVoterName(voter)?.profile
                                    }
                                  />
                                  <div className="text-sm">
                                    {getVoterName(voter)?.name}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default PollDetails;
