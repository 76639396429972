import { Avatar, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import { FavouriteChatsDataTypes } from "../../Types/Types";
import moment from "moment";
import UserPic from "../../Assets/Images/user.png";
import { DefaultImageUrl } from "../../Constant/Constant";
import ArticleIcon from "@mui/icons-material/Article";
import { emit, socketListen } from "../../Socket";
import { useEffect, useState } from "react";
import Toast from "../../coreComponent/Toast";
import {
  appendOldChats,
  appendPreviousChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setCurrentPage,
  setFocusedData,
  setTotalPage,
} from "../../Redux/ChatSlice";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { refreshTokenCllient } from "../../Graphql/authClient";
import { GET_CHATS_BY_INDEX_ID } from "../../Graphql/Queries";
import { getSession } from "../../utils/session";

function FavouriteMessage({ setFavouriteListPage, setRoomRightDrawer }: any) {
  const {
    favouriteChats,
    formattedParticipantsOfActiveRoom,
    activeRoomData,
    activeRoomChats,
    activeChatsSkip,
    activeChatsLimit,
  } = useSelector((state: any) => state.chat);
  const [loadData, setLoadData] = useState<any>({ status: false, messgae: null })
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const { focusData } = useSelector((state: any) => state.chat)
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const handleChatUnfavourite = (item: any) => {
    emit("removeChatsFromFavourite", {
      roomId: activeRoomData?._id,
      cid: [item?.cid ?? item?._id],
    });

    socketListen("message", (data: any) => {
      if (data.type === "removeChatsFromFavourite") {
        setToastShow(true);
        setToastMessage(t("favourites_message"));
      }
    });
  };

  const handleRedirectOnMessage = (message: FavouriteChatsDataTypes) => {
    if (message?.__v !== 0) {
      const totalChats = activeRoomData?.totalChats;
      const skip = totalChats - message?.__v - 25;
      const value = skip < 0 ? 0 : skip;
     
      if (
        message?.__v < activeRoomChats[activeRoomChats?.length - 1]?.index &&
        message?.__v >= activeRoomChats[0]?.index
      ) {
        dispatch(setFocusedData({ status: true, message_id: message?.__v }))
        document
          .getElementById(`message-index-${message?.__v}`)
          .scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        document
          .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '#87d3e2f5';
        document
          .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "10px";
        document
          .getElementById(`message-index-${message?.__v}`).style.transition = 'background-color ' + 2 + 's ease-in-out';

        setTimeout(() => {
          document
            .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '';
          document
            .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "0px";
          document
            .getElementById(`message-index-${message?.__v}`).style.transition = 'none';
        }, 1600);
        setRoomRightDrawer(false);
      } else {
        setRoomRightDrawer(false);
        const skip = activeChatsLimit * (Math.floor(message.__v / activeChatsLimit) + 1);
        dispatch(setActiveChatSkip(skip));
        dispatch(setCurrentPage(Math.floor(message.__v / activeChatsLimit) + 1));
        // dispatch(setTotalPage(Math.ceil(activeRoomData?.totalChats / activeChatsLimit)))
        const { token } = getSession();
        refreshTokenCllient(token)
          .query({
            query: GET_CHATS_BY_INDEX_ID,
            variables: {
              input: {
                roomId: activeRoomData?._id, // roomId
                total: activeRoomData?.totalChats, // total chats
                index: message?.__v,
                limit: activeChatsLimit, // custom limit
              },
            },
          })
          .then((res) => {
            dispatch(setFocusedData({ status: true, message_id: message?.__v }))
            dispatch(appendOldChats([]));
            dispatch(setActiveRoomChats([]));

            console.log(activeRoomChats, res)
            if (res.data.getChatsByIndex.length > 0) {
              dispatch(appendPreviousChats(res?.data?.getChatsByIndex));
            }
            setTimeout(() => {
              if (message.__v !== null && document
                .getElementById(`message-index-${message?.__v}`)) {
                console.log(message, 'message*************')
             /*    document
                  .getElementById(`message-index-${message?.__v}`)
                  .scrollIntoView({ block: "center", behavior: "auto" }) */
                document
                  .getElementById(`message-index-${message?.__v}`)
                  .scrollIntoView({ block: "center", behavior: "smooth" });
                document
                  .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '#e0e0e0';
                document
                  .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "10px";
                document
                  .getElementById(`message-index-${message?.__v}`).style.transition = 'background-color ' + 2 + 's ease-in-out';


                setTimeout(() => {
                  document
                    .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '';
                  document
                    .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "0px";
                  document
                    .getElementById(`message-index-${message?.__v}`).style.transition = 'none';
                  //dispatch(setFocusedData({ status: false, __v:null }))
                }, 2800);
              }
            }, 2000);
          })
          .catch((error) => {
            console.log(`Cannot Query scenario by id => ${error}`);
          })

        /* setTimeout(() => {
                    document
                      .getElementById(`message-index-${message?.__v}`)
                      .scrollIntoView({ block: "center", behavior: "smooth" });
                    document
                      .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '#E0FAFF';
                    document
                      .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "10px";
                    document
                      .getElementById(`message-index-${message?.__v}`).style.transition = 'background-color ' + 2 + 's ease-in-out';
        
                    setTimeout(() => {
                      document
                        .getElementById(`message-index-${message?.__v}`).style.backgroundColor = '';
                      document
                        .getElementById(`message-index-${message?.__v}`).style.paddingBottom = "0px";
                      document
                        .getElementById(`message-index-${message?.__v}`).style.transition = 'none';
                    }, 1600);
                  }, 3000);
         */

      }
    } else {
      setToastMessage(t("message_not_available"));
      setToastShow(true);
    }
  };

  const FormattedText: any = (message: any) => {
    const regex = /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```/g;

    const data = message?.lenght > 30 ? message?.slice(0, 30) + "..." : message;

    const formattedText = data?.replace(
      regex,
      (match: any, p1: any, p2: any, p3: any, p4: any) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        }

        return match;
      }
    );
    return formattedText;
  };
  const sortedArray = [...favouriteChats].sort(
    (a, b) => new Date(Number(b.created_at)).getDate() - new Date(Number(a.created_at)).getDate()
);

  return (
    <div>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />

      <div
        className="flex items-center w-full bg-[#F3F9FC] border-b fixed z-10 h-[60px] cursor-pointer"
        onClick={() => setFavouriteListPage(false)}
      >
        <div className="py-2 flex">
          <ArrowBackIosNewIcon />
        </div>
        <div className="pl-1">{t("back")}</div>
      </div>

      <div className="relative top-[60px] px-4 py-4">
        {sortedArray?.length > 0 ? (
          sortedArray?.map(
            (item: FavouriteChatsDataTypes, index: number) => {
              const userContactDetails = formattedContact[item?.sender];
              const userInfo = formattedParticipantsOfActiveRoom[item?.sender];

              const date = moment(Number(item?.created_at)).format(
                "DD.MM.YYYY HH:mm"
              );

              return (
                <div className="flex gap-2 mb-4" key={index}>
                  <div className="h-[35px] min-w-[35px] w-[35px]">
                    {userInfo?.profile_img !== "" ? (
                      <img
                        src={DefaultImageUrl + userInfo?.profile_img}
                        alt="as"
                        className="h-full w-full rounded-full"
                      />
                    ) : (
                      <img
                        src={UserPic}
                        alt="as"
                        className="h-full w-full rounded-full"
                      />
                    )}
                  </div>
                  <div className="w-full">
                    <div className="mb-2 flex justify-between">
                      <div>
                        {item?.sender === userData?._id
                          ? t("you")
                          : userContactDetails?.firstName !== "" ||
                            userContactDetails !== ""
                            ?userContactDetails?.firstName===undefined?item.phone: userContactDetails?.firstName +
                            " " +
                            userContactDetails?.lastName===undefined?item.phone: userContactDetails?.lastName
                            : userInfo?.phone}
                      </div>
                      <div className="text-sm textGray">{date}</div>
                    </div>

                    {item?.type === "text" && (
                      <div
                        className={
                          item?.sender === userData?._id
                            ? "bg-[#E0FAFF] text-black text-sm p-2 rounded-b-md rounded-r-md flex justify-between w-fit"
                            : "bg-[#F4F4F4] p-2 text-black rounded-b-md rounded-r-md text-sm flex justify-between w-fit"
                        }
                      >
                        <span
                          className="cursor-pointer"
                          onClick={() => handleRedirectOnMessage(item)}
                        >
                          {parse(FormattedText(item?.message))}
                        </span>
                        <span
                          className="cursor-pointer ml-2"
                          onClick={() => handleChatUnfavourite(item)}
                        >
                          <Tooltip title={t("unfavourite")}>
                            <StarIcon
                              sx={{ fontSize: 14 }}
                              color={
                                item?.sender === userData?._id
                                  ? "inherit"
                                  : "disabled"
                              }
                            />
                          </Tooltip>
                        </span>
                      </div>
                    )}

                    {item?.type === "IMAGE" && (
                      <div
                        className="flex justify-between w-max"
                        onClick={() => handleRedirectOnMessage(item)}
                      >
                        <span>
                          <img
                            src={DefaultImageUrl + item?.fileURL}
                            alt=""
                            className="h-[200px] w-[200px] rounded-md"
                          />
                          <span
                            className="cursor-pointer"

                          >
                            {parse(FormattedText(item?.message))}
                          </span>
                        </span>

                        <span
                          className="cursor-pointer ml-2"
                          onClick={() => handleChatUnfavourite(item?.cid)}
                        >
                          <Tooltip title={t("unfavourite")}>
                            <StarIcon
                              sx={{ fontSize: 14 }}
                              color={
                                item?.sender === userData?._id
                                  ? "primary"
                                  : "disabled"
                              }
                            />
                          </Tooltip>
                        </span>
                      </div>
                    )}

                    {item?.type !== "text" && item?.type !== "IMAGE" && (
                      <div
                        className="flex justify-between cursor-pointer"
                        onClick={() => handleRedirectOnMessage(item)}
                      >
                        <span>
                          <ArticleIcon color="primary" fontSize="large" />
                          <span
                            className="ml-1 text-sm textGray"
                          >
                            {item?.fileURL}
                          </span>
                          <span
                            className="cursor-pointer"

                          >
                            {parse(FormattedText(item?.message))}
                          </span>
                        </span>
                        <span
                          className="cursor-pointer ml-2"
                          onClick={() => handleChatUnfavourite(item?.cid)}
                        >
                          <Tooltip title={t("unfavourite")}>
                            <StarIcon
                              sx={{ fontSize: 14 }}
                              color={
                                item?.sender === userData?._id
                                  ? "primary"
                                  : "disabled"
                              }
                            />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className="text-center"> {t("no_favourite_messages")} </div>
        )}
      </div>
    </div>
  );
}

export default FavouriteMessage;
