import { useEffect, useState } from "react";
import Buttons from "../coreComponent/Buttons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthCode from "react-auth-code-input";
import ButtonWhite from "../coreComponent/ButtonWhite";
import { useNavigate } from "react-router-dom";
import "../App.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AppPath } from "../Constant/Constant";
import { useTranslation } from "react-i18next";
import { client } from "../Graphql/client";
import { FORGOT_PASSWORD, VERIFY_OTP } from "../Graphql/Queries";
import Loading from "react-loading";
import { CircularProgress } from "@mui/material";

function ForgotPassword() {
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [mode, setMode] = useState(true);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [resetload, setResetLoad] = useState(false);
  const { t } = useTranslation();
  const [defaultCode, setDefaultCode] = useState<any>("");
  const [errors, setErrors] = useState("");

  const handleSubmit = () => {
    setLoad(true);
    setErrors("");
    if (phone === "") {
      setLoad(false);
      setPhoneError("Please Enter Phone Number");
    } else if (
      JSON.stringify(phone).length > 0 &&
      JSON.stringify(phone).length < 8
    ) {
      setLoad(false);
      setPhoneError("Please Enter Phone Number");
    } else {
      client
        .query({
          query: FORGOT_PASSWORD,
          variables: {
            input: {
              phone: "+" + phone,
            },
          },
        })
        .then((data) => {
          if (data) {
            setPhoneError("");
            setMode(false);
          }
        })
        .catch((error) => {
          setErrors(
            JSON.parse(error?.message ?? {})?.message ?? "Something went wrong"
          );
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const verifyOtp = () => {
    setLoad(true);
    setErrors("");
    if (otp === "") {
      setLoad(false);
      setOtpError("Please Enter Otp");
    } else {
      client
        .query({
          query: VERIFY_OTP,
          variables: {
            input: {
              phone: "+" + phone,
              code: otp,
            },
          },
        })
        .then((data) => {
          console.log(data, "datat");
          if (data?.data?.validatePasswordResetSms) {
            localStorage.setItem(
              "resetToken",
              data?.data?.validatePasswordResetSms?.token
            );
            setPhoneError("");
            navigate(AppPath.resetPassword);
          }
        })
        .catch((error) => {
          setErrors("Invalid OTP. Please enter correct OTP.");
          console.log(error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };
  function getCountryCode() {
    // Make an API request to ipinfo.io
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        // Extract the country code from the response
        const countryCode = data.country;
        if (countryCode) {
          localStorage.setItem("code", countryCode.toLowerCase());
          setDefaultCode(countryCode.toLowerCase());
        } else {
          localStorage.setItem("code", "de");
          setDefaultCode("de");
        }
        /*  fetch(`https://restcountries.com/v3/alpha/${countryCode}`)
        .then(response => response.json())
        .then(countryData => {
          console.log(countryData)
          const dialingCode = `${countryData[0].idd?.root}${countryData[0].idd.suffixes[0]}`;
          
          console.log('Dialing Code:', dialingCode);
          // You can use the country code and dialing code for further processing
        })
        .catch(error => {
          console.error('Error fetching dialing code:', error);
        }); */
        // You can use the country code for further processing
      })
      .catch((error) => {
        setDefaultCode("de");
        console.error("Error fetching country code:", error);
      });
  }

  // Call the function to get the country code
  useEffect(() => {
    getCountryCode();
  }, []);
  return (
    <>
      <div className="flex justify-center items-center  bg-white w-full">
        <div
          className="border rounded-md p-5 md:w-[80%] lg:w-[35%] bg-white dark:bg-black"
          style={{ boxShadow: "0px 2px 13px 5px #0000001a" }}
        >
          {mode ? (
            <div>
              <div
                className="flex items-center lightBlack cursor-pointer"
                onClick={() => navigate(AppPath.login)}
              >
                <div className="textGray">
                  <KeyboardBackspaceIcon />
                </div>
                <div className="pl-1 ">{t("login")}</div>
              </div>
              <div className="text-center text-3xl font-semibold lightBlack pt-4 pb-6 dark:text-white">
                {t("forgot_password")}
              </div>
              <div className="lightBlack text-base dark:text-white">
                {t("reset_password_msg")}
              </div>

              <div className="pt-6">
                <div className="text-[#333333] pb-[4px] mb-[6px]">
                  {t("phone")}
                </div>
                <PhoneInput
                  country={defaultCode}
                  value={phone}
                  masks={{ ci: ".. .. .. .. .." }}
                  onChange={(value: any, code: any) => {
                    //console.log(value,code);
                    setDefaultCode(code?.countryCode);
                    setPhone(value);
                  }}
                  inputStyle={{
                    width: "100%",
                    borderColor: "#E0E0E0",
                    padding: "18px 47px",
                  }}
                />
              </div>

              <div className="text-sm text-red-600 font-medium mt-2">
                {phoneError}
              </div>

              {errors && (
                <div className="text-sm text-red-600 font-medium mt-2">
                  {errors}
                </div>
              )}

              <div
                className="mt-4"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Buttons>
                  <span>
                    {load ? (
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      t("continue")
                    )}
                  </span>
                </Buttons>
              </div>
            </div>
          ) : (
            <div className="">
              <div
                className="flex items-center lightBlack cursor-pointer dark:text-white"
                onClick={() => setMode(true)}
              >
                <div className="textGray">
                  <KeyboardBackspaceIcon />
                </div>
                <div className="pl-1 ">{t("back")}</div>
              </div>
              <div className="text-center text-3xl font-semibold text-[#333333] pt-4 pb-6 dark:text-white">
                {t("verification")}
              </div>

              <div className="lightBlack text-base pb-4 dark:text-white">
                {t("enter_verification_code")}
              </div>
              <div className="flex gap-4 justify-center items-center">
                <AuthCode
                  onChange={(res) => setOtp(res)}
                  inputClassName="w-[20%] border border-2 mx-2 py-6 focus:outline-none text-2xl text-center"
                  length={4}
                />
              </div>
              {otpError && (
                <div className="text-sm text-red-600 font-medium pt-2">
                  {otpError}
                </div>
              )}

              {errors && (
                <div className="text-sm text-red-600 font-medium mt-2">
                  {errors}
                </div>
              )}

              <div className="mt-7 mb-4">
                <ButtonWhite>
                  <span
                    onClick={() => {
                      setResetLoad(true);
                      setTimeout(() => {
                        setResetLoad(false);
                      }, 1000);
                    }}
                  >
                    {resetload ? (
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      t("resend_code")
                    )}
                  </span>
                </ButtonWhite>
              </div>
              <div
                className=""
                onClick={() => {
                  verifyOtp();
                }}
              >
                <Buttons>
                  <span>
                    {load ? (
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    ) : (
                      t("verify_code")
                    )}
                  </span>
                </Buttons>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
