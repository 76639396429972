import { Box, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../../Graphql/Mutations";
import { useSelector } from "react-redux";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export default function ChangePasswordDrawer({ setShowChangePassword }) {
    const { t } = useTranslation();
    const [updateUser] = useMutation(UPDATE_USER);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const { userData } = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({ current_password: "", new_password: "", confirm_password: "" });
    const [errorMessage, setErrorMessage] = useState({ type: '', message: "" });
    const UpdatePassword = () => {
        if (form.current_password === "") {
            setErrorMessage({ type: "empty", message: t('password_validation') })
        } else if (form.new_password === "") {
            setErrorMessage({ type: "new_empty", message: t('password_validation') })
        }
        else if (form.new_password !== form.confirm_password) {
            setErrorMessage({ type: "not_match", message: t('password_not_match') })
        } else {
            setLoading(true);
            updateUser({
                variables: {
                    input: {
                        _id: userData?._id,
                        password: form.new_password,
                        currentPassword: form.current_password,
                        // profile_img: userProfileData?.profile_img,
                    },
                },
            })
                .then((res) => {
                    setLoading(false);
                    setShowChangePassword(false)
                }).catch((err) => {
                    if (err) {
                        if (err?.message && JSON.parse(err.message)) {
                            setErrorMessage({ type: 'global', message: JSON.parse(err.message)?.message })
                        }
                        setLoading(false);
                    }
                });
        }
    }
    return (
        <div>
            <Box sx={{ width: 400 }} role="presentation" >
                <div className="h-[100vh] ">
                    <div className="flex justify-between pl-2 border-b py-3 bg-gray-100 sticky top-0">
                        <div
                            className="flex cursor-pointer"
                            onClick={() => setShowChangePassword(false)}
                        >
                            <div>
                                <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
                            </div>
                            <div className="">{t("back")}</div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-center mx-auto my-3">{t("change_password")}</div>
                        <hr className="my-2" />
                        <div className="flex flex-col gap-4 px-4 mt-4">
                            <div>
                                <TextField
                                    autoComplete="false"
                                    id="password-input"
                                    type={!showCurrentPassword ? "password" : "text"}
                                    className="w-full   "
                                    InputLabelProps={{ shrink: true }}
                                    value={form.current_password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (errorMessage?.type === 'empty') {
                                            setErrorMessage({ type: '', message: "" })
                                        }
                                        setForm({ ...form, current_password: e.target.value })
                                    }
                                    }
                                    label={t("current_password")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowCurrentPassword(!showCurrentPassword) }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {!showCurrentPassword ? <VisibilityOff sx={{ color: "gray", width: 18, height: 18 }} /> : <Visibility sx={{ color: "gray", width: 18, height: 18 }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={
                                        {
                                            sx: {
                                                width: "100%",
                                                borderColor: errorMessage?.type === 'empty' && errorMessage.message !== "" ? "red" : "#E0E0E0",
                                            }
                                        }} />
                                {errorMessage?.type === 'empty' && errorMessage.message !== "" && <div className="text-red-500 text-xs mt-1">{errorMessage?.message}</div>}
                            </div>
                            <div>
                                <TextField
                                    autoComplete="false"
                                    id="password-input"
                                    InputLabelProps={{ shrink: true }}
                                    type={!showPassword ? "password" : "text"}
                                    className="w-full   "
                                    value={form.new_password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (errorMessage?.type === 'not_empty') {
                                            setErrorMessage({ type: '', message: "" })
                                        }
                                        setForm({ ...form, new_password: e.target.value })
                                    }
                                    }
                                    label={t("new_passwords")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword(!showPassword) }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {!showPassword ? <VisibilityOff sx={{ color: "gray", width: 18, height: 18 }} /> : <Visibility sx={{ color: "gray", width: 18, height: 18 }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={
                                        {
                                            sx: {
                                                width: "100%",
                                                borderColor: errorMessage?.type === 'new_empty' && errorMessage.message !== "" ? "red" : "#E0E0E0",
                                            }
                                        }} />
                                {errorMessage?.type === 'new_empty' && errorMessage.message !== "" && <div className="text-red-500 text-xs mt-1">{errorMessage?.message}</div>}
                            </div>
                            <div>
                                <TextField
                                    autoComplete="false"
                                    id="password-input"
                                    type={!showConfirmPassword ? "password" : "text"}
                                    className="w-full   "
                                    InputLabelProps={{ shrink: true }}
                                    value={form.confirm_password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                        if (errorMessage?.type === 'not_match' && form.new_password === e.target.value) {
                                            setErrorMessage({ type: '', message: "" })
                                        }
                                        setForm({ ...form, confirm_password: e.target.value })
                                    }
                                    }
                                    label={t("confirm_password")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {!showConfirmPassword ? <VisibilityOff sx={{ color: "gray", width: 18, height: 18 }} /> : <Visibility sx={{ color: "gray", width: 18, height: 18 }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={
                                        {
                                            sx: {
                                                width: "100%",
                                                borderColor: errorMessage?.type === 'not_match' && errorMessage.message !== "" ? "red" : "#E0E0E0",
                                            }
                                        }} />
                                {errorMessage?.type === 'not_match' && errorMessage.message !== "" && <div className="text-red-500 text-xs mt-1">{errorMessage?.message}</div>}
                            </div>
                            {errorMessage?.type === 'global' && errorMessage.message !== "" && <div className="text-red-500 text-xs mt-1">{errorMessage?.message}</div>}

                            {form?.current_password?.trim()?.length > 0 && form?.new_password?.trim()?.length > 0 && form?.confirm_password?.trim()?.length > 0 && (
                                <div onClick={() => { if (!loading) { UpdatePassword() } }} className="cursor-pointer px-4 py-1 rounded-md bg-[#33ccff] w-fit flex justify-center text-white mx-auto">{loading ? <CircularProgress style={{ height: 16, width: 16, color: "white" }} /> : t('save')}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}