import { useState } from "react";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

function DateFilter({ filterDates, setFilterDates, setIsRefresh, loading }) {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);

  const handleChange = (name: string, date: any) => {
    const formatted = dayjs(date).add(5.5, "hours")
    setFilterDates({ ...filterDates, [name]: formatted.toISOString() });
  };

  const reset = () => {
    setFilterDates({ from: null, to: null });
    setIsRefresh(true);
  };

  return (
    <div className="mt-3 px-5">
      <div className="flex w-full justify-end">
        <div
          onClick={() => setShowFilter((prev) => !prev)}
          className="flex gap-2 cursor-pointer"
        >
          <TuneIcon sx={{ color: "#5a5858" }} />
          <div className="font-semibold text-[#5a5858]">{t("filters")}</div>
        </div>
      </div>

      {showFilter && (
        <div className="mt-3 flex gap-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                className="w-full"
                label={t("from")}
                format="DD MMM YYYY"
                value={dayjs(filterDates?.from)}
                maxDate={dayjs(filterDates?.to)}
                onChange={(date) => handleChange("from", date)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                className="w-full"
                format="DD MMM YYYY"
                value={dayjs(filterDates?.to)}
                minDate={dayjs(filterDates?.from)}
                label={<div className="capitalize">{t("to")}</div>}
                onChange={(date) => handleChange("to", date)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      )}

      {showFilter && filterDates?.from && filterDates?.to && (
        <div className="mt-3 flex justify-end gap-3">
          <button
            onClick={reset}
            disabled={Boolean(loading)}
            className="flex items-center bg-[#D10363] text-white text-sm px-3 py-1 rounded-md"
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={20} />
            ) : (
              t("reset")
            )}
          </button>
          <button
            disabled={Boolean(loading)}
            onClick={() => setIsRefresh(true)}
            className="flex items-center bg-[#006989] text-white text-sm px-3 py-1 rounded-md"
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={20} />
            ) : (
              t("apply")
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default DateFilter;
