import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SendMessageIcon from "../../Assets/Images/SendMessageIcon.png";
import PinIcon2 from "../../Assets/Images/PinIcon2.png";
import EditGroupIcon from "../../Assets/Images/EditGroupIcon.png";
import { ChangeEvent, useEffect, useState } from "react";
import DialogBox from "../../coreComponent/DialogBox";
import { emit, socketListen } from "../../Socket";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../coreComponent/Toast";
import { ParticipantsDataTypes } from "../../Types/Types";
import { DefaultImageUrl } from "../../Constant/Constant";
import { Avatar } from "@mui/material";
import moment from "moment";
import RightIcon from "../../Assets/Images/right.png";
import { setActiveRoomData } from "../../Redux/ChatSlice";
import checkImg from "../../Assets/Images/True.png";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const GroupSettings = ({ setGroupSettingsShow }: any) => {
  const [permissionModalShow, setPermissionModalShow] = useState(false);
  const [permissionType, setPermissionType] = useState("");
  const [groupAdminShow, setGroupAdminShow] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { t } = useTranslation()
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );
  const [diselectedParticipants, setDiselectedParticipants] = useState<any>([]);
  const { activeRoomData } = useSelector((state: any) => state.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const [adminIds, setAdminIds] = useState<any>([]);
  const dispatch = useDispatch();

  const handleChangeRoomPermissions = (permit: string) => {
    emit("changeRoomPermission", {
      roomId: activeRoomData?._id,
      access: { type: permissionType, permit: permit },
    });

    socketListen("message", (data: any) => {
      if (data.type === "changeRoomPermission") {
        setToastShow(true);
        setToastMessage(t("permission_changed"));
        setPermissionModalShow(false);
        setGroupSettingsShow(false)
        setPermissionType("");
      }
    });
  };

  const handleSelectParticipants = (e: ChangeEvent<HTMLInputElement>) => {
    let temp: string[] = [...selectedParticipants];

    if (e.target.checked) {
      temp.push(e.target.value);
      setSelectedParticipants(temp);
    } else {
      const filtered = temp.filter((item: any) => item !== e.target.value);
      setSelectedParticipants(filtered);

      let dis = [...diselectedParticipants];
      dis.push(e.target.value);
      setDiselectedParticipants(dis);
    }
  };

  const handleUpdateRoomAdmin = () => {
    emit("updateRoomAdmin", {
      roomId: activeRoomData?._id,
      admin: selectedParticipants,
      common: diselectedParticipants,
    });

    socketListen("message", (data: any) => {
      if (data.type === "updateRoomAdmin") {
        const tempActiveRoomData: any = { ...activeRoomData };

        const updatedParticipants = tempActiveRoomData?.participants?.map(
          (one: any) => {
            if (one?.user_id == data.pid) {
              return {
                ...one,
                user_type: data.user_type,
              };
            }
            return one;
          }
        );
        tempActiveRoomData.participants = [...updatedParticipants];
        dispatch(setActiveRoomData(tempActiveRoomData));
        setToastShow(true);
        setGroupSettingsShow(false)
        setToastMessage(t("update_room_admin"));
      }
    });
  };

  useEffect(() => {
    const ids = activeRoomData?.participants?.map(
      (item: ParticipantsDataTypes) =>
        item?.user_type === "admin" && item?.user_id
    );

    setAdminIds(ids);
  }, [activeRoomData]);

  const sendMessageCurrentPermit = activeRoomData?.access?.find(
    (item: any) => item?.type === "sendMessage"
  );

  const pinMessageCurrentPermit = activeRoomData?.access?.find(
    (item: any) => item?.type === "pinMessage"
  );

  const editInfoCurrentPermit = activeRoomData?.access?.find(
    (item: any) => item?.type === "editInfo"
  );
  const currentSetting = activeRoomData?.access?.find(
    (item: any) => item?.type === permissionType
  );



  return (
    <div>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />

      <DialogBox
        open={permissionModalShow}
        title={t("group_permission")}
        actions={<>

          <div
            className="py-2 bg-primary text-white px-6 text-center rounded cursor-pointer"
            onClick={() => setPermissionModalShow(false)}
          >
            {t("cancel")}
          </div>
        </>}
        handleClose={() => setPermissionModalShow(false)}
        content={<>
          <div className="textGray pt-5 pb-2 px-6">
            {permissionType == "sendMessage" ? (
              t("choose_who_send_msg")
            ) : permissionType == "pinMessage" ? (
              t("choose_who_pin_msg")
            ) : (
              t("choose_who_edit_group")
            )}
          </div>
          <div className="flex flex-col items-center pt-4">
            <div
              className="pt-2 pb-4 px-6 cursor-pointer flex justify-center items-center relative"
              onClick={() => handleChangeRoomPermissions("common")}
            >
              {t("all_participants")}
              {currentSetting?.permit === "common" ? (
                <img
                  src={checkImg}
                  className="w-6  ml-6 right-[30px]"
                  alt="check"
                />
              ) : <div className="w-6 h-6 rounded-full ml-3  p-3 border-2"></div>}
            </div>
            <div
              className="pt-2 pb-4 px-6 cursor-pointer flex justify-center items-center relative"
              onClick={() => handleChangeRoomPermissions("admin")}
            >
              {t("only_admins")}
              {currentSetting?.permit === "admin" ? (
                <img
                  src={checkImg}
                  className="w-6  ml-8 "
                  alt="check"
                />
              ) : <div className="w-6 h-6 rounded-full ml-10  p-3 border-2"></div>}
            </div>
          </div>
        </>
        }
      />

      {groupAdminShow ? (
        <>
          <div
            className="flex justify-between items-center bg-gray-200 fixed z-10 h-[60px]"
            style={{ width: "-webkit-fill-available" }}
          >
            <div className="flex">
              <div
                className="cursor-pointer"
                onClick={() => setGroupAdminShow(false)}
              >
                <ArrowBackIosNewIcon />
              </div>
              <div className="text-center pl-3">{t("add_admins")}</div>
            </div>

            <div
              className="px-2 cursor-pointer"
              onClick={handleUpdateRoomAdmin}
            >
              <img src={RightIcon} alt="" />
            </div>
          </div>

          <div className="relative top-[60px] px-4 py-4">
            {activeRoomData?.participants?.map(
              (item: ParticipantsDataTypes, index: number) => {
                const userContactInfo = formattedContact[item?.user_id];
                const lastSeen =
                  item?.lastSeen === 0
                    ? moment(item?.added_at).format("DD.MM.YYYY HH:mm")
                    : moment(item?.lastSeen).format("DD.MM.YYYY HH:mm");

                return (
                  item?.user_id !== userData?._id &&
                  item?.left_at === 0 && (
                    <div className="flex items-center gap-3 my-4" key={index}>
                      <div className="h-[40px] min-w-[40px] max-w-[40px]">
                        {item?.profile_img !== "" ? (
                          <img
                            src={DefaultImageUrl + item?.profile_img}
                            alt=""
                            className="h-full w-full rounded-full"
                          />
                        ) : (
                          <Avatar sx={{ height: 45, width: 45 }} />
                        )}
                      </div>

                      <div className="flex items-center justify-between w-full">
                        <div>
                          {userContactInfo &&
                            (userContactInfo?.firstName !== "" ||
                              userContactInfo?.lastName !== "") ? (
                            <div className="capitalize">
                              {userContactInfo?.firstName +
                                " " +
                                userContactInfo?.lastName}
                            </div>
                          ) : (
                            <div>{item?.phone}</div>
                          )}
                          <div className="text-sm textGray">
                            {t("last_seen_at")} {lastSeen}
                          </div>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="cursor-pointer h-[18px] w-[18px] outline-none"
                            defaultChecked={adminIds?.includes(item?.user_id)}
                            value={item?.user_id}
                            onChange={handleSelectParticipants}
                          />
                        </div>
                      </div>
                    </div>
                  )
                );
              }
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className="flex items-center w-full bg-[#F3F9FC] h-[60px] cursor-pointer border-b"
            onClick={() => setGroupSettingsShow(false)}
          >
            <div className="py-2 flex">
              <ArrowBackIosNewIcon />
            </div>
            <div className="ml-1">{t("back")}</div>
          </div>

          <div className="mt-4 px-4">
            <div
              className="cursor-pointer flex justify-between items-center"
              onClick={() => {
                setPermissionModalShow(true);
                setPermissionType("sendMessage");
              }}
            >
              <div className="flex items-center gap-2">
                <span>
                  <img
                    src={SendMessageIcon}
                    alt=""
                    className="h-[34px] w-[36px] rounded-full"
                  />
                </span>
                <span>{t("send_message")}</span>
              </div>
              <div>
                <span className="mr-2 textGray">
                  {sendMessageCurrentPermit?.permit === "common"
                    ? t("all_participants")
                    : t("only_admins")}
                </span>
                <ArrowBackIosNewIcon
                  className="rotate-180"
                  fontSize="small"
                  color="disabled"
                />
              </div>
            </div>
            <div
              className="cursor-pointer flex justify-between items-center my-4"
              onClick={() => {
                setPermissionModalShow(true);
                setPermissionType("pinMessage");
              }}
            >
              <div className="flex items-center gap-2">
                <span>
                  <img
                    src={PinIcon2}
                    alt=""
                    className="h-[34px] w-[36px] rounded-full"
                  />
                </span>
                <span>{t("pin_message")}</span>
              </div>
              <div>
                <span className="mr-2 textGray">
                  {pinMessageCurrentPermit?.permit === "common"
                    ? t("all_participants")
                    : t("only_admins")}
                </span>
                <ArrowBackIosNewIcon
                  className="rotate-180"
                  fontSize="small"
                  color="disabled"
                />
              </div>
            </div>
            <div
              className="cursor-pointer flex justify-between items-center"
              onClick={() => {
                setPermissionModalShow(true);
                setPermissionType("editInfo");
              }}
            >
              <div className="flex items-center gap-2">
                <span>
                  <img
                    src={EditGroupIcon}
                    alt=""
                    className="h-[34px] w-[36px] rounded-full"
                  />
                </span>
                <span>{t("edit_group_info")}</span>
              </div>
              <div>
                <span className="mr-2 textGray">
                  {editInfoCurrentPermit?.permit === "common"
                    ? t("all_participants")
                    : t("only_admins")}
                </span>
                <ArrowBackIosNewIcon
                  className="rotate-180"
                  fontSize="small"
                  color="disabled"
                />
              </div>
            </div>
            <div
              className="cursor-pointer flex justify-between items-center mt-4"
              onClick={() => setGroupAdminShow(true)}
            >
              <div>{t("edit_group_admins")}</div>
              <div>
                <ArrowBackIosNewIcon
                  className="rotate-180"
                  fontSize="small"
                  color="disabled"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupSettings;
