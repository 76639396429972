import { Drawer, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InvitationsType } from "../types/types";
import { useState } from "react";
import { EventTypeEnum } from "../types/enum";
import ReminderInvitation from "./ReminderInvitation";
import ScheduleApproval from "./ScheduleApproval";

type PropTypes = {
  show: boolean;
  setShow: (value: boolean) => void;
  setIsRefresh: (value: boolean) => void;
  invitations: InvitationsType[];
};

function InvitationDrawer(props: PropTypes) {
  const { t } = useTranslation();
  const { show, setShow, invitations, setIsRefresh } = props;
  const [alert, setAlert] = useState({ msg: "", show: false });

  return (
    <Drawer open={show} anchor="right" onClose={() => setShow(false)}>
      <div className="w-[500px]">
        <div className="flex justify-between items-center py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
          <div
            className="flex  items-center cursor-pointer"
            onClick={() => setShow(false)}
          >
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
            <div className=" font-bold capitalize">{t("invitations")}</div>
          </div>
        </div>

        {invitations?.length > 0 ? (
          invitations?.map((invitation: InvitationsType, index: number) => {
            return (
              <div key={index} className="p-5 border-b-2">
                {invitation?.type != EventTypeEnum.SCHEDULE ? (
                  <ReminderInvitation
                    setAlert={setAlert}
                    invitation={invitation}
                    setIsRefresh={setIsRefresh}
                  />
                ) : (
                  <ScheduleApproval
                    setAlert={setAlert}
                    invitation={invitation}
                    setIsRefresh={setIsRefresh}
                  />
                )}
              </div>
            );
          })
        ) : (
          <div className="text-center mt-5">{t("no_invitations")}</div>
        )}
      </div>

      <Snackbar
        open={alert.show}
        message={t(alert.msg)}
        autoHideDuration={3000}
        onClose={() => setAlert({ show: false, msg: "" })}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Drawer>
  );
}

export default InvitationDrawer;
