import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "../../src/Assets/Images/Logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import { validPassword } from "../Regex";
import { client } from "../Graphql/client";
import { LOGIN, ME, SUBSCRIBE_TO_TOPIC } from "../Graphql/Queries";
import { LoginInputTypes } from "../Types/Types";
import { AppPath, vapidKey } from "../Constant/Constant";
import { setSession } from "../utils/session";
import ReactLoading from "react-loading";
import { getToken } from "firebase/messaging";
import { messaging } from "../Firebase/firebase";
import { authCllient, refreshTokenCllient } from "../Graphql/authClient";
import { setUserData } from "../Redux/UserSlice";
import { useDispatch } from "react-redux";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { detect } from "detect-browser";
import ForceLogoutDialog from "../Components/dialogs/ForceLogoutDialog";
import { FORCE_LGOUT } from "../Graphql/Mutations";
import logoutClient from "../Graphql/logoutClient";

function Login() {
  const [formData, setFormData] = useState({ phone: "", password: "" });
  const { t } = useTranslation();

  const [inputError, setInputError] = useState<LoginInputTypes>({
    phone: "",
    password: "",
  });
  const [defaultCode, setDefaultCode] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [forceLogout, setForceLogout] = useState(false);
  const [forceLogoutMsg, setForceLgoutMsg] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    setTimeout(() => {
      if (passwordInputRef?.current) {
        const input = passwordInputRef?.current;
        input?.focus();
        const length = input?.value?.length;
        input?.setSelectionRange(length, length);
      }
    }, 0);
  };

  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const authToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const passwordInputRef = useRef(null);
  const location = useLocation();
  const tokenParam = location.state?.tokenParam;

  useEffect(() => {
    if (authToken) {
      navigate(AppPath.home);
    }

    if (tokenParam) {
      localStorage.setItem("token", tokenParam);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    // eslint-disable-next-line
  }, [authToken]);

  const getUserData = () => {
    authCllient
      .query({
        query: ME,
      })
      .then((res) => {
        dispatch(setUserData(res?.data?.me));
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    const browser = detect();

    if (formData.phone === "") {
      setInputError({ phone: "Please enter phone number" });
      setLoading(false);
    } else if (
      !validPassword.test(formData.password) ||
      formData.password === ""
    ) {
      setInputError({ password: "Please enter password" });
      setLoading(false);
    }
    if (formData.password !== "" && formData.phone !== "") {
      setInputError({
        phone: "",
        password: "",
      });
      client
        .query({
          query: LOGIN,
          variables: {
            input: {
              // iso_code: defaultCode.toUpperCase(),
              phone: "+" + formData.phone,
              password: formData.password,
              device: {
                token: "",
                fcmToken: "",
                type: "WEB",
                webToken: [],
              },
              // buildId: `${browser.name} ${browser.version}`,
              // OSVersion: browser.os,
            },
          },
        })
        .then((data) => {
          navigate(AppPath.home);
          if (data.data) {
            localStorage.setItem("token", data.data?.login?.token);
            getUserData();
            authCllient
              .query({
                query: ME,
              })
              .then((res) => {
                dispatch(setUserData(res?.data?.me));
              });
            setErrors("");
            // dispatch(setIsContactsLoading(true));
            // dispatch(setIsRoomsLoading(true))
            setSession(data.data?.login);
            setTimeout(() => {
              setLoading(false);
              window.location.reload();
              getToken(messaging, { vapidKey }).then((currentToken) => {
                if (currentToken) {
                  localStorage.setItem("notification_token", currentToken);
                  authCllient
                    .query({
                      query: SUBSCRIBE_TO_TOPIC,
                      variables: {
                        token: currentToken,
                      },
                    })
                    .then((res: any) => {
                      if (res?.data?.subscribeToTopic?.success) {
                        localStorage.setItem("subscribed", "true");
                      }
                    });
                }
              });
            }, 1000);
          }
        })
        .catch((error) => {
          const er = JSON.parse(error?.message || {});
          if (er?.code == "already-login") {
            setForceLogout(true);
            setForceLgoutMsg(er?.message);
            localStorage.setItem("logout_token", er?.token);
          } else if (error?.message) {
            setErrors(
              JSON.parse(error?.message ?? {})?.message ??
                "Something went wrong"
            );
          }
          setLoading(false);
        });
    }
  };

  function getCountryCode() {
    // Make an API request to ipinfo.io
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        // Extract the country code from the response
        const countryCode = data.country;
        if (countryCode) {
          localStorage.setItem("code", countryCode.toLowerCase());
          setDefaultCode(countryCode.toLowerCase());
        } else {
          localStorage.setItem("code", "de");
          setDefaultCode("de");
        }
        /*  fetch(`https://restcountries.com/v3/alpha/${countryCode}`)
         .then(response => response.json())
         .then(countryData => {
           console.log(countryData)
           const dialingCode = `${countryData[0].idd?.root}${countryData[0].idd.suffixes[0]}`;
           
           console.log('Dialing Code:', dialingCode);
           // You can use the country code and dialing code for further processing
         })
         .catch(error => {
           console.error('Error fetching dialing code:', error);
         }); */
        // You can use the country code for further processing
      })
      .catch((error) => {
        setDefaultCode("de");
        console.error("Error fetching country code:", error);
      });
  }

  // Call the function to get the country code
  useEffect(() => {
    getCountryCode();
  }, []);
  const password_input = document.getElementById("password-input");

  password_input?.addEventListener("keypress", (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("submit-button").click();
    }
  });

  const handleForceLogout = async () => {
    setForceLgoutMsg(null);
    setForceLogout(false);

    setLoading(true);
    try {
      const response = await logoutClient.mutate({ mutation: FORCE_LGOUT });
      if (response?.data?.logoutDevicesWeb) {
        localStorage.clear();
        handleSubmit();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div
      className="flex justify-center items-center bg-white w-full"
      id="login"
    >
      <div
        className="border rounded-xl p-5 md:w-[70%] lg:w-[30%] dark:bg-black bg-white "
        style={{ boxShadow: "0px 2px 13px 15px #0000001a" }}
      >
        {/* <div className="h-100 w-100 blur-lg absolute z-10 bg-red-500"></div> */}
        <div className=" flex justify-center">
          <img src={Logo} className="w-[100px] h-[100px]" alt="logo" />
        </div>

        <div className="text-center text-xl font-semibold lightBlack dark:text-white pt-4 pb-7">
          {t("welcome_comon")}
        </div>

        <div className="">
          <div className="lightBlack dark:text-white pb-[4px] mb-[6px]">
            {t("phone")}
          </div>
          <PhoneInput
            country={defaultCode}
            inputProps={{
              required: true,
              autoFocus: true,
            }}
            placeholder="Phone"
            masks={{ ci: ".. .. .. .. .." }}
            value={formData.phone}
            //     enableLongNumbers={true}
            onChange={(value: any, code: any) => {
              //console.log(value,code);
              setDefaultCode(code?.countryCode);
              setFormData({ ...formData, phone: value });
            }}
            inputStyle={{
              width: "100%",
              borderColor: "#E0E0E0",
              padding: "18px 47px",
              backgroundColor: "rgba(243,243,243,1)",
            }}
          />
        </div>

        {inputError.phone && (
          <div className="text-sm text-red-600 font-medium mt-2">
            {inputError.phone}
          </div>
        )}

        <div className="pt-4 pb-2">
          <div className="lightBlack dark:text-white pb-[4px] mb-[6px]">
            {t("password")}
          </div>
          <TextField
            inputRef={passwordInputRef}
            autoComplete="false"
            id="password-input"
            type={!showPassword ? "password" : "text"}
            className="w-full h-10  p-0"
            value={formData.password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, password: e.target.value })
            }
            label=""
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <Visibility
                        sx={{ color: "gray", width: 18, height: 18 }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{ color: "gray", width: 18, height: 18 }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                width: "100%",
                borderColor: "#E0E0E0",
              },
            }}
          />
        </div>

        {inputError.password && (
          <div className="text-sm text-red-600 font-medium mt-2">
            {inputError.password}
          </div>
        )}

        {errors && (
          <div className="text-sm text-red-600 font-medium mt-2">{errors}</div>
        )}

        <div
          className="primary text-end text-sm cursor-pointer w-fit flex ml-auto"
          onClick={() => navigate(AppPath.forgetPassword)}
        >
          {t("forgot_your_password")}
        </div>

        <div className="mt-4">
          <button
            className="w-full flex justify-center text-center py-[7px] rounded-xl cursor-pointer bg-primary text-white"
            id="submit-button"
            type="submit"
            onClick={handleSubmit}
            disabled={loading ? true : false}
          >
            {loading ? (
              <ReactLoading type="spin" color="white" height={25} width={25} />
            ) : (
              t("login")
            )}
          </button>
        </div>
      </div>

      {forceLogout && (
        <ForceLogoutDialog
          show={forceLogout}
          setShow={setForceLogout}
          message={forceLogoutMsg}
          onSubmit={handleForceLogout}
        />
      )}
    </div>
  );
}

export default Login;
