import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import { DefaultImageUrl, GroupDefault } from "../../Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import Call from "../../Assets/Images/call.png";
import VideoCall from "../../Assets/Images/videoCall.png";
import moment from "moment";
import { Checkbox, CircularProgress, styled } from "@mui/material";
import { authCllient } from "../../Graphql/authClient";
import { ADD_PARTICIPANTS, CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { setInitiatedCallData, setMemberAddedInCall, updateCallStatus, updateCreateCall } from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { CALL_PARTICIPANTS } from "../../Graphql/Queries";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 100,
  width: 25,
  height: 25,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#33ccff",
  paddingTop: 2,
  paddingLeft: 2,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#33ccff",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const AddCallParticipants = ({ setContactCall, totalJoinedUsers }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { activeCallData, memberAddedInCall } = useSelector(
    (state: any) => state.chat
  );
  const [callData, setCallData] = useState([])
  const [load, setLoad] = useState(false);
  const [id, setids] = useState('');
  const { comonUsers, userData, blockedMember } = useSelector((state: any) => state.user);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const [groupCall, setGroupCall] = useState(false);
  const [selectedUseres, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [changeCallStatus] = useMutation(ADD_PARTICIPANTS);

  const handleGroupCall = (event: any) => {
    let tempMember = [...selectedUseres];
    if (event.target.checked) {
      tempMember.push({ id: event.target.id, name: event.target.name });
    } else {
      tempMember = tempMember.filter(
        (item: any) => item?.id !== event.target.id
      );
    }
    let idsarr = [];
    idsarr.push(event.target.id);
    dispatch(setMemberAddedInCall(idsarr));
    setSelectedUsers(tempMember);
  };

  const handleSearchRooms = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const addParticipants = (type: any, users: any) => {
    const participants = [];
    if (users.length > 0) {
      users?.forEach((item: any) => {
        participants.push(item?.id);
      });
    } else {
      selectedUseres?.forEach((item: any) => {
        participants.push(item?.id);
      });
    }

    if (type === "load") {

      setLoad(true);
    }

    setDisabledCallButton(true);
    dispatch(updateCreateCall(true));
    authCllient
      .mutate({
        mutation: ADD_PARTICIPANTS,
        variables: {
          input: {
            callId: activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id,
            participants: participants
          },
        },
      })
      .then((res) => {
        if (type === "load") {
          const idsarray = [];
          idsarray.push(users[0]?.id);
          dispatch(setMemberAddedInCall(idsarray));

        }
        setContactCall(false);
        setLoad(false);
      })
      .catch((error) => console.log("error at Create call", error))

  };

  function getCallTimediff(date: any) {

    const currentTime = moment();
    const callStartTime = moment(date);
    // Add 90 seconds to the current time to get the end time
    const callEndTime = callStartTime.clone().add(90, 'seconds');

    // Compare the current time with the end time
    if (currentTime.isAfter(callEndTime)) {
      return true;
      console.log("90 seconds have passed.");
    } else {
      return false;
      console.log("90 seconds have not passed yet.");
    }
  }


  function getCallStatus(item: any) {
    const status = activeCallData?.data?.callParticipants.find((el: any) => el.userId?._id === item?.userId?._id)?.callStatus
    const users = Object.keys(totalJoinedUsers).map((user: any) => (user.slice(0, 6)));
    const uid = users.filter((el: any) => el === item.uid.toString()).length

    if (status === 'accepted' && item?.userId?._id !== userData?._id && uid === 0) {
      return <span className="text-green-500">{t('left-call')}</span>
    }
    else {
      if (memberAddedInCall.includes(item?.userId?._id) && getCallTimediff(item.createdAt) === false) { return <span>{t("ringing")}</span> }
      else {
        return <span className={status === 'accepted' ? 'text-green-500' : status === 'missed' || status === 'rejected' ? "text-red-500" : ""}>
          {status}
        </span>
      }
    }
  }

  useEffect(() => {
    setGroupCall(activeCallData?.data?.roomType === 'group')
  }, [activeCallData])

  useEffect(() => {
    console.log(activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id)
    authCllient
      .query({
        query: CALL_PARTICIPANTS,
        variables: {
          input: {
            _id: activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id
          },
        },
      })
      .then((res: any) => {
        setCallData(res.data?.callParticipants?.participants)
      })
      .catch((error) => console.log("error at fetch call history", error));
  }, [])

  return (
    <React.Fragment>
      <div className="h-screen w-[350px]">
        <div className="flex p-3 shadow-lg justify-between">
          <div
            onClick={() => {
              setSelectedUsers([]);
              setContactCall(false);
            }}
          >
            <ArrowBackIosIcon fontSize="small" /> {activeCallData?.data?.roomType === "group" ? activeCallData?.data?.roomId?.name : t("new_call")}
          </div>
          {activeCallData?.data?.roomType !== "group" && selectedUseres.length > 0 &&
            (
              <button
                className="cursor-pointer text-white rounded-md  bg-[#33ccff] p-1 px-3"
                disabled={disableCallButton ? true : false}
                onClick={() => {
                  if (selectedUseres.length > 0) {
                    setLoad(true);
                    addParticipants('', []);
                  } else {
                    alert(
                      t("select_participants")
                    );
                  }
                }}
              >
                {load ? t("calling") : t('add-to-call')}
              </button>

            )
          }
        </div>
        {<div className="h-[93vh] overflow-y-scroll">
          <div
            className="mt-3 mx-2 rounded-md border mb-2  flex items-center px-2 bg-white"
            style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
          >
            <SearchIcon sx={{ color: "#333333" }} />
            <input
              type="text"
              placeholder={t("search_by_name_phone")}
              className="w-full py-2 px-2 focus:outline-none text-sm"
              value={searchQuery}
              onChange={handleSearchRooms}
            />
          </div>
          {/*  {!groupCall ? (
              <div
                className="flex my-auto cursor-pointer gap-4 py-5 p-3"
                onClick={() => {
                  setGroupCall(true);
                }}
              >
                <img src={DefaultImageUrl + GroupDefault} className="h-12 w-12" alt={t("profile")} />
                <span className="my-auto text-lg"> {t("group_call")} </span>
              </div>
            ) : (
              <div className="">

              </div>
            )} */}
          <div>
            {comonUsers.filter((el: any) => el.firstName
              .toLowerCase().split(' ')[0]?.startsWith(searchQuery.toLowerCase()) || el.firstName
                .toLowerCase().split(' ')[1]?.startsWith(searchQuery.toLowerCase()) || el.phone.startsWith(searchQuery) ||
              el.lastName
                .toLowerCase().split(" ")[0]?.startsWith(searchQuery.toLowerCase()) || el.lastName
                  .toLowerCase().split(" ")[1]?.startsWith(searchQuery.toLowerCase())).map((item: any, index: number) => {
                    if (blockedMember.includes(item?.userId?._id) || item?.userId?._id === userData?._id || activeCallData?.data?.callParticipants.filter((el: any) => el.userId._id === item?.userId?._id).length > 0) {
                      return null;
                    }
                    return (
                      <div>

                        {(
                          <div>
                            <div className="flex p-3 justify-between">
                              <div className="flex gap-2">

                                {<div
                                  onClick={(event) => {
                                    handleGroupCall(event);
                                  }}
                                >
                                  <BpCheckbox
                                    id={item?.userId?._id}
                                    checked={selectedUseres.filter((el:any)=>el?.id===item?.userId?._id).length>0}
                                    name={item.firstName}
                                  />
                                </div>}

                                <div className="w-[45px] h-[45px]">
                                  <img
                                    alt="call"
                                    src={DefaultImageUrl + item.userId.profile_img}
                                    className="h-full w-full rounded-full"
                                  />
                                </div>
                                <div className="my-auto">
                                  <div>
                                    {item.firstName} {item.lastName}
                                  </div>
                                  <div className="text-sm text-slate-500">
                                    {t("lastSeen")}
                                    {moment(item.userId.lastSeen).format(
                                      "DD-MM-YYYY"
                                    ) === moment().format("DD-MM-YYYY")
                                      ? ` ${t('today_at')} ` +
                                      moment(item.userId.lastSeen).format("HH:mm ")
                                      : moment()
                                        .subtract(1, "d")
                                        .format("DD-MM-YYYY") ===
                                        moment(item.userId.lastSeen).format(
                                          "DD-MM-YYYY"
                                        )
                                        ? ` ${t('yesterday')} ` +
                                        moment(item.userId.lastSeen).format("HH:mm ")
                                        : ` ${moment(item.userId.lastSeen).format(
                                          "DD-MM-YY"
                                        )} `}
                                  </div>
                                </div>
                              </div>

                            </div>
                            <hr className="m-3 mx-6" />
                          </div>
                        )}
                      </div>
                    );
                  })}
          </div>
        </div>}
      </div>
    </React.Fragment>
  );
};

export default AddCallParticipants;
