import { useTranslation } from "react-i18next";
import { ParticipantType, TStatus } from "../../types/types";
import VoiceCall from "../../../../Assets/Images/call.png";
import VideoCall from "../../../../Assets/Images/videoCall.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { authCllient } from "../../../../Graphql/authClient";
import { CALL_STATUS, CREATE_CALL } from "../../../../Graphql/Mutations";
import {
  setInitiatedCallData,
  setMemberAddedInCall,
  updateCreateCall,
} from "../../../../Redux/ChatSlice";
import { closeNewEventDialog } from "../../../../Redux/EventSlice";
import ReactLoading from "react-loading";

type TProps = {
  participants: ParticipantType[];
  setShow?: (value: boolean) => void;
  setRoomRightDrawer?: (value: boolean) => void;
};

function EventCall(props: TProps) {
  const { t } = useTranslation();
  const { participants, setShow, setRoomRightDrawer } = props;
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state?.user);
  const [status, setStatus] = useState<TStatus>("idl");

  async function handleCall(callType: string) {
    const participantsIds: string[] = [userData?._id];

    participants?.forEach((item) => {
      if (item?._id != userData?._id) {
        participantsIds.push(item?._id);
      }
    });

    try {
      setStatus("loading");

      const _payload = {
        origin: userData?._id,
        participants: participantsIds,
        channelName: String(new Date().getTime()),
        type: callType,
        roomType: "contact_group",
      };

      dispatch(updateCreateCall(true));
      dispatch(setMemberAddedInCall(participants));

      const response = await authCllient.mutate({
        mutation: CREATE_CALL,
        variables: {
          input: _payload,
        },
      });

      if (!response?.data?.createNewCall) {
        setStatus("error");
      } else {
        if (setShow) {
          setShow(false);
        }
        if (setRoomRightDrawer) {
          setRoomRightDrawer(false);
        }
        setStatus("success");
        dispatch(closeNewEventDialog(""));
        handleChangeCallStatus(response?.data?.createNewCall?.call?._id)
        dispatch(setInitiatedCallData(response?.data?.createNewCall?.call));
      }
    } catch (err) {
      setStatus("error");
      console.log(err);
    }
  }

  const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-3">
      <div className="font-semibold">{t("start_call")}</div>

      {status == "idl" && (
        <div className="mt-2 flex gap-4">
          <button
            type="button"
            className="outline-none"
            onClick={() => handleCall("audio")}
          >
            <img
              alt=""
              src={VoiceCall}
              className="h-[35px] w-[35px] rounded-full"
            />
          </button>

          <button
            type="button"
            className="outline-none"
            onClick={() => handleCall("video")}
          >
            <img
              alt=""
              src={VideoCall}
              className="h-[35px] w-[35px] rounded-full"
            />
          </button>
        </div>
      )}

      {status == "loading" && (
        <div className="mt-2">
          <ReactLoading type="spin" color="#34ccff" height={25} width={25} />
        </div>
      )}

      {status == "error" && (
        <div className="mt-2 text-red-600 text-sm">
          {t("something_went_wrong")}
        </div>
      )}
    </div>
  );
}

export default EventCall;
