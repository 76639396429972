import {
  getCallHistory,
  setActiveCallData,
  setInitiatedCallData,
  setIsCallMinimized,
  setJoinedFromOtherDevice,
  setLeftCallData,
  setParticipantsInCall,
  updateCallStatus,
  updateCreateCall,
} from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CALL_END, LEFT_CALL } from "../../Graphql/Mutations";
import { useNavigate } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import AgoraRTC from "agora-rtc-sdk-ng";
import { client_call } from "../..";
import Toast from "../../coreComponent/Toast";
import CallScreenHeader from "./CallScreenHeader";
import CallEndScreen from "./CallEndScreen";
import CallScreenFooter from "./CallScreenFooter";
import CallVideoScreen from "./CallVideoScreen";
import CallAudioScreen from "./CallAudioScreen";
import InCallUserInfoBubble from "./InCallUserInfoBubble";
import CallScreenParentContainer from "./CallScreenParentContainer";
import { useTranslation } from "react-i18next";
import ScreenShare from "./screen-share/ScreenShare";
import { emit, socket, socketListen } from "../../Socket";
import ScreenShareConfirm from "./screen-share/ScreenShareConfirm";

export default function CallChildContainer({
  options,
  setTime,
  callEnd,
  setCallEnd,
  token,
  uid,
}) {
  const dispatch = useDispatch();
  const { seconds, minutes, start } = useStopwatch();
  const callTime = useStopwatch();
  const navigate = useNavigate();
  const [contactCall, setContactCall] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [input] = useMutation(LEFT_CALL);
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const { activeCallData, initiatedCall, rejectCallData } = useSelector(
    (state) => state.chat
  );
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [localScreenTrack, setLocalScreenTrack] = useState(null);
  const [screenShareModal, setScreenShareModal] = useState(false);
  const [screenShareModalType, setScreenShareModalType] = useState("start");

  // create Agora client
  const [localTracks, setLocalTracks] = useState({
    videoTrack: null,
    audioTrack: null,
  });

  const [localTrackState, setLocalTrackState] = useState({
    videoTrackEnabled: true,
    audioTrackEnabled: true,
  });

  var remoteUsers = {};
  // Agora client options

  const [endCallInput] = useMutation(CALL_END);
  const [selfCallEnd, setSelfCallEnd] = useState(false);
  const [onleave, setOnleave] = useState(false);
  const [remoteUserData, setRemoteUserData] = useState({
    video: null,
    audio: null,
  });
  const [totalJoinedUsers, setTotalJoinedUsres] = useState({});
  let joinedUsers = {};
  const [usersData, setUserData] = useState([]);
  let usersArray = [];
  let count = 0;
  const [screenSharer, setScreenSharer] = useState("");
  const muteStatusRef = useRef({});
  const activeCallDataRef = useRef(activeCallData);

  const hasScreenSharing = () => {
    const _id = activeCallData?.sharer_uid;
    const find = client_call.remoteUsers.find((el) => el?.uid == _id);
    if (find) {
      setScreenSharer(_id);
      setTimeout(() => {
        playScreenTracks("remote-screen-track", find?.videoTrack);
        setIsScreenShared(true);
      }, 1500);
    }
  };

  useEffect(() => {
    if (activeCallData?.isScreenShare) {
      hasScreenSharing();
    }
  }, [client_call.remoteUsers.length, activeCallData]);

  async function join(token, channel) {
    // create Agora client
    // client_call.setClientRole(options.role);
    // join the channel
    let res = await client_call.join(options.appid, channel, token, uid);

    if (options.role === "host") {
      client_call.on("user-published", handleUserPublished);
      client_call.on("user-joined", handleUserJoined);
      client_call.on("user-left", handleUserLeft);

      client_call.on("user-info-updated", function (event, msg) {
        if (activeCallData?.data?.type === "video") {
          if (msg === "mute-video") {
            if (document.getElementById("remote-uid")) {
              document.getElementById("remote-uid").style.display = "block";
            }
            setRemoteUserData({ ...remoteUserData, video: true });
          } else if (msg === "unmute-video") {
            if (document.getElementById("remote-uid")) {
              document.getElementById("remote-uid").style.display = "none";
            }
            setRemoteUserData({ ...remoteUserData, video: false });
          }
        }

        if (msg === "mute-audio" || msg === "unmute-audio") {
          updateMuteStatus(event, msg);
        }

        // if (msg === "mute-audio") {
        //   if (document.getElementById(`remote-audio-unmute-${event}`)) {
        //     document.getElementById(
        //       `remote-audio-unmute-${event}`
        //     ).style.display = "none";
        //   }
        //   if (document.getElementById(`remote-audio-mute-${event}`)) {
        //     document.getElementById(
        //       `remote-audio-mute-${event}`
        //     ).style.display = "block";
        //   }
        //   setRemoteUserData({ ...remoteUserData, audio: true });
        // } else if (msg === "unmute-audio") {
        //   if (document.getElementById(`remote-audio-mute-${event}`)) {
        //     document.getElementById(
        //       `remote-audio-mute-${event}`
        //     ).style.display = "none";
        //   }

        //   if (document.getElementById(`remote-audio-unmute-${event}`)) {
        //     document.getElementById(
        //       `remote-audio-unmute-${event}`
        //     ).style.display = "block";
        //   }
        //   setRemoteUserData({ ...remoteUserData, audio: false });
        // }
      });

      const trackObj = {};
      // create local audio and video tracks
      trackObj.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await client_call.publish(trackObj.audioTrack);

      if (activeCallData?.data?.type == "video") {
        trackObj.videoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 320,
            height: 240,
            frameRate: 15,
            bitrateMin: 200,
          },
        });
        await client_call.publish([trackObj.audioTrack, trackObj.videoTrack]);
      }

      setLocalTracks(trackObj);

      // play local video track
      if (trackObj.videoTrack) {
        trackObj.videoTrack.play("local-player");
      }

      if (
        activeCallData?.data?.type === "audio" &&
        document.querySelector("#local-player video")
      ) {
        document.querySelector("#local-player video").style.display = "none";
      }
      if (activeCallData?.data?.type === "audio") {
        muteVideo();
      }

      callTime.reset();
      callTime.start();

      // publish local tracks to channel
      console.log("Successfully published.");
    }
  }

  const updateMuteStatus = (userId, msg) => {
    muteStatusRef.current[userId] = msg == "mute-audio" ? true : false;
    if (muteStatusRef?.current?.[userId]) {
      document.getElementById(`remote-audio-unmute-${userId}`).style.display =
        "none";
      document.getElementById(`remote-audio-mute-${userId}`).style.display =
        "block";
    } else {
      document.getElementById(`remote-audio-unmute-${userId}`).style.display =
        "block";
      document.getElementById(`remote-audio-mute-${userId}`).style.display =
        "none";
    }
  };

  async function subscribe(user, mediaType) {
    // subscribe to a remote user
    await client_call.subscribe(user, mediaType);

    const uid = user.uid;
    joinedUsers[uid] = uid;
    setTotalJoinedUsres(joinedUsers);

    console.log("Successfully subscribed.", uid);
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
    if (mediaType === "video") {
      if (activeCallData?.data?.type === "video") {
        getRenderRemote(uid);
      }
      usersArray.push(user);
      setUserData([...usersData, user]);
    }
  }

  const getRenderRemote = (uid) => {
    if (activeCallData?.data?.type == "video") {
      const videoTrack = client_call.remoteUsers.find(
        (el) => el?.uid == uid
      )?.videoTrack;
      if (videoTrack) {
        playScreenTracks(`player-${uid}`, videoTrack);
      }
    }
  };

  // Handle user published
  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    callTime.pause();
    setRemoteUserData(user);
    if (mediaType) {
      subscribe(user, mediaType);
    }
  }

  // Handle user joined
  function handleUserJoined(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    joinedUsers[id] = id;
    setTotalJoinedUsres(joinedUsers);
    if (
      Object.keys(remoteUsers).length === 1 &&
      activeCallData?.data?.roomType === "group"
    ) {
      count = 1;
    } else {
      count = count + 1;
    }
    if (Object.keys(remoteUsers).length === 1 && count == 1) {
      start();
    }
    if (mediaType) {
      subscribe(user, mediaType);
    }

    const isMuted = muteStatusRef?.current[user?.uid];

    if (isMuted) {
      document.getElementById(`remote-audio-unmute-${user.uid}`).style.display =
        "none";
      document.getElementById(`remote-audio-mute-${user.uid}`).style.display =
        "block";
    } else {
      document.getElementById(`remote-audio-unmute-${user.uid}`).style.display =
        "block";
      document.getElementById(`remote-audio-mute-${user.uid}`).style.display =
        "none";
    }
  }

  // Handle user left
  async function handleUserLeft(user) {
    muteStatusRef.current[user?.uid] = false;

    let temp = [];
    for (const element of usersArray) {
      if (element?.uid != user?.uid) {
        temp.push(element);
      }
    }
    usersArray = temp;
    setUserData(temp);
    delete remoteUsers[user?.uid];
    delete joinedUsers[user?.uid];
    setTotalJoinedUsres(joinedUsers);

    if (Object.keys(remoteUsers)?.length == 0) {
      if (activeCallData?.data?.type == "video" && localTracks?.videoTrack) {
        await client_call.unpublish(localTracks.videoTrack);
        localTracks.videoTrack.stop();
        localTracks.videoTrack.close();
      }
      endCallFunction();
    }
  }

  const clientLeftFunctions = async () => {
    for (let trackName in localTracks) {
      var track = localTracks[trackName];

      if (track) {
        track.stop();
        track.close();
        localTracks[trackName] = undefined;
      }
    }
    setInterval(() => {
      for (let trackName in localTracks) {
        var track = localTracks[trackName];

        if (track !== null && track !== undefined) {
          track.stop();
          track.close();
          localTracks[trackName] = undefined;
        }
      }
    }, 1000);
    // remove remote users and player views
    remoteUsers = {};

    // leave the channel
    setOnleave(true);
    await client_call.leave();

    setTimeout(() => {
      dispatch(updateCallStatus(false));
      dispatch(setInitiatedCallData(""));
      dispatch(setActiveCallData(""));
      dispatch(setLeftCallData(""));
      setCallEnd(false);
      navigate("/");
    }, 1000);
    console.log("Client successfully left channel.");
  };

  // Mute video function
  async function muteVideo() {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setEnabled(false);
    localTrackState.videoTrackEnabled = false;
    setLocalTrackState({ ...localTrackState, videoTrackEnabled: false });
  }

  useEffect(() => {
    if (callEnd === true) {
      dispatch(setJoinedFromOtherDevice(false));
      clientLeftFunctions();
      if (isScreenShared) {
        stopScreenShare();
      }
    }
  }, [callEnd === true]);

  useEffect(() => {
    if (activeCallData?.callType === "Call Ended!") {
      setCallEnd(true);
      resetAllTracks();
    }
    activeCallDataRef.current = activeCallData;
  }, [activeCallData]);

  const resetAllTracks = async () => {
    if (activeCallData?.data?.type == "video" && localTracks?.videoTrack) {
      localTracks.videoTrack.stop();
      localTracks.videoTrack.close();
      await client_call.unpublish(localTracks.videoTrack);
    }

    if (localTracks?.audioTrack) {
      await client_call.unpublish(localTracks.audioTrack);
      localTracks.audioTrack.stop();
      localTracks.audioTrack.close();
    }

    if (localScreenTrack) {
      await client_call.unpublish(localScreenTrack);
      localScreenTrack.stop();
      localScreenTrack.close();
    }
    await client_call.leave();
  };

  async function paricipantRejectCall() {
    if (
      rejectCallData?.data?.roomType != "group" &&
      Object.keys(totalJoinedUsers)?.length == 0
    ) {
      resetAllTracks();
      endCallInput({
        variables: {
          endCallInput: {
            callId: activeCallData?.data?.callId ?? activeCallData?.data?._id,
            userId: userData._id,
          },
        },
      })
        .then(() => {
          setContactCall(false);
        })
        .catch((error) => console.log("erro at Get Left Call", error));
      setParticipantsInCall([]);
    }
  }

  useEffect(() => {
    if (rejectCallData) {
      paricipantRejectCall();
    }
  }, [rejectCallData]);

  const leftCallFunction = async () => {
    resetAllTracks();
    setOnleave(true);
    setSelfCallEnd(true);
    if (Object.keys(totalJoinedUsers).length > 0) {
      leftCallApi();
    } else {
      endCallFunction();
    }
  };

  const leftCallApi = async () => {
    resetAllTracks();

    input({
      variables: {
        input: {
          _id: activeCallData?.data?.callId ?? activeCallData?.data?._id,
        },
      },
    }).then(async (res) => {
      if (isScreenShared) {
        stopScreenShare();
      }
      setContactCall(false);
      dispatch(updateCreateCall(false));
      dispatch(getCallHistory(true));
      dispatch(setJoinedFromOtherDevice(false));
      dispatch(setIsCallMinimized(false));
      await client_call.leave();
      clientLeftFunctions();
    });
  };

  const endCallFunction = async () => {
    endCallInput({
      variables: {
        endCallInput: {
          callId: activeCallData?.data?.callId || activeCallData?.data?._id,
          userId: userData._id,
        },
      },
    })
      .then(async (res) => {
        if (isScreenShared) {
          stopScreenShare("exit");
        }
        setContactCall(false);
        dispatch(getCallHistory(true));
        dispatch(updateCreateCall(false));
        dispatch(setJoinedFromOtherDevice(false));
        dispatch(setIsCallMinimized(false));
        await client_call.leave();
        clientLeftFunctions();
      })
      .catch((error) => {
        leftCallApi();
      });
  };

  useEffect(() => {
    if (
      callTime.minutes == 1 &&
      callTime.seconds == 30 &&
      initiatedCall &&
      Object.keys(totalJoinedUsers).length === 0
    ) {
      callTime.pause();
      endCallFunction();
    }
  }, [callTime.seconds]);

  useEffect(() => {
    const JoinCallMethod = async () => {
      await join(token, activeCallData?.data?.channelName);
    };
    JoinCallMethod();
  }, [token !== ""]);

  // listners for screen share
  useEffect(() => {
    const socketListner = async (ev) => {
      console.log("screenshareevent", ev);
      if (ev?.type == "start") {
        const _id = ev?.sharer_uid;
        const find = client_call.remoteUsers.find((el) => el?.uid == _id);
        if (activeCallData?.data?.type == "video") {
          await client_call.unpublish(localTracks.videoTrack);
          localTracks?.videoTrack?.close();
          localTracks?.videoTrack?.stop();
        }
        playScreenTracks("remote-screen-track", find?.videoTrack);
        setScreenSharer(_id);
        setIsScreenShared(true);
      } else {
        if (activeCallData?.data?.type == "video") {
          setTimeout(async () => {
            const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
              encoderConfig: {
                width: 320,
                height: 240,
                frameRate: 15,
                bitrateMin: 200,
              },
            });
            setLocalTracks({
              ...localTracks,
              videoTrack: newVideoTrack,
            });

            await client_call.publish(newVideoTrack);
            playScreenTracks("local-player", newVideoTrack);
            client_call.remoteUsers.forEach((el) => {
              if (el?.videoTrack) {
                playScreenTracks(`player-${el?.uid}`, el?.videoTrack);
              }
            });
          }, 1500);
          setIsScreenShared(false);
        } else {
          setIsScreenShared(false);
        }
      }
    };

    socketListen("SCREENSHARE_LISTNER", socketListner);

    return () => {
      socket?.off("SCREENSHARE_LISTNER", socketListner);
    };
  }, []);

  const startScreenShare = async () => {
    try {
      if (activeCallData?.data?.type == "video" && localTracks?.videoTrack) {
        await client_call.unpublish(localTracks?.videoTrack);
        localTracks?.videoTrack?.stop();
        localTracks?.videoTrack?.close();
        setLocalTracks({ ...localTracks, videoTrack: null });
      }

      const screenVideoTrack = await AgoraRTC.createScreenVideoTrack().catch(
        (error) => {
          throw error;
        }
      );

      if (screenVideoTrack) {
        await client_call.publish(screenVideoTrack);
        setLocalScreenTrack(screenVideoTrack);
        setScreenSharer(uid);
        setIsScreenShared(true);

        const particiapnts = [];
        activeCallData?.data?.callParticipants?.forEach((el) => {
          if (el?.callStatus == "accepted") {
            particiapnts.push(el?.userId?._id);
          }
        });

        emit("SCREENSHARE", {
          userId: particiapnts,
          data: {
            type: "start",
            sharer_uid: uid,
            callId: activeCallData?.data?.callId,
          },
        });

        screenVideoTrack?.on("track-ended", async () => {
          stopScreenShareFromBrowser(screenVideoTrack);
        });

        playScreenTracks("local-screen-track", screenVideoTrack);
      }
    } catch (error) {
      if (
        error?.message?.includes("Permission denied") ||
        error?.message?.includes("NotAllowedError")
      ) {
        setScreenShareModalType("permission");
        setScreenShareModal(true);
      } else {
        console.error("Screen sharing failed or cancelled:", error);
      }

      if (activeCallData?.data?.type == "video") {
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 320,
            height: 240,
            frameRate: 15,
            bitrateMin: 200,
          },
        });
        newVideoTrack.play("local-player");

        setLocalTracks({
          ...localTracks,
          videoTrack: newVideoTrack,
        });
        await client_call.publish([localTracks.audioTrack, newVideoTrack]);
      }
    }
  };

  const playScreenTracks = (elementId, screenVideoTrack) => {
    const observer = new MutationObserver((mutations) => {
      const trackElement = document.getElementById(elementId);
      if (trackElement) {
        screenVideoTrack?.play(trackElement);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  const stopScreenShare = async (from) => {
    if (localScreenTrack) {
      const particiapnts = [];
      activeCallData?.data?.callParticipants?.forEach((el) => {
        if (el?.callStatus == "accepted") {
          particiapnts.push(el?.userId?._id);
        }
      });

      emit("SCREENSHARE", {
        userId: particiapnts,
        data: {
          type: "stop",
          sharer_uid: uid,
          callId: activeCallData?.data?.callId,
        },
      });

      localScreenTrack?.stop();
      localScreenTrack?.close();
      await client_call.unpublish(localScreenTrack);
      setLocalScreenTrack(null);
      setIsScreenShared(false);

      if (activeCallData?.data?.type == "video" && !from) {
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 320,
            height: 240,
            frameRate: 15,
            bitrateMin: 200,
          },
        });
        setLocalTracks({
          ...localTracks,
          videoTrack: newVideoTrack,
        });

        newVideoTrack.play("local-player");
        client_call.remoteUsers.forEach((el) => {
          if (el?.videoTrack) {
            playScreenTracks(`player-${el?.uid}`, el?.videoTrack);
          }
        });
        await client_call.publish([localTracks.audioTrack, newVideoTrack]);
      }
    }
  };

  const stopScreenShareFromBrowser = async (screen) => {
    const particiapnts = [];
    activeCallDataRef?.current?.data?.callParticipants?.forEach((el) => {
      if (el?.callStatus == "accepted") {
        particiapnts.push(el?.userId?._id);
      }
    });

    emit("SCREENSHARE", {
      userId: particiapnts,
      data: {
        type: "stop",
        sharer_uid: uid,
        callId: activeCallDataRef?.current?.data?.callId,
      },
    });

    screen?.stop();
    screen?.close();
    client_call.unpublish(screen);
    setLocalScreenTrack(null);
    setIsScreenShared(false);

    if (activeCallDataRef?.current?.data?.type == "video") {
      const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: {
          width: 320,
          height: 240,
          frameRate: 15,
          bitrateMin: 200,
        },
      });
      setLocalTracks({
        ...localTracks,
        videoTrack: newVideoTrack,
      });

      newVideoTrack.play("local-player");
      client_call.remoteUsers.forEach((el) => {
        if (el?.videoTrack) {
          playScreenTracks(`player-${el?.uid}`, el?.videoTrack);
        }
      });
      await client_call.publish([localTracks.audioTrack, newVideoTrack]);
    }
  };

  return (
    <>
      <Toast
        open={toastShow}
        message={t("call_rejected")}
        setShow={setToastShow}
      />
      <div className="container-fluid  bg-[#F4F4F4]  h-full " id="video">
        <CallScreenHeader
          contactCall={contactCall}
          setContactCall={setContactCall}
          seconds={seconds}
          minutes={minutes}
          onleave={onleave}
          setTime={setTime}
          uid={uid}
          totalJoinedUsers={totalJoinedUsers}
        />
        <div
          className={`${
            activeCallData?.data?.roomType === "group" ||
            activeCallData?.data?.roomType === "contact_group"
              ? ""
              : " "
          } relative h-full`}
          style={{ zIndex: "120" }}
        >
          {onleave ? (
            <CallEndScreen
              selfCallEnd={selfCallEnd}
              totalJoinedUsers={totalJoinedUsers}
            />
          ) : (
            <React.Fragment>
              {!isScreenShared ? (
                <div className="row video-group pt-[5rem]">
                  <CallScreenParentContainer
                    totalJoinedUsers={totalJoinedUsers}
                  >
                    <InCallUserInfoBubble
                      onleave={onleave}
                      totalJoinedUsers={totalJoinedUsers}
                      localTrackState={localTrackState}
                    />
                    {activeCallData?.data?.type === "video" ? (
                      <CallVideoScreen
                        totalJoinedUsers={totalJoinedUsers}
                        usersDatas={usersData}
                        muteStatusRef={muteStatusRef}
                      />
                    ) : (
                      <CallAudioScreen
                        totalJoinedUsers={totalJoinedUsers}
                        muteStatusRef={muteStatusRef}
                      />
                    )}
                  </CallScreenParentContainer>
                </div>
              ) : (
                <ScreenShare
                  totalJoinedUsers={totalJoinedUsers}
                  localTrack={localScreenTrack}
                  sharerId={screenSharer}
                  selfUID={uid}
                />
              )}
              <CallScreenFooter
                localTrackState={localTrackState}
                leave={leftCallFunction}
                localTracks={localTracks}
                setLocalTrackState={setLocalTrackState}
                startScreenShare={startScreenShare}
                localScreenTrack={localScreenTrack}
                stopScreenShare={stopScreenShare}
                totalJoinedUsers={totalJoinedUsers}
                isScreenShared={isScreenShared}
                setScreenShareModal={setScreenShareModal}
                setScreenShareModalType={setScreenShareModalType}
              />
            </React.Fragment>
          )}
        </div>
      </div>

      {screenShareModal && (
        <ScreenShareConfirm
          onStart={startScreenShare}
          onStop={stopScreenShare}
          show={screenShareModal}
          setShow={setScreenShareModal}
          confirmType={screenShareModalType}
        />
      )}
    </>
  );
}
