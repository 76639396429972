import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DefaultImageUrl } from "../../../Constant/Constant";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import { client_call } from "../../..";

interface IScreenShare {
  localTrack: any;
  totalJoinedUsers: {
    [key: string]: number;
  };
  sharerId: any;
  selfUID: string
}

const ScreenShare: FC<IScreenShare> = ({
  totalJoinedUsers,
  localTrack,
  sharerId,
  selfUID
}) => {
  const { t } = useTranslation();
  const { activeCallData } = useSelector((state: any) => state?.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state?.user
  );

  const getRemoteUserName = (uid: string, type: "profile" | "name") => {
    const _uid = activeCallData?.data?.roomType != "group" ? uid : uid?.toString()?.slice(0, 6) 

    const participant = activeCallData?.data?.callParticipants?.find(
      (el) => el?.uid == _uid
    );

    if (type == "name") {
      const contact = formattedContact?.[participant?.userId?._id];
      let name = "";
      if (selfUID == uid) {
        name = t("you")
      } else if (contact?.firstName) {
        name = contact?.firstName + " " + contact?.lastName;
      } else {
        name = participant?.userId?.phone;
      }
      return name;
    } else {
      return DefaultImageUrl + participant?.userId?.profile_img;
    }
  };

  return (
    <div className="h-[calc(100vh-80px-77px)] w-full absolute top-[75px]">
      <div className="flex w-full h-full">
        <div className="border w-full h-full bg-gray-800 relative">
          <div className="flex gap-2 items-center absolute top-0 text-white z-10 py-[10px] px-[20px]">
            <CastOutlinedIcon fontSize="small" />
            <div className="font-semibold">
              {getRemoteUserName(sharerId, "name")} Presenting
            </div>
          </div>

          {localTrack && (
            <div id="local-screen-track" className="h-full w-full"></div>
          )}

          {/* for remote user */}
          <div id="remote-screen-track" className="h-full w-full"></div>
        </div>

        <div
          style={{ scrollbarWidth: "thin" }}
          className="h-full overflow-auto bg-gray-100 px-2 py-1 w-[20%]"
        >
          <div className="grid gap-3">
            <div className="relative flex justify-center items-center h-[250px] bg-[#B4E8FF] w-full rounded-md">
              <div className="absolute left-0 top-[18px] py-1 px-4 text-sm rounded-r-md bg-white text-black">
                {t("you")}
              </div>
              <div className="h-[100px] w-[100px] rounded-full">
                <img
                  src={DefaultImageUrl + userData?.profile_img}
                  alt="profile_picture"
                  className="rounded-full h-full w-full"
                />
              </div>
            </div>

            {Object.keys(totalJoinedUsers).map(
              (user: string, index: number) => {
                return (
                  <div
                    key={index}
                    className="relative flex justify-center items-center h-[250px] bg-[#B4E8FF] w-full rounded-md"
                  >
                    <div className="absolute max-w-[60%] left-0 top-[18px] py-1 px-4 text-sm rounded-r-md bg-white text-black">
                      {getRemoteUserName(user, "name")}
                    </div>

                    <div className="h-[100px] w-[100px] rounded-full">
                      <img
                        alt="profile_picture"
                        className="rounded-full h-full w-full"
                        src={getRemoteUserName(user, "profile")}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenShare;
