interface InputProps {
  type: string;
  value?: any;
  onChange?: any;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
}

const TextInput = ({
  type,
  label,
  value,
  onChange,
  disabled,
  placeholder,
  autoFocus,
}: InputProps) => {
  return (
    <div className="text-left w-full">
      {label && (
        <label className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white">
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete="off"
        className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
    </div>
  );
};

export default TextInput;
