import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import { DefaultImageUrl, GroupDefault } from "../../Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import Call from "../../Assets/Images/call.png";
import VideoCall from "../../Assets/Images/videoCall.png";
import moment from "moment";
import { Checkbox, CircularProgress, styled } from "@mui/material";
import { authCllient } from "../../Graphql/authClient";
import { ADD_PARTICIPANTS, CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { setInitiatedCallData, setMemberAddedInCall, updateCallStatus, updateCreateCall } from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { CALL_PARTICIPANTS } from "../../Graphql/Queries";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 100,
  width: 25,
  height: 25,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#33ccff",
  paddingTop: 2,
  paddingLeft: 2,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#33ccff",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const CallParticipantsList = ({ setMemberCall, totalJoinedUsers }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { activeCallData, memberAddedInCall } = useSelector(
    (state: any) => state.chat
  );
  const [callData, setCallData] = useState([])
  const [load, setLoad] = useState(false);
  const [id, setids] = useState('');
  const { comonUsers, userData, blockedMember, formattedContact } = useSelector((state: any) => state.user);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const [groupCall, setGroupCall] = useState(false);
  const [selectedUseres, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [changeCallStatus] = useMutation(ADD_PARTICIPANTS);

  const addParticipants = (type: any, users: any) => {
    const participants = [];
    if (users.length > 0) {
      users?.forEach((item: any) => {
        participants.push(item?.id);
      });
    } else {
      selectedUseres?.forEach((item: any) => {
        participants.push(item?.id);
      });
    }

    if (type === "load") {

      setLoad(true);
    }

    setDisabledCallButton(true);
    dispatch(updateCreateCall(true));
    authCllient
      .mutate({
        mutation: ADD_PARTICIPANTS,
        variables: {
          input: {
            callId: activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id,
            participants: participants
          },
        },
      })
      .then((res) => {
        if (type === "load") {
          const idsarray = [];
          idsarray.push(users[0]?.id);
          dispatch(setMemberAddedInCall(idsarray));

        }
        setMemberCall(false);
        setLoad(false);
      })
      .catch((error) => console.log("error at Create call", error))

  };

  function getCallTimediff(date: any) {

    const currentTime = moment();
    const callStartTime = moment(date);
    // Add 90 seconds to the current time to get the end time
    const callEndTime = callStartTime.clone().add(90, 'seconds');

    // Compare the current time with the end time
    if (currentTime.isAfter(callEndTime)) {
      return true;
      console.log("90 seconds have passed.");
    } else {
      return false;
      console.log("90 seconds have not passed yet.");
    }
  }


  function getCallStatus(item: any) {
    const status = activeCallData?.data?.callParticipants.find((el: any) => el.userId?._id === item?.userId?._id)?.callStatus
    const users = Object.keys(totalJoinedUsers).map((user: any) => (user.slice(0, 6)));
    const uid = users.filter((el: any) => el === item.uid.toString()).length

    if (!memberAddedInCall.includes(item?.userId?._id) && status === 'accepted' && item?.userId?._id !== userData?._id && uid === 0) {
      return <span className="text-red-500">{t('left-call')}</span>
    }
    else {
      if (memberAddedInCall.includes(item?.userId?._id) && getCallTimediff(item.createdAt) === false) { return <span>{t("ringing")}</span> }
      else {
        return <span className={status === 'accepted' ? 'text-green-500' : status === 'missed' || status === 'rejected' ? "text-red-500" : ""}>
          {status == "missed" ? t("missed") : t(status)}
        </span>
      }
    }
  }

  useEffect(() => {
    setGroupCall(activeCallData?.data?.roomType === 'group')
  }, [activeCallData])

  useEffect(() => {
    console.log(activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id)
    authCllient
      .query({
        query: CALL_PARTICIPANTS,
        variables: {
          input: {
            _id: activeCallData?.data?.callId ? activeCallData?.data?.callId : activeCallData?.data?._id
          },
        },
      })
      .then((res: any) => {
        setCallData(res.data?.callParticipants?.participants)
      })
      .catch((error) => console.log("error at fetch call history", error));
  }, [])

  console.log(callData?.map((el: any) => ({ ...el, name: formattedContact[el?.userId?._id] ? formattedContact[el?.userId?._id]?.firstName.toLowerCase() + " " + formattedContact[el?.userId?._id]?.lastName.toLowerCase() : `${el?.phone}` })))
  return (
    <React.Fragment>
      <div className="h-screen ">
        <div className="flex p-3 shadow-lg justify-between">
          <div
            onClick={() => {
              setSelectedUsers([]);
              setMemberCall(false);
            }}
          >
            <ArrowBackIosIcon fontSize="small" /> {activeCallData?.data?.roomType === "group" ? activeCallData?.data?.roomId?.name : t("new_call")}
          </div>

        </div>
        <div className="h-[93vh] overflow-y-scroll">

          <div className="w-[380px] text-center my-7">
            {
              callData.length === 0 ?
                <CircularProgress />
                :
                callData?.map((el: any) => ({ ...el, name: formattedContact[el?.user_id] ? formattedContact[el?.user_id]?.firstName.toLowerCase() + " " + formattedContact[el?.user_id]?.lastName.toLowerCase() : `${el?.phone}` })).sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  } else if (a.name > b.name) {
                    return 1;
                  } else {
                    return 0;
                  }
                }).map((item: any, index: number) => {
                  const callStatus = getCallStatus(item)
                  return (
                    <div>

                      {(
                        <div>
                          <div className="flex p-3 justify-between items-center ">
                            <div className="flex items-center justify-between gap-2 w-full">
                              <div className="flex">
                                <div className="w-[45px] h-[45px]">
                                  <img
                                    alt="call"
                                    src={DefaultImageUrl + item?.userId?.profile_img}
                                    className="h-full w-full rounded-full"
                                  />
                                </div>
                                <div className="my-auto ml-3">
                                  {item?.userId?._id === userData?._id ?
                                    <div className="ml-[-32px]">{t("you")}</div>
                                    : formattedContact[item?.userId?._id] ? <div>
                                      {formattedContact[item?.userId?._id]?.firstName} {formattedContact[item?.userId?._id].lastName}
                                    </div> : <div>{item.userId?.phone}</div>}
                                    {item?.userId?._id === userData?._id ? (
                                      <div className="text-sm text-left text-green-500 capitalize">
                                        {t("accepted")}
                                      </div>
                                    ):(
                                      <div className="text-sm text-left text-slate-500 capitalize">
                                        {callStatus}
                                      </div>
                                    )}
                                </div>
                              </div>
                              {memberAddedInCall.includes(item?.userId?._id) && getCallTimediff(item.createdAt) === false ? ""
                                : item?.userId?._id !== userData?._id && !Object.keys(totalJoinedUsers).map((el: any) => el.length > 6 ? el.slice(0, 6) : el).includes(`${item.uid}`) && (
                                  <div
                                    onClick={(event) => {
                                      if (!load) {
                                        setids(item?.userId?._id);
                                        setSelectedUsers([{ id: item?.userId?._id }])
                                        addParticipants('load', [{ id: item?.userId?._id }]);
                                      }
                                    }}
                                    className="border bg-[#33ccff] text-white cursor-pointer capitalize items-center rounded-md flex h-fit justify-end px-3 py-1"
                                  >
                                    {load && item?.userId?._id === id ? t("calling") : t('call').toLowerCase()}
                                  </div>
                                )}
                            </div>

                          </div>
                          <hr className="m-3 mx-6" />
                        </div>
                      )}
                    </div>
                  );
                })}
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default CallParticipantsList;
