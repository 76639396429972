import { useSelector } from "react-redux";
import audioImg from "../../Assets/Images/audio.png";
import audioUnmute from "../../Assets/Unmute.png";
import videoImg from "../../Assets/unmuteVideo.png";
import videoMute from "../../Assets/Videooff.png";
import closeImg from "../../Assets/Close.png";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import StopScreenShareOutlinedIcon from "@mui/icons-material/StopScreenShareOutlined";
import React, { Fragment } from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CallScreenFooter({
  localTrackState,
  leave,
  localTracks,
  setLocalTrackState,
  localScreenTrack,
  totalJoinedUsers,
  isScreenShared,
  setScreenShareModalType,
  setScreenShareModal,
}) {
  const { t } = useTranslation();
  const { activeCallData } = useSelector((state: any) => state.chat);

  // Mute audio click
  const muteMic = () => {
    if (localTrackState.audioTrackEnabled) {
      muteAudio();
    } else {
      unmuteAudio();
    }
  };

  // Mute video click
  const muteVideoPlayer = () => {
    if (localTrackState.videoTrackEnabled) {
      document.querySelector<HTMLElement>("#local-player video").style.display =
        "none";
      muteVideo();
    } else {
      document.querySelector<HTMLElement>("#local-player video").style.display =
        "block";
      unmuteVideo();
    }
  };

  // Mute video function
  async function muteVideo() {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setEnabled(false);
    localTrackState.videoTrackEnabled = false;
    setLocalTrackState({ ...localTrackState, videoTrackEnabled: false });
  }

  // Mute audio function
  async function muteAudio() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setEnabled(false);
    localTrackState.audioTrackEnabled = false;
    setLocalTrackState({ ...localTrackState, audioTrackEnabled: false });
  }

  // Unmute audio function
  async function unmuteAudio() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setEnabled(true);
    localTrackState.audioTrackEnabled = true;
    setLocalTrackState({ ...localTrackState, audioTrackEnabled: true });
  }

  // Unmute video function
  async function unmuteVideo() {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setEnabled(true);
    localTrackState.videoTrackEnabled = true;
    setLocalTrackState({ ...localTrackState, videoTrackEnabled: true });
  }

  const handleStartScreen = () => {
    setScreenShareModalType("start");
    setScreenShareModal(true);
  };

  const handleStopScreen = () => {
    setScreenShareModalType("stop");
    setScreenShareModal(true);
  };

  return (
    <>
      <div id="join-form" className="mt-4">
        <div className="button-group mt-3">
          {Object.keys(totalJoinedUsers).length > 0 && (
            <Fragment>
              <button
                id="mic-btn"
                type="button"
                className="btn btn-live btn-sm"
                onClick={muteMic}
              >
                {localTrackState.audioTrackEnabled ? (
                  <>
                    {" "}
                    <img
                      className="w-[45px] cursor-pointer mx-2"
                      src={audioUnmute}
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="w-[45px] cursor-pointer mx-2"
                      src={audioImg}
                    />{" "}
                  </>
                )}
              </button>

              {activeCallData?.data?.type === "video" && (
                <button
                  id="video-btn"
                  type="button"
                  className="btn btn-live btn-sm"
                  onClick={muteVideoPlayer}
                >
                  {localTrackState.videoTrackEnabled ? (
                    <>
                      <img
                        className="w-[45px] cursor-pointer mx-2"
                        src={videoImg}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="w-[45px] cursor-pointer mx-2"
                        src={videoMute}
                      />
                    </>
                  )}
                </button>
              )}
            </Fragment>
          )}

          <button
            id="leave"
            type="button"
            className="w-[45px] cursor-pointer mx-2"
            onClick={leave}
          >
            <img src={closeImg} />
          </button>

          <React.Fragment>
            {Object.keys(totalJoinedUsers).length > 0 && (
              <React.Fragment>
                {!isScreenShared && (
                  <Tooltip title={t("startscreenshare")}>
                    <button
                      onClick={handleStartScreen}
                      // onClick={startScreenShare}
                      className="w-[45px] cursor-pointer mx-2 rounded-full bg-[#115ABB] text-white"
                    >
                      <ScreenShareOutlinedIcon />
                    </button>
                  </Tooltip>
                )}

                {localScreenTrack && (
                  <Tooltip title={t("stopscreenshare")}>
                    <button
                      onClick={handleStopScreen}
                      // onClick={stopScreenShare}
                      className="w-[45px] cursor-pointer mx-2 rounded-full bg-red-500 text-white"
                    >
                      <StopScreenShareOutlinedIcon />
                    </button>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    </>
  );
}
