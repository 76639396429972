import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

interface ForceLogoutDialogProps {
  show: boolean;
  setShow: (val: boolean) => void;
  onSubmit: () => void;
  message: string;
}

const ForceLogoutDialog: FC<ForceLogoutDialogProps> = ({
  show,
  setShow,
  message,
  onSubmit,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          minWidth: "40%",
        },
      }}
    >
      <DialogTitle>Force Logout ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning">{message}</Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForceLogoutDialog;
