import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB0oU09IoZDiabv6-uyMx52vM-lyhjx2vc",
    authDomain: "analog-sum-332609.firebaseapp.com",
    projectId: "analog-sum-332609",
    storageBucket: "analog-sum-332609.appspot.com",
    messagingSenderId: "13504575413",
    appId: "1:13504575413:web:66b9a9afabd64642880bfc",
    measurementId: "G-EEKHLLV9V1"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

//  const analytics = getAnalytics(app);