import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ComonUsersDataTypes,
  NoneInitiatedRoomsType,
  RoomsDataType,
  ShareMediaDataTypes,
} from "../../Types/Types";
import { DefaultImageUrl, ShareMedia } from "../../Constant/Constant";
import { FaUserAlt } from "react-icons/fa";
import { MentionsInput, Mention } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { UPLOAD_CHAT_FILE } from "../../Graphql/Mutations";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import msgButton from "../../Assets/Images/msgButton.png";
import {
  appendOldChats,
  resetActiveRoomChats,
  setActiveRoomData,
  setIsRoomLoading,
  setTotoalChatsCount,
} from "../../Redux/ChatSlice";
import Toast from "../../coreComponent/Toast";
import { setScrollBottom } from "../../Redux/UserSlice";
import { FetchDraft, StoreDraft } from "./DraftMessage";
import { emit, socketListen } from "../../Socket";
import msgsend from "../../Assets/sound/sendchat.mp3";
import { useReactMediaRecorder } from "react-media-recorder";
import SendIcon from "@mui/icons-material/Send";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import ScheduleIcon from "../../Assets/Images/schedule_msg.png";
import AudioIcon from "../../Assets/Images/AudioIcon.png";
import dayjs from "dayjs";
import pauseImg from "../../Assets/pause.png";
import playImg from "../../Assets/play_arrow.png";
import stopImg from "../../Assets/stop_circle.png";
import ReplyFooter from "./ReplyFooter";
import SelectedMediaForUploadContainer from "./SelectedMediaForUploadContainer";
import SelectedMediaCaptionBar from "./SelectedMediaCaptionBar";
import ForwardMessageBar from "./ForwardMessageBar";
import { useTranslation } from "react-i18next";
import { IoIosNotifications } from "react-icons/io";
import { openNewEventDialog } from "../../Redux/EventSlice";
import EventDialog from "../../Components/Calendar/dialogs/event/EventDialog";
import {
  ParticipantAcceptStatus,
  ReminderParticipantRole,
} from "../../Components/Calendar/types/enum";
import ScheduleModal from "./schedule/ScheduleModal";
import groupContact from "../../utils/groupContact";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import PollModal from "./polls/PollModal";

export default function ChatFooter({
  selectedMessages,
  setSelectedMessages,
  replyMessage,
  setReplyMessage,
  setIsChangeMessageFooter,
  isChangeMessageFooter,
  setShowMessageCheckboxes,
  replyFooterShow,
  setReplyFooterShow,
}) {
  const shareMediaRef = useRef<any>(null);
  const [progress, setProgress] = useState(0);
  const [second, setSecond] = useState<any>("00");
  const [minute, setMinute] = useState<any>("00");
  const [shareMediaShow, setShareMediaShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [roomsUpdated, setRoomsUpdated] = useState([]);
  const [clipOpen, setClipOpen] = useState(false);
  const [mentinoParticipants, setMentinoParticipants] = useState<any>([]);
  const { activeRoomData, totalReceivedChatsCount, activeRoomChats, rooms } =
    useSelector((state: any) => state.chat);
  const {
    userData,
    userContacts,
    formattedContact,
    formattedParticipantsOfActiveRoom,
  } = useSelector((state: any) => state.user);
  const [filesModal, setFilesModal] = useState(false);
  const [fileUploadType, setFileUploadType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [duration, setDuration] = useState<any>(0);
  const [isActive, setIsActive] = useState(false);
  const [loadingtime, setLoadingTime] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileCaptionName = useRef(null);
  const [isAudioFooter, setIsAudioFooter] = useState(false);
  const [audioRecordedFile, setAudioRecordedFile] = useState<any>("");
  const [audioUploadedFile, setAudioUploadedFile] = useState<any>("");
  const eventName =
    activeRoomData?.type === "broadcast" ? "sendBroadcastChat" : "sendChat";
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [fileUploadError, setFileUploadError] = useState<any>("");
  const [isForwardButtonDisable, setIsForwardButtonDisable] = useState(false);
  const [fileUpload, { data, loading, reset }] = useMutation(UPLOAD_CHAT_FILE);
  const [userContactData, setUserContactData] = useState<any>(
    userContacts
      .map((el: any) => ({
        ...el,
        name:
          el.firstName !== ""
            ? el?.firstName.toLowerCase() + " " + el?.lastName.toLowerCase()
            : `${el.phone.replaceAll("+", "")}`,
      }))
      .sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      })
  );
  const [contactsModalShow, setContactsModalShow] = useState(false);
  const [contactShareLoading, setContactShareLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [message, setMessage] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [messageSend, setMessageSend] = useState(false);
  const userInfo =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        )
      : activeRoomData?.participants?.length === 1
      ? activeRoomData?.participants[0]
      : null;
  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userData?._id
    );
  const isUserBlockedYou =
    activeRoomData?.type !== "group" && userInfo?.left_at !== 0 ? true : false;
  const isRoomLeft = userProfileImg?.left_at !== 0;

  const isEveyOneCanMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "sendMessage" && item?.permit === "common"
    );

  const canYouSendMessage =
    (!isEveyOneCanMessage && userProfileImg?.user_type === "admin") ||
    isEveyOneCanMessage;
  const isRoomBlocked =
    userData?.blockedRooms?.length > 0
      ? userData?.blockedRooms?.some((item: any) =>
          item?.room_Id === activeRoomData?._id ? true : false
        )
      : false;
  const [forwardToRooms, setForwardToRooms] = useState<any>([]);
  const [webcamImage, setWebcamImage] = useState("");
  const webcamRef = useRef<any>(null);
  const [forwardModalShow, setForwardModalShow] = useState(false);
  const [webcamObjFile, setWebcameObjFile] = useState<any>("");
  const [showEveryoneOption, setShowEveryoneOption] = useState(false);
  const [deleteMessageModalShow, setDeleteMessageModalShow] = useState(false);

  const handleChange = (
    e: any,
    newValue: any,
    newPlainTextValue: any,
    mentions: any
  ) => {
    setMessage(newValue);
    draftMessageHandler(newValue);
  };

  const [showSchedule, setShowSchedule] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [showPollModal, setShowPollModal] = useState(false);
  const isMySelf =
    activeRoomData?.participants?.length == 1 &&
    activeRoomData?.participants?.[0]?.user_id == userData?._id;

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);
  useEffect(() => {
    if (rooms.length > 0) {
      let temp: any = [];
      rooms?.forEach((item: any) => {
        const element = { ...item };
        const userInfo = element?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        );
        const participantContactDetail = formattedContact[userInfo?.user_id];
        if (element.type === "individual") {
          element["name"] = formattedContact[userInfo?.user_id]?.firstName
            ? participantContactDetail?.firstName.toLowerCase() +
              " " +
              participantContactDetail?.lastName.toLowerCase()
            : `${userInfo?.phone}`;
        }
        temp.push(element);
      });
      setRoomsUpdated(temp);
    }
  }, [rooms]);
  const draftMessageHandler = async (message: any) => {
    const RetriveDraftData =
      (await FetchDraft()) ??
      JSON.stringify([
        {
          id: activeRoomData?._id,
          message: message,
        },
      ]);
    const IsDraftExit = JSON.parse(RetriveDraftData).findIndex(
      (item: { id: any }) => item.id === activeRoomData?._id
    );
    if (IsDraftExit === -1) {
      const pushInIt = JSON.parse(RetriveDraftData) ?? [];
      pushInIt.push({ id: activeRoomData?._id, message: message });
      StoreDraft(JSON.stringify(pushInIt));
    } else {
      const pushInIt = JSON.parse(RetriveDraftData) ?? [];

      pushInIt.splice(IsDraftExit, 1, {
        id: activeRoomData?._id,
        message: message,
      });

      StoreDraft(JSON.stringify(pushInIt));
    }
  };

  useEffect(() => {
    if (webcamObjFile !== "") {
      fileUpload({
        variables: {
          file: webcamObjFile,
          thumbnail: null,
          input: { roomId: "sfsf3424", _id: userData?._id },
        },
      });
    }
    // eslint-disable-next-line
  }, [webcamObjFile]);

  useEffect(() => {
    if (selectedFiles[0]) {
      fileUpload({
        variables: {
          file: selectedFiles[0],
          thumbnail: null,
          input: { roomId: activeRoomData?._id, _id: userData?._id },
        },
      }).catch((error) => {
        setFileUploadError(error?.message);
      });
    }
    // eslint-disable-next-line
  }, [selectedFiles]);

  useEffect(() => {
    const participantsData = activeRoomData?.participants?.filter(
      (elem: any) => elem?.user_id !== userData?._id && elem?.left_at == 0
    );
    if (participantsData?.length > 0) {
      const userMentionData = participantsData.map((myUser) => ({
        id: myUser.user_id,
        display:
          formattedContact[myUser.user_id] !== undefined
            ? `${formattedContact[myUser.user_id].firstName} ${
                formattedContact[myUser.user_id].lastName
              }`
            : `${myUser.phone}`,
        avatar: myUser.profile_img,
      }));
      setMentinoParticipants(userMentionData);
    }

    handleResetFileSend();
  }, [activeRoomData]);

  useEffect(() => {
    const checkIsClickOutside = (e: any) => {
      if (
        shareMediaShow &&
        shareMediaRef.current &&
        !shareMediaRef.current.contains(e.target)
      ) {
        setShareMediaShow(false);
      }
    };
    document.addEventListener("mousedown", checkIsClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIsClickOutside);
    };
  }, [shareMediaShow]);

  const handleFilesUpload = (e: any) => {
    const file = e.target.files[0];

    const fileSizeInMb = file.size / 1000000;
    if (file) {
      // dispatch(setIsRoomLoading(true))
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        // Get the duration of the audio in seconds
        const durationInSeconds = audio.duration;
        setDuration(durationInSeconds * 1000);
      });

      audio.src = URL.createObjectURL(file);
    }
    if (fileSizeInMb > 16) {
      alert(t("size_validation"));
      setFilesModal(false);
    } else {
      //      const newFile=[{...e.target.files[0],name:e.target.files[0]?.name.replaceAll(' ','-')}]

      const newFile = new File(
        [e.target.files],
        `${e.target.files[0].name.replaceAll(" ", "_")}`,
        {
          type: e.target.files[0].type,
        }
      );
      setSelectedFiles(Object.values(e.target.files));
      setFilesModal(true);
      setShareMediaShow(false);
    }
    setTimeout(() => {
      setShareMediaShow(false);
    }, 100);
  };

  const handleSelectContacts = (item: ComonUsersDataTypes, e: any) => {
    if (e.target.checked) {
      const temp = [...selectedContacts];

      temp.push({
        _id: item?.userId !== null ? item?.userId?._id : null,
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        profile_img: item?.userId !== null ? item?.userId?.profile_img : null,
        localId: item?.localId,
        blocked: item?.blocked,
        lastSeen: item?.userId?.lastSeen,
        status: item?.userId?.status,
      });

      setSelectedContacts(temp);
    } else {
      const filtered = selectedContacts?.filter(
        (elem: any) => elem?.phone !== item?.phone
      );
      setSelectedContacts(filtered);
    }
  };

  const handleSearchUsers = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleShareContact = () => {
    emit("shareContact", {
      rooms: [activeRoomData?._id],
      contacts: groupContact(selectedContacts),
    });
    setSelectedContacts([]);
    setToastMessage(t("contact_shared"));
    setToastShow(true);
    dispatch(setTotoalChatsCount(totalReceivedChatsCount + 1));
    let tempActiveRoomData = { ...activeRoomData };
    tempActiveRoomData["totalChats"] += 1;
    dispatch(setActiveRoomData(tempActiveRoomData));
    setContactShareLoading(false);
    dispatch(setScrollBottom(true));
    setContactsModalShow(false);
  };

  const handleChatSend = () => {
    const regex = /@\[(.*?)\]\((.*?)\)/g;

    const processedMessage = message.replace(regex, (_, display, id) => {
      const participant = activeRoomData?.participants?.find(
        (p) => p?.user_id == id
      );
      const show = participant?.phone ? participant?.phone : display;
      return `@[${show}](${id}) `;
    });

    if (message !== "" && message !== " ") {
      // dispatch(setIsRoomLoading(false))
      let messageType = "text";
      if (replyFooterShow) {
        emit(eventName, {
          data: {
            roomId: activeRoomData?._id,
            type: messageType,
            fileUrl: null,
            isForwarded: false,
            message: processedMessage?.trim(),
            _id: "sadfsf213s",
            fontStyle: "",
            thumbnail: "",
          },
          reply_msg: {
            cid: replyMessage?._id,
            chat_type: replyMessage?.type,
            sender: replyMessage?.sender,
            message: replyMessage?.message,
            url: replyMessage?.type === "text" ? null : replyMessage?.fileURL,
            created_at: replyMessage?.created_at,
          },
        });

        setMessageSend(true);
      } else {
        const localId = Math.random() * 100;
        const data = {
          roomId: activeRoomData?._id,
          type: messageType,
          fileUrl: null,
          isForwarded: false,
          message: processedMessage?.trim(),
          _id: `${localId}`,
          fontStyle: "",
          thumbnail: "",
        };

        emit(eventName, {
          data,
          reply_msg: null,
        });
        setMessageSend(true);
      }

      setMessage("");
      setReplyMessage("");
      setReplyFooterShow(false);
      dispatch(setTotoalChatsCount(totalReceivedChatsCount + 1));

      dispatch(setScrollBottom(true));
      setMessageSend(false);
      const draftData = JSON.parse(localStorage.getItem("@DraftData"));
      const updatedDraftData = draftData?.map((ele: any) => {
        if (ele?.id === activeRoomData?._id) {
          return {
            ...ele,
            message: "",
          };
        }
        return ele;
      });
      /*    if(document.querySelector('#scrollableDiv')){
               var height = document.querySelector('#scrollableDiv').scrollHeight;
               document.querySelector('#scrollableDiv').scroll(0, height)}
    */
      localStorage.setItem("@DraftData", JSON.stringify(updatedDraftData));
    }
  };

  const onkeyFunc = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault();
      handleChatSend();
    }

    if (e.code == "PageDown" || e.code == "PageUp") {
      e.preventDefault();
    }
  };

  const handleShowEveryoneOption = () => {
    const isMyId = selectedMessages?.senderId?.every(
      (item: any) => item === userData?._id
    );

    const gettingTimes = selectedMessages?.time?.map((item: any) =>
      dayjs(item).toISOString()
    );

    const isCorrectTime = gettingTimes?.every(
      (item: any) => item && dayjs().diff(item, "minute") < 10
    );

    if (isMyId && isCorrectTime) {
      setShowEveryoneOption(true);
    } else {
      setShowEveryoneOption(false);
    }
  };

  const handleFileSend = () => {
    if (data?.UploadChatFile) {
      const { filename, type } = data?.UploadChatFile?.data;
      const localId = Math.random() * 100;
      const payload: any = {
        roomId: activeRoomData?._id,
        type: fileUploadType === "document" ? "DOCUMENT" : type,
        fileURL: filename,
        duration: parseInt(duration),
        isForwarded: false,
        message: fileCaptionName.current?.value,
        _id: `${localId}`,
        fontStyle: "",
        thumbnail: "",
      };

      console.log(
        eventName,
        {
          data: payload,
          reply_msg: null,
        },
        "events"
      );
      emit(eventName, {
        data: payload,
        reply_msg: null,
      });

      setFilesModal(false);
      /*  dispatch(setTotoalChatsCount(totalReceivedChatsCount + 1));
             let tempActiveRoomData = { ...activeRoomData };
             tempActiveRoomData["totalChats"] += 1;
             dispatch(setActiveRoomData(tempActiveRoomData)); */
      dispatch(setScrollBottom(true));
      setFileUploadType("");
      reset();
      setWebcamImage("");
      setWebcameObjFile("");
      setSelectedFiles([]);
    }
    // dispatch(setIsRoomLoading(false))
    var height = document.querySelector("#scrollableDiv").scrollHeight;
    document.querySelector("#scrollableDiv").scroll(0, height);
  };
  const handleMessagesDelete = (type: string) => {
    let tempChats = [...activeRoomChats];

    if (type === "me") {
      const updatedChats = tempChats.filter(
        (item: any) => !selectedMessages?.msgId?.includes(item?._id)
      );

      dispatch(setScrollBottom(false));
      dispatch(appendOldChats(updatedChats));
    } else {
      emit("deleteChat", {
        roomId: activeRoomData?._id,
        cid: selectedMessages?.msgId,
        type: type,
      });
      const updatedChats = tempChats?.map((item: any) => {
        if (selectedMessages?.msgId?.includes(item?._id)) {
          return {
            ...item,
            deleted: [
              {
                deleted_at: 1682680476181,
                type: "everyone",
                user_id: userData?._id,
              },
            ],
            message: "You deleted this message",
          };
        }
        return item;
      });

      dispatch(setScrollBottom(false));
      dispatch(resetActiveRoomChats(updatedChats));
    }
    emit("deleteChat", {
      roomId: activeRoomData?._id,
      cid: selectedMessages?.msgId,
      type: type,
    });
    let tempActiveRoomData = { ...activeRoomData };
    tempActiveRoomData["totalChats"] -= selectedMessages?.msgId?.length;
    dispatch(setActiveRoomData(tempActiveRoomData));
    setDeleteMessageModalShow(false);
    setSelectedMessages({ msgId: [], senderId: [], time: [] });
    setShowMessageCheckboxes(false);
    setIsChangeMessageFooter(false);
  };

  const handleSelectForwarded = (e: any, roomId: string, userId: any) => {
    if (e.target.checked) {
      setForwardToRooms((old: any) => [...old, { roomId, userId }]);
    } else {
      const temp = forwardToRooms?.filter((one: any) => {
        if (roomId) {
          return roomId !== one.roomId;
        } else {
          return userId !== one.userId;
        }
      });
      setForwardToRooms(temp);
    }
  };

  const nonInitiatedRooms = () => {
    const comonUsers: any = Object.values(formattedContact);
    const individualRoom: any = rooms?.filter(
      (one: any) => one?.type === "individual"
    );

    let result = [];

    for (const user of comonUsers) {
      const isExist = individualRoom.find((one: any) => {
        const existInparticipant = one.participants.find(
          (p: any) => p.user_id === user?.userId?._id
        );
        return existInparticipant ? true : false;
      });
      if (!isExist) {
        result.push(user);
      }
    }
    return result;
  };

  const handleForwardMessages = async () => {
    setIsForwardButtonDisable(true);
    let roomIds: any = [];

    for (let one of forwardToRooms) {
      await new Promise(async (resolve: any) => {
        if (one.roomId) {
          roomIds.push(one.roomId);
          resolve();
        } else {
          emit("createRoom", { users: [one?.userId], type: "individual" });
          await new Promise((reso: any) => {
            socketListen("createRoom", (data: any) => {
              roomIds.push(data?.msg?.roomId);
              reso();
            });
          });
        }
        resolve();
      });
    }

    emit("forwardChat", { messages: selectedMessages?.msgId, rooms: roomIds });
    setForwardToRooms([]);
    setSelectedMessages({
      msgId: [],
      senderId: [],
      time: [],
    });
    setForwardModalShow(false);
    setShowMessageCheckboxes(false);
    setIsChangeMessageFooter(false);
    setIsForwardButtonDisable(false);
  };
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    // here replaced audio/wav to audio/webm
    mediaRecorderOptions: { mimeType: "audio/webm" },
    blobPropertyBag: {
      type: "audio/webm",
    },

    // echoCancellation: true
  });

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  const handleAudioSend = () => {
    const { filename, type } = audioUploadedFile;
    const localId = Math.random() * 100;
    const data = {
      roomId: activeRoomData?._id,
      type: type,
      fileURL: filename,
      isForwarded: false,
      duration: parseInt(duration),
      message: message?.trim(),
      _id: `${localId}`,
      fontStyle: "",
      thumbnail: "",
    };

    emit(eventName, {
      data,
      reply_msg: null,
    });

    setAudioRecordedFile("");
    setAudioUploadedFile("");
    setIsAudioFooter(false);
    // dispatch(setIsRoomLoading(false))
    dispatch(setTotoalChatsCount(totalReceivedChatsCount + 1));
    let tempActiveRoomData = { ...activeRoomData };
    tempActiveRoomData["totalChats"] += 1;
    dispatch(setActiveRoomData(tempActiveRoomData));
    dispatch(setScrollBottom(true));
  };

  const uploadFile = async () => {
    let file = await fetch(mediaBlobUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File(
            [blobFile],
            `${Math.floor(Math.random() * (10000 * 10000))}.mp3`,
            {
              type: "audio/mp3",
            }
          )
      );
    const audio = new Audio(URL.createObjectURL(file));
    audio.addEventListener("loadedmetadata", () => {
      // Get the duration of the audio in seconds
      const durationInSeconds = audio.duration;
      setDuration(durationInSeconds * 1000);
    });
    fileUpload({
      variables: {
        file: file,
        thumbnail: null,
        input: { roomId: activeRoomData?._id, _id: userData?._id },
      },
    }).then((res) => {
      setLoadingTime(false);
      // clearBlobUrl();
      setAudioUploadedFile(res?.data?.UploadChatFile?.data);
    }); // Here is the base64 string
  };
  useEffect(() => {
    if (mediaBlobUrl !== undefined && mediaBlobUrl !== null) {
      setLoadingTime(true);
      uploadFile();
    }
    // eslint-disable-next-line
  }, [mediaBlobUrl]);

  useEffect(() => {
    // const timer = setInterval(() => {
    //     setProgress((prevProgress) =>
    //         prevProgress >= 100 ? 0 : prevProgress + 10
    //     );
    // }, 800);
    const handlePaste = async (event: any) => {
      const clipboardData = event.clipboardData || (await navigator.clipboard);
      const types = clipboardData.types;
      if (types.includes("text/plain")) {
        const text = await clipboardData.getData("text/plain");
      } else if (
        types.includes("Files") ||
        types.includes("image/png") ||
        types.includes("image/jpeg")
      ) {
        const imageBlob = clipboardData.items[0].getAsFile();
        if (imageBlob) {
          setFilesModal(true);
          const no = Math.floor(Math.random() * (10000 * 10000));
          const file = new File([imageBlob], `${no}.jpg`, {
            type: "image/jpeg",
          });
          setSelectedFiles([file]);
        }
      } else {
        setClipOpen(true);
      }
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
    // return () => {
    //     clearInterval(timer);
    // };
  }, []);

  function handleReminderOpen() {
    const formattedParticipants = activeRoomData?.participants
      ?.filter((item) => item?.user_id != userData?._id)
      ?.map((item) => ({
        user_id: item?.user_id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        role: ReminderParticipantRole.USER,
        status: ParticipantAcceptStatus.PENDING,
        phone: item?.phone,
      }));

    const selectInfo = {
      start: dayjs(),
      end: dayjs(),
      jsEvent: {
        pageY: 185,
        pageX: 777,
      },
      roomId: activeRoomData?._id,
      roomType: activeRoomData?.type,
      roomParticipants: formattedParticipants,
    };
    dispatch(openNewEventDialog(selectInfo));
  }

  function clickOnSchedule(scheduleType: string) {
    const { filename, type } = data?.UploadChatFile?.data || {};

    const params = {
      roomId: activeRoomData?._id,
      type:
        scheduleType == "media"
          ? fileUploadType == "document"
            ? "DOCUMENT"
            : type
          : "text",
      fileURL: scheduleType == "media" ? filename : null,
      isForwarded: false,
      message:
        scheduleType == "media" ? fileCaptionName.current?.value : message,
      _id: "",
      fontStyle: "",
      thumbnail: "",
      duration: scheduleType == "media" ? parseInt(duration) : 0,
    };

    setScheduleData(params);
    setShowSchedule(true);
  }

  function handleResetFileSend() {
    setFilesModal(false);
    setFileUploadType("");
    reset();
    setWebcamImage("");
    setWebcameObjFile("");
    setSelectedFiles([]);
  }

  return (
    activeRoomData &&
    Object.keys(activeRoomData).length !== 0 && (
      <>
        <EventDialog />

        <Dialog
          open={clipOpen}
          keepMounted
          maxWidth={"sm"}
          fullWidth
          onClose={() => {}}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogTitle
              className="bg-[#E0FAFF]"
              id="alert-dialog-slide-description"
            >
              {t("reset_clipboard_permission")}
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setClipOpen(false);
              }}
            >
              {t("ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteMessageModalShow}
          onClose={() => setDeleteMessageModalShow(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth={"sm"}
          fullWidth
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="bg-[#E0FAFF]" id="alert-dialog-title">
            {t("delete_messages")}
          </DialogTitle>
          <DialogContent>
            <div className="w-full justify-center my-4 mt-6 flex gap-6 text-lg">
              {showEveryoneOption && (
                <div
                  onClick={() => handleMessagesDelete("everyone")}
                  className="cursor-pointer rounded w-fit p-2 px-3 bg-gray-200"
                >
                  {t("for_everyone")}
                </div>
              )}
              <div
                onClick={() => handleMessagesDelete("me")}
                className=" cursor-pointer rounded w-fit p-2 px-3 bg-gray-200"
              >
                {t("for_me")}
              </div>
              <div
                onClick={() => setDeleteMessageModalShow(false)}
                className="cursor-pointer rounded w-fit bg-gray-200 p-2 px-3"
              >
                {t("cancel")}
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={forwardModalShow}
          onClose={() => setForwardModalShow(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className="bg-[#E0FAFF] w-[600px]"
            id="alert-dialog-title"
          >
            {t("forward_messages")}
          </DialogTitle>
          <DialogContent style={{ height: 716 }}>
            <div>
              <div
                className="my-3 rounded flex border mb-4 items-center px-2 bg-white"
                style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
              >
                <SearchIcon sx={{ color: "#333333" }} />
                <input
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  className="w-full py-2 px-2 focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              {roomsUpdated
                .sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  } else if (a.name > b.name) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .filter((el: any) => {
                  if (
                    el?.name
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  ) {
                    return el;
                  }
                })
                // .filter(
                //   (el: any) =>
                //     el?.name
                //       ?.split(" ")[0]
                //       ?.startsWith(searchQuery.toLowerCase()) ||
                //     el?.name
                //       ?.split(" ")[1]
                //       ?.startsWith(searchQuery.toLowerCase())
                // )
                ?.map((item: RoomsDataType, index: number) => {
                  const userInfo = item?.participants.find(
                    (one: any) => one?.user_id !== userData?._id
                  );

                  const contactDetails = formattedContact[userInfo?.user_id];

                  const isRoomBlocked = userData?.blockedRooms?.some(
                    (elem: any) => (elem?.room_Id === item?._id ? true : false)
                  );

                  if (item?.type === "broadcast") {
                    return null;
                  }

                  return (
                    <div className="flex items-center gap-5 my-2" key={index}>
                      <div>
                        <input
                          type="checkbox"
                          className="h-[17px] w-[17px] cursor-pointer"
                          disabled={isRoomBlocked ? true : false}
                          onChange={(e: any) =>
                            handleSelectForwarded(e, item._id, "")
                          }
                        />
                      </div>
                      <div className="flex gap-3">
                        {item?.type === "group" ? (
                          <div>
                            {item?.type === "group" &&
                            item?.profile_img !== "" ? (
                              <img
                                src={DefaultImageUrl + item?.profile_img}
                                alt="profile"
                                className="h-[40px] w-[40px] rounded-full"
                              />
                            ) : (
                              <Avatar sx={{ height: "40px", width: "40px" }} />
                            )}
                          </div>
                        ) : (
                          <div>
                            {item?.type === "individual" &&
                            userInfo?.profile_img !== "" &&
                            userInfo?.left_at === 0 &&
                            !isRoomBlocked ? (
                              <img
                                src={DefaultImageUrl + userInfo?.profile_img}
                                alt="profile"
                                className="h-[40px] w-[40px] rounded-full"
                              />
                            ) : (
                              <Avatar sx={{ height: "40px", width: "40px" }} />
                            )}
                          </div>
                        )}
                        <div>
                          <div>
                            {item?.type === "group"
                              ? item?.name
                              : item?.participants?.length === 1
                              ? t("you_me")
                              : contactDetails &&
                                contactDetails?.firstName !== ""
                              ? contactDetails?.firstName +
                                " " +
                                contactDetails?.lastName
                              : userInfo?.phone}
                          </div>
                          {!isUserBlockedYou &&
                            !isRoomBlocked &&
                            !isUserBlockedYou && (
                              <div className="textGray text-sm">
                                {contactDetails?.userId?.bio?.status}
                              </div>
                            )}

                          {isRoomBlocked && (
                            <div className="textGray">
                              {t("blocked_user_msg")}
                            </div>
                          )}

                          {isUserBlockedYou && (
                            <div className="textGray">
                              {t("blocked_user_msg_two")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {nonInitiatedRooms()
                ?.map((el: any) => ({
                  ...el,
                  name:
                    el?.firstName !== ""
                      ? el.firstName?.toLowerCase() +
                        " " +
                        el?.lastName.toLowerCase()
                      : `${el?.userId?.phone.replace("+", "")}`,
                }))
                .sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  } else if (a.name > b.name) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .filter(
                  (el: any) =>
                    el?.name
                      .split(" ")[0]
                      ?.startsWith(searchQuery.toLowerCase()) ||
                    el?.name
                      .split(" ")[1]
                      ?.startsWith(searchQuery.toLowerCase())
                )?.length > 0 && <div className="textGray">{t("others")}</div>}

              {nonInitiatedRooms()
                ?.map((el: any) => ({
                  ...el,
                  name:
                    el?.firstName !== ""
                      ? el.firstName?.toLowerCase() +
                        " " +
                        el?.lastName.toLowerCase()
                      : `${el?.userId?.phone.replace("+", "")}`,
                }))
                .sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  } else if (a.name > b.name) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .filter(
                  (el: any) =>
                    el?.name
                      .split(" ")[0]
                      ?.startsWith(searchQuery.toLowerCase()) ||
                    el?.name
                      .split(" ")[1]
                      ?.startsWith(searchQuery.toLowerCase())
                )
                ?.map((item: NoneInitiatedRoomsType, index: number) => {
                  return (
                    <div className="flex items-center gap-5 my-2" key={index}>
                      <div>
                        <input
                          type="checkbox"
                          className="h-[17px] w-[17px] cursor-pointer"
                          onChange={(e: any) =>
                            handleSelectForwarded(e, "", item.userId?._id)
                          }
                        />
                      </div>
                      <div>
                        {item?.userId?.profile_img !== "" ? (
                          <img
                            src={DefaultImageUrl + item?.userId?.profile_img}
                            alt="profile"
                            className="h-[40px] w-[40px] rounded-full"
                          />
                        ) : (
                          <Avatar sx={{ height: "40px", width: "40px" }} />
                        )}
                      </div>
                      <div>
                        {item?.firstName !== "" ? (
                          <div>{item?.firstName + " " + item?.lastName}</div>
                        ) : (
                          <div>{item?.userId?.phone}</div>
                        )}
                        <div className="textGray">
                          {item?.userId?.bio?.status}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="bg-gray-200 p-2 rounded-md"
              onClick={() => {
                setForwardToRooms([]);
                setForwardModalShow(false);
              }}
            >
              {t("cancel")}
            </button>
            <button
              disabled={
                forwardToRooms?.length === 0 || isForwardButtonDisable
                  ? true
                  : false
              }
              className="bg-primary p-2 rounded-md text-white disabled:opacity-25"
              onClick={handleForwardMessages}
            >
              {t("send")}
            </button>
          </DialogActions>
        </Dialog>

        {filesModal === true && (
          <SelectedMediaForUploadContainer
            fileUploadError={fileUploadError}
            setWebcameObjFile={setWebcameObjFile}
            setWebcamImage={setWebcamImage}
            selectedFiles={selectedFiles}
            webcamImage={webcamImage}
            webcamRef={webcamRef}
          />
        )}

        {/* removed h-28, items-center and mb-2 from here */}
        <div className="flex w-full absolute  bottom-space z-[130] left-0 right-0 bg-white">
          <Toast
            open={toastShow}
            message={toastMessage}
            setShow={setToastShow}
          />
          <Dialog
            open={contactsModalShow}
            onClose={() => {
              setContactsModalShow(false);
              setSearchQuery("");
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ width: "100%", height: "500px" }}
          >
            <DialogTitle id="alert-dialog-title " className="bg-[#E0FAFF]">
              <div className="flex justify-between items-center">
                <div>{t("share_contacts")}</div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setContactsModalShow(false);
                    setSearchQuery("");
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            </DialogTitle>
            <DialogContent style={{ height: 716 }}>
              <div className="w-[500px]">
                <div className="my-4">
                  <div
                    className="mt-3 mx-2 rounded-md border mb-2  flex items-center px-2 bg-white"
                    style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
                  >
                    <SearchIcon sx={{ color: "#333333" }} />
                    <input
                      type="text"
                      placeholder={`${t("search")}...`}
                      className="w-full py-2 px-2 focus:outline-none text-sm"
                      value={searchQuery}
                      onChange={handleSearchUsers}
                    />
                  </div>
                </div>

                <div className="w-full textAreaScroll">
                  {userContactData?.length > 0 ? (
                    userContactData
                      ?.filter(
                        (el: any) =>
                          el?.name
                            ?.split(" ")[0]
                            ?.startsWith(searchQuery.toLowerCase()) ||
                          el?.name
                            ?.split(" ")[1]
                            ?.startsWith(searchQuery.toLowerCase())
                      )
                      .map((item: ComonUsersDataTypes, index: any) => {
                        return (
                          <div
                            className="flex justify-between items-center mb-4"
                            key={index}
                          >
                            <div>
                              <div className="flex gap-3 items-center">
                                <div className="h-[45px] w-[45px] min-w-[45px]">
                                  {item?.userId !== null ? (
                                    <img
                                      src={
                                        DefaultImageUrl +
                                        item?.userId?.profile_img
                                      }
                                      alt=""
                                      className="h-full w-full rounded-full"
                                    />
                                  ) : (
                                    <Avatar sx={{ height: 45, width: 45 }} />
                                  )}
                                </div>
                                <div>
                                  {item?.firstName !== "" && (
                                    <div>
                                      <span>{item?.firstName}</span>
                                      <span className="ml-1">
                                        {item?.lastName}
                                      </span>
                                    </div>
                                  )}
                                  <div className="textGray">{item?.phone}</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                className="cursor-pointer h-[17px] w-[17px] outline-none"
                                onChange={(e) => handleSelectContacts(item, e)}
                                id={item.phone}
                                checked={
                                  selectedContacts?.filter(
                                    (elem: any) => elem?.phone === item?.phone
                                  ).length > 0
                                }
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="text-center textGray">
                      {t("no_results_found")}
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
            {selectedContacts?.length > 0 && (
              <DialogActions className="border">
                <button
                  className="py-2 bg-primary px-4 rounded-md text-sm text-white disabled:opacity-70"
                  onClick={handleShareContact}
                  disabled={contactShareLoading ? true : false}
                >
                  {contactShareLoading ? `${t("loading")}...` : t("send")}
                </button>
              </DialogActions>
            )}
          </Dialog>
          {filesModal === true ? (
            <SelectedMediaCaptionBar
              setFilesModal={setFilesModal}
              setSelectedFiles={setSelectedFiles}
              setWebcameObjFile={setWebcameObjFile}
              setFileUploadError={setFileUploadError}
              setWebcamImage={setWebcamImage}
              fileCaptionName={fileCaptionName}
              handleFileSend={handleFileSend}
              loading={loading}
              fileUploadError={fileUploadError}
              clickOnSchedule={clickOnSchedule}
            />
          ) : (
            <>
              {isChangeMessageFooter ? (
                <ForwardMessageBar
                  setSelectedMessages={setSelectedMessages}
                  setIsChangeMessageFooter={setIsChangeMessageFooter}
                  setShowMessageCheckboxes={setShowMessageCheckboxes}
                  selectedMessages={selectedMessages}
                  setForwardModalShow={setForwardModalShow}
                  setDeleteMessageModalShow={setDeleteMessageModalShow}
                  handleShowEveryoneOption={handleShowEveryoneOption}
                />
              ) : (
                <>
                  {isAudioFooter ? (
                    <div className="py-4 w-full flex justify-end px-6">
                      <div className="flex gap-4 items-center w-full">
                        {loadingtime ? (
                          <div className="flex">
                            <CircularProgress
                              variant="determinate"
                              value={progress}
                            />
                            <div className="mx-1 mt-2">{t("uploading")}</div>
                          </div>
                        ) : (
                          <div className="w-full">
                            {audioUploadedFile !== "" ? (
                              <div>
                                <audio
                                  src={mediaBlobUrl}
                                  controls
                                  loop
                                  style={{ width: "350px" }}
                                />
                              </div>
                            ) : (
                              <div className="flex gap-2">
                                <div className="flex">
                                  {(Number(second) !== 0 ||
                                    Number(minute) !== 0) && (
                                    <div className="loading">
                                      {[1, 2, 3, 4].map((item: any) => (
                                        <>
                                          <span className="a1"></span>
                                          <span className="a2"></span>
                                          <span className="a3"></span>
                                          <span className="a4"></span>
                                          <span className="a5"></span>
                                        </>
                                      ))}
                                    </div>
                                  )}
                                  {(Number(second) !== 0 ||
                                    Number(minute) !== 0) && (
                                    <div className="mt-1 flex">
                                      <div className="flex mx-2 mt-1">
                                        <span className="minute">{minute}</span>
                                        <span>:</span>
                                        <span className="second">{second}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="flex">
                                  <label htmlFor="icon-button-file">
                                    <div className="flex ">
                                      {(Number(second) !== 0 ||
                                        Number(minute) !== 0) && (
                                        <button
                                          className="py-1 cursor-pointer"
                                          onClick={() => {
                                            if (!isActive) {
                                              startRecording();
                                            } else {
                                              pauseRecording();
                                            }
                                            setIsActive(!isActive);
                                          }}
                                        >
                                          {isActive ? (
                                            <img
                                              src={pauseImg}
                                              className="w-10 h-8"
                                            />
                                          ) : (
                                            <img
                                              src={playImg}
                                              className="w-10 h-8"
                                            />
                                          )}
                                        </button>
                                      )}
                                      {(Number(second) !== 0 ||
                                        Number(minute) !== 0) && (
                                        <button
                                          className="  py-1 cursor-pointer"
                                          onClick={() => {
                                            stopTimer();
                                            stopRecording();
                                            pauseRecording();
                                          }}
                                        >
                                          <img
                                            src={stopImg}
                                            className="w-8 h-8"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <b></b>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="w-fit">
                          <button
                            className="text-red-600"
                            onClick={() => {
                              setLoadingTime(false);
                              stopTimer();
                              stopRecording();
                              pauseRecording();
                              setIsActive(false);
                              setAudioUploadedFile("");
                              setIsAudioFooter(false);
                              clearBlobUrl();
                            }}
                          >
                            {t("cancel")}
                          </button>
                        </div>
                        {audioUploadedFile !== "" && (
                          <div>
                            <button
                              className="outline-none disabled:opacity-20"
                              onClick={handleAudioSend}
                            >
                              <SendIcon className="text-green-600 " />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {activeRoomData?.type === "group" && isRoomLeft ? (
                        <div className="w-full">
                          <div className="py-3 text-center lightBlack">
                            {t("not_send_msg")}
                          </div>
                        </div>
                      ) : isRoomBlocked ? (
                        <div className="w-full">
                          <div className="py-3 text-center lightBlack">
                            {t("block_contact_msg")}
                          </div>
                        </div>
                      ) : !canYouSendMessage &&
                        activeRoomData?.type === "group" ? (
                        <div className="w-full">
                          <div className="py-3 text-center lightBlack">
                            {t("admin_msg")}
                          </div>
                        </div>
                      ) : (
                        <ReplyFooter
                          replyFooterShow={replyFooterShow}
                          replyMessage={replyMessage}
                          setReplyFooterShow={setReplyFooterShow}
                        />
                      )}
                    </>
                  )}
                  {activeRoomData?.type === "group"
                    ? isRoomLeft === false &&
                      canYouSendMessage && (
                        <div className="flex  items-end pb-4 justify-between w-full">
                          <div
                            className="relative flex justify-center"
                            ref={shareMediaRef}
                          >
                            {shareMediaShow && (
                              <div className="absolute bottom-11 left-3">
                                {ShareMedia?.map(
                                  (
                                    item: ShareMediaDataTypes,
                                    index: number
                                  ) => {
                                    return (
                                      <label
                                        key={index}
                                        htmlFor={item?.htmlFor}
                                        className="flex gap-3 items-center cursor-pointer"
                                      >
                                        <div>
                                          <div
                                            className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                            style={{
                                              boxShadow:
                                                "0px 2px 13px 5px #0000001a",
                                            }}
                                            onClick={() => {
                                              if (item?.id === "camera") {
                                                setFilesModal(true);
                                                // dispatch(setIsRoomLoading(true))
                                              } else {
                                                setFileUploadType(item?.id);
                                              }
                                            }}
                                          >
                                            {item?.icon}
                                          </div>
                                          <input
                                            type="file"
                                            id={item?.id}
                                            accept={item?.accept}
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFilesUpload(e)
                                            }
                                          />
                                        </div>
                                        <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                          {t(item?.tooltip)}
                                        </div>
                                      </label>
                                    );
                                  }
                                )}

                                <div
                                  onClick={() => setContactsModalShow(true)}
                                  className="flex gap-3 items-center cursor-pointer"
                                >
                                  <div
                                    className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                    style={{
                                      boxShadow: "0px 2px 13px 5px #0000001a",
                                    }}
                                  >
                                    <FaUserAlt
                                      className="text-xl"
                                      style={{ color: "white" }}
                                    />
                                  </div>
                                  <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                    {t("sendcontact")}
                                  </div>
                                </div>

                                {activeRoomData?.type != "broadcast" &&
                                  !isMySelf && (
                                    <div
                                      onClick={handleReminderOpen}
                                      className="flex gap-3 items-center cursor-pointer"
                                    >
                                      <div
                                        className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                        style={{
                                          boxShadow:
                                            "0px 2px 13px 5px #0000001a",
                                        }}
                                      >
                                        <IoIosNotifications
                                          className="text-xl"
                                          style={{ color: "white" }}
                                        />
                                      </div>
                                      <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                        {t("create_reminder_appointment")}
                                      </div>
                                    </div>
                                  )}

                                <div
                                  onClick={() => {
                                    setShareMediaShow(false);
                                    setShowPollModal(true);
                                  }}
                                  className="flex gap-3 items-center cursor-pointer"
                                >
                                  <div
                                    className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                    style={{
                                      boxShadow: "0px 2px 13px 5px #0000001a",
                                    }}
                                  >
                                    <PollOutlinedIcon
                                      className="text-xl"
                                      style={{ color: "white" }}
                                    />
                                  </div>
                                  <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                    {t("poll")}
                                  </div>
                                </div>
                              </div>
                            )}

                            <img
                              src={msgButton}
                              className="w-[38px] ms-6 h-[35px] cursor-pointer"
                              alt="Tools"
                              onClick={() => setShareMediaShow(!shareMediaShow)}
                            />
                          </div>
                          <div className="min-h-[64px] h-auto  flex  max-h-[64px] relative mx-1 w-full">
                            <div className="px-2 w-[100%]    absolute left-0 right-0 bottom-0 top-[22px]">
                              <div className="m-2 mb-0  bg-opacity-0">
                                {/* removed max-h-[170px] and add max-h-[35px] from mention input */}
                                <MentionsInput
                                  value={message}
                                  onChange={handleChange}
                                  allowSpaceInQuery={true}
                                  allowSuggestionsAboveCursor={true}
                                  forceSuggestionsAboveCursor={true}
                                  markup="@{{__type__||__id__||__display__}}"
                                  placeholder={t("message")}
                                  className="mentions bg-white px-2 outline-none min-h-[35px] max-h-[35px] bottom-2"
                                  id="message-input"
                                  autoFocus={true}
                                  onKeyDown={(e: any) => onkeyFunc(e)}
                                >
                                  <Mention
                                    trigger="@"
                                    data={mentinoParticipants}
                                    renderSuggestion={(
                                      suggestion: any,
                                      search: any,
                                      highlightedDisplay: any,
                                      index: any,
                                      focused: any
                                    ) => (
                                      <div
                                        className={`flex items-center mention-suggestion ${
                                          focused ? "focused" : ""
                                        }`}
                                      >
                                        <img
                                          src={
                                            DefaultImageUrl + suggestion.avatar
                                          }
                                          alt="Avatar"
                                          className="rounded-full w-8 h-8"
                                        />
                                        <div className="flex ml-3 items-center">
                                          {highlightedDisplay}
                                        </div>
                                      </div>
                                    )}
                                    displayTransform={(
                                      id: any,
                                      display: any,
                                      avatar: any
                                    ) => `@${display} `}
                                    className="mentions__mention h-28 "
                                  />
                                </MentionsInput>
                              </div>
                            </div>
                          </div>

                          <div className="flex me-3">
                            <div>
                              {messageSend && (
                                <audio src={msgsend} autoPlay></audio>
                              )}
                              <button
                                className="disabled:opacity-40 flex outline-none items-center justify-center border h-[40px] w-[40px] pl-[8px] pr-[6px] py-[5px] rounded-full bg-white"
                                onClick={handleChatSend}
                                disabled={!message ? true : false}
                              >
                                <SendIcon className="text-green-600" />
                              </button>
                            </div>

                            {message && !isMySelf ? (
                              <button
                                className="disabled:opacity-40 ml-2 flex outline-none items-center justify-center border h-[40px] w-[40px] pl-[8px] pr-[6px] py-[5px] rounded-full bg-white"
                                onClick={() => clickOnSchedule("text")}
                              >
                                <img src={ScheduleIcon} />
                              </button>
                            ) : (
                              <div
                                className="border cursor-pointer px-[8px] mt-1 py-[5px] rounded-full h-[35px] w-[35px] bg-white ml-2"
                                onClick={() => {
                                  setIsActive(true);
                                  startRecording();
                                  setTimeout(() => {
                                    setIsAudioFooter(true);
                                  }, 1000);
                                }}
                              >
                                <img
                                  src={AudioIcon}
                                  alt="sdf"
                                  className="h-full w-full"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    : !isAudioFooter &&
                      isRoomBlocked === false && (
                        // add items-center at here
                        <div className="flex items-center pb-4 justify-between w-full bg-white -mt-3">
                          <div
                            className="relative flex justify-center"
                            ref={shareMediaRef}
                          >
                            {shareMediaShow && (
                              <div className="absolute bottom-11 left-3">
                                {ShareMedia?.map(
                                  (
                                    item: ShareMediaDataTypes,
                                    index: number
                                  ) => {
                                    return (
                                      <label
                                        key={index}
                                        htmlFor={item?.htmlFor}
                                        className="flex gap-3 items-center cursor-pointer"
                                      >
                                        <div>
                                          <div
                                            className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                            style={{
                                              boxShadow:
                                                "0px 2px 13px 5px #0000001a",
                                            }}
                                            onClick={() => {
                                              if (item?.id === "camera") {
                                                setFilesModal(true);
                                                // dispatch(setIsRoomLoading(true))
                                              } else {
                                                setFileUploadType(item?.id);
                                              }
                                            }}
                                          >
                                            {item?.icon}
                                          </div>
                                          <input
                                            type="file"
                                            id={item?.id}
                                            accept={item?.accept}
                                            className="hidden"
                                            onChange={(e) =>
                                              handleFilesUpload(e)
                                            }
                                          />
                                        </div>
                                        <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                          {t(item?.tooltip)}
                                        </div>
                                      </label>
                                    );
                                  }
                                )}

                                <div
                                  onClick={() => setContactsModalShow(true)}
                                  className="flex gap-3 items-center cursor-pointer"
                                >
                                  <div
                                    className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                    style={{
                                      boxShadow: "0px 2px 13px 5px #0000001a",
                                    }}
                                  >
                                    <FaUserAlt
                                      className="text-xl"
                                      style={{ color: "white" }}
                                    />
                                  </div>

                                  <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                    {t("sendcontact")}
                                  </div>
                                </div>

                                {activeRoomData?.type != "broadcast" &&
                                  !isMySelf && (
                                    <div
                                      onClick={handleReminderOpen}
                                      className="flex gap-3 items-center cursor-pointer"
                                    >
                                      <div
                                        className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                        style={{
                                          boxShadow:
                                            "0px 2px 13px 5px #0000001a",
                                        }}
                                      >
                                        <IoIosNotifications
                                          className="text-xl"
                                          style={{ color: "white" }}
                                        />
                                      </div>
                                      <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                        {t("create_reminder_appointment")}
                                      </div>
                                    </div>
                                  )}

                                <div
                                  onClick={() => {
                                    setShareMediaShow(false);
                                    setShowPollModal(true);
                                  }}
                                  className="flex gap-3 items-center cursor-pointer"
                                >
                                  <div
                                    className="bg-primary rounded-full p-4 my-2 flex justify-center items-center"
                                    style={{
                                      boxShadow: "0px 2px 13px 5px #0000001a",
                                    }}
                                  >
                                    <PollOutlinedIcon
                                      className="text-xl"
                                      style={{ color: "white" }}
                                    />
                                  </div>
                                  <div className="text-xs min-w-max bg-[#e0faff] px-[16px] py-[10px] rounded-md shadow-md">
                                    {t("poll")}
                                  </div>
                                </div>
                              </div>
                            )}

                            <img
                              src={msgButton}
                              className="w-[38px] ms-6 h-[35px] cursor-pointer"
                              alt="Tools"
                              onClick={() => setShareMediaShow(!shareMediaShow)}
                            />
                          </div>
                          <div className="min-h-[64px] h-auto  flex  max-h-[64px] relative mx-1 w-full">
                            <div className="px-2 w-[100%]   absolute left-0 right-0 bottom-0">
                              <div className="m-2 mb-0  bg-opacity-0">
                                {/* removed max-h-[170px] and add max-h-[35px] from mention input */}
                                <MentionsInput
                                  value={message}
                                  onChange={handleChange}
                                  allowSuggestionsAboveCursor={true}
                                  forceSuggestionsAboveCursor={true}
                                  markup="@{{__type__||__id__||__display__}}"
                                  placeholder={t("message")}
                                  className="mentions bg-white px-2 outline-none min-h-[35px] max-h-[35px] bottom-2"
                                  id="message-input"
                                  autoFocus={true}
                                  onKeyDown={(e: any) => onkeyFunc(e)}
                                >
                                  <Mention
                                    trigger="@"
                                    data={mentinoParticipants}
                                    renderSuggestion={(
                                      suggestion: any,
                                      search: any,
                                      highlightedDisplay: any,
                                      index: any,
                                      focused: any
                                    ) => (
                                      <div
                                        className={`flex items-center mention-suggestion ${
                                          focused ? "focused" : ""
                                        }`}
                                      >
                                        <img
                                          src={
                                            DefaultImageUrl + suggestion.avatar
                                          }
                                          alt="Avatar"
                                          className="rounded-full w-8 h-8"
                                        />
                                        <div className="flex ml-3 items-center">
                                          {highlightedDisplay}
                                        </div>
                                      </div>
                                    )}
                                    displayTransform={(
                                      id: any,
                                      display: any,
                                      avatar: any
                                    ) => `@${display} `}
                                    className="mentions__mention h-28 "
                                  />
                                </MentionsInput>
                              </div>
                            </div>
                          </div>

                          <div className="flex me-3">
                            <div>
                              {messageSend && (
                                <audio src={msgsend} autoPlay></audio>
                              )}
                              <button
                                className="disabled:opacity-40 flex outline-none items-center justify-center border h-[40px] w-[40px] pl-[8px] pr-[6px] py-[5px] rounded-full bg-white"
                                onClick={handleChatSend}
                                disabled={!message ? true : false}
                              >
                                <SendIcon className="text-green-600" />
                              </button>
                            </div>

                            {message && !isMySelf ? (
                              <button
                                className="disabled:opacity-40 ml-2 flex outline-none items-center justify-center border h-[40px] w-[40px] pl-[8px] pr-[6px] py-[5px] rounded-full bg-white"
                                onClick={() => clickOnSchedule("text")}
                              >
                                <img src={ScheduleIcon} />
                              </button>
                            ) : (
                              <div
                                className="border cursor-pointer px-[8px] mt-1 py-[5px] rounded-full h-[35px] w-[35px] bg-white ml-2"
                                onClick={() => {
                                  setIsActive(true);
                                  startRecording();
                                  setTimeout(() => {
                                    setIsAudioFooter(true);
                                  }, 1000);
                                }}
                              >
                                <img
                                  src={AudioIcon}
                                  alt="sdf"
                                  className="h-full w-full"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                </>
              )}
            </>
          )}
        </div>

        {showSchedule && (
          <ScheduleModal
            show={showSchedule}
            setShow={setShowSchedule}
            scheduleData={scheduleData}
            setToastShow={setToastShow}
            setToastMessage={setToastMessage}
            setMessage={setMessage}
            roomType={activeRoomData?.type}
            handleResetFileSend={handleResetFileSend}
            modalType="create"
          />
        )}

        {showPollModal && (
          <PollModal show={showPollModal} setShow={setShowPollModal} />
        )}
      </>
    )
  );
}
