import { Collapse, ListItem, Avatar, ListItemAvatar, ListItemText, Popover, TextField, Typography } from "@mui/material"
import { useOrganizations } from "../../hooks/useOrganizations";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageIcon from "@mui/icons-material/Image";
import { setCurrentAssignment, setCurrentOrganization, setCurrentReport, setCurrentScenario, setShowTaskPage } from "../../Redux/OrganizationSlice";
import { setActiveRoomChats, setActiveRoomData } from "../../Redux/ChatSlice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export default function OrganizationListView({ headerTab, setMenuAnchorElOrg, menuAnchorElOrg, openOrg, setCurrentOrg }) {
    const { organizations, currentOrganization, showTaskPage } = useSelector(
        (state: any) => state.organization
    );
    const [search, setSearcData] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation()

    return (
        <>
            {organizations.length > 0 && (
                <Collapse in={headerTab === 1}>
                    <div className="relative px-2" >
                        <div className="flex border rounded-full bg-[#E0FAFF] my-2 justify-between items-center cursor-pointer" onClick={(event) => {
                            setMenuAnchorElOrg(!openOrg)
                        }}>
                            <ListItem className="" style={{ padding: '0 10px' }}>
                                <hr />
                                <ListItemAvatar>
                                    <Avatar sx={{ fontSize: "0.5rem" }}>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        currentOrganization?.name?.length > 50 ? (
                                            <Typography variant="body2" style={{ fontSize: '16px', color: "#4f4d4d" }}>
                                                {currentOrganization?.name?.slice(0, 50)}...
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" style={{ fontSize: '16px', color: "#4f4d4d" }}>
                                                {`${currentOrganization?.name}`}
                                            </Typography>
                                        )
                                    }
                                    secondary={
                                        currentOrganization?.description?.length > 50 ? (
                                            <Typography variant="body2" style={{ fontSize: '14px', color: 'gray' }}>
                                                {currentOrganization?.description?.slice(0, 50)}...
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" style={{ fontSize: '14px', color: 'gray' }}>
                                                {currentOrganization?.description}
                                            </Typography>
                                        )
                                    }
                                />{" "}
                                <hr />
                            </ListItem>
                            {organizations.length > 1 &&
                                <KeyboardArrowDownIcon
                                    className="me-2 cursor-pointer"
                                />
                            }
                        </div>
                        {openOrg && <div className="absolute left-2 right-2 z-50 rounded-md shadow-md border h-[350px] bg-white overflow-y-scroll">
                            <div className="px-10  pt-3">
                                <input
                                    placeholder={t("search") + " " + t("organization")}
                                    className="w-full border p-2 px-10 text-center rounded-full"
                                    onChange={(e) => setSearcData(e.target.value)}
                                />
                            </div>

                            <div className="py-2 px-2">
                                {organizations?.filter((e: any) => e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((item: any) => (
                                    <div
                                        className="cursor-pointer"
                                        onClick={(event) => {
                                            setMenuAnchorElOrg(null);
                                            dispatch(setCurrentScenario({}))
                                            dispatch(setCurrentReport({}))
                                            dispatch(setCurrentAssignment({}));
                                            dispatch(setActiveRoomData({}));
                                            dispatch(setActiveRoomChats([]));
                                            dispatch(setShowTaskPage(false))
                                            setCurrentOrg(item);
                                        }}
                                    >

                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ fontSize: "0.5rem" }}>
                                                    <ImageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${item?.name}`}
                                                secondary={item?.description}
                                            />{" "}
                                        </ListItem>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </Collapse>
            )}
        </>
    )
}