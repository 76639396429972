import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const events = []

export const EventSlice = createSlice({
    name: "events",
    initialState: {
        events,
        showCalendarPage: false,
        eventDialog: {
            type: "new",
            props: {
                open: false,
                anchorPosition: { top: 200, left: 400 },
            },
            data: null,
        }
    },

    reducers: {
        openNewEventDialog: {
            prepare: (selectInfo): any => {
                const { start, jsEvent, roomId, roomType, roomParticipants } = selectInfo
                const payload = {
                    type: 'new',
                    props: {
                        open: true,
                        anchorPosition: { top: jsEvent.pageY, left: jsEvent.pageX },
                    },
                    data: {
                        start: dayjs(new Date(start)),
                        end: dayjs(new Date(start)),
                        time: dayjs().add(30, "minute"),
                        endTimes: dayjs().add(31, "minute"),
                        roomId,
                        roomType,
                        roomParticipants,
                    },
                }
                return { payload };
            },
            reducer: (state, action) => {
                state.eventDialog = action.payload
            }
        },

        openEditEventDialog: {
            prepare: (clickInfo): any => {
                const { jsEvent, event, start, end } = clickInfo || {}
                const { title, allDay, extendedProps } = event || {};

                const payload = {
                    type: 'edit',
                    props: {
                        open: true,
                        anchorPosition: { top: jsEvent.pageY, left: jsEvent.pageX },
                    },
                    data: {
                        _id: extendedProps?._id,
                        parent_id: extendedProps?.parent_id,
                        title,
                        allDay,
                        roomId: extendedProps?.roomId,
                        roomParticipants: extendedProps?.roomParticipants,
                        type: extendedProps?.type,
                        description: extendedProps?.description,
                        frequency: extendedProps?.frequency,
                        days: extendedProps?.days,
                        noOfWeeks: extendedProps?.noOfWeeks,
                        repeatEveryMonth: extendedProps?.repeatEveryMonth,
                        months: extendedProps?.months,
                        notifications: extendedProps?.notifications,
                        attachments: extendedProps?.attachments,
                        participants: extendedProps?.participants,
                        location: extendedProps?.location,
                        start: dayjs(new Date(start)),
                        end: dayjs(new Date(end)),
                        time: extendedProps?.time,
                        endTimes: extendedProps?.endTimes,
                        isAllDay: extendedProps?.isAllDay,
                        monthDateType: extendedProps?.monthDateType,
                        onDay: extendedProps?.onDay,
                        onWeek: extendedProps?.onWeek,
                        everyWeek: extendedProps?.everyWeek,
                        hasComon: !extendedProps?.hasComon,
                        isConfirmationNeeded: extendedProps?.isConfirmationNeeded,
                        roomType: extendedProps?.roomType,
                        form: extendedProps?.from,
                        to: extendedProps?.to
                    },
                };
                return { payload }
            },
            reducer: (state, action) => {
                state.eventDialog = action.payload
            }
        },

        closeNewEventDialog: (state, action) => {
            state.eventDialog = {
                type: 'new',
                props: {
                    open: false,
                    anchorPosition: { top: 200, left: 400 },
                },
                data: null,
            };
        },

        addNewEvent: (state, action) => {
            state.events = [...state.events, action.payload]
        },

        updateEvent: (state, action) => {
            state.events = action.payload
        },

        removeEvent: (state, action) => {
            state.events = state?.events?.filter(item => item?.id != action?.payload)
        },

        getAllEvents: (state, action) => {
            state.events = action.payload
        },

        setShowCalendar: (state, action) => {
            state.showCalendarPage = action.payload
        }
    }
})

export const {
    openNewEventDialog,
    openEditEventDialog,
    closeNewEventDialog,
    addNewEvent,
    updateEvent,
    removeEvent,
    getAllEvents,
    setShowCalendar
} = EventSlice.actions

export default EventSlice.reducer