import dayjs from "dayjs"

export default function formatAssignmentData(data: any) {
    const formatted = []

    if (data?.length) {
        data?.forEach(item => {
            item?.dateTimeInput?.forEach(dateTime => {
                const startDate = dayjs(dateTime?.date)?.set("hours", dayjs(item?.startTimeInMs)?.hour()).set('minute', dayjs(item?.startTimeInMs).minute()).set('second', dayjs(item?.startTimeInMs).second());

                formatted.push({
                    ...item,
                    title: item?.scenario?.name,
                    type: "ASSIGNMENT",
                    start: startDate,
                    ...(item?.recurrent != "ANYTIME" && { end: startDate }),
                    isAllDay: item?.recurrent == "ANYTIME" ? true : false
                })
            })
        })
    }

    return formatted
}