import { useSelector } from "react-redux"
import { DefaultImageUrl } from "../../Constant/Constant";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffIcon from "@mui/icons-material/MicOff";
import outcall from "../../Assets/sound/outcall.mp3";
import { useTranslation } from "react-i18next";

export default function InCallUserInfoBubble({ onleave, totalJoinedUsers, localTrackState }) {
    const { userData } = useSelector((state: any) => state.user);
    const { activeCallData } = useSelector((state: any) => state.chat);
    const {t}=useTranslation();
    return (
        <div
            className={`col   border-rouned bg-no-repeat bg-center ${userData.profile_img
                    ? userData.profile_img.includes("default")
                        ? ""
                        : " "
                    : " bg-[#B4E8FF]"
                }`}
            style={{
                minHeight:Object.keys(totalJoinedUsers).length<=2?"": "35vh",
                height:Object.keys(totalJoinedUsers).length<=2?"83vh":"",
                backgroundImage: `url('${DefaultImageUrl + userData.profile_img
                    }')`,
                    backgroundSize:Object.keys(totalJoinedUsers).length<=2?"":"20%"
            }}
        >
            {activeCallData?.callType==='CALL'&&onleave===false &&
                activeCallData?.data?.origin?._id === userData._id &&
                Object.keys(totalJoinedUsers).length === 0 && (
                    <audio src={outcall} autoPlay loop></audio>
                )}
            <p
                id="local-player-name"
                className="player-name relative "
            >
                {!localTrackState.audioTrackEnabled ? (
                    <div
                        className={`absolute flex bg-[#f4f4f4] top-[10px] left-[1px] px-2 py-1 text-black rounded-tr-md rounded-br-md`}
                        style={{ zIndex: "10000" }}
                    >
                        <MicOffIcon
                            className="mic-icon"
                            style={{
                                color: "black",
                            }}
                        />
                        <h4>
                            {t("you")}
                        </h4>
                    </div>
                ) : (
                    <div
                        className={`absolute flex bg-[#f4f4f4] top-[10px] left-[1px] px-2 py-1 text-black rounded-tr-md rounded-br-md`}
                        style={{ zIndex: "10000" }}
                    >
                        <MicNoneIcon
                            className="mic-icon"
                            style={{
                                color: "black",
                            }}
                        />
                        <h4>
                        {t("you")}
                        </h4>
                    </div>
                )}
            </p>
            <div
                id="local-player"
                className={"player border border-white"}
            >
            </div>
        </div>
    )
}