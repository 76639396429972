import React, { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import ComputerIcon from "@mui/icons-material/Computer";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function WebDesktop() {
  const [loginTab, setLoginTab] = useState(0);
  return (
    <div>
      <div className={loginTab ? "hidden" : "px-4 block"}>
        <div className="flex items-end justify-center py-4 primary">
          <div>
            <ComputerIcon sx={{ fontSize: "80px" }} />
          </div>
          <div className="pb-3">
            <SmartphoneIcon sx={{ fontSize: "40px" }} />
          </div>
        </div>

        <div className="lightBlack">Logged in devices</div>
        <div className="flex border-b pb-2 pt-3">
          <div className="pt-1">
            <LanguageIcon
              className="border rounded-3xl bgGray text-white p-1"
              sx={{ fontSize: "30px" }}
            />
          </div>
          <div className="pl-2">
            <div className="lightBlack">Last active today at 12:30</div>
            <div className="textGray text-sm">Started in Ukraine</div>
            <div className="textGray text-sm">Mac OC</div>
          </div>
        </div>

        <div className="flex pt-2">
          <div className="pt-1">
            <LanguageIcon
              className="border rounded-3xl bgGray text-white p-1"
              sx={{ fontSize: "30px" }}
            />
          </div>
          <div className="pl-2">
            <div className="lightBlack">Last active today at 12:30</div>
            <div className="textGray text-sm">Started in Ukraine</div>
            <div className="textGray text-sm">Mac OC</div>
          </div>
        </div>

        <div
          className="flex justify-between pb-3 pt-9 cursor-pointer"
          onClick={() => setLoginTab(1)}
        >
          <div className="lightBlack">Log out from all devices</div>
          <div className="lightBlack">
            <ArrowForwardIosIcon />
          </div>
        </div>

        <div className="flex justify-between items-center pt-2">
          <div>
            <div className="lightBlack">Scan QR Code</div>
            <div className="text-sm textGray">User ComOn on other devices</div>
          </div>
          <div className="lightBlack">
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>

      {loginTab === 1 && (
        <div className="h-[400px]">
          <div
            className="flex pl-2 border-b py-2 cursor-pointer"
            onClick={() => setLoginTab(0)}
          >
            <div>
              <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
            </div>
            <div className="">Back</div>
          </div>
          <div className="bg-white drop-shadow-sm border">
            <div className="text-center pt-3 textGray">
              Log out from all devices?
            </div>
            <div className="flex justify-center py-4 gap-4">
              <div className="border px-8 py-1 rounded-md bg-red-500 text-white">
                Log Out
              </div>
              <div className="border px-8 py-1 rounded-md bg-gray-600 text-white">
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WebDesktop;
