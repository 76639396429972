import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPollForm } from "./polls/polls";
import VoteProgress from "../../Components/VoteProgress";
import { useDispatch, useSelector } from "react-redux";
import { authCllient } from "../../Graphql/authClient";
import { UPDATE_CHAT } from "../../Graphql/Mutations";
import { setScrollBottom } from "../../Redux/UserSlice";
import { setActiveRoomsChats } from "../../Redux/ChatSlice";

const PollMessageFields = ({
  messageId,
  polls,
  newMessageArray,
  setNewMessageArray,
  date,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state?.user);
  const { activeRoomData, activeRoomChats } = useSelector((state: any) => state?.chat);
  const activeParticipants = activeRoomData?.participants?.filter(
    (part) => part?.left_at == 0
  );
  const self = activeRoomData?.participants?.find(
    (part) => part?.user_id == userData?._id
  );

  const onVote = async (
    pollId: string,
    optionId: string,
    type: string,
    checked?: boolean
  ) => {
    const updatedPolls =
      type == "radio"
        ? onRadioSelect(pollId, optionId)
        : onCheckboxSelect(pollId, optionId, checked);

    const updatedMsgs = newMessageArray?.map((item) => {
      if (item?.date == date) {
        return {
          ...item,
          messages: item?.messages?.map((msg) => {
            if (msg?._id == messageId) {
              return {
                ...msg,
                message: updatedPolls,
              };
            }
            return msg;
          }),
        };
      }
      return item;
    });
    setNewMessageArray(updatedMsgs);
    
    const updatedActiveChats = activeRoomChats?.map(el => {
      if (el?._id == messageId) {
        return {
          ...el,
          message: JSON.stringify(updatedPolls)
        }
      }
      return el
    })
    dispatch(setActiveRoomsChats(updatedActiveChats))

    const payload = {
      isSent: true,
      reply_msg: null,
      data: {
        _id: messageId,
        roomId: activeRoomData?._id,
        type: "poll",
        fileURL: "",
        isForwarded: false,
        message: JSON.stringify(updatedPolls),
        fontStyle: "",
        thumbnail: "",
      },
    };

    try {
      const response = await authCllient.mutate({
        mutation: UPDATE_CHAT,
        variables: {
          input: payload,
        },
      });

      if (response?.data?.updateChat) {
        dispatch(setScrollBottom(true));
      } else {
        alert(t("something_went_wrong"));
      }
    } catch (error) {
      console.log(error);
      alert(t("something_went_wrong"));
    }
  };

  const onRadioSelect = (pollId: string, optionId: string) => {
    const updated = polls?.map((poll) => {
      if (poll?._id == pollId) {
        return {
          ...poll,
          options: poll?.options?.map((option) => {
            if (option?._id == optionId) {
              const isSelected = option?.value?.includes(userData?._id);

              return {
                ...option,
                value: isSelected
                  ? option?.value?.filter((id) => id != userData?._id)
                  : [...option?.value, userData?._id],
              };
            } else {
              return {
                ...option,
                value: option?.value?.filter((id) => id != userData?._id),
              };
            }
          }),
        };
      }
      return poll;
    });
    return updated;
  };

  const onCheckboxSelect = (
    pollId: string,
    optionId: string,
    checked: boolean
  ) => {
    const updated = polls?.map((poll) => {
      if (poll?._id == pollId) {
        return {
          ...poll,
          options: poll?.options?.map((option) => {
            if (option?._id == optionId) {
              return {
                ...option,
                value: checked
                  ? [...option?.value, userData?._id]
                  : option?.value?.filter((id) => id != userData?._id),
              };
            }
            return option;
          }),
        };
      }
      return poll;
    });
    return updated;
  };

  return (
    <div className="grid gap-4">
      {polls?.map((poll: IPollForm, pollIndex: number) => {
        return (
          <div key={pollIndex}>
            <div className="font-semibold text-gray-700">{poll?.title}</div>
            <div className="text-gray-400">
              {poll?.isMultiAnswerAllow
                ? t("selectoneore")
                : t("selectonlyone")}
            </div>

            <div className="mt-2">
              {poll?.isMultiAnswerAllow ? (
                <FormGroup>
                  {poll.options.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex}>
                        <FormControlLabel
                          name={option?._id}
                          control={
                            <Checkbox
                              size="small"
                              onChange={(ev) =>
                                onVote(
                                  poll?._id,
                                  option?._id,
                                  "checkbox",
                                  ev.target.checked
                                )
                              }
                              checked={Boolean(
                                option?.value?.includes(userData?._id)
                              )}
                              disabled={Boolean(self?.left_at != 0)}
                            />
                          }
                          label={<div className="text-sm">{option?.name}</div>}
                        />

                        <VoteProgress
                          totalVotes={option?.value?.length}
                          percent={
                            (option?.value?.length /
                              activeParticipants?.length) *
                            100
                          }
                        />
                      </div>
                    );
                  })}
                </FormGroup>
              ) : (
                <RadioGroup>
                  {poll?.options.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex}>
                        <FormControlLabel
                          name={poll?._id}
                          label={<div className="text-sm">{option?.name}</div>}
                          onClick={() =>
                            onVote(poll?._id, option?._id, "radio")
                          }
                          control={
                            <Radio
                              size="small"
                              value={option?._id}
                              checked={Boolean(
                                option?.value?.includes(userData?._id)
                              )}
                              disabled={Boolean(self?.left_at != 0)}
                            />
                          }
                        />

                        <VoteProgress
                          totalVotes={option?.value?.length}
                          percent={
                            (option?.value?.length /
                              activeParticipants?.length) *
                            100
                          }
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PollMessageFields;
