import DocumentIcon from "../../../Assets/Images/DocumentFileIcon.png";
import { DefaultImageUrl } from "../../../Constant/Constant";

const ReportQuestionDocument = ({ data }: any) => {
  const handleDownloadDocument = () => {
    window.open(DefaultImageUrl + data?.attachment?.filename);
  };

  return (
    <>
      {data?.attachment?.type === "DOCUMENT" && (
        <div className="mt-3">
          <div className="flex">
            <img src={DocumentIcon} alt="" className="h-[30px] w-[30px]" />
            <div
              className="ml-2 text-sm textGray cursor-pointer"
              style={{ overflowWrap: "anywhere" }}
              onClick={handleDownloadDocument}
            >
              {data?.attachment?.filename}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportQuestionDocument;
