import { DefaultImageUrl } from "../../../Constant/Constant";

const TaskQuestionAudio = ({ currentTask }: any) => {
  return (
    <>
      {currentTask?.attachment?.type === "AUDIO" && (
        <div className="mt-3">
          <audio
            src={
              DefaultImageUrl + currentTask?.attachment?.attachment?.filename
            }
            controls
          ></audio>
        </div>
      )}
    </>
  );
};

export default TaskQuestionAudio;
