import React, { useState } from "react";
import "../App.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
interface PropType {
  type: string;
  name: string;
  onChange?: any;
  label: string;
  value: string;
  autoComplete: string;
  id?: string;
}

const TextFieldForm = ({
  type,
  name,
  onChange,
  label,
  value,
  autoComplete,
  id,
}: PropType) => {
  const [show,setShow]=useState(false)
  return (
    <form className='relative'>
      <label className="lightBlack dark:text-white pb-[4px]">{label}</label>
      <input
        className="text-sm border focus:outline-none px-2 py-[8px] w-full mt-2 rounded-sm border-[#E0E0E0]"
        type={type==='password'?show?'text':type:type}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        id={id}
      />
       { type==="password"&&!show?<VisibilityOffIcon onClick={()=>setShow(true)} sx={{heigth:15,width:15,color:'#929497'}} className='cursor-pointer absolute right-[12px] top-[40px]'/>:<VisibilityIcon onClick={()=>setShow(false)} sx={{heigth:15,width:15,color:'#929497'}} className='cursor-pointer absolute right-[12px] top-[40px]'/> }
    </form>
  );
};

export default TextFieldForm;
