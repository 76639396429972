import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosHelpCircle } from "react-icons/io";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const data = [
  {
    name: "usermanual",
    src: "/assets/flags/en.svg",
    url: "https://clientsstaging.com/comon_user_manual/",
  },
  // {
  //   name: "German",
  //   src: "/assets/flags/de.svg",
  //   url: "https://clientsstaging.com/german-user-manual/",
  // },
  // {
  //   name: "French",
  //   src: "/assets/flags/fr.png",
  //   url: "https://clientsstaging.com/french-user-manual/",
  // },
  // {
  //   name: "Spanish",
  //   src: "/assets/flags/es.png",
  //   url: "https://clientsstaging.com/spanish-user-manual/",
  // },
];

const HelpCenter = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavigate = (url: string) => {
    window.open(url);
  };

  return (
    <div className="px-1 mt-2">
      <div
        onClick={handleToggle}
        className="flex items-center justify-between cursor-pointer"
      >
        <div className="flex gap-1 items-center">
          <IoIosHelpCircle
            style={{ color: "#33ccff" }}
            className="w-[40px] h-[30px]"
          />
          {t("helpcenter")}
        </div>

        <div className="px-3">
          {!expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </div>
      </div>

      <div
        className={clsx(
          "px-6 mt-3 grid gap-1 overflow-hidden transition-max-height duration-500 ease-in-out",
          expanded ? "max-h-screen" : "max-h-0"
        )}
      >
        {data.map((item, index) => (
          <div
            key={index}
            onClick={() => handleNavigate(item.url)}
            className="flex items-center gap-2 mb-2 cursor-pointer"
          >
            {/* <img src={item.src} alt={item.name} className="w-[25px] h-[15px]" /> */}
            <div>{t(item.name)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpCenter;
