import { setContext } from "@apollo/client/link/context";

import { client } from "./client";
import { store } from "../Redux/store";
import { RefreshSessionDocument } from "./Queries";
import { getSession, setSession } from "../utils/session";
import { authCllient } from "./authClient";

const isRefreshNeeded = () => {
  const session = getSession();

  if (!session.expireAt) {
    return false;
  }

  const expireAt = Number(session.expireAt);
  const now = Number(+new Date())

  if (now >= expireAt) {
    return true;
  }

  return false;
};

export const refreshAuthToken = async (_id?: string) => {
  const orgId = (_id) ? _id : store.getState().organization.currentOrganization?._id;
  // Generate refreshToken for organization selection
  const refresh = getSession().refresh;
  
  let session: any;
  if(refresh!==null){
  await client
    .mutate({
      mutation: RefreshSessionDocument,
      variables: {
        input: { refresh, orgId ,plateform:'WEB'},
      },
      fetchPolicy:"network-only"
    })
    .then((res) => {
      session = res.data.refreshSession;
      if (res?.data?.refreshSession.token) {
        setSession(res?.data.refreshSession)
      }
    })
    .catch((err) => {
      console.log(`Error occured at RefreshSessionDocument ${err}`)
    });
  return session;}
};

enum OperationNames {
  REFRESH_SESSION = "refreshSession",
  GET_ORGANIZATION = "organization",
}

export default setContext(async (request, { headers }) => {
  let token = localStorage.getItem("token");


  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});
