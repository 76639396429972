import { useEffect, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import CallChildContainer from "./CallChildContainer";
import { useSelector } from "react-redux";
import { App_id } from "../../Constant/Constant";
import { JOIN_CALL } from "../../Graphql/Mutations";
import { useMutation } from "@apollo/client";

const localId = Math.floor(Math.random() * 10);

export default function AudioVideo({ setTime, callEnd, setCallEnd }) {
  const { userData } = useSelector((state: any) => state.user)
  const [token, setToken] = useState("");
  const [getRtmToken] = useMutation(JOIN_CALL);
  const { activeCallData } = useSelector((state: any) => state.chat)
  let id = activeCallData?.data?.callParticipants?.filter(
    (item: any) => item?.userId?._id === userData?._id
  )[0]?.uid;
  const uid =
    activeCallData?.data?.roomType === "group"
      ? parseInt(`${id}${localId}`)
      : activeCallData?.data?.callParticipants?.filter(
        (item: any) => item?.userId?._id === userData?._id
      )[0]?.uid;
  let [options, setOptions] = useState({
    appid: App_id,
    channel: "",
    uid: null,
    token: null,
    role: "host", // host or audience
  });

  const JoinChannel = async () => {
    if (
      uid &&
      activeCallData?.data?.type &&
      activeCallData?.data?.channelName
    ) {
      getRtmToken({
        variables: {
          createRtmToken: {
            uid: uid,
            userId: userData._id,
            type: activeCallData?.data?.type,
            channelName: activeCallData?.data?.channelName,
          },
        },
      })
        .then(async (res) => {
          setOptions({
            ...options,
            token: res?.data?.getRtmToken?.token,
            channel: activeCallData?.data?.channelName,
          });
          try {
            setToken(
              res?.data?.getRtmToken?.token);
          } catch (error) {
            console.error(error);
          } finally {
            console.log("");
          }
        })
        .catch((error) => console.log("erro at Get RTM Token", error));
    }
  };

  useEffect(() => {
    JoinChannel();
  }, []);
  return (
    <>
      {token !== "" &&
       <CallChildContainer options={options} uid={uid} token={token} setTime={setTime} callEnd={callEnd} setCallEnd={setCallEnd} />
       }
    </>
  )
}