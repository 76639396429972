import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { NoneInitiatedRoomsType, RoomsDataType } from "../../Types/Types";
import { useDispatch, useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import { useState } from "react";
import { setScrollBottom } from "../../Redux/UserSlice";
import Toast from "../../coreComponent/Toast";
import { emit } from "../../Socket";
import { useTranslation } from "react-i18next";

export default function ShareContactModal({ shareContactModalShow, setShareContactModal }) {
    const { rooms, activeRoomData } = useSelector((state: any) => state.chat);
    const [toastShow, setToastShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { userData, formattedContact } = useSelector((state: any) => state.user);
    const userInfo = activeRoomData?.participants?.length > 1 ? activeRoomData?.participants?.find(
        (one: any) => one?.user_id !== userData?._id
    ) : activeRoomData?.participants?.length === 1 ? activeRoomData?.participants[0] : null;
    const [shareContactList, setSharecontactList] = useState<any>([]);
    const userProfileImg = activeRoomData?.participants?.length > 0 && activeRoomData?.participants?.find((item: any) => item?.user_id === userInfo?.user_id);
    const isUserBlockedYou =
        activeRoomData?.type !== "group" && userInfo?.left_at !== 0
            ? true
            : false;
    const nonInitiatedRooms = () => {
        const comonUsers: any = Object.values(formattedContact);
        const individualRoom: any = rooms?.filter(
            (one: any) => one?.type === "individual"
        );

        let result = [];

        for (const user of comonUsers) {
            const isExist = individualRoom.find((one: any) => {
                const existInparticipant = one.participants.find(
                    (p: any) => p.user_id === user?.userId?._id
                );
                return existInparticipant ? true : false;
            });
            if (!isExist) {
                result.push(user);
            }
        }
        return result;
    };

    const handleSelectshared = (e: any, item: any) => {
        if (e.target.checked) {
            setSharecontactList([...shareContactList, item._id]);
        } else {
            const filtered = shareContactList?.filter(
                (elem: any) => elem !== item?._id
            );
            setSharecontactList(filtered);
        }
    };

    const handleSharedContact = async () => {
        setToastMessage(t("contact_shared"));
        dispatch(setScrollBottom(true));
        if (shareContactList.length > 0) {
            let firstName = activeRoomData.participants.length === 1 ? activeRoomData.participants[0] : activeRoomData.participants.filter(
                (item: any) => item?.user_id !== userData._id
            )[0];
            const data = {
                _id: firstName._id,
                firstName: firstName?.firstName,
                lastName: firstName?.lastName,
                phone: firstName?.phone,
                profile_img: firstName ? firstName?.profile_img : null,
            };
            emit("shareContact", {
                rooms: shareContactList,
                contacts: [data],
            });
        }
        setSharecontactList([]);
        setShareContactModal(false);
    };

    return (
        <>
            <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
            <Dialog
                open={shareContactModalShow}
                onClose={() => setShareContactModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("shared_contact_to")}</DialogTitle>
                <DialogContent>
                    {rooms?.map((item: RoomsDataType, index: number) => {
                        const userInfo = item?.participants.find(
                            (one: any) => one?.user_id !== userData?._id
                        );

                        const contactDetails = formattedContact[userInfo?.user_id];

                        const isRoomBlocked = userData?.blockedRooms?.some((elem: any) =>
                            elem?.room_Id === item?._id ? true : false
                        );

                        return (
                            <div className="flex items-center gap-5 my-2" key={index}>
                                <div>
                                    <input
                                        type="checkbox"
                                        className="h-[17px] w-[17px] cursor-pointer"
                                        disabled={isRoomBlocked ? true : false}
                                        onChange={(e: any) => handleSelectshared(e, item)}
                                    />
                                </div>
                                <div className="flex gap-3">
                                    {item?.type === "group" ? (
                                        <div>
                                            {item?.type === "group" && item?.profile_img !== "" ? (
                                                <img
                                                    src={DefaultImageUrl + item?.profile_img}
                                                    alt="profile"
                                                    className="h-[40px] w-[40px] rounded-full"
                                                />
                                            ) : (
                                                <Avatar sx={{ height: "40px", width: "40px" }} />
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {item?.type === "individual" &&
                                                userInfo?.profile_img !== "" &&
                                                userInfo?.left_at === 0 &&
                                                !isRoomBlocked ? (
                                                <img
                                                    src={DefaultImageUrl + userInfo?.profile_img}
                                                    alt="profile"
                                                    className="h-[40px] w-[40px] rounded-full"
                                                />
                                            ) : (
                                                <Avatar sx={{ height: "40px", width: "40px" }} />
                                            )}
                                        </div>
                                    )}
                                    <div>
                                        <div>
                                            {item?.type === "group"
                                                ? item?.name
                                                : item?.participants?.length === 1
                                                    ? t("you_me")
                                                    : contactDetails
                                                        ? contactDetails?.firstName +
                                                        " " +
                                                        contactDetails?.lastName
                                                        : userInfo?.phone}
                                        </div>
                                        {!isUserBlockedYou &&
                                            !isRoomBlocked &&
                                            !isUserBlockedYou && (
                                                <div className="textGray">
                                                    {contactDetails?.userId?.bio?.status}
                                                </div>
                                            )}

                                        {isRoomBlocked && (
                                            <div className="textGray">
                                                {t("blocked_user_msg")}
                                            </div>
                                        )}

                                        {isUserBlockedYou && (
                                            <div className="textGray">{t("blocked_user_msg_two")}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div className="textGray">{t("others")}</div>

                    {nonInitiatedRooms()?.map(
                        (item: NoneInitiatedRoomsType, index: number) => {
                            return (
                                <div className="flex items-center gap-5 my-2" key={index}>
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="h-[17px] w-[17px] cursor-pointer"
                                            onChange={(e: any) => handleSelectshared(e, item)}
                                        />
                                    </div>
                                    <div>
                                        {item?.userId?.profile_img !== "" ? (
                                            <img
                                                src={DefaultImageUrl + item?.userId?.profile_img}
                                                alt="profile"
                                                className="h-[40px] w-[40px] rounded-full"
                                            />
                                        ) : (
                                            <Avatar sx={{ height: "40px", width: "40px" }} />
                                        )}
                                    </div>
                                    <div>
                                        <div>{item?.firstName + " " + item?.lastName}</div>
                                        <div className="textGray">{item?.userId?.bio?.status}</div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </DialogContent>
                <DialogActions>
                    <button
                        className="bg-gray-200 p-2 rounded-md"
                        onClick={() => {
                            setSharecontactList([]);
                            setShareContactModal(false);
                        }}
                    >
                        {t("cancel")}
                    </button>
                    <button
                        disabled={shareContactList?.length === 0 ? true : false}
                        className="bg-primary p-2 rounded-md text-white disabled:opacity-25"
                        onClick={handleSharedContact}
                    >
                        {t("send")}
                    </button>
                </DialogActions>
            </Dialog>

        </>
    )
}