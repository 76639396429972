import { configureStore } from "@reduxjs/toolkit";
import ChatReducer from "./ChatSlice";
import UserReducer from "./UserSlice";
import OrganizationReducer from "./OrganizationSlice";
import EventReducer from "./EventSlice"

export const store = configureStore({
  reducer: {
    chat: ChatReducer,
    user: UserReducer,
    organization: OrganizationReducer,
    events: EventReducer,
  },
});
