import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { FormPropsType } from "../../../types/types";
import { Avatar, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { EventTypeEnum } from "../../../types/enum";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../../../Assets/Images/userAvatar.png";
import { DefaultImageUrl } from "../../../../../Constant/Constant";
import SelectParticipants from "./SelectParticipants";
import React from "react";
import NotifyParticipants from "./NotifyParticipants";
import { getCalendarColor } from "../../../utils/calendarColors";

function EventParticipants(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm, errors, setErrors, roomParticipants } = props;
  const { formattedContact, userData } = useSelector(
    (state: any) => state?.user
  );

  function handleRemove(index: number) {
    const updated = form?.participants?.filter(
      (user, key) => key != index && user
    );
    setForm({ ...form, participants: updated });
    const newErrors = { ...errors };
    if (updated?.length == 0) {
      newErrors.participants = "Please select Participants.";
    }
    setErrors(newErrors);
  }

  return (
    <div className="flex gap-3 mt-5">
      <GroupOutlinedIcon
        fontSize="medium"
        sx={{
          color: getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full font-medium text-gray-600">
        {form?.roomType != "individual" && (
          <SelectParticipants
            form={form}
            setForm={setForm}
            errors={errors}
            setErrors={setErrors}
            roomParticipants={roomParticipants}
          />
        )}

        {form?.participants?.length > 0 && (
          <div className="flex flex-wrap gap-3 form-participant-scroll">
            {form?.participants?.map((participant: any, index: number) => {
              const contact = formattedContact?.[participant?._id];

              return (
                userData?._id != participant?._id && (
                  <Chip
                    variant="outlined"
                    {...(form?.roomType != "individual" && {
                      onDelete: () => handleRemove(index),
                    })}
                    avatar={
                      <Avatar
                        alt="Natacha"
                        src={
                          contact?.userId?.profile_img
                            ? DefaultImageUrl + contact?.userId?.profile_img
                            : UserAvatar
                        }
                      />
                    }
                    label={
                      !form?.hasComon ? (
                        <React.Fragment>
                          {contact?.firstName ? (
                            <div className="font-medium">
                              <span className="mr-1">{contact?.firstName}</span>
                              {contact?.lastName && (
                                <span>{contact?.lastName}</span>
                              )}
                            </div>
                          ) : (
                            <div className="font-medium">
                              {participant?.phone}
                            </div>
                          )}
                        </React.Fragment>
                      ) : (
                        <div className="font-medium">
                          <span className="mr-1">{participant?.firstName}</span>
                          {participant?.lastName && (
                            <span>{participant?.lastName}</span>
                          )}
                        </div>
                      )
                    }
                  />
                )
              );
            })}
          </div>
        )}

        {errors?.participants && (
          <p className="text-sm text-red-600 mt-2">{t(errors?.participants)}</p>
        )}

        {form?.participants?.length < 2 &&
          form?.type == EventTypeEnum.CALLREMINDER && (
            <div className="text-sm text-red-600">{t("call-reminder-msg")}</div>
          )}

        {!form?.hasComon && !form?._id && (
          <NotifyParticipants form={form} setForm={setForm} />
        )}
      </div>
    </div>
  );
}

export default EventParticipants;
