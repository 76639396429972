import { useSelector } from "react-redux"

export default function CallScreenParentContainer({children,totalJoinedUsers}){
    const {activeCallData}=useSelector((state:any)=>state.chat);
    return(
        <>
        <div
                    className={
                      activeCallData?.data?.roomType === "group" ||
                      activeCallData?.data?.roomType === "contact_group"||activeCallData?.data?.roomType==='individual'
                        ? Object.keys(totalJoinedUsers).length === 2
                          ? "grid  grid-cols-3 bg-[#b4e8ff] pt-[1.3rem]"
                          : Object.keys(totalJoinedUsers).length > 2
                          ? "grid grid-cols-2 bg-[#b4e8ff] pt-[1.3rem]"
                          : Object.keys(totalJoinedUsers).length === 0
                          ? "grid grid-cols-1 bg-[#b4e8ff] pt-[1.3rem]"
                          : Object.keys(totalJoinedUsers).length === 1
                          ? "grid grid-cols-2 bg-[#b4e8ff] pt-[1.3rem]"
                          : "grid grid-flow-col auto-cols-auto bg-[#b4e8ff]"
                        : Object.keys(totalJoinedUsers).length > 0
                        ? "grid grid-cols-2 bg-[#b4e8ff] pt-[1.3rem]"
                        : Object.keys(totalJoinedUsers).length === 0
                        ? "grid grid-cols-1 bg-[#b4e8ff] pt-[1.3rem]"
                        : " grid grid-flow-col auto-cols-auto bg-[#b4e8ff] pt-[1.3rem]"
                    }
                    style={{
                      overflowY:
                        Object.keys(totalJoinedUsers).length > 2
                          ? "scroll"
                          : "hidden",
                      height:
                        activeCallData?.data?.roomType === "group" ||
                        activeCallData?.data?.roomType === "contact_group"||activeCallData?.data?.roomType==='individual'
                          ? "82vh"
                          : "100%",
                    }}
                  >
                    {children}
                  </div>
        </>
    )
}