import { useSelector } from "react-redux"
import { DefaultImageUrl } from "../../../Constant/Constant"
import { useEffect, useState } from "react"

function RoomDetail({ data }) {
    const { rooms } = useSelector((state: any) => state?.chat)
    const { userData, formattedContact } = useSelector((state: any) => state?.user)
    const room = rooms?.find((el: any) => el?._id == data?.roomId)
    const [name, setName] = useState('')
    const [profile, setProfile] = useState(null)

    function getRoomDetails() {
        let name = ""
        if (room?.type == "group") {
            name = room?.name

            setProfile(DefaultImageUrl + room?.profile_img)
        } else {
            const participant = room?.participants?.find((el: any) => el?.user_id != userData?._id)
            setProfile(DefaultImageUrl + participant?.profile_img)

            const contact = formattedContact?.[participant?.user_id]
            if (contact?.firstName) {
                name = `${contact?.firstName} ${contact?.lastName}`
            } else {
                name = participant?.phone
            }
        }

        setName(name)
    }

    useEffect(() => {
        getRoomDetails()
    }, [data])

    return (
        <div className="flex gap-3 items-center">
            <div className="h-[30px] w-[30px] rounded-full">
                <img
                    src={profile}
                    className="h-full w-full rounded-full"
                />
            </div>
            <div className="font-semibold">
                {name}
            </div>
        </div>
    )
}

export default RoomDetail