import { useState } from "react";
import { FormPropsType, NotificationType } from "../../../types/types";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Button, Chip, Menu, MenuItem } from "@mui/material";
import AddCustomNotification from "./AddCustomNotification";
import { ApprovalReminderTimeTypes, RecurrentTypes } from "../../../types/enum";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import convertToMinutes from "../../../../../utils/convertToMinutes";
import { getCalendarColor } from "../../../utils/calendarColors";

const notificationOptions = [
  { Count: 5, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 10, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 15, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 30, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 1, Unit: ApprovalReminderTimeTypes.HOUR },
  { Count: 1, Unit: ApprovalReminderTimeTypes.DAY },
];

function EventNotification(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm, errors, setErrors } = props;
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currentDate = dayjs();
  const selectedDateTime = dayjs(
    `${form?.start?.format("YYYY-MM-DD")}T${form?.time?.format("HH:mm:ss")}`
  );
  const minuteDifference = selectedDateTime?.diff(currentDate, "minute");

  const filterOptions = notificationOptions?.filter((option) => {
    if (form?.frequency == RecurrentTypes.ONCE) {
      const optionMinutes = convertToMinutes(option.Count, option.Unit);
      return optionMinutes <= minuteDifference;
    } else {
      return option;
    }
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSelectNotification(notification: NotificationType) {
    const isExist = form?.notifications?.find(
      (item: NotificationType) =>
        item?.Count == notification?.Count && item?.Unit == notification?.Unit
    );
    if (!isExist) {
      const newErrors = { ...errors };

      const temp: NotificationType[] = form?.notifications?.length
        ? [...form?.notifications]
        : [];
      temp.push({ Count: notification?.Count, Unit: notification?.Unit });
      setForm({ ...form, notifications: temp });

      if (temp?.length > 5) {
        newErrors.notifications = "notification_max_error";
      }
      setErrors(newErrors);
    } else {
      const updated = form?.notifications?.filter(
        (item) =>
          item?.Count != notification?.Count && item?.Unit != notification?.Unit
      );
      setForm({ ...form, notifications: updated });
    }
  }

  function handleRemove(index: number) {
    const updated = form?.notifications?.filter(
      (item, key) => key != index && item
    );
    setForm({ ...form, notifications: updated });

    const newErrors = { ...errors };
    delete newErrors.notifications;
    setErrors(newErrors);
  }

  return (
    <div className="flex gap-3 mt-5">
      <NotificationsNoneOutlinedIcon
        fontSize="medium"
        sx={{
          color: getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full">
        <Button
          sx={{
            minWidth: 120,
            background: "#e4e4e4",
            fontWeight: 500,
            textTransform: "capitalize",
          }}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          id="view-select-button"
          aria-controls="view-select-menu"
          aria-haspopup="true"
          color="inherit"
          size="small"
        >
          {t("add_Notification")}
        </Button>

        <Menu
          id="view-select-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "view-select-button",
          }}
        >
          {filterOptions.map((item: any, index: number) => {
            const label: string = `${item?.Count} ${t(
              item?.Unit?.toLowerCase()
            )} ${t("before")}`;
            const isSelected = form?.notifications?.find(
              (el) => el?.Count == item?.Count && el?.Unit == item?.Unit
            );

            return (
              !isSelected && (
                <MenuItem
                  key={index}
                  onClick={() => handleSelectNotification(item)}
                >
                  <div className={clsx("lowercase")}>{label}</div>
                </MenuItem>
              )
            );
          })}

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setShowCustomModal(true);
            }}
          >
            <div>{t("custom")}</div>
          </MenuItem>
        </Menu>

        {form?.notifications?.length > 0 && (
          <div className="mt-3 flex flex-wrap gap-3">
            {form?.notifications?.map((notification, index) => {
              const label: any =
                notification?.Unit == "Custom"
                  ? notification?.Count
                  : `${notification?.Count} ${t(
                      notification?.Unit?.toLowerCase()
                    )} ${t("before")}`;

              return (
                <Chip
                  key={index}
                  variant="outlined"
                  onDelete={() => handleRemove(index)}
                  label={<div className="lowercase">{label}</div>}
                />
              );
            })}
          </div>
        )}

        {errors?.notifications && (
          <p className="text-sm text-red-600 mt-2">
            {t(errors?.notifications)}
          </p>
        )}
      </div>

      <AddCustomNotification
        show={showCustomModal}
        setShow={setShowCustomModal}
        onSubmit={handleSelectNotification}
        date={form?.start}
        time={form?.time}
      />
    </div>
  );
}

export default EventNotification;
