import { useDispatch, useSelector } from "react-redux";
import {
  GET_CHATS_BY_ROOM_ID,
  GET_MY_ASSIGNMENT,
  GET_MY_INVITES,
  GET_MY_REPORTS,
  GET_REPORT,
  GET_SCENARIO_BY_ID,
  ORGANIZATION,
} from "../Graphql/Queries";
import { authCllient, refreshTokenCllient } from "../Graphql/authClient";
import {
  setAllReports,
  setAllReportsCount,
  setAssignmentCount,
  setAssignments,
  setCurrentOrganization,
  setCurrentReport,
  setCurrentScenario,
  setLoadingState,
  setOrganizationInvite,
  setOrganizations,
  setReportsLoading,
  setScenarioLoading,
  setTaskData,
} from "../Redux/OrganizationSlice";
import { getSession } from "../utils/session";
import { setActiveRoomChats, setIsRoomsLoading } from "../Redux/ChatSlice";
import { FilterAssignments } from "../utils/task";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const useOrganizations = () => {
  const dispatch = useDispatch();
  const { activeChatsLimit, activeChatsSkip } = useSelector(
    (state: any) => state.chat
  );
  const { allReports,currentOrganization, myAssignments,taskData,currentNotifyData } = useSelector(
    (state: any) => state.organization
  );
  const {t}=useTranslation();

  const updateOrganizationsList = () => {
    dispatch(setIsRoomsLoading(true));
    authCllient
      .query({
        query: ORGANIZATION,
      })
      .then((res) => {
        dispatch(setOrganizations(res?.data?.organizations));

        setTimeout(() => {
          dispatch(setIsRoomsLoading(false));
        }, 2000);
      })
      .catch((err) => {
        dispatch(setIsRoomsLoading(false));
        console.log(`Error occured at Organizations ${err}`);
      });
  };

  const setCurrentOrg = () => {
    const currentOrganization = getSession().currentOrganization;
    if (currentOrganization) {
      const org = JSON.parse(currentOrganization);
      dispatch(setCurrentOrganization(org));
      getMyAssignment(org._id, 0, 0);
    }
  };

  const updateInvites = async () => {
    await authCllient
      .query({
        query: GET_MY_INVITES,
      })
      .then((res) => {
        dispatch(setOrganizationInvite(res?.data?.getMyInvites));
      })
      .catch((err) => {
        console.log(`Error occured at GET_MY_INVITES ${err}`);
      });
  };

  const getMyAssignment = async (
    organizationId: string,
    skip: number,
    limit: number
  ) => {
    await authCllient
      .query({
        query: GET_MY_ASSIGNMENT,
        variables: {
          input: {
            organizationId,
            limit: limit + 50,
            skip: limit,
          },
        },
        fetchPolicy: "network-only"
      })
      .then((res) => {
        if (limit + 50 > 50) {
          if (res?.data?.myAssignments?.data.length > 0) {
            dispatch(setAssignments([...myAssignments, ...res?.data?.myAssignments?.data]))
          }
        }
        else {
          dispatch(setAssignments(res?.data?.myAssignments?.data));
        }
        if (res?.data?.myAssignments.data.length > 0) {
          dispatch(setAssignmentCount(res?.data?.myAssignments?.totalCount))
        }
      })
      .catch((err) => {
        console.log(`Error occured at MY ASSIGNMENTS ${err}`);
      });
  };

  const getMyReports = async (skip: any, limit: any) => {
    const { token } = getSession();
   // dispatch(setLoadingState({ status: true, text: t('fetch-reports') }))
   if(currentOrganization?._id){ await authCllient.query({
      query: GET_MY_REPORTS,
      variables: {
        input: {
          assignmentId: null,
          limit: limit + 25,
          skip: limit,
          masterOrg:currentOrganization?._id
          // sort: '-1',
        },
      },
      fetchPolicy: "network-only"
    })
      .then((res) => {
        /* if(taskData&&currentNotifyData?.type === 'TASK_COMPLETE'){
          dispatch(setTaskData(false))
          dispatch(setLoadingState({ status: true, text: 'Fetching Task Reports....' }))
          if(res.data?.myReports?.data?.length>0){
            getReportById(res.data?.myReports.data[0]?._id);
          }
        } */
        dispatch(setLoadingState({ status: false, text: '' }))
        if (res?.data?.myReports.data.length > 0) {
          dispatch(setAllReportsCount(res?.data?.myReports?.totalCount))
        }
        if (limit + 25 > 25) {
          if (res?.data?.myReports?.data.length > 0) {
            dispatch(setAllReports([...allReports, ...res?.data?.myReports?.data]))
          }
        } else {
          if (res?.data?.myReports?.data.length > 0) {
            dispatch(setAllReports(res?.data?.myReports?.data));
          }else{
            dispatch(setAllReports(res?.data?.myReports?.data))
          }
        }
      })
      .catch((err) => {
        dispatch(setLoadingState({ status: false, text: '' }))
        console.log(`Error occured at Query My Reports ${err}`);
      });}
  };

  const getReportById = async (_id: string) => {
    const { token } = getSession();

    await authCllient.query({
        query: GET_REPORT,
        variables: {
          input: {
            _id: _id,
          },
        },
        fetchPolicy: "network-only"
      })
      .then((res) => {
        dispatch(setLoadingState({ status: false, text: '' }))
        dispatch(setCurrentReport(res?.data?.report));

      })
      .catch((error) => {
        console.log(`Cannot Query report by id => ${error}`);
      })
      .finally(() => {
        dispatch(setReportsLoading(false));

      });
  };

  const getChatsByRoomId = async (roomId: string) => {
    const { token } = getSession();

    // refreshTokenCllient(token)
    //   .query({
    //     query: GET_CHATS_BY_ROOM_ID,
    //     variables: {
    //       input: {
    //         roomId: roomId,
    //         skip: activeChatsSkip,
    //         limit: activeChatsLimit,
    //       },
    //     },
    //   })
    //   .then((res) => dispatch(setActiveRoomChats(res?.data?.getChatsByRoomId)))
    //   .catch((error) =>
    //     console.log(`Cannot Query get chat by room_id => ${error}`)
    //   );
  };

  const getScenarioById = async (_id: string, roomId: string) => {
    const { token } = getSession();

    refreshTokenCllient(token)
      .query({
        query: GET_SCENARIO_BY_ID,
        variables: {
          input: {
            _id: _id,
            orgId:currentOrganization?._id
          },
        },
        fetchPolicy: "network-only"
      })
      .then((res) => {
        dispatch(setCurrentScenario(res?.data?.scenario));

        //  getChatsByRoomId(roomId)
      })
      .catch((error) => {
        console.log(`Cannot Query scenario by id => ${error}`);
      })
      .finally(() => {
        dispatch(setScenarioLoading(false));

      });
  };

  return {
    updateOrganizationsList,
    updateInvites,
    setCurrentOrg,
    getMyAssignment,
    getMyReports,
    getReportById,
    getScenarioById,
  };
};
