
import { Avatar, Box, Drawer, Switch } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { App_version, DefaultImageUrl } from "../../../Constant/Constant";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { BsCheck2Circle } from "react-icons/bs";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UPDATE_GLOBAL_READ_RECEIPT } from "../../../Graphql/Mutations";
import { authCllient } from "../../../Graphql/authClient";
import { emit } from "../../../Socket";
import block from '../../../Assets/Images/blocked.png'
import { HiOutlineKey } from "react-icons/hi2";
import BlockedContactsDrawer from "./BlockedContactsDrawer";
import ChangePasswordDrawer from "./ChangePasswordDrawer";
import HelpCenter from "../help/HelpCenter";
const SettingDrawer = ({ setShowSettingsModal, setShowOrganization, handleLogout }: any) => {
  const { t } = useTranslation()
  const {
    userData
  } = useSelector((state: any) => state.user);
  const [readReceipts, setReadReceipts] = useState(userData?.receipts);
  const [showReadReceipts, setShowReadReceipts] = useState(false);
  const [showBlockedContacts, setShowBlockedContacts] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const handleChangeReadReceipts = (type: boolean) => {
    authCllient
      .mutate({
        mutation: UPDATE_GLOBAL_READ_RECEIPT,
        variables: {
          input: {
            action: type,
          },
        },
      })
      .then((res) => {
        emit('getProfile');
        setShowSettingsModal(false)
      })
      .catch((error) => console.log("error at Create call", error))

  }
  return (
    <div>
     
      <Drawer
        anchor={"left"}
        open={showBlockedContacts}
        onClose={() => setShowBlockedContacts(false)}
      >
        <BlockedContactsDrawer setShowBlockedContacts={setShowBlockedContacts} />
      </Drawer>
      <Drawer
        anchor={"left"}
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      >
        <ChangePasswordDrawer setShowChangePassword={setShowChangePassword} />
      </Drawer>
      <Box sx={{ width: 400 }} role="presentation" >
        <div className="h-[100vh] ">
          <div className="flex justify-between pl-2 border-b py-3 bg-gray-100 sticky top-0">
            <div
              className="flex cursor-pointer"
              onClick={() => setShowSettingsModal(false)}
            >
              <div>
                <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
              </div>
              <div className="">{t("back")}</div>
            </div>
          </div>
          <div className="h-[84vh]">
            <div className="text-center w-full">
              <div
                className="h-[80px] w-[80px] flex mx-auto mt-4 cursor-pointer"
              >
                {userData?.profile_img ? (
                  <img
                    src={DefaultImageUrl + userData.profile_img}
                    className="h-full w-full rounded-full"
                    alt="profile"
                  />
                ) : (
                  <Avatar sx={{ width: "45px", height: "45px" }} />
                )}
              </div>
              <div className="pl-3 pt-1">
                <div className="lightBlack dark:text-white">
                  <span>{userData?.firstName}</span>
                  <span className="ml-1">{userData?.lastName}</span>
                </div>
                <div className="textGray text-sm  dark:text-white">
                  {userData?.phone}
                </div>
              </div>
              <hr className="mt-2" />

            </div>

            <div className="pl-6 py-4">
              <div className="flex gap-3 cursor-pointer items-center px-2" onClick={() => { setShowOrganization(true) }}><FaEnvelopeOpenText style={{ color: '#33ccff' }} className="roudned-full p-1 w-[28px] h-[28px] " />{t('invites')}</div>
              <div className="flex gap-3 cursor-pointer items-center px-1 mt-2" onClick={() => { setShowBlockedContacts(true) }}><img src={block} style={{ color: '#33ccff' }} className="roudned-full p-1 w-[30px] h-[30px] " />{t('blocked_contacts')}</div>
              <div className="flex gap-3 cursor-pointer items-center px-2 mt-2" onClick={() => { setShowChangePassword(true) }}><HiOutlineKey style={{ background: '#33ccff' }} className="rounded-full p-[2px] text-white    w-[22px] h-[22px] " />{t('change_password')}</div>
              <div className={"px-2 mt-2 flex"} >
                <div className="flex gap-3 cursor-pointer w-full justify-between mt-2 items-center" onClick={() => setShowReadReceipts(!showReadReceipts)}>
                  <div className="flex gap-3 cursor-pointer items-start">
                    <BsCheck2Circle className="w-[22px] h-[22px]  bg-[#33ccff] rounded-full font-bold text-white" style={{ color: "white" }} />
                    <div className="flex-1">
                      {t("read_receipants")}
                      <div className="pb-2 text-xs textGray   cursor-pointer ">
                        {t("read_receipants_message")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pl-3 pt-2 flex">

                  <div>
                    <Switch checked={readReceipts} onChange={(e) => { handleChangeReadReceipts(e.target.checked); setReadReceipts(e.target.checked) }} />
                  </div>
                </div>

              </div>

              <HelpCenter />

            </div>
          </div>
          
          <div className="flex justify-between pl-2 border-b py-3 bg-gray-100 sticky bottom-0">
            <div
              className="flex cursor-pointer"
              onClick={() => setShowSettingsModal(false)}
            >
              <div className="cursor-pointer items-center" onClick={handleLogout}>
                <ExitToAppIcon fontSize="small" style={{ color: '#33ccff' }} className="mr-1" /> {t("logout")}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SettingDrawer;
