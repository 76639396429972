import { DefaultImageUrl } from "../../../Constant/Constant";
import TextFileImg from "../../../Assets/Images/doc-icon.png";

function ScheduleMessages({ scheduleData }: any) {

    function renderMessage(): string {
        const pattern = /@\[(.*?)\]\([^)]+\)/g;

        const formattedMessage = scheduleData?.message
            ?.replace(pattern, (match, captureGroup) => {
                return captureGroup + " ";
            })
            ?.replace(/\([^)]+\)/g, '')

        return formattedMessage
    }

    return (
        <div className="mt-4 flex flex-col items-end w-full">
            {scheduleData?.type != "text" && (
                <div className="mb-2">
                    {scheduleData?.type == "IMAGE" ? (
                        <img
                            src={DefaultImageUrl + scheduleData?.fileURL}
                            className="w-[300px] h-[250px] object-cover"
                        />
                    ) : scheduleData?.type == "VIDEO" ? (
                        <video
                            controls
                            className="w-[500px] h-[250px] object-cover"
                            src={DefaultImageUrl + scheduleData?.fileURL}
                        >
                        </video>
                    ) : scheduleData?.type == "AUDIO" ? (
                        <audio
                            controls
                            src={DefaultImageUrl + scheduleData?.fileURL}
                        >
                        </audio>
                    ) : (
                        <img
                            src={TextFileImg}
                            className="h-[100px] w-[100px] rounded-md object-contain mx-auto"
                        />
                    )}
                </div>
            )}

            {scheduleData?.message && (
                <div className="p-[10px] max-w-[90%] rounded-md bg-[#e0faff]">
                    {renderMessage()}
                </div>
            )}
        </div>
    )
}

export default ScheduleMessages