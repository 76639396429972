import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffIcon from "@mui/icons-material/MicOff";

export default function CallVideoScreen({ totalJoinedUsers, muteStatusRef }) {
  const { activeCallData } = useSelector((state: any) => state.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );

  return (
    <>
      {Object.keys(totalJoinedUsers).map((item, index) => {
        let temp =
          item.length === 7
            ? activeCallData?.data?.callParticipants.filter(
                (ele) => ele?.uid == item.toString().slice(0, 6)
              )[0]?.userId
            : activeCallData?.data?.callParticipants.filter(
                (ele) => ele?.uid == item
              )[0]?.userId;
        let name =
          temp === undefined
            ? userData.firstName + " " + userData.lastName
            : JSON.stringify(formattedContact) === "{}"
            ? temp?.phone
            : formattedContact[temp?._id]?.firstName !== undefined
            ? formattedContact[temp?._id]?.firstName +
              " " +
              formattedContact[temp?._id]?.lastName
            : temp?.phone;
        let profilePhoto = DefaultImageUrl + temp?.profile_img;
        // for (const element of usersDatas) {
        //   if (element.videoTrack) {
        //     element.videoTrack.play(`player-${element.uid}`);
        //     // if (activeCallData?.data?.type === "audio") {

        //     // }
        //   }
        // }

        return (
          <div key={index} className="relative ">
            <div
              className="absolute bg-[#f4f4f4] flex top-[7px] left-[0px] px-2 py-1 rounded-tr-md rounded-br-md"
              style={{ zIndex: "1000" }}
            >
              <div
                id={`remote-audio-mute-${item}`}
                className=""
                // style={{ display: "none" }}
                style={{ display: muteStatusRef?.current?.[item] ? "block" : "none" }}
              >
                <MicOffIcon
                  className="mic-icon"
                  style={{
                    color: "black",
                  }}
                />
              </div>

              <div
                id={`remote-audio-unmute-${item}`}
                className=""
                // style={{
                //   display: "block",
                // }}
                style={{ display: !muteStatusRef?.current?.[item] ? "block" : "none" }}
              >
                <MicNoneIcon
                  className="mic-icon"
                  style={{
                    color: "black",
                  }}
                />
              </div>
              <h4 className="text-black  ">{name}</h4>
            </div>
            <div
              id="remote-playerlist"
              className={`border border-white  bg-condition`}
              style={{
                background: `url('${profilePhoto}'),#b4e8ff`,
                backgroundSize: temp?.profile_img.includes("default")
                  ? ""
                  : "cover",
              }}
            >
              <div id={`player-wrapper-${item}`}>
                <div id={`player-${item}`} className="player"></div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
