import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  rooms: [],
  activeRoomData: {},
  fetchData:false,
  activeRoomChats: [],
  formattedParticipantsOfActiveRoom: {},
  userOnlineStatus: {},
  roomList: [],
  callHistory: [],
  pinnedChats: [],
  favouriteChats: [],
  userMediaByRoomId: [],
  groupsInCommon: [],
  activeChatsLimit: 50,
  activeChatsSkip: 0,
  currentPage:-1,
  totalPage:0,
  totalReceivedChatsCount: 50,
  oldChats: [],
  newMessageCount: 0,
  searchChatsResult: [],
  callDetails: "",
  callisRunning: false,
  activeCallData: "",
  leftcallData: '',
  initiatedCall: "",
  NotificationRequest: false,
  isRoomsLoading: false,
  onGoingCallData: [],
  isParticipantsInCall: [],
  isCallMinimized: false,
  joinedFromOtherDevice: false,
  rejectCallData:"",
  fetchCallHistory:false,
  isCreateCall:false,
  onlineRooms:[],
  roomsSession:{},
  memberAddedInCall:[],
  focusData:{
    status:false,
    message_id:null,
  },
  locationData:{
    lat:"",
    long:"",
  },
  activeRoomReminders: [],
  schedules: [],
  isRoomLoading: false,
};

export const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setRooms: (state, action) => {
      if (state.activeRoomData?._id) {
        const temp = action.payload.find(
          (one: any) => one?._id === state.activeRoomData?._id
        );

        let tempParticipants: any = {};
        temp?.participants.forEach((one: any) => {
          tempParticipants = { ...tempParticipants, [one.user_id]: one };
        });

      //  state.activeRoomData = temp;
        state.formattedParticipantsOfActiveRoom = tempParticipants;
      }
      let temp:any=[];
      if(action.payload.length>0){
        action.payload.forEach((element:any) => {
            if(element.type==='individual'){
              if(element.participants.filter((el:any)=>el.user_id===localStorage.getItem('id')).length!==2){
                temp.push(element)
              }
            }else{
              temp.push(element)
            }
        });
      }
      state.rooms = temp;
    },

    setActiveRoomData: (state, action) => {
      let tempParticipants: any = {};
      action?.payload?.participants?.forEach((one: any) => {
        tempParticipants = { ...tempParticipants, [one.user_id]: one };
      });
      state.activeRoomData = action.payload;
      state.formattedParticipantsOfActiveRoom = tempParticipants;
    },

    appendOldChats: (state, action) => {
      state.oldChats = action.payload;
      state.activeRoomChats = action.payload
    },

    appendPreviousChats: (state, action) => {
      const data = [...state.oldChats];
      data.push( ...action.payload);
      state.oldChats = data;
      state.activeRoomChats = data;
    },

    appendNextChats: (state, action) => {
      const data = [...state.oldChats];
      data.unshift( ...action.payload);
      state.oldChats = data;
      state.activeRoomChats = data;
    },
    setFetchData:(state,action)=>{
      state.fetchData=action.payload;
    },

    setActiveRoomChats: (state, action) => {
      const data = [ ...state.oldChats];
      data.unshift(...action.payload)
      state.oldChats = data;
      state.activeRoomChats = data;
    },
    setActiveRoomsChats: (state, action) => {
      const data = [...action.payload];
      state.activeRoomChats = data;
      state.oldChats=data;
    },

    updateActiveRoomChats: (state, action) => {
      const temp=[...state.oldChats];
      temp.push(action.payload)
      state.activeRoomChats = temp;
      state.oldChats = [...temp];
      state.totalReceivedChatsCount = state.totalReceivedChatsCount + 1;
    },

    resetActiveRoomChats: (state, action) => {
      state.activeRoomChats = action.payload;
      state.oldChats = action.payload;
    },

    setUserOnlineStatus: (state, action) => {
      state.userOnlineStatus = action.payload;
    },

    setRoomList: (state, action) => {
      state.roomList = action.payload;
    },
    setCallHistory: (state, action) => {
      state.callHistory = action.payload;
    },

    setPinnedChats: (state, action) => {
      state.pinnedChats = action.payload;
    },

    setFavouriteChats: (state, action) => {
      state.favouriteChats = action.payload;
    },
    setFocusedData:(state,action)=>{
      state.focusData=action.payload;
    },

    setUserMediaByRoomId: (state, action) => {
      state.userMediaByRoomId = action.payload;
    },
    setOnlineRooms:(state,action)=>{
      state.onlineRooms=action.payload;
    },
    setGroupsInCommon: (state, action) => {
      state.groupsInCommon = action.payload;
    },

    setActiveChatsLimit: (state, action) => {
      //state.activeChatsLimit = action.payload;
    },

    setActiveChatSkip: (state, action) => {
      state.activeChatsSkip = action.payload;
    },

    setTotoalChatsCount: (state, action) => {
      state.totalReceivedChatsCount = action.payload;
    },
    setCurrentPage:(state,action)=>{
      state.currentPage=action.payload
    },
    setTotalPage:(state,action)=>{
      state.totalPage=action.payload
    },

    replaceMessageWithLocalId: (state: any, action) => {
      const { id, newItem } = action.payload;
      const itemIndex = state.activeRoomChats?.findIndex(
        (item: any) => item?.local_Id === id
      );

      if (itemIndex !== -1) {
        state.activeRoomChats[itemIndex] = newItem;
        state.oldChats[itemIndex] = newItem;
      }
    },

    setNewMessageCount: (state, action) => {
      state.newMessageCount = action.payload
    },

    setSearchChats: (state, action) => {
      state.searchChatsResult = action.payload
    },
    setCallDetails: (state, action) => {
      state.callDetails = action.payload;
    },
    setActiveCallData: (state, action) => {
      state.activeCallData = action.payload;
    },
    getCallHistory:(state,action)=>{
      state.fetchCallHistory=action.payload;
    },
    setLeftCallData: (state, action) => {
      state.leftcallData = action.payload;
    },
    setRejectCallData:(state,action)=>{
      state.rejectCallData=action.payload;
    },
    updateCallStatus: (state, action) => {
      state.callisRunning = action.payload;
    },
    updateCreateCall:(state,action)=>{
      state.isCreateCall=action.payload
    },
    setInitiatedCallData: (state, action) => {
      state.initiatedCall = action.payload;
    },
    setNotificationIssue: (state, action) => {
      state.NotificationRequest = action.payload;
    },
    setIsRoomsLoading: (state, action) => {
      state.isRoomsLoading = action.payload
    },
    setOnGoingCallData: (state, action) => {
      state.onGoingCallData = action.payload
    },
    setParticipantsInCall: (state, action) => {
      state.isParticipantsInCall = action.payload
    },
    setIsCallMinimized: (state, action) => {
      state.isCallMinimized = action.payload
    },
    setJoinedFromOtherDevice: (state, action) => {
      state.joinedFromOtherDevice = action.payload
    },
    setRoomsSession:(state,action)=>{
      state.roomsSession=action.payload;
    },
    resetChat:(state,action)=>{
      return initialState;
    },
    setMemberAddedInCall:(state,action)=>{
      state.memberAddedInCall=action.payload;
    },
    setLocation:(state,action)=>{
      state.locationData=action.payload;
    },
    setActiveRoomReminders: (state, action) => {
      state.activeRoomReminders = action.payload
    },
    setSchedules: (state, action) => {
      state.schedules = action.payload
    },
    setIsRoomLoading: (state, action)=> {
      state.isRoomLoading = action.payload
    },
  },
});

export const {
  setActiveRoomChats,
  setActiveRoomData,
  setRooms,
  updateActiveRoomChats,
  setUserOnlineStatus,
  setRoomList,
  setPinnedChats,
  setFavouriteChats,
  setUserMediaByRoomId,
  setGroupsInCommon,
  setActiveChatSkip,
  setActiveChatsLimit,
  setTotoalChatsCount,
  replaceMessageWithLocalId,
  appendOldChats,
  setNewMessageCount,
  setSearchChats,
  appendPreviousChats,
  appendNextChats,
  setCallHistory,
  setCallDetails,
  updateCallStatus,
  updateCreateCall,
  setActiveCallData,
  setFocusedData,
  getCallHistory,
  setLeftCallData,
  setRejectCallData,
  setInitiatedCallData,
  setNotificationIssue,
  setIsRoomsLoading,
  setOnGoingCallData,
  setParticipantsInCall,
  setIsCallMinimized,
  setJoinedFromOtherDevice,
  resetActiveRoomChats,
  setFetchData,
  setActiveRoomsChats,
  setOnlineRooms,
  setRoomsSession,
  resetChat,
  setCurrentPage,
  setTotalPage,
  setMemberAddedInCall,
  setLocation,
  setActiveRoomReminders,
  setSchedules,
  setIsRoomLoading,
} = ChatSlice.actions;
export default ChatSlice.reducer;
