import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { FormPropsType } from "../../../types/types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { EventTypeEnum, RecurrentTypes } from "../../../types/enum";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import React from "react";
import { getCalendarColor } from "../../../utils/calendarColors";

function EventDates(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm, errors, setErrors } = props;

  function handleDateChange(type: string, date: Date) {
    const tempForm = { ...form };
    const newErrors = { ...errors };
    tempForm[type] = date;
    const isPast =
      type == "start"
        ? dayjs(date).isBefore(dayjs()) || dayjs(date).isSame(dayjs())
        : false;

    if (tempForm?.frequency != RecurrentTypes.ONCE) {
      if (type == "start") {
        if (date > form?.end) {
          newErrors.start = "start_date_error";
        } else {
          delete newErrors.start;
          delete newErrors.end;
        }
      } else if (type == "end") {
        if (date < form?.start) {
          newErrors.start = "end_date_error";
        } else {
          delete newErrors.end;
          delete newErrors.start;
        }
      }
    }

    const difference = dayjs(tempForm?.end).diff(tempForm?.start, "day") + 1;

    if (form?.frequency == RecurrentTypes.WEEKLY) {
      if (difference < 7) {
        newErrors.start = "week_diff_error";
        newErrors.end = "week_diff_error";
      } else {
        delete newErrors.end;
        delete newErrors.start;
      }
    } else if (form?.frequency == RecurrentTypes.MONTHLY) {
      if (difference < 30) {
        newErrors.start = "month_diff_error";
        newErrors.end = "month_diff_error";
      } else {
        delete newErrors.end;
        delete newErrors.start;
      }
    }

    setForm({
      ...form,
      months: [],
      [type]: date,
      end:
        form?.frequency == RecurrentTypes.ONCE ? tempForm.start : tempForm?.end,
      isAllDay: isPast ? false : form?.isAllDay,
      time: isPast ? dayjs().add(30, "minute") : form?.time,
      endTimes: isPast ? dayjs().add(31, "minute") : form?.endTimes,
    });
    setErrors(newErrors);
  }

  function handleTimeChange(type: string, date: Date) {
    const tempForm = { ...form };
    const newErrors = { ...errors };

    if (type === "time") {
      if (
        tempForm?.type == EventTypeEnum.REMINDER ||
        tempForm?.type == EventTypeEnum.CALLREMINDER
      ) {
        tempForm["time"] = date;
        tempForm["endTimes"] = date;
      } else {
        tempForm[type] = date;
      }

      if (tempForm?.endTimes && tempForm?.endTimes < date) {
        newErrors.endTimes = "end_time_error";
      } else {
        delete newErrors.endTimes;
      }
    } else if (type === "endTimes") {
      tempForm[type] = date;

      if (tempForm?.time && tempForm?.time > date) {
        newErrors.endTimes = "end_time_error";
      } else {
        delete newErrors.endTimes;
      }
    }

    setForm(tempForm);
    setErrors(newErrors);
  }

  function handleCheckbox(checked: boolean) {
    const newErrors = { ...errors };
    delete newErrors.time;
    delete newErrors.endTimes;
    setErrors(newErrors);
    setForm({
      ...form,
      isAllDay: checked,
      time: checked ? dayjs()?.set("hours", 0).set("minutes", 0) : dayjs(),
      endTimes: checked ? dayjs()?.endOf("day") : dayjs(),
    });
  }

  return (
    <div className="flex gap-3 mt-3">
      <CalendarMonthOutlinedIcon
        fontSize="medium"
        sx={{
          color: getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full -mt-[8px]">
        {form?.frequency == RecurrentTypes.ONCE && (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={form?.isAllDay}
                onChange={(e) => handleCheckbox(e.target.checked)}
                disabled={Boolean(
                  dayjs(form?.start).isBefore(dayjs()) ||
                    dayjs(form?.start).isSame(dayjs())
                )}
                sx={{
                  ".MuiSvgIcon-root": {
                    color: getCalendarColor(form?.type, "dark"),
                  },
                }}
              />
            }
            label={<div className="text-sm">{t("all_Day")}</div>}
          />
        )}

        <div className="grid gap-3 grid-cols-2">
          {form?._id ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  className="w-full"
                  minDate={dayjs()}
                  label={t("date")}
                  format="DD MMM YYYY"
                  value={form?.date}
                  onChange={(date) => setForm({ ...form, date })}
                  slotProps={{
                    textField: {
                      error: Boolean(errors?.date),
                      helperText: t(errors?.date),
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          ) : (
            <React.Fragment>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    className="w-full"
                    minDate={dayjs()}
                    format="DD MMM YYYY"
                    label={t("start_date")}
                    value={form?.start}
                    onChange={(date) => handleDateChange("start", date)}
                    slotProps={{
                      textField: {
                        error: Boolean(errors?.start),
                        helperText: t(errors?.start),
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    className="w-full"
                    label={t("end_date")}
                    format="DD MMM YYYY"
                    value={form?.end}
                    minDate={dayjs(form?.start)}
                    disabled={Boolean(form?.frequency == RecurrentTypes.ONCE)}
                    onChange={(date) => handleDateChange("end", date)}
                    slotProps={{
                      textField: {
                        error: Boolean(errors?.end),
                        helperText: t(errors?.end),
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </React.Fragment>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                className="w-full"
                label={t("start_time")}
                value={form?.time}
                disabled={Boolean(form?.isAllDay)}
                timeSteps={{ hours: 1, minutes: 1 }}
                // ampm={form?.isAllDay ? false : true}
                ampm={false}
                onChange={(date) => handleTimeChange("time", date)}
                disablePast={dayjs(form?.start)
                  .startOf("day")
                  ?.isSame(dayjs().startOf("day"))}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                className="w-full"
                label={t("end_time")}
                value={form?.endTimes}
                // ampm={form?.isAllDay ? false : true}
                ampm={false}
                onChange={(date) => handleTimeChange("endTimes", date)}
                disabled={
                  Boolean(
                    form?.type == EventTypeEnum.REMINDER ||
                      form?.type == EventTypeEnum.CALLREMINDER
                  ) ||
                  Boolean(
                    form?.frequency == RecurrentTypes.ONCE && form?.isAllDay
                  )
                }
                slotProps={{
                  textField: {
                    error: Boolean(errors?.endTimes),
                    helperText: t(errors?.endTimes),
                  },
                }}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
}

export default EventDates;
