import React, { createRef, useState } from "react";
import {
  AttachmentType,
  FileUploadResponseType,
  FormPropsType,
} from "../../../types/types";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { Button, Chip } from "@mui/material";
import { mutationClient } from "../../../../../Graphql/authClient";
import checkFileType from "../../../../../utils/checkFileType";
import { useTranslation } from "react-i18next";
import { getSession } from "../../../../../utils/session";
import { NEW_UPLOAD_FILE } from "../../../../../Graphql/Mutations";
import dayjs from "dayjs";
import { DefaultImageUrl } from "../../../../../Constant/Constant";
import FilePreview from "../../../../../Pages/RightTab/database/FilePreview";
import { getCalendarColor } from "../../../utils/calendarColors";

enum FileUploadStatus {
  Loading = "loading",
  Idle = "idl",
  Error = "error",
  Success = "success",
}

function EventAttachments(props: FormPropsType) {
  const { t } = useTranslation();
  const fileInputRef: any = createRef();
  const { form, setForm, errors, setErrors } = props;
  const [filePreview, setFilePreview] = useState({ show: false, file: null });
  const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>(
    FileUploadStatus.Idle
  );

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  function pushItemInArray(data: FileUploadResponseType) {
    const temp: AttachmentType[] = [...form?.attachments];

    const splittedFileName = data?.filename?.split(".");
    const fileExtension = splittedFileName?.pop();
    const fileType = checkFileType(fileExtension);

    temp.push({
      type: fileType,
      url: data?.url,
      name: data?.filename,
      thumbnail: null,
      duration: 0,
      uploadedAt: dayjs().toISOString(),
      mimeType: fileExtension,
    });
    setForm({ ...form, attachments: temp });
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files?.[0];
    const fileSizeInMegabytes: any = file?.size / (1024 * 1024);
    const newErrors = { ...errors };

    if (form?.attachments?.length < 5) {
      if (file && !file?.type) {
        newErrors.attachments = "invalid_file";
      } else if (parseInt(fileSizeInMegabytes) > 10) {
        newErrors.attachments = "file_size_error";
      } else {
        delete newErrors.attachments;
  
        setFileUploadStatus(FileUploadStatus.Loading);
        const { token } = getSession();
  
        await mutationClient(token)
          .mutate({
            mutation: NEW_UPLOAD_FILE,
            variables: {
              file,
              input: {
                orgId: "",
                attachments: true,
              },
            },
          })
          .then((res) => {
            if (!res?.data) {
              setFileUploadStatus(FileUploadStatus.Error);
            } else {
              pushItemInArray(res?.data?.uploadFileRecord);
              setFileUploadStatus(FileUploadStatus.Success);
            }
          })
          .catch((err) => {
            console.log(err);
            setFileUploadStatus(FileUploadStatus.Error);
          });
      }
      setErrors(newErrors);
    } else {
      alert(t("attachment_max_error"));
    }
  };

  function handleDelete(index: number) {
    const updated = form?.attachments?.filter(
      (item, key) => key != index && item
    );
    setForm({ ...form, attachments: updated });

    const newErrors = { ...errors };
    delete newErrors.attachments;
    setErrors(newErrors);
  }

  const handleClickOnFile = (file: any) => {
    const types = ["AUDIO", "VIDEO", "PHOTO", "IMAGE"];
    if (types.includes(file?.type)) {
      setFilePreview({ file, show: true });
    } else {
      const source = DefaultImageUrl + file?.url;
      window.open(source);
    }
  };

  return (
    <div className="flex gap-3 mt-2">
      <AttachFileOutlinedIcon
        fontSize="medium"
        sx={{
          color: getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full">
        <Button
          color="primary"
          onClick={handleClick}
          sx={{
            minWidth: 120,
            fontWeight: 600,
            textTransform: "capitalize",
            color: "gray",
          }}
          disabled={Boolean(fileUploadStatus == FileUploadStatus.Loading)}
        >
          {t("add_attachment")}
        </Button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {form?.attachments?.length > 0 && (
          <div className="mt-1">
            <div className="flex flex-wrap gap-3">
              {form?.attachments?.map((item, index) => {
                const splitted = item?.name?.split("name-");
                const fileName = splitted?.pop();

                return (
                  <Chip
                    key={index}
                    variant="outlined"
                    onClick={() => handleClickOnFile(item)}
                    label={
                      <div className="font-medium text-[12px]">{fileName}</div>
                    }
                    onDelete={() => handleDelete(index)}
                  />
                );
              })}
            </div>
          </div>
        )}

        {errors?.attachments && (
          <p className="text-sm text-red-600 mt-2">{t(errors?.attachments)}</p>
        )}

        {fileUploadStatus == FileUploadStatus.Error && (
          <div className="text-sm text-red-600 mt-2">
            {t("something_went_wrong")}
          </div>
        )}
      </div>

      {filePreview?.show && (
        <FilePreview
          filePreview={filePreview}
          setFilePreview={setFilePreview}
        />
      )}
    </div>
  );
}

export default EventAttachments;
