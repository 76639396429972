import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import parse from "html-react-parser";
import { DefaultImageUrl } from "../../Constant/Constant";
import { BsCameraVideoFill, BsHeadphones, BsPeople } from "react-icons/bs";
import ArticleIcon from "@mui/icons-material/Article";
import { useTranslation } from "react-i18next";

export default function ReplyFooter({
  replyFooterShow,
  replyMessage,
  setReplyFooterShow,
}) {
  const { userData, formattedContact, } = useSelector((state: any) => state.user);
  const { activeRoomData } = useSelector((state: any) => state.chat);
  const { t } = useTranslation();
  const userObject = activeRoomData.participants?.find(
    (item: any) => item.user_id === replyMessage.sender
  );
  const contactDetail = formattedContact?.[userObject?.user_id]

  const FormattedMessage = (message: any) => {
    const regex =
      /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

    const formattedText = message?.replace(
      regex,
      (match: any, p1: any, p2: any, p3: any, p4: any, p5: any, p6: any, p7:any, p8:any) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        } else if (p5) {
          return (
            `<a href=${p5} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
            p5 +
            "</a>"
          );
        } else if (p8) {
          const participant = activeRoomData?.participants?.find(part => part?.user_id == p8)
          const self = userData?._id == participant?.user_id
          const formatPart = formattedContact?.[participant?.user_id]
          const mentionedName = self 
          ? `${t("you")} `
          : formatPart?.firstName
          ? formatPart?.firstName + " " + formatPart?.lastName
          : participant
          ? participant?.phone
          : p7;
          return '<span style="color: green;">' + "@" + mentionedName + "</span>"
        }

        return match;
      }
    );
    return formattedText;
  };
  
  return (
    replyFooterShow && (
      <div className="h-auto py-2  gap-4 flex items-center px-[10%] absolute bottom-[5rem] right-0 left-0 bg-white rounded-t-md">
        <div className="bg-gray-200 w-[90%] p-2 border-l-4 border-blue-400">
          <div className="text-blue-500">
            {replyMessage?.sender === userData?._id
              ? t("you")
              : contactDetail?.firstName 
              ? contactDetail?.firstName + " " + contactDetail?.lastName
              : userObject?.phone}
          </div>

          {replyMessage?.type === "text" && (
            <div className="text-gray-600 text-sm line-clamp-2">
              {replyMessage.message !== ""
                ? parse(FormattedMessage(replyMessage.message))
                : ""}
            </div>
          )}

          {replyMessage?.type === "IMAGE" && (
            <>
              <div className="mt-2 flex gap-2">
                <img
                  src={DefaultImageUrl + replyMessage?.fileURL}
                  alt="img"
                  className="h-[40px] w-[40px] rounded-md opacity-75"
                />
                <span className="text-gray-600 text-sm">
                  {replyMessage?.fileURL.split("/").pop()}
                </span>
              </div>
              <div className="text-gray-400">
                {replyMessage?.message !== ""
                  ? replyMessage?.message?.length > 100
                    ? replyMessage?.message.slice(0, 170) + "..."
                    : replyMessage?.message
                  : ""}
              </div>
            </>
          )}
          {replyMessage?.type === "AUDIO" && (
            <>
              <div className="mt-2 flex gap-2">
                <BsHeadphones
                  className="text-xl"
                  style={{ color: "#33ccff" }}
                />
                <span className="text-gray-600 text-sm">
                  {replyMessage?.fileURL.split("/").pop()}
                </span>
              </div>
              <div className="text-gray-400">
                {replyMessage?.message !== ""
                  ? replyMessage?.message?.length > 100
                    ? replyMessage?.message.slice(0, 170) + "..."
                    : replyMessage?.message
                  : ""}
              </div>
            </>
          )}
          {replyMessage?.type === "VIDEO" && (
            <>
              <div className="mt-2 flex gap-2">
                <BsCameraVideoFill
                  className="text-xl"
                  style={{ color: "#33ccff" }}
                />
                <span className="text-gray-600 text-sm">
                  {replyMessage?.fileURL.split("/").pop()}
                </span>
              </div>
              <div className="text-gray-400">
                {replyMessage?.message !== ""
                  ? replyMessage?.message?.length > 100
                    ? replyMessage?.message.slice(0, 170) + "..."
                    : replyMessage?.message
                  : ""}
              </div>
            </>
          )}
          {replyMessage?.type === "contact" && (
            <div>
              <div className="flex items-center gap-1">
                <BsPeople color="primary" fontSize="large" />
                Contact:
              </div>
              <div className="px-4 py-4 flex gap-3 items-center border-b bg-[#E0FAFF] w-fit rounded-xl mt-1">
                <div className="h-[45px] w-[45px] min-w-[45px]">
                  <img
                    src={
                      DefaultImageUrl +
                      JSON.parse(replyMessage?.message)?.profile_img
                    }
                    alt=""
                    className="rounded-full h-full w-full"
                  />
                </div>
                <div className="text-lg font-semibold">
                  <span> {JSON.parse(replyMessage?.message)?.firstName} </span>
                  <span className="ml-1">
                    {" "}
                    {JSON.parse(replyMessage?.message)?.lastName}{" "}
                  </span>
                  <div className="text-base font-normal">
                    {JSON.parse(replyMessage?.message)?.phone}
                  </div>
                </div>
              </div>
            </div>
          )}

          {replyMessage?.type !== "IMAGE" &&
            replyMessage?.type !== "text" &&
            replyMessage?.type !== "VIDEO" &&
            replyMessage?.type !== "AUDIO" &&
            replyMessage?.type !== "contact" && (
              <>
                <div>
                  <ArticleIcon color="primary" fontSize="large" />
                  <span className="text-gray-600 text-sm">
                    {replyMessage?.fileURL.split("/").pop()}
                  </span>
                </div>
                <div className="text-gray-400">
                  {replyMessage?.message !== ""
                    ? replyMessage?.message?.length > 100
                      ? replyMessage?.message.slice(0, 170) + "..."
                      : replyMessage?.message
                    : ""}
                </div>
              </>
            )}
        </div>
        <div className="w-[10%]">
          <button
            className="outline-none"
            onClick={() => setReplyFooterShow(false)}
          >
            <CancelIcon color="disabled" />
          </button>
        </div>
      </div>
    )
  );
}
