import { Avatar, CircularProgress, Drawer } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import Vector from "../../Assets/info_outline.svg";
import { authCllient } from "../../Graphql/authClient";
import { CALL_LIST } from "../../Graphql/Queries";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import AddCall from "../../Assets/Images/Addcall.png";
import { incoming, outgoing } from "../../utils/Call/Callhistory";
import { DefaultImageUrl } from "../../Constant/Constant";
import moment from "moment";
import CallHistory from "./CallHistory";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ContactCall from "./ContactCall";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import gif from '../../Assets/S7MyZax57y.gif';
import { CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { setActiveCallData, setCallHistory, setInitiatedCallData, setIsCallMinimized, setMemberAddedInCall, updateCallStatus, updateCreateCall } from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { RoomsDataType } from "../../Types/Types";
import GroupAvatar from "../../Assets/Images/Avatar.png";
import { emit } from "../../Socket";
import { useTranslation } from "react-i18next";
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function Call() {
  const [dataloading, setDataLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const [activeTab, setActiveTab] = useState<Number>(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [callHistory, setCallHistory] = useState([]);
  const [missedCallData, setMissedCallData] = useState([]);
  const [openCallHistoryDrawer, setOpenCallHistoryDrawer] = useState(false);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [categoryIdData, setCategoryIdData] = useState(null);
  const [loader,setLoader]=useState(false);
  const [roomIdData, setRoomIdData] = useState(null);
  const [selectedUser, setSelectedUser] = useState({ name: "", data: { type: "", callParticipants: [], _id: "", roomId: { _id: "" }, roomType: "" } });
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const { rooms, callHistory, callisRunning, joinedFromOtherDevice, fetchCallHistory, onGoingCallData, activeCallData, activeRoomData, isParticipantsInCall } = useSelector(
    (state: any) => state.chat
  );
  const onGoingCallRoomData = onGoingCallData?.find((item: any) =>
    item?.roomId?._id ? item?.roomId?._id : item?.roomId === activeRoomData?._id
  );
  const postRef = createRef<HTMLDivElement>();
  const [currentPageNo, setcurrentPageNo] = useState(0);

  const joinOnGoingCall = (callId) => {
    if (
      activeCallData?.data?.channelName === onGoingCallRoomData?.channelName
    ) {
      dispatch(setIsCallMinimized(false));
      dispatch(updateCallStatus(true));
    } else {

      if (onGoingCallRoomData?.callId || onGoingCallRoomData?._id) {
        changeCallStatus({
          variables: {
            changeCallStatus: {
              callId: callId,
              status: "accepted",
              userId: userData._id,
            },
          },
        })
          .then((res) => {
            if (res?.data) {
              const data = { data: onGoingCallRoomData };
              dispatch(setActiveCallData(data));
              dispatch(setIsCallMinimized(false));
              dispatch(updateCallStatus(true));
            }
          })
      }
    }
  };
  const getCallHistoryData = (status: string, limit: number) => {
    authCllient
      .query({
        query: CALL_LIST,
        variables: {
          input: {
            skip: limit,
            callStatus: "all",
            limit: 25,
          },
        },
        fetchPolicy:"network-only"
      })
      .then((res) => {
        if(limit===0){
          const temp = [ ...res.data?.getMyCallList];
          dispatch(setCallHistory(temp));
        }else{
          const temp = [...callHistory, ...res.data?.getMyCallList];
          dispatch(setCallHistory(temp));
        }
       
        const missed = res.data?.getMyCallList?.filter(
          (item: any) => item?.callStatus === "missed" && item
        );
        setMissedCallData(missed);
      })
      .catch((error) => console.log("error at fetch call history", error))
      .finally(() => { setLoading(false); setDataLoading(false) });
  };


  useEffect(() => {
    
      setLoading(true);
      getCallHistoryData('all', currentPageNo);
     
    
  }, [fetchCallHistory])

 

  const onScroll = () => {
    if (postRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = postRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!dataloading) {
          getCallHistoryData('all', currentPageNo + 25);
        }
        setcurrentPageNo(currentPageNo + 25)
        setDataLoading(true)
      }
    }
  };

  const handleCallDetails = (categoryId: string, item: any) => {
    setOpenCallHistoryDrawer(true);
    setCategoryIdData(categoryId);
    setRoomIdData(item);
  };



  const initiateCall = () => {
    setLoader(true);
    setShow(false);
    const participants = [];
    let userId = ''
    selectedUser?.data?.callParticipants?.map((item: any) => {
      if (item?.userId._id !== userData._id) {
        userId = item?.userId._id;
      }
    });
    selectedUser?.data?.callParticipants?.map((item: any) => {
      participants.push(item?.userId._id);
    });
    let param = {};
    if (selectedUser.data.roomType === 'group' || selectedUser.data.roomType === 'individual') {
      dispatch(setMemberAddedInCall(participants))

      param = {
        origin: String(userData._id),
        participants: participants,
        channelName: String(new Date().getTime()),
        type: selectedUser.data.type,
        roomId: selectedUser.data.roomType==='individual'&&participants.length>2?"":selectedUser?.data?.roomId?._id,
        roomType: selectedUser.data.roomType==='individual'&&participants.length>2?'contact_group':selectedUser.data.roomType,
      }
    } else {
      dispatch(setMemberAddedInCall(selectedUser.data.roomType === "contact_group"
      ? participants
      : [userId]))

      param = {
        origin: String(userData._id),
        participants: selectedUser.data.roomType === "contact_group"
          ? participants
          : [userId, userData?._id],
        channelName: String(new Date().getTime()),
        type: selectedUser.data.type,
        roomType: selectedUser.data.roomType,
      }
    }
    dispatch(updateCreateCall(true));
    authCllient
      .mutate({
        mutation: CREATE_CALL,
        variables: {
          input: param,
        },
      })
      .then((res) => {
        handleChangeCallStatus(res?.data?.createNewCall?.call?._id)
        dispatch(setInitiatedCallData(res?.data?.createNewCall?.call));
      })
      .catch((error) => {
          alert(error.message)        
        console.log("error at Create call", error.message)}).finally(()=>{setLoader(false)})
   }

   const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contactCallRoomPicture: any = (participants: any) => {
    let picture = "";
    if (participants?.roomType === "contact") {
      const otherUser = participants?.callParticipants?.find(
        (item: any) => item?.userId?._id !== userData?._id
      );
      picture = DefaultImageUrl + otherUser?.userId?.profile_img;
    } else if (participants?.roomType === "individual") {
      const roomData = rooms?.find(
        (item: RoomsDataType) => item?._id === participants?.data?.roomId?._id
      );
      const participant = participants?.callParticipants?.find((item: any) =>
        item?.userId?._id
          ? item?.userId?._id !== userData?._id
          : item?.user_id !== userData?._id
      );
    
      picture = participant!==undefined?DefaultImageUrl + participant?.userId?.profile_img:"";
    } else {
      picture = GroupAvatar;
    }
    return picture;
  };

  const contactCallRoomName: any = (participants: any) => {
    let roomName = "";

    if (participants?.roomType === "group") {
      const roomData = rooms?.find((item: RoomsDataType) =>
        !participants?.roomId?._id
          ? item?._id === participants?.roomId
          : item?._id === participants?.roomId?._id
      );
      roomName = roomData?.name;
    } else if (participants?.roomType === "individual"&&participants?.callParticipants?.length===2) {
      const roomData = rooms?.find(
        (item: RoomsDataType) => item?._id === activeCallData?.data?.roomId
      );
      const participant = participants.callParticipants?.find((item: any) =>
        item?.userId
          ? item?.userId?._id !== userData?._id
          : item?.user_id !== userData?._id
      );
      const contactName =participant!==undefined? formattedContact[participant.userId ? participant.userId._id : participant?.user_id] === undefined ? participant.userId.phone :
        formattedContact[participant.userId ? participant.userId._id : participant?.user_id]?.firstName +
        " " +
        formattedContact[participant.userId ? participant.userId._id : participant?.user_id]?.lastName:"";

      roomName = contactName ? contactName : participant?.phone;
    } else if ((participants?.roomType === "individual"&&participants?.callParticipants?.length>2)||participants?.roomType === "contact_group" || participants?.roomType === "contact") {
      const otherUsers = participants?.callParticipants.filter(
        (item: any) => item?.userId?._id !== userData?._id
      );
      const phone = otherUsers[0]?.userId?.phone;
      const contactNameOne =
        formattedContact[otherUsers[0].userId ? otherUsers[0].userId._id : otherUsers[0]?.user_id] === undefined ? otherUsers[0].userId.phone :
          formattedContact[otherUsers[0]?.userId._id]?.firstName +
          " " +
          formattedContact[otherUsers[0]?.userId._id]?.lastName
      // otherUsers[0]?.userId?.firstName?otherUsers[0]?.userId?.firstName +
      //     " " +
      //     otherUsers[0]?.userId?.lastName
      //   : otherUsers[0]?.userId?.phone;

      const contactNameTwo = formattedContact[otherUsers[1]?.userId ? otherUsers[1]?.userId._id : otherUsers[1]?.user_id] === undefined ? otherUsers[1]?.userId?.phone :
        formattedContact[otherUsers[1]?.userId._id]?.firstName +
        " " +
        formattedContact[otherUsers[1]?.userId._id]?.lastName;

      if (otherUsers?.length === 2) {
        roomName = contactNameOne + ", " + contactNameTwo;
      } else if (otherUsers?.length === 1) {
        roomName = contactNameOne ? contactNameOne : phone;
      } else {
        roomName = contactNameOne + ", " + contactNameTwo + " "+t('and_others');
      }
    }

    return roomName;
  };

  
  return (
    <div>
      {loader && (
        <div className="h-screen w-screen bg-[#0000009c] fixed z-[999999999] left-0 right-0 top-0 bottom-0 flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img
                alt={t("loading")}
                src={gif}
                className="h-36 w-36"
              />
            </div>
          </div>
        </div>
      )}
      <Drawer
        anchor={"right"}
        open={openCallHistoryDrawer}
        onClose={() => setOpenCallHistoryDrawer(false)}
      >
        <div className="w-[500px] p-2 h-screen overflow-auto">
          <CallHistory
            setHistoryDetails={setOpenCallHistoryDrawer}
            UserDetails={roomIdData}
            CategoryIdData={categoryIdData}
          />
        </div>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={openContactDrawer}
        onClose={() => setOpenContactDrawer(false)}
      >
        <div className="w-[500px] h-screen overflow-auto">
          <ContactCall setContactCall={setOpenContactDrawer} />
        </div>
      </Drawer>

      <div
        className="my-3 m-2 rounded flex items-center px-2 bg-white"
        style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
        onClick={() => setOpenContactDrawer(true)}
      >
        <SearchIcon sx={{ color: "#333333" }} />
        <div
          className="w-full text-gray-400 py-2 px-2 focus:outline-none cursor-pointer text-sm"
        >{t("searchPlaceholder")}</div>
        <div
          className="my-auto mr-3"

        >
          <img src={AddCall} className="h-7 cursor-pointer w-7" alt={t('add_call')} />
        </div>
      </div>

      <div className="flex text-sm pt-4 border-b">
        <div
          className={
            activeTab === 1
              ? "border-botom-primary text-center px-7 w-1/2 pb-2 cursor-pointer"
              : "text-center px-7 w-1/2 pb-2 cursor-pointer"
          }
          onClick={() => setActiveTab(1)}
        >
          {t("all")}
        </div>
        <div
          className={
            activeTab === 2
              ? "border-botom-primary text-center px-7 w-1/2 pb-2 cursor-pointer"
              : "text-center px-7 w-1/2 pb-2 cursor-pointer"
          }
          onClick={() => setActiveTab(2)}
        >
          {t("missed")}
        </div>
      </div>

      <Modal
        open={show}
        onClose={() => { setShow(false) }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }} className='text-center'>
          <h1 id="parent-modal-title" className="text-[22px]">{t("do_you_want_call")}</h1>
          <h3 className="my-2">{selectedUser?.name}</h3>
          <div className="flex gap-10 justify-center mt-4">
            <button className="bg-[#e0e0e0] text-primary rounded-full p-2 px-6" onClick={() => { setShow(false); setSelectedUser({ name: "", data: { type: "", callParticipants: [], _id: "", roomId: { _id: "" }, roomType: "" } }) }}>{t('cancel')}</button>
            <button className="bg-[#33ccff] text-white rounded-full p-2 px-6" onClick={() => {
              initiateCall()
            }}>{t('call')}</button>
          </div>
        </Box>
      </Modal>

      {activeTab === 1 ? (
        loading ? (
          <div className="flex xxl-h-67px justify-center mt-6">
            <CircularProgress size={40} />
          </div>
        ) : (
          <div className="overflow-y-auto xxl-h-67px" ref={postRef}
            onScroll={onScroll}>
            {callHistory?.length > 0 ? (
              callHistory?.map((item: any, index: number) => {
                const individualDetail =
                  item?.call?.roomType !== "group"
                    ? item?.call?.callParticipants?.find(
                      (elem: any) => elem?.userId?._id !== userData?._id
                    )
                    : null;

                const userContactDetails =
                  formattedContact[individualDetail?.userId?._id];

                const currentDate = moment(new Date()).format("DD-MM-YYYY");
                const gettingDate = moment(item?.call?.callStartedAt).format(
                  "DD-MM-YYYY"
                );

                  if(item?.call===null){
                    return;
                  }
                return (
                  <div
                    className="flex justify-between py-3 my-1 px-3  cursor-pointer"
                    key={index}

                  >
                    <div className="flex"
                      onClick={() => {
                        if (
                          callisRunning === false &&
                          !joinedFromOtherDevice
                        ) {
                          setShow(true);
                        } else {
                          alert(
                           t("call_validation")
                          );
                        }

                        setSelectedUser({
                          name: item?.call?.roomType === "group"
                            ? item?.call?.roomId?.name
                            : userContactDetails
                              ? userContactDetails?.firstName +
                              " " +
                              userContactDetails?.lastName
                              : individualDetail?.userId?.phone, data: item.call
                        })
                      }}>
                      <div className="h-[45px] w-[45px] min-w-[45px]">
                        <img
                          src={contactCallRoomPicture(
                            item?.call
                          )}
                          alt=""
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <div className="pl-[14px]">
                        <div>
                          {contactCallRoomName(
                            item?.call
                          )}
                          {item.count > 1 ? <span>{`(${item.count})`}</span> : ""}
                        </div>
                        <div className="flex gap-2 mt-2">
                          <div className="h-[16px] w-[16px]">
                            {item?.call?.origin === userData?._id ? (
                              <img
                                src={
                                  item?.call?.callStatus === "accepted"
                                    ? item?.call?.type === "video"
                                      ? outgoing.accepted.videoIcon
                                      : outgoing.accepted.audioIcon
                                    : item?.call?.type === "video"
                                      ? outgoing.missed.videoIcon
                                      : outgoing.missed.audioIcon
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  item?.call?.callStatus === "accepted"
                                    ? item?.call?.type === "video"
                                      ? incoming.accepted.videoIcon
                                      : incoming.accepted.audioIcon
                                    : item?.call?.type === "video"
                                      ? incoming.missed.videoIcon
                                      : incoming.missed.audioIcon
                                }
                                alt=""
                              />
                            )}
                          </div>
                          <div className="textGray text-[13px]">
                            {item?.call?.callStatus === "missed"
                              ? t("missed_call")
                              : item?.call?.origin === userData?._id
                                ? t("outgoing")
                                : t("incoming")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-auto">
                      <div className="flex items-center gap-2 justify-end mb-1">
                        <div className="textGray text-[12px]">
                          {
                            gettingDate === currentDate
                              ? `${moment(item?.call?.callStartedAt).format('HH:mm ')}`
                              : gettingDate ===
                                moment().subtract(1, "d").format("DD-MM-YYYY")
                                ? t(`yesterday`)
                                :
                                moment(item?.call?.callStartedAt).format(
                                  "DD-MM-YYYY HH:mm "
                                )}
                        </div>
                        <div
                          className="h-[18px] min-w-[18px] w-[18px] cursor-pointer"
                          onClick={() =>
                            handleCallDetails(item?.call?.categoryId, item)
                          }
                        >
                          <img src={Vector} alt="" className="h-full w-full" />
                        </div>
                      </div>
                      <div>
                        {isParticipantsInCall?.includes(item?.call?.roomId?._id)&&onGoingCallData.filter((el:any)=>el?.channelName===item?.call?.channelName).length>0 && (
                          item.call?.roomType === "group" ? (
                            <button
                              className="flex items-center ml-4 bg-[#33ccff] text-white w-fit py-1 px-2 text-center text-sm rounded-md cursor-pointer disabled:opacity-60"
                              onClick={()=>joinOnGoingCall(item?.call?._id)}
                              disabled={callisRunning ? true : false}
                            >
                              <CallOutlinedIcon className="mr-2" />
                              {callisRunning ? t("joining") : t("join_call")}
                            </button>
                          ) : (
                            ""
                          ))}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mt-6 text-lg text-center">
                {t("call_history_not_found")}
              </div>
            )}
            {dataloading && <div className="flex justify-center mx-auto ">
              <CircularProgress size={40} />
            </div>}
          </div>
        )
      ) : loading ? (
        <div className="flex justify-center mt-6">
          <CircularProgress size={40} />
        </div>
      ) : (
        <div className=" overflow-y-auto h-[74vh] " ref={postRef}
          onScroll={onScroll}>
          {missedCallData?.length > 0 ? (
            missedCallData?.map((item: any, index: number) => {
              const individualDetail =
                item?.call?.roomType !== "group"
                  ? item?.call?.callParticipants?.find(
                    (elem: any) => elem?.userId?._id !== userData?._id
                  )
                  : null;

              const userContactDetails =
                formattedContact[individualDetail?.userId?._id];

              const currentDate = moment(new Date()).format("DD-MM-YYYY ");
              const gettingDate = moment(item?.call?.callStartedAt).format(
                "DD-MM-YYYY"
              );

              if(item?.call===null){
                return;
              }
              return (
                <div
                  className="flex justify-between py-3 my-1 px-3 cursor-pointer"
                  key={index}

                >
                  <div className="flex" onClick={() => {
                    if (
                      callisRunning === false &&
                      !joinedFromOtherDevice
                    ) {
                      setShow(true);
                    } else {
                      alert(
                        t('call_validation')
                      );
                    }
                    setSelectedUser({
                      name: item?.call?.roomType === "group"
                        ? item?.roomId?.name
                        : userContactDetails
                          ? userContactDetails?.firstName +
                          " " +
                          userContactDetails?.lastName
                          : individualDetail?.userId?.phone, data: item.call
                    })
                  }}>
                    <div className="h-[45px] w-[45px] min-w-[45px]">
                      {item?.call?.roomType === "group" ? (
                        <img
                          src={DefaultImageUrl + item?.call?.roomId?.profile_img}
                          alt=""
                          className="h-full w-full rounded-full"
                        />
                      ) : individualDetail?.userId?.profile_img ? (
                        <img
                          src={
                            DefaultImageUrl +
                            individualDetail?.userId?.profile_img
                          }
                          alt=""
                          className="h-full w-full rounded-full"
                        />
                      ) : (
                        <Avatar
                          src="https://storage.googleapis.com/comon-bucket/resources/images/16694514102-individual_default.png"
                          sx={{ height: "100%", width: "100%" }}
                        />
                      )}
                    </div>
                    <div className="pl-[14px]">
                      <div>
                        {contactCallRoomName(item.call)}
                        {item.count > 1 ? <span>{`(${item.count})`}</span> : ""}
                      </div>
                      <div className="flex gap-2 mt-2">
                        <div className="h-[16px] w-[16px]">
                          {item?.call?.origin === userData?._id ? (
                            <img
                              src={
                                item?.call?.callStatus === "accepted"
                                  ? item?.call?.type === "video"
                                    ? outgoing.accepted.videoIcon
                                    : outgoing.accepted.audioIcon
                                  : item?.call?.type === "video"
                                    ? outgoing.missed.videoIcon
                                    : outgoing.missed.audioIcon
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              src={
                                item?.call?.callStatus === "accepted"
                                  ? item?.call?.type === "video"
                                    ? incoming.accepted.videoIcon
                                    : incoming.accepted.audioIcon
                                  : item?.call?.type === "video"
                                    ? incoming.missed.videoIcon
                                    : incoming.missed.audioIcon
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <div className="textGray text-[13px]">
                          {item?.call?.callStatus === "missed"
                            ? t("missed_call")
                            : item?.call?.origin === userData?._id
                              ? t("outgoing")
                              : t("incoming")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-auto">
                    <div className="flex items-center gap-2 justify-end mb-1">
                      <div className="textGray text-[12px]">
                        {gettingDate === currentDate
                          ? `${moment(item?.call?.callStartedAt).format('HH:mm ')}`
                          : gettingDate ===
                            moment().subtract(1, "d").format("DD-MM-YYYY")
                            ? t(`yesterday`)
                            : moment(item?.call?.callStartedAt).format(
                              "DD-MM-YYYY HH:mm "
                            )}
                      </div>
                      <div className="h-[18px] min-w-[18px] w-[18px]" onClick={() =>
                        handleCallDetails(item?.call?.categoryId, item)
                      }>
                        <img src={Vector} alt="" className="h-full w-full" />
                      </div>
                    </div>
                    <div>
                      {isParticipantsInCall?.includes(item?.call?.roomId?._id) && (
                        item.call?.roomType === "group" ? (
                          <button
                            className="flex items-center ml-4 bg-[#33ccff] text-white w-fit py-1 px-2 text-center text-sm rounded-md cursor-pointer disabled:opacity-60"
                            onClick={joinOnGoingCall}
                            disabled={callisRunning ? true : false}
                          >
                            <CallOutlinedIcon className="mr-2" />
                            {callisRunning ? t("joining") : t("join_call")}
                          </button>
                        ) : (
                          ""
                        ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-6 text-lg text-center">
              {t("missed_call_history_not_found")}
            </div>
          )}
          {dataloading && <div className="flex justify-center mx-auto ">
            <CircularProgress size={40} /></div>}
        </div>
      )}
    </div>
  );
}

export default Call;
