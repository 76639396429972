import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import { DefaultImageUrl, GroupDefault } from "../../Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import Call from "../../Assets/Images/call.png";
import VideoCall from "../../Assets/Images/videoCall.png";
import moment from "moment";
import { Checkbox, styled } from "@mui/material";
import { authCllient } from "../../Graphql/authClient";
import { CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { setInitiatedCallData, setMemberAddedInCall, updateCallStatus, updateCreateCall } from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 100,
  width: 25,
  height: 25,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#33ccff",
  paddingTop: 2,
  paddingLeft: 2,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#33ccff",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const ContactCall = ({ setContactCall }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { callisRunning, joinedFromOtherDevice } = useSelector(
    (state: any) => state.chat
  );
  const { comonUsers, userData, blockedMember } = useSelector((state: any) => state.user);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const [groupCall, setGroupCall] = useState(false);
  const [selectedUseres, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const handleGroupCall = (event: any) => {
    let tempMember = [...selectedUseres];
    if (event.target.checked) {
      tempMember.push({ id: event.target.id, name: event.target.name });
    } else {
      tempMember = tempMember.filter(
        (item: any) => item?.id !== event.target.id
      );
    }
    setSelectedUsers(tempMember);
  };

  const handleSearchRooms = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleCallInitiate = (
    callType: string,
    roomType: string,
    userId: string
  ) => {
    const participants = [userData?._id];
    selectedUseres?.forEach((item: any) => {
      participants.push(item?.id);
    });

    setDisabledCallButton(true);
    dispatch(updateCreateCall(true));
    dispatch(setMemberAddedInCall(roomType === "contact_group"
      ? participants
      : [userId]))
    authCllient
      .mutate({
        mutation: CREATE_CALL,
        variables: {
          input: {
            origin: userData?._id,
            participants:
              roomType === "contact_group"
                ? participants
                : [userId, userData?._id],
            channelName: String(new Date().getTime()),
            type: callType,
            roomType,
          },
        },
      })
      .then((res) => {
        setContactCall(false);
        handleChangeCallStatus(res?.data?.createNewCall?.call?._id)
        dispatch(setInitiatedCallData(res?.data?.createNewCall?.call));
      })
      .catch((error) => console.log("error at Create call", error))
      .finally(() => setDisabledCallButton(false));
  };

  const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="h-screen">
        <div className="flex p-3 shadow-lg justify-between">
          <div
            onClick={() => {
              setContactCall(false);
            }}
          >
            <ArrowBackIosIcon fontSize="small" /> {t("new_call")}
          </div>
        </div>
        <div className="h-[93vh] overflow-y-scroll">
          <div
            className="mt-3 mx-2 rounded-3xl  flex items-center px-2 bg-white"
            style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
          >
            <SearchIcon sx={{ color: "#333333" }} />
            <input
              type="text"
              placeholder={t("search_by_name_phone")}
              className="w-full py-2 px-2 focus:outline-none text-sm"
              value={searchQuery}
              onChange={handleSearchRooms}
            />
          </div>
          {!groupCall ? (
            <div
              className="flex my-auto cursor-pointer gap-4 py-5 p-3"
              onClick={() => {
                setGroupCall(true);
              }}
            >
              <img src={DefaultImageUrl + GroupDefault} className="h-12 w-12" alt={t("profile")} />
              <span className="my-auto text-lg"> {t("group_call")} </span>
            </div>
          ) : (
            <div className="flex p-3 pt-4 justify-between">
              {selectedUseres.length < 2 ? (
                <div>{t("select_participants")}</div>
              ) : (
                <div className="flex gap-3 text-[#33ccff]">
                  <button
                    className="cursor-pointer "
                    disabled={disableCallButton ? true : false}
                    onClick={() => {
                      if (!callisRunning && !joinedFromOtherDevice) {
                        handleCallInitiate("audio", "contact_group", "");
                      } else {
                        alert(
                          t("call_validation")
                        );
                      }
                    }}
                  >
                    <img
                      src={require("../../Assets/Images/call.png")}
                      className="h-8 w-8"
                      alt="call"
                    />
                  </button>

                  <button
                    className="cursor-pointer"
                    disabled={disableCallButton ? true : false}
                    onClick={() => {
                      if (!callisRunning && !joinedFromOtherDevice) {
                        handleCallInitiate("video", "contact_group", "");
                      } else {
                        alert(
                          t("call_validation")
                        );
                      }
                    }}
                  >
                    <img
                      src={require("../../Assets/Images/videoCall.png")}
                      className="h-8 w-8"
                      alt='call'
                    />
                  </button>
                </div>
              )}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedUsers([]);
                  setGroupCall(false);
                }}
              >
                {t("cancel")}
              </div>
            </div>
          )}
          <div>
            {comonUsers.map((el: any) => ({ ...el, name: el?.firstName !== "" ? el.firstName?.toLowerCase() + " " + el?.lastName.toLowerCase() : `${el?.phone.replace('+', '')}` })).sort((a: any, b: any) => {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            }).filter((el: any) => el?.name.split(' ')[0]?.startsWith(searchQuery.toLowerCase()) || el?.name.split(' ')[1]?.startsWith(searchQuery.toLowerCase()))?.map((item: any, index: number) => {

              if (item?.userId?._id === userData?._id) {
                return null;
              }
              return (
                <div>

                  {(
                    !blockedMember.includes(item?.userId?._id) && <div>
                      <div className="flex p-3 justify-between">
                        <div className="flex gap-2">
                          {groupCall && (
                            <div
                              onClick={(event) => {
                                handleGroupCall(event);
                              }}
                            >
                              <BpCheckbox
                                id={item.userId._id}
                                checked={selectedUseres.filter((el:any)=>el?.id===item?.userId?._id).length>0}
                                name={item.firstName}
                              />
                            </div>
                          )}
                          <div className="w-[45px] h-[45px]">
                            <img
                              alt="call"
                              src={DefaultImageUrl + item.userId.profile_img}
                              className="h-full w-full rounded-full"
                            />
                          </div>
                          <div className="my-auto">
                            {item.firstName !== "" ? <div>
                              {item.firstName} {item.lastName}
                            </div> : <div>{item.phone.replace('+', '')}</div>}
                            <div className="text-sm text-slate-500">
                              {t("lastSeen")}
                              {moment(item.userId.lastSeen).format(
                                "DD-MM-YYYY"
                              ) === moment().format("DD-MM-YYYY")
                                ? ` ${t('today_at')} ` +
                                moment(item.userId.lastSeen).format("HH:mm ")
                                : moment()
                                  .subtract(1, "d")
                                  .format("DD-MM-YYYY") ===
                                  moment(item.userId.lastSeen).format(
                                    "DD-MM-YYYY"
                                  )
                                  ? ` ${t('yesterday')} ` +
                                  moment(item.userId.lastSeen).format("HH:mm ")
                                  : ` ${moment(item.userId.lastSeen).format(
                                    "DD-MM-YY"
                                  )} `}
                            </div>
                          </div>
                        </div>
                        {!groupCall && (
                          blockedMember.includes(item?.userId?._id) ?
                            <div className="flex justify-end">{t("blocked_contact")}</div>
                            : <div className="flex my-auto  gap-3">
                              <button
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                  if (!callisRunning && !joinedFromOtherDevice) {
                                    handleCallInitiate(
                                      "audio",
                                      "contact",
                                      item?.userId?._id
                                    );
                                  } else {
                                    alert(
                                      t("call_validation")
                                    );
                                  }
                                }}
                              >
                                <img
                                  alt="call"
                                  src={Call}
                                  className="h-8 w-8 cursor-pointer"
                                />
                              </button>
                              <button
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                  if (!callisRunning && !joinedFromOtherDevice) {
                                    handleCallInitiate(
                                      "video",
                                      "contact",
                                      item?.userId?._id
                                    );
                                  } else {
                                    alert(
                                      t("call_validation")
                                    );
                                  }
                                }}
                              >
                                <img
                                  alt='video-call'
                                  src={VideoCall}
                                  className="h-8 w-8 cursor-pointer"
                                />
                              </button>
                            </div>
                        )}
                      </div>
                      <hr className="m-3 mx-6" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactCall;
