import ForwardIcon from "../../Assets/Images/ForwardIcon.png";
import DeleteIcon from "../../Assets/Images/DeleteIcon.png";
import { useTranslation } from "react-i18next";
export default function ForwardMessageBar({ setSelectedMessages, handleShowEveryoneOption, setDeleteMessageModalShow, setForwardModalShow, setIsChangeMessageFooter, setShowMessageCheckboxes, selectedMessages }) {
    const { t } = useTranslation()
    return (
        <>
            <div className="flex justify-between items-center w-full px-8">
                <div className="flex lightBlack">
                    <div
                        className="cursor-pointer text-red-500"
                        onClick={() => {
                            setSelectedMessages({
                                msgId: [],
                                senderId: [],
                                time: [],
                            });
                            setIsChangeMessageFooter(false);
                            setShowMessageCheckboxes(false);
                        }}
                    >
                        {t("cancel")}
                    </div>
                    {selectedMessages?.msgId?.length > 0 && (
                        <div className="px-4">
                            {selectedMessages?.msgId?.length}
                            <span className="ml-1">{t("selected")}</span>
                        </div>
                    )}
                </div>
                <div className="flex">
                    <button
                        className="cursor-pointer disabled:opacity-10"
                        onClick={() => setForwardModalShow(true)}
                        disabled={
                            selectedMessages?.msgId?.length === 0
                                ? true
                                : false
                        }
                    >
                        <img
                            src={ForwardIcon}
                            alt="forward"
                            className="h-[35px] w-[35px]"
                        />
                    </button>
                    <button
                        className="px-4 cursor-pointer disabled:opacity-10"
                        disabled={
                            selectedMessages?.msgId?.length === 0
                                ? true
                                : false
                        }
                        onClick={() => {
                            setDeleteMessageModalShow(true);
                            handleShowEveryoneOption();
                        }}
                    >
                        <img
                            src={DeleteIcon}
                            alt="delete"
                            className="h-[35px] w-[35px]"
                        />
                    </button>
                </div>
            </div>
        </>
    )
}