import { useSelector } from "react-redux";
import { TaskEdgesTypes } from "../../../Types/Types";
import ReactLoading from "react-loading";
import { useGetLocation } from "../../../hooks/useGetLocation";
import { getSession } from "../../../utils/session";
import { mutationClient } from "../../../Graphql/authClient";
import { UPLOAD_FILE } from "../../../Graphql/Mutations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TaskSubmitButtonPropTypes {
  currentTask: any;
  isExecutable: boolean;
  loading: boolean;
  isSubmitDisable: boolean;
  checkedValues: any;
  inputRef: any;
  location: any;
  setUploadedImage: any;
  attachmentId: any;
  media: any;
  setAttachmentId: any;
  setLoading: any; setResult: any;
  result: any;
  setTaskSubmitError: any;
  handleAddTaskResult: (
    p1: string,
    p2: string,
    p3: string,
    p4: any,
    p5: any
  ) => void;
}

const TaskSubmitButton = ({
  currentTask,
  checkedValues,
  loading,
  handleAddTaskResult,
  isSubmitDisable,
  location,
  media,
  setUploadedImage,
  setAttachmentId,
  result,
  attachmentId,
  setResult,
  inputRef,
  setLoading,
  setTaskSubmitError,
  isExecutable,
}: TaskSubmitButtonPropTypes) => {
  const { currentAssignment,currentOrganization } = useSelector((state: any) => state.organization);
  const { userData } = useSelector((state: any) => state.user);
  const { getCurrentLocation } = useGetLocation();
  const [items, setItem] = useState<any>({});
  const [fileId, setFileId] = useState("");
  const [showButton, setShowButton] = useState(false);
  const { locationData } = useSelector((state: any) => state.chat);
  const { t } = useTranslation();
  const [showSubmit, setShowSubmit] = useState(false);
  const [mediaError, setMediaError] = useState(false);
  const [allMedia, setAllMedia] = useState(false);
  const [medialabel,setMediaLabel]=useState({label:"",key:""});
  const mediaType = currentTask?.type === 'RANGE' ? currentTask?.rangeExpression?.customExp.find((el: any) => el.media !== null)?.media === 'PHOTO' ? "image/*" : currentTask?.rangeExpression?.customExp.find((el: any) => el.media !== null)?.media : "";
  const handleFileUpload = (e: any, elem: any) => {
    setLoading(true);
    const file = e.target.files[0];
    const medias = currentTask?.type === 'RANGE' ? currentTask?.rangeExpression?.customExp.find((el: any) => el.media !== null)?.media === 'PHOTO' ? "image" : currentTask?.rangeExpression?.customExp.find((el: any) => el.media !== null)?.media : currentTask.edges[0]?.options.length > 0 ? currentTask.edges[0]?.options?.find((el: any) => el.media !== null)?.media === "PHOTO" ? 'image' : currentTask.edges[0]?.options?.find((el: any) => el.media !== null)?.media.toLowerCase() : currentTask.edges.find((el: any) => el?.media !== null)?.media === 'PHOTO' ? 'image' : currentTask.edges[0]?.media
    const fileSizeInMb = file.size / 1000000;
    
    if (medias === 'DOCUMENT' || medias === 'document' || file.type.includes(medias.toLowerCase())) {
      if (fileSizeInMb > 16) {
        setLoading(false);
        setTaskSubmitError(t("size_validation"));
      } else {
        setTaskSubmitError("");

        if (file) {

          const { token } = getSession();

          mutationClient(token)
            .mutate({
              mutation: UPLOAD_FILE,
              variables: {
                file: file,
                input:{
                  orgId:currentOrganization?._id,
                  attachments:false
                }
              },
              fetchPolicy:"network-only"
            })
            .then((res) => {

              setUploadedImage(res?.data?.uploadFile)
              setFileId(res?.data?.uploadFile?._id);
              setAttachmentId(res?.data?.uploadFile?._id);

              e.target.value = '';

            })

            .catch((error) => {
              console.log(`Error occurred at Goal File upload => ${error}`);
            })
            .finally(() => {
              setMediaError(false);
              setLoading(false);
            });
        }
      }
    } else {
      setLoading(false);
      alert(t('please-select') + ' ' + medias.toUpperCase())
    }
  };
  const handlesubmit = (elem: any, label: any, id: any) => {
    switch (currentTask?.type) {
      case "SELECT_ONE": {
        if (result !== "") {
          handleAddTaskResult(
            elem?.targetTaskID,
            elem?._id,
            label,
            "",
            id
          );
        } else {
          setLoading(false)
          setTaskSubmitError(t("task_error"));
        }
        break;
      }
      case "CHECKBOX": {
        if (checkedValues.length > 0) {
          handleAddTaskResult(
            elem?.targetTaskID,
            elem?._id,
            "",
            "",
            id
          );
        } else {
          setLoading(false)
          setTaskSubmitError(t("task_error"));
        }
        break;
      }
      case "TEXT_INPUT": {
        if (result !== "") {
          setTaskSubmitError("")
          handleAddTaskResult(
            elem?.targetTaskID,
            elem?._id,
            "",
            "",
            id
          );
        } else {
          setLoading(false)
          setTaskSubmitError(t("task_error"));
        }
        break;
      }
      case "RANGE": {
        console.log(result, '9999')
        if (result !== 0 && result !== "") {
          handleAddTaskResult(
            elem?.targetTaskID,
            elem?._id,
            "",
            "",
            id
          );
        } else {
          setLoading(false)
          setTaskSubmitError(t("task_error"));
        }
        break;
      }
      case "NUMBER_INPUT": {
        if (result !== "") {
          setTaskSubmitError("")
          handleAddTaskResult(
            elem?.targetTaskID,
            elem?._id,
            "",
            "",
            id
          );
        } else {
          setLoading(false)
          setTaskSubmitError(t("task_error"));
        }
        break;
      }
      default: {
        setLoading(false)
        setTaskSubmitError("")
        handleAddTaskResult(
          elem?.targetTaskID,
          elem?._id,
          elem.label,
          "",
          id
        );

        break;
      }
    }
  }

  useEffect(() => {
    if(JSON.stringify(currentTask)!=="{}"){
    currentTask?.edges?.map((elem: TaskEdgesTypes, index: number) => {
      if (elem.location) {
        if (
          locationData?.lat === ""
        ) {
          getCurrentLocation();
        }
      }
    });
    setShowButton(false);
    console.log(currentTask,'currentTask')
    switch (currentTask?.type) {
      case "SELECT_ONE": {
        setShowSubmit(true)
        setAllMedia(false);
        setMediaLabel({label:currentTask.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')),key:currentTask?.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')).toLowerCase()+'/*'})
        if (currentTask?.edges?.length > 0 && currentTask?.edges?.filter((el: any) => el?.media !== null).length === currentTask?.edges?.length) {
          setAllMedia(true);
        } else {
          setAllMedia(false);
        }
        break;
      }
      case "CHECKBOX": {
        setShowSubmit(false);
        setMediaLabel({label:currentTask.edges[0]?.options?.find((el: any) => el.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')) ,key:currentTask.edges[0]?.options?.find((el: any) => el.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')).toLowerCase()+'/*' })
        if (currentTask?.edges?.length > 0 && currentTask?.edges?.[0]?.options?.filter((el: any) => el.media !== null).length === currentTask?.edges?.[0]?.options.length) {
          setAllMedia(true);
        } else {
          setAllMedia(false);
        }
        break;
      }
      case "TEXT_INPUT": {
        setMediaLabel({label:currentTask.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')),key:currentTask.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')).toLowerCase()+'/*'})
        setShowSubmit(false)
        if (currentTask?.edges?.length > 0 && currentTask?.edges[0]?.media !== null) {
          setAllMedia(true)
        } else {
          setAllMedia(false);
        }
        break;
      }
      case "RANGE": {
        setMediaLabel({label:mediaType?.replace("IMAGE", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')) ,key:mediaType?.replace("IMAGE", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')).toLowerCase()+'/*' })
        if (currentTask?.rangeExpression?.customExp?.length > 0 && currentTask?.rangeExpression?.customExp?.filter((el: any) => el.media !== null).length === currentTask?.rangeExpression?.customExp.length) {
          setAllMedia(true)
        } else {
          setAllMedia(false);
        }
        setShowSubmit(false)

        break;
      }
      case "NUMBER_INPUT": {
        setMediaLabel({label:currentTask.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')),key:currentTask.edges.find((el: any) => el?.media !== null)?.media.replace("PHOTO", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document')).toLowerCase()+'/*'})
        setShowSubmit(false)
        if (currentTask?.edges?.length > 0 && currentTask?.edges[0]?.media !== null) {
          setAllMedia(true)
        } else {
          setAllMedia(false);
        }
        break;
      }
      default: {
        console.log(currentTask,'==')
        setMediaLabel({label:currentTask?.mediaType.toLowerCase()+'/*',key:currentTask?.mediaType.toLowerCase()+'/*'})
        setShowSubmit(true)
        setAllMedia(false);
        break;
      }

    }
  }
  }, [currentTask]);
  return (
    (isExecutable && (currentTask?.assignTo?.length === 0 || (currentTask?.assignTo?.length > 0 && currentTask?.assignTo?.filter((el: any) => el.user?._id === userData._id).length > 0))) ?
      (
        <>
          {mediaError && (
            <div className="text-red-500 mb-1 text-sm">{t('media-error')}</div>)}
          <div className={currentTask?.type === 'TEXT_INPUT' || currentTask?.type === 'NUMBER_INPUT' || currentTask?.type === 'CHECKBOX' || currentTask?.type === 'RANGE' ? "flex gap-2" : ""}>

            <div className="flex gap-2 flex-wrap">
              {(
                currentTask?.edges?.map((item: any, index: number) => {
                  console.log(result, 'result', currentTask, item)
                  return (
                    item?.type !== "TIMEOUT" &&
                    (
                      <div className="block">
                        <div className={currentTask?.type === "SELECT_ONE" ? "block" : "flex gap-2"}>
                          <button
                            key={index}
                            id={'last'}
                            className={`${currentTask?.subType === "like_dislike"
                              ? "  min-w-[150px]"
                              : " min-w-[150px]"
                              } ${result === item.label || item.label === 'Next' || item.label === '' ? "bg-[#33ccff]" : "bg-slate-300"} outline-none py-2 px-5 cursor-pointer rounded-md text-white disabled:opacity-30 h-fit my-2`}
                            onClick={() => {

                              if (currentTask?.edges?.filter((elem: TaskEdgesTypes, index: number) => elem.location).length > 0 &&
                                (locationData?.lat === "")
                              ) {
                                alert(t("location_permission"));
                              } else {
                                switch (currentTask?.type) {
                                  case "SELECT_ONE": {
                                    setTaskSubmitError("")
                                    setResult(item.label);
                                    console.log(item, 'ssss', checkedValues)
                                    if (item.media !== null) {
                                      if (attachmentId) {
                                        setMediaError(false);
                                      } else {
                                        setShowButton(true);
                                        setMediaError(true);
                                      }
                                    }
                                    else {

                                      handleAddTaskResult(
                                        item?.targetTaskID,
                                        item?._id,
                                        item.label,
                                        "",
                                        ""
                                      );

                                    }
                                    break;
                                  }
                                  case "CHECKBOX": {
                                    setTaskSubmitError("")
                                    console.log(item, currentTask)
                                    if (item?.options.find((el: any) => el?.media !== null)) {
                                      if (attachmentId) {
                                        handlesubmit(items, result, '');
                                      } else {
                                        setShowButton(true);
                                        setMediaError(true);
                                      }
                                    }
                                    else {
                                      handleAddTaskResult(
                                        item?.targetTaskID,
                                        item?._id,
                                        item.label,
                                        "",
                                        ""
                                      );
                                    }
                                    break;
                                  }
                                  case "TEXT_INPUT": {
                                    setTaskSubmitError("")
                                    console.log(item,attachmentId,result,'--')
                                    if (item.media === null || attachmentId) {
                                      if (result !== "") {
                                        handleAddTaskResult(
                                          item?.targetTaskID,
                                          item?._id,
                                          '',
                                          "",
                                          ""
                                        );
                                      } else {
                                        setTaskSubmitError(t("task_error"));
                                      }
                                    } else {
                                      if (attachmentId) {
                                        setMediaError(false)
                                        if (result !== "") {
                                          handleAddTaskResult(
                                            item?.targetTaskID,
                                            item?._id,
                                            '',
                                            "",
                                            ""
                                          );
                                        } else {
                                          setTaskSubmitError(t("task_error"));
                                        }
                                      } else {
                                        setShowButton(true);
                                        setMediaError(true)
                                      }
                                    }
                                    break;
                                  }
                                  case "RANGE": {
                                    setTaskSubmitError("")
                                    console.log(item)
                                    if (currentTask?.rangeExpression?.customExp.find((el: any) => el.media !== null)) {
                                      if (attachmentId) {
                                        setMediaError(false)
                                        handlesubmit(items, result, '');
                                      } else {
                                        setShowButton(true);
                                        setMediaError(true)
                                      }
                                    } else {
                                      handleAddTaskResult(
                                        item?.targetTaskID,
                                        item?._id,
                                        '',
                                        "",
                                        ""
                                      );
                                    }

                                    break;
                                  }
                                  case "NUMBER_INPUT": {
                                    setTaskSubmitError("")
                                    if (item.media === null || attachmentId) {
                                      if (result !== "") {
                                        handleAddTaskResult(
                                          item?.targetTaskID,
                                          item?._id,
                                          '',
                                          "",
                                          ""
                                        );
                                      } else {
                                        setTaskSubmitError(t("task_error"));
                                      }
                                    } else {
                                      if (attachmentId) {
                                        setMediaError(false)
                                        if (result !== "") {
                                          handleAddTaskResult(
                                            item?.targetTaskID,
                                            item?._id,
                                            '',
                                            "",
                                            ""
                                          );
                                        } else {
                                          setTaskSubmitError(t("task_error"));
                                        }
                                      } else {
                                        setShowButton(true);
                                        setMediaError(true)
                                      }
                                    }
                                    break;
                                  }
                                  case "MEDIA_UPLOAD": {
                                    
                                    handleAddTaskResult(
                                      item?.targetTaskID,
                                      item?._id,
                                      '',
                                      "",
                                      ""
                                    );
                                    break;
                                  }

                                }
                                setItem(item);
                              }
                            }}
                            disabled={
                              currentTask?.edges?.filter((elem: TaskEdgesTypes, index: number) => elem.location).length > 0 ?
                                locationData?.lat === ""
                                  ? true
                                  : isSubmitDisable || loading
                                    ? true
                                    : false
                                : false
                            }
                          >

                            {currentTask?.subType === "like_dislike" ? (
                              item?.label === "Like" ? (
                                <span className="text-[27px]">👍</span>
                              ) : (
                                <span className="text-[27px]">👎</span>
                              )
                            ) : (
                              item?.label || t("submit")
                            )}
                          </button>
                        </div>
                      </div>
                    ))
                })
              )}
            </div >
            <div className="flex gap-2">
              {(showButton || allMedia) &&
                <label
                  onClick={() => {
                    if (currentTask?.edges?.filter((elem: TaskEdgesTypes, index: number) => elem.location).length > 0 &&
                      (locationData?.lat === "")
                    ) {
                      alert(t("location_permission"));
                    }
                  }}
                  className=" flex cursor-pointer relative"
                 
                >
                  <button
                    className={`${currentTask?.subType === "like_dislike"
                      ? "  min-w-[150px]"
                      : " min-w-[150px]"
                      } bg-[#33ccff] outline-none py-2 h-fit  my-2 px-5 rounded-md text-white cursor-pointer disabled:opacity-30`}
                    
                  >
                    {medialabel?.label}
                  </button>
                  <input
                   id={'232'}
                    type={"file"}
                    accept={medialabel?.key}
                    name="radio"
                    title=""
                    style={{
                      opacity: "0",
                      zIndex: 10000,
                      width: "150px",
                      bottom: '0rem',
                      overflow: "hidden",
                    }}
                    className="h-[40px] cursor-pointer  absolute "
                    onChange={(e: any) => handleFileUpload(e, '')}
                  />
                </label>}
              {(showButton || allMedia) && showSubmit && (
                <div className="bg-[#33ccff] my-2 outline-none py-2 px-5 min-w-[150px] flex text-center justify-center rounded-md text-white cursor-pointer disabled:opacity-30" onClick={() => {
                  if (items.media === null) {
                    setLoading(true);
                    handlesubmit(items, result, '');
                  } else {
                    if (attachmentId) {
                      setLoading(true);
                      handlesubmit(items, result, '');
                    } else {
                      setMediaError(true)
                    }
                  }
                }}>
                  {t("submit")}
                </div>
              )}
            </div>
          </div>
        </>
      )
      : <></>
  );
};

export default TaskSubmitButton;
