import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventType, ParticipantType } from "../../types/types";
import { addNewEvent, closeNewEventDialog } from "../../../../Redux/EventSlice";
import { CircularProgress, Popover, Snackbar, TextField } from "@mui/material";
import AddEventType from "./fields/AddEventType";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import EventDates from "./fields/EventDates";
import EventNotification from "./fields/EventNotification";
import EventParticipants from "./fields/EventParticipants";
import EventAttachments from "./fields/EventAttachments";
import EventLocations from "./fields/EventLocations";
import SegmentIcon from "@mui/icons-material/Segment";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  RecurrentTypes,
  ReminderParticipantRole,
} from "../../types/enum";
import { authCllient } from "../../../../Graphql/authClient";
import {
  CREATE_APPOINTMENT,
  CREATE_REMINDER,
  DELETE_REMINDER,
  UPDATE_REMINDER,
} from "../../../../Graphql/Mutations";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ViewEvent from "./ViewEvent";
import RecurrentFrequency from "../../../Recurrents/RecurrentFrequency";
import EventConfirmation from "../EventConfirmation";
import { GET_MY_CONTACTS } from "../../../../Graphql/Queries";
import { setComonUsers, setUserContacts } from "../../../../Redux/UserSlice";
import { getCalendarColor } from "../../utils/calendarColors";

enum SubmittingStatus {
  Loading = "loading",
  Idle = "idl",
  Error = "error",
  Success = "success",
}

const defaultValues: EventType = {
  _id: "",
  parent_id: "",
  title: "",
  type: EventTypeEnum.REMINDER,
  frequency: RecurrentTypes.ONCE,
  days: [],
  noOfWeeks: 1,
  repeatEveryMonth: true,
  months: [],
  notifications: [],
  participants: [],
  attachments: [],
  location: null,
  description: "",
  isAllDay: false,
  endTimes: null,
  start: null,
  end: null,
  time: null,
  monthDateType: "day",
  onDay: 1,
  onWeek: "MON",
  everyWeek: 1,
  isConfirmationNeeded: null,
  roomType: "",
  date: null,
};

function EventDialog(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsRefresh, isEdit } = props;
  const { events } = useSelector((state: any) => state);
  const { userData, blockedMember } = useSelector((state: any) => state?.user);
  const { eventDialog } = events;
  const [errors, setErrors] = useState(null);
  const [submitStatus, setSubmitStatus] = useState<SubmittingStatus>(
    SubmittingStatus.Idle
  );
  const [form, setForm] = useState<EventType>(defaultValues);
  const [toast, setToast] = useState({ show: false, msg: "" });
  const [self, setSelf] = useState(null);
  const [isEditMode, setIsEditMode] = useState(isEdit || false);
  const [confirmationType, setConfirmationType] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const fetchMyContacts = () => {
    authCllient
      .query({
        query: GET_MY_CONTACTS,
      })
      .then((res) => {
        const contacts = res?.data?.getMyContacts?.contacts?.length
          ? res?.data?.getMyContacts?.contacts
          : [];

        dispatch(setUserContacts(contacts));

        let temp = [...contacts];
        const comonUsers = temp.filter(
          (item: any) => item?.userId !== null && item
        );
        dispatch(setComonUsers(comonUsers));
      })
      .catch((error) => console.log(`Error occured at Contact Fetch ${error}`));
  };

  const initDialog = useCallback(() => {
    const formattedParticipants = [];
    let tempSelf: ParticipantType;

    if (eventDialog?.data?.participants) {
      eventDialog?.data?.participants?.forEach((item) => {
        if (item?._id != userData?._id) {
          if (!blockedMember?.includes(item?._id)) {
            formattedParticipants.push(item);
          }
        } else {
          tempSelf = item;
          setSelf(tempSelf);
        }
      });
    }

    const selectedRoomPart = eventDialog?.data?.roomParticipants?.map((el) => ({
      ...el,
      _id: el?.user_id,
    }));

    setForm({
      ...form,
      ...eventDialog?.data,
      date: eventDialog?.data?.start,
      participants:
        eventDialog?.data?.roomId && eventDialog?.type == "new"
          ? [
              ...selectedRoomPart,
              {
                _id: userData?._id,
                role: ReminderParticipantRole.ADMIN,
                accepted: ParticipantAcceptStatus.ACCEPT,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                phone: userData?.phone,
                profile_img: userData?.profile_img,
              },
            ]
          : tempSelf?.role == ReminderParticipantRole.USER
          ? eventDialog?.data?.participants
          : [
              ...formattedParticipants,
              {
                _id: userData?._id,
                role: ReminderParticipantRole.ADMIN,
                accepted: ParticipantAcceptStatus.ACCEPT,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                phone: userData?.phone,
                profile_img: userData?.profile_img,
              },
            ],
    });
  }, [eventDialog?.data, eventDialog?.type]);

  useEffect(() => {
    if (eventDialog.props.open) {
      initDialog();
      fetchMyContacts();
    }
  }, [eventDialog?.props?.open, initDialog]);

  function closeComposeDialog() {
    dispatch(closeNewEventDialog(""));
    setForm(defaultValues);
    setErrors(null);
    setSubmitStatus(SubmittingStatus.Idle);
    setIsEditMode(false);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event?.target.name;
    const value = event?.target.value;
    setForm({ ...form, [name]: value });

    const newErrors = { ...errors };
    if (name == "title") {
      if (value?.length > 80) {
        newErrors.title = "title_max_length";
      } else {
        delete newErrors.title;
      }
    } else if (name == "description") {
      if (value?.length > 500) {
        newErrors.description = "desc_max_length";
      } else {
        delete newErrors.description;
      }
    }
    setErrors(newErrors);
  }

  async function onSubmit(updateType?: string) {
    const payload = {
      _id: form?._id || null,
      title: form?.title?.trim(),
      type: form?.type,
      time: dayjs(form?.time)?.toISOString(),
      endTime: dayjs(form?.endTimes)?.toISOString(),
      isAllDay: form?.isAllDay,
      roomId: form?.roomId || null,
      recursive:
        form?.frequency == "Doesn't repeat"
          ? RecurrentTypes.ONCE
          : form?.frequency,
      description: form?.description && form?.description?.trim(),
      approvalReminderTime: form?.notifications,
      startTimeInMs: dayjs(form?.start)?.unix(),
      isConfirmationNeeded: form?.isConfirmationNeeded,
      participants: form?.participants?.length
        ? form?.participants?.map((participant: ParticipantType) => {
            const { _id, role, accepted, firstName, lastName, phone } =
              participant;
            return {
              _id,
              role,
              accepted:
                role == ReminderParticipantRole.USER
                  ? form?.isConfirmationNeeded == true
                    ? accepted
                    : ParticipantAcceptStatus.ACCEPT
                  : ParticipantAcceptStatus.ACCEPT,
              firstName,
              lastName,
              phone: String(phone),
            };
          })
        : [],
      ...(form?.type == EventTypeEnum.APPOINTMENT && {
        location: form?.location
          ? {
              address: form?.location?.address,
              latitude: form?.location?.latitude,
              longitude: form?.location?.longitude,
              countryOffset: form?.location?.countryOffset,
              mapUrl: form?.location?.mapUrl,
            }
          : null,
        attachment: form?.attachments,
      }),
      ...(form?.frequency == RecurrentTypes.WEEKLY && {
        daylyParams: {
          dayOfWeeks: form?.days,
          everyWeek: form?.noOfWeeks,
        },
      }),
      ...(form?.frequency == RecurrentTypes.MONTHLY && {
        monthlyParams: {
          months: form?.repeatEveryMonth ? [] : form?.months,
          twicePerMonth: form?.repeatEveryMonth,
          onDay: form?.monthDateType == "day" ? form?.onDay : undefined,
          onWeek:
            form?.monthDateType == "week"
              ? {
                  dayOfWeeks: [form?.onWeek],
                  everyWeek: form?.everyWeek,
                }
              : undefined,
        },
      }),
      ...(form?.type != EventTypeEnum.APPOINTMENT && {
        hasComon: !form?.hasComon,
      }),
      ...(form?.roomType && { roomType: form?.roomType }),
      ...(eventDialog?.type == "edit" && {
        thisOccurrence: updateType == "this" ? true : false,
        allOccurrence: updateType == "all" ? true : false,
        parent_id: form?.parent_id,
      }),
      ...(!form?._id && {
        startDate: dayjs(form?.start)
          ?.set("hours", dayjs(form?.time)?.get("hours"))
          ?.set("minutes", dayjs(form?.time)?.get("minutes")),
        endDate: dayjs(form?.end)
          ?.set("hours", dayjs(form?.time)?.get("hours"))
          ?.set("minutes", dayjs(form?.time)?.get("minutes")),
      }),
      ...(form?._id &&
        updateType == "this" && {
          date: dayjs(form?.date)
            ?.set("hours", dayjs(form?.time)?.get("hours"))
            ?.set("minutes", dayjs(form?.time)?.get("minutes")),
        }),
    };

    setSubmitStatus(SubmittingStatus.Loading);
    if (eventDialog?.type == "new") {
      const { _id, ...restPayload } = payload;
      await authCllient
        .mutate({
          mutation:
            form?.type == EventTypeEnum.APPOINTMENT
              ? CREATE_APPOINTMENT
              : CREATE_REMINDER,
          variables: { input: restPayload },
        })
        .then((res) => {
          if (res?.data) {
            // if (form?.roomId) {
            //     updateEventInRoom(res?.data?.createAppointment || res?.data?.createReminder)
            // }
            setToast({
              msg:
                form?.type == EventTypeEnum.APPOINTMENT
                  ? "appointment_created_msg"
                  : form?.type == EventTypeEnum.CALLREMINDER
                  ? "call_reminder_created_msg"
                  : "reminder_created_msg",
              show: true,
            });
            dispatch(
              addNewEvent({
                ...form,
                id: res?.data?.createAppointment?._id,
              })
            );
            setForm(defaultValues);
            if (setIsRefresh) {
              setIsRefresh(true);
            }
            closeComposeDialog();
            setSubmitStatus(SubmittingStatus.Success);
          }
        })
        .catch((err) => {
          console.log(`Error occured at Calendar App -> ${err}`);
          setSubmitStatus(SubmittingStatus.Error);
        });
    } else {
      await authCllient
        .mutate({
          mutation: UPDATE_REMINDER,
          variables: { input: payload },
        })
        .then((res) => {
          if (res?.data?.updateReminder) {
            setToast({
              msg:
                form?.type == EventTypeEnum.APPOINTMENT
                  ? "appointment_updated_msg"
                  : form?.type == EventTypeEnum.CALLREMINDER
                  ? "call_reminder_updated_msg"
                  : "reminder_updated_msg",
              show: true,
            });
            setForm(defaultValues);
            closeComposeDialog();
            setSubmitStatus(SubmittingStatus.Success);
          }
        })
        .catch((err) => {
          console.log(`Error occured at Calendar App -> ${err}`);
          setSubmitStatus(SubmittingStatus.Error);
        });
    }
  }

  async function handleRemove(deleteType: string) {
    setSubmitStatus(SubmittingStatus.Loading);
    await authCllient
      .mutate({
        mutation: DELETE_REMINDER,
        variables: {
          input: {
            _id: deleteType == "all" ? form?.parent_id : form?._id,
            thisOccurrence: deleteType == "this" ? true : false,
            allOccurrence: deleteType == "all" ? true : false,
          },
        },
      })
      .then((res) => {
        if (res?.data?.deleteReminder) {
          setToast({
            msg:
              form?.type == EventTypeEnum.APPOINTMENT
                ? "appointment_deleted_msg"
                : form?.type == EventTypeEnum.APPOINTMENT
                ? "call_reminder_deleted_msg"
                : "reminder_deleted_msg",
            show: true,
          });
          closeComposeDialog();
          setSubmitStatus(SubmittingStatus.Success);
        } else {
          setSubmitStatus(SubmittingStatus.Error);
        }
      })
      .catch((err) => {
        console.log(`Error occured at Calendar App -> ${err}`);
        setSubmitStatus(SubmittingStatus.Error);
      });
  }

  return (
    <React.Fragment>
      <Snackbar
        open={toast.show}
        message={t(toast.msg)}
        autoHideDuration={3000}
        onClose={() => setToast({ show: false, msg: "" })}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />

      <Popover
        component="form"
        {...eventDialog?.props}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            borderTop: `8px solid ${getCalendarColor(form?.type, "dark")}`,
          },
        }}
      >
        {!isEditMode && eventDialog?.type == "edit" ? (
          <ViewEvent
            form={form}
            self={self}
            setSelf={setSelf}
            setToast={setToast}
            setIsEditMode={setIsEditMode}
            closeComposeDialog={closeComposeDialog}
          />
        ) : (
          <div
            className="p-5 max-w-[750px] max-h-[95vh]"
            style={{ overflowY: "scroll", scrollbarWidth: "thin" }}
          >
            <AddEventType
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
              closeComposeDialog={closeComposeDialog}
            />

            <div className="flex gap-3 mt-3">
              <NoteAltOutlinedIcon
                fontSize="medium"
                sx={{
                  color: getCalendarColor(form?.type, "dark"),
                }}
              />
              <TextField
                id="title"
                name="title"
                label={t("title")}
                className="flex-auto"
                InputLabelProps={{
                  shrink: true,
                }}
                value={form?.title}
                onChange={handleChange}
                error={Boolean(errors?.title)}
                helperText={t(errors?.title)}
                variant="outlined"
                autoFocus
                required
                fullWidth
              />
            </div>

            <EventDates
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
            />

            {eventDialog?.type != "edit" && (
              <RecurrentFrequency
                form={form}
                setForm={setForm}
                errors={errors}
                setErrors={setErrors}
                formType={EventTypeEnum.REMINDER}
              />
            )}

            <EventNotification
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
            />

            <EventParticipants
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
              roomParticipants={eventDialog?.data?.roomParticipants}
            />

            {form?.type == EventTypeEnum.APPOINTMENT && (
              <React.Fragment>
                <EventAttachments
                  form={form}
                  setForm={setForm}
                  errors={errors}
                  setErrors={setErrors}
                />

                <EventLocations form={form} setForm={setForm} />
              </React.Fragment>
            )}

            <div className="flex gap-3 mt-3">
              <SegmentIcon
                fontSize="medium"
                sx={{
                  color: getCalendarColor(form?.type, "dark"),
                }}
              />
              <TextField
                className="mt-8 mb-16"
                id="desc"
                name="description"
                label={t("description")}
                type="text"
                multiline
                rows={2}
                value={form?.description}
                onChange={handleChange}
                error={Boolean(errors?.description)}
                helperText={t(errors?.description)}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="flex gap-3 mt-3">
              <div className="flex flex-1" />

              {submitStatus == SubmittingStatus.Loading ? (
                <button className="cursor-default flex items-center justify-center py-2 bg-primary px-6 rounded text-center">
                  <CircularProgress sx={{ color: "white" }} size={20} />
                </button>
              ) : (
                <React.Fragment>
                  {form?._id && (
                    <button
                      type="button"
                      onClick={() => {
                        if (form?.frequency != RecurrentTypes.ONCE) {
                          setConfirmationType("delete");
                          setShowConfirmation(true);
                        } else {
                          handleRemove("this");
                        }
                      }}
                      className="disabled:bg-gray-300 disabled:text-white disabled:cursor-default text-white bg-red-500 py-2 px-6 rounded cursor-pointer"
                    >
                      {t("delete")}
                    </button>
                  )}

                  {form?.title?.trim()?.length > 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        if (eventDialog?.type == "new") {
                          onSubmit("");
                        } else {
                          if (
                            dayjs(form?.date).isSame(form?.start) &&
                            form?.frequency != RecurrentTypes.ONCE
                          ) {
                            setConfirmationType("edit");
                            setShowConfirmation(true);
                          } else {
                            onSubmit("this");
                          }
                        }
                      }}
                      disabled={
                        !form?.title ||
                        Boolean(errors && Object?.keys(errors)?.length) ||
                        form?.participants?.length == 0 ||
                        (form?.repeatEveryMonth == false &&
                          form?.months?.length == 0) ||
                        (form?.frequency == RecurrentTypes.WEEKLY &&
                          form?.days?.length == 0) ||
                        (form?.type == EventTypeEnum.CALLREMINDER &&
                          form?.participants?.length < 2)
                      }
                      className="disabled:bg-gray-300 disabled:text-white disabled:cursor-default py-2 bg-primary px-6 rounded text-center cursor-pointer"
                    >
                      {t("submit")}
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>

            {submitStatus == SubmittingStatus.Error && (
              <div className="text-sm text-red-600 mt-2 text-right">
                {t("something_went_wrong")}
              </div>
            )}
          </div>
        )}
      </Popover>

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={confirmationType == "edit" ? onSubmit : handleRemove}
          message={
            confirmationType == "edit"
              ? t("update_recurring_event")
              : t("delete_recurring_event")
          }
        />
      )}
    </React.Fragment>
  );
}

export default EventDialog;
