import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ApprovalReminderTimeTypes,
  RecurrentTypes,
} from "../../../Components/Calendar/types/enum";
import dayjs from "dayjs";
import convertToMinutes from "../../../utils/convertToMinutes";

const options = [
  { Count: 5, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 10, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 15, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 30, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 1, Unit: ApprovalReminderTimeTypes.HOUR },
  { Count: 1, Unit: ApprovalReminderTimeTypes.DAY },
];

function SelectReminder(props: any) {
  const { t } = useTranslation();
  const { form, setForm, setShowCustom } = props;
  const currentDate = dayjs();
  const selectedDateTime = dayjs(
    `${form?.startDate?.format("YYYY-MM-DD")}T${form?.time?.format("HH:mm:ss")}`
  );
  const minuteDifference = selectedDateTime?.diff(currentDate, "minute");

  const filterOptions = options?.filter((option) => {
    if (form?.frequency == RecurrentTypes.ONCE) {
      const optionMinutes = convertToMinutes(option.Count, option.Unit);
      return optionMinutes <= minuteDifference;
    } else {
      return option;
    }
  });

  function handleSelect(item) {
    if (item != "custom") {
      setShowCustom(false);
      setForm({
        ...form,
        approvalReminderTime: { Count: item?.Count, Unit: item?.Unit },
      });
    } else {
      setShowCustom(true);
    }
  }

  return (
    <div className="mt-4">
      <div className="flex flex-wrap gap-5">
        {filterOptions.map((option, index) => {
          const label = `${option.Count} ${option.Unit} ${t("before")}`;

          return (
            <Chip
              key={index}
              variant="outlined"
              label={<div className="lowercase">{label}</div>}
              onClick={() => handleSelect(option)}
            />
          );
        })}

        <Chip
          variant="outlined"
          label={<div>{t("custom")}</div>}
          onClick={() => handleSelect("custom")}
        />
      </div>
    </div>
  );
}

export default SelectReminder;
