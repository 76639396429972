import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authCllient } from "../../Graphql/authClient";
import { useState } from "react";
import { ACCEPT_INVITE, DECLINE_INVITE } from "../../Graphql/Mutations";
import Toast from "../../coreComponent/Toast";
import moment from "moment";
import { useOrganizations } from "../../hooks/useOrganizations";

const SystemMessage = ({ item,messageArray, setMessageArray }: any) => {
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const [loadingClicked, setIsLoadingOnClicked] = useState(false);
  const { formattedParticipantsOfActiveRoom, activeRoomData } = useSelector(
    (state: any) => state.chat
  );
  const {updateInvites}=useOrganizations();
  
  const userInfo = activeRoomData?.participants?.length > 1 ? activeRoomData?.participants?.find(
    (one: any) => one?.user_id !== userData?._id
  ) : activeRoomData?.participants?.length === 1 ? activeRoomData?.participants[0] : null;

  const userProfileImg = activeRoomData?.participants?.length > 0 && activeRoomData?.participants?.find((item: any) => item?.user_id === userInfo?.user_id);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const rejectInvite = async (_id: string, msg_id: string,orgId:string) => {
    setIsLoadingOnClicked(true);

    await authCllient
      .mutate({
        mutation: DECLINE_INVITE,
        variables: { input: { _id, msgId: msg_id ,orgId:orgId} },
      })
      .then((res) => {
        setToastMessage(t("invite-decline"));
        setToastShow(true);
        updateInvites();
        setIsLoadingOnClicked(false);
      })
      .catch((err) => {
        setToastMessage(t("invite-error"));
        setToastShow(true);
        console.log(`Error occured at Accept Invitation ${err}`); setIsLoadingOnClicked(false);
      })
  };

  const acceptInvite = async (_id: string, msg_id: string,orgId:string) => {
    setIsLoadingOnClicked(true);

    await authCllient
      .mutate({
        mutation: ACCEPT_INVITE,
        variables: { input: { _id, msgId: msg_id,orgId:orgId } },
      })
      .then((res) => {
        if (res.data?.accept) {
          setToastMessage(t("invite-accept"));
          setToastShow(true);
          updateInvites();
          setIsLoadingOnClicked(false);
        }
      })
      .catch((err) => {
        setToastMessage(t("invite-error"));
        setToastShow(true); console.log(`Error occured at Accept Invitation ${err}`); setIsLoadingOnClicked(false);
      })
  };
  
  const { t } = useTranslation();
  
  const getMessage: any = () => {
    let message: any;
    const senderContactDetails = formattedContact[item?.sender];
    const senderInfo = formattedParticipantsOfActiveRoom[item?.sender];

    if (item?.type === "createdRoom") {
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails&&senderContactDetails?.firstName !==""
                ? senderContactDetails?.firstName + " " + senderContactDetails?.lastName
                : senderInfo?.phone}
          </span>
          <span className="ml-1">{item?.message.replace("created group",t('created-group')).replace("created broadcast",t('create-broadcast-message')).replace(/\s+/g, ' ').trim()}</span>
        </div>
      );
    } else if (item?.type === "addedUser" || item?.type === "removedUser") {
      const jsonMsg = JSON.parse(item?.message);
      const userContactDetails = formattedContact[jsonMsg?.pid];
      const userInfo = formattedParticipantsOfActiveRoom[jsonMsg?.pid];
      const findUser=activeRoomData.participants?.find((el:any)=>el.user_id===jsonMsg?.pid);

        message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails&&senderContactDetails?.firstName !==""
                ? senderContactDetails?.firstName + " " + senderContactDetails?.lastName
                : senderInfo?.phone}
          </span>
          <span className=""> {jsonMsg?.msg.replace('added',t('added')).replace('removed',t('removed')).replace(/\s+/g, ' ').trim()} </span>
          <span className="">
            {jsonMsg?.pid === userData?._id
              ? t("you")
              : userContactDetails&& userContactDetails?.firstName!==""
                ? userContactDetails?.firstName + " " + userContactDetails?.lastName
                : findUser?.phone}
          </span>
        </div>
      );
    } else if (
      item?.type === "changedDescription" ||
      item?.type === "changedName" ||
      item?.type === "changedPicture" ||
      item?.type === "leftRoom" ||
      item?.type === "chatDisappear"
    ) {
     const findUser=activeRoomData.participants?.find((el:any)=>el.user_id===item?.sender);
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails&&senderContactDetails?.firstName !==""
                ? senderContactDetails?.firstName + " " + senderContactDetails?.lastName
                : findUser?.phone}
          </span>
          <span className=""> {item?.message.replace('changed the name of group',t('name-change-group')).replace("turned off disappearing messages",t("turn-off-disapper")).replace('changed the description of group',t('description-change-group')).replace("changed the picture of the group",t('picture-change-group')).replace("turned on disappearing messages. All new messages will disappear from this chat 7 days after they're send.",t("chat-disapper")).replace("left the group",t("left-group")).replace(/\s+/g, ' ').trim()} </span>
        </div>
      );
    } else if (item?.type === "audioCall" || item?.type === "videoCall") {
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails&&senderContactDetails?.firstName !==""
                ? senderContactDetails?.firstName + " " + senderContactDetails?.lastName
                : senderInfo?.phone}
          </span>
          <span> {t("started")} </span>
          <span className="">
            {item?.type === "audioCall" ? t("audio_call") : t("video_call")}{" "}
          </span>
        </div>
      );
    }
    else if (item.type === 'invited') {
      const finalmsg = JSON.parse(item.message)
      const first=formattedContact[userInfo?.user_id]?.firstName??userInfo?.phone;
      const last_name= formattedContact[userInfo?.user_id]?.lastName!==undefined?formattedContact[userInfo?.user_id]?.lastName:" "
      message = (
        <div>
          <span className="ml-1">
            <span className="ml-1">{item?.sender === userData?._id ?
              <>
                <span>
                  {t('accept-1') + " " + first + " " + last_name + " " + t("accept-2") + " "}
                </span>
                <b>{" "+finalmsg?.orgName}</b>
              </>
              :
              <>
                <span> {first + " " +last_name + " " + t('accept-3') + " "}</span>
                <b>{" "+finalmsg?.orgName}</b>
              </>
            }
            </span></span>
          {item?.sender !== userData?._id && item?.inviteStatus !== "RESTRICTED" && <div>
            {item?.inviteStatus === "ACCEPTED" ?
              <div>
                <Button disabled={true} className="bg-[#33ccff] text-white mx-2 my-2">
                  {t("accepted-invite")}
                </Button>
              </div>
              : item?.inviteStatus !== "DECLINED" && !messageArray.includes(item._id) && <div>
                <Button loading={loadingClicked} onClick={() => { setMessageArray([...messageArray, item?._id]); acceptInvite(finalmsg.inviteId, item?._id,finalmsg?.orgId) }} className="bg-[#33ccff] text-white mx-2 my-2">
                  {t("accept")}
                </Button>
                <Button loading={loadingClicked} onClick={() => { setMessageArray([...messageArray, item?._id]); rejectInvite(finalmsg.inviteId, item?._id,finalmsg?.orgId) }} className="bg-[#fb8484] text-white mx-2 my-2">
                  {t('decline')}
                </Button>
              </div>}
          </div>}
        </div>

      )
    } else if (item.type === 'declined') {
      const finalmsg = JSON.parse(item.message)
      message = (
        <div>
          <div>
            <span className="ml-1">{item?.sender === userData?._id ?
              <>
                <span>
                  {t("you") + " " + t('msg-1') + " "}
                </span>
                <b>{finalmsg?.orgName+" "}</b>
                <span>{" " + t('msg-2') + " "}</span>
                <span><b>{moment(item.created_at).format('MMM Do YY')}</b></span>
                <span>
                  {" " + t('msg-3') + " "}
                </span>
                <span> <b>{moment(item.created_at).format('HH:mm') + " "}</b>
                </span>
                <span> {t("msg-4")}
                </span>
              </>
              :
              <>
                <span>
                  {formattedContact?.[userInfo?.user_id]?.firstName ?  formattedContact[userInfo?.user_id]?.firstName + " " + formattedContact[userInfo?.user_id]?.lastName : userInfo?.phone}
                </span>
                <span>
                  {" "+t('msg-5') + " "}
                </span>
                <span>
                  <b>
                    {finalmsg?.orgName + " "}
                  </b>
                </span>
                <span>
                  {t('msg-2') + " "}
                </span>
                <span>
                  <b>
                    {moment(item.created_at).format('MMM Do YY') + " "}
                  </b>
                </span>
                <span>{" "+t('msg-3')+" "}</span>
                <span>
                  <b>{moment(item.created_at).format('HH:mm') + " "}</b>
                </span>
                <span>
                  {t("msg-6")}
                </span>
              </>}
            </span>
          </div>


        </div>
      )
    }
    else if (item.type === 'taskAssigned') {
      const finalmsg = JSON.parse(item.message)
      const last_name= formattedContact[userInfo?.user_id]?.lastName!==undefined?formattedContact[userInfo?.user_id]?.lastName:" "
      message = (
        <div>
          <div>
            <span className="ml-1">{item?.sender === userData?._id ?
              <>
                <span>
                  {t('task-assigned')  + " " }
                </span>
                <b>{finalmsg?.scenarioName+" "}</b>
                <span>{" " + t('to') + " "}</span>
                {/* <span><b>{formattedContact[userInfo?.user_id]?.firstName??userInfo?.phone + " " + last_name }</b></span> */}
                <span>
                  <b>
                    {formattedContact?.[userInfo?.user_id]?.firstName ?  formattedContact[userInfo?.user_id]?.firstName + " " + formattedContact[userInfo?.user_id]?.lastName : userInfo?.phone}
                  </b>
                </span>
              </>
              :
              <>
                <span>
                  <b>{formattedContact[userInfo?.user_id]?.firstName ? formattedContact[userInfo?.user_id]?.firstName + " " + formattedContact[userInfo?.user_id]?.lastName : userInfo?.phone}</b>
                </span>
                <span>
                  {" "+t('assigned-you') + " "}
                </span>
                <span>
                  <b>
                    {finalmsg?.scenarioName + " "}
                  </b>
                </span>
                
              </>}
            </span>
          </div>


        </div>
      )
    }
    return message;
  };

  return (
    <>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {(item.type === 'invited' || item?.type === "declined") && <div className="textSideBorder" >
        <span
          className={`px-2 rounded text-center text-[10px] w-fit flex mx-auto my-2  bg-[#E0FAFF] text-black `}
          id="date-label"

        >
          {moment(item.created_at).format("HH:mm")}</span>
      </div>}
      <div className="flex justify-center text-[13px] textGray tracking-wide">
        <div className={`max-w-[400px] text-center ${item.type === 'invited' || item?.type === "declined" ? "bg-[#E0FAFF]" : "bg-[#F3F9FC]"}  px-4 p-2 rounded-lg`}>
          {getMessage()}
        </div>
      </div>
    </>
  );
};

export default SystemMessage;
