import { Slider } from "@mui/material";
import { TaskEdgesTypes } from "../../../Types/Types";
import { useRangeExpressions } from "../../../hooks/useRangeExpressions";
import { useGetLocation } from "../../../hooks/useGetLocation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskRangeInput = ({
  setIsSubmitDisable,
  isExecutable,
  currentTask,
  setResult,
  result,
  location
}: any) => {
  const { handleExpressions, rangeError } = useRangeExpressions();
  const { t } = useTranslation();
  const [item, setItem] = useState({ location: false });
  const {locationData}=useSelector((state: any) => state.chat);
  const handleChange = (e: any, value: number) => {
    if (item?.location) {
      if (locationData?.lat==="") {
        getCurrentLocation();
        alert(t('location_permission'))
      } else {

        setResult(value.toString());
        handleExpressions(currentTask, value);
      }
    } else {

      setResult(value.toString());
      handleExpressions(currentTask, value);
    }

  };

  if (rangeError) {
    setIsSubmitDisable(true);
  } else {
    setIsSubmitDisable(false);
  }

  useEffect(()=>{
    if(currentTask?.type==='RANGE'){
      setResult( currentTask?.rangeExpression?.min !== null
        ? `${currentTask?.rangeExpression?.min}`
        : '0')}
      },[currentTask])
      const { getCurrentLocation } = useGetLocation();


  return (
    <>
      {currentTask?.type === "RANGE" &&
        isExecutable &&
        currentTask?.edges?.map((item: TaskEdgesTypes, index: number) => {
          return (
            item?.type !== "TIMEOUT" && (

              <div key={item?._id} className="min-w-[300px] pl-3" onFocus={() => { setItem(item) }}>
                <Slider
                  valueLabelDisplay="auto"
                  getAriaValueText={() => result}
                  onChange={handleChange}
                  value={Number(result)}
                  defaultValue={Number(result)}
                  min={
                    currentTask?.rangeExpression?.min !== null
                      ? currentTask?.rangeExpression?.min
                      : 0
                  }
                  max={
                    currentTask?.rangeExpression?.max !== null
                      ? currentTask?.rangeExpression?.max
                      : 100
                  }
                />
                {currentTask?.rangeExpression?.max !== "" && currentTask?.rangeExpression?.min !== "" ?
                  <div className='flex justify-between'>
                    <div> select the value between <span className='font-bold'>{currentTask?.rangeExpression?.min}</span> to <span className='font-bold'> {currentTask?.rangeExpression?.max}</span> </div>

                  </div>
                  : ""}

                {rangeError && (
                  <div className="my-2 text-red-600 text-sm">{rangeError}</div>
                )}
              </div>
            )
          );
        })}
    </>
  );
};

export default TaskRangeInput;
