import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  ADD_TASK_RESULT,
  COMPLETE_REPORT,
  START_REPORT,
  START_TASK,
  UPLOAD_FILE,
} from "../../Graphql/Mutations";
import { useEffect, useRef, useState } from "react";
import {
  setAssignments,
  setCurrentAssignment,
  setCurrentReport,
  setShowTaskPage,
} from "../../Redux/OrganizationSlice";
import { AssignmentDataTypes, TasksPropType } from "../../Types/Types";
import videofile from '../../Assets/Images/VideoFileIcon.png';
import audiofile from '../../Assets/Images/AudioFileIcon.png';
import docfile from '../../Assets/Images/DocumentFileIcon.png'
import mediafile from '../../Assets/Images/Media.png';
import TaskTextInput from "./TaskQuestionInputs/TaskTextInput";
import TaskNumberInput from "./TaskQuestionInputs/TaskNumberInput";
import TaskRadioInput from "./TaskQuestionInputs/TaskRadioInput";
import TaskCheckboxInput from "./TaskQuestionInputs/TaskCheckboxInput";
import TaskFileInput from "./TaskQuestionInputs/TaskFileInput";
import TaskQuestionImage from "./TaskQuestionMediaPreview/TaskQuestionImage";
import TaskQuestionDocument from "./TaskQuestionMediaPreview/TaskQuestionDocument";
import TaskQuestionVideo from "./TaskQuestionMediaPreview/TaskQuestionVideo";
import TaskQuestionAudio from "./TaskQuestionMediaPreview/TaskQuestionAudio";
import TaskRecordInput from "./TaskQuestionInputs/TaskRecordInput";
import { useGetLocation } from "../../hooks/useGetLocation";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import TaskSubmitButton from "./TaskButtons/TaskSubmitButton";
import TaskRestartButton from "./TaskButtons/TaskRestartButton";
import TaskStartButton from "./TaskButtons/TaskStartButton";
import TaskTimeCounter from "./TaskTimeCounter/TaskTimeCounter";
import Logo from "../../Assets/Images/Logo.png";
import { mutationClient } from "../../Graphql/authClient";
import { getSession } from "../../utils/session";
import TaskRangeInput from "./TaskQuestionInputs/TaskRangeInput";
import { setActiveRoomData } from "../../Redux/ChatSlice";
import { useOrganizations } from "../../hooks/useOrganizations";
import gif from '../../Assets/S7MyZax57y.gif'
import { isExecutableDefinitionNode } from "graphql";
import { useTranslation } from "react-i18next";
import { socketListen } from "../../Socket";
import dayjs from "dayjs";
import moment from "moment";
import { currentWeekOfCurrentMonth } from "../../utils/task";
import { DefaultImageUrl } from "../../Constant/Constant";
const CurrentTask = ({ tasks, tabButton, setTabButton }: any) => {
  const {
    currentReport,
    currentAssignment,
    scenario,
    assignments,
    currentOrganization,
  } = useSelector((state: any) => state.organization);
  const { getCurrentLocation, longitude, latitude } = useGetLocation();
  const [timeoutCountDown, setTimeoutCountDown] = useState<any>(null);
  const [delayTimeCount, setDelayTimeCount] = useState<any>(null);
  const { getMyReports, getReportById } = useOrganizations();
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const { userData } = useSelector((state: any) => state.user);
  const [taskSubmitError, setTaskSubmitError] = useState("");
  const [isTimeDelayed, setIsTimeDelayed] = useState(true);
  const [currentTask, setCurrentTask] = useState<any>({});
  const [isExecutable, setIsExecutable] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [completeReport] = useMutation(COMPLETE_REPORT);
  const [addTaskResult] = useMutation(ADD_TASK_RESULT);
  const [attachmentId, setAttachmentId] = useState("");
  const [uploadedImage, setUploadedImage] = useState<any>({});
  const [location, setLocation] = useState([]);
  const [media, setMedia] = useState([]);
  const [upload, setUpload] = useState({ location: "", media: "" })
  const [loading, setLoading] = useState(false);
  const [startTask] = useMutation(START_TASK);
  const [result, setResult] = useState("");
  const inputRef = useRef<any>("");
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { getMyAssignment } = useOrganizations();
  const [enableSignature, setEnableSignature] = useState(false);
  const handleStartTask = (reportId: string, taskId: string) => {
    if (taskId) {
      startTask({
        variables: {
          input: {
            reportId: reportId,
            taskId: taskId,
          },
        },
      })
        .then((res) => { getMyAssignment(currentOrganization._id, 0, 0); })
        .catch((error) => {
          console.log(`Error occurred at Start Task => ${error}`);
        });
    }
  };

  function checkValidation(item: any) {
    let showToday = false;
    const getWeek = item?.daylyParams?.dayOfWeeks;
    const getMonth = item?.montlyParams?.months;
    const startDate = moment(item?.start).format("DD MMMM YYYY");
    const startTime = moment(item?.startTimeInMs).format("HH:mm");
    const startDateForMonth = moment(item?.start).format("DD yyyy");
    const endDateForMonth = moment(item?.end).format("DD yyyy");
    const currentDateForMonth = moment().format("DD yyyy");
    const currentWeekDay = moment().format("ddd");
    const month = moment().format("MMM");
    let message = "";
    const member = item?.members.filter(
      (item: any) => item?.member?.user?._id === userData._id
    );
    const currentTime = moment().format("hh:mm");
    const cctime = dayjs(item.startTimeInMs).format("hh:mm");
    switch (item?.recurrent) {
      case "ANYTIME":
        showToday = true;
        break;
      case "ONCE":
        if (
          dayjs().format("DD/MM/YYYY") ===
          dayjs(startDate).format("DD/MM/YYYY")
        ) {
          if (
            dayjs(member[0]?.completeTime).format("DD/MM/YYYY") ===
            dayjs().format("DD/MM/YYYY")
          ) {
            showToday = false;
            message = t("task_completed");
          } else {
            if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
              showToday = true;
            } else {
              showToday = false;
              message = t("task_condition1");
            }
          }
        } else {
          showToday = false;
          message = t("task_condition1");
        }

        break;
      case "DAILY":
        if (dayjs().isAfter(dayjs(item.start)) &&
          (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
          if (
            dayjs(member[0]?.completeTime).format("DD/MM/YYYY HH:mm") ===
            dayjs().format("DD/MM/YYYY HH:mm")
          ) {
            showToday = false;
            message = t("task_completed");
          } else {
            if ((moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
              showToday = true;
            } else {
              showToday = false;
              message = t("task_condition1");
            }
          }
        } else {
          showToday = false;
          message = t("task_condition2");
        }
        break;
      case "WEEKLY":
        switch (item.daylyParams?.everyWeek) {
          case 1:
            if (
              dayjs().isAfter(dayjs(item.start)) &&
              (dayjs().isBefore(dayjs(item.end), 'date') || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf()) && (moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())
            ) {
              if (
                dayjs(member[0]?.completeTime).format(
                  "DD/MM/YYYY HH:mm"
                ) === dayjs().format("DD/MM/YYYY HH:mm")
              ) {
                showToday = false;
                message = t('task_completed');
              } else {
                if ((moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
                  showToday = true;
                } else {
                  showToday = false;
                  message = t('task_condition1');
                }
              }
            } else {
              showToday = false;
              message = t('task_condition2');
            }
            break;
          case 2:
            if (currentWeekOfCurrentMonth() === 2) {
              if (getWeek.includes(currentWeekDay.toUpperCase())) {
                if (
                  dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                  (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                ) {
                  if (
                    dayjs(member[0]?.completeTime).format(
                      "DD/MM/YYYY HH:mm"
                    ) === dayjs().format("DD/MM/YYYY HH:mm")
                  ) {
                    showToday = false;
                    message = t('task_completed');
                  } else {
                    if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                      showToday = true;
                    } else {
                      showToday = false;
                      message = t('task_condition1');
                    }
                  }
                } else {
                  showToday = false;
                  message = t('task_condition2');
                }
              } else {
                showToday = false;
                message = t('task_condition2');
              }
            } else {
              showToday = false;
              message = t('task_condition2');
            }
            break;
          case 3:
            if (currentWeekOfCurrentMonth() === 3) {
              if (getWeek.includes(currentWeekDay.toUpperCase())) {
                if (
                  dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                  (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                ) {
                  if (
                    dayjs(member[0]?.completeTime).format(
                      "DD/MM/YYYY HH:mm"
                    ) === dayjs().format("DD/MM/YYYY HH:mm")
                  ) {
                    showToday = false;
                    message = t('task_completed');
                  } else {
                    if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                      showToday = true;
                    } else {
                      showToday = false;
                      message = t('task_condition1');
                    }
                  }
                } else {
                  showToday = false;
                  message = t('task_condition2');
                }
              } else {
                showToday = false;
                message = t('task_condition2');
              }
            } else {
              showToday = false;
              message = t('task_condition2');
            }
            break;
          case 4:
            if (currentWeekOfCurrentMonth() === 4) {
              if (getWeek.includes(currentWeekDay.toUpperCase())) {
                if (
                  dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                  (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                ) {
                  if (
                    dayjs(member[0]?.completeTime).format(
                      "DD/MM/YYYY HH:mm"
                    ) === dayjs().format("DD/MM/YYYY HH:mm")
                  ) {
                    showToday = false;
                    message = t('task_completed');
                  } else {
                    if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                      showToday = true;
                    } else {
                      showToday = false;
                      message = t("task_condition1");
                    }
                  }
                } else {
                  showToday = false;
                  message = t('task_condition2');
                }
              } else {
                showToday = false;
                message = t('task_condition2');
              }
            } else {
              showToday = false;
              message = t('task_condition2');
            }
            break;
        }
        break;
      case "MONTHLY":
        if (item?.montlyParams?.twicePerMonth === false) {
          if (
            currentDateForMonth >= startDateForMonth &&
            currentDateForMonth <= endDateForMonth
          ) {
            if (
              dayjs(member[0]?.completeTime).format(
                "DD/MM/YYYY HH:mm"
              ) === dayjs().format("DD/MM/YYYY HH:mm")
            ) {
              showToday = false;
              message = t("task_completed");
            } else {
              if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                showToday = true;
              } else {
                showToday = false;
                message = t('task_condition1');
              }
            }
          } else {
            showToday = false;
            message = t('task_condition2');
          }
        } else {
          if (getMonth.includes(month.toLocaleUpperCase())) {
            if (
              currentDateForMonth >= startDateForMonth &&
              currentDateForMonth <= endDateForMonth
            ) {
              if (
                dayjs(member[0]?.completeTime).format(
                  "DD/MM/YYYY HH:mm"
                ) === dayjs().format("DD/MM/YYYY HH:mm")
              ) {
                showToday = false;
                message = t('task_completed');
              } else {

                if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                  showToday = true;
                } else {
                  showToday = false;
                  message = t(`task_condition1`);
                }
              }
            } else {
              showToday = false;
              message = t("task_condition2");
            }
          }
        }
        break;
    }
    return { show: showToday, message }
  }
  useEffect(() => {

    if (
      currentReport?._id &&
      tasks[0]?._id &&
      currentReport?.tasksData?.length === 0
    ) {
      const targetTaskId = tasks[0]?.edges[0]?.targetTaskID;
      const task = tasks?.find((item: any) => item?._id === targetTaskId);
      const executable = task?.assignTo?.find(
        (item: any) => item?.user?._id === userData?._id
      );


      if (
        task?.assignTo?.length === 0 ||
        executable?.user?._id === userData?._id
      ) {
        setIsExecutable(true);
      } else {
        setIsExecutable(false);
      }

      setCurrentTask(task);

      if (task?.saveUserLocation) {
        // getCurrentLocation();
      }


      if (task?.nextPrompt?.type === "DELAY") {
        setDelayTimeCount(task?.nextPrompt?.time);
        setIsTimeDelayed(false);
      }

      setTimeoutCountDown(task?.timeout);
      handleStartTask(currentReport?._id, tasks[0]?._id);
      handleStartTask(currentReport?._id, targetTaskId);
    } else if (Object.keys(currentReport).length) {

      const targetTaskId =
        currentReport?.tasksData[currentReport?.tasksData?.length - 1]
          ?.targetTaskId;

      const task = tasks?.find((item: any) => item?._id === targetTaskId);
      const executable = task?.assignTo?.find(
        (item: any) => item?.user?._id === userData?._id
      );
      if (
        task?.assignTo?.length === 0 ||
        executable?.user?._id === userData?._id
      ) {
        setIsExecutable(true);
      } else {
        setIsExecutable(false);
      }


      setCurrentTask(task);

      if (task?.saveUserLocation) {
        // getCurrentLocation();
      }
      if (task) {

        setTaskData(task);
      }
      if (currentReport?.tasksData !== undefined && currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.isDelay) {
        setDelayTimeCount(currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.delayTime);
        setIsTimeDelayed(false);
      }

      setTimeoutCountDown(task?.timeout);
    }

    setTaskSubmitError("");

    // eslint-disable-next-line
  }, [currentReport, scenario, tasks]);

  useEffect(() => {
    var height = document.querySelector('#task-report').scrollHeight;
    document.querySelector('#task-report').scroll(0, height)
  }, [currentTask, currentReport]);

  useEffect(() => { setTaskData(currentTask) }, [currentTask])
  const setTaskData = (task: any) => {
    if (task?.subType === "custom_type") {
      let optionArray: any[] = [];
      let tempArray: any[] = [];
      let media: any[] = [];
      if (task && task.edges.length > 0) {
        task?.edges.forEach((element: any) => {
          if (element.location === true) {
            optionArray.push(element.label);
          }
          if (element.media !== null) {
            tempArray.push({ [element.label]: element.media });
          }
        });
        if (
          task.edges.length > 0 && task?.edges.filter((item: any) => item.media !== null).length > 0 &&
          task?.edges.filter((item: any) => item.media === task?.edges[0].media).length ===
          task?.edges.length
        ) {
          tempArray = [];
          tempArray.push({ ["all"]: task.edges[0].media });
        }
        if (
          task?.edges.filter((item: any) => item.location === true).length === task?.edges.length
        ) {
          setLocation(["all"]);
        } else {
          setLocation(optionArray);
        }
        setMedia(tempArray);
      }
    } else if (
      task?.type === "TEXT_INPUT" ||
      task?.type === "NUMBER_INPUT"
    ) {
      let optionArray: any[] = [];
      let tempArray: any[] = [];
      if (task && task.edges.length > 0) {
        task?.edges.forEach((element: any) => {
          if (element.location === true) {
            optionArray.push("all");
            setLocation(["all"]);
          }
          if (element.media !== null) {
            tempArray.push({ ["all"]: element.media });
          }
        });
        setMedia(tempArray);
      }
    } else if (
      task?.type === "RANGE"
    ) {
      let optionArray: any[] = [];
      let tempArray: any[] = [];
      if (task && task.rangeExpression?.customExp?.length > 0) {
        task?.rangeExpression?.customExp.forEach((element: any) => {
          if (element.location === true) {
            optionArray.push("all");
            setLocation(["all"]);
          }
          if (element.media !== null) {
            tempArray.push({ ["all"]: element.media });
          }
        });
        setMedia(tempArray);
      }
    } else if (
      task?.type === "CHECKBOX" || task?.type === 'MULTI_SELECT' || task?.type === 'RADIO'
    ) {
      let tempArray: any[] = [];
      let media: any[] = [];

      if (task && task.edges.length > 0) {

        task?.edges[0].options.forEach((element: any) => {
          if (element.location === true) {
            setLocation([...location, element.label]);
          }
          if (task?.edges[0].options.filter((item: any) => item.location === true).length === task?.edges[0].options.length) {
            setLocation(["all"]);
          }
          if (element.media !== null) {
            media.push(element.label);
            tempArray.push({ [element.label]: element.media });
          }
          if (task?.edges[0].options.filter((item: any) => item.media !== null).length === task?.edges[0].options.length) {
            media.push("all");
            tempArray.push({ [element.label]: element.media });
          }
        });
        setMedia(tempArray);
      }
    } else if (task?.subType === "yes_no" || task?.subType === "like_dislike") {
      let tempArray = [];
      let locationArray: any[] = [];
      if (task && task.edges.length > 0) {

        task?.edges.forEach((element: any) => {
          if (element.label === "Yes" && element.location === true) {
            locationArray.push("yes");
            if (element.media !== "" && element.media !== null) {
              tempArray.push({ ["yes"]: task?.edges[0].media });
            }
          } else {
            if (element.label === "Yes" && element.media !== "" && element.media !== null) {
              tempArray.push({ ["yes"]: task?.edges[0].media });
            }
          }
          if (element.label === "No" && element.location === true) {
            locationArray.push("no");
            if (element.media !== "" && element.media !== null) {
              tempArray.push({ ["no"]: task?.edges[0].media });
            }
          } else {
            if (element.label === "No" && element.media !== "" && element.media !== null) {
              tempArray.push({ ["no"]: task?.edges[0].media });
            }
          }
          if (element.label === "Like" && element.location === true) {
            locationArray.push("like");

            if (element.media !== "" && element.media !== null) {
              tempArray.push({ ["like"]: element.media });
            }
          } else {
            if (element.label === "Like" && element.media !== "" && element.media !== null) {
              tempArray.push({ ["like"]: element.media });
            }
          }

          if (element.label === "Dislike" && element.location === true) {
            locationArray.push("dislike");
            if (element.media !== "" && element.media !== null) {

              tempArray.push({ ["dislike"]: element.media });
            }
          } else {
            if (element.label === "Dislike" && element.media !== "" && element.media !== null) {
              tempArray.push({ ["dislike"]: element.media });
            }
          }
          if (task?.edges.length > 0) {
            if (
              task?.edges[0]?.location !== null &&
              task?.edges[1]?.location !== null &&
              task?.edges.filter((item: any) => item.location === true).length === task?.edges.length
            ) {
              locationArray = [];
              locationArray.push("all");

            }
          }
        });
        if (task?.edges.length > 0) {
          if (
            task?.edges[0]?.media !== null &&
            task?.edges[1]?.media !== null &&
            task?.edges[0]?.media === task?.edges[1]?.media
          ) {
            tempArray = [];
            tempArray.push({ ["all"]: task?.edges[0].media });
          }
        }
      }

      setLocation(locationArray);
      setMedia(tempArray);
    }
  }
  const handleStartReport = () => {
    setLoading(true);
    const { token } = getSession();

    mutationClient(token)
      .mutate({
        mutation: START_REPORT,
        variables: {
          input: {
            assignmentId: currentAssignment?._id,
            orgId:scenario?.organizationId,
            masterOrg:currentOrganization?._id
          },
        },
      })
      .then((res) => {
        const tempAssignments = [...assignments];
        const updatedAssignments = tempAssignments?.map((item) => {
          if (currentAssignment?._id === item?._id) {
            return {
              ...item,
              activeReportId: res?.data?.startReport?._id,
            };
          }
          return item;
        });

        dispatch(setAssignments(updatedAssignments));

        let tempAssignment = {
          ...currentAssignment,
          activeReportId: res?.data?.startReport?._id,
        };
        dispatch(setCurrentAssignment(tempAssignment));
        dispatch(setCurrentReport(res?.data?.startReport));
        var height = document.querySelector('#task-report').scrollHeight;
        document.querySelector('#task-report').scroll(0, height)
      })
      .catch((error) =>
        console.log(`Error occurred at Start Report => ${error}`)
      )
      .finally(() => setLoading(false));
  };

  const handleAddTaskResult = (
    taskTargetId: string,
    edgeId: string,
    edgeLabel: string,
    timeoutLabel: string,
    attachId: string
  ) => {
    if (
      currentTask?.type === "SELECT_ONE" ||
      result !== "" ||
      attachmentId !== "" ||
      inputRef.current !== "" ||
      timeoutLabel !== "" ||
      checkedValues.length !== 0
    ) {
      setTimeoutCountDown(null);
      setLoading(true);


      addTaskResult({
        variables: {
          input: {
            orgId:currentOrganization?._id,
            attachmentId: currentTask?.attachment?.attachment?._id || null,
            reportId: currentReport?._id,
            label: currentTask?.label,
            content: currentTask?.content,
            taskId: currentTask?._id,
            result:
              edgeLabel || result ||
              inputRef?.current ||

              "" ||
              timeoutLabel ||
              checkedValues.join(", "),
            edgeId: edgeId,
            type: currentTask?.type,
            lat: location.length > 0 ? latitude : null,
            long: location.length > 0 ? longitude : null,
            resultAttachment: attachmentId ? attachmentId : attachId ? attachId : null,
            targetTaskId: taskTargetId,
          },
        },
      })
        .then((res) => {
          const targetId =
            res?.data?.addTaskResult?.tasksData[
              res?.data?.addTaskResult?.tasksData?.length - 1
            ]?.targetTaskId;
          const task = tasks?.find((item: any) => item?._id === targetId);
          setCurrentTask(task);
          setUploadedImage({});
          setAttachmentId("")
          dispatch(setCurrentReport(res?.data?.addTaskResult));
          var height = document.querySelector('#task-report').scrollHeight;
          document.querySelector('#task-report').scroll(0, height)// Scrolls to the bottom
          //   getReportById(currentAssignment.members.find((el: any) => el?.member?.user?._id === userData?._id)?.activeReportId)
        })
        .catch((error) => {
          console.log(`Error occurred at Add Task Result => ${error}`);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
          setResult("");
          setCheckedValues([]);
          setAttachmentId("");
          setTaskSubmitError("");
          setMedia([]);
          setLocation([])
          inputRef.current = "";
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      setTaskSubmitError(t("task_error"));
    }
  };



  useEffect(() => {
    if (currentTask?.type !== 'INPUT') {
      switch (currentTask?.type) {
        case "SELECT_ONE": {
          if (currentTask?.edges?.length > 0 && currentTask?.edges?.filter((el: any) => el?.signature === true).length > 0) {
            setEnableSignature(true);
          } else {
            setEnableSignature(false);
          }
          break;
        }
        case "CHECKBOX": {
          if (currentTask?.edges?.length > 0 && currentTask?.edges?.[0]?.options?.filter((el: any) => el.signature === true).length > 0) {
            setEnableSignature(true);
          } else {
            setEnableSignature(false);
          }
          break;
        }
        case "TEXT_INPUT": {
          if (currentTask?.edges?.length > 0 && currentTask?.edges[0]?.signature) {
            setEnableSignature(true)
          } else {
            setEnableSignature(false);
          }
          break;
        }
        case "MEDIA_UPLOAD": {
          if (currentTask?.edges?.length > 0 && currentTask?.edges[0]?.signature) {
            setEnableSignature(true)
          } else {
            setEnableSignature(false);
          }
          break;
        }
        case "RANGE": {
          if (currentTask?.rangeExpression?.customExp?.length > 0 && currentTask?.rangeExpression?.customExp?.filter((el: any) => el.signature === true).length > 0) {
            setEnableSignature(true)
          } else {
            setEnableSignature(false);
          }

          break;
        }
        case "NUMBER_INPUT": {
          if (currentTask?.edges?.length > 0 && currentTask?.edges[0]?.signature === true) {
            setEnableSignature(true)
          } else {
            setEnableSignature(false);
          }
          break;
        }
        case "OUTPUT": {
          if (currentTask?.signature === true) {
            setEnableSignature(true)
          } else {
            setEnableSignature(false);
          }
          break;
        }

      }
    }
  }, [currentTask])


  const handleTimeout = () => {
    setTimeoutCountDown(null);

    let targetTaskId = "";
    let edgeId = "";

    currentTask?.edges?.forEach((item: any) => {
      if (item?.type === "TIMEOUT") {
        targetTaskId = item?.targetTaskID;
        edgeId = item?._id;
      }
    });

    setTimeout(() => {
      handleAddTaskResult(targetTaskId, edgeId, "", "Timeout", attachmentId);
    }, 1000);
  };

  const handleCompleteReport = () => {
    setLoading(true);
    completeReport({
      variables: {
        input: {
          _id: currentReport?._id,
          orgId:currentOrganization?._id
        },
      },
    })
      .then((res) => {
        getMyAssignment(currentOrganization._id, 0, 0);
        if (currentAssignment?.recurrent === "ANYTIME") {
          const updatedAssignments = assignments?.map(
            (item: AssignmentDataTypes) => {
              if (item?._id === res?.data?.completeReport?.assignment?._id) {
                return {
                  ...item,
                  activeReportId: null,
                };
              }

              return item;
            }
          );

          dispatch(setAssignments(updatedAssignments));
        } else {
          const updatedAssignments = assignments?.filter(
            (item: AssignmentDataTypes) =>
              item?._id !== res?.data?.completeReport?.assignment?._id
          );

          dispatch(setAssignments(updatedAssignments));
        }
        dispatch(setShowTaskPage(false));
        dispatch(setActiveRoomData({}));
        dispatch(setCurrentReport({}));
        getMyReports(0, 0).finally(() => {
          setTabButton(2);
        })
      })
      .catch((error) =>
        console.log(`Error occurred at complete report => ${error}`)
      )
      .finally(() => setLoading(false));
  };



  useEffect(() => {
    socketListen("message", (data: any) => {

      if (data.type === "completeReport") {
        getMyAssignment(currentOrganization._id, 0, 0);
        getMyReports(0, 0);
        if (currentAssignment?._id === data?.msg?.assignmentId) {
          dispatch(setShowTaskPage(false));
          dispatch(setActiveRoomData({}));
          dispatch(setCurrentReport({}));
          setLoading(false)
          setTabButton(2)
        }
      }
    });

  }, [])

  const isExecutableorNot = tasks.length > 2 ? tasks[1]?.assignTo?.filter((item: any) => item?.user?._id === userData?._id) : [];
  const taskId = currentReport?.tasksData?.length > 0 &&
    currentReport?.tasksData[currentReport?.tasksData?.length - 1].taskId;
  const role = currentReport !== undefined ? currentReport?.member?.filter((el: any) => el?.user?._id === userData?._id).length > 0 ? currentReport?.member?.find((el: any) => el?.user?._id === userData?._id).role : "null" : "null";
  const currentEdge = tasks?.length > 2 ?
    tasks.filter((el: any) => el._id === taskId).pop()
    : { nextPrompt: { type: "" } }
  const executable = currentTask?.assignTo === undefined ? tasks.length > 2 ? tasks[1]?.assignTo?.filter((item: any) => item?.user?._id === userData?._id) : [] : currentTask?.assignTo?.length === 0 ? true : false;
  const now: any = new Date();

  // Set the target time to 12:30 PM today
  const targetTime: any = new Date();
  if (currentReport?.tasksData?.length > 0) {
    targetTime.setHours(dayjs(currentReport?.tasksData[currentReport?.tasksData?.length - 1].taskCompleteTime).hour(), dayjs(currentReport?.tasksData[currentReport?.tasksData?.length - 1].taskCompleteTime).minute(), dayjs(currentReport?.tasksData[currentReport?.tasksData?.length - 1].taskCompleteTime).second(), dayjs(currentReport?.tasksData[currentReport?.tasksData?.length - 1].taskCompleteTime).millisecond()); // 12:30 PM
  }
  // Calculate the time difference in milliseconds
  const timeDifference: any = now - targetTime;

  // Convert the time difference into seconds
  const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);

  let timediff: any = currentReport !== undefined && currentReport?.tasksData?.length > 0 ? currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.delayTime < timeDifferenceInSeconds ? Math.abs(timeDifferenceInSeconds - currentReport?.tasksData[currentReport?.tasksData?.length - 1].delayTime) : 0 : delayTimeCount;
  // console.log(currentReport?.tasksData[currentReport?.tasksData?.length - 1],'evnets======')
  const adminapproval = executable &&
    currentReport?.tasksData?.length > 0 &&
    currentReport?.tasksData[currentReport?.tasksData?.length - 1]
      ?.isApproved !== null &&
    !currentReport?.tasksData[currentReport?.tasksData?.length - 1]
      ?.isApproved;
  return (
    <div>

      {loading && (
        <div className="h-screen w-screen bg-[#0000009c] fixed z-[999999999] left-0 right-0 top-0 bottom-0 flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img
                alt={t("loading")}
                src={gif}
                className="h-36 w-36"
              />
            </div>
          </div>
        </div>
      )}
      {checkValidation(currentAssignment).show === false ?
        <div className="bg-[#33ccff] outline-none py-2 text-lg px-5 rounded-md w-full
        text-white text-center">{checkValidation(currentAssignment).message}</div>
        : currentReport && Object.keys(currentReport).length ? (
          <>
            {currentTask && (
              <>
                <div className="flex items-center gap-3 my-3 ">
                  <div className="h-[35px] min-w-[35px] w-[35px]">
                    <img
                      src={Logo}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div>{currentOrganization?.name}</div>
                </div>

                <div className=" w-fit min-w-[300px] max-w-[50%] py-3 px-4 mb-4 rounded-md bg-[#E0FAFF] border " style={{ borderColor: "#33ccff" }}>
                  <div className=" border-b-2 pb-1">
                    {currentTask?.label}
                  </div>
                  <div className="mt-2">{currentTask?.content}</div>

                  {currentTask?.attachment &&
                    currentTask?.attachment?.attachment && (
                      <>
                        <TaskQuestionImage currentTask={currentTask} />
                        <TaskQuestionDocument currentTask={currentTask} currentOrganization={currentOrganization} />
                        <TaskQuestionVideo currentTask={currentTask} />
                        <TaskQuestionAudio currentTask={currentTask} />
                      </>
                    )}
                  {attachmentId && uploadedImage?.filename !== undefined && (
                    <div className="flex items-center"><img src={uploadedImage?.type === 'VIDEO' ? videofile : uploadedImage?.type === 'AUDIO' ? audiofile : uploadedImage?.type === 'APPLICATION' ? docfile : DefaultImageUrl + uploadedImage?.filename} className="h-8 w-8 mr-2 rounded-md " />{uploadedImage?.filename?.split('/').pop()}</div>
                  )}

                  {timeoutCountDown !== null && (
                    <div>
                      <div className="my-2 textGray text-sm flex gap-2 items-center justify-end">
                        <div>
                          <TaskTimeCounter
                            duration={timeoutCountDown}
                            onComplete={handleTimeout}
                          />

                        </div>
                        <div>
                          <AlarmOutlinedIcon />
                        </div>
                      </div>
                      <div className="text-sm flex mt-[-12px] justify-end text-gray-500">{t('task-timeout-message')}</div>
                    </div>
                  )}

                  {currentTask?.type === 'RANGE' && currentTask?.rangeExpression?.max !== "" && currentTask?.rangeExpression?.min !== "" ?
                    <div className='flex justify-between'>
                      <div>{t('min_val')}: {currentTask?.rangeExpression?.min}</div>
                      <div>{t('max_val')}: {currentTask?.rangeExpression?.max}</div>
                    </div>
                    : ""}
                  <div className="my-3 w-full max-w-[50%]">
                    <TaskRadioInput
                      location={location}
                      media={location}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      setResult={setResult}

                    />

                    <TaskCheckboxInput
                      location={location}
                      media={media}
                      attachmentId={attachmentId}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      checkedValues={checkedValues}
                      setResult={setResult}
                      result={result}
                      setCheckedValues={setCheckedValues}
                    />

                    <TaskFileInput
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      setLoading={setLoading}
                      setAttachmentId={setAttachmentId}
                      setTaskSubmitError={setTaskSubmitError}
                    />

                    <TaskTextInput
                      location={location}
                      media={location}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      inputRef={inputRef}
                      result={result}
                      setResult={setResult}
                    />

                    <TaskNumberInput
                      location={location}
                      media={location}
                      result={result}
                      setResult={setResult}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      inputRef={inputRef}
                    />

                    <TaskRecordInput
                      location={location}
                      media={location}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}

                    />

                    <TaskRangeInput
                      location={location}
                      media={location}
                      setAttachmentId={setAttachmentId}
                      setLoading={setLoading}
                      setTaskSubmitError={setTaskSubmitError}
                      currentTask={currentTask}
                      isExecutable={isExecutable}
                      result={result}
                      setResult={setResult}
                      setIsSubmitDisable={setIsSubmitDisable}
                    />
                  </div>
                </div>
              </>
            )}
            {!isTimeDelayed && currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.isDelay && currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.delayTime > timeDifferenceInSeconds && (
              <div className="bg-gray-100 rounded-md py-2 px-5 my-3 w-fit">
                <div className="text-sm flex  justify-end text-gray-500">{t("task-delay-timer-message")} </div>
                <div className="mt-2 flex items-center gap-3">
                  <div>
                    <AlarmOutlinedIcon />
                  </div>
                  <div>
                    <TaskTimeCounter
                      duration={timediff * 1000}
                      timediff={timediff}
                      onComplete={(e) => { setIsTimeDelayed(true); timediff = 0; console.log(e, 'complete') }}
                    />
                  </div>
                </div>
              </div>
            )}

            {location.length > 0 && (
              <div
                className="my-4 bg-[#f1f3f4] text-gray-500 py-2 px-3 flex gap-1
               w-fit rounded-md"
              >
                <FmdGoodOutlinedIcon />
                <div>{t("use_your_location")}</div>
              </div>
            )}

            {
              role !== "null" && (role === "MEMBER" || role === "OWNER") ?
                !executable &&
                currentTask?.assignTo?.length > 0 && currentTask?.assignTo?.filter((item: any) => item?.user?._id === userData?._id).length === 0 && (
                  <button className="bg-[#33ccff] outline-none py-2 text-lg px-5 rounded-md w-full
              text-white"
                    disabled>
                    {t("task_assigned_others")}
                  </button>
                ) : (role !== "null" && currentTask?.type !== "OUTPUT" && !executable && currentTask?.assignTo?.length > 0 && currentTask?.assignTo?.filter((item: any) => item?.user?._id === userData?._id).length === 0) && (
                  <div className="my-4">
                    <button
                      className="bg-[#33ccff] outline-none py-2 text-lg px-5 rounded-md w-full
                text-white"
                      disabled
                    >
                      {t("monitor_task_execute_member")}
                    </button>
                  </div>
                )}
            {(adminapproval && (
              <div className="my-3 bg-gray-100 rounded-md py-2 px-4 w-full mx-auto flex justify-center ">
                {t("execute_task_admin_aprove")}
              </div>
            )
            )
            }

            {taskSubmitError && (
              <div className="my-2 text-red-600 text-sm">{taskSubmitError}</div>
            )}

            {enableSignature ?
              <div className="my-3 bg-gray-100 rounded-md py-2 px-4 w-full mx-auto flex justify-center ">
                {t("enable-signature-message")}
              </div>
              :
              <>
                {currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.isDelay && !isTimeDelayed ? ((currentReport?.tasksData?.length > 0 &&
                  (currentReport?.tasksData[currentReport?.tasksData?.length - 1]
                    ?.isApproved !== false || currentReport?.tasksData[currentReport?.tasksData?.length - 1]
                      ?.isApproved === null)) || (currentReport?.tasksData[currentReport?.tasksData?.length - 1] === undefined)) && (currentReport?.tasksData[currentReport?.tasksData?.length - 1]?.delayTime < timeDifferenceInSeconds) &&
                  <TaskSubmitButton
                    location={location}
                    media={media}
                    setAttachmentId={setAttachmentId}
                    setLoading={setLoading}
                    setTaskSubmitError={setTaskSubmitError}
                    currentTask={currentTask}
                    loading={loading}
                    setUploadedImage={setUploadedImage}
                    attachmentId={attachmentId}
                    setResult={setResult}
                    handleAddTaskResult={handleAddTaskResult}
                    isExecutable={isExecutable}
                    inputRef={inputRef}
                    checkedValues={result ||
                      inputRef?.current ||
                      checkedValues.join(", ")}
                    result={result}
                    isSubmitDisable={isSubmitDisable}
                  /> :
                  adminapproval === false &&
                  <TaskSubmitButton
                    location={location}
                    media={media}
                    setUploadedImage={setUploadedImage}
                    attachmentId={attachmentId}
                    result={result}
                    setResult={setResult}
                    setAttachmentId={setAttachmentId}
                    setLoading={setLoading}
                    inputRef={inputRef}
                    setTaskSubmitError={setTaskSubmitError}
                    currentTask={currentTask}
                    loading={loading}
                    checkedValues={result ||
                      inputRef?.current ||
                      checkedValues.join(", ")}
                    handleAddTaskResult={handleAddTaskResult}
                    isExecutable={isExecutable}

                    isSubmitDisable={isSubmitDisable}
                  />
                }
              </>}

            {
            !enableSignature&&(
            currentEdge?.nextPrompt?.type === 'APPROVAL' ? currentReport?.tasksData[currentReport?.tasksData?.length - 1]
              ?.isApproved && <TaskRestartButton
                loading={loading}
                currentTask={currentTask}
                handleCompleteReport={handleCompleteReport}
              /> : <TaskRestartButton
              loading={loading}
              currentTask={currentTask}
              handleCompleteReport={handleCompleteReport}
            />
          )}
          </>
        ) : (
          <>
            {!executable ? (
              <div className="my-4">
                <button
                  className="bg-[#33ccff] outline-none py-2 px-5 rounded-md w-full
                text-white"
                  disabled
                >
                  {t("monitor_task_execute_member")}
                </button>
              </div>
            ) :
              <div className="flex justify-center">
                <TaskStartButton
                  loading={loading}
                  handleStartReport={handleStartReport}
                />
              </div>
            }
          </>
        )}
    </div>
  );
};

export default CurrentTask;
