import { ApprovalReminderTimeTypes } from "../Components/Calendar/types/enum"

const convertToMinutes = (count: number, unit: string) => {
    switch (unit) {
        case ApprovalReminderTimeTypes.MINUTE:
            return count
        case ApprovalReminderTimeTypes.HOUR:
            return count * 60
        case ApprovalReminderTimeTypes.DAY:
            return count * 24 * 60
        case ApprovalReminderTimeTypes.WEEK:
            return count * 7 * 24 * 60
        default:
            return 0
    }
}

export default convertToMinutes