import { useState } from "react"
import { useDispatch } from "react-redux"
import { setLocation } from "../Redux/ChatSlice"

export const useGetLocation = () => {
    const [latitude, setLatitude] = useState<string | null>(null)
    const [longitude, setLongitude] = useState<string | null>(null)
const dispatch=useDispatch();
    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            localStorage.setItem('long',position.coords.longitude.toString())
            localStorage.setItem('lat',position.coords.latitude.toString())
            dispatch(setLocation({lat:position.coords.latitude.toString(),long:position.coords.longitude.toString()}))
        })
    }
    return {
        getCurrentLocation,
        latitude:localStorage.getItem('lat'),
        longitude:localStorage.getItem('long')
    }
}
