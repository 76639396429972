import { Box } from "@mui/material";
import dayjs from "dayjs";
import { colors } from "../../Constant/Constant";
import { EventTypeEnum } from "./types/enum";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiDatabase } from "react-icons/fi";

function CalendarAppEventContent(props: any) {
  const { t } = useTranslation();
  const { eventInfo } = props || {};
  const time = eventInfo?.event?.start;
  const { extendedProps } = eventInfo?.event;
  const { rooms } = useSelector((state: any) => state?.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state?.user
  );

  function backgroundColor() {
    switch (extendedProps?.type) {
      case EventTypeEnum.REMINDER:
        return colors.reminder;
      case EventTypeEnum.APPOINTMENT:
        return colors.appointment;
      case EventTypeEnum.CALLREMINDER:
        return colors.callReminder;
      case EventTypeEnum.SCHEDULE:
        return colors.schedule;
      case EventTypeEnum.Record_Reminder:
        return colors.record;
      default:
        return colors.assignment;
    }
  }

  function getScheduleTitle() {
    const room = rooms?.find((el: any) => el?._id == extendedProps?.roomId);
    let name = "";
    if (room?.type == "group") {
      name = room?.name;
    } else {
      const participant = room?.participants?.find(
        (el: any) => el?.user_id != userData?._id
      );
      const contact = formattedContact?.[participant?.user_id];
      if (contact?.firstName) {
        name = `${contact?.firstName} ${contact?.lastName}`;
      } else {
        name = participant?.phone;
      }
    }
    return name;
  }

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor(),
        color:
          extendedProps?.type == EventTypeEnum.SCHEDULE ? "#373A40" : "white",
      }}
      className={
        "flex flex-wrap gap-x-1 px-2 items-center w-full rounded-md mt-2"
      }
    >
      {extendedProps?.type != EventTypeEnum.Record_Reminder ? (
        <div>{dayjs(time).format("HH:mm")}</div>
      ) : (
        <div>
          <FiDatabase />
        </div>
      )}

      <div className="text-[12px] truncate">
        <span>
          {extendedProps?.type == EventTypeEnum.SCHEDULE
            ? getScheduleTitle()
            : eventInfo?.event?.title}
        </span>
        {extendedProps?.type == EventTypeEnum.SCHEDULE && (
          <span className="ml-1">({t("SCHEDULE")})</span>
        )}
      </div>
    </Box>
  );
}

export default CalendarAppEventContent;
