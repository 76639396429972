import { DefaultImageUrl } from "../../../Constant/Constant";

const TaskQuestionVideo = ({ currentTask }: any) => {

  return (
    <>
      {currentTask?.attachment?.attachment?.type === "VIDEO" && (
        <div className="mt-3 w-[300px] h-[400px]">
          <video
            src={
              DefaultImageUrl + currentTask?.attachment?.attachment?.filename
            }
            className="w-full h-full"
            controls
          ></video>
        </div>
      )}
    </>
  );
};

export default TaskQuestionVideo;
