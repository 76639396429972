import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FirstHalfOfYear, SecondHalfOfYear } from "../utils/task";

export const useScheduleTime = () => {
  const [executingTime, setExecutingTime] = useState<any>("");
  const [isCorrectTime, setIsCorrectTime] = useState<boolean>(false);
  const { currentAssignment } = useSelector((state: any) => state.organization);
  const gettingTime = moment(currentAssignment?.startTimeInMs).format("HH:mm");
  const currentTime = moment().format("HH:mm");
  const currentDay = moment().format("ddd");
  const currentMonth = moment().format("MMM");
  const gettingDayOfWeeks = currentAssignment?.daylyParams?.dayOfWeeks;
  const gettingMonths = currentAssignment?.montlyParams?.months;
  const currentQuarter = moment().quarter();
  const gettingQuarter = moment(currentAssignment?.start).quarter();
  const currMonth = moment().format("MMM");
  const gettingYear = moment(currentAssignment?.start).year()
  const currYear = moment().year()

  const checkScheduleTime = () => {
    if (currentTime >= gettingTime) {
      if (currentAssignment?.recurrent === "WEEKLY") {
        if (gettingDayOfWeeks?.includes(currentDay?.toUpperCase())) {
          setIsCorrectTime(true);
        } else {
          setExecutingTime(gettingDayOfWeeks?.join(", "));
        }
      } else if (currentAssignment?.recurrent === "MONTHLY") {
        if (gettingMonths?.includes(currentMonth?.toUpperCase())) {
          setIsCorrectTime(true);
        } else {
          setExecutingTime(gettingMonths?.join(", "));
        }
      } else if (currentAssignment?.recurrent === "QUARTERLY") {
        if (currentQuarter !== gettingQuarter) {
          setIsCorrectTime(true);
        } else {
          setExecutingTime(
            moment().quarter(gettingQuarter).format("DD MMMM YYYY")
          );
        }
      } else if (currentAssignment?.recurrent === "HALFYEARLY") {
        if (!FirstHalfOfYear.includes(currMonth?.toUpperCase())) {
          setExecutingTime(SecondHalfOfYear.join(", "));
        } else {
          setIsCorrectTime(true)
        }
      } else if (currentAssignment?.recurrent === 'ANNUALLY') {
        if (currYear === gettingYear) {
          setIsCorrectTime(true)
        } else {
          setExecutingTime(gettingYear)
        }
      }else if(currentAssignment?.recurrent==='DAILY'){
        if(currentTime===executingTime){
          setIsCorrectTime(true);
        }
      } else {
        setIsCorrectTime(true)
      }
    } else {
      setExecutingTime(gettingTime);
      setIsCorrectTime(true);
    }
  };

  return {
    executingTime,
    isCorrectTime,
    checkScheduleTime,
  };
};
