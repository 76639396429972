import { useTranslation } from "react-i18next";
import { Dialog, Alert, DialogContent } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "#a4a4a4",
  },
}));

const MultiTabDialog = ({ show }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Alert severity="error">
          <div> ComOn {t("multitabdesc")}</div>
        </Alert>
      </DialogContent>
    </CustomDialog>
  );
};

export default MultiTabDialog;
