import dayjs from "dayjs";
import { InvitationsType } from "../types/types";
import { authCllient } from "../../../Graphql/authClient";
import {
  UPDATE_PARENT_REMINDER_STATUS,
  UPDATE_REMINDER_STATUS,
} from "../../../Graphql/Mutations";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  RecurrentTypes,
} from "../types/enum";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "../../../Assets/Images/userAvatar.png";
import clsx from "clsx";
import { colors } from "../../../Constant/Constant";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { CircularProgress } from "@mui/material";
import EventConfirmation from "../dialogs/EventConfirmation";

type PropTypes = {
  setAlert: (value: any) => void;
  setIsRefresh: (value: boolean) => void;
  invitation: InvitationsType;
};

function ReminderInvitation(props: PropTypes) {
  const { t } = useTranslation();
  const { setAlert, setIsRefresh, invitation } = props;
  const [loading, setloading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  function clickOnAcceptReject(invitation: InvitationsType, status: string) {
    setSelectedInvitation(invitation);
    setSelectedStatus(status);
    setShowConfirmation(true);
  }

  async function handleChangeStatus(
    type: string,
    isOnce: boolean,
    id: string,
    status: string
  ) {
    setloading(true);

    const payload = {
      _id: isOnce
        ? id
        : type == "this"
        ? selectedInvitation?._id
        : selectedInvitation?.parent_id,
      ApprovalStatus: isOnce ? status : selectedStatus,
    };

    try {
      const response = await authCllient.mutate({
        mutation:
          type == "this"
            ? UPDATE_REMINDER_STATUS
            : UPDATE_PARENT_REMINDER_STATUS,
        variables: {
          input: payload,
        },
      });

      setIsRefresh(true);
      let msg = "";
      if (!response?.data) {
        msg = "something_went_wrong";
      } else {
        msg =
          (selectedStatus || status) == ParticipantAcceptStatus.ACCEPT
            ? "invitation_accept_msg"
            : "invitation_reject_msg";
      }
      setAlert({ msg, show: true });
    } catch (error) {
      setAlert({ msg: "something_went_wrong", show: true });
      console.log(error);
    } finally {
      setloading(false);
    }
  }

  return (
    <div className="flex gap-3">
      <div>
        <img src={Avatar} alt="avatar" className="h-[40px] w-[40px]" />
      </div>
      <div>
        <div className="text-lg font-semibold">{invitation?.title}</div>
        <div className="mt-1 text-sm text-gray-500">
          {dayjs(invitation?.startDate).format("DD MMMM YYYY")}

          {invitation?.recursive != RecurrentTypes.ONCE && (
            <React.Fragment>
              - {dayjs(invitation?.endDate).format("DD MMMM YYYY")}
            </React.Fragment>
          )}

          <span className="ml-1">
            | {dayjs(invitation?.time).format("HH:mm")}
          </span>
        </div>

        <div className="flex gap-3 mt-2">
          <div
            className={clsx(
              "text-[12px] text-white px-4 py-1 w-fit rounded-md"
            )}
            style={{
              backgroundColor:
                invitation?.type == EventTypeEnum.APPOINTMENT
                  ? colors.appointment
                  : invitation?.type == EventTypeEnum.REMINDER
                  ? colors.reminder
                  : colors.callReminder,
            }}
          >
            {t(invitation?.recursive)}
          </div>

          <div>
            <GroupIcon
              sx={{
                color:
                  invitation?.type == EventTypeEnum.APPOINTMENT
                    ? colors.appointment
                    : invitation?.type == EventTypeEnum.REMINDER
                    ? colors.reminder
                    : colors.callReminder,
              }}
            />
            <span className="ml-1">{invitation?.participants?.length}</span>
          </div>

          {invitation?.approvalReminderTime?.length > 0 && (
            <div>
              <NotificationsIcon
                sx={{
                  color:
                    invitation?.type == EventTypeEnum.APPOINTMENT
                      ? colors.appointment
                      : invitation?.type == EventTypeEnum.REMINDER
                      ? colors.reminder
                      : colors.callReminder,
                }}
              />

              <span className="ml-1">
                {invitation?.approvalReminderTime?.length}
              </span>
            </div>
          )}
        </div>

        {!dayjs(invitation?.startDate).isBefore(dayjs(), "day") &&
          dayjs(invitation?.time).isAfter(dayjs(), "minute") && (
            <React.Fragment>
              {loading ? (
                <div className="mt-3 flex justify-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div className="mt-3 flex gap-3">
                  <button
                    disabled={Boolean(loading)}
                    onClick={() => {
                      if (invitation?.recursive == RecurrentTypes.ONCE) {
                        handleChangeStatus(
                          "this",
                          true,
                          invitation?._id,
                          ParticipantAcceptStatus.ACCEPT
                        );
                      } else {
                        clickOnAcceptReject(
                          invitation,
                          ParticipantAcceptStatus.ACCEPT
                        );
                      }
                    }}
                    className="outline-none text-sm border border-green-700 text-green-700 rounded-md py-1 px-2"
                  >
                    {t("accept")}
                  </button>
                  <button
                    disabled={Boolean(loading)}
                    onClick={() => {
                      if (invitation?.recursive == RecurrentTypes.ONCE) {
                        handleChangeStatus(
                          "this",
                          true,
                          invitation?._id,
                          ParticipantAcceptStatus.REJECT
                        );
                      } else {
                        clickOnAcceptReject(
                          invitation,
                          ParticipantAcceptStatus.REJECT
                        );
                      }
                    }}
                    className="outline-none text-sm border border-red-700 text-red-700 rounded-md py-1 px-2"
                  >
                    {t("reject")}
                  </button>
                </div>
              )}
            </React.Fragment>
          )}
      </div>

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={handleChangeStatus}
          message={t("update_approval_status")}
        />
      )}
    </div>
  );
}

export default ReminderInvitation;
