import React, { useState } from "react";
import Buttons from "../coreComponent/Buttons";
import TextFieldForm from "../coreComponent/TextFieldForm";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AppPath,vapidKey } from "../Constant/Constant";
import { useTranslation } from "react-i18next";
import { authCllient, refreshTokenCllient } from "../Graphql/authClient";
import { setSession } from "../utils/session";

import { setUserData } from "../Redux/UserSlice";
import { messaging } from "../Firebase/firebase";
import { getToken } from "firebase/messaging";
import { ME, SUBSCRIBE_TO_TOPIC } from "../Graphql/Queries";
import { RESET_PASSWORD } from "../Graphql/Mutations";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

type ErrorType = {
  password: String;
  confirmPassword: String;
};

function ResetPassword() {
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [load,setLoad]=useState(false);
  const [error, setError] = useState<ErrorType | any>({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setLoad(true);
    if (input.password === "") {
      setLoad(false);
      setError({ password: "Please Enter Password" });
    } else if (input.confirmPassword === "") {
      setLoad(false)
      setError({ confirmPassword: "Please Enter Confirm Password" });
    } else {
      const token=localStorage.getItem('resetToken');
    console.log(`Bearer ${token}`, '00000')
    refreshTokenCllient(token).mutate({
      mutation: RESET_PASSWORD,
      variables: {
        input: {
          password:input.password,
        },
      },
    }).then(({ data }) => {
      
      if (data.passwordReset) {
        localStorage.setItem("token", data.passwordReset.token);
        authCllient
          .query({
            query: ME,
          })
          .then((res) => {
            navigate(AppPath.home);
            dispatch(setUserData(res?.data?.me))
          });
        // dispatch(setIsContactsLoading(true));
        // dispatch(setIsRoomsLoading(true))

        setSession(data?.passwordReset);
        setTimeout(() => {

         window.location.reload();

          getToken(messaging, { vapidKey }).then((currentToken) => {
            if (currentToken) {
              localStorage.setItem("notification_token", currentToken);
              authCllient
                .query({
                  query: SUBSCRIBE_TO_TOPIC,
                  variables: {
                    token: currentToken,
                  },
                })
                .then((res: any) => {
                  if (res?.data?.subscribeToTopic?.success) {
                    localStorage.setItem('subscribed', "true");
                  }
                });
            }
          });
        }, 1000);
      }
    }).finally(()=>{setLoad(false)})
      
    }
  };

  return (
    <div className="flex justify-center items-center  bg-white w-full">
      <div
        className="border rounded-md p-5 md:w-[80%] lg:w-[35%] bg-white dark:bg-black"
        style={{ boxShadow: "0px 2px 13px 5px #0000001a" }}
      >
        <div
          className="flex items-center lightBlack cursor-pointer dark:text-white"
          onClick={() => navigate(AppPath.login)}
        >
          <div className="textGray">
            <KeyboardBackspaceIcon />
          </div>
          <div className="pl-1 ">{t("back")}</div>
        </div>

        <div className="text-center text-3xl font-semibold text-[#333333] pt-4 pb-7 dark:text-white">
          {t("reset_password")}
        </div>

        <div className="text-[#828282] text-base dark:text-white">
          {t("new_password")}
        </div>

        <div className="pt-4 pb-2">
          <TextFieldForm
            name="password"
            type="password"
            value={input.password}
            label={t("password")}
            onChange={handleChange}
            autoComplete="false"
          />
        </div>
        {error.password && (
          <div className="text-sm text-red-600 font-medium">
            {error.password}
          </div>
        )}

        <div className="pt-4 pb-2">
          <TextFieldForm
            name="confirmPassword"
            type="password"
            label={t("confirm_password")}
            value={input.confirmPassword}
            onChange={handleChange}
            autoComplete="false"
          />
        </div>
        {error.confirmPassword && (
          <div className="text-sm text-red-600 font-medium">
            {error.confirmPassword}
          </div>
        )}

        <div
          className="mt-4"
          onClick={() => {
            handleSubmit();
          }}
        >
          <Buttons>
            <span>{load?<CircularProgress style={{width:'25px' ,height:'25px'}}/>:t("reset")}</span>
          </Buttons>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
