import { EventTypeEnum } from "../types/enum";

export const calendarColors = {
  light: {
    callReminder: "#F4F9FD",
    appointment: "#FFF9F9",
    reminder: "#F2F8F8",
  },
  dark: {
    reminder: "#135D66",
    appointment: "#ff8183",
    callReminder: "#34ccff",
    assignment: "#115ABB",
    schedule: "#DAD3BE",
    record: "#e46f54",
  },
};

export const getCalendarColor = (type: string, colorType: "dark" | "light") => {
  const ev = calendarColors[colorType];

  switch (type) {
    case EventTypeEnum.REMINDER:
      return ev.reminder;
    case EventTypeEnum.APPOINTMENT:
      return ev.appointment;
    default:
      return ev.callReminder;
  }
};
