import * as yup from "yup";
import generateShortUUID from "../../../utils/generateShortUUID";

export const defaultValues = {
  polls: [
    {
      _id: generateShortUUID(),
      title: "",
      isMultiAnswerAllow: false,
      options: [
        { _id: generateShortUUID(), name: "", value: [] },
        { _id: generateShortUUID(), name: "", value: [] },
      ],
    },
  ],
};

export const pollSchema = yup.object().shape({
  polls: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .max(300, "titlemaxlength")
        .required("titleisrequired"),
      options: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .max(100, "optionmaxlength")
            .required("optionrequired"),
        })
      ),
    })
  ),
});
