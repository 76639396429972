import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../Assets/Images/ChatMsgPoper/delete.png";
import createIcon from "../../Assets/Images/EditGroupIcon.png";
import FileIcon from "../../Assets/Images/FileIcon.png";
import Media from "../../Assets/Images/Media.png";
import PdfFileIcon from "../../Assets/Images/PdfIcon.png";
import PptFileIcon from "../../Assets/Images/PptFileIcon.png";
import TextFileIcon from "../../Assets/Images/TextFileIcon.png";
import DocFileIcon from "../../Assets/Images/WordFileIcon.png";
import XlsxFileIcon from "../../Assets/Images/XlsxFileIcon.png";
import ZipFileIcon from "../../Assets/Images/ZipFileIcon.png";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import {
  CREATE_TOPIC,
  DELETE_MSG_FROM_TOPIC,
  DELETE_TOPIC,
  UPDATE_TOPIC,
} from "../../Graphql/Mutations";
import {
  GET_CHATS_BY_INDEX_ID,
  GET_MY_TOPICS,
  GET_TOPIC_BY_ID,
} from "../../Graphql/Queries";
import { authCllient, refreshTokenCllient } from "../../Graphql/authClient";
import {
  appendOldChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomData,
  setActiveRoomsChats,
  setCurrentPage,
  setFocusedData,
} from "../../Redux/ChatSlice";
import { setTopicsData } from "../../Redux/UserSlice";
import { emit, socketListen } from "../../Socket";
import {
  ActiveRoomChatsType,
  MessagesActionDataTypes,
  NoneInitiatedRoomsType,
  RoomsDataType,
} from "../../Types/Types";
import Toast from "../../coreComponent/Toast";
import { getSession } from "../../utils/session";
import getFileExtension from "../../utils/getFileExtension";
export default function Headings({ setHeadingShow }) {
  const { t } = useTranslation();
  const {
    activeRoomData,
    searchChatsResult,
    formattedParticipantsOfActiveRoom,
    activeRoomChats,
    activeChatsLimit,
    rooms,
  } = useSelector((state: any) => state.chat);
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const [roomsUpdated, setRoomsUpdated] = useState([]);
  const [showHeadingModal, setShowHeadingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [headingError, setHeadingError] = useState("");
  const [deletedHeading, setDeletedHeading] = useState("");
  const [newHeading, setNewHeading] = useState("");
  const [confirmModal, setShowConfirmModal] = useState(false);
  const { userData, formattedContact, topicsData } = useSelector(
    (state: any) => state.user
  );
  const [showMessageActions, setShowMessageActions] = useState({
    status: false,
    id: "",
  });
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [navHis, setNavHis] = useState([]);
  const [load, setLoad] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [messages, setMessages] = useState<any>([]);
  const [forwardMessages, setForwardMessages] = useState<any>({
    messages: [],
    rooms: [],
  });
  const [selectedHeading, setSelectedHeading] = useState("");
  const [createLoading, setCreateLoad] = useState(false);
  const [forwardModalShow, setForwardModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [subTopics, setSubTopics] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const MessageAction = MessageActions(t);
  const userInfo =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        )
      : activeRoomData?.participants?.length === 1
      ? activeRoomData?.participants[0]
      : null;

  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userData?._id
    );
  const isUserBlockedYou =
    activeRoomData?.type !== "group" && userInfo?.left_at !== 0 ? true : false;
  const isRoomLeft = userProfileImg?.left_at !== 0;

  const getSelectedHeadingMessage = (heading: any) => {
    setLoad(true);
    const { token } = getSession();
    refreshTokenCllient(token)
      .query({
        query: GET_TOPIC_BY_ID,
        variables: {
          input: {
            _id: heading?._id,
          },
        },
      })
      .then((res: any) => {
        setSubTopics(res?.data?.getTopicById?.subTopics);
        setMessages(res?.data?.getTopicById?.chats);
        setLoad(false);
      });
  };
  const getMessage: any = (item) => {
    let message: any;
    const senderContactDetails = formattedContact[item?.sender];
    const senderInfo = formattedParticipantsOfActiveRoom[item?.sender];

    if (item?.type === "createdRoom") {
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails
              ? senderContactDetails?.firstName + senderContactDetails?.lastName
              : senderInfo?.phone}
          </span>
          <span className="ml-1">
            {item?.message
              .replace("created group", t("created-group"))
              .replace(/\s+/g, " ")
              .trim()}
          </span>
        </div>
      );
    } else if (item?.type === "addedUser" || item?.type === "removedUser") {
      const jsonMsg = JSON.parse(item?.message);
      const userContactDetails = formattedContact[jsonMsg?.pid];
      const userInfo = formattedParticipantsOfActiveRoom[jsonMsg?.pid];

      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails
              ? senderContactDetails?.firstName + senderContactDetails?.lastName
              : senderInfo?.phone}
          </span>
          <span className="">
            {" "}
            {jsonMsg?.msg
              .replace("added", t("added"))
              .replace("removed", t("removed"))
              .replace(/\s+/g, " ")
              .trim()}{" "}
          </span>
          <span className="">
            {jsonMsg?.pid === userData?._id
              ? t("you")
              : userContactDetails
              ? userContactDetails?.firstName + userContactDetails?.lastName
              : userInfo?.phone}
          </span>
        </div>
      );
    } else if (
      item?.type === "changedDescription" ||
      item?.type === "changedName" ||
      item?.type === "changedPicture" ||
      item?.type === "leftRoom" ||
      item?.type === "chatDisappear"
    ) {
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails
              ? senderContactDetails?.firstName + senderContactDetails?.lastName
              : senderInfo?.phone}
          </span>
          <span className="">
            {" "}
            {item?.message
              .replace("changed the name of group", t("name-change-group"))
              .replace(
                "turned off disappearing messages",
                t("turn-off-disapper")
              )
              .replace(
                "changed the description of group",
                t("description-change-group")
              )
              .replace(
                "changed the picture of the group",
                t("picture-change-group")
              )
              .replace(
                "turned on disappearing messages. All new messages will disappear from this chat 7 days after they're send.",
                t("chat-disapper")
              )
              .replace("left the group", t("left-group"))
              .replace(/\s+/g, " ")
              .trim()}{" "}
          </span>
        </div>
      );
    } else if (item?.type === "audioCall" || item?.type === "videoCall") {
      message = (
        <div>
          <span>
            {senderInfo?.user_id === userData?._id
              ? t("you")
              : senderContactDetails
              ? senderContactDetails?.firstName + senderContactDetails?.lastName
              : senderInfo?.phone}
          </span>
          <span> {t("started")} </span>
          <span className="">
            {item?.type === "audioCall" ? t("audio_call") : t("video_call")}{" "}
          </span>
        </div>
      );
    } else if (item.type === "invited") {
      const finalmsg = JSON.parse(item.message);
      const first =
        formattedContact[userInfo?.user_id]?.firstName ?? userInfo?.phone;
      const last_name =
        formattedContact[userInfo?.user_id]?.lastName !== undefined
          ? formattedContact[userInfo?.user_id]?.lastName
          : " ";
      message = (
        <div>
          <span className="ml-1">
            <span className="ml-1">
              {item?.sender === userData?._id ? (
                <>
                  <span>
                    {t("accept-1") +
                      " " +
                      first +
                      " " +
                      last_name +
                      " " +
                      t("accept-2") +
                      " "}
                  </span>
                  <b>{" " + finalmsg?.orgName}</b>
                </>
              ) : (
                <>
                  <span>
                    {" "}
                    {first + " " + last_name + " " + t("accept-3") + " "}
                  </span>
                  <b>{" " + finalmsg?.orgName}</b>
                </>
              )}
            </span>
          </span>
        </div>
      );
    } else if (item.type === "declined") {
      const finalmsg = JSON.parse(item.message);
      const last_name =
        formattedContact[userInfo?.user_id]?.lastName !== undefined
          ? formattedContact[userInfo?.user_id]?.lastName
          : " ";
      message = (
        <div>
          <div>
            <span className="ml-1">
              {item?.sender === userData?._id ? (
                <>
                  <span>{t("you") + " " + t("msg-1") + " "}</span>
                  <b>{finalmsg?.orgName + " "}</b>
                  <span>{" " + t("msg-2") + " "}</span>
                  <span>
                    <b>{moment(item.created_at).format("MMM Do YY")}</b>
                  </span>
                  <span>{" " + t("msg-3") + " "}</span>
                  <span>
                    {" "}
                    <b>{moment(item.created_at).format("HH:mm") + " "}</b>
                  </span>
                  <span> {t("msg-4")}</span>
                </>
              ) : (
                <>
                  <span>
                    <b>
                      {formattedContact[userInfo?.user_id]?.firstName ??
                        userInfo?.phone + " " + last_name + " "}
                    </b>
                  </span>
                  <span>{" " + t("msg-5") + " "}</span>
                  <span>
                    <b>{finalmsg?.orgName + " "}</b>
                  </span>
                  <span>{t("msg-2") + " "}</span>
                  <span>
                    <b>{moment(item.created_at).format("MMM Do YY") + " "}</b>
                  </span>
                  <span>{" " + t("msg-3") + " "}</span>
                  <span>
                    <b>{moment(item.created_at).format("HH:mm") + " "}</b>
                  </span>
                  <span>{t("msg-6")}</span>
                </>
              )}
            </span>
          </div>
        </div>
      );
    } else if (item.type === "taskAssigned") {
      const finalmsg = JSON.parse(item.message);
      const last_name =
        formattedContact[userInfo?.user_id]?.lastName !== undefined
          ? formattedContact[userInfo?.user_id]?.lastName
          : " ";
      message = (
        <div>
          <div>
            <span className="ml-1">
              {item?.sender === userData?._id ? (
                <>
                  <span>{t("task-assigned") + " "}</span>
                  <b>{finalmsg?.scenarioName + " "}</b>
                  <span>{" " + t("to") + " "}</span>
                  <span>
                    <b>
                      {formattedContact[userInfo?.user_id]?.firstName ??
                        userInfo?.phone + " " + last_name}
                    </b>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <b>
                      {formattedContact[userInfo?.user_id]?.firstName ??
                        userInfo?.phone + " " + last_name + " "}
                    </b>
                  </span>
                  <span>{" " + t("assigned-you") + " "}</span>
                  <span>
                    <b>{finalmsg?.scenarioName + " "}</b>
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
      );
    }
    return message;
  };

  useEffect(() => {
    if (rooms.length > 0) {
      let temp: any = [];
      rooms?.forEach((item: any) => {
        const element = { ...item };
        const userInfo = element?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        );
        const participantContactDetail = formattedContact[userInfo?.user_id];
        if (element.type === "individual") {
          element["name"] = formattedContact[userInfo?.user_id]?.firstName
            ? participantContactDetail?.firstName.toLowerCase() +
              " " +
              participantContactDetail?.lastName.toLowerCase()
            : `${userInfo?.phone}`;
        }
        temp.push(element);
      });
      setRoomsUpdated(temp);
    }
  }, [rooms]);

  const FormattedText: any = (message: any) => {
    const regex = /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|(https?:\/\/[^\s]+)/g;

    const formattedText = message?.replace(
      regex,
      (match: any, p1: any, p2: any, p3: any, p4: any, p5: any) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        } else if (p5) {
          return (
            `<a href=${p5} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px">` +
            p5 +
            "</a>"
          );
        }

        return match;
      }
    );
    return formattedText;
  };
  const handleRedirectOnMessage = ({ message, roomId }: any) => {
    const totalChats = activeRoomData.totalChats;

    const skip = totalChats - message?.index - 25;

    const value = skip < 0 ? 0 : skip;
    dispatch(
      setActiveRoomData(rooms.find((el: any) => el?._id === roomId?._id))
    );
    if (
      message?.index < activeRoomChats[activeRoomChats?.length - 1]?.index &&
      message?.index >= activeRoomChats[0]?.index
    ) {
      dispatch(setFocusedData({ status: true, message_id: message?.index }));
      setHeadingShow(false);
      document
        .getElementById(`message-index-${message?.index}`)
        .scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      document.getElementById(
        `message-index-${message?.index}`
      ).style.backgroundColor = "#87d3e2f5";
      document.getElementById(
        `message-index-${message?.index}`
      ).style.paddingBottom = "10px";
      document.getElementById(
        `message-index-${message?.index}`
      ).style.transition = "background-color " + 2 + "s ease-in-out";

      setTimeout(() => {
        document.getElementById(
          `message-index-${message?.index}`
        ).style.backgroundColor = "";
        document.getElementById(
          `message-index-${message?.index}`
        ).style.paddingBottom = "0px";
        document.getElementById(
          `message-index-${message?.index}`
        ).style.transition = "none";
      }, 16000);
    } else {
      dispatch(setFocusedData({ status: true, message_id: message?.index }));
      const skip =
        activeChatsLimit * (Math.floor(message.index / activeChatsLimit) + 1);
      dispatch(setActiveChatSkip(skip));
      dispatch(
        setCurrentPage(Math.floor(message.index / activeChatsLimit) + 1)
      );
      //dispatch(setTotalPage(Math.ceil(activeRoomData?.totalChats / activeChatsLimit)))
      const { token } = getSession();
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_INDEX_ID,
          variables: {
            input: {
              roomId: roomId?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index: message?.index,
              limit: activeChatsLimit, // custom limit
            },
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          dispatch(appendOldChats([]));
          dispatch(setActiveRoomChats([]));
          if (res.data.getChatsByIndex.length > 0) {
            dispatch(setActiveRoomsChats(res?.data?.getChatsByIndex));
          }
          console.log(
            message?.index,
            document.getElementById(`message-index-${message?.index}`),
            " document.getElementById(`message-index-${message?.index}`)"
          );
          setTimeout(() => {
            if (
              message.index !== null &&
              document.getElementById(`message-index-${message?.index}`)
            ) {
              console.log(message, "message*************");
              /* document
                .getElementById(`message-index-${message?.index}`)
                .scrollIntoView({ block: "center", behavior: "auto" }) */
              document
                .getElementById(`message-index-${message?.index}`)
                .scrollIntoView({ block: "center", behavior: "smooth" });
              document.getElementById(
                `message-index-${message?.index}`
              ).style.backgroundColor = "#e0e0e0";
              document.getElementById(
                `message-index-${message?.index}`
              ).style.paddingBottom = "10px";
              document.getElementById(
                `message-index-${message?.index}`
              ).style.transition = "background-color " + 2 + "s ease-in-out";

              setTimeout(() => {
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.backgroundColor = "";
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.paddingBottom = "0px";
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.transition = "none";
                //dispatch(setFocusedData({ status: false, __v:null }))
              }, 28000);
            }
          }, 5000);
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        });

      // setHeadingShow(false)

      /*  dispatch(appendOldChats([]));
       dispatch(setActiveRoomChats([])); */
    }
  };

  const finalForwad = async () => {
    let roomIds: any = [];

    for (let one of forwardMessages?.rooms) {
      await new Promise(async (resolve: any) => {
        if (one.roomId) {
          roomIds.push(one.roomId);
          resolve();
        } else {
          emit("createRoom", { users: [one?.userId], type: "individual" });
          await new Promise((reso: any) => {
            socketListen("createRoom", (data: any) => {
              roomIds.push(data?.msg?.roomId);
              reso();
            });
          });
        }
        resolve();
      });
    }

    emit("forwardChat", {
      messages: forwardMessages?.messages,
      rooms: roomIds,
    });
    setForwardModalShow(false);
    setForwardMessages({ messages: [], rooms: [] });
  };

  const { token } = getSession();
  const getTopicsData = () => {
    refreshTokenCllient(token)
      .query({
        query: GET_MY_TOPICS,
      })
      .then((res: any) => {
        dispatch(setTopicsData(res?.data?.getMyTopics));
      });
  };

  const sortedArray = [...messages].sort(
    (a, b) =>
      new Date(Number(b.created_at)).getDate() -
      new Date(Number(a.created_at)).getDate()
  );
  const updateHeading = async () => {
    setLoading(true);
    await authCllient
      .mutate({
        mutation: DELETE_TOPIC,
        variables: { input: { _id: deleteId } },
      })
      .then((res) => {
        if (navHis.length === 0) {
          getTopicsData();
        } else {
          getSelectedHeadingMessage(navHis[navHis.length - 1]);
        }
        setDeleteId("");
      })
      .catch((err) => console.log(`Error occured at Accept Invitation ${err}`))
      .finally(() => {
        setShowConfirmModal(false);
        setLoading(false);
      });
  };
  const nonInitiatedRooms = () => {
    const comonUsers: any = Object.values(formattedContact);
    const individualRoom: any = rooms?.filter(
      (one: any) => one?.type === "individual"
    );

    let result = [];

    for (const user of comonUsers) {
      const isExist = individualRoom.find((one: any) => {
        const existInparticipant = one.participants.find(
          (p: any) => p.user_id === user?.userId?._id
        );
        return existInparticipant ? true : false;
      });
      if (!isExist) {
        result.push(user);
      }
    }
    return result;
  };

  const handleMessageAction = async (action: string, item: any) => {
    setShowMessageActions({ status: false, id: "" });
    setAnchorEl(null);
    if (action === "forward") {
      setForwardModalShow(true);
      handleMessageSelect(true, item);
      setShowCheckbox(true);
    }
    if (action === "copy") {
      navigator.clipboard.writeText(item);

      setToastShow(true);
      setToastMessage(t("copy_success"));
    }
    if (action === "delete") {
      await authCllient
        .mutate({
          mutation: DELETE_MSG_FROM_TOPIC,
          variables: {
            input: {
              topicId:
                navHis.length === 0 ? null : navHis[navHis?.length - 1]?._id,
              chatIds: [item?.chatId],
            },
          },
        })
        .then((res) => {
          if (navHis.length === 0) {
            getTopicsData();
          } else {
            getSelectedHeadingMessage(navHis[navHis.length - 1]);
          }
          setAnchorEl(null);
        })
        .catch((err) =>
          console.log(`Error occured at Accept Invitation ${err}`)
        );
    }
  };
  const handleMessageSelect = (e: any, item: any) => {
    let temp = [...forwardMessages?.messages];

    if (e) {
      temp.push(item?.chatId);
    } else {
      temp = temp.filter((elem: any) => elem !== item?.chatId);
    }

    setForwardMessages({ ...forwardMessages, messages: temp });
  };

  const handleSelectForwarded = (e: any, roomId: string, userId: any) => {
    if (e.target.checked) {
      setForwardMessages({
        ...forwardMessages,
        rooms: [...forwardMessages?.rooms, { roomId, userId }],
      });
    } else {
      const temp = forwardMessages?.rooms?.filter((one: any) => {
        if (roomId) {
          return roomId !== one.roomId;
        } else {
          return userId !== one.userId;
        }
      });
      setForwardMessages(temp);
    }
  };

  const createHeading = async () => {
    setCreateLoad(true);
    if (newHeading === "") {
      setHeadingError(t("heading-error"));
    } else if (topicsData.filter((el: any) => el === newHeading).length > 0) {
      setHeadingError(t("already-exist-heading-error"));
    } else {
      if (newHeading !== "" && selectedId !== "") {
        await authCllient
          .mutate({
            mutation: UPDATE_TOPIC,
            variables: {
              input: {
                name: newHeading,
                _id: selectedId,
              },
            },
          })
          .then((res) => {
            if (navHis.length === 0) {
              getTopicsData();
            } else {
              getSelectedHeadingMessage(navHis[navHis.length - 1]);
            }
            setSelectedId("");
          })
          .catch((err) =>
            console.log(`Error occured at Accept Invitation ${err}`)
          )
          .finally(() => {
            setNewHeading("");
            setCreateLoad(false);
            setHeadingError("");
            setShowHeadingModal(false);
          });
      } else {
        await authCllient
          .mutate({
            mutation: CREATE_TOPIC,
            variables: {
              input: {
                name: newHeading,
                parent:
                  navHis.length === 0 ? null : navHis[navHis.length - 1]?._id,
              },
            },
          })
          .then((res) => {
            if (navHis.length === 0) {
              getTopicsData();
            } else {
              getSelectedHeadingMessage(navHis[navHis.length - 1]);
            }
          })
          .catch((err) =>
            console.log(`Error occured at Accept Invitation ${err}`)
          )
          .finally(() => {
            setNewHeading("");
            setCreateLoad(false);
            setHeadingError("");
            setShowHeadingModal(false);
          });
      }
    }
  };

  return (
    <Box sx={{ width: 400 }} role="presentation" className="relative">
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      <div>
        <Dialog
          open={forwardModalShow}
          onClose={() => setForwardModalShow(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            className="bg-[#E0FAFF] w-[600px]"
            id="alert-dialog-title"
          >
            {t("forward_messages")}
          </DialogTitle>
          <DialogContent>
            <div
              className="my-3 rounded flex border mb-4 items-center px-2 bg-white"
              style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
            >
              <SearchIcon sx={{ color: "#333333" }} />
              <input
                type="text"
                placeholder={t("searchPlaceholder")}
                className="w-full py-2 px-2 focus:outline-none text-sm"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {roomsUpdated
              .sort((a: any, b: any) => {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .filter((el: any) => {
                if (
                  el?.name
                    ?.toLowerCase()
                    ?.includes(searchQuery?.toLowerCase())
                ) {
                  return el;
                }
              })
              // .filter(
              //   (el: any) =>
              //     el?.name
              //       ?.split(" ")[0]
              //       ?.startsWith(searchQuery.toLowerCase()) ||
              //     el?.name?.split(" ")[1]?.startsWith(searchQuery.toLowerCase())
              // )
              ?.map((item: RoomsDataType, index: number) => {
                const userInfo = item?.participants.find(
                  (one: any) => one?.user_id !== userData?._id
                );

                const contactDetails = formattedContact[userInfo?.user_id];

                const isRoomBlocked = userData?.blockedRooms?.some(
                  (elem: any) => (elem?.room_Id === item?._id ? true : false)
                );

                if (item?.type === "broadcast") {
                  return null;
                }

                return (
                  <div className="flex items-center gap-5 my-2" key={index}>
                    <div>
                      <input
                        type="checkbox"
                        className="h-[17px] w-[17px] cursor-pointer"
                        disabled={isRoomBlocked ? true : false}
                        onChange={(e: any) =>
                          handleSelectForwarded(e, item._id, "")
                        }
                      />
                    </div>
                    <div className="flex gap-3">
                      {item?.type === "group" ? (
                        <div>
                          {item?.type === "group" &&
                          item?.profile_img !== "" ? (
                            <img
                              src={DefaultImageUrl + item?.profile_img}
                              alt="profile"
                              className="h-[40px] w-[40px] rounded-full"
                            />
                          ) : (
                            <Avatar sx={{ height: "40px", width: "40px" }} />
                          )}
                        </div>
                      ) : (
                        <div>
                          {item?.type === "individual" &&
                          userInfo?.profile_img !== "" &&
                          userInfo?.left_at === 0 &&
                          !isRoomBlocked ? (
                            <img
                              src={DefaultImageUrl + userInfo?.profile_img}
                              alt="profile"
                              className="h-[40px] w-[40px] rounded-full"
                            />
                          ) : (
                            <Avatar sx={{ height: "40px", width: "40px" }} />
                          )}
                        </div>
                      )}
                      <div>
                        <div>
                          {item?.type === "group"
                            ? item?.name
                            : item?.participants?.length === 1
                            ? t("you_me")
                            : contactDetails && contactDetails?.firstName !== ""
                            ? contactDetails?.firstName +
                              " " +
                              contactDetails?.lastName
                            : userInfo?.phone}
                        </div>
                        {!isUserBlockedYou &&
                          !isRoomBlocked &&
                          !isUserBlockedYou && (
                            <div className="textGray text-sm">
                              {contactDetails?.userId?.bio?.status}
                            </div>
                          )}

                        {isRoomBlocked && (
                          <div className="textGray">
                            {t("blocked_user_msg")}
                          </div>
                        )}

                        {/* {isUserBlockedYou && (
                          <div className="textGray">
                            {t("blocked_user_msg_two")}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                );
              })}

            {nonInitiatedRooms()
              ?.map((el: any) => ({
                ...el,
                name:
                  el?.firstName !== ""
                    ? el.firstName?.toLowerCase() +
                      " " +
                      el?.lastName.toLowerCase()
                    : `${el?.userId?.phone.replace("+", "")}`,
              }))
              .sort((a: any, b: any) => {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .filter(
                (el: any) =>
                  el?.name
                    .split(" ")[0]
                    ?.startsWith(searchQuery.toLowerCase()) ||
                  el?.name.split(" ")[1]?.startsWith(searchQuery.toLowerCase())
              )?.length > 0 && <div className="textGray">{t("others")}</div>}

            {nonInitiatedRooms()
              ?.map((el: any) => ({
                ...el,
                name:
                  el?.firstName !== ""
                    ? el.firstName?.toLowerCase() +
                      " " +
                      el?.lastName.toLowerCase()
                    : `${el?.userId?.phone.replace("+", "")}`,
              }))
              .sort((a: any, b: any) => {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .filter(
                (el: any) =>
                  el?.name
                    .split(" ")[0]
                    ?.startsWith(searchQuery.toLowerCase()) ||
                  el?.name.split(" ")[1]?.startsWith(searchQuery.toLowerCase())
              )
              ?.map((item: NoneInitiatedRoomsType, index: number) => {
                return (
                  <div className="flex items-center gap-5 my-2" key={index}>
                    <div>
                      <input
                        type="checkbox"
                        className="h-[17px] w-[17px] cursor-pointer"
                        onChange={(e: any) =>
                          handleSelectForwarded(e, "", item.userId?._id)
                        }
                      />
                    </div>
                    <div>
                      {item?.userId?.profile_img !== "" ? (
                        <img
                          src={DefaultImageUrl + item?.userId?.profile_img}
                          alt="profile"
                          className="h-[40px] w-[40px] rounded-full"
                        />
                      ) : (
                        <Avatar sx={{ height: "40px", width: "40px" }} />
                      )}
                    </div>
                    <div>
                      {item?.firstName !== "" ? (
                        <div>{item?.firstName + " " + item?.lastName}</div>
                      ) : (
                        <div>{item?.userId?.phone}</div>
                      )}
                      <div className="textGray">
                        {item?.userId?.bio?.status}
                      </div>
                    </div>
                  </div>
                );
              })}
          </DialogContent>
          <DialogActions>
            <button
              className="bg-gray-200 p-2 rounded-md"
              onClick={() => {
                setForwardMessages({ messages: [], rooms: [] });
                setForwardModalShow(false);
              }}
            >
              {t("cancel")}
            </button>
            <button
              disabled={forwardMessages?.rooms?.length === 0 ? true : false}
              className="bg-primary p-2 rounded-md text-white disabled:opacity-25"
              onClick={finalForwad}
            >
              {t("send")}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showHeadingModal}
          onClose={() => {
            setShowHeadingModal(false);
            setHeadingError("");
            setSelectedId("");
            setNewHeading("");
          }}
          aria-labelledby="responsive-dialog-title"
          maxWidth="sm"
          fullWidth
          onKeyUp={(e: any) => {
            const ENTER = 13;
            console.log(e.keyCode);

            if (e.keyCode === ENTER) {
              if (!createLoading) {
                createHeading();
              }
            }
          }}
        >
          <DialogTitle className=" pt-5 pb-4 bg-[#E0FAFF]">
            {selectedId ? t("update-heading") : t("create-heading")}
          </DialogTitle>
          <DialogContent sx={{ padding: "0 !important" }}>
            <DialogContentText>
              <div className="my-4 px-2 text-center w-full block">
                <TextField
                  type="text"
                  name="selected_heading"
                  placeholder={t("heading")}
                  value={newHeading}
                  className={`mr-2 cursor-pointer rounded-md w-[75%] px-4 py-2 border ${
                    headingError !== "" ? "border-red-500" : ""
                  } `}
                  onChange={(e: any) => {
                    setHeadingError("");
                    setNewHeading(e.target.value);
                  }}
                />
                <div className="text-red-500 mt-1 text-sm">
                  {headingError !== ""
                    ? headingError.includes("exist")
                      ? `${newHeading} ${headingError}`
                      : headingError
                    : ""}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="text-center">
            <div
              className="bg-primary text-white cursor-pointer rounded-md p-3 py-1"
              onClick={() => {
                setHeadingError("");
                setSelectedId("");
                setNewHeading("");
                setShowHeadingModal(false);
              }}
            >
              {t("cancel")}
            </div>
            {newHeading?.trim()?.length > 0 && (
              <div
              className="bg-primary text-white cursor-pointer rounded-md p-3 py-1"
              onClick={() => {
                if (!createLoading) {
                  createHeading();
                }
              }}
            >
              {createLoading ? (
                <CircularProgress
                  style={{ height: 16, width: 16, color: "white" }}
                />
              ) : (
                t("add")
              )}
            </div>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmModal}
          keepMounted
          maxWidth={"sm"}
          fullWidth
          onClose={() => {}}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            className="bg-[#E0FAFF] "
            id="alert-dialog-slide-description"
          >
            {t("delete-heading")}
          </DialogTitle>
          <DialogContent>
            <div className="mt-8 font-normal text-md">
              {t("delete-heading-message")}
            </div>
          </DialogContent>
          <DialogActions>
            <div
              className="bg-[#33ccff] cursor-pointer rounded-md text-white px-4 py-2"
              onClick={() => {
                setShowConfirmModal(false);
              }}
            >
              {t("cancel")}
            </div>
            <div
              className="text-white cursor-pointer rounded-md px-4 py-2 bg-red-500"
              onClick={() => {
                if (!loading) {
                  updateHeading();
                }
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{ height: 16, width: 16, color: "white" }}
                />
              ) : (
                t("delete")
              )}
            </div>
          </DialogActions>
        </Dialog>
        <div className="flex w-full justify-between items-center py-2 border-b px-2 h-[60px] sticky top-0 bg-[#F3F9FC]">
          <div
            className="flex  items-center cursor-pointer"
            onClick={() => {
              const navItem = navHis.slice(0, navHis.length - 1);
              if (selectedHeading === "") {
                setHeadingShow(false);
              }
              if (navItem.length === 0) {
                setNavHis([]);
                setSubTopics([]);
                setMessages([]);
                setSelectedHeading("");
                getTopicsData();
              } else {
                if (navItem.length === 1) {
                  setNavHis([]);
                  setSubTopics([]);
                  setSelectedHeading(navItem[navItem.length - 1]?.name);
                  getSelectedHeadingMessage(navItem[navItem.length - 1]);
                } else {
                  setSubTopics([]);
                  setNavHis(navItem);
                  setSelectedHeading(navItem[navItem.length - 1]?.name);
                  getSelectedHeadingMessage(navItem[navItem.length - 1]);
                }
              }
            }}
          >
            <div className="flex">
              <div>
                <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
              </div>
            </div>

            {selectedHeading === "" ? (
              <div className="flex justify-center font-bold text-lg ">
                {t("headings")}
              </div>
            ) : (
              <div className=" font-bold capitalize">{selectedHeading}</div>
            )}
          </div>
        </div>
        <div className="h-[82vh] overflow-y-scroll">
          {selectedHeading === "" ? (
            <div>
              {/*  <div className="flex justify-center font-bold text-lg mt-2" >{t('headings')}</div>
                    <hr className="mt-3" /> */}
              <div className="px-2 py-3">
                {topicsData?.length === 0 ? (
                  <div className="text-center mx-auto flex my-4 justify-center">
                    {t("no_headings")}
                  </div>
                ) : (
                  topicsData?.map((item: any) => {
                    return (
                      <div className="hover:bg-gray-100">
                        <div className="flex gap-2 py-2 items-center ">
                          <div
                            onClick={() => {
                              setNavHis([...navHis, { ...item }]);
                              setSelectedHeading(item?.name);
                              getSelectedHeadingMessage(item);
                            }}
                            className=" flex items-center w-full    px-4 capitalize cursor-pointer "
                          >
                            <div className="">{item?.name}</div>
                          </div>
                          <img
                            src={createIcon}
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                              setSelectedId(item._id);
                              setShowHeadingModal(true);
                              setHeadingError("");
                              setNewHeading(item?.name);
                            }}
                            style={{ color: "red" }}
                          />
                          <img
                            src={deleteIcon}
                            className="w-4 h-4 mx-2 cursor-pointer"
                            onClick={() => {
                              setDeleteId(item?._id);
                              setDeletedHeading(item);
                              setShowConfirmModal(true);
                            }}
                            style={{ color: "red" }}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          ) : (
            <div>
              {subTopics.length > 0 && (
                <div className="px-4 my-4">
                  {subTopics.map((el: any) => {
                    return (
                      <div className="cursor-pointer my-3">
                        <div className="flex justify-between w-full">
                          <div
                            className="mb-2"
                            onClick={() => {
                              setSelectedHeading(el.name);
                              setSubTopics([]);
                              setNavHis([...navHis, { ...el }]);
                              getSelectedHeadingMessage(el);
                            }}
                          >
                            {el?.name}
                          </div>
                          <div className="flex gap-2">
                            <img
                              src={createIcon}
                              className="w-4 h-4 mt-2 cursor-pointer"
                              onClick={() => {
                                setSelectedId(el._id);
                                setShowHeadingModal(true);
                                setHeadingError("");
                                setNewHeading(el?.name);
                              }}
                              style={{ color: "red" }}
                            />
                            <img
                              src={deleteIcon}
                              className="w-4 h-4 mt-2 cursor-pointer"
                              onClick={() => {
                                setDeleteId(el?._id);
                                setDeletedHeading(el);
                                setShowConfirmModal(true);
                              }}
                              style={{ color: "red" }}
                            />
                            <BsArrowRight className="mt-2 mr-2" />
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              )}
              {load ? (
                <div className="flex justify-center py-6">
                  <CircularProgress style={{ height: 18, width: 18 }} />
                </div>
              ) : messages.length === 0 ? (
                <div className="text-center flex mx-auto my-4 justify-center w-full">
                  {t("no_chats")}
                </div>
              ) : (
                <div className="px-2">
                  {sortedArray?.map((item: any, index: any) => {
                    const time = moment(item?.message?.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    );
                    return (
                      <div>
                        <div
                          key={index}
                          className={`mt-6 mb-1 flex gap-3  cursor-pointer `}
                        >
                          {" "}
                          <div className="h-[28px] w-[28px] min-w-[28px]">
                            {item?.sender?.profile_img ? (
                              <img
                                src={
                                  DefaultImageUrl + item?.sender?.profile_img
                                }
                                alt=""
                                className="h-full w-full rounded-full"
                              />
                            ) : (
                              <Avatar sx={{ height: 45, width: 45 }} />
                            )}
                          </div>
                          <div className="flex gap-1">
                            <div>
                              {`${
                                userData?._id === item?.sender?._id
                                  ? t("you")
                                  : item?.sender
                                  ? item?.sender?.firstName +
                                    " " +
                                    item?.sender?.lastName
                                  : "+" + item.sender?.phone
                              } >> ${
                                item?.roomId.type === "group"
                                  ? item?.roomId?.name
                                  : userData?._id === item?.toUser?._id
                                  ? t("you")
                                  : item?.toUser
                                  ? item?.toUser?.firstName +
                                    " " +
                                    item?.toUser?.lastName
                                  : "+" + item.toUser?.phone
                              }`}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div
                            // onClick={() => handleRedirectOnMessage(item)}
                            className={`w-[325px]  ml-8 ${
                              userData?._id === item?.sender?._id
                                ? "bg-[#E0FAFF]"
                                : "bg-[#F4F4F4]"
                            } py-4 px-3 rounded-t-xl rounded-bl-xl`}
                          >
                            <div
                              className="textGray"
                              id="heading-div"
                            >
                              {item?.message?.type === "IMAGE"
                                ? item?.message?.message !==
                                    "You deleted this message" &&
                                  item?.message?.message !==
                                    "This message was deleted" && (
                                    <img
                                      src={
                                        DefaultImageUrl + item?.message?.fileURL
                                      }
                                      alt="as"
                                      className="h-[200px] w-[200px] rounded-md object-cover cursor-pointer"
                                    />
                                  )
                                : item?.message?.type === "VIDEO"
                                ? item?.message?.message !==
                                    "You deleted this message" &&
                                  item?.message?.message !==
                                    "This message was deleted" && (
                                    <video
                                      controls
                                      src={
                                        DefaultImageUrl + item?.message?.fileURL
                                      }
                                      className="rounded-xl max-h-[20rem]"
                                    ></video>
                                  )
                                : item?.message?.type === "AUDIO"
                                ? item?.message?.message !==
                                    "You deleted this message" &&
                                  item?.message?.message !==
                                    "This message was deleted" && (
                                    <audio
                                      src={
                                        DefaultImageUrl + item?.message?.fileURL
                                      }
                                      controls
                                      onLoadedMetadata={() => console.log()}
                                    ></audio>
                                  )
                                : item?.message?.type === "TEXT" ||
                                  item?.message?.type === "PDF" ||
                                  item?.message?.type === "DOC" ||
                                  item?.message?.type === "DOCX" ||
                                  item?.message?.type === "ZIP" ||
                                  item?.message?.type === "XLS" ||
                                  item?.message?.type === "XLSX" ||
                                  item?.message?.type === "PPT" ||
                                  item?.message?.type === "PPTX" ||
                                  item?.message?.type === "APPLICATION" ||
                                  item?.message?.type === "DOCUMENT"
                                ? item?.message?.message !==
                                    "You deleted this message" &&
                                  item?.message?.message !==
                                    "This message was deleted" && (
                                    <div className="flex gap-2">
                                      <div className="h-[28px] min-w-[28px] w-[28px]">
                                        {item?.message?.fileURL.includes(
                                          ".pdf"
                                        ) && (
                                          <img
                                            src={PdfFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {item?.message?.fileURL.includes(
                                          ".text"
                                        ) && (
                                          <img
                                            src={TextFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {(getFileExtension(item?.fileURL) == "doc" ||
                                          getFileExtension(item?.fileURL) == "docx" ||
                                          getFileExtension(item?.fileURL) == "wav") && (
                                          <img
                                            src={DocFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {getFileExtension(item?.fileURL) == "zip" && (
                                          <img
                                            src={ZipFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {(getFileExtension(item?.fileURL) == "xls" ||
                                          getFileExtension(item?.fileURL) == "xlsx") && (
                                          <img
                                            src={XlsxFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {(getFileExtension(item?.fileURL) == "ppt" ||
                                          getFileExtension(item?.fileURL) == "pptx") && (
                                          <img
                                            src={PptFileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {(getFileExtension(item?.fileURL) == "jpg" ||
                                          getFileExtension(item?.fileURL) == "jpeg" ||
                                          getFileExtension(item?.fileURL) == "png") && (
                                          <img
                                            src={Media}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                        {getFileExtension(item?.fileURL) == "APPLICATION" && (
                                          <img
                                            src={FileIcon}
                                            alt="text"
                                            className="w-[35px] h-[35px]"
                                          />
                                        )}
                                      </div>
                                      <div
                                        className={`text-sm  flex items-center ${
                                          item?.message?.message !==
                                          "You deleted this message"
                                            ? ""
                                            : "textGray"
                                        }`}
                                      >
                                        {item?.message?.fileURL
                                          .split("/")
                                          .pop()}
                                      </div>
                                    </div>
                                  )
                                : ""}
                              <Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={[selectedHeading]}
                                autoEscape={true}
                                textToHighlight={
                                  item?.message?.type !== "text"
                                    ? getMessage(item?.message)
                                    : parse(
                                        FormattedText(item?.message?.message)
                                      )
                                }
                              />
                            </div>
                            <div className="textGray mt-1 ml-auto float-right justify-end text-xs">
                              {time}
                            </div>
                          </div>
                          <div
                            className=""
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setshowArrow({ status: true, id: item.chatId });
                            }}
                            ref={anchorRef}
                          >
                            <MoreVertIcon
                              className="cursor-pointer -pr-4 mt-2"
                              sx={{
                                visibility: "visible",
                                // : "hidden",
                                color: "#33CCFF",
                                fontSize: "1.8rem",
                              }}
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            />
                          </div>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={
                              showArrow.status && showArrow.id === item?.chatId
                                ? open
                                : false
                            }
                            onClose={() => {
                              setAnchorEl(null);
                              setshowArrow({ status: false, id: null });
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {MessageAction.filter(
                              (elem: MessagesActionDataTypes) =>
                                activeRoomData?.type === "individual"
                                  ? elem?.action !== "pin"
                                  : elem
                            ).map(
                              (el: MessagesActionDataTypes, index: number) => {
                                return el?.action === "pin" ||
                                  el?.action === "heading" ||
                                  el?.action === "info" ||
                                  el?.action === "favourite" ||
                                  el?.action === "unFavourite" ||
                                  el?.action === "reply" ? (
                                  ""
                                ) : [
                                    "TEXT",
                                    "PDF",
                                    "DOC",
                                    "DOCX",
                                    "ZIP",
                                    "XLS",
                                    "XLSX",
                                    "PPT",
                                    "PPTX",
                                    "APPLICATION",
                                    "DOCUMENT",
                                    "VIDEO",
                                    "IMAGE",
                                    "AUDIO",
                                  ].includes(item?.message?.type) ? (
                                  el?.action === "copy" ? (
                                    ""
                                  ) : (
                                    <MenuItem
                                      onClick={() => {
                                        if (el.action === "copy") {
                                          handleMessageAction(
                                            el.action,
                                            item?.message?.type !== "text"
                                              ? getMessage(item?.message)
                                              : parse(
                                                  FormattedText(
                                                    item?.message?.message
                                                  )
                                                )
                                          );
                                        } else {
                                          handleMessageAction(el.action, item);
                                        }
                                        setshowArrow({
                                          status: false,
                                          id: null,
                                        });
                                        setAnchorEl(null);
                                      }}
                                      key={index}
                                      sx={{ fontSize: "15px" }}
                                    >
                                      {el.ui}
                                    </MenuItem>
                                  )
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      if (el.action === "copy") {
                                        handleMessageAction(
                                          el.action,
                                          item?.message?.type !== "text"
                                            ? getMessage(item?.message)
                                            : parse(
                                                FormattedText(
                                                  item?.message?.message
                                                )
                                              )
                                        );
                                      } else {
                                        handleMessageAction(el.action, item);
                                      }
                                      setshowArrow({ status: false, id: null });
                                      setAnchorEl(null);
                                    }}
                                    key={index}
                                    sx={{ fontSize: "15px" }}
                                  >
                                    {el.ui}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Menu>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className=" w-full text-center pt-3 sticky bottom-0">
          <div className="px-3 -mb-2">
            <div
              onClick={() => {
                setShowHeadingModal(true);
                setHeadingError("");
              }}
              className="border cursor-pointer py-2 px-3 w-full  flex justify-center  border-[#33ccff] text-[#33ccff] text-md rounded-md"
            >
              {navHis.length === 0 ? t("create-heading") : t("add-sub-topic")}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
