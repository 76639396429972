import { useSelector } from "react-redux";
import ChatContainer from "./ChatContainer";
import ChatHeadContainer from "./ChatHeadContainer";
import ChatFooter from "./ChatFooter";
import { useState } from "react";

export default function ChatPage({ tabValue, setTabValue }: any) {
  const [replyMessage, setReplyMessage] = useState<any>("");
  const [isChangeMessageFooter, setIsChangeMessageFooter] = useState(false);
  const [showMessageCheckboxes, setShowMessageCheckboxes] = useState(false);
  const [replyFooterShow, setReplyFooterShow] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<any>({
    msgId: [],
    senderId: [],
    time: [],
  });
  const [messageArray, setMessageArray] = useState([]);
  const [showMediaPreview, setShowMediaPreview] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({ src: "", type: "" });
  const [mediaPreviewData, setMediaPreviewData] = useState<any>([]);

  return (
    <div className="relative h-full">
      <ChatHeadContainer
        tabValue={tabValue}
        setTabValue={setTabValue}
        showMediaPreview={showMediaPreview}
        setShowMediaPreview={setShowMediaPreview}
        selectedMedia={selectedMedia}
        setSelectedMedia={setSelectedMedia}
        mediaPreviewData={mediaPreviewData}
        setMediaPreviewData={setMediaPreviewData}
      />
      {tabValue !== 4 && (
        <>
          <ChatContainer
            messageArray={messageArray}
            setMessageArray={setMessageArray}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            mediaPreviewData={mediaPreviewData}
            setMediaPreviewData={setMediaPreviewData}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            setReplyFooterShow={setReplyFooterShow}
            setReplyMessage={setReplyMessage}
            setIsChangeMessageFooter={setIsChangeMessageFooter}
            setShowMessageCheckboxes={setShowMessageCheckboxes}
            showMessageCheckboxes={showMessageCheckboxes}
            showMediaPreview={showMediaPreview}
            setShowMediaPreview={setShowMediaPreview}
          />
          <ChatFooter
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            replyFooterShow={replyFooterShow}
            setReplyFooterShow={setReplyFooterShow}
            replyMessage={replyMessage}
            setReplyMessage={setReplyMessage}
            setIsChangeMessageFooter={setIsChangeMessageFooter}
            isChangeMessageFooter={isChangeMessageFooter}
            setShowMessageCheckboxes={setShowMessageCheckboxes}
          />
        </>
      )}{" "}
    </div>
  );
}
