
function groupContact(contacts: any[]) {
    const grouped = contacts?.reduce((acc: any, curr: any) => {
        const localIdPrefix = curr?.localId?.split("_")?.[0]
        const existingGroup = acc?.find(group => group?.localIdPrefix == localIdPrefix)

        if (existingGroup) {
            existingGroup?.groupedContacts?.push(curr)
        } else {
            acc?.push({ localIdPrefix, groupedContacts: [curr] })
        }

        return acc
    }, [])

    return grouped?.map(group => ({
        _id: group?.groupedContacts?.[0]?._id,
        firstName: group?.groupedContacts?.[0]?.firstName,
        lastName: group?.groupedContacts?.[0]?.lastName,
        phone: group?.groupedContacts?.[0]?.phone,
        localId: group?.groupedContacts?.[0]?.localId,
        profile_img: group?.groupedContacts?.[0]?.profile_img,
        groupedContact: group?.groupedContacts?.map(contact => {
            const { _id, blocked, lastSeen, status, ...rest } = contact

            return {
                ...rest,
                userId: { _id },
                blocked,
                hasInvited: false,
                hasComon: _id ? true : false,
                lastSeen,
                status,
                invitedAt: 0,
                additional: "",
                address: "",
                city: "",
                country: "",
                email: "",
                gender: "",
                prefix: "",
                region: "",
                street: "",
                suffix: "",
                website: "",
                dob: null,
                isSelected: true
            }
        })
    }))
}

export default groupContact