import { useSelector } from "react-redux";
import { CompletedTaskData } from "../../Types/Types";
import moment from "moment";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import AttachmentIcon from "../../Assets/Images/TaskAttachmentIcon.png";
import { DefaultImageUrl, googleMapCapture } from "../../Constant/Constant";
import Logo from "../../Assets/Images/Logo.png";
import ReportQuestionAudio from "./ReportQuestionMediaPreview/ReportQuestionAudio";
import ReportQuestionImage from "./ReportQuestionMediaPreview/ReportQuestionImage";
import ReportQuestionDocument from "./ReportQuestionMediaPreview/ReportQuestionDocument";
import ReportQuestionVideo from "./ReportQuestionMediaPreview/ReportQuestionVideo";
import SingleImagePreview from "../../Components/Gallery/SignleImagePreview";
import { Avatar } from "antd";
import UserImage from '../../Assets/Images/user.png';
import { useTranslation } from "react-i18next";
const TaskReports = () => {
  const { currentReport, currentOrganization } = useSelector(
    (state: any) => state.organization
  );
  const { t } = useTranslation()
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const { userData } = useSelector((state: any) => state.user)
  const handleFileDownload = async (url: string) => {
    window.open(DefaultImageUrl + url);
  };

  document.addEventListener(
    "play",
    function (e) {
      var audios = document.getElementsByTagName("audio");
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause();
        }
      }
    },
    true
  );

  document.addEventListener(
    "play",
    function (e) {
      var videos = document.getElementsByTagName("video");
      for (var i = 0, len = videos.length; i < len; i++) {
        if (videos[i] !== e.target) {
          videos[i].pause();
        }
      }
    },
    true
  );
  return (
    <div>
      <SingleImagePreview
        show={openImage}
        setShow={setOpenImage}
        imgSrc={imageSrc}
      />

      {currentReport?.tasksData?.length > 0 &&
        currentReport?.tasksData?.map(
          (item: CompletedTaskData, index: number) => {
            const todayDate = moment(new Date()).format("DD MMMM YYYY");

            const completedDate = moment(item?.taskCompleteTime).format(
              "DD MMMM YYYY"
            );
            console.log(item, '000')

            return (
              <div key={index} className="my-2 w-full">

                <div className="flex items-center gap-3">
                  <div className="h-[35px] min-w-[35px] w-[35px]">
                    <img
                      src={Logo}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="text-[18px]">{currentOrganization?.name}</div>
                </div>

                <div className="flex gap-3 w-full mt-4">
                  <div className=" max-w-[50%] pt-3 pb-1 px-4 rounded-md bg-[#E0FAFF] border " style={{ borderColor: "#33ccff" }}>
                    <div className="text-[20px] border-b-2 pb-1">
                      {item?.label}
                    </div>

                    <div className="mt-2">{item?.content}</div>

                    {item?.attachment && (
                      <>
                        <ReportQuestionAudio data={item} />
                        <ReportQuestionImage data={item} />
                        <ReportQuestionDocument data={item} />
                        <ReportQuestionVideo data={item} />
                      </>
                    )}

                    <div className="text-sm flex ml-auto w-full justify-end textGray">
                      {completedDate === todayDate
                        ? moment(item?.taskCompleteTime).format("HH:mm")
                        : completedDate}
                    </div>
                  </div>

                </div>

                <div className="">
                  <div className={`flex flex-col gap-3 items-end my-8  ${item?.memberId?.user?._id === userData?._id ? "ml-auto" : "mr-auto"}`}>
                    <div className={` ${item?.memberId?.user?._id === userData?._id ? "ml-auto text-right" : "mr-auto text-left"}  w-fit`}>


                      {item?.result && (
                        <div className="">
                          {item?.memberId?.user?._id !== userData?._id && <div>
                            {item?.memberId?.user?._id !== userData?._id && <Avatar className="mr-2  mb-2" src={UserImage} />}
                            {item?.memberId?.user?.firstName !== "" ? item?.memberId?.user?.firstName + " " + item?.memberId?.user?.lastName : item?.memberId?.user?.phone}
                          </div>}
                          <div className="w-fit block   px-2 justify-start mr-auto bg-[#E0FAFF] border rounded" style={{ borderColor: "#33ccff" }}>
                            {item?.resultExp?.length > 0 && (
                              <div
                                className="flex mr-auto py-1 border-b-2"
                              >
                                {item?.resultExp?.map((el: any) => (
                                  `${JSON.parse(el).message}${item?.resultExp?.length > 1 ? ', ' : ''}`
                                ))}

                              </div>
                            )}
                            <div
                              className={` py-1  rounded-md ms-2 me-4 h-fit
                       ${item?.memberId?.user?._id !== userData?._id ? "" : ""}`}
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {t('result')}: {item?.result}
                            </div>
                            {item?.signatureAttachment !== "" && (
                              <div className="h-[80px] flex justify-end ml-auto my-2 cursor-pointer" onClick={() => {
                                setImageSrc(
                                  `data:image/png;base64,${item?.signatureAttachment}`
                                );
                                setOpenImage(true);
                              }}>
                                <img src={`data:image/png;base64,${item?.signatureAttachment}`} className="h-full p-2 bg-white w-fit rounded-md object-contain" />
                              </div>

                            )}

                            <div>
                              {(item?.result || item?.attachment) && (
                                <div className="text-sm  textGray">
                                  {completedDate === todayDate
                                    ? moment(item?.taskCompleteTime).format("HH:mm")
                                    : completedDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {item?.resultAttachment?.type === "IMAGE" && (
                    <div
                      className="h-fit flex-col flex ml-auto justify-end w-[250px] min-w-[250px] cursor-pointer bg-[#E0FAFF] px-3  my-0 mr-0  py-4"
                      style={{ border: "1px solid rgb(51, 204, 255)" }}
                      onClick={() => {
                        setImageSrc(
                          DefaultImageUrl + item?.resultAttachment?.filename
                        );
                        setOpenImage(true);
                      }}
                    >
                      <img
                        src={DefaultImageUrl + item?.resultAttachment?.filename}
                        alt=""
                        className="h-full w-full rounded-md  object-contain"
                      />
                      {item.result === "" && item.type === 'MEDIA_UPLOAD' && item?.signatureAttachment !== "" && (
                        <>
                          <div className="h-[80px] flex justify-end ml-auto my-2 cursor-pointer" onClick={() => {
                            setImageSrc(
                              `data:image/png;base64,${item?.signatureAttachment}`
                            );
                            setOpenImage(true);
                          }}>
                            <img src={`data:image/png;base64,${item?.signatureAttachment}`} className="h-full p-2 bg-white w-fit rounded-md object-contain" />
                          </div>
                          <div>
                            { (
                              <div className="text-sm  textGray">
                                {completedDate === todayDate
                                  ? moment(item?.taskCompleteTime).format("HH:mm")
                                  : completedDate}
                              </div>
                            )}
                          </div>
                        </>

                      )}
                    </div>
                  )}

                  {item?.resultAttachment?.type === "VIDEO" && (
                    <div style={{ border: "1px solid rgb(51, 204, 255)" }} className="h-fit flex-col flex justify-end w-[250px] min-w-[200px] bg-[#E0FAFF] p-2  my-0 mr-0 ml-auto py-4">
                      <video
                      controls
                      src={DefaultImageUrl + item?.resultAttachment?.filename}
                      className="h-full w-full"
                    ></video>
                      {item.result === "" && item.type === 'MEDIA_UPLOAD' && item?.signatureAttachment !== "" && (
                        <>
                          <div className="h-[80px] flex justify-end ml-auto my-2 cursor-pointer" onClick={() => {
                            setImageSrc(
                              `data:image/png;base64,${item?.signatureAttachment}`
                            );
                            setOpenImage(true);
                          }}>
                            <img src={`data:image/png;base64,${item?.signatureAttachment}`} className="h-full p-2 bg-white w-fit rounded-md object-contain" />
                          </div>
                          <div>
                            { (
                              <div className="text-sm  textGray">
                                {completedDate === todayDate
                                  ? moment(item?.taskCompleteTime).format("HH:mm")
                                  : completedDate}
                              </div>
                            )}
                          </div>
                        </>

                      )}
                    </div>
                  )}

                  {item?.resultAttachment?.type === "AUDIO" && (
                    <div style={{ border: "1px solid rgb(51, 204, 255)" }} className='flex h-fit flex-col justify-end bg-[#E0FAFF] p-2 w-fit my-0 mr-0 ml-auto py-4'>
                      <audio
                        src={DefaultImageUrl + item?.resultAttachment?.filename}
                        controls
                      ></audio>
                      {item.result === "" && item.type === 'MEDIA_UPLOAD' && item?.signatureAttachment !== "" && (
                        <>
                          <div className="h-[80px] flex justify-end ml-auto my-2 cursor-pointer" onClick={() => {
                            setImageSrc(
                              `data:image/png;base64,${item?.signatureAttachment}`
                            );
                            setOpenImage(true);
                          }}>
                            <img src={`data:image/png;base64,${item?.signatureAttachment}`} className="h-full p-2 bg-white w-fit rounded-md object-contain" />
                          </div>
                          <div>
                            {(
                              <div className="text-sm  textGray">
                                {completedDate === todayDate
                                  ? moment(item?.taskCompleteTime).format("HH:mm")
                                  : completedDate}
                              </div>
                            )}
                          </div>
                        </>

                      )}
                    </div>
                  )}
                  {item?.resultAttachment?.type === "APPLICATION" &&
                    (
                      <div
                        style={{ border: "1px solid rgb(51, 204, 255)" }}
                        className="h-fit flex-col justify-end flex gap-2 max-w-[50%] 
                       rounded-md px-3 bg-[#E0FAFF] p-2 w-fit my-0 mr-0 ml-auto py-4"
                      >
                        <div className="flex gap-2 items-center">
                          <div className="flex border rounded-md gap-2 p-2 border-gray-400 bg-gray-100">
                        <div className="h-[30px] min-w-[30px] w-[30px]">
                          <img
                            src={AttachmentIcon}
                            alt=""
                            className="h-full w-full"
                          />
                        </div>
                        <span style={{ overflowWrap: "anywhere" }}>
                          {item?.resultAttachment?.filename.split('/').pop()}
                        </span>
                        </div>

                        {isFileDownloading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              handleFileDownload(
                                item?.resultAttachment?.filename
                              )
                            }
                          >
                            <FileDownloadOutlinedIcon />
                          </span>
                        )}</div>
                        {item.result === "" && item.type === 'MEDIA_UPLOAD' && item?.signatureAttachment !== "" && (
                          <>
                            <div className="h-[80px] flex justify-end ml-auto my-2 cursor-pointer" onClick={() => {
                              setImageSrc(
                                `data:image/png;base64,${item?.signatureAttachment}`
                              );
                              setOpenImage(true);
                            }}>
                              <img src={`data:image/png;base64,${item?.signatureAttachment}`} className="h-full p-2 bg-white w-fit rounded-md object-contain" />
                            </div>
                            <div>
                              {(
                                <div className="text-sm  textGray">
                                  {completedDate === todayDate
                                    ? moment(item?.taskCompleteTime).format("HH:mm")
                                    : completedDate}
                                </div>
                              )}
                            </div>
                          </>

                        )}
                      </div>
                    )}

                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

export default TaskReports;
