import { useEffect, useState } from "react";
import { setOrganizationInvite } from "../../Redux/OrganizationSlice";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CircularProgress } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ACCEPT_INVITE, DECLINE_INVITE } from "../../Graphql/Mutations";
import { authCllient } from "../../Graphql/authClient";
import { useOrganizations } from "../../hooks/useOrganizations";
import { useTranslation } from "react-i18next";
import { socketListen } from "../../Socket";

export enum InviteStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

function Invites({ onSetDrawer, loading }) {
  const dispatch = useDispatch();
  const [curr, setCurr] = useState('');
  const { t } = useTranslation();
  const { formattedContact } = useSelector(
    (state: any) => state.user
  );
  const { invites,currentOrganization } = useSelector((state: any) => state.organization);
  const [isLoadingOnClicked, setIsLoadingOnClicked] = useState(false);
  const { updateOrganizationsList } = useOrganizations();
  const rejectInvite = async (_id: string, msgId: string,orgId:any) => {
    const updatedInvites = invites?.filter((item: any) => item?._id !== _id);
    setIsLoadingOnClicked(true);
    setCurr(_id)
    await authCllient
      .mutate({
        mutation: DECLINE_INVITE,
        variables: { input: { _id: _id, msgId: msgId ,orgId:orgId} },
      })
      .then((res) => {
        dispatch(setOrganizationInvite(updatedInvites));
        onSetDrawer(false);
      })
      .catch((err) => console.log(`Error occured at Accept Invitation ${err}`))
      .finally(() => { setIsLoadingOnClicked(false); setCurr("") });
  };

  const acceptInvite = async (_id: string, msgId: string,orgId:any) => {
    const updatedInvites = invites?.filter((item: any) => item?._id !== _id);
    setIsLoadingOnClicked(true);
    setCurr(_id)
    await authCllient
      .mutate({
        mutation: ACCEPT_INVITE,
        variables: { input: { _id: _id, msgId: msgId,orgId:orgId } },
      })
      .then((res) => {
        updateOrganizationsList();
        dispatch(setOrganizationInvite(updatedInvites));
        onSetDrawer(false);
      })
      .catch((err) => console.log(`Error occured at Accept Invitation ${err}`))
      .finally(() => { setIsLoadingOnClicked(false); setCurr("") });
  };

  // useEffect(() => {
  //   socketListen("message", (data: any) => {
  //     if (data.type === "deleteInvite") {
  //       dispatch(setOrganizationInvite(invites.filter((el: any) => el._id !== data?.msg?.inviteId)));
  //     }
  //   });
  // }, [])
  if (loading && !invites[0]) {
    return (
      <div className="d-flex align-center justify-center mt-10 text-center">
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <Card className="d-flex justify-center p-2  mx-6 mb-10 mt-4 " style={{ border: '1px solid #33ccff', background: '#E0FAFF' }}>
        <CardContent className="p-0">
          {invites?.length > 0 ? (
            <>
              {invites?.map((item: any, index: number) => {
                return (
                  <div className="block border-b-1 pb-3 my-3" key={index} style={{ borderBottom: index !== invites.length - 1 ? '1px solid #33ccff' : "" }}>
                    <div className="text-center">
                      <b>{formattedContact[item?.user?._id]?.firstName ? formattedContact[item?.user?._id]?.firstName + " " + formattedContact[item?.user?._id]?.lastName + " " : item?.user?.phone + " "}</b>
                      {t("join_organisation_msg")} <b>{item?.masterOrg?.name||item.organization?.name}</b>
                    </div>

                    {isLoadingOnClicked && curr === item?._id ? (
                      <div className="flex mx-auto my-2 justify-center">
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <div className="flex gap-10 justify-center">
                        <button onClick={() => { if (curr === "") { rejectInvite(item?._id, item?.msgId,item?.masterOrg?item?.organization[0]:item?.organization?._id) } }} disabled={curr!==""} className=" rounded-md my-4 text-red-500  p-1 px-3" style={{ border: "1px solid #EF4444" }}>
                          {t("decline")}
                        </button>
                        <button onClick={() => { if (curr === "") { acceptInvite(item?._id, item?.msgId,item?.masterOrg?item?.organization[0]:item?.organization?._id) } }} className={`text-white rounded-md my-4 bg-[#33ccff] p-1 px-3`} disabled={curr!==""} >
                          {t("accept")}
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="d-flex align-center justify-center mt-10 text-center">
              {t("no_invites")}
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default Invites;
