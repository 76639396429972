import dayjs from "dayjs";
import { useEffect } from "react";
import { Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import capitalize from "../../utils/capitalize";
import WeeklyRecurrent from "./WeeklyRecurrent";
import MonthlyRecurrent from "./MonthlyRecurrent";
import useRecurrence from "../../hooks/useRecurrence";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import { DaysNum, EventTypeEnum, RecurrentTypes } from "../Calendar/types/enum";
import ShowNextOccurrence from "./ShowNextOccurrence";
import { getCalendarColor } from "../Calendar/utils/calendarColors";

const frequenciesOptions = [
  RecurrentTypes.ONCE,
  RecurrentTypes.DAILY,
  RecurrentTypes.WEEKLY,
  RecurrentTypes.MONTHLY,
];

interface PropType {
  form: any;
  setForm: (value: any) => void;
  errors: any;
  setErrors: (value: any) => void;
  formType: EventTypeEnum.SCHEDULE | EventTypeEnum.REMINDER;
}

function RecurrentFrequency(props: PropType) {
  const { t } = useTranslation();
  const { form, setForm, errors, setErrors, formType } = props;
  const { recurrentList, calculateRecurrence } = useRecurrence();

  function handleFrequencySelect(frequency: string) {
    const tempFrom = { ...form };
    const newErrors = { ...errors };
    const startDate =
      formType == EventTypeEnum.SCHEDULE ? form?.startDate : form?.start;
    let endDate;

    switch (frequency) {
      case RecurrentTypes.ONCE:
        endDate = startDate;
        break;
      case RecurrentTypes.DAILY:
        endDate = startDate.add(1, "day");
        break;
      case RecurrentTypes.WEEKLY:
        endDate = startDate.add(7, "day");
        break;
      case RecurrentTypes.MONTHLY:
        endDate = startDate.add(1, "year");
        break;
      default:
        return;
    }

    tempFrom.days = [];
    tempFrom.months = [];
    tempFrom.frequency = frequency;
    tempFrom.noOfWeeks = 1;
    // tempFrom.time = dayjs().add(30, "minute");

    if (formType == EventTypeEnum.REMINDER) {
      tempFrom.isAllDay =
        frequency != RecurrentTypes.ONCE ? false : tempFrom?.isAllDay;
      tempFrom.endTimes = dayjs();
      tempFrom.start = startDate;
      tempFrom.end = endDate;
      delete newErrors.start;
      delete newErrors.end;
    } else {
      tempFrom.startDate = startDate;
      tempFrom.endDate = endDate;
      delete newErrors.startDate;
      delete newErrors.endDate;
    }

    setForm(tempFrom);
    setErrors(newErrors);
  }

  useEffect(() => {
    if (form?.start || form?.startDate || form?.end || form?.endDate) {
      calculateRecurrence({
        type: form?.frequency,
        startDate:
          formType == EventTypeEnum.REMINDER ? form?.start : form?.startDate,
        endDate: formType == EventTypeEnum.REMINDER ? form?.end : form?.endDate,
        daysOfWeek: form?.days,
        weekRepeatNumber: form?.noOfWeeks,
        isMonthDay: form?.monthDateType == "day" ? true : false,
        day: form?.onDay ?? 1,
        dayOfWeek: DaysNum?.[form?.onWeek] ?? 1,
        weekNumber: form?.everyWeek ?? 1,
      });
    }
  }, [
    form?.startDate,
    form?.start,
    form?.endDate,
    form?.end,
    form?.time,
    form?.endTimes,
    form?.frequency,
    form?.days,
    form?.noOfWeeks,
    form?.monthDateType,
    form?.onDay,
    form?.onWeek,
    form?.everyWeek,
    form?.months,
    form?.repeatEveryMonth,
  ]);

  return (
    <div className="mt-4 flex gap-3">
      <RepeatOutlinedIcon
        fontSize="medium"
        className="text-gray-500"
        sx={{
          color:
            formType == EventTypeEnum.REMINDER &&
            getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full">
        <Stack direction="row" spacing={1}>
          {frequenciesOptions?.map((item: string, index: number) => (
            <Chip
              key={index}
              size="small"
              color="primary"
              label={capitalize(t(item))}
              onClick={() => handleFrequencySelect(item)}
              variant={item == form?.frequency ? "filled" : "outlined"}
              sx={{
                backgroundColor:
                  form?.frequency == item
                    ? getCalendarColor(form?.type, "dark")
                    : "",
              }}
            />
          ))}
        </Stack>

        {form?.frequency == RecurrentTypes.WEEKLY && (
          <WeeklyRecurrent form={form} setForm={setForm} />
        )}

        {form?.frequency == RecurrentTypes.MONTHLY && (
          <MonthlyRecurrent form={form} setForm={setForm} formType={formType} />
        )}

        <ShowNextOccurrence
          form={form}
          formType={formType}
          recurrentList={recurrentList}
        />
      </div>
    </div>
  );
}

export default RecurrentFrequency;
