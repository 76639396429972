import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import PredefinedFields from "./PredefinedFields";
import { IRecordModalProps, TField } from "./databasetypes";
import { customFieldTypes } from "./data";
import RecordFields from "./RecordFields";
import { authCllient } from "../../../Graphql/authClient";
import {
  CREATE_RECORD,
  GET_RECORD_BY_ID,
  UPDATE_RECORD,
} from "../../../Graphql/Mutations";
import dayjs from "dayjs";
import ViewRecord from "./ViewRecord";

const initialData = [
  {
    key: "title",
    label: "title",
    value: "",
    type: "text",
    required: true,
    isCustom: false,
  },
];

const RecordModal = ({
  show,
  setShow,
  currentDatabase,
  globalId,
  setIsRefresh,
  recordId,
  setRecordId,
  fromCalendar,
  modalMode,
  setModalMode,
}: IRecordModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showPredefinedFields, setShowPredefinedFields] = useState(false);
  const [customFieldAnchor, setCustomFieldAnchor] = useState(null);
  const [recordDetails, setRecordDetails] = useState(null);
  const [recordTitle, setRecordTitle] = useState("");
  const [selectedFields, setSelectedFields] =
    useState<Array<TField>>(initialData);
  const customFieldOpen = Boolean(customFieldAnchor);
  const customFieldId = customFieldOpen ? "simple-popover" : undefined;

  const handleClose = () => {
    setError("");
    setModalMode("edit");
    if (fromCalendar) {
      setShow({ show: false, data: null });
    } else {
      setRecordId("");
      setShow(false);
    }
  };

  useEffect(() => {
    if (recordId) {
      getRecordDetails();
    }
  }, []);

  const getRecordDetails = async () => {
    setError("");
    setIsFetching(true);

    try {
      const response = await authCllient.mutate({
        mutation: GET_RECORD_BY_ID,
        variables: { input: { _id: recordId } },
      });

      const responseD = response?.data?.getRecordById;
      if (responseD) {
        if (fromCalendar) {
          setRecordDetails({
            parent: responseD?.parent,
          });
        }

        const ignoreKeys = [
          "__typename",
          "_id",
          "reminderId",
          "parent",
          "createdAt",
          "userId",
          "customFields",
          "isReminder",
        ];

        const predifinedFields = [];
        const customFields = [];
        let title = "";

        for (const key in responseD) {
          if (!ignoreKeys.includes(key)) {
            if (key == "title") {
              title = responseD?.["title"];
            }

            if (responseD?.[key]) {
              predifinedFields.push({
                key,
                label: key?.toLowerCase(),
                value: responseD?.[key],
                type: key == "mobile" || key == "landLine" ? "number" : "text",
                required: key == "title" ? true : false,
                isCustom: false,
              });
            }
          }
        }

        const customData = responseD?.customFields
          ? JSON.parse(responseD?.customFields)
          : [];

        if (customData?.length) {
          for (let i = 0; i < customData?.length; i++) {
            const item = customData?.[i];
            customFields.push({
              isCustom: true,
              key: `custom_${i}`,
              label: item?.label,
              value: item?.value,
              remind_at: item?.type != "date" ? null : item?.remind_at,
              type: item?.type,
              on_calender: item?.on_calender,
              attachments: item?.attachments,
              required: false,
            });
          }
        }

        setRecordTitle(title);
        const formattedData = [...predifinedFields, ...customFields];
        setSelectedFields(formattedData);
      } else {
        setError(t("pleaseenterfields"));
      }
    } catch (error) {
      console.log(error);
      setError(t("pleaseenterfields"));
    } finally {
      setIsFetching(false);
    }
  };

  const addCustomField = (type: string) => {
    const newField = {
      key: `custom_${selectedFields?.length}`,
      label: "",
      value: type == "date" ? dayjs().toISOString() : "",
      type,
      remind_at: null,
      required: false,
      isCustom: true,
    };
    setSelectedFields([...selectedFields, newField]);
    setCustomFieldAnchor(null);
  };

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    const isValid = selectedFields?.every((elem) => {
      if (elem?.type == "attachment") {
        return elem?.label?.trim() && elem?.attachments?.length != 0;
      } else if (typeof elem?.value == "object") {
        return elem?.label?.trim() && Object.keys(elem?.value).length != 0;
      }
      {
        return elem?.label?.trim() && elem?.value?.trim();
      }
    });

    if (isValid) {
      setError("");
      setloading(true);
      const customFields = selectedFields
        ?.filter((elem) => elem?.isCustom)
        ?.map((elem) => ({
          ...elem,
          attachments: elem?.type === "attachment" ? elem?.attachments : [],
          on_calender: elem?.type === "date" ? elem?.on_calender : false,
        }));

      const payload = {
        parent: fromCalendar
          ? recordDetails?.parent
          : currentDatabase?._id || globalId,
        customFields: customFields?.length
          ? JSON.stringify(customFields)
          : "[]",
        ...(recordId && {
          _id: recordId,
        }),
      };

      selectedFields?.forEach((elem) => {
        if (!elem?.isCustom) {
          payload[elem?.key] = elem?.value;
        }
      });

      console.log(payload);

      try {
        const response = await authCllient.mutate({
          mutation: recordId ? UPDATE_RECORD : CREATE_RECORD,
          variables: {
            input: payload,
          },
        });

        if (
          response?.data?.createUserRecord ||
          response?.data?.updateUserRecord
        ) {
          setIsRefresh(true);
          setShow(false);
        } else {
          setError(t("something_went_wrong"));
        }
      } catch (error) {
        setError(t("something_went_wrong"));
        console.log(error);
      } finally {
        setloading(false);
      }
    } else {
      setError(t("pleaseenterfields"));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={show}
        sx={{
          ".MuiDialog-paper": {
            minWidth: "60%",
            minHeight: "70%",
            backgroundColor: "#F3F9FC",
          },
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <DialogContent
          sx={{
            maxHeight: "700px",
            overflowY: "auto",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "0px 24px 10px 24px",
          }}
        >
          <div>
            <div className="flex items-center justify-between py-[10px] sticky top-0 bg-[#F3F9FC]">
              {modalMode == "edit" ? (
                <div className="font-semibold text-lg capitalize">
                  {recordId ? t("updaterecord") : t("createnewrecord")}
                </div>
              ) : (
                <div className="font-semibold text-lg capitalize">
                  {recordTitle}
                </div>
              )}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            {isFetching ? (
              <div className="flex justify-center items-center">
                <CircularProgress size={40} color="primary" />
              </div>
            ) : modalMode == "edit" ? (
              <div>
                <div className="text-center mt-2">
                  <RecordFields
                    selectedFields={selectedFields}
                    setSelectedFields={setSelectedFields}
                  />

                  <div className="mt-4 flex gap-4 items-center justify-between">
                    <button
                      type="button"
                      className="outline-none capitalize font-semibold text-[#33CCFF]"
                      onClick={() => setShowPredefinedFields(true)}
                    >
                      {t("addfields")}
                    </button>

                    <button
                      type="button"
                      onClick={(ev) => setCustomFieldAnchor(ev.currentTarget)}
                      className="outline-none capitalize font-semibold text-[#33CCFF]"
                    >
                      {t("addcustomfields")}
                    </button>
                  </div>
                </div>
                <div className="mt-5">
                  {error && (
                    <div className="text-red-600 text-center text-sm mb-2">
                      {error}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <ViewRecord
                data={selectedFields}
                recordId={recordId}
                recordTitle={recordTitle}
                setIsRefresh={setIsRefresh}
                closeRecordModal={handleClose}
                setModalMode={setModalMode}
              />
            )}
          </div>

          {!isFetching && modalMode == "edit" && (
            <button
              type="submit"
              disabled={Boolean(loading)}
              className="bg-[#33CCFF] w-full outline-none py-2 rounded-md text-white"
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                t("submit")
              )}
            </button>
          )}
        </DialogContent>
      </Dialog>

      {showPredefinedFields && (
        <PredefinedFields
          show={showPredefinedFields}
          setShow={setShowPredefinedFields}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
        />
      )}

      <Popover
        id={customFieldId}
        open={customFieldOpen}
        anchorEl={customFieldAnchor}
        onClose={() => setCustomFieldAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {customFieldTypes.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => addCustomField(item?.value)}>
              <div className="flex gap-2 capitalize text-sm">
                <img src={item.icon} className="w-[22px]" />{" "}
                {`${t(item.label)} ${t("field")}`}
              </div>
            </MenuItem>
          );
        })}
      </Popover>
    </React.Fragment>
  );
};

export default RecordModal;
