import { gql } from "@apollo/client";
import { APPOINTMENT_FRAGMENT, FOLDER_FRAGMENT } from "./Fragments";

export const LOGIN = gql`
  query login($input: SignInInput!) {
    login(input: $input) {
      token
      refresh
      mode
      isMasterAdmin
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query requestPasswordResetSms($input: RequestPasswordResetSmsInput!) {
    requestPasswordResetSms(input: $input) {
      success
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  query validatePasswordResetSms($input: ValidatePasswordResetSmsInput!) {
    validatePasswordResetSms(input: $input) {
      token
    }
  }
`;
export const GET_MY_CONTACTS = gql`
  query getMyContacts {
    getMyContacts {
      contacts {
        localId
        userId {
          _id
          firstName
          lastName
          phone
          status
          bio {
            time
            status
          }
          profile_img
          lastSeen
        }
        blocked
        phone
        firstName
        lastName
      }
    }
  }
`;

export const SUBSCRIBE_TO_TOPIC = gql`
  query subscribeToTopic($token: String!) {
    subscribeToTopic(token: $token) {
      success
      message
    }
  }
`;

export const UNSUBSCRIBE_FROM_TOPIC = gql`
  query unsubscribeFromTopic($token: String!) {
    unsubscribeFromTopic(token: $token) {
      success
      message
    }
  }
`;

export const LOGOUT = gql`
  query logout($input: webDeviceDto!) {
    logout(input: $input) {
      success
      message
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserDetails on User {
    firstName
    lastName
    phone
    _id
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment MemberDetails on Member {
    _id
    role
    organizationId
    user {
      ...UserDetails
    }
  }
  ${USER_FRAGMENT}
`;

export const ORGANIZATION = gql`
  query organizations {
    organizations {
      _id
      name
      link
      description
    }
  }
`;

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationDetails on Organization {
    _id
    name
    link
    description
    members {
      ...MemberDetails
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const INVITE_FRAGMENT = gql`
  fragment InviteDetails on Invite {
    _id
    email
    phone
    organization
    masterOrg {
      ...OrganizationDetails
    }
    role
    status
    user {
      _id
      phone
      firstName
      lastName
      profile_img
    }
    msgId
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const GET_INVITES_BY_ORG_ID = gql`
  query getInvitesByOrg($input: InvitesByOrgInput!) {
    getInvitesByOrg(input: $input) {
      ...InviteDetails
    }
  }
  ${INVITE_FRAGMENT}
`;

export const GET_MY_INVITES = gql`
  query getMyInvites {
    getMyInvites {
      ...InviteDetails
    }
  }
  ${INVITE_FRAGMENT}
`;

export const SessionDetailsFragmentDoc = gql`
  fragment SessionDetails on Session {
    token
    refresh
    mode
    isMasterAdmin
  }
`;

export const RefreshSessionDocument = gql`
  query refreshSession($input: RefreshTokenInput!) {
    refreshSession(input: $input) {
      ...SessionDetails
    }
  }
  ${SessionDetailsFragmentDoc}
`;

// Assignment

export const ASSIGNMENT_FRAGMENT = gql`
  fragment AssignmentDetails on Assignment {
    _id
    recurrent

    end
    members {
      completeTime
      reportsCount
      activeReportId
      member {
        _id
        user {
          _id
          firstName
          lastName
        }
      }
      memberRole
      roomId
    }
    scenario {
      _id
      name
      description
    }
    start
    end
    startTimeInMs

    periodical

    montlyParams {
      months
      twicePerMonth
    }
    daylyParams {
      dayOfWeeks
      everyWeek
    }
  }
`;

export const PAGINATED_ASSIGNMENT_FRAGMENT = gql`
  fragment PaginatedAssignmentDetails on PaginatedAssignment {
    data {
      ...AssignmentDetails
    }
    totalCount
  }
  ${ASSIGNMENT_FRAGMENT}
`;

export const GET_MY_ASSIGNMENT = gql`
  query myAssignments($input: MyAssignmentsInputDto!) {
    myAssignments(input: $input) {
      ...PaginatedAssignmentDetails
    }
  }
  ${PAGINATED_ASSIGNMENT_FRAGMENT}
`;

// Reports

export const REPORT_FRAGMENT = gql`
  fragment ReportDetails on Report {
    _id
    organizationId
    assignment {
      _id
      scenario {
        _id
        name
        description
      }
    }
    member {
      _id
      user {
        _id
        firstName
        lastName
      }
      role
    }
    tasksData {
      type
      label
      content
      edgeId
      targetTaskId
      result
      lat
      outOfLocation
      distance
      distanceUnit
      long
      memberId {
        _id
        user {
          _id
          firstName
          lastName
          phone
        }
        role
      }
      resultExp
      taskStartTime
      taskCompleteTime
      # attachment {
      #   _id
      #   filename
      #   type
      #   url
      # }
    }
    startTime
    completeTime
  }
`;

export const PAGINATED_REPORT_FRAGMENT = gql`
  fragment PaginatedReportDetails on PaginatedReport {
    data {
      ...ReportDetails
    }
    totalCount
  }
  ${REPORT_FRAGMENT}
`;

export const GET_MY_REPORTS = gql`
  query myReports($input: ReportsInputDto!) {
    myReports(input: $input) {
      ...PaginatedReportDetails
    }
  }
  ${PAGINATED_REPORT_FRAGMENT}
`;

export const GET_REPORT = gql`
  query report($input: IdDto!) {
    report(input: $input) {
      ...ReportDetails
      tasksData {
        type
        label
        content
        edgeId
        targetTaskId
        taskId
        result
        lat
        long
        outOfLocation
        distance
        distanceUnit
        isApproved
        isDelay
        delayTime
        taskStartTime
        taskCompleteTime
        memberId {
          _id
          user {
            _id
            firstName
            lastName
            phone
          }
          role
        }
        attachment {
          _id
          filename
          type
          url
        }
        signatureAttachment
        resultAttachment {
          _id
          organizationId
          userId
          filename
          type
          url
        }
      }
    }
  }
  ${REPORT_FRAGMENT}
`;

export const GET_SCENARIO_BY_ID = gql`
  query scenario($input: orgAndIdDto!) {
    scenario(input: $input) {
      _id
      type
      name
      description
      isValid
      isAssigned
      parentId
      childId
      organizationId
      tasks {
        _id
        label
        content
        type
        subType
        assignTo {
          _id
          user {
            _id
            email
            phone
            firstName
            lastName
            iso_code
            phoneConfirmed
            emailConfirmed
            profile_img
            bio {
              status
              time
            }
            status
            lastSeen
            folders {
              _id
              name
              roomId
            }
          }
          organizationId
          role
        }
        position {
          x
          y
        }
        width
        timeout
        remindEvery
        saveUserLocation
        mediaType
        mediaQuality
        mediaDuration
        signature
        attachment {
          attachment {
            _id
            organizationId
            userId
            filename
            type
            url
          }
          type
        }
        member {
          _id
          user {
            _id
            email
            phone
            firstName
            lastName
            iso_code
            phoneConfirmed
            emailConfirmed
            profile_img
            bio {
              status
              time
            }
            status
            lastSeen
            folders {
              _id
              name
              roomId
            }
          }
          organizationId
          role
        }
        edges {
          _id
          type
          label
          order
          signature
          location
          media
          targetTaskID
          options {
            label
            location
            media
            signature
          }
        }
        nextPrompt {
          type
          time
        }
        rangeExpression {
          type
          customError
          min
          max
          customExp {
            id
            message
            signature
            media
            location
            value
            notifyTo {
              member {
                _id
              }
              message
            }
          }
        }
      }
    }
  }
`;

export const CALL_PARTICIPANTS = gql`
  query callParticipants($input: IdDto!) {
    callParticipants(input: $input) {
      participants {
        uid
        callStatus
        userId {
          _id
          email
          phone
          firstName
          lastName
          profile_img
          status
        }
        createdAt
        callHistory {
          callJoinedAt
          callEndedAt
        }
      }
    }
  }
`;
export const CALL_LIST = gql`
  query getMyCallList($input: GetMyCallListDTO!) {
    getMyCallList(input: $input) {
      count
      call {
        _id
        origin
        type
        channelName
        roomId {
          _id
          name
          profile_img
          type
        }
        duration
        roomType
        categoryId
        callStatus
        callStartedAt
        callEndedAt
        channelName
        callParticipants {
          callStatus
          userId {
            _id
            phone
            firstName
            lastName
            profile_img
            status
          }
          callHistory {
            callJoinedAt
            callEndedAt
          }
        }
      }
    }
  }
`;

export const CALL_LIST_WITH_PARTICIPANTS = gql`
  query getCallListWithAParticipant($input: CallListWithAParticipantDTO!) {
    getCallListWithAParticipant(input: $input) {
      _id
      participants {
        firstName
        lastName
        _id
      }
      callStartedAt
      duration
      channelName
      roomId
      origin {
        _id
        phone
        firstName
        lastName
        lastSeen
        profile_img
        status
      }
      callParticipants {
        callStatus
        userId {
          _id
          email
          phone
          firstName
          lastName
          profile_img
          status
        }
        callHistory {
          callJoinedAt
          callEndedAt
        }
      }
    }
  }
`;

export const GET_USER_DATA = gql`
  query me {
    me {
      profile_img
      firstName
      lastName
      bio {
        time
        status
      }
    }
  }
`;

export const GET_CHATS_BY_ROOM_ID = gql`
  query getChatsByRoomId($input: getConversationInput!) {
    getChatsByRoomId(input: $input) {
      _id
      roomId
      type
      sender
      message
      fileURL
      thumbnail
      reply_msg {
        cid
        type
        sender
        message
        file_URL
        fontStyle
        created_at
        index
      }
      read_by {
        user_id
        read_at
      }
      favourite_by {
        user_id
        favourite_at
      }
      deleted {
        type
        user_id
        deleted_at
      }
      downloadBy {
        user_id
        device_unique
      }
      isForwarded
      PinBy {
        user_id
        pin_at
      }
      fontStyle
      isSent
      delivered_to {
        user_id
        delivered_at
      }
      deliveredToIds
      readByIds
      created_at
      receipts
      inviteStatus
      updated_at
      index
    }
  }
`;

export const GET_CHATS_BY_INDEX_ID = gql`
  query getChatsByIndex($input: getChatsByIndexInput!) {
    getChatsByIndex(input: $input) {
      _id
      roomId
      type
      sender
      message
      fileURL
      thumbnail
      reply_msg {
        cid
        type
        sender
        message
        file_URL
        fontStyle
        created_at
        index
      }
      read_by {
        user_id
        read_at
      }
      favourite_by {
        user_id
        favourite_at
      }
      deleted {
        type
        user_id
        deleted_at
      }
      downloadBy {
        user_id
        device_unique
      }
      isForwarded
      PinBy {
        user_id
        pin_at
      }
      fontStyle
      isSent
      delivered_to {
        user_id
        delivered_at
      }
      deliveredToIds
      readByIds
      created_at
      updated_at
      inviteStatus
      index
    }
  }
`;

export const GET_ON_GOING_CALLS = gql`
  query getOnGoingCalls {
    getOnGoingCalls {
      _id
      type
      channelName
      roomType
      roomId {
        _id
        name
        profile_img
      }
      callStartedAt
      callParticipants {
        callStatus
        userId {
          _id
          email
          phone
          firstName
          lastName
          iso_code
          phoneConfirmed
          emailConfirmed
          profile_img
          bio {
            status
            time
          }
          status
          lastSeen
          folders {
            _id
            name
            roomId
          }
          device {
            token
            type
          }
        }
        uid
        callHistory {
          callJoinedAt
          callEndedAt
        }
      }
    }
  }
`;

export const GET_CHANNEL_STATUS = gql`
  query getChannelStatus($input: ChannelStatusInput!) {
    getChannelStatus(input: $input) {
      isChannelExists
      callId
      channelName
      success
      users
    }
  }
`;

export const getMyNotifications = gql`
  query getMyNotifications($input: MyNotificationsInput!) {
    getMyNotifications(input: $input) {
      data {
        title
        body
        type
        payload
        isSeen
        _id
        createdAt
      }
      total
    }
  }
`;

export const ME = gql`
  query me {
    me {
      email
      firstName
      lastName
      phone
      phoneConfirmed
      emailConfirmed
      _id
      profile_img
      lastSeen
      language
      folders {
        _id
        name
        roomId
      }
      language
      WebDeviceId
    }
  }
`;

export const GET_APPOINTMENTS_REMINDERS = gql`
  query getAppointmentForCalendar {
    getAppointmentForCalendar {
      ...AppointmentFields
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_APPOINTMENT_BY_ROOM_ID = gql`
  query getReminderRange($input: childReminderInput!) {
    getReminderRange(input: $input) {
      date
      totalPage
      reminders {
        _id
        type
        title
        parent_id
        participants {
          _id
          accepted
          role
          firstName
          lastName
          phone
          profile_img
          left_at
        }
        date
        startDate
        isAllDay
        endTime
        endDate
        roomId
        time
        recursive
        description
        approvalReminderTime {
          _id
          Count
          Unit
        }
        attachment {
          type
          url
          name
          thumbnail
          duration
          uploadedAt
          mimeType
        }
        location {
          _id
          address
          latitude
          longitude
          countryOffset
          mapUrl
        }
        startTimeInMs
        daylyParams {
          dayOfWeeks
          everyWeek
        }
        monthlyParams {
          months
          twicePerMonth
          onDay
          onWeek {
            dayOfWeeks
            everyWeek
          }
        }
        hasComon
        isConfirmationNeeded
        roomType
        isApprovalNeeded
        message {
          _id
          roomId
          type
          fileURL
          isForwarded
          message
          fontStyle
          thumbnail
          duration
        }
      }
    }
  }
`;

export const GET_CALENDAR_INVITATIONS = gql`
  query getPendingReminder {
    getPendingReminder {
      _id
      parent_id
      type
      title
      startDate
      endDate
      time
      recursive
      participants {
        _id
        accepted
        role
        firstName
        lastName
        phone
        profile_img
        left_at
      }
      isApprovalNeeded
      approvalReminderTime {
        Count
        Unit
      }
      message {
        _id
        roomId
        type
        fileURL
        isForwarded
        message
        fontStyle
        thumbnail
        duration
      }
    }
  }
`;

export const GET_SCHEDULE_BY_ROOM_ID = gql`
  query getScheduleByRoomID($input: childReminderInput!) {
    getScheduleByRoomID(input: $input) {
      date
      totalPage
      reminders {
        _id
        parent_id
        type
        roomId
        startDate
        endDate
        time
        message {
          _id
          roomId
          type
          fileURL
          isForwarded
          message
          fontStyle
          thumbnail
          duration
        }
        isApprovalNeeded
        approvalReminderTime {
          Count
          Unit
        }
        recursive
        daylyParams {
          dayOfWeeks
          everyWeek
        }
        monthlyParams {
          months
          onDay
          onWeek {
            dayOfWeeks
            everyWeek
          }
          twicePerMonth
        }
        roomType
      }
    }
  }
`;

export const GET_MY_TOPICS = gql`
  query getMyTopics {
    getMyTopics {
      _id
      name
      userId
      parent
      subTopics {
        _id
        name
      }
      chats {
        roomId {
          _id
          name
          type
        }
        chatId
        sender {
          _id
          firstName
          lastName
          profile_img
        }
        message {
          type
          duration
          message
          fileURL
          thumbnail
          isForwarded
          fontStyle
          index
          isSent
          created_at
          updated_at
        }
        toUser {
          _id
          firstName
          lastName
          profile_img
        }
      }
    }
  }
`;

export const GET_TOPIC_BY_ID = gql`
  query getTopicById($input: IdDto!) {
    getTopicById(input: $input) {
      _id
      name
      userId
      parent
      subTopics {
        _id
        name
      }
      chats {
        roomId {
          _id
          name
          type
        }
        chatId
        sender {
          _id
          firstName
          lastName
          profile_img
        }
        message {
          type
          duration
          message
          fileURL
          thumbnail
          index
          isForwarded
          fontStyle
          isSent
          created_at
          updated_at
        }
        toUser {
          _id
          firstName
          lastName
          profile_img
        }
      }
    }
  }
`;

export const GET_CHILD_TOPIC_BY_ID = gql`
  query getChildTopics($input: IdDto!) {
    getChildTopics(input: $input) {
      _id
      name
      userId
      parent
      subTopics {
        _id
        name
      }
      chats {
        roomId {
          _id
          name
          type
        }
        chatId
        sender {
          _id
          firstName
          lastName
          profile_img
        }
        message {
          type
          duration
          message
          fileURL
          thumbnail
          index
          isForwarded
          fontStyle
          isSent
          created_at
          updated_at
        }
        toUser {
          _id
          firstName
          lastName
          profile_img
        }
      }
    }
  }
`;

export const GET_ASSIGNMENTS_FOR_CALENDAR = gql`
  query getAssignmentForCalendar {
    getAssignmentForCalendar {
      _id
      type
      recurrent
      startTimeInMs
      organizationId
      daylyParams {
        dayOfWeeks
        everyWeek
      }
      montlyParams {
        months
        twicePerMonth
      }
      scenario {
        _id
        name
        description
        tasks {
          _id
          label
          content
          type
          saveUserLocation
          mediaType
          mediaQuality
          edges {
            _id
            type
            location
            signature
          }
        }
      }
      completeTime
      members {
        memberRole
        reportsCount
      }
      dateTimeInput {
        date
        time
      }
    }
  }
`;

export const GET_MY_FOLDERS = gql`
  query getMyFolders($input: getFolderInput!) {
    getMyFolders(input: $input) {
      ...folderFields
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_ONLINE_USERS = gql`
  query getOnlineUsersForWeb {
    getOnlineUsersForWeb {
      userId
      status
      lastSeen
    }
  }
`;

export const GET_NOTIFICATION_BY_ID = gql`
  query getNotificationById($input: IdDto!) {
    getNotificationById(input: $input) {
      _id
      title
      body
      type
      payload
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguageList {
    getLanguageList {
      _id
      code
      name
      icon
    }
  }
`
