import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Checkbox, Chip, FormControlLabel, Tooltip } from "@mui/material";
import { IPredefinedProps, TField } from "./databasetypes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { getSession } from "../../../utils/session";
import { mutationClient } from "../../../Graphql/authClient";
import { NEW_UPLOAD_FILE } from "../../../Graphql/Mutations";
import checkFileType from "../../../utils/checkFileType";
import TextInput from "../../../Components/inputs/TextInput";
import FileInput from "../../../Components/inputs/FileInput";
import AttachmentIcon from "@mui/icons-material/Attachment";
import RecordReminder from "./RecordReminder";
import FilePreview from "./FilePreview";
import getAttachmentName from "../../../utils/getAttachmentName";
import { DefaultImageUrl } from "../../../Constant/Constant";

const RecordFields = (props: IPredefinedProps) => {
  const { t } = useTranslation();
  const { selectedFields, setSelectedFields } = props;
  const [uploading, setUploading] = useState<number | null>(null);
  const [filePreview, setFilePreview] = useState({ show: false, file: null });

  const onRemoveField = (field: TField) => {
    field.checked = false;
    const updated = selectedFields?.filter(
      (el: TField) => el?.key != field?.key
    );
    setSelectedFields(updated);
  };

  const handleChange = (ev: ChangeEvent<HTMLInputElement>, key: string) => {
    const value = ev.target.value;
    const updated = selectedFields?.map((field: TField) =>
      field?.key == key ? { ...field, value } : field
    );
    setSelectedFields(updated);
  };

  const handleCustomFieldChange = (
    key: string,
    property: string,
    value: any
  ) => {
    const updated: any = selectedFields?.map((field) => {
      return field?.key == key ? { ...field, [property]: value } : field;
    });
    setSelectedFields(updated);
  };

  const handleDateTimeChange = (date: any, time: any, key: string) => {
    const combinedDateTime = dayjs(date)
      .set("hour", dayjs(time).hour())
      .set("minute", dayjs(time).minute());

    const newValue = dayjs(combinedDateTime).toISOString();

    const updated = selectedFields?.map((field) => {
      if (field?.key == key) {
        if (dayjs(newValue).isBefore(dayjs(), "day")) {
          return {
            ...field,
            value: newValue,
            on_calender: false,
            remind_at: null,
          };
        } else {
          return { ...field, value: newValue };
        }
      }
      return field;
    });
    setSelectedFields(updated);
  };

  const handleFileChange = async (key: string, file: File, index: number) => {
    const fileSizeInMegabytes: any = file?.size / (1024 * 1024);

    if (file) {
      if (!file?.type) {
        alert(t("invalid_file"));
      } else if (parseInt(fileSizeInMegabytes) > 10) {
        alert(t("file_size_error"));
      } else {
        setUploading(index);
        const { token } = getSession();

        try {
          const response = await mutationClient(token).mutate({
            mutation: NEW_UPLOAD_FILE,
            variables: {
              file,
              input: {
                orgId: "",
                attachments: true,
              },
            },
          });

          const data = response?.data?.uploadFileRecord;
          const splittedFileName = data?.filename?.split(".");
          const fileExtension = splittedFileName?.pop();
          const fileType = checkFileType(fileExtension);

          const newFile = {
            type: fileType,
            url: data?.url,
            name: getAttachmentName(data?.filename),
            thumbnail: null,
            duration: 0,
            uploadedAt: dayjs().toISOString(),
            mimeType: fileExtension,
          };

          const updated = selectedFields?.map((field) => {
            if (field?.key == key) {
              const tempFile = field?.attachments?.length
                ? [...field?.attachments]
                : [];

              tempFile.push(newFile);

              return {
                ...field,
                value: "file",
                attachments: tempFile,
              };
            }
            return field;
          });

          setSelectedFields(updated);
        } catch (error) {
          console.log(error);
          alert(t("something_went_wrong"));
        } finally {
          setUploading(null);
        }
      }
    }
  };

  const handleCalendar = (key: string, checked: boolean) => {
    const updated = selectedFields?.map((field) => {
      return field?.key == key
        ? {
            ...field,
            on_calender: checked,
            remind_at: null,
          }
        : field;
    });
    setSelectedFields(updated);
  };

  const handleRemindAt = (key: string, checked: boolean) => {
    const updated = selectedFields?.map((field) => {
      return field?.key == key
        ? {
            ...field,
            remind_at: checked ? { Count: 5, Unit: "MINUTE" } : null,
          }
        : field;
    });
    setSelectedFields(updated);
  };

  const onRemoveFile = (fieldKey: string, fileIndex: number) => {
    const updated = selectedFields?.map((field) => {
      return field?.key == fieldKey
        ? {
            ...field,
            attachments: field?.attachments?.filter(
              (_, index) => index != fileIndex
            ),
          }
        : field;
    });
    setSelectedFields(updated);
  };

  const handleClickOnFile = (file: any) => {
    const types = ["AUDIO", "VIDEO", "PHOTO", "IMAGE"];
    if (types.includes(file?.type)) {
      setFilePreview({ file, show: true });
    } else {
      const source = DefaultImageUrl + file?.url;
      window.open(source);
    }
  };

  const showFileName = (name: string): string => {
    if (name?.length > 30) {
      return name?.slice(0, 20) + "..." + name?.slice(-4);
    }
    return name;
  };

  return (
    <div className="grid gap-4">
      {selectedFields?.map((field: TField, index: number) => {
        return (
          <div key={index} className="grid gap-4 p-4 bg-white rounded-md">
            {field?.isCustom ? (
              <Fragment>
                <div className="text-sm font-semibold text-left">
                  {t("customfield")}
                </div>

                <div className="grid gap-4 w-full">
                  <div className="flex gap-2 justify-between items-start">
                    <TextInput
                      type={"text"}
                      value={field?.label}
                      placeholder={`${t("enter")} ${t("label")}`}
                      onChange={(ev) =>
                        handleCustomFieldChange(
                          field?.key,
                          "label",
                          ev.target.value
                        )
                      }
                    />

                    <div className="text-right">
                      <button
                        type="button"
                        onClick={() => onRemoveField(field)}
                        className="outline-none font-semibold text-sm text-[#33CCFF]"
                      >
                        {t("remove")}
                      </button>
                    </div>
                  </div>

                  {(field?.type == "text" || field?.type == "number") && (
                    <TextInput
                      type={field?.type}
                      placeholder={`${t("enter")} ${t("value")}`}
                      value={field?.value}
                      onChange={(ev: any) => handleChange(ev, field.key)}
                    />
                  )}

                  {field?.type == "date" && (
                    <div>
                      <div className="flex flex-wrap items-center gap-2 w-full">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label={t("fieldvalue")}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              className="w-full"
                              value={
                                field?.value ? dayjs(field?.value) : dayjs()
                              }
                              onChange={(date) =>
                                handleDateTimeChange(
                                  date,
                                  dayjs(field?.value),
                                  field?.key
                                )
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              label={t("time")}
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              className="w-full"
                              timeSteps={{ hours: 1, minutes: 1 }}
                              value={
                                field?.value ? dayjs(field?.value) : dayjs()
                              }
                              onChange={(time) =>
                                handleDateTimeChange(
                                  dayjs(field?.value),
                                  time,
                                  field?.key
                                )
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>

                        {!dayjs(field?.value).isBefore(dayjs(), "day") && (
                          <div className="flex gap-2 mt-1">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#33CCFF",
                                    },
                                  }}
                                  checked={Boolean(field?.on_calender == true)}
                                  onChange={(ev) =>
                                    handleCalendar(
                                      field?.key,
                                      ev.target.checked
                                    )
                                  }
                                />
                              }
                              label={
                                <div className="text-sm">
                                  {t("addoncalendar")}
                                </div>
                              }
                            />

                            {field?.on_calender && (
                              <div className="text-left">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#33CCFF",
                                        },
                                      }}
                                      checked={Boolean(
                                        field?.remind_at ? true : false
                                      )}
                                      onChange={(ev) =>
                                        handleRemindAt(
                                          field?.key,
                                          ev.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <div className="text-sm">
                                      {t("remind_me_before")}
                                    </div>
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {field?.remind_at != null && (
                        <div className="w-full mt-2">
                          <RecordReminder
                            fields={selectedFields}
                            setFields={setSelectedFields}
                            selected={field?.remind_at}
                            fieldKey={field?.key}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {field?.type == "attachment" && (
                    <React.Fragment>
                      <FileInput
                        id={field?.key}
                        loading={uploading == index}
                        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                          handleFileChange(
                            field?.key,
                            ev?.target?.files?.[0],
                            index
                          )
                        }
                      />

                      {field?.attachments?.length > 0 && (
                        <div className="flex flex-wrap gap-3">
                          {field?.attachments?.map((file: any, key: number) => {
                            return (
                              <div className="text-left" key={key}>
                                <Tooltip title={getAttachmentName(file?.url)}>
                                  <Chip
                                    onClick={() => handleClickOnFile(file)}
                                    label={
                                      <div>
                                        <AttachmentIcon className="text-gray-400" />
                                        <span className="ml-1">
                                          {showFileName(
                                            getAttachmentName(file?.url)
                                          )}
                                        </span>
                                      </div>
                                    }
                                    variant="outlined"
                                    onDelete={() =>
                                      onRemoveFile(field?.key, key)
                                    }
                                  />
                                </Tooltip>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </Fragment>
            ) : (
              <div className="grid w-full">
                <div className="flex items-start justify-between">
                  <TextInput
                    type={field?.type}
                    value={field?.value}
                    label={`${t("enter")} ${t(field?.label)}`}
                    placeholder={`${t("enter")} ${t(field?.label)}`}
                    onChange={(ev: any) => handleChange(ev, field?.key)}
                  />
                  {!field?.required && (
                    <div className="text-right">
                      <button
                        type="button"
                        onClick={() => onRemoveField(field)}
                        className="outline-none font-semibold text-sm text-[#33CCFF]"
                      >
                        {t("remove")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {filePreview?.show && (
        <FilePreview
          filePreview={filePreview}
          setFilePreview={setFilePreview}
        />
      )}
    </div>
  );
};

export default RecordFields;
