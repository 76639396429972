import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import { Avatar } from "@mui/material";
import userAvtar from "../../Assets/Images/userAvatar.png";
import { useTranslation } from "react-i18next";
export default function CallEndScreen({ selfCallEnd, totalJoinedUsers }) {
    const { activeCallData, rooms } = useSelector((state: any) => state.chat);
    const { t } = useTranslation()
    const { userData, formattedContact } = useSelector((state: any) => state.user);
    const roomId = activeCallData?.data?.roomId?._id
        ? activeCallData?.data?.roomId?._id
        : activeCallData?.data?.roomId;
    const GroupName = rooms?.find((item: any) => item?._id === roomId);
    const remote_user_id = activeCallData.data?.callParticipants.filter(
        (item: any) => item.userId?._id !== userData._id
    )[0]?.userId?._id;
    const remote_user_Name_Image =
        JSON.stringify(formattedContact) !== "{}"
            ? formattedContact[remote_user_id]
            : activeCallData.data?.callParticipants.filter(
                (item: any) => item.userId?._id !== userData._id
            )[0]?.userId?.phone;


    function Profile() {
        return activeCallData?.data?.roomType !== "group" &&
            activeCallData?.data?.roomType !== "contact_group" ? (
            <Avatar
                className="calling-profile-icon rounded-full"
                src={
                    remote_user_Name_Image?.userId?.profile_img
                        ? DefaultImageUrl + remote_user_Name_Image?.userId?.profile_img
                        : userAvtar
                }
            >
                {remote_user_Name_Image?.firstName || remote_user_Name_Image?.lastName
                    ? remote_user_Name_Image?.firstName.slice(0, 1) +
                    "" +
                    remote_user_Name_Image?.lastName.slice(0, 1)
                    : ""}
            </Avatar>
        ) : (
            <Avatar
                className="calling-profile-icon rounded-full"
                src={
                    GroupName !== undefined
                        ? DefaultImageUrl + GroupName.profile_img
                        : userAvtar
                }
            ></Avatar>
        );
    }

    const renderMessage = () => {
        if (
            activeCallData?.data?.roomType !== "group" &&
            activeCallData?.data?.roomType !== "contact_group"
        ) {
            return <div className="text-black mt-5 text-xl" style={{ zIndex: "120" }}>{t("call_ended")}</div>;
        } else if (
            (Object.keys(totalJoinedUsers).length === 0 ||
                Object.keys(totalJoinedUsers).length === 1) && selfCallEnd === false
        ) {
            return <div className="text-black mt-5 text-xl" style={{ zIndex: "120" }}>{t("call_ended")}</div>;
        } else {
            return <div className="text-black mt-5 text-xl" style={{ zIndex: "120" }}>{t("left_call")}</div>;
        }
    };

    return (
        <>
            <div
                className=" bg-white text-center h-full"
                style={{ zIndex: "1000" }}
            >
                <div className="ml-4 flex flex-col justify-center h-full items-center">
                    <Profile />
                    {renderMessage()}
                </div>
            </div>
        </>
    )
}