import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { emit } from "../../../Socket";
import { setScrollBottom } from "../../../Redux/UserSlice";
import { resetActiveRoomChats } from "../../../Redux/ChatSlice";

interface IProps {
  show: boolean;
  poll: any;
  setShow: (value: any) => void;
}

const DeletePoll = ({ show, setShow, poll }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state?.user);
  const { activeRoomChats, activeRoomData } = useSelector(
    (state: any) => state?.chat
  );

  const handleClose = () => setShow(false);

  const handleDelete = () => {
    const tempChats = [...activeRoomChats];

    emit("deleteChat", {
      roomId: activeRoomData?._id,
      cid: [poll],
      type: "everyone",
    });

    const updatedChats = tempChats?.map((item: any) => {
      if (poll == item?._id) {
        return {
          ...item,
          deleted: [
            {
              deleted_at: 1682680476181,
              type: "me",
              user_id: userData?._id,
            },
          ],
          message: "",
        };
      }
      return item;
    });

    dispatch(setScrollBottom(false));
    dispatch(resetActiveRoomChats(updatedChats));
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          minWidth: 500,
        },
      }}
    >
      <DialogContent>
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg">{t("deleteconfirmation")}</div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="mt-3">
          <Alert severity="error">{t("deletepolldesc")}</Alert>
        </div>
      </DialogContent>

      <DialogActions>
        <button
          onClick={handleClose}
          className="bg-gray-400 flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
        >
          {t("cancel")}
        </button>

        <button
          onClick={handleDelete}
          className="bg-red-600 flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
        >
          {t("delete")}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePoll;
