import { useEffect, useState } from "react";
import { Fade, Menu, MenuItem, Tooltip } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authCllient } from "../Graphql/authClient";
import { UPDATE_LANGUAGE } from "../Graphql/Mutations";

function FadeMenu({ language, setLanguage }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n, t } = useTranslation();
  const open = Boolean(anchorEl);
  const [loading, setloading] = useState(false);
  const { user } = useSelector((state: any) => state);
  const [flag, setFlag] = useState("");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const findLng = user?.languages?.find(
      (lng) => lng?._id == user?.userData?.language
    );
    if (findLng) {
      setLanguage(findLng?.code);
      i18n.changeLanguage(findLng?.code);
    } else {
      setLanguage("en");
      i18n.changeLanguage("en");
    }

    getSelectedFlag();
  }, [user?.userData?.language]);

  const handleClose = (lang: any) => {
    setAnchorEl(null);

    if (i18n.language.includes("en")) {
      setLanguage("en");
    } else {
      setLanguage(i18n.language);
    }
  };

  const getSelectedFlag = () => {
    const eng = user?.languages?.find((el) => el?.code == "en");
    const flagSrc =
      user?.languages?.find((el) => el?._id == user?.userData?.language) ||
      eng;

    setFlag(flagSrc?.icon);
  };

  const handleChangeLangage = async (item: any) => {
    setloading(true);
    try {
      const response = await authCllient.mutate({
        mutation: UPDATE_LANGUAGE,
        variables: {
          input: {
            language: item?._id,
          },
        },
      });

      if (response?.data?.updateUserLanguage) {
        i18n.changeLanguage(item?.code);
        setLanguage(item?.code);
        handleClose(item?.code);
        setFlag(item?.icon);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <div>
      <span>
        <Tooltip title={t("language")}>
          <div
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className=" flex flex-col-reverse  sm:flex-row items-center  justify-end sm:gap-1 p-0 "
          >
            <div className="cursor-pointer text-xs mt-1 sm:mt-0 text-fontDark dark:text-fontWhite w-6 flex items-center  sm:font-bold">
              <img src={flag} />
            </div>
            {language == "" && (
              <LanguageIcon className="text-fontDark dark:text-fontWhite  p-0" />
            )}
          </div>
        </Tooltip>
      </span>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        className="max-h-96"
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {user?.languages?.map((item: any) => (
          <MenuItem
            key={item?.code}
            disabled={Boolean(loading)}
            onClick={() => handleChangeLangage(item)}
          >
            <img src={item?.icon} className="w-4 h-4 mr-2" /> {t(item?.name)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default FadeMenu;
