import React, { useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { emit } from "../../Socket";
import { CommonInGroupTypes, ParticipantsDataTypes } from "../../Types/Types";
import { AppPath, DefaultImageUrl } from "../../Constant/Constant";
import { useNavigate } from "react-router-dom";
import { setActiveRoomData } from "../../Redux/ChatSlice";
import { useTranslation } from "react-i18next";

function GroupInComon({ setGroupInComonShow, setRoomRightDrawer }: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );

  const { activeRoomData, groupsInCommon } = useSelector(
    (state: any) => state.chat
  );

  const otherUserData = activeRoomData?.participants?.find(
    (item: any) => item?.user_id !== userData?._id
  );

  const handleNavigateRoom = (item: CommonInGroupTypes) => {
    dispatch(setActiveRoomData(item));
    /*  emit("getChatsByRoomId", { roomId: item?._id }); */
    emit("getUserMediaByRoomId", { roomId: item?._id, type: "media" });
    setRoomRightDrawer(false);
  };

  useEffect(() => {
    emit("getRoomInComon", {
      pid: otherUserData?.user_id,
    });
  }, []);

  return (
    <div>
      <div
        className="flex items-center w-full bg-gray-200 h-[60px] cursor-pointer"
        onClick={() => setGroupInComonShow(false)}
      >
        <div className="py-2 flex">
          <ArrowBackIosNewIcon />
        </div>
        <div className="pl-1">{t("back")}</div>
      </div>

      <div className="mt-4 px-4">
        {groupsInCommon?.map((item: CommonInGroupTypes) => {
          return (
            <div
              key={item?._id}
              className="flex items-center pb-4 cursor-pointer"
              onClick={() => {
                handleNavigateRoom(item);
              }}
            >
              {item?.profile_img ? (
                <img
                  src={DefaultImageUrl + item?.profile_img}
                  className="h-10 w-10 rounded-full"
                  alt="profile"
                />
              ) : (
                <div>
                  <Avatar />
                </div>
              )}
              <div className="pl-3">
                <div>{item?.name}</div>
                <div>
                  {item?.participants
                    ?.slice(0, 3)
                    ?.map((item: ParticipantsDataTypes, index: number) => {
                      let userContactDetail = formattedContact[item?.user_id];
                      return (
                        <span className="textGray text-sm mr-1" key={index}>
                          {userContactDetail
                            ? userContactDetail?.firstName +
                            " " +
                            userContactDetail?.lastName +
                            ","
                            : item?.phone + ","}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GroupInComon;
