import { DefaultImageUrl } from "../../Constant/Constant";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { incoming, outgoing } from "../../utils/Call/Callhistory";
import React, { useEffect, useState } from "react";
import { authCllient } from "../../Graphql/authClient";
import ReactLoading from "react-loading";
import { CALL_LIST_WITH_PARTICIPANTS } from "../../Graphql/Queries";
import { CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { appendOldChats, setActiveCallData, setActiveChatSkip, setActiveRoomChats, setActiveRoomData, setInitiatedCallData, setIsCallMinimized, setMemberAddedInCall, setPinnedChats, setTotoalChatsCount, updateCallStatus, updateCreateCall } from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { emit } from "../../Socket";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { isNaN } from "lodash";
import { useTranslation } from "react-i18next";
import { RoomsDataType } from "../../Types/Types";
import capitalize from "../../utils/capitalize";

const CallHistory = ({
  setHistoryDetails,
  UserDetails,
  CategoryIdData,
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [singleUserCallData, setsingleUserCallData] = useState<any>([]);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const { rooms, callisRunning, joinedFromOtherDevice, onGoingCallData, activeRoomData, isParticipantsInCall, activeCallData } = useSelector((state: any) => state.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const onGoingCallRoomData = onGoingCallData?.find((item: any) =>
    item?.roomId?._id ? item?.roomId?._id : item?.roomId === activeRoomData?._id
  );
  const userInfo = activeRoomData?.participants?.length > 1 ? activeRoomData?.participants?.find(
    (one: any) => one?.user_id !== userData?._id
  ) : activeRoomData?.participants?.length === 1 ? activeRoomData?.participants[0] : null;
  const userProfileImg = activeRoomData?.participants?.length > 0 && activeRoomData?.participants?.find((item: any) => item?.user_id === userInfo?.user_id);
  const isRoomLeft =userProfileImg?.left_at? userProfileImg?.left_at !== 0:false;
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const joinOnGoingCall = () => {
    if (
      activeCallData?.data?.channelName === onGoingCallRoomData?.channelName
    ) {
      dispatch(setIsCallMinimized(false));
      dispatch(updateCallStatus(true));
    } else {

      if (onGoingCallRoomData?.callId || onGoingCallRoomData?._id) {
        changeCallStatus({
          variables: {
            changeCallStatus: {
              callId: onGoingCallRoomData?.callId ?? onGoingCallRoomData?._id,
              status: "accepted",
              userId: userData._id,
            },
          },
        })
          .then((res) => {
            if (res?.data) {
              const data = { data: onGoingCallRoomData };
              dispatch(setActiveCallData(data));
              dispatch(setIsCallMinimized(false));
              dispatch(updateCallStatus(true));
            }
          })
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    if (CategoryIdData) {
      authCllient
        .query({
          query: CALL_LIST_WITH_PARTICIPANTS,
          variables: {
            input: {
              categoryId: CategoryIdData,
              skip: 0,
              take: 20,
            },
          },
        })
        .then((res) => {
          setsingleUserCallData(res.data.getCallListWithAParticipant);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  const ContactName = UserDetails.call?.callParticipants ?
    formattedContact[
    UserDetails?.call?.callParticipants[1]?.userId?._id !== undefined ? "" : UserDetails?.call?.callParticipants[1]?.userId?._id !== userData?._id
      ? UserDetails?.call?.callParticipants[1]?.userId?._id
      : UserDetails?.call?.callParticipants[0]?.userId?._id
    ] : "";

  const handleCallInitiate = (type: string, roomType: string) => {
    setDisabledCallButton(true);
    const participants = [];
    UserDetails?.call?.callParticipants?.forEach((item: any) => {
      participants.push(item?.userId?._id);
    });
    dispatch(updateCreateCall(true));
    dispatch(setMemberAddedInCall(participants))
    authCllient
      .mutate({
        mutation: CREATE_CALL,
        variables: {
          input: {
            origin: userData?._id,
            participants,
            type,
            roomId:roomType==="individual"&&participants.length>2?"": UserDetails?.call?.roomId?._id,
            roomType: roomType !== "group" ?roomType==="individual"&&participants.length>2?"contact_group": "individual" : roomType,
            channelName: String(new Date().getTime()),
          },
        },
      })
      .then((res) => {
        setHistoryDetails(false);
        handleChangeCallStatus(res?.data?.createCall.call?._id)
        dispatch(setInitiatedCallData(res?.data?.createCall.call));
      })
      .catch((error) => console.log("error at Create call", error))
      .finally(() => setDisabledCallButton(false));
  };

  const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {

    const item = rooms.filter((item: any) => item._id === UserDetails.call?.roomId?._id);
    dispatch(setActiveRoomChats([]));
    dispatch(appendOldChats([]));
    dispatch(setTotoalChatsCount(50));
    dispatch(setActiveChatSkip(0));
    dispatch(setPinnedChats([]))
    // emit("getChatsByRoomId", { roomId: item[0]?._id, skip: 0, limit: 50 });
    dispatch(setActiveRoomData(item[0]))
    setHistoryDetails(false);
  }

  const contactCallRoomName: any = (participants: any) => {
    let roomName = "";

    if (participants?.roomType === "group") {
      const roomData = rooms?.find((item: RoomsDataType) =>
        !participants?.roomId?._id
          ? item?._id === participants?.roomId
          : item?._id === participants?.roomId?._id
      );
      roomName = roomData?.name;
    } else if (participants?.roomType === "individual" && participants?.callParticipants?.length === 2) {
      const roomData = rooms?.find(
        (item: RoomsDataType) => item?._id === activeCallData?.data?.roomId
      );
      const participant = participants.callParticipants?.find((item: any) =>
        item?.userId
          ? item?.userId?._id !== userData?._id
          : item?.user_id !== userData?._id
      );
      const contactName = participant !== undefined ? formattedContact[participant.userId ? participant.userId._id : participant?.user_id] === undefined ? participant.userId.phone :
        formattedContact[participant.userId ? participant.userId._id : participant?.user_id]?.firstName +
        " " +
        formattedContact[participant.userId ? participant.userId._id : participant?.user_id]?.lastName : "";

      roomName = contactName ? contactName : participant?.phone;
    } else if ((participants?.roomType === "individual" && participants?.callParticipants?.length > 2) || participants?.roomType === "contact_group" || participants?.roomType === "contact") {
      const otherUsers = participants?.callParticipants.filter(
        (item: any) => item?.userId?._id !== userData?._id
      );
      const phone = otherUsers[0]?.userId?.phone;
      const contactNameOne =
        formattedContact[otherUsers[0].userId ? otherUsers[0].userId._id : otherUsers[0]?.user_id] === undefined ? otherUsers[0].userId.phone :
          formattedContact[otherUsers[0]?.userId._id]?.firstName +
          " " +
          formattedContact[otherUsers[0]?.userId._id]?.lastName
      // otherUsers[0]?.userId?.firstName?otherUsers[0]?.userId?.firstName +
      //     " " +
      //     otherUsers[0]?.userId?.lastName
      //   : otherUsers[0]?.userId?.phone;

      const contactNameTwo = formattedContact[otherUsers[1]?.userId ? otherUsers[1]?.userId._id : otherUsers[1]?.user_id] === undefined ? otherUsers[1]?.userId?.phone :
        formattedContact[otherUsers[1]?.userId._id]?.firstName +
        " " +
        formattedContact[otherUsers[1]?.userId._id]?.lastName;

      if (otherUsers?.length === 2) {
        roomName = contactNameOne + ", " + contactNameTwo;
      } else if (otherUsers?.length === 1) {
        roomName = contactNameOne ? contactNameOne : phone;
      } else {
        roomName = contactNameOne + ", " + contactNameTwo + " " + t('and_others');
      }
    }

    return roomName;
  };



  return (
    <div>
      {!loading ? (
        <div className="h-screen overflow-y-scroll">
          <div className="mx-4">
            <span
              onClick={() => {
                setHistoryDetails(false);
              }}
            >
              <ArrowBackIosIcon />
            </span>
            {t("call_history")}
          </div>
          {UserDetails?.call?.roomType !== "contact_group" && (UserDetails.call?.roomType === "group"
            ? (
              <img
                src={DefaultImageUrl + UserDetails?.call?.roomId?.profile_img}
                className="mx-auto my-2  w-full h-72 object-contain"
                alt={t('profile')}
              />
            ) : UserDetails?.call?.callParticipants[1]?.userId?._id !== userData?._id ? (
              <img
                alt={t('profile')}
                src={
                  DefaultImageUrl +
                  UserDetails?.call?.callParticipants[0]?.userId?.profile_img
                }
                className="mx-auto my-2  w-full h-72 object-contain"
              />
            ) : (
              <img
                alt={t('profile')}
                src={
                  DefaultImageUrl +
                  UserDetails?.call?.callParticipants[0]?.userId.profile_img
                }
                className="mx-auto my-2  w-full h-72 object-contain"
              />
            ))}
          <div className="bg-[#F3F9FC] p-2 flex justify-between">
            <div className="my-auto">
              {contactCallRoomName(UserDetails?.call)}
            </div>
            {!isRoomLeft &&
             <div className="flex gap-2 my-auto">
              {UserDetails?.call?.roomType !== "contact_group" && <img
                src={require("../../Assets/Images/SendMsg.png")}
                className="h-8 w-8 cursor-pointer"
                alt={t('message')}
                onClick={() => { handleClick() }}
              />}
              {(UserDetails?.call?.roomType !== "contact_group"||(UserDetails?.call?.roomType==='individual'&&UserDetails?.call?.callParticipants.length>2)) && (
               isParticipantsInCall.length>0&& isParticipantsInCall?.includes(UserDetails?.call?.roomId?._id) ? (
                  UserDetails.call?.roomType === "group" ? (
                    <button
                      className="flex items-center ml-4 bg-[#33ccff] text-white w-fit py-1 px-2 text-center text-sm rounded-md cursor-pointer disabled:opacity-60"
                      onClick={joinOnGoingCall}
                      disabled={callisRunning ? true : false}
                    >
                      <CallOutlinedIcon className="mr-2" />
                      {callisRunning ? t("joining") : t("join_call")}
                    </button>
                  ) : (
                    ""
                  ))
                  : <>
                    <button
                      disabled={disableCallButton ? true : false}
                      onClick={() => {
                        if (!callisRunning && !joinedFromOtherDevice) {
                          handleCallInitiate("audio", UserDetails?.call?.roomType);
                        } else {
                          alert(
                            t("call_validation")
                          );
                        }
                      }}
                    >
                      <img
                        alt='call'
                        src={require("../../Assets/Images/call.png")}
                        className="h-8 w-8 cursor-pointer"
                      />
                    </button>
                    <button
                      disabled={disableCallButton ? true : false}
                      onClick={() => {
                        if (!callisRunning && !joinedFromOtherDevice) {
                          handleCallInitiate("video", UserDetails?.call?.roomType);
                        } else {
                          alert(
                            t("call_validation")
                          );
                        }
                      }}
                    >
                      <img
                        alt='video-call'
                        src={require("../../Assets/Images/videoCall.png")}
                        className="h-8 w-8 cursor-pointer"
                      />
                    </button>
                  </>
              )}
            </div>}
          </div>
          {UserDetails?.call?.roomType !== "group" &&
            UserDetails?.call?.roomType !== "contact_group" && UserDetails.call?.callParticipants.length === 2 && (
              <div className="p-2 py-3">
                {t("last_seen")}
                <span className="ml-1"> {moment(singleUserCallData[0]?.origin?.lastSeen).format("HH:mm")}</span>
              </div>
            )}
          <div className="">
            {singleUserCallData?.length > 0 &&
              singleUserCallData?.map((item: any, key: any) => {
                var prevDate = moment(
                  singleUserCallData[key - 1]?.callStartedAt
                )?.format("DD-MM-YYYY");
                var currentDate = moment(item?.callStartedAt).format(
                  "DD-MM-YYYY"
                );
                var isToday =
                  currentDate === moment(new Date()).format("DD-MM-YYYY");
                var isYesterday =
                  currentDate ===
                  moment().subtract(1, "d").format("DD-MM-YYYY");
                const initiator =
                  item?.origin?._id === userData?._id ? true : false;
                const status = item?.callParticipants[1]?.callStatus;
                const callStatus = initiator
                  ? outgoing[status]
                  : incoming[status];
                const duration = moment.duration(item?.duration);
                const hoursD = duration.hours().toString().padStart(2, "0");
                const minutesD = duration.minutes().toString().padStart(2, "0");
                const secondsD = duration.seconds().toString().padStart(2, "0");
                const formattedTime = `${(Number(hoursD) > 0 && hoursD + `${t('hour')}  ,`) || ""
                  }${(Number(minutesD) > 0 && minutesD + `${t('minutes')}  ,`) || ""} ${(Number(secondsD) > 0 && secondsD + ` ${t('second')}`) || ""
                  }`;
                return (
                  <div>
                    {(prevDate !== currentDate || key === 0) && (
                      <div
                        className={`${UserDetails?.call?.roomType !== "group" &&
                          UserDetails?.call?.roomType !== "contact_group" &&
                          "bg-[#F3F9FC]"
                          }
                     my-2 p-2`}
                      >
                        {isToday
                          ? t("today")
                          : isYesterday
                            ? t("yesterday")
                            : currentDate}
                      </div>
                    )}
                    <div
                      className={`grid ${(UserDetails?.call?.roomType === "group" ||
                          UserDetails?.call?.roomType === "contact_group") &&
                        "bg-[#F3F9FC]"
                        } grid-cols-5 py-2 p-3`}
                    >
                      <div className="col-span-1 ">
                        <div className="mt-1">
                          {moment(item?.callStartedAt).format("HH:mm")}
                        </div>
                      </div>
                      <div className="col-span-3 m-2">
                        <div className="flex gap-2">
                          <div className="text-xs capitalize">
                            <div className="flex gap-2">
                              <img
                                src={
                                  UserDetails?.call?.type === "video"
                                    ? callStatus?.videoIcon
                                    : callStatus?.audioIcon
                                }
                                className={`${UserDetails?.call?.type === "video"
                                    ? "h-[14px]  w-5"
                                    : "h-[17px] w-[17px]"
                                  } my-auto mt-1`}
                                alt='icon'
                              />
                              <div>{t(callStatus?.msg?.toUpperCase())} {t("call")}</div>
                            </div>
                            <div className="mx-6 my-1">{formattedTime}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(UserDetails.call?.roomType === "group" ||
                      UserDetails.call?.roomType === "contact_group" || (UserDetails.call?.roomType === 'individual' && UserDetails.call?.callParticipants.length > 2)) && (
                        <div>
                          {item?.callParticipants.map((data: any) => {
                            console.log(item?.callParticipants,formattedContact[data?.userId?._id],JSON.stringify(formattedContact)!=="{}")
                            const moment1 = moment(
                              data?.callHistory[0]?.callJoinedAt
                            );
                            const moment2 = moment(
                              data?.callHistory[0]?.callEndedAt
                            );
                            const duration = moment.duration(
                              moment2.diff(moment1)
                            );
                            const hours = `${Math.floor(
                              duration.asHours()
                            )} hours, `;
                            const minutes = !isNaN(duration.minutes()) ? `${duration.minutes()} ${t('minutes')}, ` : `0 ${t('minutes')}`;
                            const seconds = !isNaN(duration.seconds()) ? `${duration.seconds()} ${t('second')}` : `0 ${t('second')}`;
                            const formattedDuration = `${(Number(hours) > 0 && hours) || ""
                              } ${minutes} ${seconds}`;
                            const User_call_status =
                              UserDetails?.origin?._id === userData?._id
                                ? outgoing[data.callStatus]
                                : incoming[data.callStatus];

                            return (
                              <div>
                                <div className="w-full p-3">
                                  <div className="flex justify-between">
                                    <div className=" gap-4 flex">
                                      <img
                                        src={
                                          DefaultImageUrl +
                                          data?.userId?.profile_img
                                        }
                                        className="h-[45px] w-[45px] rounded-full"
                                        alt='profile'
                                      />
                                      <div className="my-auto">
                                        <div>
                                          {data?.userId?._id === userData?._id
                                            ? t("you")
                                            :`${JSON.stringify(formattedContact)!=="{}"?formattedContact[data?.userId?._id]?.firstName?formattedContact[data?.userId?._id]?.firstName:data?.userId?.phone: data?.userId?.phone} ${JSON.stringify(formattedContact)!=="{}"?formattedContact[data?.userId?._id]?.lastName?formattedContact[data?.userId?._id]?.lastName:'':""}` 
                                           }
                                        </div>
                                        <div>{formattedDuration}</div>
                                      </div>
                                    </div>
                                    <div className="flex gap-2">
                                      <div className="my-auto">
                                        <img
                                          src={
                                            UserDetails?.call?.type === "video"
                                              ? User_call_status?.videoIcon
                                              : User_call_status?.audioIcon
                                          }
                                          className={`${UserDetails?.call?.type === "video"
                                              ? "h-4  w-5"
                                              : "h-5 w-5"
                                            } my-auto`}
                                          alt='icon'
                                        />
                                      </div>
                                      {/* <div>{User_call_status.msg}</div> */}
                                      <div className="capitalize my-auto">
                                        {t(data?.callStatus?.toUpperCase())}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="flex my-20 h-screen justify-center">
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color="#33ccff"
          />{" "}
        </div>
      )}
    </div>
  );
};

export default CallHistory;
