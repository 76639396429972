import { Avatar, Menu } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import MessageInfo from "./MessageInfo";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { MessagesActionDataTypes } from "../../Types/Types";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { emit, socketListen } from "../../Socket";
import { ParticipantsDataTypes, RoomsDataType } from "../../Types/Types";
import { setScrollBottom } from "../../Redux/UserSlice";
import {
    appendOldChats,
    setActiveChatSkip,
    setActiveRoomChats,
    setActiveRoomData,
    setTotoalChatsCount,
} from "../../Redux/ChatSlice";
import { useMutation } from "@apollo/client";
import { CREATE_ROOM } from "../../Graphql/Mutations";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosShareAlt } from "react-icons/io";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Toast from "../../coreComponent/Toast";
import { useTranslation } from "react-i18next";
import messageSenderInfo from "../../utils/messageSenderInfo";
const ContactFilePreview = ({
    showDate,
    profileShown,
    showDateforoutgoing,
    item,
    setMessages,
    messages,
    setChangeFooter,
    setShowCheckbox,
    showCheckbox,
    setReplyFooterShow,
    setReplyMessage,
    setMessageInfoItem,
    setMessageInfoDrawerShow,
}: any) => {
    const [showMessageActions, setShowMessageActions] = useState(false);
    const [activeFav, setActiveFav] = useState<any>({});
    const [showArrow, setshowArrow] = useState({ status: false, id: null });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const anchorRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const MessageAction = MessageActions(t);
    const [toastShow, setToastShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isRoomCreating, setIsRoomCreating] = useState(false);
    const [createRoom, { data }] = useMutation(CREATE_ROOM);
    const [roomsData, setRoomsData] = useState<any>([]);
    const { userData, userContacts } = useSelector((state: any) => state.user);
    const { formattedParticipantsOfActiveRoom, activeRoomChats, rooms, activeRoomData, pinnedChats } = useSelector((state: any) => state.chat);
    const deleted =
        item?.deleted?.filter((element: any) => element.user_id === userData?._id)?.length > 0 ? false : true;
    const gettingMessage = item?.message?.includes("deleted") ? item?.message : JSON.parse(item?.message);
    const isEveryoneCanPinMessage =
        activeRoomData?.type === "group" &&
        activeRoomData?.access?.some(
            (item: any) => item?.type === "pinMessage" && item?.permit === "common"
        );

    const canYouPinMessage =
        (!isEveryoneCanPinMessage &&
            formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
            "admin") ||
        isEveryoneCanPinMessage;
    const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
    let messageTime = moment(item?.created_at).format("HH:mm");

    let userDetails = userContacts?.find((item: any) => item?.userId?._id === senderInfo?.user_id);

    const messageSender = messageSenderInfo(item.sender, activeRoomData?.participants)

    const isUserBlocked = activeRoomData?.participants?.find((el: any) => el?.left_at !== 0 && el?.user_id === userData?._id);

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setShowMessageActions(false);
        } else if (event.key === "Escape") {
            setShowMessageActions(false);
        }
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        // setshowArrow({ status: false, id: null });
        setAnchorEl(null);

    };

    useEffect(() => {
        document.addEventListener('click', handleClose);

        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, []);

    const handleMessageAction = (action: string, item: any) => {
        setShowMessageActions(false);
        if (action === "forward" || action === "delete") {
            handleMessageSelect(true, item)
            setChangeFooter(true);
            setShowCheckbox(true);
        } else {
            setChangeFooter(false);
            setShowCheckbox(false);
        }

        if (action === "favourite") {
            emit("addChatsToFavourite", {
                roomId: activeRoomData?._id,
                cid: [item?._id],
            });
        } else if (action === "unFavourite") {
            emit("removeChatsFromFavourite", {
                roomId: activeRoomData?._id,
                cid: [item?._id],
            });
        }

        if (action === "reply") {
            setTimeout(() => {
                document.getElementById('message-input').focus()
            }, 0)
            setReplyMessage(item);
            setReplyFooterShow(true);
        }

        if (action === "info" && item?.sender === userData?._id) {
            setMessageInfoItem(item);
            setMessageInfoDrawerShow(true);
        }
        if (action === "pin") {
            if (canYouPinMessage) {
                if (pinnedChats?.length < 3) {
                    emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

                    socketListen("message", (data: any) => {
                        if (data.type === "pinChat") {
                            setToastShow(true);
                            setToastMessage(t("chat_pinned"));
                        }
                    });
                } else {
                    setToastMessage(t("chat_pin_validation"));
                    setToastShow(true);
                }
            } else {
                setToastMessage(t("chat_pin_admin"));
                setToastShow(true);
            }
        }
    };

    const handleMessageSelect = (e: any, item: any) => {
        let temp = [...messages.msgId];
        let tempMsgId = [...messages.senderId];
        let tempTime = [...messages.time];

        if (e) {
            temp.push(item?._id);
            tempMsgId.push(item?.sender);
            tempTime.push(item?.created_at);
        } else {
            temp = temp.filter((elem: any) => elem !== item?._id);
            tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
            tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
        }
        setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
    };

    const handleCreateRoom = (userId: string) => {
        console.log('==========', userId)
        const initiatedRoom = rooms?.find(
            (item: RoomsDataType) =>
                item?.type === "individual" &&
                item?.participants?.find((ele: ParticipantsDataTypes) => ele?.user_id === userId)
        );

        if (initiatedRoom) {
            dispatch(setScrollBottom(true));
            dispatch(setActiveRoomData(initiatedRoom));
            /*   emit("getChatsByRoomId", {
                  roomId: initiatedRoom?._id,
                  skip: 0,
                  limit: 50,
              }); */
            dispatch(setActiveRoomChats([]));
            dispatch(appendOldChats([]));
            dispatch(setTotoalChatsCount(50));
            dispatch(setActiveChatSkip(0));
            emit("getUserMediaByRoomId", {
                roomId: initiatedRoom?._id,
                type: "media",
            });
        } else {
            setIsRoomCreating(true);
            createRoom({
                variables: {
                    input: {
                        type: "individual",
                        users: [userId],
                        name: null,
                        profile_img: null,
                        localId: "0",
                    },
                },
            }).then((res) => {
                emit("GetAllRooms");
                setTimeout(() => {
                    socketListen("message", (data: any) => {
                        if (data.type === "GetAllRooms") {
                            const filteredData = data?.msg?.rooms.filter((item: any) => item?.organization === "")
                            setRoomsData(filteredData);
                        }
                    });
                }, 1000);
            });
        }
    };

    useEffect(() => {
        const createdRoomData = roomsData?.find((item: any) => item?._id === data?.createRoom?.roomId);

        if (createdRoomData) {
            setIsRoomCreating(false);

            dispatch(setScrollBottom(true));
            dispatch(setActiveRoomData(createdRoomData));
            /*  emit("getChatsByRoomId", {
                 roomId: createdRoomData?._id,
                 skip: 0,
                 limit: 50,
             }); */
            dispatch(setActiveRoomChats([]));
            dispatch(appendOldChats([]));
            dispatch(setTotoalChatsCount(50));
            dispatch(setActiveChatSkip(0));
            emit("getUserMediaByRoomId", {
                roomId: createdRoomData?._id,
                type: "media",
            });
        }
    }, [roomsData?.length > 0]);

    return item?.sender === userData?._id ? (
        <div className="flex items-center">
            <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
            {showCheckbox && deleted && (
                <div className="h-[20px] w-[20px]">
                    <input
                        type="checkbox"
                        name="message"
                        className="mr-2 cursor-pointer h-[20px] w-[20px]"
                        checked={messages?.msgId.includes(item._id)}
                        onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
                    />
                </div>
            )}

            <div className="w-max ml-auto max-w-[400px]" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
                onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>

                <div className="flex items-start">
                    {showArrow.status && showArrow.id === item?._id && deleted &&
                        <div className="" onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setActiveFav(item);
                        }} ref={anchorRef}>
                            <MoreVertIcon className=" cursor-pointer" sx={{ color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined} />
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => { setAnchorEl(null); }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >

                                {MessageAction.filter((elem: MessagesActionDataTypes) =>
                                    activeRoomData?.type === "individual" ? elem?.action !== "pin" && elem?.action !== "favourite" : elem
                                ).map((el: MessagesActionDataTypes, index: number) => {
                                    const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                                    return pinchat.length > 0 && el?.action === "pin" ? (
                                        ""
                                    ) : activeFav?.favourite_by?.length > 0 &&
                                        el?.action === "favourite" ? (
                                        ""
                                    ) : activeFav?.favourite_by?.length === 0 &&
                                        el?.action === "unFavourite" ? (
                                        ""
                                    ) : (activeFav?.type === "contact" && el?.action === "copy") || el?.action === "heading" ? (
                                        ""
                                    ) : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                                        ? ""
                                        : (
                                            <MenuItem
                                                onClick={() => handleMessageAction(el.action, item)}
                                                key={index}
                                                sx={{ fontSize: "14px" }}
                                            >
                                                {el.ui}
                                            </MenuItem>
                                        );
                                })}
                            </Menu>

                        </div>}
                    <div className=" ">


                        <div>
                            <div className="flex items-end gap-2 relative group" ref={anchorRef}>
                                <div>
                                    <div onContextMenu={e => {
                                        if (!item?.message?.includes("You deleted this message")) {
                                            e.preventDefault();
                                            setAnchorEl(e.currentTarget);
                                            setShowMessageActions(true);
                                            setActiveFav(item);
                                        }
                                    }} className={`rounded-md ${gettingMessage?._id === undefined ? "" : 'min-h-[130px]'} mb-1 rounded-t-xl rounded-bl-xl bg-[#E0FAFF] `}>
                                        <div
                                            className={`flex  pt-2 px-2 ${item.isForwarded ? "justify-between" : "justify-end"
                                                }`}
                                        >
                                            {item?.isForwarded && (
                                                <div className="flex">
                                                    <IoIosShareAlt className="mt-1 mx-1" />
                                                    <div className="text-sm textGray text-right">{t("forwarded")}</div>
                                                </div>
                                            )}
                                            {showDateforoutgoing && (
                                                <div className="textGray text-[13px] pr-2">{messageTime}</div>
                                            )}
                                        </div>
                                        {gettingMessage?._id === undefined ? (
                                            <div className=" italic px-3 pt-1 text-[14px]">
                                                {gettingMessage.replace("You deleted this message", t("you-message-deleted"))}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="px-4 py-4 flex gap-3 items-center border-b">
                                                    <div className="h-[45px] w-[45px] min-w-[45px]">
                                                        <img
                                                            src={DefaultImageUrl + gettingMessage?.profile_img}
                                                            alt=""
                                                            className="rounded-full h-full w-full"
                                                        />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        <span> {gettingMessage?.firstName} </span>
                                                        <span className="ml-1"> {gettingMessage?.lastName} </span>
                                                        <div className="text-base font-normal">{gettingMessage?.phone}</div>
                                                    </div>
                                                </div>
                                                {gettingMessage?._id && (
                                                    <div
                                                        className="cursor-pointer text-black text-lg flex w-fit mx-auto py-2"
                                                        onClick={() => handleCreateRoom(gettingMessage?._id)}
                                                    >
                                                        {t("msg")}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {item?.favourite_by?.length > 0 && (
                                            <div>
                                                <StarIcon
                                                    sx={{ fontSize: 14 }}
                                                    className="text-black mt-1 me-1"
                                                />
                                            </div>
                                        )}
                                    </div>

                                </div>


                                {item.isSent && item.read_by.length > 0 && item.receipts ? <></> : <div className="flex items-end justify-end">
                                    {item.isSent && item.delivered_to.length > 0 ? <DoneAllIcon className="text-gray-400" sx={{ width: 18, height: 18 }} /> : <DoneIcon className="text-gray-400" sx={{ width: 18, height: 18 }} />}
                                </div>}
                            </div>
                        </div>

                    </div>
                </div>
                {item.receipts && activeRoomChats[activeRoomChats.length - 1]?._id === item._id && item.isSent && item.read_by.length > 0 && <div
                    className={` ml-auto mb-2  ${activeRoomData?.type === "group" ? 'w-12' : "w-fit ml-[5px]"} flex gap-1 items-end self-end justify-end`}
                >
                    {<MessageInfo item={item} />}
                </div>
                }
            </div>
        </div>
    ) : (
        <div className="flex">
            <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
            {showCheckbox && deleted && (
                <input
                    type="checkbox"
                    name="message"
                    className="mr-2 cursor-pointer h-[20px] w-[20px]"
                    checked={messages?.msgId.includes(item._id)}
                    onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
                />
            )}

            <div>
                <div className="w-max flex pr-4 mr-auto  max-w-[400px]" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
                    onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>
                    <div className="min-w-[35px]">
                        {/* {showDate &&
                            (senderInfo?.profile_img !== "" && !isUserBlocked ? (
                                <img
                                    src={DefaultImageUrl + senderInfo?.profile_img}
                                    alt="asas"
                                    className="h-[35px] w-[35px] rounded-full"
                                />
                            ) : (
                                <div className="">
                                    <Avatar sx={{ width: "35px", height: "35px" }} />
                                </div>
                            ))} */}

                        {showDate &&
                            (messageSender?.profile !== "" && !isUserBlocked ? (
                                <img
                                    src={DefaultImageUrl + messageSender?.profile}
                                    alt="asas"
                                    className="h-[35px] w-[35px] rounded-full"
                                />
                            ) : (
                                <div className="">
                                    <Avatar sx={{ width: "35px", height: "35px" }} />
                                </div>
                            ))}
                    </div>


                    <div className="flex items-start">
                        <div className="py-[6px] mx-2">
                            <div className="flex items-center gap-2 group relative" ref={anchorRef}>
                                <div>
                                    <div onContextMenu={e => {
                                        if (!item?.message?.includes("This message was deleted")) {
                                            e.preventDefault();
                                            setAnchorEl(e.currentTarget);
                                            setShowMessageActions(true);
                                            setActiveFav(item);
                                        }
                                    }} className={`rounded-md ${gettingMessage?._id === undefined ? "" : 'min-h-[130px]'}  pt-4 bg-[#F4F3F4]  rounded-tr-xl `}>
                                        <div className="flex justify-between gap-3 px-4">
                                            <div className="flex text-sm">
                                                {showDate && messageSender?.name}
                                                {/* {showDate &&
                                                    (userDetails &&
                                                        (userDetails?.firstName !== "" || userDetails?.lastName !== "")
                                                        ? userDetails?.firstName + " " + userDetails?.lastName
                                                        : senderInfo?.phone)} */}
                                                {item?.isForwarded && (
                                                    <div className="flex">
                                                        <IoIosShareAlt className="mt-1 mx-1" />
                                                        <div className="text-sm textGray text-right">{t("forwarded")}</div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="textGray text-[13px] pr-2">{showDate && messageTime}</div>
                                        </div>
                                        {gettingMessage?._id === undefined ? (
                                            <div className=" italic px-3 pt-1 text-[14px]">
                                                {gettingMessage.replace("This message was deleted", t("this-message-deleted"))}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="px-4 py-4 flex gap-3 items-center border-b">
                                                    <div className="h-[45px] w-[45px] min-w-[45px]">
                                                        <img
                                                            src={DefaultImageUrl + gettingMessage?.profile_img}
                                                            alt=""
                                                            className="rounded-full h-full w-full"
                                                        />
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        <span> {gettingMessage?.firstName} </span>
                                                        <span className="ml-1"> {gettingMessage?.lastName} </span>
                                                        <div className="text-base font-normal">{gettingMessage?.phone}</div>
                                                    </div>
                                                </div>
                                                {gettingMessage?._id !== undefined && gettingMessage?._id !== null && (
                                                    <div
                                                        className="cursor-pointer text-black text-sm flex w-fit mx-auto py-2"
                                                        onClick={() => handleCreateRoom(gettingMessage?._id)}
                                                    >
                                                        {t("msg")}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <div>
                                            {item?.favourite_by?.length > 0 && (
                                                <div className='flex justify-end'>
                                                    <StarIcon
                                                        sx={{ fontSize: 14 }}
                                                        className="text-black mt-1 me-1"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                        {showArrow.status && showArrow.id === item?._id && gettingMessage?._id !== undefined &&
                            <div className="" onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setActiveFav(item);
                            }} ref={anchorRef}>
                                <MoreVertIcon className=" cursor-pointer" sx={{ color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined} />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => { setAnchorEl(null); }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {MessageAction.filter((elem: MessagesActionDataTypes) =>
                                        activeRoomData?.type === "individual" ? elem?.action !== "pin" && elem.action !== 'info' && elem?.action !== "favourite"
                                            : elem.action !== 'info'
                                    ).map((el: MessagesActionDataTypes, index: number) => {
                                        const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                                        return pinchat.length > 0 && el?.action === "pin" ? (
                                            ""
                                        ) : activeFav?.favourite_by?.length > 0 &&
                                            el?.action === "favourite" ? (
                                            ""
                                        ) : activeFav?.favourite_by?.length === 0 &&
                                            el?.action === "unFavourite" ? (
                                            ""
                                        ) : (activeFav?.type === "contact" && el?.action === "copy") || el?.action === "heading" ? (
                                            ""
                                        ) : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                                            ? ""
                                            : (
                                                <MenuItem
                                                    onClick={() => handleMessageAction(el.action, item)}
                                                    key={index}
                                                    sx={{ fontSize: "14px" }}
                                                >
                                                    {el.ui}
                                                </MenuItem>
                                            );
                                    })}
                                </Menu>

                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactFilePreview;
