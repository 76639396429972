import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "../../Assets/Images/search.png";
import { useDispatch, useSelector } from "react-redux";
import { emit } from "../../Socket";
import { useState } from "react";
import ReactLoading from "react-loading";
import { ActiveRoomChatsType, ParticipantsDataTypes } from "../../Types/Types";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { DefaultImageUrl } from "../../Constant/Constant";
import {
  appendOldChats,
  appendPreviousChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomsChats,
  setCurrentPage,
  setFocusedData,
  setTotalPage,
} from "../../Redux/ChatSlice";
import { Avatar } from "@mui/material";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { refreshTokenCllient } from "../../Graphql/authClient";
import { GET_CHATS_BY_INDEX_ID } from "../../Graphql/Queries";
import { getSession } from "../../utils/session";

const SearchChatTab = ({ setSearchTabShow, setRoomRightDrawer }: any) => {
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState<any>("");
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const {
    activeRoomData,
    searchChatsResult,
    formattedParticipantsOfActiveRoom,
    activeRoomChats,
    activeChatsLimit,
  } = useSelector((state: any) => state.chat);

  const [debounceTime, setDebounceTime] = useState(null);

  const { formattedContact, userData } = useSelector(
    (state: any) => state.user
  );

  const userDetail = activeRoomData?.participants?.find(
    (item: ParticipantsDataTypes) => item?.user_id !== userData?._id
  );

  const userContactDetail = formattedContact[userDetail?.user_id];

  const FormattedText: any = (message: any) => {
    const regex =
      /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

    const formattedText = message?.replace(
      regex,
      (
        match: any,
        p1: any,
        p2: any,
        p3: any,
        p4: any,
        p5: any,
        p6: any,
        p7: any,
        p8: any
      ) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        } else if (p5) {
          return `<div style="">` + p5 + "</div>";
        } else if (p6) {
          return (
            `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
            p6 +
            "</a>"
          );
        } else if (p8) {
          const participant = activeRoomData?.participants?.find(
            (part) => part?.user_id == p8
          );
          const self = userData?._id == participant?.user_id;
          const formatPart = formattedContact?.[participant?.user_id];
          const mentionedName = self
            ? `${t("you")} `
            : formatPart?.firstName
            ? formatPart?.firstName + " " + formatPart?.lastName
            : participant
            ? participant?.phone
            : p7;
          return (
            '<span style="color: green;">' + "@" + mentionedName + "</span>"
          );
        }
        return match;
      }
    );
    return formattedText;
  };

  const handleChatSearch = (query: string) => {
    if (debounceTime) {
      clearTimeout(debounceTime);
    }

    setSearch(query);
    if (query) {
      setIsChatLoading(true);
      setSearchedQuery(query);

      setDebounceTime(
        setTimeout(() => {
          emit("searchChatsByRoomId", {
            roomId: activeRoomData?._id,
            input: query,
            skip: 0,
            limit: 500,
          });
        }, 1000)
      );

      setTimeout(() => {
        setIsChatLoading(false);
      }, 1000);
    } else {
      setIsChatLoading(false);
      setSearchedQuery("");
    }
  };

  const handleRedirectOnMessage = (message: ActiveRoomChatsType) => {
    const totalChats = activeRoomData.totalChats;

    const skip = totalChats - message?.__v - 25;

    const value = skip < 0 ? 0 : skip;

    if (
      message?.__v < activeRoomChats[activeRoomChats?.length - 1]?.index &&
      message?.__v >= activeRoomChats[0]?.index
    ) {
      dispatch(setFocusedData({ status: true, message_id: message?.__v }));
      setSearchTabShow(false);
      setRoomRightDrawer(false);
      setSearch("");
      document.getElementById(`message-index-${message?.__v}`).scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      document.getElementById(
        `message-index-${message?.__v}`
      ).style.backgroundColor = "#87d3e2f5";
      document.getElementById(
        `message-index-${message?.__v}`
      ).style.paddingBottom = "10px";
      document.getElementById(
        `message-index-${message?.__v}`
      ).style.transition = "background-color " + 2 + "s ease-in-out";

      setTimeout(() => {
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.backgroundColor = "";
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.paddingBottom = "0px";
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.transition = "none";
      }, 1600);
    } else {
      setRoomRightDrawer(false);
      setSearch("");
      dispatch(setFocusedData({ status: true, message_id: message?.__v }));
      const skip =
        activeChatsLimit * (Math.floor(message.__v / activeChatsLimit) + 1);
      dispatch(setActiveChatSkip(skip));
      dispatch(setCurrentPage(Math.floor(message.__v / activeChatsLimit) + 1));
      //dispatch(setTotalPage(Math.ceil(activeRoomData?.totalChats / activeChatsLimit)))
      const { token } = getSession();
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_INDEX_ID,
          variables: {
            input: {
              roomId: activeRoomData?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index: message?.__v,
              limit: activeChatsLimit, // custom limit
            },
          },
        })
        .then((res) => {
          dispatch(appendOldChats([]));
          dispatch(setActiveRoomChats([]));
          console.log(activeRoomChats, res);
          if (res.data.getChatsByIndex.length > 0) {
            setRoomRightDrawer(false);
            setSearch("");
            dispatch(setActiveRoomsChats(res?.data?.getChatsByIndex));
          }
          setTimeout(() => {
            if (
              message.__v !== null &&
              document.getElementById(`message-index-${message?.__v}`)
            ) {
              console.log(message, "message*************");
              /* document
                .getElementById(`message-index-${message?.__v}`)
                .scrollIntoView({ block: "center", behavior: "auto" }) */
              document
                .getElementById(`message-index-${message?.__v}`)
                .scrollIntoView({ block: "center", behavior: "smooth" });
              document.getElementById(
                `message-index-${message?.__v}`
              ).style.backgroundColor = "#e0e0e0";
              document.getElementById(
                `message-index-${message?.__v}`
              ).style.paddingBottom = "10px";
              document.getElementById(
                `message-index-${message?.__v}`
              ).style.transition = "background-color " + 2 + "s ease-in-out";

              setTimeout(() => {
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.backgroundColor = "";
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.paddingBottom = "0px";
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.transition = "none";
                //dispatch(setFocusedData({ status: false, __v:null }))
              }, 2800);
            }
          }, 2000);
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        });

      /*  dispatch(appendOldChats([]));
       dispatch(setActiveRoomChats([])); */
    }
  };
  const sortedArray = [...searchChatsResult].sort(
    (a, b) =>
      new Date(Number(b.created_at)).getDate() -
      new Date(Number(a.created_at)).getDate()
  );
  return (
    <div>
      <div className="flex py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setSearchTabShow(false)}
        >
          <div>
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
          </div>
          <div className="pt-[1px]">{t("back")}</div>
        </div>
      </div>

      <div className="px-4 w-full mt-4">
        <div className="bg-gray-100 flex items-center gap-2 w-full rounded-md border p-2">
          <div className="w-[35px]">
            <img src={SearchIcon} alt="" className="w-[35px] h-[30px]" />
          </div>
          <div className="w-full">
            <input
              type="text"
              autoFocus={true}
              className="outline-none rounded-md w-full bg-gray-100 text-gray-600"
              placeholder={t("search_message")}
              onChange={(e) => handleChatSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4 textGray text-center">
          {t("search_messages")}
          <span className="ml-1">
            {activeRoomData?.type === "group" ||
            activeRoomData?.type === "broadcast"
              ? activeRoomData?.name
              : userContactDetail
              ? userContactDetail?.firstName + " " + userContactDetail?.lastName
              : userDetail?.phone}
          </span>
        </div>

        {searchedQuery !== "" && (
          <div className="mt-4 flex justify-center">
            {isChatLoading ? (
              <div>
                <ReactLoading
                  type="bubbles"
                  width={80}
                  height={80}
                  color="#33ccff"
                />
              </div>
            ) : sortedArray?.length > 0 ? (
              <div className="w-full">
                {sortedArray?.map(
                  (item: ActiveRoomChatsType, index: number) => {
                    const time = moment(item?.created_at).format(
                      "DD.MM.YYYY HH:mm"
                    );

                    const senderContactDetails = formattedContact[item?.sender];
                    const senderDetails =
                      formattedParticipantsOfActiveRoom[item?.sender];

                    return typeof parse(FormattedText(item?.message)) ==
                    "string" && (
                      <div
                        key={index}
                        className={`my-6 flex gap-3 cursor-pointer `}
                        onClick={() => handleRedirectOnMessage(item)}
                      >
                        {" "}
                        <div className="h-[45px] w-[45px] min-w-[45px]">
                          {senderDetails?.profile_img !== "" ? (
                            <img
                              src={DefaultImageUrl + senderDetails?.profile_img}
                              alt=""
                              className="h-full w-full rounded-full"
                            />
                          ) : (
                            <Avatar sx={{ height: 45, width: 45 }} />
                          )}
                        </div>
                        <div
                          className={`w-full ${
                            userData?._id === item?.sender
                              ? "bg-[#E0FAFF]"
                              : "bg-[#F4F4F4]"
                          } py-4 px-3 rounded-t-xl rounded-bl-xl`}
                        >
                          <div className="flex justify-between">
                            <div>
                              {userData?._id === item?.sender
                                ? t("you")
                                : senderContactDetails
                                ? senderContactDetails?.firstName +
                                  " " +
                                  senderContactDetails?.lastName
                                : "+" + senderDetails?.phone}
                            </div>
                            <div className="textGray text-sm">{time}</div>
                          </div>
                            <div className="textGray">
                              <Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={parse(
                                  FormattedText(item?.message)
                                )}
                              />
                            </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              t("no_chats")
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchChatTab;
