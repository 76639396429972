import { store } from "../Redux/store";

interface IReturn {
  name: string
  profile: string | null
}

function messageSenderInfo( senderId, participants): IReturn {
  const { formattedContact } = store.getState()?.user;

  const findParticipant = participants?.find(
    (el: any) => el?.user_id == senderId
  );

  const senderContactDetails = formattedContact?.[findParticipant?.user_id];

  const name = senderContactDetails?.firstName
    ? senderContactDetails?.firstName + " " + senderContactDetails?.lastName
    : findParticipant?.phone;

  return {
    name,
    profile: findParticipant?.profile_img,
  };
}

export default messageSenderInfo;
