// import moment from "moment";

// export const FirstHalfOfYear = [
//     'JAN',
//     'FEB',
//     'MAR',
//     'APR',
//     'MAY',
//     'JUN',
// ]

// export const SecondHalfOfYear = [
//     'JUL',
//     'AUG',
//     'SEP',
//     'OCT',
//     'NOV',
//     'DEC',
// ]

// enum dateCount {
//     QUARTERLY = "90",
//     HALFYEARLY = '180',
//     YEARLY = '365'
// }

// const getCustomRange = (date: number, type: string) => {
//     const querters: any = [];
//     let newDate = + moment(date);
//     const diff = parseInt(dateCount[type]);

//     switch (type) {
//         case "QUARTERLY":
//             for (let i = 0; i < 4; i++) {
//                 newDate = + moment(newDate).add(diff, 'days')
//                 querters.push(newDate);
//             }
//             return querters;
//         case "HALFYEARLY":
//             for (let i = 0; i < 2; i++) {
//                 newDate = + moment(newDate).add(diff, 'days')
//                 querters.push(newDate);
//             }
//             return querters;
//         case "YEARLY":
//             return querters.push(moment(newDate).add(diff, 'days'));

//         default:
//             return querters;
//     }
// }

// const isCompleted = (assignment: any, range: number[]) => {
//     const assign = { ...assignment, 'completeTime': +moment() }
//     const date = + moment();
//     const day = moment().format('ddd')
//     const getDay = moment(assign?.completeTime).format('ddd');
//     const week = moment().week()
//     const getWeek = moment(assign?.completeTime).week();
//     const month = moment().format('M')
//     const getMonth = moment(assign?.completedTime).format('M')
//     const quarter = moment().quarter()
//     const getQuarter = moment(assign?.completedTime).quarter()
//     const halfYear = moment().get('quarter') < 3 ? 1 : 2
//     const getHalfYear = moment(assign?.completedTime).get('quarter') < 3 ? 1 : 2
//     const year = moment().year()
//     const getYear = moment(assign?.completedTime).year()

//     let completeCount = 0;
//     let currentCount = 0;
//     for (let index = 1; index < range.length - 1; index++) {
//         if (range[index - 1] <= assign?.completeTime && range[index] >= assign?.completeTime)
//             completeCount = index;
//     }

//     for (let index = 1; index < range.length - 1; index++) {
//         if (range[index - 1] <= date && range[index] >= date)
//             currentCount = index;
//     }

//     switch (assign?.recurrent) {
//         case 'ONCE':
//             return false;
//         case 'DAILY':
//             return !(getDay === day)
//         case 'WEEKLY':
//             return !(week === getWeek);
//         case 'MONTHLY':
//             return !(month === getMonth);
//         // case 'QUARTERLY':

//         //     return !(completeCount === currentCount)
//         // case 'HALFYEARLY':
//         //     return !(halfYear === getHalfYear)
//         // case 'ANNUALLY':
//         //     return !(year === getYear)
//         default:
//             return !(completeCount === currentCount);
//     }
// }

// export const FilterAssignments = (assignments: any) => {

//     return assignments.filter((item: any) => {
//         const range = getCustomRange(item.start, item.recurrent)
//         if (item?.completeTime) {
//             return isCompleted(item, range)
//         } else {
//             return true;
//         }
//     })
// }

// old code --->

import dayjs from "dayjs";
import moment from "moment";

// export enum startName {
//    "START" = "START",
//    "ANFANG" = "START"
// }

// export enum finishName {
//     "FINISH" = "FINISH",
//     "Beenden"
// }

export const FirstHalfOfYear = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN"];

export const SecondHalfOfYear = ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const isCompleted = (assignment: any) => {
  const assign = { ...assignment, completeTime: +moment() };
  
  const month = moment().format("MMM");
  const getWeek = assign.daylyParams.dayOfWeeks;
  const getMonth = assign.montlyParams.months;
  const endDate = moment(assign.end).format("DD-MMMM-YYYY");
  const startDate = moment(assign.start).format("DD-MMMM-YYYY");
  const currentDate = moment(new Date()).format("DD-MMMM-YYYY");
  const currentTime = moment().format("HH:mm");
  const startTime = moment(assign.startTimeInMs).format("HH:mm");
  const currentWeekDay = moment().format("ddd");
  switch (assign?.recurrent) {
    case "ANYTIME":if(assign.completeTime){
      return false
    }else{
      return true
    }
    case "ONCE":
      if(assign.completeTime){
        return false
      }if (currentDate === startDate) {
            if (currentTime === startTime) {
              return true;
            } else {
              return false;
            }
          }
      break;
    case "DAILY":
        if (currentDate >= startDate && currentDate <= endDate) {
            if (currentTime === startTime) {
              return true;
            } else {
              return false;
            }
          }
      break;
    case "WEEKLY":
      switch (assign.daylyParams?.everyWeek) {
        case 1:
          if (currentDate >= startDate && currentDate <= endDate) {
            if (currentTime === startTime) {
              return true;
            } else {
              return false;
            }
          }
          break;
        case 2:
          if (currentWeekOfCurrentMonth() === 2) {
            if (getWeek.includes(currentWeekDay)) {
              if (currentDate >= startDate && currentDate <= endDate) {
                if (currentTime === startTime) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
          break;
        case 3:
          if (currentWeekOfCurrentMonth() === 3) {
            if (getWeek.includes(currentWeekDay)) {
              if (currentDate >= startDate && currentDate <= endDate) {
                if (currentTime === startTime) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
          break;
        case 4:
          if (currentWeekOfCurrentMonth() === 4) {
            if (getWeek.includes(currentWeekDay)) {
              if (currentDate >= startDate && currentDate <= endDate) {
                if (currentTime === startTime) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
          break;
          default:
      return false;
      }
      break;
    case "MONTHLY":if(assign?.montlyParams?.twicePerMonth===false){
        if (currentDate >= startDate && currentDate <= endDate) {
            if (currentTime === startTime) {
              return true;
            } else {
              return false;
            }
          }
    }else{
       if(getMonth.includes(month.toLocaleUpperCase())){ if (currentDate >= startDate && currentDate <= endDate) {
            if (currentTime === startTime) {
              return true;
            } else {
              return false;
            }
          }}
    }
      break;
    default:
      return true;
  }
};

export const currentWeekOfCurrentMonth = () => {
  const currentDate = moment();
  const currentWeekOfMonth =currentDate.week() - moment(currentDate).startOf("month").week() + 1;
  return currentWeekOfMonth;
};

export const FilterAssignments = (assignments: any) => {
  return assignments;
  return assignments.filter((item: any) => {
    if (item?.completeTime || item?.recurrent !== "ANYTIME") {
      return isCompleted(item);
    } else {
      return true;
    }
  });
};
