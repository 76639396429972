import React from "react";

interface PropType {
  children: JSX.Element;
}

function Buttons({ children }: PropType) {
  return (
    <div>
      <div className="border text-center py-[7px] rounded cursor-pointer bg-primary text-white">
        {children}
      </div>
    </div>
  );
}

export default Buttons;
