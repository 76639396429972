import { DefaultImageUrl } from "../../../Constant/Constant";

const TaskQuestionImage = ({ currentTask }: any) => {
  return (
    <>
      {(currentTask?.attachment?.type === "PHOTO" ||
        currentTask?.attachment?.type === "IMAGE") && (
        <div className="h-[250px] w-full min-w-[300px] mt-3">
          <img
            src={
              DefaultImageUrl + currentTask?.attachment?.attachment?.filename
            }
            alt=""
            className="h-full w-full object-contain rounded-md"
          />
        </div>
      )}
    </>
  );
};

export default TaskQuestionImage;
