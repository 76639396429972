import { useTranslation } from "react-i18next";
import { EventTypeEnum } from "../Calendar/types/enum";
import Notes from "../../coreComponent/Note";
import calendar from "dayjs/plugin/calendar";
import dayjs from "dayjs";

dayjs.extend(calendar);

interface IProps {
  form: any;
  recurrentList: Array<{ occurrencesDate: string }>;
  formType: EventTypeEnum.REMINDER | EventTypeEnum.SCHEDULE;
}

function ShowNextOccurrence({ recurrentList, form }: IProps) {
  const { t } = useTranslation();

  function showOccurrences() {
    const time = dayjs(form?.time).format("HH:mm");
    const months = form?.months?.length ? form?.months : [];
    const selectedMonths = form?.repeatEveryMonth
      ? []
      : recurrentList?.filter((el) => {
          const month = dayjs(el?.occurrencesDate)
            ?.format("MMM")
            ?.toUpperCase();
          return months?.includes(month);
        });

    const updatedList = form?.repeatEveryMonth ? recurrentList : selectedMonths;

    if (updatedList?.length == 0) {
      return `0 ${t("reminders")?.toLowerCase()}`;
    } else {
      const first = dayjs(updatedList?.[0]?.occurrencesDate)?.calendar(null, {
        sameDay: `[${t("today")} ${t("at")}] ${time}`,
        nextDay: `[${t("tomorrow")} ${t("at")}] ${time}`,
        nextWeek: `DD MMMM YYYY | ${time}`,
        sameElse: `DD MMMM YYYY | ${time}`,
      });

      return `${t("occurs")} ${first} ${
        updatedList?.length > 1
          ? `${t("and") + " " + t("then")} ${
              updatedList?.length > 2
                ? `${updatedList?.length - 1} ${t(
                    "reminders"
                  )?.toLowerCase()} ${t("till")} `
                : ""
            } ${dayjs(
              updatedList?.[updatedList?.length - 1]?.occurrencesDate
            ).format("DD MMMM YYYY")}`
          : ""
      }`;
    }
  }

  return (
    <div className="mt-2">
      <Notes note={showOccurrences()} />
    </div>
  );
}

export default ShowNextOccurrence;
