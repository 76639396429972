import { useState } from "react";
import { RangeExpressionsCustomExpTypes } from "../Types/Types";

export const useRangeExpressions = () => {
  const [rangeError, setRangeError] = useState("");

  const handleExpressions = (currentTask: any, userInput: number) => {
    if (
      currentTask?.rangeExpression?.type === "custom-condition" ||
      currentTask?.rangeExpression?.customExp?.length
    ) {
      if(currentTask?.rangeExpression?.customExp?.length>0){
        const formattedData =[];
      const parsedData = currentTask?.rangeExpression?.customExp;
      currentTask?.rangeExpression?.customExp.map((parent:any)=>(
         JSON.parse(parent?.value)?.map(
          (item: any, index: number) => {
            if (index === JSON.parse(parent?.value)?.length - 1) {
              formattedData.push( {
                id: item?.id,
                operator: item?.operator,
                value: item?.value,
                gate: "",
              });
            }
            return item;
          }
        )
      ))
      ;

      let newExp = "";

      formattedData?.forEach((item: RangeExpressionsCustomExpTypes) => {
        newExp =
          JSON.stringify(newExp) + ` ${userInput} ${item.operator} ${item.value} ${item.gate}`;
      });
      if (newExp) {
        setRangeError("");
      } else {
        setRangeError(parsedData?.message);
      }
    }
    }
  };

  return {
    handleExpressions,
    rangeError,
  };
};
