import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import ScheduleIcon from "../../Assets/Images/schedule_msg.png"
import { useDispatch, useSelector } from "react-redux";
import { setIsRoomLoading } from "../../Redux/ChatSlice";

export default function SelectedMediaCaptionBar({ setFilesModal, setSelectedFiles, loading, setWebcameObjFile, handleFileSend, fileCaptionName, setFileUploadError, setWebcamImage, fileUploadError, clickOnSchedule }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const {userData} = useSelector((state:any) => state?.user)
    const {activeRoomData} = useSelector((state:any) => state?.chat)
    const isMySelf = activeRoomData?.participants?.length == 1 &&
                     activeRoomData?.participants?.[0]?.user_id == userData?._id

    return (
        <div className=" px-10 text-right flex items-center justify-center w-full">
            <div
                className="text-gray-800 cursor-pointer"
                onClick={() => {
                    setFilesModal(false);
                    setSelectedFiles([]);
                    setWebcameObjFile("");
                    setWebcamImage("");
                    setFileUploadError("");
                    dispatch(setIsRoomLoading(false))
                }}
            >
                <CloseIcon />
            </div>
            <div className="flex items-center w-full">
                <div className="px-2 w-full">
                    <input
                        name="fileCaption"
                        className="w-full outline-none bg-gray-100 text-gray-700 focus:outline-none border py-[6px] rounded-lg px-4"
                        placeholder={t("message")}
                        autoComplete="off"
                        // value={fileCaption}
                        ref={fileCaptionName}
                        autoFocus={true}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                handleFileSend();
                            }
                        }}
                    />
                </div>

                {loading ? (
                    <ReactLoading type="bubbles" color="#33ccff" />
                ) : (
                    <div className="flex items-center gap-3">
                        <button
                            className="outline-none disabled:opacity-20"
                            onClick={handleFileSend}
                            disabled={fileUploadError ? true : false}
                        >
                            <SendIcon className="text-green-600 " />
                        </button>

                        {!isMySelf && (
                            <button
                                className="outline-none disabled:opacity-20"
                                onClick={() => clickOnSchedule("media")}
                            >
                                <img src={ScheduleIcon} className="w-[25px]" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}