import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TaskStartButtonPropTypes {
  loading: boolean;
  handleStartReport: () => void;
}

const TaskStartButton = ({
  loading,
  handleStartReport,
}: TaskStartButtonPropTypes) => {
  const {t}=useTranslation();
  return (
    <div className="w-full">
      <button
        className="bg-[#33ccff] outline-none py-2 px-5 rounded-md w-full text-white"
        onClick={handleStartReport}
        disabled={loading ? true : false}
      >
        {loading ? <CircularProgress color="inherit" size={22} /> : t("start")}
      </button>
    </div>
  );
};

export default TaskStartButton;
