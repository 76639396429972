import { CountdownCircleTimer } from "react-countdown-circle-timer";

const TaskTimeCounter = ({ duration, onComplete ,timediff}: any) => {
  
  return (
    <div className="px-4">
      <CountdownCircleTimer
        isPlaying
        duration={duration / 1000}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[7, 5, 2, 0]}
        size={0}
        onComplete={onComplete}
      >
        {({ remainingTime }) =>
          {timediff=new Date(remainingTime * 1000).toISOString().substring(12, 19)
            return new Date(remainingTime * 1000).toISOString().substring(12, 19)
          }
        }
      </CountdownCircleTimer>
    </div>
  );
};

export default TaskTimeCounter;
