import { RiInformationFill } from "react-icons/ri";

interface PropTypes {
    note: string
}

function Notes({ note }: PropTypes) {
    return (
        <div className="mt-2 p-2 flex gap-2 bg-[#FFF9D0] rounded-md">
            <RiInformationFill color="#DD761C" />

            <div className="text-sm text-gray-800">
                {note}
            </div>
        </div>
    )
}

export default Notes