import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { TSchedule } from "../../../ChatPage/schedule/types";
import {
  DefaultImageUrl,
  SCHEDULE_SOCKET_EVENT,
} from "../../../../Constant/Constant";
import UserPic from "../../../../Assets/Images/user.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Pagination,
  Popover,
  Snackbar,
} from "@mui/material";
import ScheduleMessage from "./ScheduleMessage";
import React, { useEffect, useRef, useState } from "react";
import { authCllient } from "../../../../Graphql/authClient";
import {
  DELETE_REMINDER,
  UPDATE_PARENT_REMINDER_STATUS,
  UPDATE_REMINDER_STATUS,
} from "../../../../Graphql/Mutations";
import { setSchedules } from "../../../../Redux/ChatSlice";
import {
  ParticipantAcceptStatus,
  RecurrentTypes,
} from "../../../../Components/Calendar/types/enum";
import ScheduleModal from "../../../ChatPage/schedule/ScheduleModal";
import dayjs from "dayjs";
import EventConfirmation from "../../../../Components/Calendar/dialogs/EventConfirmation";
import { GET_SCHEDULE_BY_ROOM_ID } from "../../../../Graphql/Queries";
import { socketListen } from "../../../../Socket";
import DateFilter from "../reminder/DateFilter";
import determineDateText from "../../../../utils/determineDateText";

type PropTypes = {
  setShow: (value: boolean) => void;
};

function ScheduleDrawer({ setShow }: PropTypes) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state?.user);
  const { schedules, activeRoomData } = useSelector(
    (state: any) => state?.chat
  );
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [alert, setAlert] = useState({ msg: "", show: false });
  const [status, setStatus] = useState<"idl" | "loading" | "error" | "success">(
    "idl"
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popover_id = open ? "simple-popover" : undefined;
  const [messageData, setMessageData] = useState(null);
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);
  const [loading, setloading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [filterDates, setFilterDates] = useState({ from: null, to: null });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pageRef = useRef(null);
  const [confirmationType, setConfirmationType] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  async function getSchedulesByRoomId() {
    setloading(true);
    try {
      const response = await authCllient.query({
        query: GET_SCHEDULE_BY_ROOM_ID,
        variables: {
          input: {
            roomId: activeRoomData?._id,
            pageNo: page,
            limit: 10,
            ...(filterDates?.from &&
              filterDates?.to && {
                From: filterDates?.from,
                To: filterDates?.to,
              }),
          },
        },
      });

      if (response?.data?.getScheduleByRoomID) {
        dispatch(setSchedules(response?.data?.getScheduleByRoomID));
        setTotalPage(response?.data?.getScheduleByRoomID?.[0]?.totalPage);
      }
    } catch (err) {
      console.log(`Error at get reminders: ${err}`);
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    getSchedulesByRoomId();
  }, [page]);

  useEffect(() => {
    if (isRefresh) {
      getSchedulesByRoomId();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  socketListen("message", (data: any) => {
    if (SCHEDULE_SOCKET_EVENT?.includes(data?.type)) {
      setIsRefresh(true);
    }
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    schedule: any,
    date: string
  ) => {
    const _date = dayjs(date)
      .set("hour", dayjs(schedule?.time).hour())
      .set("minute", dayjs(schedule?.time).minute())
      .set("second", dayjs(schedule?.time).second());

    const format = {
      _id: schedule?._id,
      parent_id: schedule?.parent_id,
      roomId: schedule?.roomId,
      roomType: schedule?.roomType,
      startDate: dayjs(schedule?.startDate),
      endDate: dayjs(schedule?.endDate),
      time: dayjs(schedule?.time),
      type: schedule?.type,
      isApprovalNeeded: schedule?.isApprovalNeeded,
      approvalReminderTime: {
        Count: schedule?.approvalReminderTime?.[0]?.Count,
        Unit: schedule?.approvalReminderTime?.[0]?.Unit,
      },
      frequency: schedule?.recursive,
      days: schedule?.daylyParams?.dayOfWeeks,
      noOfWeeks: schedule?.daylyParams?.everyWeek,
      repeatEveryMonth: schedule?.monthlyParams
        ? schedule?.monthlyParams?.twicePerMonth
        : true,
      months: schedule?.monthlyParams?.months,
      onDay: schedule?.monthlyParams?.onDay ?? 1,
      onWeek: schedule?.monthlyParams?.onWeek?.dayOfWeeks?.[0] ?? "MON",
      everyWeek: schedule?.monthlyParams?.onWeek?.everyWeek ?? 1,
      monthDateType: schedule?.monthlyParams?.onWeek ? "week" : "day",
      date: _date,
    };
    const { __typename, ...rest } = schedule?.message?.[0];
    setMessageData(rest);
    setSelectedSchedule(format);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSchedule(null);
  };

  function handleAction(type: string) {
    if (type == "delete") {
      setDeleteScheduleId({
        id: selectedSchedule?._id,
        parent_id: selectedSchedule?.parent_id,
      });
      setConfirmationType("delete");
      setShowConfirmation(true);
    } else {
      setAnchorEl(null);
      setEditModalShow(true);
    }
  }

  async function handleDelete(deleteType: string, isOnce: boolean, data: any) {
    const payload = {
      _id: isOnce
        ? data?._id
        : deleteType == "all"
        ? deleteScheduleId?.parent_id
        : deleteScheduleId?.id,
      thisOccurrence: deleteType == "this" ? true : false,
      allOccurrence: deleteType == "all" ? true : false,
    };

    try {
      setStatus("loading");
      const response = await authCllient.mutate({
        mutation: DELETE_REMINDER,
        variables: {
          input: payload,
        },
      });

      if (!response?.data) {
        setStatus("error");
      } else {
        setStatus("success");
        const updatedSchedules = schedules?.filter(
          (item) => item?._id != deleteScheduleId?.id
        );
        dispatch(setSchedules(updatedSchedules));
        setAlert({ msg: "schedule_delete_msg", show: true });
        handleClose();
      }
    } catch (err) {
      setStatus("error");
      console.log(err);
    }
  }

  const handleClickOnApproveReject = (status: string, schedule: TSchedule) => {
    setSelectedStatus(status);
    setSelectedSchedule(schedule);
    setShowConfirmation(true);
    setConfirmationType("status");
  };

  async function handleApproveReject(
    type: string,
    isOnce: boolean,
    _id: string,
    status: string
  ) {
    const payload = {
      _id: isOnce
        ? _id
        : type == "this"
        ? selectedSchedule?._id
        : selectedSchedule?.parent_id,
      ApprovalStatus: isOnce ? status : selectedStatus,
    };

    try {
      setStatus("loading");
      const response = await authCllient.mutate({
        mutation:
          type == "this"
            ? UPDATE_REMINDER_STATUS
            : UPDATE_PARENT_REMINDER_STATUS,
        variables: {
          input: payload,
        },
      });
      if (!response?.data) {
        setStatus("error");
      } else {
        let message = "";
        if ((selectedStatus || status) == ParticipantAcceptStatus.ACCEPT) {
          message = "approved_success";
        } else {
          message = "unapproved_success";
        }
        setAlert({ msg: message, show: true });
        setStatus("success");
        setSelectedSchedule(null);
      }
    } catch (error) {
      setStatus("error");
      console.log(error);
    }
  }

  function handlePageChange(ev, value) {
    setPage(value);
    pageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div ref={pageRef}>
      <Snackbar
        open={alert.show}
        message={t(alert.msg)}
        autoHideDuration={3000}
        onClose={() => setAlert({ msg: "", show: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <div className="sticky top-0 z-10">
        <div className="flex justify-between items-center py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
          <div
            className="flex  items-center cursor-pointer"
            onClick={() => setShow(false)}
          >
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
            <div className=" font-bold capitalize">{t("schedule_msg")}</div>
          </div>
        </div>
        {loading && <LinearProgress color="primary" sx={{ height: "2px" }} />}
      </div>

      <DateFilter
        filterDates={filterDates}
        setFilterDates={setFilterDates}
        setIsRefresh={setIsRefresh}
        loading={loading}
      />

      {schedules?.length > 0 ? (
        <div className="grid gap-4 px-4 py-4">
          {schedules?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className="px-2 font-semibold text-sm text-gray-700 mb-3">
                  {determineDateText(item?.date, t)}
                </div>

                {item?.reminders?.map((schedule: TSchedule, key: number) => {
                  const messageObj = schedule?.message?.[0];

                  return (
                    <div key={key} className="border-b mb-2">
                      <div className="flex gap-2 mb-6">
                        <div className="h-[35px] min-w-[35px] w-[35px]">
                          {userData?.profile_img ? (
                            <img
                              src={DefaultImageUrl + userData?.profile_img}
                              alt="as"
                              className="h-full w-full rounded-full"
                            />
                          ) : (
                            <img
                              src={UserPic}
                              className="h-full w-full rounded-full"
                            />
                          )}
                        </div>

                        <ScheduleMessage
                          schedule={schedule}
                          messageObj={messageObj}
                        />

                        <IconButton
                          className="h-fit"
                          onClick={(ev) =>
                            handleClick(ev, schedule, item?.date)
                          }
                          aria-describedby={popover_id}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </div>

                      {selectedSchedule?._id == schedule?._id &&
                        status == "error" && (
                          <div className="my-2 text-sm text-center text-red-600">
                            {t("something_went_wrong")}
                          </div>
                        )}

                      {schedule?.isApprovalNeeded && (
                        <div className="mb-2 mt-1 flex justify-end gap-3">
                          <Button
                            size="small"
                            color="success"
                            variant="outlined"
                            sx={{ textTransform: "capitalize" }}
                            disabled={Boolean(status == "loading")}
                            onClick={() => {
                              if (schedule?.recursive == RecurrentTypes.ONCE) {
                                handleApproveReject(
                                  "this",
                                  true,
                                  schedule?._id,
                                  ParticipantAcceptStatus.ACCEPT
                                );
                              } else {
                                handleClickOnApproveReject(
                                  ParticipantAcceptStatus.ACCEPT,
                                  schedule
                                );
                              }
                            }}
                          >
                            {t("approve")}
                          </Button>

                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            sx={{ textTransform: "capitalize" }}
                            disabled={Boolean(status == "loading")}
                            onClick={() => {
                              if (schedule?.recursive == RecurrentTypes.ONCE) {
                                handleApproveReject(
                                  "this",
                                  true,
                                  schedule?._id,
                                  ParticipantAcceptStatus.REJECT
                                );
                              } else {
                                handleClickOnApproveReject(
                                  ParticipantAcceptStatus.REJECT,
                                  schedule
                                );
                              }
                            }}
                          >
                            {t("reject")}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="flex justify-center mt-3 pb-5">
            <Pagination
              page={page}
              color="primary"
              count={totalPage}
              variant="outlined"
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">{t("no_schedules")}</div>
      )}

      {editModalShow && (
        <ScheduleModal
          modalType="edit"
          show={editModalShow}
          scheduleData={messageData}
          setShow={setEditModalShow}
          editScheduleData={selectedSchedule}
          setToastShow={setAlert}
        />
      )}

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={
            confirmationType == "status" ? handleApproveReject : handleDelete
          }
          message={
            confirmationType == "status"
              ? t("update_approval_status")
              : t("delete_recurring_event")
          }
        />
      )}

      <Popover
        open={open}
        id={popover_id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        component="form"
      >
        <MenuList>
          {dayjs(selectedSchedule?.date).isSameOrAfter(dayjs()) && (
            <MenuItem
              disabled={Boolean(status == "loading")}
              onClick={() => handleAction("edit")}
            >
              {t("edit")}
            </MenuItem>
          )}
          <MenuItem
            disabled={Boolean(status == "loading")}
            onClick={() => {
              if (selectedSchedule?.frequency == RecurrentTypes.ONCE) {
                handleDelete("this", true, selectedSchedule);
              } else {
                handleAction("delete");
              }
            }}
          >
            {t("delete")}
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}

export default ScheduleDrawer;
