import { useDispatch, useSelector } from "react-redux"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setIsCallMinimized } from "../../Redux/ChatSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddCallParticipants from "./AddCallParticipants";
import { Drawer } from "@mui/material";
import { authCllient } from "../../Graphql/authClient";
import { CALL_PARTICIPANTS } from "../../Graphql/Queries";
import CallParticipantsList from "./CallParticipantsList";
import InfoIcon from '@mui/icons-material/Info';
export default function CallScreenHeader({ minutes, contactCall, setContactCall, seconds, setTime, uid, totalJoinedUsers, onleave }) {
    const { activeCallData, rooms } = useSelector((state: any) => state.chat);
    const { formattedContact, userData } = useSelector((state: any) => state.user);
    const roomId = activeCallData?.data?.roomId?._id
        ? activeCallData?.data?.roomId?._id
        : activeCallData?.data?.roomId;
    const [callMember, setMemberCall] = useState(false);
    const GroupName = rooms?.find((item: any) => item?._id === roomId);
    const dispatch = useDispatch();
    const { t } = useTranslation()

    function MyStopwatch() {
        let minute = minutes > 9 ? `${minutes}:` : `0${minutes}:`;
        let second = seconds > 9 ? `${seconds}` : `0${seconds}`;
        setTime(`${minute}${second}`);
        return (
            <div
                className={
                    activeCallData?.data?.roomType === "group" ||
                        activeCallData?.data?.roomType === "contact_group"
                        ? "mx-2"
                        : " mx-2"
                }
            >
                <div className="text-left" style={{ fontSize: "1rem" }}>
                    {!onleave && (
                        <>
                            <span>{minutes > 9 ? minutes : `0${minutes}`}</span>:
                            <span>{seconds > 9 ? seconds : `0${seconds}`}</span>
                        </>
                    )}
                </div>
            </div>
        );
    }
    const getRemoteUserName = () => {
        let remoteUserName = "";
        let temp = activeCallData?.data?.callParticipants.filter(
            (ele) => ele?.uid !== uid
        )[0]?.userId;
        remoteUserName =
            JSON.stringify(formattedContact) === "{}"
                ? temp?.phone
                : formattedContact[temp?._id]?.firstName !== undefined
                    ? formattedContact[temp?._id]?.firstName +
                    " " +
                    formattedContact[temp?._id]?.lastName
                    : temp?.phone;
        return remoteUserName;
    };

    const contactGroupName = () => {
        let roomName = "contact group";

        const otherUsers = activeCallData?.data?.callParticipants?.filter(
            (item) => item?.userId?._id !== userData?._id
        );

        const contactNameOne = formattedContact[otherUsers[0]?.userId?._id]
            ? formattedContact[otherUsers[0]?.userId?._id]?.firstName +
            " " +
            formattedContact[otherUsers[0]?.userId?._id]?.lastName
            : otherUsers[0]?.userId?.phone;

        const contactNameTwo = formattedContact[otherUsers[1]?.userId?._id]
            ? formattedContact[otherUsers[1]?.userId?._id]?.firstName +
            " " +
            formattedContact[otherUsers[1]?.userId?._id]?.lastName
            : otherUsers.length > 1 ? otherUsers[0]?.userId?.phone : "";
        if (otherUsers?.length == 2) {
            roomName = contactNameOne + ", " + contactNameTwo;
        } else if (otherUsers.length === 1) {
            roomName = contactNameOne;
        } else {
            roomName = contactNameOne + ", " + contactNameTwo + t("and_others");
        }

        return roomName;
    };

    const handleMinimize = () => {
        dispatch(setIsCallMinimized(true));
    };

    return (
        <>
            {callMember &&

                <Drawer
                    anchor={"right"}
                    open={callMember}
                    onClose={() => setMemberCall(false)}
                >
                    <CallParticipantsList setMemberCall={setMemberCall} totalJoinedUsers={totalJoinedUsers} />

                </Drawer>
            }
            {contactCall &&

                <Drawer
                    anchor={"right"}
                    open={contactCall}
                    onClose={() => setContactCall(false)}
                >
                    <AddCallParticipants setContactCall={setContactCall} totalJoinedUsers={totalJoinedUsers} />

                </Drawer>
            }

            <div
                className="mic-icon shadow-xl fixed-bar w-full m-2 ms-0  mr-4 px-2 rounded flex justify-between items-center"
                style={{
                    width: "100%",
                    zIndex: '121'
                }}
            >
                <div className="flex gap-2 items-center">
                    {!onleave && (
                        <div className="text-black z-[999] py-2  flex gap-8">

                            <button onClick={handleMinimize}>
                                <ArrowBackIcon />
                                {/* {t("back")} */}
                            </button>
                        </div>
                    )}
                    <div>
                        <div className='flex'>
                            {!onleave &&
                                activeCallData?.data?.roomType !== "group" &&
                                activeCallData?.data?.roomType !== "contact_group" ? (
                                <div className="mx-1">{getRemoteUserName()}</div>
                            ) : (
                                !onleave && (
                                    <div className="mx-1">
                                        {activeCallData?.data?.roomType === "group"
                                            ? GroupName?.name
                                            : contactGroupName()}
                                    </div>
                                )
                            )}
                            {!onleave && <div className="cursor-pointer" onClick={() => { setMemberCall(true); }}><InfoIcon className="cursor-pointer" sx={{ color: "#33ccff" }} /></div>}
                        </div>

                        {Object.keys(totalJoinedUsers).length > 0 && <MyStopwatch />}
                    </div>
                </div>

                {!onleave && activeCallData?.data?.roomType !== "group" && (
                    <div className="text-black z-[999] py-2 px-4 flex gap-8">

                        <button onClick={() => { setContactCall(true); }}>
                            {t('add_members')}
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}