import { JWT_EXPIRE } from "../Constant/Constant";

export const getNewDate = (seconds?: number) => {
  let date = new Date();

  if (seconds) {
    date = new Date(date.getTime() + seconds * 1000);
  }
  return date.getTime();
};

export const setSessionItem = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const getSessionItem = (key: string) => {
  return localStorage.getItem(key) || sessionStorage.getItem(key);
};

const removeSessionItem = (key: string) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

export const setSession = ({ token, refresh, isMasterAdmin }: any) => {
  if (typeof localStorage !== "undefined") {
    const expireAt = getNewDate(Number(JWT_EXPIRE)).toString();
    setSessionItem("token", token);
    setSessionItem("isMasterAdmin", `${isMasterAdmin}`);
    setSessionItem("refresh", refresh);
    setSessionItem("expireAt", expireAt);
  }
};

export const getSession = () => {
  if (typeof localStorage === "undefined") {
    return {};
  }
  const token = getSessionItem("token");
  const refresh = getSessionItem("refresh");
  const expireAt = getSessionItem("expireAt");
  const isMasterAdmin = getSessionItem("isMasterAdmin");
  const currentOrganization = getSessionItem("currentOrganization");
  return {
    token,
    refresh,
    expireAt,
    isMasterAdmin,
    currentOrganization,
  };
};

export const removeSession = (cleanSettings?: boolean) => {
  if (typeof localStorage !== "undefined") {
    // Auth Session
    removeSessionItem("token");
    removeSessionItem("refresh");
    removeSessionItem("expireAt");
    removeSessionItem("isMasterAdmin");
  }
};
