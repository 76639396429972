import React from "react";

interface PropType {
  children: JSX.Element;
}

function ButtonWhite({ children }: PropType) {
  return (
    <div className="border text-center py-[7px] rounded cursor-pointer border-[#33CCFF] primary">
      <div>{children}</div>
    </div>
  );
}

export default ButtonWhite;
