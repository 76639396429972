import { Avatar, Menu } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import MessageInfo from "./MessageInfo";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MenuList from "@mui/material/MenuList";
import { MessagesActionDataTypes } from "../../Types/Types";
import StarIcon from "@mui/icons-material/Star";
import { emit, socketListen } from "../../Socket";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosShareAlt } from "react-icons/io";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Toast from "../../coreComponent/Toast";
import { useTranslation } from "react-i18next";
import messageSenderInfo from "../../utils/messageSenderInfo";
const AudioFilePreview = ({
  showDate,
  showDateforoutgoing,
  setData,
  setSelectedHeading,
  setSelectedHeadingId,
  setShowHeadingModal,
  profileShown,
  item,
  setMessages,
  messages,
  setChangeFooter,
  setShowCheckbox,
  showCheckbox,
  setReplyFooterShow,
  setReplyMessage,
  setMessageInfoItem,
  setMessageInfoDrawerShow,
}: any) => {
  const [showMessageActions, setShowMessageActions] = useState(false);
  const [activeFav, setActiveFav] = useState<any>({});
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const MessageAction = MessageActions(t)
  const { topicsData } = useSelector((state: any) => state.user);
  const [toastShow, setToastShow] = useState(false);
  const audioRef = useRef(null);
  const [toastMessage, setToastMessage] = useState("");
  const anchorRef = useRef<HTMLDivElement>(null);
  const { userData, userContacts } = useSelector((state: any) => state.user);
  const { formattedParticipantsOfActiveRoom, activeRoomChats, activeRoomData, pinnedChats } =
    useSelector((state: any) => state.chat);
  const deleted =
    item?.deleted?.filter((element: any) => element.user_id === userData?._id)
      ?.length > 0
      ? false
      : true;
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  let messageTime =
    item?.created_at !== null
      ? moment(item?.created_at).format("HH:mm")
      : moment(new Date()).format("HH:mm");
  const isEveryoneCanPinMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "pinMessage" && item?.permit === "common"
    );
  const userProfileImg = activeRoomData?.participants?.length > 0 && activeRoomData?.participants?.find((item: any) => item?.user_id === userData?._id);
  const isRoomLeft = userProfileImg?.left_at !== 0;

  const messageSender = messageSenderInfo(item.sender, activeRoomData?.participants)

  const canYouPinMessage =
    (!isEveryoneCanPinMessage &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
      "admin") ||
    isEveryoneCanPinMessage;
  let userDetails = userContacts?.find(
    (item: any) => item?.userId?._id === senderInfo?.user_id
  );

  const isUserBlocked = activeRoomData?.participants?.find((el: any) => el?.left_at !== 0 && el?.user_id === userData?._id);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMessageActions(false);
    } else if (event.key === "Escape") {
      setShowMessageActions(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    //setshowArrow({ status: false, id: null });
    setAnchorEl(null);

  };

  useEffect(() => {
    document.addEventListener('click', handleClose);

    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, []);

  const handleMessageAction = (action: string, item: any) => {
    setShowMessageActions(false);
    if (action === "forward" || action === "delete") {
      handleMessageSelect(true, item)
      setChangeFooter(true);
      setShowCheckbox(true);
    } else {
      setChangeFooter(false);
      setShowCheckbox(false);
    }

    if (action === "favourite") {
      emit("addChatsToFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    } else if (action === "unFavourite") {
      emit("removeChatsFromFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    }

    if (action === "heading") {
      setData(topicsData);
      setSelectedHeading(item?.message);
      setSelectedHeadingId(item?._id);
      setShowHeadingModal(true);
    }

    if (action === "reply") {
      setTimeout(() => {
        document.getElementById('message-input').focus()
      }, 0)
      setReplyMessage(item);
      setReplyFooterShow(true);
    }

    if (action === "info" && item?.sender === userData?._id) {
      setMessageInfoItem(item);
      setMessageInfoDrawerShow(true);
    }
    if (action === "pin") {
      if (canYouPinMessage) {
        if (pinnedChats?.length < 3) {
          emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

          socketListen("message", (data: any) => {
            if (data.type === "pinChat") {
              setToastShow(true);
              setToastMessage("Chat Pinned Successfully");
            }
          });
        } else {
          setToastMessage("Maximum 3 Chat Pin Allowed");
          setToastShow(true);
        }
      } else {
        setToastMessage("Only Admin Can Pin Messages");
        setToastShow(true);
      }
    }
  };

  const handleMessageSelect = (e: any, item: any) => {
    let temp = [...messages.msgId];
    let tempMsgId = [...messages.senderId];
    let tempTime = [...messages.time];

    if (e) {
      temp.push(item?._id);
      tempMsgId.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      temp = temp.filter((elem: any) => elem !== item?._id);
      tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
      tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
    }
    setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
  };

  document.addEventListener(
    "play",
    function (e) {
      var audios = document.getElementsByTagName("audio");
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] != e.target) {
          audios[i].pause();
        }
      }
    },
    true
  );
  const handleDownloadFile = () => {
    const url = DefaultImageUrl + item?.fileURL;
    window.open(url);
    setShowMessageActions(false);
  };

  return item?.sender === userData?._id ? (
    <div className=" items-center" id="audio" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
      onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="flex h-[20px] w-[20px]">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}

      <div className="w-max ml-auto max-w-[400px]" >
        <div className="flex items-start">
          <div className="w-8 h-6" style={{ color: "#33CCFF", fontSize: '1.3rem' }}>
            {showArrow.status && showArrow.id === item?._id && deleted &&
              <div className="" onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setActiveFav(item);
              }} ref={anchorRef}>
                <MoreVertIcon className="-mr-2 cursor-pointer" sx={{ color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined} />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => { setAnchorEl(null); }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleDownloadFile}>
                    <div className="pr-2 mt-1">
                      <FileDownloadOutlinedIcon
                        sx={{ color: "#33ccff" }}
                      />
                    </div>
                    <div className="text-[14px]">{t("download")}</div>
                  </MenuItem>

                  {MessageAction.filter((elem: MessagesActionDataTypes) =>
                    activeRoomData?.type === "individual"
                      ? elem?.action !== "pin" && elem.action !== "info" && elem?.action !== "copy"
                      : elem.action !== "info" && elem?.action !== "copy"
                  ).map((el: MessagesActionDataTypes, index: number) => {
                    const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                    return pinchat.length > 0 && el?.action === "pin" ? (
                      ""
                    ) : activeFav?.favourite_by?.length > 0 &&
                      el?.action === "favourite" ? (
                      ""
                    ) : activeFav?.favourite_by?.length === 0 &&
                      el?.action === "unFavourite" ? (
                      ""
                    ) : (activeFav?.type === "PDF" ||
                      activeFav?.type === "TEXT" ||
                      activeFav?.type === "DOC" ||
                      activeFav?.type === "DOCX" ||
                      activeFav?.type === "ZIP" ||
                      activeFav?.type === "XLS" ||
                      activeFav?.type === "XLSX" ||
                      activeFav?.type === "PPT" ||
                      activeFav?.type === "PPTX" ||
                      activeFav?.type === "APPLICATION") &&
                      el?.action === "copy" ? (
                      ""
                    ) : (
                      isRoomLeft && (el?.action === "forward" || el?.action === "reply" || el?.action === "delete") ? ""
                        : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                          ? ""
                          : <MenuItem
                            onClick={() => handleMessageAction(el.action, item)}
                            key={index}
                            sx={{ fontSize: "14px" }}
                          >
                            {el.ui}
                          </MenuItem>
                    );
                  })}
                </Menu>

              </div>}
          </div>
          <div className="flex ">
            <div
              onContextMenu={(e) => {
                if (!item?.message?.includes("You deleted this message")) {
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                  setShowMessageActions(true);
                  setActiveFav(item);
                }
              }}
              className=" rounded-t-xl rounded-bl-xl bg-[#E0FAFF] p-4 mb-1"
            >

              <div className="relative" ref={anchorRef}>
                <div
                  className={`flex ${item.isForwarded ? "justify-between" : "justify-end"
                    }`}
                >
                  {item?.isForwarded && (
                    <div className="flex">
                      <IoIosShareAlt className="mt-1 mx-1" />
                      <div className="text-sm textGray text-right">{t("forwarded")}</div>
                    </div>
                  )}


                  {showDateforoutgoing && <div className="textGray text-[13px] pr-2 w-28 mb-1">
                    {messageTime}
                  </div>}

                </div>
                {item?.message !== "You deleted this message" && (
                  <div className={`flex items-center gap-2 ${item?.message !== "You deleted this message" && item.message !== "" ? 'rounded-md p-2  py-3' : ""}`}>
                    <div className="w-full  relative group">
                      <audio
                        src={DefaultImageUrl + item?.fileURL}
                        controls
                        ref={audioRef} onLoadedMetadata={() => console.log()}
                      ></audio>
                    </div>
                  </div>
                )}
                {item?.message !== "" && <div
                  className={` block justify-end bg-[#E0FAFF]   rounded-t-xl rounded-bl-xl `}
                >

                  {item?.message === "You deleted this message" ? <div className=" text-[13px] text-[#515151]  ">
                    <i> {item?.message.replace("You deleted this message", t("you-message-deleted"))}</i>
                  </div> :
                    <div className=" text-[14px] mt-2 text-[#515151]">
                      {item?.message.replace("You deleted this message", t("you-message-deleted"))}
                    </div>
                  }
                </div>}
                {item?.favourite_by?.length > 0 && (
                  <div>
                    <StarIcon
                      sx={{ fontSize: 14 }}
                      className="text-black mt-1 me-1"
                    />
                  </div>
                )}
              </div>
            </div>


            {item.isSent && item.read_by.length > 0 && item.receipts ? (
              <></>
            ) : (
              <div className="flex items-end ">
                {item.isSent && item.delivered_to.length > 0 ? (
                  <DoneAllIcon className="text-gray-400" sx={{ width: 18, height: 18 }} />
                ) : (
                  <DoneIcon className="text-gray-400" sx={{ width: 18, height: 18 }} />
                )}
              </div>
            )}
          </div>
        </div>
        {item.receipts && activeRoomChats[activeRoomChats.length - 1]?._id === item._id && item.isSent && item.read_by.length > 0 && (
          <div
            className={` ml-auto mb-2  ${activeRoomData?.type === "group" ? "w-12" : "w-fit ml-[5px]"
              } flex gap-1 items-end self-end justify-end`}
          >
            {<MessageInfo item={item} />}
          </div>
        )
        }
      </div>
    </div>
  ) : (
    <div className="flex w-full" id="audio" onMouseEnter={() => { setshowArrow({ status: true, id: item._id }); setAnchorEl(null); }}
      onMouseLeave={() => { setshowArrow({ status: false, id: null }); setAnchorEl(null); }}>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px] mr-4">
          <input
            type="checkbox"
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            checked={messages?.msgId.includes(item._id)}
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}

      <div>
        <div className={`flex pr-4 mr-auto max-w-[80%] w-fit`} >
          <div className="min-w-[35px]">
            {/* {showDate &&
              (senderInfo?.profile_img !== "" &&
                !isUserBlocked ? (
                <div className="h-[35px] w-[35px] min-w-[35px]">
                  <img
                    src={DefaultImageUrl + senderInfo?.profile_img}
                    alt="asas"
                    className="h-[35px] w-[35px] rounded-full"
                  />
                </div>
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))} */}

            {showDate &&
              (messageSender?.profile !== "" &&
                !isUserBlocked ? (
                <div className="h-[35px] w-[35px] min-w-[35px]">
                  <img
                    src={DefaultImageUrl + messageSender?.profile}
                    alt="asas"
                    className="h-[35px] w-[35px] rounded-full"
                  />
                </div>
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))}
          </div>
          <div className="flex items-start">

            <div
              onContextMenu={(e) => {
                if (!item?.message?.includes("This message was deleted")) {
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                  setShowMessageActions(true);
                  setActiveFav(item);
                }
              }}
              className="py-[6px] bg-[#F4F3F4] pb-4 rounded-tr-xl ml-2 px-4 pt-4 rounded-b-xl mx-2"
            >
              <div className="flex justify-between relative">
                <div className=" mb-2 text-sm flex">
                  {showDate && messageSender?.name}
                  {/* {showDate &&
                    (userDetails &&
                      (userDetails?.firstName !== "" ||
                        userDetails?.lastName !== "")
                      ? userDetails?.firstName + " " + userDetails?.lastName
                      : senderInfo?.phone)} */}
                  {item?.isForwarded && (
                    <div className="flex">
                      <IoIosShareAlt className="mx-1 mt-1" />
                      <div className="text-sm textGray text-right">{t("forwarded")}</div>
                    </div>
                  )}
                </div>

                {showDate && (
                  <div className="textGray text-[12px] pr-2 w-20">
                    {messageTime}
                  </div>
                )}

              </div>
              <div className="block  items-center gap-2">
                <div className={`${item.message !== "" && item.message !== "This message was deleted" ? 'rounded-md p-2 py-3' : ''}`}>
                  <div
                    className={`w-full  rounded-md p-2  py-3 group `}
                    ref={anchorRef}
                  >
                    <audio
                      src={DefaultImageUrl + item?.fileURL}
                      controls

                    ></audio>

                  </div>
                </div>

                <div
                  className={` block justify-end  rounded-t-xl rounded-bl-xl `}
                >
                  {item.message !== 'This message was deleted' ?
                    <div className="text-[14px]  mt-2 text-[#515151]">
                      {item?.message.replace("This message was deleted", t("this-message-deleted"))}
                    </div>
                    : <div className="text-[13px] text-[#515151]  ">
                      <i> {item?.message.replace("This message was deleted", t("this-message-deleted"))}</i>
                    </div>}
                </div>

              </div>
              {item?.favourite_by?.length > 0 && (
                <div className="flex justify-end">
                  <StarIcon
                    sx={{ fontSize: 14 }}
                    className="text-black mt-1 me-1"
                  />
                </div>
              )}
            </div>
            <div className="w-8 h-6" style={{ color: "#33CCFF", fontSize: '1.3rem' }}>
              {deleted &&
                <div className="" onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setActiveFav(item);
                }} ref={anchorRef}>
                  <MoreVertIcon className="-ml-2 cursor-pointer" sx={{ visibility: showArrow.status && showArrow.id === item?._id ? "visible" : "hidden", color: "#33CCFF", fontSize: '1.8rem' }} id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined} />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => { setAnchorEl(null); }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleDownloadFile}>
                      <div className="pr-2 mt-1">
                        <FileDownloadOutlinedIcon
                          sx={{ color: "#33ccff" }}
                        />
                      </div>
                      <div className="text-[14px]">{t("download")}</div>
                    </MenuItem>
                    {MessageAction.filter((elem: MessagesActionDataTypes) =>
                      activeRoomData?.type === "individual"
                        ? elem?.action !== "pin" && elem.action !== "info" && elem?.action !== "copy"
                        : elem.action !== "info" && elem?.action !== "copy"
                    ).map((el: MessagesActionDataTypes, index: number) => {
                      const pinchat = pinnedChats.filter((els: any) => els._id === item?._id);
                      return pinchat.length > 0 && el?.action === "pin" ? (
                        ""
                      ) : activeFav?.favourite_by?.length > 0 &&
                        el?.action === "favourite" ? (
                        ""
                      ) : activeFav?.favourite_by?.length === 0 &&
                        el?.action === "unFavourite" ? (
                        ""
                      ) : (activeFav?.type === "PDF" ||
                        activeFav?.type === "TEXT" ||
                        activeFav?.type === "DOC" ||
                        activeFav?.type === "DOCX" ||
                        activeFav?.type === "ZIP" ||
                        activeFav?.type === "XLS" ||
                        activeFav?.type === "XLSX" ||
                        activeFav?.type === "PPT" ||
                        activeFav?.type === "PPTX" ||
                        activeFav?.type === "APPLICATION") &&
                        el?.action === "copy" ? (
                        ""
                      ) : (
                        isRoomLeft && (el?.action === "forward" || el?.action === "reply" || el?.action === "delete") ? ""
                          : (activeRoomData?.type == "broadcast" && el?.action == "heading")
                            ? ""
                            : <MenuItem
                              onClick={() => handleMessageAction(el.action, item)}
                              key={index}
                              sx={{ fontSize: "14px" }}
                            >
                              {el.ui}
                            </MenuItem>
                      );
                    })}
                  </Menu>

                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioFilePreview;
