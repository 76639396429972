import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useCallback,
} from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import Popover from "@mui/material/Popover";
import DoneIcon from "@mui/icons-material/Done";
import Webcam from "react-webcam";
import { GET_USER_DATA } from "../../Graphql/Queries";
import { authCllient } from "../../Graphql/authClient";
import { useMutation } from "@apollo/client";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  UPDATE_USER,
  UPDATE_USER_BIO,
  UPLOAD_CHAT_FILE,
} from "../../Graphql/Mutations";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import "cropperjs/dist/cropper.css";
import { DefaultImageUrl } from "../../Constant/Constant";
import { setUserData } from "../../Redux/UserSlice";
import { useTranslation } from "react-i18next";
import { emit } from "../../Socket";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
};

function ProfileDrawer({ setProfilePageShow }: any) {
  const [fileUpload, { data, loading }] = useMutation(UPLOAD_CHAT_FILE);
  const [previewImg, setPreviewImg] = useState<any>("");
  const [loader, setLoader] = useState({ status: false, type: "" })
  const [imageCropperModal, setImageCropperModal] = useState<any>(false);
  const [cropImageUrl, setCropImageUrl] = useState<any>(false);
  const [showWebCam, setShowWebCam] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [removeImage, setRemoveImage] = useState(false);
  const [crop, setCrop] = useState<any>({ x: 400, y: 400 });
  const { userData } = useSelector((state: any) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [nameInputFieldEdit, setNameInputFieldEdit] = useState(false);
  const [bioInputFieldEdit, setBioInputFieldEdit] = useState(false);
  const [userProfileData, setUserProfileData] = useState<any>();
  const [webcamImage, setWebcamImage] = useState("");
  const [blobImageUrl, setBlobImageUrl] = useState<any>("");
  const [webcamObjFile, setWebcameObjFile] = useState<any>("");
  const webcamRef = useRef<any>(null);
  const [rotation, setRotation] = useState(0);
  const [updateUser] = useMutation(UPDATE_USER);
  const [setBio] = useMutation(UPDATE_USER_BIO);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const id = open ? "simple-popover" : undefined;
  const [input, setInput] = useState({ name: { firstName: "", lastName: "" } });
  const [bioInput, setBioInput] = useState("");
  const [image, setImage] = useState(defaultSrc);
  const [name, setName] = useState("");
  const [cropData, setCropData] = useState("");
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event: any) => {
    setName(event.target.value)
    const name = event.target.value.includes(' ') ? event.target.value.split(" ") : [event.target.value, ''];
    setInput({
      name: {
        firstName: name.join(' '),
        lastName: name.pop(),
      },
    });
  };

  useEffect(() => {
    UserData();
  }, []);

  const UserData = () => {
    authCllient
      .query({ query: GET_USER_DATA })
      .then((res) => {
        setUserProfileData(res.data.me);
        setInput({
          name: {
            firstName: res.data.me.firstName,
            lastName: res.data.me.lastName,
          },
        });
        setBioInput(res.data.me.bio.status);
      })
      .catch((error) => {
        console.log(`Cannot Query user (me) => `, error);
      });
  };

  const handleUpdateName = () => {
    const final = name.split(' ').slice(0, name.split(' ').length - 1).join(' ');
    const firstName = name.split(' ').length > 2 ? final : name.split(' ').shift();
    const lastName = name.split(' ').length > 1 ? name.split(' ').pop() : "";
   
    if(firstName!==""&&lastName!==""){
    updateUser({
      variables: {
        input: {
          _id: userData?._id,
          firstName: firstName,
          lastName: lastName,
          // profile_img: userProfileData?.profile_img,
        },
      },
    })
      .then((res) => {
        setTimeout(() => {
          setUserProfileData(res.data.updateUser);
          setInput({ name: { firstName: res.data.updateUser.firstName, lastName: res.data.updateUser.lastName } })
          dispatch(setUserData({ ...userData, firstName: res.data.updateUser.firstName, lastName: res.data.updateUser.lastName }));
          setLoader({ status: false, type: "" })
        }, 2000);
        setNameInputFieldEdit(false);
      })
      .catch((er) => {
        setLoader({ status: false, type: "" })
        setNameInputFieldEdit(false);
        console.log(er);
      });
    }else{
      setTimeout(() => {
        setLoader({ status: false, type: "" })
        setNameInputFieldEdit(false);
      }, 2000);
    }
  };

  const handleUpdateBio = () => {
    setBio({
      variables: {
        description: bioInput,
      },
    })
      .then((res) => {
        setTimeout(() => {
          setUserProfileData(res.data.setBio);
        }, 2000);
        setLoader({ status: false, type: "bio" })
        setBioInputFieldEdit(false);
      })
      .catch((er) => {
        setBioInputFieldEdit(false);
        setLoader({ status: false, type: "bio" })
        console.log(er);
      });
  };

  const captureWebcamImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamImage(imageSrc);
    setImage(imageSrc);
    setShowWebCam(false);
    fetch(imageSrc)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new File([arrayBuffer], "webcam2.jpeg", {
          type: "image/jpeg",
        });
        setWebcameObjFile(file);
      });
  }, [webcamRef]);

  const handleUpdateImage = () => {
    if (blobImageUrl !== "") {
      setImageLoading(true);
      fileUpload({
        variables: {
          file: blobImageUrl,
          thumbnail: null,
          input: { roomId: "sfsf3424", _id: userData?._id },
        },
      }).then((response) => {
        setImageCropperModal(false);
        emit("setProfilePicture", { imageURl:  response.data?.UploadChatFile.data.filename });

        setTimeout(() => {
          updateUser({
            variables: {
              input: {
                _id: userData?._id,
                profile_img: removeImage
                  ? ""
                  : response.data?.UploadChatFile.data.filename,
              },
            },
          })
            .then((res) => {
              setCropImageUrl(false);
              setTimeout(() => {
               dispatch(setUserData({ ...userData, profile_img: res.data.updateUser.profile_img }));
                setUserProfileData({ ...res.data.updateUser, profile_img: res.data.updateUser.profile_img })
                setImageLoading(false);
              }, 4000);
              setAnchorEl(null);
            })
            .catch((er) => {
              console.log(er);
            });
        }, 3000);
      });
    }
    else {
      updateUser({
        variables: {
          input: {
            _id: userData?._id,
            profile_img: "",
          },
        },
      })
        .then((res) => {
          setImageCropperModal(false);
          setCropImageUrl(false);
          setImageLoading(false);
          emit("setProfilePicture", { imageURl:  res.data.updateUser.profile_img});
          dispatch(setUserData({ ...userData, profile_img: res.data.updateUser.profile_img }));
          setTimeout(() => {
            setUserProfileData(res.data.updateUser)
            // setPreviewImg(res.data.updateUser.profile_img);
          }, 3000);
          setAnchorEl(null);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  //image new cropper
  function getCroppedImg(imageSrc, pixelCrop) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        const dataUrl = canvas.toDataURL("image/jpeg", 1);
        resolve(dataUrl);
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      image.src = imageSrc;
    });
  }



  const handleImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  var request = new XMLHttpRequest();
  request.open("GET", cropData, true);
  request.responseType = "blob";
  request.onload = function () {
    var reader = new FileReader();
    reader.readAsDataURL(request.response);
    reader.onload = function (e) {
      // setCropImageUrl(e.target.result);
      // console.log(e.target.result);
    };
  };
  request.send();

  const onZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const onZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  return (
    <>
      <Dialog
        open={imageCropperModal}
        onClose={() => {
          setImageCropperModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {showWebCam ? (
          <DialogContent>
            <div className="mt-4 h-[500px]">
              {/* {webcamImage === "" ? ( */}
              <Webcam
                style={{ margin: "auto" }}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                ref={webcamRef}
                mirrored={false}
              />
              {/* // ) : (
              //   <img src={webcamImage} alt="sdf" className="w-full h-[450px]" />
              // )} */}

              <div className="mt-5 flex justify-center gap-3">
                <button
                  className="p-2 my-2 gap-2 border w-40 bg-red-600 rounded-md "
                  onClick={() => {
                    setShowWebCam(false);
                    setWebcamImage("");
                    setImageCropperModal(false);
                  }}
                >
                  {t("cancel")}
                </button>
                <button
                  className="p-2 my-2 gap-2 border w-40 bg-[#33ccff] rounded-md "
                  onClick={() => {
                    if (webcamImage === "") {
                      captureWebcamImage();
                    } else {
                      setWebcamImage("");
                    }
                  }}
                >
                  {t("capture")}
                </button>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <div className=" p-0 bg-white">
              <div className="relative  p-0">
                <div style={{ width: "450px", height: "400px" }}>
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={7 / 7}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                      getCroppedImg(image, croppedAreaPixels).then(
                        (croppedImage: any) => {
                          setCropImageUrl(true);
                          fetch(croppedImage)
                            .then((res) => res.arrayBuffer())
                            .then((arrayBuffer) => {
                              // const url = window.URL.createObjectURL(blob);
                              const file = new File(
                                [arrayBuffer],
                                `${+new Date() - Math.random()}.jpg`,
                                {
                                  type: "image/jpeg",
                                }
                              );
                              setBlobImageUrl(file);
                            });
                        }
                      );
                    }}
                    onZoomChange={setZoom}
                    onMediaLoaded={(mediaSize) => {
                      // setZoom(500 / mediaSize.naturalHeight);
                    }}
                  />
                </div>
                <br style={{ clear: "both" }} />
              </div>
              <div className="my-auto px-2"></div>
            </div>
            <div className="flex justify-center gap-3 mt-3 py-3">
              {" "}
              <button
                onClick={onZoomIn}
                className="p-1 my-2 border bg-[#33ccff] rounded-md "
              >
                <AddIcon />
              </button>
              <button
                onClick={onZoomOut}
                className="p-1 my-2 border bg-[#33ccff] rounded-md"
              >
                <RemoveIcon />
              </button>
              <button
                className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
                onClick={() => {
                  if (rotation === 360) {
                    setRotation(0);
                  } else {
                    setRotation(rotation + 90);
                  }
                }}
              >
                <Rotate90DegreesCcwIcon /> {t("flip")}
              </button>
              <button
                onClick={handleUpdateImage}
                // disabled={cropImageUrl}
                className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
              >
                <CloudUploadIcon />
                <span className="mx-1"> {t("upload")} </span>
              </button>
              {webcamImage !== "" && (
                <button
                  onClick={() => {
                    setShowWebCam(true);
                    if (webcamImage === "") {
                      captureWebcamImage();
                    } else {
                      setWebcamImage("");
                    }
                  }}
                  className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
                >
                  <AddAPhotoIcon />
                  <span className="mx-1"> {t("retake")} </span>
                </button>
              )}
            </div>
          </DialogContent>
        )}
      </Dialog>

      <div className="w-[500px]">
        <div className="flex justify-between py-4 bg-[#F3F9FC] border-b px-2">
          <div>{t("profile")}</div>
          <div
            className="cursor-pointer"
            onClick={() => setProfilePageShow(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div
          className="flex justify-center items-center pt-6 cursor-pointer"
          onClick={handleClick}
        >
          <div className="w-[200px] min-w-[200px] h-[200px]">
            {imageLoading ? (
              <div className="flex justify-center my-16">
                <CircularProgress sx={{ height: 20, width: 20 }} />
              </div>
            ) : userProfileData?.profile_img ? (
              <img
                src={
                  userProfileData?.profile_img !== ""
                    ? DefaultImageUrl + userProfileData?.profile_img
                    : image
                }
                alt="profile"
                className="border rounded-full h-full w-full"
              />
            ) : (
              <Avatar sx={{ width: "200px", height: "200px" }} />
            )}
          </div>
        </div>
        {!showWebCam && !removeImage && (
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            id="imageUploadBtn"
            className="hidden"
            onChange={handleImageChange}
          />
        )}

        <div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{ cursor: "pointer" }}
          >
            <label >
              <div
                style={{ padding: "8px 13px" }}
                className="hover:bg-gray-200"
                onClick={() => {
                  setShowWebCam(true);
                  setImageCropperModal(true);
                }}
              >
                {t("take_photo")}
              </div>
            </label>
            <label htmlFor="imageUploadBtn">
              <div
                style={{ padding: "8px 13px" }}
                className="hover:bg-gray-200"
                onClick={() => {
                  setWebcamImage("");
                  setImage("");
                  setShowWebCam(false);
                  setRemoveImage(false);
                  setImageCropperModal(true);
                }}
              >
                {t("upload_photo")}
              </div>
            </label>
            <label >
              <div
                style={{ padding: "8px 13px" }}
                className="hover:bg-gray-200"
                onClick={() => {
                  setRemoveImage(true);
                  setBlobImageUrl("");
                  if (blobImageUrl === "") {
                    handleUpdateImage();
                  }
                }}
              >
                {t("remove_photo")}
              </div>
            </label>
          </Popover>
        </div>
        <div className="">
          <div className="px-4 pb-4 bg-gray-100 mt-12 mb-2 ">
            <div className="pt-2 pb-3 text-[15px]">{t("your_name")}</div>
            <div className="flex justify-between text-gray-700 cursor-pointer">
              {nameInputFieldEdit ? (
                <>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    sx={{ width: "85%" }}
                    defaultValue={
                      input.name.firstName + " " + input.name.lastName
                    }
                    name="name"
                    onChange={handleChange}
                  />
                  {loader.status && loader.type === 'name' ?
                    <CircularProgress style={{ height: 20, width: 20 }} />
                    : <button
                      onClick={() => {
                        setLoader({ status: true, type: "name" })
                        handleUpdateName();
                      }}
                    >
                      <DoneIcon />
                    </button>}
                </>
              ) : (
                <>
                  <div className="lightBlack">
                    {
                      // userProfileData?.firstName.length > 12
                      //   ? userProfileData?.firstName.slice(0, 12)
                      //   :
                      userProfileData?.firstName}
                    &nbsp;
                    {
                      // userProfileData?.lastName.length > 12
                      //   ? userProfileData?.lastName.slice(0, 12)
                      //   : 
                      userProfileData?.lastName}
                  </div>
                  {loader.status && loader.type === 'name' ?
                    <CircularProgress style={{ height: 20, width: 20 }} />
                    : <button onClick={() => setNameInputFieldEdit(true)}>
                      <EditIcon />
                    </button>}
                </>
              )}
            </div>
          </div>
          <div className="px-4 pb-4 bg-gray-100 mt-12 mb-2 ">
            <div className="pt-2 pb-3 text-[15px]">{t("about")}</div>
            <div className="flex justify-between text-gray-700 cursor-pointer">
              {bioInputFieldEdit ? (
                <>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    sx={{ width: "85%" }}
                    value={bioInput}
                    onChange={(e: any) => setBioInput(e.target.value)}
                  // name="bio"
                  />
                  {loader.status && loader.type === 'bio' ?
                    <CircularProgress style={{ height: 20, width: 20 }} />
                    : <button
                      onClick={() => {
                        handleUpdateBio();
                        setLoader({ status: true, type: "bio" })
                      }}
                    >
                      <DoneIcon />
                    </button>
                  }
                </>
              ) : (
                <>
                  <div className="lightBlack ">
                    {userProfileData?.bio?.status}
                  </div>
                  {loader.status && loader.type === 'bio' ?
                    <CircularProgress style={{ height: 20, width: 20 }} />
                    : <button onClick={() => setBioInputFieldEdit(true)}>
                      <EditIcon />
                    </button>
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileDrawer;
