import { ChangeEvent, useState } from "react";
import { TaskEdgesTypes } from "../../../Types/Types";
import { useGetLocation } from "../../../hooks/useGetLocation";
import { mutationClient } from "../../../Graphql/authClient";
import { UPLOAD_FILE } from "../../../Graphql/Mutations";
import { getSession } from "../../../utils/session";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskRadioInput = ({ currentTask, isExecutable, setResult, location, media, setTaskSubmitError, setAttachmentId, setLoading }: any) => {
  const { getCurrentLocation } = useGetLocation();
  const { t } = useTranslation();
  const {locationData}=useSelector((state: any) => state.chat);
  return (
    <>
      {currentTask?.type === "RADIO" &&
        isExecutable &&
        currentTask?.edges?.map((item: TaskEdgesTypes) => {
          return item?.options?.map((elem: any, index: number) => {
            return (
              item?.type !== "TIMEOUT" && (

                <label key={index} className="my-2 flex cursor-pointer">
                  <input
                    type={"radio"}
                    name="radio"
                    className="h-[20px] w-[20px] cursor-pointer"
                    value={elem.label}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (elem.location) {
                        if (locationData?.lat==="") {
                          getCurrentLocation();
                          alert(t('location_permission'))
                        } else {
                          setResult(e.target.value)
                        }
                      } else {
                        setResult(e.target.value)
                      }
                    }
                    }
                  />
                  <span className="ml-1">{elem?.label}</span>
                </label>
              )
            );
          });
        })
        }
    </>
  );
};

export default TaskRadioInput;
