import { Badge, Button, IconButton, Tooltip } from "@mui/material"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarViewMenu from "./CalendarViewMenu";
import { useTranslation } from "react-i18next";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useEffect, useState } from "react";
import InvitationDrawer from "./InvitationDrawer";
import { authCllient } from "../../../Graphql/authClient";
import { GET_CALENDAR_INVITATIONS } from "../../../Graphql/Queries";
import { useDispatch, useSelector } from "react-redux";
import { ParticipantAcceptStatus } from "../types/enum";
import { openNewEventDialog, setShowCalendar } from "../../../Redux/EventSlice";
import dayjs from "dayjs";
import DateSelect from "./DateSelect";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function CalendarAppHeader(props: any) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { userData } = useSelector((state: any) => state?.user)
    const { calendarRef, currentDate, isRefresh, setIsRefresh, viewMode, setViewMode, setTabValue } = props
    const calendarApi = () => calendarRef?.current?.getApi();
    const [invitations, setInvitations] = useState([])
    const [showInvitationDrawer, setShowInvitationDrawer] = useState(false)

    async function getInvitations() {
        try {
            const response = await authCllient.query({
                query: GET_CALENDAR_INVITATIONS,
            })

            if (response?.data?.getPendingReminder) {
                const tempInvitations = []
                response?.data?.getPendingReminder
                    ?.forEach(item => {
                        item?.participants?.forEach(el => {
                            if (el?._id == userData?._id && el?.accepted == ParticipantAcceptStatus.PENDING) {
                                tempInvitations.push(item)
                            }
                        })
                    })

                setInvitations(tempInvitations)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getInvitations()
    }, [])

    useEffect(() => {
        if (isRefresh) {
            getInvitations()
            setIsRefresh(false)
        }
    }, [isRefresh])

    function handleCreateReminder() {
        const obj = {
            start: dayjs(),
            end: dayjs(),
            jsEvent: {
                pageY: 185,
                pageX: 777,
            },
            roomId: "",
            roomType: "",
            roomParticipants: []
        }
        dispatch(openNewEventDialog(obj))
    }

    const handleGoBack = () => {
        setTabValue(2)
        dispatch(setShowCalendar(false))
    }

    return (
        <div className="flex flex-col md:flex-row w-full p-5 mb-2 justify-between">
            <div className="flex flex-col sm:flex-row items-center">
                <div className="flex items-center gap-12">
                    <Button
                        size="small"
                        color="inherit"
                        onClick={handleGoBack}
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        {t("back")}
                    </Button>

                    <div className="flex items-center gap-4">
                        <Tooltip
                            title={t("previous")}
                            onClick={() => calendarApi()?.prev()}
                        >
                            <IconButton aria-label="Previous">
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                        </Tooltip>

                        <div className="flex items-center">
                            <DateSelect
                                setViewMode={setViewMode}
                                calendarApi={calendarApi}
                                currentDate={currentDate}
                            />
                        </div>

                        <Tooltip
                            title={t("next")}
                            onClick={() => calendarApi()?.next()}
                        >
                            <IconButton aria-label="Next">
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Tooltip>

                        <button
                            onClick={() => calendarApi()?.today()}
                            className="border border-black text-sm rounded-md py-2 outline-none px-4"
                        >
                            {t("today")}
                        </button>

                        <Tooltip title={t("invites")}>
                            <IconButton
                                onClick={() => setShowInvitationDrawer(true)}
                            >
                                <Badge badgeContent={invitations?.length} color="primary">
                                    <NotificationsActiveOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center gap-4">
                <Tooltip title={t("create_reminder_appointment")}>
                    <button
                        onClick={handleCreateReminder}
                        className="border border-black text-sm rounded-md p-2 outline-none"
                    >
                        {t("create_reminder")}
                    </button>
                </Tooltip>

                <CalendarViewMenu
                    currentDate={currentDate}
                    calendarApi={calendarApi}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                />
            </div>

            {showInvitationDrawer && (
                <InvitationDrawer
                    invitations={invitations}
                    show={showInvitationDrawer}
                    setShow={setShowInvitationDrawer}
                    setIsRefresh={setIsRefresh}
                />
            )}
        </div>
    )
}

export default CalendarAppHeader