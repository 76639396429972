import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  AppPath,
  DefaultImageUrl,
  MuteActions,
  RoomActions,
} from "../../Constant/Constant";
import moment from "moment";
import {
  FoldersDataTypes,
  RoomActionsType,
  RoomsDataType,
} from "../../Types/Types";
import InventoryIcon from "@mui/icons-material/Inventory";
import broad from "../../Assets/broad.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BiVolumeMute } from "react-icons/bi";
import {
  appendOldChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomData,
  setCurrentPage,
  setFetchData,
  setOnlineRooms,
  setRooms,
  setTotalPage,
  setTotoalChatsCount,
} from "../../Redux/ChatSlice";
import { useDispatch, useSelector } from "react-redux";
import { emit, socketListen } from "../../Socket";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Toast from "../../coreComponent/Toast";
import pinIcon from "../../Assets/Images/PinMsg.png";
import MuteIcon from "../../Assets/Images/MuteMsg.png";
import DialogBox from "../../coreComponent/DialogBox";
import { setScrollBottom } from "../../Redux/UserSlice";
import parse from "html-react-parser";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { GET_ONLINE_USERS } from "../../Graphql/Queries";
import { authCllient } from "../../Graphql/authClient";

const ChatTab = ({ setHeaderTab }: any) => {
  const [chatTab, setChatTab] = useState<any>(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showActions, setShowActions] = useState(false);
  const [menuIndex, setMenuIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const RoomAction = RoomActions(t);
  const [chatRooms, setChatRooms] = useState([]);
  const [archivedRooms, setArchivedRooms] = useState([]);
  const [archivedChatsDrawer, setArchivedChatsDrawer] =
    useState<boolean>(false);
  const [archivedAction, setArchivedAction] = useState(false);
  const [arhivedActionIndex, setArchivedActionIndex] = useState(0);
  const [muteActions, setMuteActions] = useState(false);
  const [fixedRooms, setFixedRooms] = useState([]);
  const [fixedRoomsActions, setFixedRoomsActions] = useState(false);
  const navigate = useNavigate();
  const {
    onlineRooms,
    rooms,
    activeChatsLimit,
    activeRoomData,
    userOnlineStatus,
    formattedParticipantsOfActiveRoom,
    isRoomLoading,
  } = useSelector((state: any) => state.chat);
  const { userData, userContacts, comonUsers, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const isUserBlocked = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );

  const userInfo =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        )
      : activeRoomData?.participants?.length === 1
      ? activeRoomData?.participants[0]
      : null;

  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userInfo?.user_id
    );

  const screenWidth = window.innerWidth;
  const authToken = localStorage.getItem("token");
  const MuteAction = MuteActions(t);

  const roomActionsRef = useRef<any>(null);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [deleteRoomModal, setDeleteRoomModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState<any>("");
  const [fixedChatRoom, setFixedChatRoom] = useState([]);

  socketListen("message", (data: any) => {
    if (data.type === "deleteRoom") {
      setToastMessage(t("delete-room"));
      setToastShow(true);
    } else if (data.type === "muteRoom") {
      setToastMessage(t("muteRoom"));
      setToastShow(true);
    } else if (data.type === "unmuteRoom") {
      setToastMessage(t("unmuteRoom"));
      setToastShow(true);
    } else if (data.type === "archiveRoom") {
      setToastMessage(t("archived-room"));
      setToastShow(true);
    } else if (data.type === "clearAllChats") {
      setToastMessage(t("clear-chats"));
      setToastShow(true);
    } else if (data.type === "unArchiveRoom") {
      setToastMessage(t("unarchive-room"));
      setToastShow(true);
    } else if (data.type === "fixRoom") {
      setToastMessage(t("pinned_room"));
      setToastShow(true);
    } else if (data.type === "unfixRoom") {
      setToastMessage(t("unpinned_room"));
      setToastShow(true);
    } else if (data.type === "GetAllRooms") {
      const filteredData = data?.msg?.rooms.filter(
        (item: any) => item?.organization === ""
      );
      dispatch(setRooms(filteredData));
    }
  });

  useEffect(() => {
    setChatTab(1);
  }, [userData]);

  useEffect(() => {
    if (!authToken) {
      navigate(AppPath.login);
    }
  }, [authToken, navigate]);

  const getOnlineUsers = async () => {
    try {
      const response = await authCllient.query({
        query: GET_ONLINE_USERS,
      });

      const result = response?.data?.getOnlineUsersForWeb;
      if (result) {
        const uesrIds: string[] = [];
        result?.forEach((el: any) => {
          if (el?.status == "online") {
            uesrIds.push(el?.userId);
          }
        });

        const onlineRooms = [];
        rooms?.forEach((room: any) => {
          if (room?.type == "individual") {
            const otherPart = room?.participants?.find(
              (el) => el?.user_id != userData?._id
            );

            if (uesrIds?.includes(otherPart?.user_id)) {
              onlineRooms.push(room?._id);
            }
          }
        });

        dispatch(setOnlineRooms(onlineRooms));
      }
    } catch (error) {
      console.log(`Error in online users: ${error}`);
    }
  };

  useEffect(() => {
    handleSetRoomsData();
    getOnlineUsers()
  }, [rooms]);

  const handleSetRoomsData = () => {
    const tempArchived: any = [];
    const tempChatRoom: any = [];
    const tempFixedRoom: any = [];

    rooms?.forEach((one: any) => {
      const isArchived = one?.archivedBy.find(
        (aOne: any) => aOne?.user_id === userData?._id
      );

      const isFixed = one?.fixedBy.find(
        (aOne: any) => aOne?.user_id === userData?._id
      );

      if (isArchived) {
        tempArchived.push(one);
      } else if (isFixed) {
        tempChatRoom.unshift(one);
      } else {
        tempChatRoom.push(one);
      }
    });

    setChatRooms(tempChatRoom);
    setArchivedRooms(tempArchived);
    setFixedRooms(tempFixedRoom);
  };

  const handleRoomClick = (item: RoomsDataType, unreadMessage: any) => {
    if (!isRoomLoading) {
      emit("GetRoomById", { roomId: item?._id });
      setHeaderTab(2);
      if (item?._id !== activeRoomData?._id) {
        dispatch(setFetchData(true));
        dispatch(setScrollBottom(true));
        setArchivedChatsDrawer(false);
        dispatch(setActiveRoomData(item));
        emit("GetRoomById", { roomId: item?._id });
        socketListen("message", (data: any) => {
          if (data.type === "GetRoomById") {
            if (data?.msg?.room?._id === item?._id) {
              dispatch(
                setActiveRoomData({
                  ...data?.msg?.room,
                  topics: data?.msg?.topics,
                  contactData: data?.msg?.contactData,
                })
              );
            }
          }
        });
        // emit("getChatsByRoomId", { roomId: item?._id, skip: 0, limit: 50 });
        dispatch(setTotalPage(Math.ceil(item?.totalChats / activeChatsLimit)));
        dispatch(setActiveRoomChats([]));
        dispatch(appendOldChats([]));
        //dispatch(setTotoalChatsCount(50));
        //  dispatch(setActiveChatSkip(50));
        emit("setChatReadBy", {
          roomId: item?._id,
          cid: unreadMessage[0]?.unread_cid,
        });
        if (screenWidth < 900) {
          navigate(AppPath.chatPage);
        }
      } else {
      }
    }
  };

  const handleRoomActions = (item: RoomsDataType, type: string) => {
    if (type === "exportChatsByRoomId") {
      emit("exportChatsByRoomId", {
        roomId: item?._id,
        region: "Asia/Kolkata",
        attach: true,
      });
    } else if (type === "fixRoom") {
      if (fixedRooms?.length < 3) {
        emit("fixRoom", { roomId: item?._id });
        const tempArr: any = [...rooms];
        const updatedRoom = tempArr?.map((elem: any, index: number) => {
          if (item?._id === elem?._id) {
            return {
              ...elem,
              fixedBy: ["0"],
            };
          }
          return elem;
        });
        dispatch(setRooms(updatedRoom));
      } else if (fixedRooms?.length === 3) {
        setToastMessage(t("pin_validation"));
        setToastShow(true);
      }
    } else if (type === "setChatReadBy") {
      emit("setChatReadBy", {
        roomId: item?._id,
        cid: [],
      });
    } else {
      if (type == "deleteRoom") {
        if (activeRoomData?._id == item?._id) {
          dispatch(setActiveRoomData({}));
        }
      }
      emit(type, { roomId: item?._id });
    }

    setShowActions(false);
    setFixedRoomsActions(false);
    setArchivedChatsDrawer(false);
  };
  const [item, setItem] = useState(null);
  const handleRoomMute = (expire_at: string) => {
    emit("muteRoom", {
      roomId: item?._id,
      created_at: item?.created_at,
      expired_at: expire_at,
    });
    setMuteActions(false);
    setShowActions(false);
  };

  const renderLastMessage = (
    item: any,
    userInfo: any,
    first: string,
    last: string
  ) => {
    const message = item?.last_msg[0];
    const sender = item?.participants?.find(
      (el: any) => el?.user_id === message?.sender
    );
    const senderContactDetail = formattedContact[sender?.user_id];

    let lastMessage: any;

    if (item?.type === "group" || item?.type === "broadcast") {
      if (message?.type === "createdRoom") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message
                    ?.replace("created group", t("created-group"))
                    .replace(/\s+/g, " ")
                    .trim()
                    .slice(0, 22) + "..."
                : message?.message
                    .replace("created group", t("created-group"))
                    .replace(/\s+/g, " ")
                    .trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "addedUser" ||
        message?.type === "removedUser"
      ) {
        const jsonMsg = JSON.parse(message?.message);
        const findUser = item.participants?.find(
          (el: any) => el.user_id === jsonMsg?.pid
        );
        lastMessage = (
          <div >
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + " "
                : senderContactDetail && senderContactDetail?.firstName !== ""
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName +
                  " "
                : sender?.phone + " "}
            </span>
            <span className="">
              {jsonMsg?.msg
                .replace("added", t("added"))
                .replace("removed", t("removed"))
                .replace(/\s+/g, " ")
                .trim()}
            </span>
            <span className="ml-1">
              {jsonMsg?.pid === userData?._id
                ? t("you")
                : formattedContact[jsonMsg?.pid]
                ? formattedContact[jsonMsg?.pid]?.firstName +
                  " " +
                  formattedContact[jsonMsg?.pid]?.lastName
                : findUser?.phone}
            </span>
          </div>
        );
      } else if (
        message?.type === "changedDescription" ||
        message?.type === "changedName" ||
        message?.type === "changedPicture" ||
        message?.type === "leftRoom" ||
        message?.type === "chatDisappear"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail && senderContactDetail?.firstName !== ""
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message
                    ?.replace(
                      "changed the name of group",
                      t("name-change-group")
                    )
                    .replace(
                      "changed the description of group",
                      t("description-change-group")
                    )
                    .replace(
                      "changed the picture of the group",
                      t("picture-change-group")
                    )
                    .replace(
                      "turned on disappearing messages. All new messages",
                      t("chat-disapper-1")
                    )
                    .replace(
                      "turned off disappearing messages",
                      t("turn-off-disapper")
                    )
                    .replace("left the group", t("left-group"))
                    .replace(
                      "changed the name of broadcast",
                      t("broad-name-change")
                    )
                    .replace(
                      "changed the description of broadcast",
                      t("broad-desc-change")
                    )
                    .replace(
                      "changed the picture of the broadcast",
                      t("broad-image-change")
                    )
                    .replace(/\s+/g, " ")
                    .trim()
                    .slice(0, 22) + "..."
                : message?.message
                    .replace(
                      "changed the name of group",
                      t("name-change-group")
                    )
                    .replace(
                      "changed the description of group",
                      t("description-change-group")
                    )
                    .replace(
                      "changed the picture of the group",
                      t("picture-change-group")
                    )
                    .replace(
                      "turned on disappearing messages. All new messages",
                      t("chat-disapper-1")
                    )
                    .replace(
                      "turned off disappearing messages",
                      t("turn-off-disapper")
                    )
                    .replace("left the group", t("left-group"))
                    .replace(/\s+/g, " ")
                    .trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail && senderContactDetail?.firstName !== ""
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone + " "}
            </span>
            <span className="ml-1">{`${t("shared_file")} ${message?.type
              .replace("IMAGE", t("image"))
              .replace("VIDEO", t("video"))
              .replace("AUDIO", t("audio"))
              .replace("DOCUMENT", t("document"))} `}</span>
          </div>
        );
      } else if (message.type === "APPLICATION") {
        lastMessage = (
          <div>
            {message.message ? (
              <div>{message?.message}</div>
            ) : (
              <>
                <span className="capitalize">
                  {message?.sender === userData?._id
                    ? t("you") + ": "
                    : senderContactDetail &&
                      senderContactDetail?.firstName !== ""
                    ? senderContactDetail?.firstName +
                      " " +
                      senderContactDetail?.lastName
                    : sender?.phone}
                </span>
                <span className="ml-1">{t("shared_file")}</span>
              </>
            )}
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail && senderContactDetail?.firstName !== ""
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">{`${t("shared_contact")}`}</span>
          </div>
        );
      } else if (message?.type === "text" || message?.type === "Link") {
        const data =
          message?.message
            .replace("You deleted this message", t("you-message-deleted"))
            .replace("This message was deleted", t("this-message-deleted"))
            .replace("You are assigned", t("you_are_assigned"))
            .replace("please accept the invitation to join", t("accept-invite"))
            .replace("to start execution", t("start-execution"))
            .replace("by", t("by"));
        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (
            match: any,
            p1: any,
            p2: any,
            p3: any,
            p4: any,
            p5: any,
            p6: any,
            p7: any,
            p8: any,
          ) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return `<div style="">` + p5 + "</div>";
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find(part => part?.user_id == p8)
              const self = userData?._id == participant?.user_id
              const formatPart = formattedContact?.[participant?.user_id]
              const mentionedName = self 
              ? `${t("you")} `
              : formatPart?.firstName
              ? formatPart?.firstName + " " + formatPart?.lastName
              : participant
              ? participant?.phone
              : p7;
              return '<span style="color: green;">' + "@" + mentionedName + "</span>"
            }
            return match;
          }
        );
        lastMessage = (
          <div>
            <span>
              {parse(formattedText)}
            </span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        )
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="mx-1"> {t("started")} </span>
            <span>
              {message?.type === "audioCall"
                ? t("audio_call")
                : t("video_call")}
            </span>
          </div>
        );
      }
    } else {
      const isUnknown = first ? false : true;

      if (message.type === "invited") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";

        lastMessage =
          message?.sender === userData?._id
            ? t("accept-1") +
              " " +
              (isUnknown ? userInfo?.phone : first) +
              " " +
              (isUnknown ? "" : last) +
              " " +
              t("accept-2") +
              " " +
              data?.orgName +
              " "
            : (isUnknown ? userInfo?.phone : first) +
              " " +
              (isUnknown ? "" : last) +
              " " +
              t("accept-3") +
              " " +
              data?.orgName;
      }
      if (message.type === "declined") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";
        //data.msg.slice(0,28)+'...'
        lastMessage =
          message?.sender === userData?._id
            ? t("you") +
              " " +
              t("msg-1") +
              " " +
              data?.orgName +
              " " +
              t("msg-2") +
              "..."
            : (isUnknown ? userInfo?.phone : first) +
              " " +
              (isUnknown ? "" : last) +
              " " +
              t("msg-5") +
              " " +
              data?.orgName +
              " " +
              t("msg-2") +
              "... ";
      }
      if (message.type === "taskAssigned") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";
        //data.msg.slice(0,28)+'...'
        lastMessage =
          message?.sender === userData?._id
            ? t("task-assigned") +
              " " +
              data?.scenarioName +
              " " +
              t("to") +
              " " +
              (isUnknown ? userInfo?.phone : first) +
              " " +
              (isUnknown ? "" : last)
            : (isUnknown ? userInfo?.phone : first) +
              " " +
              (isUnknown ? "" : last) +
              " " +
              t("assigned-you") +
              " " +
              data?.scenarioName;
      } else if (message?.type === "text" || message?.type === "Link") {
        const data =
          message?.message
            .replace("You deleted this message", t("you-message-deleted"))
            .replace("This message was deleted", t("this-message-deleted"))
            .replace("You are assigned", t("you_are_assigned"))
            .replace("please accept the invitation to join", t("accept-invite"))
            .replace("to start execution", t("start-execution"))
            .replace("by", t("by"));
        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (
            match: any,
            p1: any,
            p2: any,
            p3: any,
            p4: any,
            p5: any,
            p6: any,
            p7: any,
            p8: any,
          ) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return `<div style="">` + p5 + "</div>";
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find(part => part?.user_id == p8)
              const self = userData?._id == participant?.user_id
              const formatPart = formattedContact?.[participant?.user_id]
              const mentionedName = self 
              ? `${t("you")} `
              : formatPart?.firstName
              ? formatPart?.firstName + " " + formatPart?.lastName
              : participant
              ? participant?.phone
              : p7;
              return '<span style="color: green;">' + "@" + mentionedName + "</span>"
            }
            return match;
          }
        );
        lastMessage = (
          <div>
            <span>
              {parse(formattedText)}
            </span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        )
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "TEXT" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName +
                  " "
                : sender?.phone}
            </span>
            <span className="ml-1">{`${t("shared_file")} ${message?.type
              .replace("IMAGE", t("image"))
              .replace("VIDEO", t("video"))
              .replace("AUDIO", t("audio"))
              .replace("DOCUMENT", t("document"))} `}</span>
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName +
                  " "
                : sender?.phone}
            </span>
            <span className="">{t("shared_contact")}</span>
          </div>
        );
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="mx-1"> {t("started")} </span>
            <span>
              {message?.type === "audioCall"
                ? t("audio_call")
                : t("video_call")}
            </span>
          </div>
        );
      }
    }

    return lastMessage
  };

  const renderLastMessageTime: any = (item: any) => {
    let date;

    if (item?.last_msg[0]?.created_at !== 0) {
      const gettingDate = moment(item?.last_msg[0]?.created_at).format(
        "DD.MM.YYYY"
      );

      const timeStamp = moment(item?.last_msg[0]?.created_at).format("HH:mm");

      const todayDate = moment(new Date()).format("DD.MM.YYYY");

      const yesterdayDate = moment(new Date())
        .subtract(1, "day")
        .format("DD.MM.YYYY");

      if (gettingDate === todayDate) {
        date = timeStamp;
      } else if (gettingDate === yesterdayDate) {
        date = t("yesterday");
      } else {
        date = gettingDate;
      }
    } else {
      date = moment(item?.created_at).format("DD.MM.YYYY");
    }

    return <span> {date} </span>;
  };

  useEffect(() => {
    const checkIsClickOutside = (e: any) => {
      if (
        (showActions || fixedRoomsActions || archivedAction) &&
        roomActionsRef.current &&
        !roomActionsRef.current.contains(e.target)
      ) {
        setShowActions(false);
        setFixedRoomsActions(false);
        setArchivedAction(false);
      }
    };
    document.addEventListener("mousedown", checkIsClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIsClickOutside);
    };
  }, [showActions, fixedRoomsActions, archivedAction]);

  const RenderRoomsData = ({ chatRooms }: any) => {
    return (
      <>
        {chatTab > 1 && (
          <div>
            {fixedChatRoom?.map((item: RoomsDataType, index: number) => {
              const unreadMsgs = item?.participants?.filter(
                (ele: any) => ele.user_id === userData?._id && ele
              );

              const userInfo = item?.participants?.find(
                (one: any) => one?.user_id !== userData?._id
              );

              const userContactInfo = userContacts?.find(
                (one: any) => one?.userId?._id === userInfo?.user_id
              );

              const isRoomBlocked = userData?.blockedRooms?.some(
                (elem: any) => elem?.room_Id === item?._id
              );

              return (
                <div
                  key={index}
                  onBlur={() => {
                    setFixedRoomsActions(false);
                  }}
                  className="w-full mr-auto relative flex items-center"
                >
                  {fixedRoomsActions && menuIndex === index && (
                    <div
                      className="absolute right-[10px] top-[40px] z-10 w-[50%] border bg-gray-100 p-2 text-center  text-gray-800"
                      ref={roomActionsRef}
                    >
                      {!muteActions && (
                        <>
                          {RoomAction?.map(
                            (elem: RoomActionsType, index: number) =>
                              elem.action === "markRoomUnread" &&
                              item.type === "broadcast" ? (
                                ""
                              ) : (
                                <div
                                  className="cursor-pointer hover:bg-white border-b-2 py-1 text-sm"
                                  key={index}
                                  onClick={() =>
                                    handleRoomActions(item, elem?.action)
                                  }
                                >
                                  {elem?.label}
                                </div>
                              )
                          )}
                          <div
                            className="mt-2 cursor-pointer hover:bg-white border-b-2 text-sm"
                            onClick={() => {
                              if (item?.fixedBy?.length > 0) {
                                handleRoomActions(item, "unfixRoom");
                              } else {
                                handleRoomActions(item, "fixRoom");
                              }
                            }}
                          >
                            {item?.fixedBy?.length > 0
                              ? t("unpinned")
                              : t("pin")}
                          </div>
                        </>
                      )}
                      {muteActions ? (
                        <>
                          {/* {MuteAction?.map(
                            (elem: RoomActionsType, index: number) => (
                              <div
                                className="cursor-pointer hover:bg-white border-b-2 text-sm"
                                key={index}
                                onClick={() =>
                                  handleRoomMute( elem.action)
                                }
                              >
                                {elem.label}
                              </div>
                            )
                          )} */}
                        </>
                      ) : (
                        item?.type !== "broadcast" && (
                          <div
                            className="mt-2 cursor-pointer hover:bg-white border-b-2 text-sm"
                            onClick={() => {
                              if (item?.mutedBy?.length > 0) {
                                handleRoomActions(item, "unmuteRoom");
                              } else {
                                setMuteActions(true);
                              }
                              setShowActions(false);
                            }}
                          >
                            {item?.mutedBy?.length > 0
                              ? t("unmute")
                              : t("mute")}
                          </div>
                        )
                      )}
                      {!muteActions && (
                        <div
                          className="mt-2 cursor-pointer hover:bg-white text-red-600"
                          onClick={() => {
                            setFixedRoomsActions(false);
                            setMuteActions(false);
                          }}
                        >
                          {t("cancel")}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={clsx(
                      "flex justify-between py-3 my-1 px-4 w-full",
                      isRoomLoading ? "cursor-not-allowed" : ""
                    )}
                    onClick={() => handleRoomClick(item, unreadMsgs)}
                  >
                    <div
                      className={clsx(
                        "flex",
                        isRoomLoading ? "cursor-not-allowed" : "cursor-pointer"
                      )}
                    >
                      {item?.type === "group" || item?.type === "broadcast" ? (
                        item?.profile_img !== "" ? (
                          <div className="h-[45px] w-[45px]">
                            <img
                              src={DefaultImageUrl + item?.profile_img}
                              alt="profile"
                              className="h-[45px] w-[45px] rounded-full"
                            />
                          </div>
                        ) : (
                          <div>
                            <Avatar />
                          </div>
                        )
                      ) : userInfo?.profile_image !== "" &&
                        userInfo?.left_at === 0 &&
                        !isRoomBlocked ? (
                        <div className="h-[45px] w-[45px]">
                          <img
                            src={DefaultImageUrl + userInfo?.profile_img}
                            alt="profile"
                            className="h-[45px] w-[45px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div>
                          <Avatar />
                        </div>
                      )}

                      <div className="pl-[14px]">
                        <div className="lightBlack dark:text-white text-[15px]">
                          {item?.type === "group" ||
                          item?.type === "broadcast" ? (
                            item?.name
                          ) : userContactInfo &&
                            (userContactInfo?.firstName !== "" ||
                              userContactInfo?.lastName !== "") ? (
                            <span>
                              <span>{userContactInfo?.firstName}</span>
                              <span> {userContactInfo?.lastName} </span>
                            </span>
                          ) : (
                            <span>{userInfo?.phone}</span>
                          )}
                        </div>
                        <div className="textGray line-clamp-2 dark:text-white text-[13px]">
                          {renderLastMessage(
                            item,
                            userInfo,
                            userContactInfo?.firstName ?? userInfo?.phone,
                            userContactInfo?.lastName ?? ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <div className="flex gap-1 items-center">
                        <div className="ml-1 textGray text-[12px] dark:text-white flex items-center gap-2">
                          {renderLastMessageTime(item)}

                          {item?.fixedBy?.length > 0 && (
                            <button className="">
                              <img
                                src={pinIcon}
                                alt="pin"
                                className="w-4 h-4"
                              />
                            </button>
                          )}

                          {item?.mutedBy?.length > 0 && (
                            <button className="">
                              <img
                                src={MuteIcon}
                                alt="mute"
                                className="w-4 h-4"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                      {unreadMsgs[0]?.unread_cid?.length > 0 && (
                        <div className="dark:text-white bg-primary border rounded-full text-center text-white text-[12px] w-[25px] h-[24px] flex justify-center items-center mt-2">
                          {unreadMsgs[0]?.unread_cid?.length}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="cursor-pointer lightBlack"
                    onClick={() => {
                      setFixedRoomsActions(true);
                      setItem(item);
                      setMenuIndex(index);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {chatTab === 1 && (
          <div>
            {chatRooms?.map((item: RoomsDataType, index: number) => {
              const unreadMsgs = item?.participants?.filter(
                (ele: any) => ele.user_id === userData?._id && ele
              );

              const userInfo =
                item?.participants.length > 1
                  ? item?.participants?.find(
                      (one: any) => one?.user_id !== userData?._id
                    )
                  : item?.participants.length === 1
                  ? item?.participants[0]
                  : null;

              const userContactInfo = userContacts?.find(
                (one: any) => one?.userId?._id === userInfo?.user_id
              );

              const userProfileImg =
                item?.participants?.length > 0 &&
                item?.participants?.find(
                  (item: any) => item?.user_id === userInfo?.user_id
                );

              const isRoomBlocked =
                userData?.blockedRooms?.length > 0
                  ? userData?.blockedRooms?.some(
                      (elem: any) => elem?.room_Id === item?._id
                    )
                  : false;

              return (
                item?.deletedBy?.length === 0 &&
                userInfo !== undefined && (
                  <div
                    key={index}
                    className={`w-full mr-auto relative flex ${
                      activeRoomData?._id === item?._id ? "bg-[#E0FAFF]" : ""
                    } items-center `}
                  >
                    {showActions && menuIndex === index && (
                      <div
                        className="absolute right-[10px] top-[40px] z-10 w-[50%] border bg-gray-100 p-2 text-center text-gray-800"
                        // ref={roomActionsRef}
                      >
                        {!muteActions && (
                          <>
                            {RoomAction?.map(
                              (elem: RoomActionsType, index: number) => {
                                if (item.totalChats === 0) {
                                  if (
                                    elem.action === "clearAllChats" ||
                                    elem.action === "markRoomUnread"
                                  ) {
                                    return;
                                  }
                                }
                                if (
                                  elem.action === "markRoomUnread" &&
                                  item.type === "broadcast"
                                ) {
                                  return;
                                }
                                return (
                                  <div
                                    className="cursor-pointer hover:bg-white border-b-2 text-sm py-1"
                                    key={index}
                                    onClick={() => {
                                      if (
                                        item?.unreadBy.length > 0 &&
                                        elem.action === "markRoomUnread"
                                      ) {
                                        handleRoomActions(
                                          item,
                                          "setChatReadBy"
                                        );
                                      } else {
                                        handleRoomActions(item, elem?.action);
                                      }
                                    }}
                                  >
                                    {item?.unreadBy.length > 0
                                      ? elem.action === "markRoomUnread"
                                        ? t("mark_as_read")
                                        : elem?.label
                                      : elem?.label}
                                  </div>
                                );
                              }
                            )}
                            <div
                              className="mt-2 cursor-pointer hover:bg-white border-b-2 text-sm"
                              onClick={() => {
                                if (item?.fixedBy?.length > 0) {
                                  handleRoomActions(item, "unfixRoom");
                                } else {
                                  handleRoomActions(item, "fixRoom");
                                }
                              }}
                            >
                              {item?.fixedBy?.length > 0
                                ? t("unpinned")
                                : t("pin")}
                            </div>

                            {/*  <div
                              className="mt-2 cursor-pointer hover:bg-white border-b-2 text-sm"
                              onClick={() => {
                                setDeleteRoomModal(true);
                                setDeleteModalData(item);
                              }}
                            >
                              {t("delete_room")}
                            </div> */}
                          </>
                        )}
                        {muteActions ? (
                          <>
                            {/* {MuteAction?.map(
                              (elem: RoomActionsType, index: number) => (
                                <div
                                  className="cursor-pointer hover:bg-white border-b-2 text-sm"
                                  key={index}
                                  onClick={() =>
                                    handleRoomMute(item, elem.action)
                                  }
                                >
                                  {elem.label}
                                </div>
                              )
                            )} */}
                          </>
                        ) : (
                          item.type !== "broadcast" && (
                            <div
                              className="mt-2 cursor-pointer hover:bg-white border-b-2 text-sm"
                              onClick={() => {
                                if (item?.mutedBy?.length > 0) {
                                  handleRoomActions(item, "unmuteRoom");
                                } else {
                                  setMuteActions(true);
                                }
                                setShowActions(false);
                              }}
                            >
                              {item?.mutedBy?.length > 0
                                ? t("unmute")
                                : t("mute")}
                            </div>
                          )
                        )}

                        {!muteActions && (
                          <div
                            className="mt-2 cursor-pointer hover:bg-white text-red-600 text-sm"
                            onClick={() => {
                              setShowActions(false);
                              setMuteActions(false);
                            }}
                          >
                            {t("cancel")}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className={clsx(
                        "flex justify-between py-2  px-4 w-full",
                        isRoomLoading ? "cursor-not-allowed" : ""
                      )}
                      onClick={() => {
                        setShowActions(false);
                        handleRoomClick(item, unreadMsgs);
                      }}
                    >
                      <div
                        className={clsx(
                          "flex relative",
                          isRoomLoading
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        )}
                      >
                        {item?.type === "group" ||
                        item?.type === "broadcast" ? (
                          item?.profile_img !== "" ? (
                            <div className="h-[45px] w-[45px]">
                              <img
                                src={DefaultImageUrl + item?.profile_img}
                                alt="profile"
                                className="h-[45px] w-[45px] rounded-full"
                              />
                            </div>
                          ) : (
                            <div>
                              <Avatar />
                            </div>
                          )
                        ) : isRoomBlocked ? (
                          <div>
                            <Avatar />
                          </div>
                        ) : (
                          <div className="h-[45px] w-[45px]">
                            <img
                              src={DefaultImageUrl + userInfo?.profile_img}
                              alt="profile"
                              className="h-[45px] w-[45px] rounded-full"
                            />
                          </div>
                        )}

                        {item?.blocked === false &&
                        item?.type === "individual" &&
                        !isUserBlocked &&
                        onlineRooms.includes(item?._id) ? (
                          <div
                            className={`h-[15px] w-[15px] border-white bg-green-600 border-2 rounded-full absolute left-[35px] top-[30px]`}
                          ></div>
                        ) : item.type === "broadcast" ? (
                          <div
                            className={`   rounded-full absolute left-[35px]  bg-white top-[30px]`}
                          >
                            <img
                              src={broad}
                              className="h-[15px] w-[15px]  object-contain"
                            />
                          </div>
                        ) : (
                          <div
                            className={`h-[15px] w-[15px]   rounded-full absolute left-[35px] top-[30px]`}
                          ></div>
                        )}
                        <div className="pl-[14px]">
                          <div
                            className={`lightBlack dark:text-white text-[15px] ${
                              unreadMsgs[0]?.unread_cid?.length > 0
                                ? "font-bold"
                                : ""
                            }`}
                          >
                            {item?.type === "group" ||
                            item?.type === "broadcast" ? (
                              <div>{item?.name}</div>
                            ) : //.slice(0, 18)
                            userContactInfo &&
                              (userContactInfo?.firstName !== "" ||
                                userContactInfo?.lastName !== "") ? (
                              <span>
                                <span>{userContactInfo?.firstName}</span>
                                <span> {userContactInfo?.lastName} </span>
                              </span>
                            ) : (
                              <span>{userInfo?.phone}</span>
                            )}
                          </div>
                          <div className="textGray line-clamp-2 dark:text-white text-[13px] max-w-[15rem]">
                            {renderLastMessage(
                              item,
                              userInfo,
                              userContactInfo?.firstName ?? userInfo?.phone,
                              userContactInfo?.lastName ?? ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-end">
                        <div className="flex gap-1 items-center">
                          <div className="ml-1 textGray text-[12px] dark:text-white flex items-center gap-2">
                            {renderLastMessageTime(item)}
                            {item?.fixedBy?.length > 0 && (
                              <button className="">
                                <img
                                  src={pinIcon}
                                  alt="pin"
                                  className="w-4 h-4"
                                />
                              </button>
                            )}
                            {item?.mutedBy?.length > 0 && (
                              <button className="outline-noe bg-gray-500 p-1 rounded-full">
                                <BiVolumeMute className="text-white" />
                              </button>
                            )}
                          </div>
                          {item.totalChats > 0 &&
                            item?.unreadBy?.length > 0 && (
                              <div className="h-[10px] w-[10px] bg-primary ml-1 rounded-full"></div>
                            )}
                        </div>
                        {item.totalChats > 0 &&
                          unreadMsgs[0]?.unread_cid?.length > 0 && (
                            <div className="dark:text-white bg-primary border rounded-full text-center text-white text-[12px] w-[25px] h-[24px] flex justify-center items-center mt-2">
                              {unreadMsgs[0]?.unread_cid?.length}
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      className="cursor-pointer lightBlack"
                      onClick={() => {
                        setShowActions(true);
                        setMenuIndex(index);
                        setItem(item);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                )
              );
            })}
          </div>
        )}
      </>
    );
  };

  const handleFolderClick = (item: any) => {
    let temp: any = [...rooms];
    temp = rooms?.filter((one: any) => item?.roomId?.includes(one?._id));
    setChatRooms(temp);
    setFixedChatRoom(temp);
  };

  const handleRoomDelete = () => {
    dispatch(setActiveRoomData({}));
    emit("deleteRoom", {
      roomId: deleteModalData?._id,
    });
    setDeleteRoomModal(false);
  };

  const deleteAlertModal = () => {
    return (
      <div className="p-[14px]">
        <div className="flex justify-center pt-2"></div>
      </div>
    );
  };

  const handleSearchRooms = (event) => {
    handleSearchRooms(event.target.value);
  };

  return (
    <div>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      <Dialog
        open={muteActions}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setShowActions(false);
          setMuteActions(false);
          setFixedRoomsActions(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-description"
          className="bg-[#E0FAFF]"
        >
          <h1> {t("mute_notifications")}</h1>
        </div>
        <DialogContent className="text-center">
          {MuteAction?.map((item: any, index: number) => (
            <div
              id="alert-dialog-slide-description"
              key={index}
              className="cursor-pointer pb-4"
              onClick={() => handleRoomMute(item.action)}
            >
              {item.label}
              <hr className="mt-2" />
            </div>
          ))}
        </DialogContent>
        <DialogActions id="alert-dialog-slide-description">
          <div
            className="cursor-pointer py-2 px-2 font-extrabold text-white ml-auto flex rounded-md  bg-primary w-fit"
            onClick={() => {
              setShowActions(false);
              setMuteActions(false);
              setFixedRoomsActions(false);
            }}
          >
            {t("cancel")}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteRoomModal}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setDeleteRoomModal(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-description"
          className="bg-[#E0FAFF]"
        >
          <h1 className="text-[26px] "> {t("confirmation")}</h1>
        </div>
        <DialogContent className="text-left">
          <div className="text-[16px] mt-4 lightBlack pb-2">
            {t("delete_msg")}
          </div>
        </DialogContent>
        <DialogActions id="alert-dialog-slide-description">
          <div
            className="border px-4 py-1 rounded cursor-pointer bg-red-400 text-white"
            onClick={() => handleRoomDelete()}
          >
            {t("delete")}
          </div>
          <div
            className="border px-4 bg-primary text-white py-1 rounded ml-2 cursor-pointer"
            onClick={() => setDeleteRoomModal(false)}
          >
            {t("cancel")}
          </div>
        </DialogActions>
      </Dialog>
      {/*  <DialogBox
        open={deleteRoomModal}
        handleClose={() => setDeleteRoomModal(false)}
        content={deleteAlertModal()}
      /> */}

      <Drawer
        open={archivedChatsDrawer}
        onClose={() => setArchivedChatsDrawer(false)}
      >
        <div className="min-w-[400px]">
          <div className="w-full px-4 text-center py-6 text-[22px] sticky top-0 z-50 bg-[#F3F9FC]">
            <div className="float-left">
              <button onClick={() => setArchivedChatsDrawer(false)}>
                <ArrowBackIcon />
              </button>
            </div>
            <div className="text-gray-800">{t("archived_chats")}</div>
          </div>

          {archivedRooms?.map((item: RoomsDataType, index: number) => {
            const unreadMsgs = item?.participants?.filter(
              (ele: any) => ele.user_id === userData?._id && ele
            );

            const userInfo = item?.participants?.find(
              (one: any) => one?.user_id !== userData?._id
            );

            const userContactInfo = userContacts?.find(
              (one: any) => one?.userId?._id === userInfo?.user_id
            );

            const isRoomBlocked = userData?.blockedRooms?.some((elem: any) =>
              elem?.room_id === item?._id ? true : false
            );
            return (
              <div
                className="px-4 group flex items-center relative"
                key={index}
              >
                {archivedAction && arhivedActionIndex === index && (
                  <div
                    className="absolute right-[10px] top-[60px] z-10 w-[50%] border bg-gray-100 p-2 text-center  text-gray-800"
                    onClick={() => setArchivedAction(false)}
                    ref={roomActionsRef}
                  >
                    <div
                      className="cursor-pointer hover:bg-white border-b-2 text-sm py-1"
                      onClick={() => {
                        setDeleteRoomModal(true);
                        setDeleteModalData(item);
                      }}
                    >
                      {t("delete_room")}
                    </div>
                    <div
                      className="cursor-pointer hover:bg-white border-b-2 text-sm py-1"
                      onClick={() => handleRoomActions(item, "unArchiveRoom")}
                    >
                      {t("unarchived")}
                    </div>
                    <div
                      className="mt-2 cursor-pointer hover:bg-white text-red-600 text-sm"
                      onClick={() => setArchivedAction(false)}
                    >
                      {t("cancel")}
                    </div>
                  </div>
                )}

                <div className="my-2 w-[90%]" key={index}>
                  <div
                    className={clsx(
                      "flex justify-between items-center my-1",
                      isRoomLoading ? "cursor-not-allowed" : ""
                    )}
                    onClick={() => handleRoomClick(item, unreadMsgs)}
                  >
                    <div
                      className={clsx(
                        "flex",
                        isRoomLoading ? "cursor-not-allowed" : "cursor-pointer"
                      )}
                    >
                      {item?.type === "group" || item?.type === "broadcast" ? (
                        item?.profile_img !== "" ? (
                          <div className="h-[45px] w-[45px]">
                            <img
                              src={DefaultImageUrl + item?.profile_img}
                              alt="profile"
                              className="h-[45px] w-[45px] rounded-full"
                            />
                          </div>
                        ) : (
                          <div>
                            <Avatar />
                          </div>
                        )
                      ) : userInfo?.profile_image !== "" &&
                        userInfo?.left_at === 0 &&
                        !isRoomBlocked ? (
                        <div className="h-[45px] w-[45px]">
                          <img
                            src={DefaultImageUrl + userInfo?.profile_img}
                            alt="profile"
                            className="h-[45px] w-[45px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div>
                          <Avatar />
                        </div>
                      )}

                      <div className="pl-[14px]">
                        <div className="lightBlack dark:text-white text-[15px]">
                          {item?.type === "group" ||
                          item?.type === "broadcast" ? (
                            item?.name
                          ) : userContactInfo !== undefined &&
                            (userContactInfo?.firstName !== "" ||
                              userContactInfo?.lastName !== "") ? (
                            <span>
                              <span>{userContactInfo?.firstName}</span>
                              <span> {userContactInfo?.lastName} </span>
                            </span>
                          ) : (
                            <span>{userInfo?.phone}</span>
                          )}
                        </div>
                        <div className="textGray line-clamp-2 dark:text-white text-[13px] max-w-[15rem]">
                          {renderLastMessage(
                            item,
                            userInfo,
                            userContactInfo?.firstName,
                            userContactInfo?.lastName
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <div className="flex gap-1 items-center">
                        <div className="textGray text-[12px] dark:text-white">
                          {renderLastMessageTime(item)}
                        </div>
                      </div>
                      {unreadMsgs[0]?.unread_cid?.length > 0 && (
                        <div className="dark:text-white bg-primary border rounded-full text-center text-white text-[12px] w-[25px] h-[24px] flex justify-center items-center mt-2">
                          {unreadMsgs[0]?.unread_cid?.length}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="group-hover:block hidden cursor-pointer ml-2"
                  onClick={() => {
                    setArchivedAction(true);
                    setArchivedActionIndex(index);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </div>
              </div>
            );
          })}
        </div>
      </Drawer>

      <div
        onMouseLeave={() => {
          setShowActions(false);
          setMuteActions(false);
          setFixedRoomsActions(false);
        }}
        className=""
      >
        <div className="lightBlack pt-2 text-[15px] flex px-4 border-b-2 w-full overflow-auto gap-4 h-[60px]">
          <div
            className={
              chatTab === 1
                ? "py-2 border-botom-primary cursor-pointer text-sm capitalize min-w-max"
                : "py-2 cursor-pointer text-sm dark:text-white capitalize min-w-max"
            }
            onClick={() => {
              setChatTab(1);
              handleSetRoomsData();
            }}
          >
            {t("all_chats")}
          </div>
          {userData?.folders?.map((item: FoldersDataTypes, index: number) => {
            return (
              <div
                className={
                  chatTab === index + 2
                    ? "py-2 border-botom-primary cursor-pointer text-sm capitalize min-w-max"
                    : "py-2 cursor-pointer text-sm dark:text-white capitalize min-w-max"
                }
                onClick={() => {
                  setChatTab(index + 2);
                  handleFolderClick(item);
                }}
                key={index}
              >
                {item?.name}
              </div>
            );
          })}
        </div>

        {/* removed large-size from here */}
        <div id="roomListScroll" className=" overflow-y-auto overflow-x-hidden xl:h-[calc(100vh-250px)] h-[calc(100vh-270px)]">
          {archivedRooms?.length > 0 && (
            <div
              className="flex items-center gap-5 w-full mr-auto px-10 py-2 cursor-pointer"
              onClick={() => setArchivedChatsDrawer(true)}
            >
              <div className="bg-blue-800 w-max px-2 py-2 flex items-center h-max rounded-full">
                <InventoryIcon className="text-white" sx={{ fontSize: 20 }} />
              </div>
              <div className="w-full flex justify-between text-gray-800">
                <div>{t("archived_chats")}</div>
                <div>{archivedRooms?.length}</div>
              </div>
            </div>
          )}

          {/* <div>
                  {chatTab === 1 && <RenderRoomsData chatRooms={chatRooms} />}
                </div> */}

          <div>{chatTab >= 1 && <RenderRoomsData chatRooms={chatRooms} />}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatTab;
