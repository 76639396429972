import { DefaultImageUrl } from "../../../Constant/Constant";

const ReportQuestionVideo = ({ data }: any) => {
  return (
    <>
      {data?.attachment?.type === "VIDEO" && (
        <div className="mt-3 w-[300px] h-[400px]">
          <video
            src={DefaultImageUrl + data?.attachment?.filename}
            className="w-full h-full"
            controls
          ></video>
        </div>
      )}
    </>
  );
};

export default ReportQuestionVideo;
