import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlinePhotoCamera, MdOutlineInsertPhoto } from "react-icons/md";
import { BsHeadphones } from "react-icons/bs";
import copy from "../Assets/Images/ChatMsgPoper/copy.png";
import forwad from "../Assets/Images/ChatMsgPoper/forwad.png";
import reply from "../Assets/Images/ChatMsgPoper/reply.png";
import favourite from "../Assets/Images/ChatMsgPoper/favourite.png";
import pin from "../Assets/Images/ChatMsgPoper/pin.png";
import heading from "../Assets/Images/storage.png";
import info from "../Assets/Images/ChatMsgPoper/info.png";
import delet from "../Assets/Images/ChatMsgPoper/delete.png";
import { TFunction } from "react-i18next";

export const App_version = "1.1.28 (development)";
//export const App_version="1.1.0 (Stage)";
export const App_Name = "ComOn Web";
export const App_id = "86c93a4f76bc4f069cd51ffed38048e5";

// development
export const SocketUrl = "https://comon.peclick.com?token=";
export const GraphQlUrl = "https://comon.peclick.com/graphql";

// live
// export const SocketUrl = "https://api-pr-preview-pb2r7hxayq-ey.a.run.app?token=";
// export const GraphQlUrl = "https://api-pr-preview-pb2r7hxayq-ey.a.run.app/graphql";

export const taskFlowUrl = "https://fe-pr-preview-pb2r7hxayq-ez.a.run.app/";

export const DefaultImageUrl = "https://storage.googleapis.com/comon-bucket/";

export const TaskAttachmentUrl = "https://comon.peclick.com";

// export const JWT_EXPIRE = 180;
// for 1 hour 45 minute
export const JWT_EXPIRE = 6300;

export const ImageUrl = "resources/images/16694514102-individual_default.png";

export const GroupDefault = "resources/images/16694514287-group_default.png";

export const vapidKey =
  "BHf3bS-a1nX_f5k-FTzTAhYGnarNGkXwILUoHEY_E1au9y-QRmGpmIGaWjFeEmceeWt_q41vbj58ojct80yYk4o";

export const soundUrl =
  "https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7";

export const comonDownloadAppUrl = "https://comon.com.de/";

export const AppPath = {
  login: "/login",
  loginWithToken: "/login-with-token",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
  chatPage: "/chat-page",
  home: "/",
  homeWithId: "/:roomId",
  videoCall: "/videoCall",
  audioCall: "/audioCall",
};

export const RoomActions = (t: TFunction) => [
  { action: "archiveRoom", label: t("archive") },
  { action: "exportChatsByRoomId", label: t("export_chat") },
  { action: "markRoomUnread", label: t("mark_as_unread") },
  { action: "clearAllChats", label: t("clear_chats") },
  { action: "deleteRoom", label: t("delete_room") },
];

export const MuteActions = (t: TFunction) => [
  { action: "8h", label: t("8_hours") },
  { action: "1w", label: t("1_week") },
  { action: "Always", label: t("always") },
];

export const ShareMedia = [
  {
    htmlFor: "document",
    icon: (
      <IoDocumentTextOutline className="text-xl" style={{ color: "white" }} />
    ),
    id: "document",
    accept:
      ".json,.csv,.xlsx,.xls,.ppt,.pptx,.pdf,.rtf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain",
    tooltip: "uploaddocuments",
  },
  {
    htmlFor: "camera",
    icon: (
      <MdOutlinePhotoCamera className="text-xl" style={{ color: "white" }} />
    ),
    id: "camera",
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    tooltip: "capture_image",
  },
  {
    htmlFor: "gallery",
    icon: (
      <MdOutlineInsertPhoto className="text-xl" style={{ color: "white" }} />
    ),
    id: "gallery",
    accept: "video/*,image/*",
    tooltip: "chooseimagevideo",
  },
  {
    htmlFor: "audios",
    icon: <BsHeadphones className="text-xl" style={{ color: "white" }} />,
    id: "audios",
    accept: ".mp3",
    tooltip: "chooseaudio",
  },
];

export const MessageActions = (t: TFunction) => [
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={copy} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("copy")}</div>
      </>
    ),
    action: "copy",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={forwad} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("forward_message")}</div>
      </>
    ),
    action: "forward",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={reply} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("reply")}</div>
      </>
    ),
    action: "reply",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={favourite} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("favourite")}</div>
      </>
    ),
    action: "favourite",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={favourite} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("un_favourite")}</div>
      </>
    ),
    action: "unFavourite",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={pin} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("pin")}</div>
      </>
    ),
    action: "pin",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={info} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("info")}</div>
      </>
    ),
    action: "info",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={heading} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("create-heading")}</div>
      </>
    ),
    action: "heading",
  },
  {
    ui: (
      <>
        <div className="pr-2">
          <img src={delet} className="w-[20px]" alt="sdf" />
        </div>
        <div>{t("delete")}</div>
      </>
    ),
    action: "delete",
  },
];

export const googleMapCapture = (data) => {
  let staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";

  staticMapUrl += `?size=${data?.size?.width || 600}x${
    data?.size?.height || 400
  }`;

  staticMapUrl += "&zoom=" + (data?.zoom ?? 12);

  if (data?.marker) {
    staticMapUrl += `&markers=${data.marker.lat},${data.marker.lng}`;
  }

  if (data?.direction) {
    staticMapUrl +=
      "&path=color:0xff0000ff|weight:5|51.511333,-0.119355|51.506205,-0.114462|51.507774,-0.10861|51.507801,-0.107569|51.5071,-0.107344|51.507207,-0.105016|51.507507,-0.104404|51.510999,-0.104297";
  }
  staticMapUrl += "&key=AIzaSyA0g8vdRBeD45kSyMAk4Fxb0M9DIw1chr8";

  return staticMapUrl;
};

export const colors = {
  reminder: "#135D66",
  appointment: "#ff8183",
  callReminder: "#34ccff",
  assignment: "#115ABB",
  schedule: "#DAD3BE",
  record: "#e46f54",
};

export const CALENDER_SOCKET_EVENTS = [
  "EVENT_CREATION_REMINDER",
  "Update_REMINDER",
  "delete_REMINDER",
  "Resend_INVITATION_REMINDER",
  "status_updated",
];

export const SCHEDULE_SOCKET_EVENT = [
  "Create_Schedule",
  "MESSAGE_DELIVERED",
  "Update_SCHEDULE",
  "status_updated",
  "delete_REMINDER",
];

export const EVENT_INVITATION = [
  "EVENT_INVITATION_REMINDER",
  "SCHEDULE_BEFORE_REMINDER",
];
