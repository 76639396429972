import { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllReportsDataTypes, AssignmentDataTypes } from "../../Types/Types";
import {
  setAssignments,
  setCurrentAssignment,
  setCurrentOrganization,
  setCurrentReport,
  setLoadingState,
  setReportsLoading,
  setScenarioLoading,
  setShowTaskPage,
  setTaskData,
} from "../../Redux/OrganizationSlice";
import moment from "moment";
import { useOrganizations } from "../../hooks/useOrganizations";
import { setActiveRoomData } from "../../Redux/ChatSlice";
import { CircularProgress, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { currentWeekOfCurrentMonth } from "../../utils/task";
import { useTranslation } from "react-i18next";
import { socketListen } from "../../Socket";

interface TaskPropsType {
  setShowReportPage: (value: boolean) => void;
  setShowChatPage: (value: boolean) => void;
  tabButton: any;
  setTabButton: any;
}

function Task({ setShowReportPage, setShowChatPage, tabButton, setTabButton }: TaskPropsType) {

  const { assignments, allReports, currentNotifyData, totalAssignment, taskData, notificationArray, totalReportCount, currentOrganization, organizations, currentReport, currentAssignment } = useSelector(
    (state: any) => state.organization
  );
  const [loader, setLoader] = useState(true);
  const { getReportById, getMyReports, getScenarioById, getMyAssignment } = useOrganizations();
  const [isReportLoading, setIsReportLoading] = useState(true);
  const { rooms } = useSelector((state: any) => state.chat);
  const { userData } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [currentSelectTask, setCurrentSelectTask] = useState<any>({});
  const [eventData, setEventData] = useState<any>({});
  const { t } = useTranslation()
  const postRef = createRef<HTMLDivElement>();
  const [currentGoalPageNo, setcurrentGoalPageNo] = useState(0);
  const [currentReportPageNo, setcurrentReportPageNo] = useState(0);
  const [reportDataLoading, setReportDataLoading] = useState(false);
  const [assignmentDataLoading, setAssignmentDataLoading] = useState(false);
  const handleViewTask = (
    _id: string,
    activeReportId: string | null,
    item: AssignmentDataTypes
  ) => {
    setCurrentSelectTask(item);
    const currentChatRoom = rooms?.find(
      (elem: any) => elem?._id === item?.roomId
    );
    dispatch(setActiveRoomData(currentChatRoom));
    dispatch(setScenarioLoading(true));
    dispatch(setShowTaskPage(true));
    dispatch(setCurrentAssignment(item));
    setShowReportPage(false);
    setShowChatPage(false);
    getScenarioById(_id, item?.roomId);

    const member = item?.members.filter(
      (item: any) => item?.member?.user?._id === userData._id
    );

    if (member[0]?.activeReportId) {
      getReportById(member[0]?.activeReportId);
    } else {
      dispatch(setCurrentReport({}));
    }
  };

  const handleViewReport = (_id: string) => {
    setShowReportPage(true);
    dispatch(setShowTaskPage(false));
    setShowChatPage(false);
    dispatch(setReportsLoading(true));
    getReportById(_id);
  };

  function checkTimeValidation(item: any, showToday: any) {
    // authCllient
    // .mutate({
    //   mutation:CHECK_TIME_VALIDATION,
    //   variables: {
    //     input: {
    //        "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    //        "currentTime": new Date().getTime()
    //     },
    //   },
    // })
    // .then((res) => {
    //  if(res.data?.checkIsValidDate?.isCorrectTime===false){
    //     alert('Please correct date and time.')
    //   }else{
    if (showToday === false) {
    } else {
      handleViewTask(
        item?.scenario?._id,
        item?.activeReportId,
        item
      );
    }
    //   }
    // });

  }
  /*  */
  useMemo(() => {
    if (JSON.stringify(currentSelectTask) !== "{}") {
      if (JSON.stringify(eventData) !== "{}" && eventData?.assignment !== undefined && currentAssignment._id === eventData?.assignment?._id) {
        handleViewTask(
          eventData?.assignment?.scenario?._id,
          '',
          eventData?.assignment
        );

      }
    } else {
      if (JSON.stringify(eventData) !== "{}" && localStorage.getItem("currentOrganization") !== undefined && localStorage.getItem("currentOrganization") !== 'undefined') {
        getMyAssignment(JSON.parse(localStorage.getItem("currentOrganization"))?._id, 0, 0)
      }
    }
  }, [eventData])


  useEffect(() => {
    socketListen("message", (data: any) => {

      if (data.type === "taskSubmit") {
        console.log(data,'type***********')
        setEventData(data?.msg?.data)
      }
      if (data.type === 'addAssignment' ||data.type === 'addAssignmentMember' || data.type === 'removeAssignmentMember' || data.type === 'taskUpdated' || data?.type === "updateScenario") {
        if (currentOrganization?._id === data?.msg?.orgId) {
          getMyAssignment(JSON.parse(localStorage.getItem("currentOrganization"))?._id, 0, 0)
        }
      }
      if (data.type === 'overDueReminder') {
        if (currentOrganization?._id === data?.msg?.orgId) {

          let temp = assignments.map((element: any) => ({ ...element, 'inprogress': false, 'overdue': element._id === data?.msg?.assignmentId }))

          dispatch(setAssignments(temp));
        }
      }
      if (data.type === 'taskStartingReminder') {
        if (currentOrganization?._id === data?.msg?.orgId) {

          let temp = assignments.map((element: any) => ({ ...element, 'inprogress': element._id === data?.msg?.assignmentId }))

          dispatch(setAssignments(temp));
        }
      }
    });
  }, [assignments])


  useEffect(() => {
    setIsReportLoading(false)
  }, [allReports?.length])

  const setCurrentOrg = async (orgId: any, org: any,) => {
    const parsed = JSON.parse(currentNotifyData?.data)?.data
    const firstNotifi = JSON.parse(parsed)
    console.log("firstNotifi", firstNotifi)
    const organizationId = firstNotifi?.organizationId;
    const assignmentId = firstNotifi?.assignmentId;
    const reportId = firstNotifi?.reportId;
    if (currentOrganization._id !== organizationId) {
        localStorage.setItem("currentOrganization", JSON.stringify(org));
        dispatch(setCurrentOrganization(org))
        getMyAssignment(orgId, 0, 0).then(() => {
          
          dispatch(setLoadingState({ status: false, text: '' }))
          /* getMyReports(0, 0).then(() => {
            if (currentNotifyData?.type === 'TASK_COMPLETE') {
              setTimeout(() => {
                dispatch(setLoadingState({ status: false, text: '' }))
                setReportDataLoading(false);
                setShowReportPage(true);
                dispatch(setShowTaskPage(false));
                setShowChatPage(false);
                dispatch(setReportsLoading(false));
              }, 2000);
            }
          }); */
        })
    }
    
    switch (currentNotifyData?.type) {
      case 'REMINDER': {
        //  if (currentOrganization._id === organizationId) {
          if (assignments.filter((el: any) => el._id === assignmentId).length > 0) {
          dispatch(setLoadingState({ status: true, text: t('fetch-task-data') }))

          handleViewTask(
            assignments.find((el: any) => el._id === assignmentId)?.scenario?._id,
            "",
            assignments.find((el: any) => el._id === assignmentId)
          );
         // dispatch(setTaskData(false))
         dispatch(setLoadingState({ status: false, text: '' }))
        }
        //}
        break;
      }
        // window.location.href = 
        // window.history.replaceState({ idx: 1 }, '', event.data.url);
      
      case 'TASK_COMPLETE': {
        //dispatch(setTaskData(false))

        //  setOrgReport(organizations.find((el: any) => el._id === organizationId)?._id, organizations.find((el: any) => el._id === organizationId), reportId)
        dispatch(setLoadingState({ status: true, text: t('fetch-reports') }))
        getMyReports(0, 0).then((res) => {
          
          /* setTimeout(() => {
            setReportDataLoading(false);
            setShowReportPage(true);
            dispatch(setShowTaskPage(false));
            setShowChatPage(false);
            dispatch(setReportsLoading(false));
          }, 2000); */
        });
        
      }
        break;
    }


  }





  useEffect(() => {
    if (assignments) {
      setLoader(false);
    }
  }, [assignments])

  useEffect(() => {
    if (currentNotifyData?.data!==undefined&&JSON.stringify(currentNotifyData)!=="{}" && taskData) {
      const parsed = JSON.parse(currentNotifyData?.data)?.data
      const firstNotifi = JSON.parse(parsed)
      const organizationId = firstNotifi?.organizationId;
      const assignmentId = firstNotifi?.assignmentId;
      const reportId = firstNotifi?.reportId;
      //  if (currentOrganization._id !== organizationId) {
      setCurrentOrg(organizations.find((el: any) => el._id === organizationId)?._id, organizations.find((el: any) => el._id === organizationId))
      //}
    }

  }, [tabButton, taskData, notificationArray,currentOrganization,assignments])






  const onScroll = () => {
    if (postRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = postRef.current;
      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        if (tabButton === 1) {
          if (totalAssignment >= currentGoalPageNo) {
            if (!assignmentDataLoading) {
              getMyAssignment(organizations[0]?._id, 0, currentGoalPageNo + 25).then(() => { setAssignmentDataLoading(false) });
            }

            setcurrentGoalPageNo(currentGoalPageNo + 25)
            setAssignmentDataLoading(true)
          }
        } else {
          if (totalReportCount >= currentReportPageNo) {
            if (!reportDataLoading) {
              getMyReports(0, currentReportPageNo + 25).then(() => { setReportDataLoading(false) });
            }

            setcurrentReportPageNo(currentReportPageNo + 25)
            setReportDataLoading(true)
          }
        }
      }
    }
  };

  return (
    <div className="h-screen">
      <div className="flex text-sm pt-4 border-b">
        <div
          className={
            tabButton === 1
              ? "text-center border-b-2 border-botom-primary px-7 w-1/2 pb-2 cursor-pointer"
              : "text-center px-7 w-1/2 pb-2 cursor-pointer dark:text-white"
          }
          onClick={() => {
            dispatch(setCurrentAssignment({})); dispatch(setCurrentReport({})); setTabButton(1);
            setShowReportPage(false);
            dispatch(setShowTaskPage(false)); dispatch(setCurrentAssignment({})); dispatch(setCurrentReport({}))
          }}
        >
          {t("goals")}
        </div>
        <div
          className={
            tabButton === 2
              ? "text-center px-7 w-1/2 cursor-pointer border-botom-primary"
              : "text-center px-7 w-1/2 cursor-pointer dark:text-white"
          }
          onClick={() => {
            setIsReportLoading(true);
            setTabButton(2);
            setShowReportPage(false);
            dispatch(setShowTaskPage(false));
            dispatch(setCurrentAssignment({}))
            dispatch(setCurrentReport({}))
            getMyReports(0, 0).finally(() => setIsReportLoading(false));
          }}
        >
          {t("reports")}
        </div>
      </div>

      {tabButton === 1 && (
        <div
          // className="overflow-y-scroll child pb-3 
          //  mb-5"
          className="overflow-y-auto h-[calc(100vh-382px)] pb-3 mt-4  mb-5"
          ref={postRef}
          onScroll={onScroll}
        >
          {loader ?
            <div>
              <div className="flex justify-center mt-4">
                <CircularProgress sx={{ color: "#17b8ee" }} size={30} />
              </div>
            </div>
            : Object?.keys(currentOrganization)?.length > 0 ? (

              assignments?.length > 0 ? (
                assignments?.map((item: AssignmentDataTypes, index: number) => {
                  const getWeek = item?.daylyParams?.dayOfWeeks;
                  const getMonth = item?.montlyParams?.months;
                  const startDate = moment(item?.start).format("DD MMMM YYYY");
                  const startTime = moment(item?.startTimeInMs).format("HH:mm");
                  const startDateForMonth = moment(item?.start).format("DD yyyy");
                  const endDateForMonth = moment(item?.end).format("DD yyyy");
                  const currentDateForMonth = moment().format("DD yyyy");
                  const currentWeekDay = moment().format("ddd");
                  const month = moment().format("MMM");
                  let message = "";
                  const member = item?.members.filter(
                    (item: any) => item?.member?.user?._id === userData._id
                  );
                  const currentTime = moment().format("hh:mm");
                  const cctime = dayjs(item.startTimeInMs).format("hh:mm");
                  let overdue = false;
                  if (item.recurrent === 'ANYTIME' || item.recurrent === 'ONCE') {
                    overdue = false;
                  } else {
                    //  console.log(dayjs(member[0]?.completeTime).format('DD MM YYYY HH:mm'))
                    if (moment().format('HH:mm').valueOf() !== moment(item.startTimeInMs).add(2, 'minutes').format('HH:mm').valueOf() && moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() && member[0]?.completeTime !== null && !dayjs(member[0]?.completeTime).isSame(dayjs())) {
                      // console.log('first')
                      overdue = true
                    }
                    // console.log(moment().format('HH:mm').valueOf()!==moment(item.startTimeInMs).format('HH:mm').valueOf())
                    if ((item.inprogress === undefined || item.inprogress === false) && moment().format('HH:mm').valueOf() !== moment(item.startTimeInMs).format('HH:mm').valueOf() && moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() && member[0]?.completeTime === null) {
                      //console.log('second')
                      overdue = true
                    }
                  }
                  let inprogress = false;
                  if (item.recurrent === 'ANYTIME' || item.recurrent === 'ONCE') {
                    inprogress = false;
                  } else {
                    //  console.log(dayjs(member[0]?.completeTime).format('DD MM YYYY HH:mm'))
                    if (moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf() && member[0]?.completeTime !== null && !dayjs(member[0]?.completeTime).isSame(dayjs())) {
                      // console.log('first')
                      inprogress = true
                    }
                    // console.log(moment().format('HH:mm').valueOf()!==moment(item.startTimeInMs).format('HH:mm').valueOf())
                    if ((moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf()) && member[0]?.completeTime === null) {
                      //console.log('second')
                      inprogress = true
                    }
                  }
                  // console.log(dayjs().isBefore(dayjs(item.end),'date'),dayjs().isSame(dayjs(item.end),'date'))
                  /*  if(item?.recurrent==='DAILY')
                 console.log(item?.recurrent,currentTime,cctime,moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')),'condition',dayjs().isAfter(dayjs(item.start)),'edn',
                 (dayjs().isBefore(dayjs(item.end))||dayjs().isSame(dayjs(item.end))),moment(item.end),moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')),'same',moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')) ) */
                  let showToday = false;
                  switch (item?.recurrent) {
                    case "ANYTIME":
                      showToday = true;
                      break;
                    case "ONCE":
                      if (
                        dayjs().format("DD/MM/YYYY") ===
                        dayjs(startDate).format("DD/MM/YYYY")
                      ) {
                        if (
                          dayjs(member[0]?.completeTime).format("DD/MM/YYYY") ===
                          dayjs().format("DD/MM/YYYY")
                        ) {
                          showToday = false;
                          message = t("task_completed");
                        } else {
                          if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                            showToday = true;
                          } else {
                            showToday = false;
                            message = t("task_condition1");
                          }
                        }
                      } else {
                        showToday = false;
                        message = t("task_condition1");
                      }

                      break;
                    case "DAILY":
                      if (dayjs().isAfter(dayjs(item.start)) &&
                        (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
                        if (
                          dayjs(member[0]?.completeTime).format("DD/MM/YYYY HH:mm") ===
                          dayjs().format("DD/MM/YYYY HH:mm")
                        ) {
                          showToday = false;
                          message = t("task_completed");
                        } else {
                          if ((moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
                            showToday = true;
                          } else {
                            showToday = false;
                            message = t("task_condition1");
                          }
                        }
                      } else {
                        showToday = false;
                        message = t("task_condition2");
                      }
                      break;
                    case "WEEKLY":
                      switch (item.daylyParams?.everyWeek) {
                        case 1:
                          if (
                            dayjs().isAfter(dayjs(item.start)) &&
                            (dayjs().isBefore(dayjs(item.end), 'date') || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf()) && (moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())
                          ) {
                            if (
                              dayjs(member[0]?.completeTime).format(
                                "DD/MM/YYYY HH:mm"
                              ) === dayjs().format("DD/MM/YYYY HH:mm")
                            ) {
                              showToday = false;
                              message = t('task_completed');
                            } else {
                              if ((moment().format('HH:mm').valueOf() > moment(item.startTimeInMs).format('HH:mm').valueOf() || moment().format('HH:mm').valueOf() === moment(item.startTimeInMs).format('HH:mm').valueOf())) {
                                showToday = true;
                              } else {
                                showToday = false;
                                message = t('task_condition1');
                              }
                            }
                          } else {
                            showToday = false;
                            message = t('task_condition2');
                          }
                          break;
                        case 2:
                          if (currentWeekOfCurrentMonth() === 2) {
                            if (getWeek.includes(currentWeekDay.toUpperCase())) {
                              if (
                                dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                                (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                              ) {
                                if (
                                  dayjs(member[0]?.completeTime).format(
                                    "DD/MM/YYYY HH:mm"
                                  ) === dayjs().format("DD/MM/YYYY HH:mm")
                                ) {
                                  showToday = false;
                                  message = t('task_completed');
                                } else {
                                  if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                                    showToday = true;
                                  } else {
                                    showToday = false;
                                    message = t('task_condition1');
                                  }
                                }
                              } else {
                                showToday = false;
                                message = t('task_condition2');
                              }
                            } else {
                              showToday = false;
                              message = t('task_condition2');
                            }
                          } else {
                            showToday = false;
                            message = t('task_condition2');
                          }
                          break;
                        case 3:
                          if (currentWeekOfCurrentMonth() === 3) {
                            if (getWeek.includes(currentWeekDay.toUpperCase())) {
                              if (
                                dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                                (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                              ) {
                                if (
                                  dayjs(member[0]?.completeTime).format(
                                    "DD/MM/YYYY HH:mm"
                                  ) === dayjs().format("DD/MM/YYYY HH:mm")
                                ) {
                                  showToday = false;
                                  message = t('task_completed');
                                } else {
                                  if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                                    showToday = true;
                                  } else {
                                    showToday = false;
                                    message = t('task_condition1');
                                  }
                                }
                              } else {
                                showToday = false;
                                message = t('task_condition2');
                              }
                            } else {
                              showToday = false;
                              message = t('task_condition2');
                            }
                          } else {
                            showToday = false;
                            message = t('task_condition2');
                          }
                          break;
                        case 4:
                          if (currentWeekOfCurrentMonth() === 4) {
                            if (getWeek.includes(currentWeekDay.toUpperCase())) {
                              if (
                                dayjs().isAfter(dayjs(item.startTimeInMs)) &&
                                (dayjs().isBefore(dayjs(item.end), 'date') || dayjs().isSame(dayjs(item.end), 'date')) && (moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))
                              ) {
                                if (
                                  dayjs(member[0]?.completeTime).format(
                                    "DD/MM/YYYY HH:mm"
                                  ) === dayjs().format("DD/MM/YYYY HH:mm")
                                ) {
                                  showToday = false;
                                  message = t('task_completed');
                                } else {
                                  if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                                    showToday = true;
                                  } else {
                                    showToday = false;
                                    message = t("task_condition1");
                                  }
                                }
                              } else {
                                showToday = false;
                                message = t('task_condition2');
                              }
                            } else {
                              showToday = false;
                              message = t('task_condition2');
                            }
                          } else {
                            showToday = false;
                            message = t('task_condition2');
                          }
                          break;
                      }
                      break;
                    case "MONTHLY":
                      if (item?.montlyParams?.twicePerMonth === false) {
                        if (
                          currentDateForMonth >= startDateForMonth &&
                          currentDateForMonth <= endDateForMonth
                        ) {
                          if (
                            dayjs(member[0]?.completeTime).format(
                              "DD/MM/YYYY HH:mm"
                            ) === dayjs().format("DD/MM/YYYY HH:mm")
                          ) {
                            showToday = false;
                            message = t("task_completed");
                          } else {
                            console.log(moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')), currentTime, cctime, dayjs())
                            if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                              showToday = true;
                            } else {
                              showToday = false;
                              message = t('task_condition1');
                            }
                          }
                        } else {
                          showToday = false;
                          message = t('task_condition2');
                        }
                      } else {
                        if (getMonth.includes(month.toLocaleUpperCase())) {
                          if (
                            currentDateForMonth >= startDateForMonth &&
                            currentDateForMonth <= endDateForMonth
                          ) {
                            if (
                              dayjs(member[0]?.completeTime).format(
                                "DD/MM/YYYY HH:mm"
                              ) === dayjs().format("DD/MM/YYYY HH:mm")
                            ) {
                              showToday = false;
                              message = t('task_completed');
                            } else {

                              if ((moment(currentTime, 'HH:mm').isAfter(moment(cctime, 'HH:mm')) || moment(currentTime, 'HH:mm').isSame(moment(cctime, 'HH:mm')))) {
                                showToday = true;
                              } else {
                                showToday = false;
                                message = t(`task_condition1`);
                              }
                            }
                          } else {
                            showToday = false;
                            message = t("task_condition2");
                          }
                        }
                      }
                      break;
                  }
                  return (
                    <Tooltip title={message} placement="right-start">
                      <>
                        <div
                          key={item?._id}
                          className={`ml-2  border-l-4 border-black border-left-primary pl-4 pr-2 my-2 ${currentAssignment?._id === item._id ? "bg-[#E0FAFF]" : 'dark:bg-gray-300'}  cursor-pointer ${showToday === false ? "opacity-[.5]  bg-slate-200" : ""
                            }`}
                          onClick={() => {
                            checkTimeValidation(item, showToday);
                          }}
                        >
                          <div className="dark:text-white">
                            {item?.scenario.name?.length > 60
                              ? item?.scenario?.name?.slice(0, 60) + "..."
                              : item?.scenario?.name}
                          </div>
                          <div className="dark:text-white text-sm text-gray-400">
                            {item?.scenario.description?.length > 60
                              ? item?.scenario?.description?.slice(0, 60) + "..."
                              : item?.scenario.description}
                          </div>
                          {item?.recurrent === "ANYTIME" ?
                            (
                              <div className="my-1 flex gap-2">
                                <span>{t("start_time")} :</span>
                                <span>
                                  {t("anytime")}
                                </span>
                              </div>
                            )
                            : startTime !== "00:00" && (
                              <div className="my-1 flex w-full justify-between">
                                <div className="my-1 flex">
                                  <span>{t("start_time")} : </span>
                                  <span className="flex">
                                    {" "}
                                    {dayjs(item?.start).format("DD.MM.YYYY HH:mm") + " "}{" "}
                                  </span>
                                </div>
                                <span>
                                  {showToday && (item.inprogress || inprogress) && <div className="pl-2 ml-2 mt-1 text-green-500 w-fit rounded-md">{t('to-perform')}</div>}
                                  {showToday && (item.overdue || overdue) && <div className="pl-2 ml-2 mt-1 text-red-500 w-fit rounded-md" >{t("overdue")}</div>}
                                </span>
                              </div>
                            )}

                        </div>
                        {index !== assignments.length - 1 && <hr className="" />}
                      </>
                    </Tooltip>
                  );
                })
              ) : (
                <div className="d-flex items-center justify-center mt-20 text-center">
                  <h1>{t("task_complete")}</h1>
                </div>
              )
            ) : (
              <div className="mt-6 text-center">
                {t("chooses_an_organisation")} <br />
                {t("view_the_task")}
              </div>
            )}
          {reportDataLoading && <div className="flex justify-center mx-auto ">
            <CircularProgress size={40} />
          </div>}
        </div>
      )}

      {tabButton === 2 && (
        <div
          // className="overflow-y-scroll child pb-3 mt-4  mb-5"
          className="overflow-y-auto h-[calc(100vh-382px)] pb-3 mt-4  mb-5"
          ref={postRef}
          onScroll={onScroll}
        >
          {isReportLoading ? (
            <div className="flex justify-center mt-4">
              <CircularProgress sx={{ color: "#17b8ee" }} size={30} />
            </div>
          ) : allReports?.length > 0 ? (
            allReports?.map((item: AllReportsDataTypes, index: number) => {
              const time = moment(item?.startTime).format("DD.MM.YYYY HH:mm");
              const completedTime = moment(
                item?.completeTime
              ).format("DD.MM.YYYY HH:mm");

              return (
                item?.assignment?.scenario !== null &&
                <>
                  <div
                    className={` cursor-pointer border-left-primary px-4 ml-2 ${currentReport?._id === item._id ? "bg-[#E0FAFF]" : 'dark:bg-gray-300'}`}
                    key={index}
                    onClick={() => handleViewReport(item?._id)}
                  >
                    <div>
                      <div>
                        {item?.assignment?.scenario?.name?.length > 60
                          ? item?.assignment?.scenario?.name?.slice(0, 60) + "..."
                          : item?.assignment?.scenario?.name}
                      </div>
                      <div className="text-sm text-gray-400">
                        {item?.assignment?.scenario?.description?.length > 60
                          ? item?.assignment?.scenario?.description?.slice(
                            0,
                            60
                          ) + "..."
                          : item?.assignment?.scenario?.description}
                      </div>
                      <div className="mt-1 textGray text-sm flex justify-between">
                        <div>{t("start_time")} : {time}</div>
                        {item?.completeTime ? completedTime : t("in_progress")}
                      </div>
                    </div>
                  </div>
                  {index !== allReports.length - 1 && <hr className="my-2" />}
                </>
              );
            })
          ) : (
            <div className="d-flex items-center justify-center mt-20 text-center">
              <h1>{t("report_empty")}</h1>
            </div>
          )}
          {reportDataLoading && <div className="flex justify-center mx-auto ">
            <CircularProgress size={40} />
          </div>}
        </div>
      )}
    </div>
  );
}

export default Task;
