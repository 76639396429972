import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";

export default function ParentChatPage() {
  const {
    activeRoomData,
  } = useSelector((state: any) => state.chat);
  const { userData } = useSelector((state: any) => state.user);
  const [image, setImage] = useState({ images: "", opacity: 1 });
  useMemo(() => {
      
      var RoomBackgroundImage = activeRoomData?.participants?.find(
        (item: any) => item.user_id === userData?._id
      );
  
      if (RoomBackgroundImage !== undefined) {
        setImage({ ...image, images: "" });
        if (RoomBackgroundImage?.wallpaper?.fileName === "") {
          setImage({ ...image, opacity: 0 });
        } else {
          setImage({ images: `${DefaultImageUrl + RoomBackgroundImage?.wallpaper?.fileName}`, opacity: RoomBackgroundImage?.wallpaper.opacity?100 - Number(RoomBackgroundImage?.wallpaper.opacity):1 }
          );
  
        }
      } else {
        setImage({ opacity: 1, images: "" });
      }
    return () => {
      setImage({ images: "", opacity: 1 })
    }
    // eslint-disable-next-line
  }, [activeRoomData]);
   return (
    useMemo(() => {
      return (
        <div
          className="relative"
          style={{
            height: "100%",
            backgroundImage: `url("${image.images}")`,
            opacity: image.opacity,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
        </div>
      )
    }, [image])

  )
}