import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  currentOrganization: {},
  organizations: [],
  invites: [],
  assignments: [],
  totalAssignment: 0,
  scenario: {},
  currentReport: {},
  currentAssignment: {},
  isScenarioLoading: false,
  isReportsLoading: false,
  allReports: [],
  showTaskPage: false,
  notificationArray:[],
  notificationCounts:0,
  totalReportCount:0,
  taskData:false,
  currentNotifyData:{},
  load:{status:false,text:""},
};

export const OrganizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload;
    },
    setOrganizationInvite: (state, action) => {
      state.invites = action.payload;
    },
    setAssignments: (state, action) => {
     
      state.assignments = action.payload;
    },
    
    setAssignmentCount:(state,action)=>{
      state.totalAssignment = action.payload;
    },
    addOrganization: (state, action) => {
      state.invites = [...state.invites, action.payload];
    },
    setCurrentScenario: (state, action) => {
      state.scenario = action.payload;
    },
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload;
    },
    setCurrentAssignment: (state, action) => {
      state.currentAssignment = action.payload;
    },
    setScenarioLoading: (state, action) => {
      state.isScenarioLoading = action.payload;
    },
    setReportsLoading: (state, action) => {
      state.isReportsLoading = action.payload
    },
    updateAssignments: (state, action) => {
      state.assignments = [action.payload, ...state.assignments];
    },
    updateInvites: (state, action) => {
      state.invites = [action.payload, ...state.invites]
    },
    setAllReports: (state, action) => {
      state.allReports = action.payload
    },
    setAllReportsCount: (state, action) => {
      state.totalReportCount = action.payload
    },
    setShowTaskPage: (state, action) => {
      state.showTaskPage = action.payload
    },
    setNotificationArray:(state,action)=>{
      state.notificationArray=action.payload
    },
    setNotificationCount:(state,action)=>{
      state.notificationCounts=action.payload;
    },
    resetOrg:(state,action)=>{
      return initialState;
    },
    setTaskData:(state,action)=>{
      state.taskData=action.payload;
    },
    setCurrentNoti:(state,action)=>{
      state.currentNotifyData=action.payload
    },
    setLoadingState:(state,action)=>{
      state.load={status:action.payload.status,text:action.payload.text};
      
    }
  },
});

export const {
  setOrganizations,
  setCurrentOrganization,
  setOrganizationInvite,
  addOrganization,
  setAssignments,
  setAssignmentCount,
  setCurrentScenario,
  setCurrentReport,
  setCurrentAssignment,
  setScenarioLoading,
  updateAssignments,
  updateInvites,
  setAllReports,
  setAllReportsCount,
  setReportsLoading,
  setShowTaskPage,
  setNotificationArray,
  setNotificationCount,
  resetOrg,
  setCurrentNoti,
  setTaskData,
  setLoadingState
} = OrganizationSlice.actions;
export default OrganizationSlice.reducer;
