import { ChangeEvent, useEffect } from "react";
import { TaskEdgesTypes } from "../../../Types/Types";
import { useGetLocation } from "../../../hooks/useGetLocation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskTextInput = ({ currentTask, isExecutable, inputRef ,result,setResult}: any) => {
  const { getCurrentLocation } = useGetLocation();
  const {t}=useTranslation();
  const {locationData}=useSelector((state: any) => state.chat);
  return (
    <>
      {currentTask?.type === "TEXT_INPUT" &&
        isExecutable &&
        currentTask?.edges?.map((item: TaskEdgesTypes,index:number) => {
          return (
            item?.type !== "TIMEOUT" && (
              <div key={item?._id}>
                <input
                  type="text"
                  value={result}
                  placeholder={""}
                  className="outline-none border min-w-[300px] rounded-md p-2"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if(item.location&&locationData?.lat===""){
                      getCurrentLocation();
                      alert(t('location_permission'))
                    }else{
                      setResult(e.target.value)
                    if (e.target.value) {
                      inputRef.current = e.target.value;
                    } else {
                      inputRef.current = "";
                    }
                  }
                  }}
                />
              </div>
            )
          );
        })}
    </>
  );
};

export default TaskTextInput;
