
function sortByAlphabet(arr: any, key: string) {
    return arr?.slice()?.sort((a: string, b: string) => {
        const nameA = a?.[key]?.toUpperCase() || ""
        const nameB = b?.[key]?.toUpperCase() || ""

        return nameA?.localeCompare(nameB)
    })
}

export default sortByAlphabet