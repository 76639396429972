import dayjs from "dayjs";
import { FormPropsType } from "../../types/types";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  RecurrentTypes,
  ReminderParticipantRole,
} from "../../types/enum";
import { colors, DefaultImageUrl } from "../../../../Constant/Constant";
import clsx from "clsx";
import { Button, Chip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../../Assets/Images/userAvatar.png";
import React, { useState } from "react";
import {
  DELETE_REMINDER,
  RESEND_REMINDER,
  UPDATE_PARENT_REMINDER_STATUS,
  UPDATE_REMINDER_STATUS,
} from "../../../../Graphql/Mutations";
import { authCllient } from "../../../../Graphql/authClient";
import {
  closeNewEventDialog,
  removeEvent,
  updateEvent,
} from "../../../../Redux/EventSlice";
import EventCall from "./EventCall";
import EventConfirmation from "../EventConfirmation";

enum APIStatus {
  Loading = "loading",
  Idle = "idl",
  Error = "error",
  Success = "success",
}

function ViewEvent(props: FormPropsType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, self, setSelf, setToast, closeComposeDialog, setIsEditMode } =
    props;
  const { events } = useSelector((state: any) => state?.events);
  const { formattedContact } = useSelector((state: any) => state?.user);
  const [apiStatus, setApiStatus] = useState(APIStatus.Idle);
  const showInvitationButton = form?.participants?.some(
    (item) => item?.accepted == ParticipantAcceptStatus.REJECT
  );
  const showCallIcons = form?.participants?.some(
    (item) =>
      item?.role != ReminderParticipantRole.ADMIN &&
      item?.accepted == ParticipantAcceptStatus.ACCEPT
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const currentDate = dayjs();

  function handleClickOnMap(url: string) {
    window.open(url);
  }

  function handleClickOnAttachment(url: string) {
    window.open(DefaultImageUrl + url);
  }

  function clickOnChangeStatus(type: string) {
    setSelectedType(type);
    setConfirmationType("change_status");
    setShowConfirmation(true);
  }

  async function handleChangeReminderStatus(type: string, submitType: string) {
    setApiStatus(APIStatus.Loading);

    const payload = {
      _id: type == "this" ? form?._id : form?.parent_id,
      ApprovalStatus:
        (submitType || selectedType) == "notification"
          ? self?.accepted == ParticipantAcceptStatus.ACCEPT
            ? ParticipantAcceptStatus.PAUSE
            : ParticipantAcceptStatus.ACCEPT
          : ParticipantAcceptStatus.REJECT,
    };

    try {
      const response = await authCllient.mutate({
        mutation:
          type == "this"
            ? UPDATE_REMINDER_STATUS
            : UPDATE_PARENT_REMINDER_STATUS,
        variables: {
          input: payload,
        },
      });

      if (response?.data) {
        setApiStatus(APIStatus.Success);

        let message = "";
        if ((selectedType || submitType) == "notification") {
          const key =
            type === "this"
              ? "updateReminderApprovalStatus"
              : "updateReminderApprovalParent";
          const find = response?.data?.[key]?.participants?.find(
            (item) => item?._id == self?._id
          );
          setSelf(find);

          if (self?.accepted == ParticipantAcceptStatus.ACCEPT) {
            message = "notifcation_paused";
          } else {
            message = "notifcation_enabled";
          }
        } else {
          message = "left_msg";
          dispatch(removeEvent(form?._id));
          closeComposeDialog();
        }
        setToast({
          msg: message,
          show: true,
        });
      } else {
        setApiStatus(APIStatus.Error);
      }
    } catch (err) {
      setApiStatus(APIStatus.Error);
      console.log(err);
    }
  }

  function updateReminders(updatedParticipants: any) {
    const updated = events?.map((item) => {
      if (item?.id == form?._id) {
        return {
          ...form,
          participants: updatedParticipants,
        };
      } else {
        return item;
      }
    });
    dispatch(updateEvent(updated));
  }

  async function handleResendInvitation() {
    setApiStatus(APIStatus.Loading);

    try {
      const response = await authCllient.mutate({
        mutation: RESEND_REMINDER,
        variables: {
          input: {
            _id: form?._id,
          },
        },
      });

      if (response?.data?.ResendReminder) {
        updateReminders(response?.data?.ResendReminder?.participants);
        setApiStatus(APIStatus.Success);
        setToast({
          msg: "invitation_sent",
          show: true,
        });
        closeComposeDialog();
      } else {
        setApiStatus(APIStatus.Error);
      }
    } catch (err) {
      setApiStatus(APIStatus.Error);
      console.log(err);
    }
  }

  async function deleteReminder(deleteType: string) {
    setApiStatus(APIStatus.Loading);
    await authCllient
      .mutate({
        mutation: DELETE_REMINDER,
        variables: {
          input: {
            _id: deleteType == "all" ? form?.parent_id : form?._id,
            thisOccurrence: deleteType == "this" ? true : false,
            allOccurrence: deleteType == "all" ? true : false,
          },
        },
      })
      .then((res) => {
        if (res?.data?.deleteReminder) {
          setToast({
            msg:
              form?.type == EventTypeEnum.APPOINTMENT
                ? "appointment_deleted_msg"
                : form?.type == EventTypeEnum.APPOINTMENT
                ? "call_reminder_deleted_msg"
                : "reminder_deleted_msg",
            show: true,
          });
          closeComposeDialog();
          setApiStatus(APIStatus.Success);
        } else {
          setApiStatus(APIStatus.Error);
        }
      })
      .catch((err) => {
        console.log(`Error occured at Calendar App -> ${err}`);
        setApiStatus(APIStatus.Error);
      });
  }

  return (
    <div className="p-7 min-w-[600px] max-w-[750px]">
      <div className="flex justify-between mt-1">
        <div className="text-left text-[24px] font-semibold">{form?.title}</div>

        <div>
          <IconButton onClick={() => dispatch(closeNewEventDialog(""))}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      {form?.description && <div className="mb-1">{form?.description}</div>}

      <div className="text-sm text-gray-600">
        {dayjs(form?.start).format("DD MMMM YYYY")} | {dayjs(form?.start).format("HH:mm")}
      </div>

      <div className="flex gap-5 mt-3">
        <div
          className={clsx(
            "text-[12px] text-white px-4 py-1 h-fit w-fit rounded-md"
          )}
          style={{
            backgroundColor:
              form?.type == EventTypeEnum.APPOINTMENT
                ? colors.appointment
                : form?.type == EventTypeEnum.REMINDER
                ? colors.reminder
                : colors.callReminder,
          }}
        >
          {t(form?.frequency)}
        </div>

        {!form?.repeatEveryMonth && form?.months?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {form?.months?.map((item: string, key: number) => {
              return <Chip key={key} variant="outlined" label={item} />;
            })}
          </div>
        )}

        {form?.days?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {form?.days?.map((item: string, key: number) => {
              return <Chip key={key} variant="outlined" label={item} />;
            })}
          </div>
        )}
      </div>

      {!form?.hasComon &&
        form?.type == EventTypeEnum.CALLREMINDER &&
        showCallIcons && <EventCall participants={form?.participants} />}

      {form?.location?.address && (
        <div className="mt-3">
          <div className="font-semibold">
            <LocationOnOutlinedIcon /> {t("location")}
          </div>
          <div className="mt-1">{form?.location?.address}</div>
        </div>
      )}

      {form?.location?.mapUrl && (
        <div className="mt-3 w-full">
          <Button
            color="inherit"
            variant="outlined"
            sx={{ textTransform: "capitalize", width: "100%" }}
            onClick={() => handleClickOnMap(form?.location?.mapUrl)}
          >
            {t("open_map")}
          </Button>
        </div>
      )}

      {form?.attachments?.length > 0 && (
        <div className="mt-3">
          <div className="font-semibold">{t("attachment")}</div>
          {form?.attachments?.map((item: any, index: number) => {
            const splitted = item?.name?.split("name-");
            const fileName = splitted?.pop();

            return (
              <div key={index} className="mt-2">
                <div className="flex justify-between">
                  <div>
                    <AttachFileIcon />
                    {fileName}
                  </div>
                  <div>
                    <IconButton
                      onClick={() => handleClickOnAttachment(item?.url)}
                    >
                      <CloudDownloadOutlinedIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {form?.notifications?.length > 0 && (
        <div className="mt-3">
          <div className="font-semibold">{t("remind_me_before")}</div>
          {form?.notifications?.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-2">
                <div>
                  <NotificationsNoneIcon />
                  <span className="lowercase">
                    {item?.Count} {item?.Unit}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {form?.participants?.length > 0 && (
        <div className="mt-3">
          <div className="flex justify-between items-center">
            <div className="font-semibold">{t("reminder_participants")}</div>

            {self?.role == ReminderParticipantRole.ADMIN &&
              showInvitationButton && (
                <button
                  disabled={Boolean(apiStatus == APIStatus.Loading)}
                  className={clsx(
                    "text-[12px] text-white px-4 py-1 h-fit w-fit rounded-md"
                  )}
                  style={{
                    backgroundColor:
                      form?.type == EventTypeEnum.APPOINTMENT
                        ? colors.appointment
                        : form?.type == EventTypeEnum.REMINDER
                        ? colors.reminder
                        : colors.callReminder,
                  }}
                  onClick={handleResendInvitation}
                >
                  {apiStatus == APIStatus.Loading
                    ? t("please_wait")
                    : t("re_invite")}
                </button>
              )}
          </div>

          <div className="grid gap-3 mt-2 event-participant-scroll">
            {form?.participants?.map((item: any, index: number) => {
              const contact = formattedContact?.[item?._id];

              return (
                <div key={index} className="flex gap-3 items-center">
                  <div className="relative h-[40px] w-[40px]">
                    {item?.profile_img ? (
                      <img
                        alt="avatar"
                        src={DefaultImageUrl + item?.profile_img}
                        className="h-full w-full rounded-full"
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="avatar"
                        className="h-full w-full"
                      />
                    )}

                    {item?.accepted == ParticipantAcceptStatus.ACCEPT && (
                      <CheckIcon
                        sx={{ color: "white", fontSize: 15 }}
                        className="bg-green-600 rounded-full absolute bottom-0 right-0"
                      />
                    )}
                    {item?.accepted == ParticipantAcceptStatus.REJECT && (
                      <CloseIcon
                        sx={{ color: "white", fontSize: 15 }}
                        className="bg-red-600 rounded-full absolute bottom-0 right-0"
                      />
                    )}
                  </div>

                  <div>
                    {self?._id == item?._id ? (
                      <div>{t("you")}</div>
                    ) : (
                      <div>
                        {!form?.hasComon ? (
                          <React.Fragment>
                            <span className="mr-1">
                              {contact?.firstName
                                ? contact?.firstName
                                : item?.phone}
                            </span>
                            {contact?.lastName && (
                              <span>{contact?.lastName}</span>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className="mr-1">{item?.firstName}</span>
                            {item?.lastName && <span>{item?.lastName}</span>}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                    {item?.role == ReminderParticipantRole.ADMIN && (
                      <div className="text-sm text-gray-600">
                        {t("organizer")}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {self?.accepted != ParticipantAcceptStatus.PENDING &&
        self?.accepted != ParticipantAcceptStatus.REJECT &&
        (form?.frequency != RecurrentTypes.ONCE ||
          !dayjs(form?.time).isBefore(dayjs())) && (
          <div className="mt-3 w-full">
            <Button
              color="inherit"
              variant="outlined"
              sx={{ textTransform: "capitalize", width: "100%" }}
              onClick={() => {
                if (form?.frequency == RecurrentTypes.ONCE) {
                  handleChangeReminderStatus("this", "notification");
                } else {
                  clickOnChangeStatus("notification");
                }
              }}
              disabled={Boolean(apiStatus == APIStatus.Loading)}
            >
              {self?.accepted == ParticipantAcceptStatus.ACCEPT
                ? t("pause_notification")
                : t("enable_notification")}
            </Button>
          </div>
        )}

      {self?.role != ReminderParticipantRole.ADMIN ? (
        <div className="mt-3 w-full">
          <Button
            color="error"
            variant="outlined"
            sx={{ textTransform: "capitalize", width: "100%" }}
            disabled={Boolean(apiStatus == APIStatus.Loading)}
            onClick={() => {
              if (form?.frequency == RecurrentTypes.ONCE) {
                handleChangeReminderStatus("this", "status");
              } else {
                clickOnChangeStatus("left");
              }
            }}
          >
            {t("left")}
          </Button>
        </div>
      ) : (
        <div className="mt-3 flex gap-4 w-full">
          {dayjs(form?.start).isSameOrAfter(dayjs()) && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setIsEditMode(true)}
              sx={{ textTransform: "capitalize", width: "100%" }}
              disabled={Boolean(apiStatus == APIStatus.Loading)}
            >
              {t("edit")}
            </Button>
          )}
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              if (form?.frequency != RecurrentTypes.ONCE) {
                setShowConfirmation(true);
                setConfirmationType("delete");
              } else {
                deleteReminder("this");
              }
            }}
            sx={{ textTransform: "capitalize", width: "100%" }}
            disabled={Boolean(apiStatus == APIStatus.Loading)}
          >
            {t("delete")}
          </Button>
        </div>
      )}

      {apiStatus == APIStatus.Error && (
        <div className="mt-2 text-red-600 text-center text-sm">
          {t("something_went_wrong")}
        </div>
      )}

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={
            confirmationType == "delete"
              ? deleteReminder
              : handleChangeReminderStatus
          }
          message={
            confirmationType == "delete"
              ? t("delete_recurring_event")
              : t("update_approval_status")
          }
        />
      )}
    </div>
  );
}

export default ViewEvent;
