import { Avatar, Menu } from "@mui/material";
import moment from "moment";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import MessageInfo from "./MessageInfo";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { MessagesActionDataTypes } from "../../Types/Types";
import StarIcon from "@mui/icons-material/Star";
import { emit, socketListen } from "../../Socket";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoIosShareAlt } from "react-icons/io";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Toast from "../../coreComponent/Toast";
import { useTranslation } from "react-i18next";
import messageSenderInfo from "../../utils/messageSenderInfo";
const ImagePreview = ({
  item,
  showDate,
  profileShown,
  showDateforoutgoing,
  setMessages,
  messages,
  setChangeFooter,
  setShowCheckbox,
  showCheckbox,
  setReplyFooterShow,
  setReplyMessage,
  setMessageInfoItem,
  setData,
  setSelectedHeading,
  setSelectedHeadingId,
  setShowHeadingModal,
  setMessageInfoDrawerShow,
  setShowMediaPreview,
  setSelectedMedia,
}: any) => {
  const [showMessageActions, setShowMessageActions] = useState({
    status: false,
    id: "",
  });
  const [activeFav, setActiveFav] = useState<any>({});
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const { topicsData } = useSelector((state: any) => state.user);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const MessageAction = MessageActions(t);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { userData, userContacts } = useSelector((state: any) => state.user);
  const {
    formattedParticipantsOfActiveRoom,
    activeRoomData,
    activeRoomChats,
    pinnedChats,
  } = useSelector((state: any) => state.chat);

  const deleted =
    item?.deleted?.filter((element: any) => element.user_id === userData?._id)
      ?.length > 0
      ? false
      : true;
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  let userDetails = userContacts?.find(
    (item: any) => item?.userId?._id === senderInfo?.user_id
  );
  const isEveryoneCanPinMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "pinMessage" && item?.permit === "common"
    );
  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userData?._id
    );
  const isRoomLeft = userProfileImg?.left_at !== 0;
  const canYouPinMessage =
    (!isEveryoneCanPinMessage &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
        "admin") ||
    isEveryoneCanPinMessage;
  let messageTime =
    item?.created_at !== null
      ? moment(item?.created_at).format("HH:mm")
      : moment(new Date()).format("HH:mm");

  const isUserBlocked = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );

  const messageSender = messageSenderInfo(
    item?.sender,
    activeRoomData?.participants
  );

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMessageActions({ status: false, id: "" });
    } else if (event.key === "Escape") {
      setShowMessageActions({ status: false, id: "" });
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    //setshowArrow({ status: false, id: null });
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const handleMessageAction = (action: string, item: any) => {
    setShowMessageActions({ status: false, id: "" });
    if (action === "forward" || action === "delete") {
      handleMessageSelect(true, item);
      setChangeFooter(true);
      setShowCheckbox(true);
    } else {
      setChangeFooter(false);
      setShowCheckbox(false);
    }

    if (action === "heading") {
      setData(topicsData);
      setSelectedHeading(item?.message);
      setSelectedHeadingId(item?._id);
      setShowHeadingModal(true);
    }

    if (action === "favourite") {
      emit("addChatsToFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    } else if (action === "unFavourite") {
      emit("removeChatsFromFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    }

    if (action === "reply") {
      setTimeout(() => {
        document.getElementById("message-input").focus();
      }, 0);
      setReplyMessage(item);
      setReplyFooterShow(true);
    }

    if (action === "info" && item?.sender === userData?._id) {
      setMessageInfoItem(item);
      setMessageInfoDrawerShow(true);
    }
    if (action === "pin") {
      if (canYouPinMessage) {
        if (pinnedChats?.length < 3) {
          emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

          socketListen("message", (data: any) => {
            if (data.type === "pinChat") {
              setToastShow(true);
              setToastMessage(t("chat_pinned"));
            }
          });
        } else {
          setToastMessage(t("chat_pin_validation"));
          setToastShow(true);
        }
      } else {
        setToastMessage(t("chat_pin_admin"));
        setToastShow(true);
      }
    }
  };

  const handleMessageSelect = (e: any, item: any) => {
    let temp = [...messages.msgId];
    let tempMsgId = [...messages.senderId];
    let tempTime = [...messages.time];

    if (e) {
      temp.push(item?._id);
      tempMsgId.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      temp = temp.filter((elem: any) => elem !== item?._id);
      tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
      tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
    }
    setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
  };
  useEffect(() => {}, [item?.fileURL]);
  return item?.sender === userData?._id ? (
    <div
      className=" items-center"
      onMouseEnter={() => {
        setshowArrow({ status: true, id: item._id });
        setAnchorEl(null);
      }}
      onMouseLeave={() => {
        setshowArrow({ status: false, id: null });
        setAnchorEl(null);
      }}
    >
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px]">
          <input
            type="checkbox"
            name="message"
            checked={messages?.msgId.includes(item._id)}
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}
      <div className="w-max flex items-start ml-auto max-w-[400px]">
        <div
          className="w-8 h-6"
          style={{ color: "#33CCFF", fontSize: "1.3rem" }}
        >
          {showArrow.status && showArrow.id === item?._id && deleted && (
            <div
              className=""
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setActiveFav(item);
              }}
              ref={anchorRef}
            >
              <MoreVertIcon
                className="-mr-2 cursor-pointer"
                sx={{ color: "#33CCFF", fontSize: "1.8rem" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {MessageAction.filter((elem: MessagesActionDataTypes) =>
                  activeRoomData?.type === "individual"
                    ? elem?.action !== "pin"
                    : elem?.action !== "copy" && elem
                ).map((el: MessagesActionDataTypes, index: number) => {
                  const pinchat = pinnedChats.filter(
                    (els: any) => els._id === item?._id
                  );
                  return pinchat.length > 0 && el?.action === "pin" ? (
                    ""
                  ) : activeFav?.favourite_by?.length > 0 &&
                    el?.action === "favourite" ? (
                    ""
                  ) : activeFav?.favourite_by?.length === 0 &&
                    el?.action === "unFavourite" ? (
                    ""
                  ) : activeFav?.type === "IMAGE" && item?.action === "copy" ? (
                    ""
                  ) : activeRoomData?.type == "broadcast" &&
                    el?.action == "heading" ? (
                    ""
                  ) : isRoomLeft &&
                    (el?.action === "forward" ||
                      el?.action === "reply" ||
                      el?.action === "delete") ? (
                    ""
                  ) : (
                    <MenuItem
                      onClick={() => {
                        handleMessageAction(el.action, item);
                        setshowArrow({ status: false, id: null });
                        setAnchorEl(null);
                      }}
                      key={index}
                      sx={{ fontSize: "15px" }}
                    >
                      {el.ui}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          )}
        </div>
        <div
          className=" rounded-t-xl rounded-bl-xl bg-[#E0FAFF] p-4 pt-2 mb-1"
          onContextMenu={(e) => {
            if (!item?.message?.includes("You deleted this message")) {
              e.preventDefault();
              setAnchorEl(e.currentTarget);
              setShowMessageActions({ status: true, id: item._id });
              setActiveFav(item);
            }
          }}
        >
          <div className="">
            <div className="flex  items-center gap-3 justify-start">
              <div>
                {item?.message === "" && (
                  <div
                    className={`flex relative mb-1 ${
                      item?.isForwarded ? "justify-between" : "justify-end"
                    } text-right `}
                  >
                    <div>
                      {item?.isForwarded && (
                        <div className="flex">
                          <IoIosShareAlt className="mt-1 me-1" />
                          <div className="text-sm textGray text-right">
                            {t("forwarded")}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="textGray text-right flex pt-1 ps-2 justify-end text-xs pr-2 ">
                      {messageTime}
                    </div>
                  </div>
                )}

                <div className="group relative" ref={anchorRef}>
                  {deleted && (
                    <img
                      src={DefaultImageUrl + item?.fileURL}
                      alt="asd"
                      className="h-[200px] w-[200px] rounded-md object-cover cursor-pointer"
                      onClick={() => {
                        setShowMediaPreview(true);
                        setSelectedMedia({
                          src: DefaultImageUrl + item?.fileURL,
                          type: "image",
                        });
                      }}
                    />
                  )}
                </div>
                {item?.favourite_by?.length > 0 && (
                  <div>
                    <StarIcon
                      sx={{ fontSize: 14 }}
                      className="text-black mt-1 me-1"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {item?.message !== "" && (
            <div className=" bg-[#E0F9FF] py-[6px] rounded-t-xl rounded-bl-xl text-[#515151]  ">
              <div className="flex justify-end text-right ">
                <div className="textGray text-right flex pt-1 ps-2 justify-end text-xs pr-2 ">
                  {messageTime}
                </div>
              </div>
              {item?.message === "You deleted this message" ? (
                <i className="text-[14px] text-[#515151]">
                  {item?.message.replace(
                    "You deleted this message",
                    t("you-message-deleted")
                  )}
                </i>
              ) : (
                item.message.replace(
                  "You deleted this message",
                  t("you-message-deleted")
                )
              )}
            </div>
          )}
        </div>
        {item.isSent && item.read_by.length > 0 && item.receipts ? (
          <></>
        ) : (
          <div className="flex items-end ">
            {item.isSent && item.delivered_to.length > 0 ? (
              <DoneAllIcon
                className="text-gray-400"
                sx={{ width: 18, height: 18 }}
              />
            ) : (
              <DoneIcon
                className="text-gray-400"
                sx={{ width: 18, height: 18 }}
              />
            )}
          </div>
        )}
      </div>
      {item.receipts &&
        activeRoomChats[activeRoomChats.length - 1]?._id === item._id &&
        item.isSent &&
        item.read_by.length > 0 && (
          <div
            className={` ml-auto mb-2  ${
              activeRoomData?.type === "group" ? "w-12" : "w-fit ml-[5px]"
            } flex gap-1 items-end self-end justify-end`}
          >
            {<MessageInfo item={item} />}
          </div>
        )}
    </div>
  ) : (
    <div
      className="flex w-full"
      onMouseEnter={() => {
        setshowArrow({ status: true, id: item._id });
        setAnchorEl(null);
      }}
      onMouseLeave={() => {
        setshowArrow({ status: false, id: null });
        setAnchorEl(null);
      }}
    >
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {showCheckbox && deleted && (
        <div className="h-[20px] w-[20px] mr-4">
          <input
            type="checkbox"
            checked={messages?.msgId.includes(item._id)}
            name="message"
            className="mr-2 cursor-pointer h-[20px] w-[20px]"
            onChange={(e: any) => handleMessageSelect(e.target.checked, item)}
          />
        </div>
      )}

      <div>
        <div className={` flex  pr-4 mr-auto `}>
          <div className="min-w-[35px]">
            {/* {showDate &&
              (senderInfo?.profile_img !== "" &&
                !isUserBlocked ? (
                <img
                  src={DefaultImageUrl + senderInfo?.profile_img}
                  alt="asas"
                  className="h-[35px] w-[35px] rounded-full"
                />
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))} */}

            {showDate &&
              (messageSender?.profile !== "" && !isUserBlocked ? (
                <img
                  src={DefaultImageUrl + messageSender?.profile}
                  alt="asas"
                  className="h-[35px] w-[35px] rounded-full"
                />
              ) : (
                <div className="">
                  <Avatar sx={{ width: "35px", height: "35px" }} />
                </div>
              ))}
          </div>

          <div className={`flex items-start max-w-[80%] w-fit`}>
            <div
              className={` bg-[#F4F3F4] rounded-tr-xl ml-2 rounded-b-xl ${
                !deleted ? "px-3" : "p-4"
              } items-center`}
              onContextMenu={(e) => {
                if (!item?.message?.includes("This message was deleted")) {
                  e.preventDefault();
                  setAnchorEl(e.currentTarget);
                  setShowMessageActions({ status: true, id: item._id });
                  setActiveFav(item);
                }
              }}
            >
              {
                <div className="flex justify-between relative">
                  <div className=" mb-2 text-sm flex">
                    {showDate && messageSender?.name}
                    {/* {showDate &&
                      (userDetails &&
                      (userDetails?.firstName !== "" ||
                        userDetails?.lastName !== "")
                        ? userDetails?.firstName + " " + userDetails?.lastName
                        : senderInfo?.phone)} */}
                    {item?.isForwarded && (
                      <div className="flex items-center ml-1">
                        <IoIosShareAlt />
                        <div className="text-sm textGray px-[5px]">
                          {t("forwarded")}
                        </div>
                      </div>
                    )}
                  </div>
                  {showDate && (
                    <div className="textGray text-[13px] pr-2">
                      {messageTime}
                    </div>
                  )}
                </div>
              }
              <div className="">
                <div className="py-[6px]  group relative top-0" ref={anchorRef}>
                  {deleted && (
                    <img
                      src={DefaultImageUrl + item?.fileURL}
                      alt="as"
                      className="h-[200px] w-[200px] rounded-md object-cover cursor-pointer"
                      onClick={() => {
                        setShowMediaPreview(true);
                        setSelectedMedia({
                          src: DefaultImageUrl + item?.fileURL,
                          type: "image",
                        });
                      }}
                    />
                  )}

                  {item?.message !== "" &&
                    (item.deleted.length > 0 ? (
                      <div className="text-[#515151] italic text-[14px]  py-[6px] rounded-tr-xl rounded-b-xl">
                        {item?.message.replace(
                          "this message was deleted",
                          t("this-message-deleted")
                        )}
                      </div>
                    ) : (
                      <div className="text-[#515151]  text-[15px]  py-[6px] rounded-tr-xl rounded-b-xl">
                        {item?.message.replace(
                          "this message was deleted",
                          t("this-message-deleted")
                        )}
                      </div>
                    ))}
                </div>

                {item?.favourite_by?.length > 0 && (
                  <div className="h-[100%] flex flex-col justify-between py-2">
                    <div className="flex justify-end">
                      <StarIcon
                        sx={{ fontSize: 14 }}
                        className="text-black mt-1 me-1"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="w-18 h-6 ml-2"
              style={{ color: "#33CCFF", fontSize: "1.3rem" }}
            >
              {deleted && (
                <div
                  className=""
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setActiveFav(item);
                  }}
                  ref={anchorRef}
                >
                  <MoreVertIcon
                    className=" cursor-pointer"
                    sx={{
                      visibility:
                        showArrow.status && showArrow.id === item?._id
                          ? "visible"
                          : "hidden",
                      color: "#33CCFF",
                      fontSize: "1.8rem",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {MessageAction.filter((elem: MessagesActionDataTypes) =>
                      activeRoomData?.type === "individual"
                        ? elem?.action !== "pin"
                        : elem?.action !== "copy" &&
                          elem?.action !== "info" &&
                          elem
                    ).map((el: MessagesActionDataTypes, index: number) => {
                      const pinchat = pinnedChats.filter(
                        (els: any) => els._id === item?._id
                      );
                      return pinchat.length > 0 && el?.action === "pin" ? (
                        ""
                      ) : activeFav?.favourite_by?.length > 0 &&
                        el?.action === "favourite" ? (
                        ""
                      ) : activeFav?.favourite_by?.length === 0 &&
                        el?.action === "unFavourite" ? (
                        ""
                      ) : activeFav?.type === "IMAGE" &&
                        item?.action === "copy" ? (
                        ""
                      ) : activeRoomData?.type == "broadcast" &&
                        el?.action == "heading" ? (
                        ""
                      ) : isRoomLeft &&
                        (el?.action === "forward" ||
                          el?.action === "reply" ||
                          el?.action === "delete") ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleMessageAction(el.action, item);
                            setshowArrow({ status: false, id: null });
                            setAnchorEl(null);
                          }}
                          key={index}
                          sx={{ fontSize: "15px" }}
                        >
                          {el.ui}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
