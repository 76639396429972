import {
  setActiveRoomData,
  setActiveRoomsChats,
  setTotalPage,
} from "../Redux/ChatSlice";
import { emit } from "../Socket";
import { store } from "../Redux/store";
import { Dispatch } from "@reduxjs/toolkit";
import { setScrollBottom } from "../Redux/UserSlice";
import { setTaskData } from "../Redux/OrganizationSlice";

const backgroundNotificationAction = (
  type: string,
  dispatch: Dispatch,
  msgData: any
) => {
  const { activeRoomData, rooms } = store.getState()?.chat;
  const findRoom = rooms?.find((el: any) => el?._id == msgData?.myMessage?.roomId);

  switch (type) {
    case "TASK_COMPLETE":
    case "REMINDER":
      dispatch(setTaskData(true));
      break;

    default:
      if (activeRoomData?._id !== findRoom?._id) {
        dispatch(setActiveRoomData(findRoom));
        dispatch(setActiveRoomsChats([]));
        dispatch(setTotalPage(Math.ceil(msgData?.totalChats / 50)));
      }

      setTimeout(() => {
        if (document.querySelector("#scrollableDiv") !== null) {
          var height = document.querySelector("#scrollableDiv").scrollHeight;
          document.querySelector("#scrollableDiv").scroll(0, height - 100);
        }
      }, 3000);

      emit("getUserMediaByRoomId", {
        roomId: findRoom?._id,
        type: "media",
      });
      dispatch(setScrollBottom(true));
      break;
  }
};

export default backgroundNotificationAction;
