import { UPLOAD_FILE } from "../../../Graphql/Mutations";
import { TaskEdgesTypes } from "../../../Types/Types";
import { getSession } from "../../../utils/session";
import { mutationClient } from "../../../Graphql/authClient";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskFileInput = ({
  currentTask,
  isExecutable,
  setLoading,
  setAttachmentId,
  setTaskSubmitError,
}: any) => {
  const {currentOrganization}=useSelector((state:any)=>state.organization)
  // const [uploadFile] = useMutation(UPLOAD_FILE);
const {t}=useTranslation();
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];

    const fileSizeInMb = file.size / 1000000;

    if (fileSizeInMb > 16) {
      setTaskSubmitError(t("size_validation"));
    } else {
      setTaskSubmitError("");

      if (file) {
        setLoading(true);
        const { token } = getSession();

        mutationClient(token)
          .mutate({
            mutation: UPLOAD_FILE,
            variables: {
              file: file,
              input:{
                orgId:currentOrganization?._id,
                attachments: false
              }
            },
          })
          .then((res) => {
            setAttachmentId(res?.data?.uploadFile?._id);
          })
          .catch((error) => {
            console.log(`Error occurred at Goal File upload => ${error}`);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  return (
    <>
      {currentTask?.type === "MEDIA_UPLOAD" &&
        currentTask?.mediaType !== "RECORD" &&
        isExecutable &&
        currentTask?.edges?.map((item: TaskEdgesTypes) => {
          return (
            item?.type !== "TIMEOUT" && (
              <div key={item?._id}>
                <input
                  accept={`${
                    currentTask?.mediaType === "PHOTO"
                      ? "image/*"
                      : currentTask?.mediaType === "VIDEO"
                      ? "video/*"
                      : currentTask?.mediaType === "AUDIO"
                      ? "audio/*"
                      : ".doc, .docx, .ppt, .pptx, .txt, .pdf, xlsx, .xls"
                  }`}
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                />
              </div>
            )
          );
        })}
    </>
  );
};

export default TaskFileInput;
