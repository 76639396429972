import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskReports from "./TaskReports";
import CurrentTask from "./CurrentTask";
import ReactLoading from "react-loading";
import { AiFillWechat } from "react-icons/ai";
import ChatPage from "../ChatPage/ChatPage";
import { appendOldChats, setActiveRoomChats } from "../../Redux/ChatSlice";
import { useScheduleTime } from "../../hooks/useScheduleTime";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { IconButton } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getMyNotifications } from "../../Graphql/Queries";
import { authCllient } from "../../Graphql/authClient";
import { useMutation } from "@apollo/client";
import send from "../../Assets/send-2.png";
import { SET_NOTIFICATION_SEEN } from "../../Graphql/Mutations";
import CurrentOrganizations from "../../Components/Organizations/CurrentOrganizations";
import { setTaskData } from "../../Redux/OrganizationSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import StartTaskImg from "../../Assets/startTask.png";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const TaskPage = ({ tabButton, setTabButton }: any) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [showTaskChatPage, setShowTaskChatPage] = useState(false);
  const {
    scenario,
    currentAssignment,
    isScenarioLoading,
    notificationArray,
    notificationCounts,
    currentOrganization,
    currentReport,
  } = useSelector((state: any) => state.organization);
  const [notificationCount, setNotificationCount] =
    useState(notificationCounts);
  const { t } = useTranslation();
  const { isCorrectTime, checkScheduleTime, executingTime } = useScheduleTime();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [notification, setNotification] = useState(notificationArray);
  const [setNotificationSeen] = useMutation(SET_NOTIFICATION_SEEN);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    let notificationArray = [];
    notification.forEach((element: any) => {
      if (!element.isSeen) {
        notificationArray.push(element._id);
      }
    });
    setNotificationSeen({
      variables: {
        input: {
          notificationIds: notificationArray,
        },
      },
    }).then((res) => {
      getNotificationData();
    });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const arrangeTaskPosition = async () => {
    let taskTypes = [];
    let temp: any = [...scenario?.tasks];

    await scenario?.tasks?.forEach((item: any) => {
      if (item?.type !== "INPUT" && item?.type !== "OUTPUT") {
        taskTypes.push(item?.type);
      }
    });

    const sort_key = ["INPUT", ...taskTypes, "OUTPUT"];

    const arrange = await temp?.sort(
      (a: any, b: any) => sort_key.indexOf(a.type) - sort_key.indexOf(b.type)
    );
    setTasks(arrange);
  };
  useEffect(() => {
    setTasks([]);
    if (currentOrganization?._id) {
      getNotificationData();
    }
  }, [currentOrganization]);
  const getNotificationData = () => {
    authCllient
      .query({
        query: getMyNotifications,
        variables: {
          input: {
            skip: 0,
            limit: 100,
            // organizationId:scenario?.organizationId,
            assignmentId: currentAssignment?._id,
          },
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        let count = 0;
        setNotification(res?.data?.getMyNotifications?.data);
        if (res?.data?.getMyNotifications?.data.length > 0) {
          res.data?.getMyNotifications?.data.forEach((element: any) => {
            if (!element.isSeen) {
              count = count + 1;
            }
          });
        }
        setNotificationCount(count);
      });
  };

  useEffect(() => {
    setNotificationCount(notificationCounts);
    setNotification(notificationArray);
  }, [notificationCounts]);

  useEffect(() => {
    if (Object.keys(scenario)?.length) {
      arrangeTaskPosition();
      checkScheduleTime();
      // console.log(scenario, "scenario");
      //  getNotificationData();
    }

    dispatch(setTaskData(false));

    // eslint-disable-next-line
  }, [scenario?.tasks]);

  const handleToggleChatPage = () => {
    if (showTaskChatPage) {
      setShowTaskChatPage(false);
    } else {
      dispatch(setActiveRoomChats([]));
      dispatch(appendOldChats([]));
      setShowTaskChatPage(true);
    }
  };

  const renderButton = (data: any) => {
    switch (data) {
      case "TASK_COMPLETE": {
        return (
          <button className="border-2 rounded-md mr-2 px-2 py-1 mb-2">
            {t("completed")}
          </button>
        );
        break;
      }
    }
  };
  return isScenarioLoading ? (
    <div className="h-[100vh] flex justify-center items-center">
      <ReactLoading
        type="spinningBubbles"
        color="#33ccff"
        height={100}
        width={100}
      />
    </div>
  ) : (
    <div className="flex w-full">
      <div
        className="bg-white w-full h-[100vh] text-[#000000b8]
     relative"
      >
        <div
          className="flex justify-between items-center w-full border-b relative
          py-2 px-4 bg-white h-[60px] z-[999]"
        >
          <div>
            <div className="text-gray-700 text-[22px]">{scenario?.name}</div>
            <div className="textGray">{scenario?.description}</div>
          </div>
          <div className="mr-5 flex">
            <div className="mr-3">
              <IconButton
                aria-label="notification"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {notificationCount > 0 ? (
                  <StyledBadge badgeContent={notificationCount} color="success">
                    <NotificationsActiveIcon />
                  </StyledBadge>
                ) : (
                  <NotificationsActiveIcon />
                )}
              </IconButton>
              {notification.length > 0 && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  style={{ maxHeight: "550px", width: "" }}
                  onClose={handleClose}
                  className="p-10 relative"
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* removed fixed from here and add w-full */}
                  <div className="bg-white w-full mt-[-10px] p-3 text-[20px] ">
                    {t("task_notification")}
                  </div>
                  <hr />
                  <div className="w-[450px] max-h-[400px] h-full overflow-auto bg-gray-200 p-2 shadow-md" style={{scrollbarWidth: "thin"}}>
                    {notification.map((item: any, index: number) => (
                      <div className={`bg-white rounded-md my-3  p-1 py-2`}>
                        <div
                          key={index}
                          className="flex mx-2 text-md font-bold"
                        >
                          {item.title}
                        </div>
                        <div className="mx-2 pt-2 text-md  pb-2">
                          {item.body}
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex justify-start ms-2 mb-2  py-1">
                            {moment(item?.createdAt).format("HH:mm")}
                          </div>
                          <div className="flex justify-end">
                            {renderButton(item.type)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Menu>
              )}
            </div>
            {/*   <button onClick={handleToggleChatPage} className="outline-none">
              <img src={send} className="h-[30px] w-[30px] text-[#17b8ee]" />
            </button> */}
          </div>
        </div>

        <div className="flex">
          <div className="relative h-[calc(100vh-60px)] w-full border-r">
            {Object.keys(currentReport).length == 0 && (
              <div className="py-4 flex justify-center">
                <div className="h-auto w-[500px]">
                  <img src={StartTaskImg} className="h-full w-full" />
                </div>
              </div>
            )}

            <div
              className="w-full 
              absolute bottom-[0rem] "
            >
              <div
                className="py-4 w-full px-5 max-h-[93vh]  overflow-y-scroll "
                id="task-report"
              >
                <div className="min-h-[30vh]">
                  <TaskReports />
                </div>

                <div className="my-2">
                  <CurrentTask
                    tasks={tasks}
                    tabButton={tabButton}
                    setTabButton={setTabButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTaskChatPage && (
        <div className="w-[45%]">
          <ChatPage />
        </div>
      )}
    </div>
  );
};

export default TaskPage;
