import { Controller, useFieldArray } from "react-hook-form";
import { IOption, IPollFields } from "./polls";
import { useTranslation } from "react-i18next";
import TextInput from "../../../Components/inputs/TextInput";
import generateShortUUID from "../../../utils/generateShortUUID";
import { Checkbox, FormControlLabel } from "@mui/material";

const PollFields = ({
  control,
  errors,
  pollField,
  pollIndex,
  removePoll,
}: IPollFields) => {
  const { t } = useTranslation();
  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
  }: any = useFieldArray({
    control,
    name: `polls.${pollIndex}.options`,
  });

  return (
    <div className="mb-6 p-5 grid gap-3 bg-white rounded-md">
      <div>
        <div className="flex items-start justify-between">
          <Controller
            name={`polls.${pollIndex}.title`}
            control={control}
            render={({ field }) => (
              <TextInput
                autoFocus
                {...field}
                type="text"
                placeholder={t("enteryourqtn")}
                label={`${t("question")} ${pollIndex + 1}`}
              />
            )}
          />

          {pollIndex != 0 && (
            <button
              type="button"
              onClick={() => removePoll(pollIndex)}
              className="text-[#33CCFF] text-sm min-w-[80px] outline-none"
            >
              {t("removepoll")}
            </button>
          )}
        </div>

        {errors?.polls?.[pollIndex]?.title && (
          <div className="text-red-600 text-sm mt-1">
            {t(errors?.polls?.[pollIndex]?.title?.message)}
          </div>
        )}
      </div>

      <div className="pl-4 text-sm font-semibold">{t("options")}</div>
      <div className="pl-4 grid gap-3">
        {optionFields?.map((optionField: IOption, optionIndex: number) => {
          return (
            <div key={optionField?._id}>
              <div className="flex gap-2">
                <Controller
                  control={control}
                  name={`polls.${pollIndex}.options.${optionIndex}.name`}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      type="text"
                      placeholder={t("enteroption")}
                    />
                  )}
                />

                {optionIndex > 1 && (
                  <button
                    type="button"
                    onClick={() => removeOption(optionIndex)}
                    className="text-[#33CCFF] text-sm w-max outline-none"
                  >
                    {t("remove")}
                  </button>
                )}
              </div>
              {errors?.polls?.[pollIndex]?.options?.[optionIndex]?.name && (
                <div className="text-red-600 text-sm mt-1">
                  {t(
                    errors?.polls?.[pollIndex]?.options?.[optionIndex]?.name
                      ?.message
                  )}
                </div>
              )}
            </div>
          );
        })}

        <div className="flex justify-between items-center">
          <Controller
            control={control}
            name={`polls.${pollIndex}.isMultiAnswerAllow`}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    size="small"
                    checked={Boolean(field?.value)}
                  />
                }
                label={
                  <div className="text-sm capitalize">
                    {t("allowmultianswer")}
                  </div>
                }
              />
            )}
          />

          {optionFields?.length < 5 && (
            <button
              type="button"
              onClick={() =>
                appendOption({
                  _id: generateShortUUID(),
                  name: "",
                  value: [],
                })
              }
              className="text-[#33CCFF] outline-none capitalize"
            >
              + {t("addoptn")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PollFields;
