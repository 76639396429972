import React from "react";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const BreadCrumbs = ({ history, onClick }) => {
  return (
    <div className="flex gap-2 items-center">
      {history.length > 0 && (
        <div
          onClick={() => onClick(null, -1)}
          className="cursor-pointer text-gray-700 text-sm"
        >
          <HomeOutlinedIcon />
        </div>
      )}

      {history.length > 0 &&
        history.map((el, index) => (
          <React.Fragment>
            <span className="cursor-pointer text-gray-700 text-sm"> / </span>
            <div
              key={index}
              onClick={() => onClick(el, index)}
              className="cursor-pointer text-gray-700 text-sm"
            >
              {el?.name?.length > 15
                ? el?.name?.slice(0, 15) + "..."
                : el?.name}
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default BreadCrumbs;
