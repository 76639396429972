import {
  appendOldChats,
  setActiveRoomData,
  setActiveRoomsChats,
  setNotificationIssue,
  setTotalPage,
} from "./Redux/ChatSlice";
import {
  DefaultImageUrl,
  EVENT_INVITATION,
  vapidKey,
} from "./Constant/Constant";
import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emit } from "./Socket";
import { getToken, isSupported, onMessage } from "firebase/messaging";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { setActiveTab, setScrollBottom } from "./Redux/UserSlice";
import { authCllient } from "./Graphql/authClient";
import { GET_NOTIFICATION_BY_ID, SUBSCRIBE_TO_TOPIC } from "./Graphql/Queries";
import { messaging } from "./Firebase/firebase";
import TaskCompletedImg from "./Assets/Images/TaskCompletedImg.png";
import video from "./Assets/Images/VideoFileIcon.png";
import contact from "./Assets/Images/DocumentFileIcon.png";
import userAvtar from "./Assets/Images/userAvatar.png";
import { Avatar, Snackbar } from "@mui/material";
import { setCurrentNoti, setTaskData } from "./Redux/OrganizationSlice";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import sound from "./Assets/sound/notificationTune.mp3";
import {
  ParticipantAcceptStatus,
  RecurrentTypes,
} from "./Components/Calendar/types/enum";
import { NOTIFICATION_LOGS, UPDATE_REMINDER_STATUS } from "./Graphql/Mutations";
import WarningIcon from "./Assets/warning.png";
import Logo from "./Assets/Images/Logo.png";
import MultiTabDialog from "./Components/dialogs/MultiTabDialog";
import backgroundNotificationAction from "./utils/backgroundNotificationAction";
import ReminderSound from "./Assets/sound/reminder-sound.mp3";
import { setShowCalendar } from "./Redux/EventSlice";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const APP_SESSION_KEY = "app_session";

export default function AuthProvider({ children }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.user);
  const [toastMessage, setToastMessage] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const [notifyPayload, setNotifyPayload] = useState<any>("");
  const [notificationData, setNotificationData] = useState<any>("");
  const [profileUrl, setProfileUrl] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [firebaseMsgData, setFirebaseMsgData] = useState<any>("");
  const [isInvitation, setIsInvitation] = useState(false);
  const [loading, setloading] = useState(false);
  const notification_token = localStorage.getItem("notification_token");
  const [showMultiTabModal, setShowMultiTabModal] = useState(false);
  const [reminderData, setReminderData] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);

  const setNotificationLogs = async (NotificationType: string) => {
    if (userData?._id) {
      try {
        const response = await authCllient.mutate({
          mutation: NOTIFICATION_LOGS,
          variables: {
            input: {
              NotificationType,
              PlatformType: "WEB",
              userId: userData?._id,
              deviceId: notification_token,
              name: `${userData?.firstName} ${userData?.lastName}`,
            },
          },
        });

        if (response?.data) {
          console.log("Notification LOGGED successfully");
        }
      } catch (error) {
        console.log(`Error in notification logs: ${error}`);
      }
    }
  };

  let messageListenerAdded = false;
  const onBackgroundNotification = () => {
    if (navigator.serviceWorker.controller) {
      console.log("Service worker not active yet.");
      return;
    }

    const handleServiceWorkerMessage = (event: MessageEvent) => {
      if (event?.data?.type == "background_notification") {
        setNotificationLogs(event?.data?.notificationType);
        if (
          event?.data?.notificationType == "EVENT_BEFORE_REMINDER" ||
          event?.data?.notificationType == "EVENT_MAIN_REMINDER" ||
          event?.data?.notificationType == "EVENT_INVITATION_REMINDER"
        ) {
          const reminderSound = new Audio(ReminderSound);
          reminderSound?.play();
        } else {
          const notificationSound = new Audio(sound);
          notificationSound?.play();
        }
      } else if (event?.data?.type == "notification_clicked") {
        const actionType = event?.data?.data?.notificationType;
        backgroundNotificationAction(actionType, dispatch, event?.data?.data);
      }
    };

    if (!messageListenerAdded) {
      navigator.serviceWorker.addEventListener(
        "message",
        handleServiceWorkerMessage
      );
      messageListenerAdded = true;
    }
  };

  const NotificationBar = () => {
    const { rooms, activeRoomData } = useSelector((state: any) => state.chat);

    useEffect(() => {
      if (window.location.pathname.includes("/")) {
        let tempRoomData = rooms?.find(
          (item: any) => item?._id === notificationData?.myMessage?.roomId
        );
        setFirebaseMsgData(tempRoomData);
      }
      // return () => {
      requestPermission();
      // };
    }, [rooms?.length > 0, activeRoomData?._id]);

    const getNotificationById = async (_id: string) => {
      try {
        const response = await authCllient.query({
          query: GET_NOTIFICATION_BY_ID,
          variables: {
            input: { _id },
          },
        });

        const result = response?.data?.getNotificationById;
        if (result) {
          console.log("notification response (foreground)", result);
          setNotifyPayload({
            type: result?.type,
            data: JSON.stringify({ data: result?.payload }),
          });

          const parsedPayload = JSON.parse(result?.payload);
          setToastTitle(result?.title);
          setToastMessage(result?.body);
          setToastShow(true);

          if (
            result?.type == "EVENT_BEFORE_REMINDER" ||
            result?.type == "EVENT_MAIN_REMINDER" ||
            result?.type == "EVENT_INVITATION_REMINDER"
          ) {
            const reminderSound = new Audio(ReminderSound);
            reminderSound?.play();
          } else {
            const notificationSound = new Audio(sound);
            notificationSound?.play();
          }

          if (
            result?.type == "EVENT_INVITATION_REMINDER" ||
            result?.type == "SCHEDULE_BEFORE_REMINDER"
          ) {
            const parsedReminder = JSON.parse(parsedPayload?.data);
            if (result?.type == "SCHEDULE_BEFORE_REMINDER") {
              setScheduleData({
                ...parsedReminder,
                notify_id: parsedPayload?.reminderID,
              });
            } else {
              setReminderData(parsedReminder);
            }
            setIsInvitation(true);
          }
        }
      } catch (error) {
        console.log(`Error in show notification`, error);
      }
    };

    async function requestPermission() {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            dispatch(setNotificationIssue(true));
            getToken(messaging, { vapidKey })
              .then((currentToken) => {
                if (currentToken) {
                  localStorage.setItem("notification_token", currentToken);
                  const authToken = localStorage.getItem("token");
                  const alreadyDone = localStorage.getItem("subscribed");
                  if (alreadyDone === "false" && currentToken) {
                    setTimeout(() => {
                      if (authToken !== null) {
                        authCllient
                          .query({
                            query: SUBSCRIBE_TO_TOPIC,
                            variables: {
                              token: currentToken,
                            },
                          })
                          .then((res: any) => {
                            if (res?.data?.subscribeToTopic?.success) {
                              localStorage.setItem("subscribed", "true");
                            }
                          });
                      }
                    }, 2000);
                  }
                  onMessage(messaging, (payload) => {
                    if (payload && payload?.data && payload?.data?.data) {
                      const response = JSON.parse(payload?.data?.data);
                      const reminderParsedData = response?.data?.data
                        ? JSON.parse(response?.data?.data)
                        : null;
                      setReminderData(reminderParsedData);
                      setNotificationLogs(payload?.data?.type);

                      console.log("notification response --> ", response);

                      if (payload?.data?.type != "CHAT") {
                        getNotificationById(response?.data?.notificationId);
                      } else {
                        setNotifyPayload(payload.data);

                        console.log(
                          "notification response (foreground)",
                          response
                        );

                        if (
                          response?.myMessage?.roomId &&
                          activeRoomData?._id &&
                          response?.myMessage?.roomId == activeRoomData?._id
                        ) {
                          // don't show notification when type is CHAT and that chat room is open
                        } else {
                          const notificationSound = new Audio(sound);
                          notificationSound?.play();

                          setNotificationData(response);

                          if (response?.data?.callBackground) {
                            setProfileUrl(response?.data?.callBackground);
                          }

                          if (
                            response?.notification?.body === "INVITED" ||
                            response?.notification?.body === "DECLINED"
                          ) {
                            const message = JSON.parse(
                              response?.myMessage?.message
                            )?.msg;
                            setToastShow(true);
                            setToastMessage(message);
                          } else {
                            const selected = rooms.find(
                              (el: any) =>
                                el._id === response?.myMessage?.roomId
                            );
                            switch (response?.myMessage?.type) {
                              case "DOCUMENT": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage(
                                    response?.myMessage?.fileURL
                                      .split("/")
                                      .pop()
                                  );
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage(
                                    `${response?.notification?.title
                                      .replace(/\s+/g, " ")
                                      .trim()}: ` +
                                      response?.myMessage?.fileURL
                                        .split("/")
                                        .pop()
                                  );
                                }
                                setToastShow(true);
                                break;
                              }
                              case "IMAGE": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage("");
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage("");
                                }
                                setToastShow(true);
                                break;
                              }
                              case "VIDEO": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage("");
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage("");
                                }
                                setToastShow(true);
                                break;
                              }
                              case "contact": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage("");
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage("");
                                }
                                setToastShow(true);
                                break;
                              }
                              case "text": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage(response?.notification?.body);
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage(
                                    `${response?.notification?.title
                                      .replace(/\s+/g, " ")
                                      .trim()}: ` + response?.notification?.body
                                  );
                                }
                                setToastShow(true);
                                break;
                              }
                              case "poll": {
                                setProfileUrl(response?.profile_img);
                                setToastTitle(t("newpollcreated"));
                                setToastMessage(response?.notification?.body);
                                setToastShow(true);
                                break;
                              }
                              case "changedDescription": {
                                if (response?.subtitle === "") {
                                  setProfileUrl(response?.profile_img);
                                  setToastTitle(response?.notification?.title);
                                  setToastMessage(response?.notification?.body);
                                } else {
                                  setProfileUrl(selected?.profile_img);
                                  setToastTitle(response?.subtitle);
                                  setToastMessage(
                                    `${response?.notification?.title
                                      .replace(/\s+/g, " ")
                                      .trim()}: ` +
                                      response?.myMessage?.message.replace(
                                        "changed the description of group",
                                        t("description-change-group")
                                      )
                                  );
                                }
                                setToastShow(true);
                                break;
                              }
                              case "invited":
                              case "taskAssigned": {
                                setProfileUrl(response?.profile_img);
                                setToastTitle(response?.notification?.title);
                                setToastMessage(response?.notification?.body);
                                setToastShow(true);
                                break;
                              }
                              default: {
                                setProfileUrl("");
                                setToastTitle("");
                                setToastMessage("");
                              }
                            }
                          }
                        }
                      }
                    }
                  });
                } else {
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                  // ...
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                // ...
              });
          } else {
            dispatch(setNotificationIssue(false));
          }
        });
      } else {
        console.log("Firebase not supported this browser");
      }

      // return data;
    }

    const handleFirebaseMsgClick = (tempRoomData?: any) => {
      switch (notifyPayload.type) {
        case "TASK_COMPLETE":
          {
            setToastShow(false);
            dispatch(setCurrentNoti(notifyPayload));
            dispatch(setTaskData(true));
          }
          break;
        case "REMINDER":
          {
            setToastShow(false);
            dispatch(setCurrentNoti(notifyPayload));
            dispatch(setTaskData(true));
          }
          break;
        default:
          {
            if (activeRoomData?._id !== firebaseMsgData?._id) {
              dispatch(setActiveRoomData(firebaseMsgData));
              /* emit("getChatsByRoomId", {
                            roomId: firebaseMsgData?._id,
                            skip: 0,
                            limit: 50,
                        }); */
              dispatch(setActiveRoomsChats([]));
              dispatch(
                setTotalPage(Math.ceil(notificationData?.totalChats / 50))
              );
              //dispatch(setTotalPage(0))
              dispatch(appendOldChats([]));

              dispatch(setActiveRoomData(firebaseMsgData));
            }
            setTimeout(() => {
              if (document.querySelector("#scrollableDiv") !== null) {
                var height =
                  document.querySelector("#scrollableDiv").scrollHeight;
                document
                  .querySelector("#scrollableDiv")
                  .scroll(0, height - 100);
              }
            }, 3000);
            emit("getUserMediaByRoomId", {
              roomId: firebaseMsgData?._id,
              type: "media",
            });

            dispatch(setScrollBottom(true));
          }
          break;
      }

      setReminderData(null);
      setScheduleData(null);
      setIsInvitation(false);
      //dispatch(setActiveRoomChats([]));
      //dispatch(setTotoalChatsCount(50));
      //dispatch(setActiveChatSkip(0));
    };

    const renderImage = () => {
      return (
        <>
          {notificationData?.myMessage?.type === "IMAGE" ? (
            <div className="flex">
              {notificationData?.subtitle !== ""
                ? `${notificationData?.notification?.title}: `
                : ""}
              <img
                src={DefaultImageUrl + notificationData?.myMessage?.fileURL}
                className="h-[30px] w-[30px] ml-1 rounded-md"
              />
            </div>
          ) : notificationData?.myMessage?.type === "VIDEO" ? (
            <div className="flex items-center text-xs">
              {notificationData?.subtitle !== ""
                ? `${notificationData?.notification?.title}: `
                : ""}
              <img src={video} className="h-[24px] w-[24px] ml-1 rounded-md" />
              <div className="mt-1 ml-1 text-xs">{`(${notificationData?.myMessage?.type
                .replace("IMAGE", t("image"))
                .replace("VIDEO", t("video"))
                .replace("AUDIO", t("audio"))
                .replace("DOCUMENT", t("document"))})`}</div>
            </div>
          ) : notificationData?.myMessage?.type === "contact" ? (
            <div className="flex items-center text-xs">
              {notificationData?.subtitle !== ""
                ? `${notificationData?.notification?.title}: `
                : ""}
              <img
                src={contact}
                className="h-[24px] w-[24px] ml-1 rounded-md"
              />
              <div className="mt-1 ml-1 text-xs capitalize">{`(${notificationData?.myMessage?.type.replace(
                "contact",
                t("contact")
              )})`}</div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    };

    async function handleAcceptInvitation(
      event: MouseEvent,
      ApprovalStatus: string
    ) {
      event?.stopPropagation();
      const payload = {
        _id: reminderData?.notify_id || scheduleData?.notify_id,
        ApprovalStatus,
      };

      setloading(true);
      try {
        const response = await authCllient.mutate({
          mutation: UPDATE_REMINDER_STATUS,
          variables: {
            input: payload,
          },
        });

        if (response?.data) {
        }
      } catch (err) {
        console.log(err);
      } finally {
        setToastShow(false);
        setloading(false);
        setScheduleData(null);
        setReminderData(null);
        setIsInvitation(false);
      }
    }

    return (
      <div className="cursor-pointer" onClick={() => handleFirebaseMsgClick()}>
        <Snackbar
          open={toastShow}
          autoHideDuration={5000}
          onClose={() => {
            setToastShow(false);
            setReminderData(null);
            setScheduleData(null);
            setIsInvitation(false);
            setNotificationData("");
            setNotifyPayload("");
            setProfileUrl("");
          }}
          message={
            <div>
              <div className="flex items-center">
                {notifyPayload?.type == "CHAT" ? (
                  <div>
                    {profileUrl === "" ? (
                      <Avatar sx={{ height: 45, width: 45 }} />
                    ) : (
                      <img
                        src={
                          notificationData?.data
                            ? TaskCompletedImg
                            : profileUrl
                            ? DefaultImageUrl + profileUrl
                            : userAvtar
                        }
                        alt="profile"
                        className="h-[45px] w-[45px] rounded-full"
                      />
                    )}
                  </div>
                ) : (
                  <div className="bg-[#33ccff] flex justify-center items-center h-[45px] w-[45px] rounded-full">
                    <NotificationsActiveIcon sx={{ color: "white" }} />
                  </div>
                )}
                <div className="pl-3">
                  <div className="font-bold text-md">{toastTitle}</div>
                  {toastMessage !== "" && (
                    <div className=" text-sm w-[18rem]">{toastMessage}</div>
                  )}
                  {/* {renderImage()} */}
                </div>
              </div>

              {isInvitation &&
                (reminderData?.recursive == RecurrentTypes.ONCE ||
                scheduleData?.recursive == RecurrentTypes.ONCE ? (
                  <div className="flex items-center justify-end gap-3">
                    {!loading ? (
                      <React.Fragment>
                        <button
                          onClick={(ev) =>
                            handleAcceptInvitation(
                              ev,
                              ParticipantAcceptStatus.REJECT
                            )
                          }
                          className="outline-none text-sm border border-red-700 text-red-700 rounded-md py-1 px-2"
                        >
                          {t("reject")}
                        </button>
                        <button
                          onClick={(ev) =>
                            handleAcceptInvitation(
                              ev,
                              ParticipantAcceptStatus.ACCEPT
                            )
                          }
                          className="outline-none text-sm border border-green-700 text-green-700 rounded-md py-1 px-2"
                        >
                          {t("accept")}
                        </button>
                      </React.Fragment>
                    ) : (
                      <div className="text-right">{t("please_wait")}</div>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center justify-end gap-3">
                    {reminderData && (
                      <button
                        onClick={() => dispatch(setShowCalendar(true))}
                        className="outline-none text-sm border border-blue-700 text-blue-700 rounded-md py-1 px-2"
                      >
                        {t("viewdetails")}
                      </button>
                    )}
                  </div>
                ))}
            </div>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          ContentProps={{
            sx: {
              background: "#F3F9FC",
              color: "black",
            },
          }}
        />
      </div>
    );
  };

  const browserTabChanged = () => {
    if (document.hidden) {
      dispatch(setActiveTab(false));
    } else {
      dispatch(setActiveTab(true));
    }
  };

  const windowFocusChanged = () => {
    if (document.hasFocus()) {
      dispatch(setActiveTab(true));
    } else {
      dispatch(setActiveTab(false));
    }
  };

  const changeIcon = (icon) => {
    let link: any = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = icon;
  };

  const handleMultiTab = (event) => {
    if (event?.storageArea === localStorage && event?.key === APP_SESSION_KEY) {
      changeIcon(WarningIcon);
      setShowMultiTabModal(true);
    }
  };

  useEffect(() => {
    localStorage.setItem("subscribed", "false");
    localStorage.setItem(APP_SESSION_KEY, Date.now().toString());
    onBackgroundNotification();

    document.addEventListener("visibilitychange", browserTabChanged);
    window.addEventListener("focus", windowFocusChanged);
    window.addEventListener("blur", windowFocusChanged);
    window.addEventListener("storage", handleMultiTab);

    return () => {
      document.removeEventListener("visibilitychange", browserTabChanged);
      window.removeEventListener("focus", windowFocusChanged);
      window.removeEventListener("blur", windowFocusChanged);
      window.removeEventListener("storage", handleMultiTab);
      localStorage.removeItem(APP_SESSION_KEY);
      changeIcon(Logo);
    };
  }, []);

  return (
    <>
      {showMultiTabModal && <MultiTabDialog show={showMultiTabModal} />}

      {notificationData.myMessage?.sender !== userData?._id && (
        <NotificationBar />
      )}

      {children}
    </>
  );
}
