import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormPropsType } from "../../../types/types";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  ReminderParticipantRole,
} from "../../../types/enum";
import { useTranslation, TFunction } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { getCalendarColor } from "../../../utils/calendarColors";

const capitalizeFirstLetter = (str: string, t: TFunction) => {
  return t(str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase());
};

function AddEventType(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm, errors, setErrors, closeComposeDialog } = props;
  const { userData } = useSelector((state: any) => state?.user);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event?.target?.value;
    const newErrors = { ...errors };
    delete newErrors.time;
    delete newErrors.endTimes;
    setErrors(newErrors);
    const participants = [
      {
        _id: userData?._id,
        role: ReminderParticipantRole.ADMIN,
        accepted: ParticipantAcceptStatus.ACCEPT,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        phone: userData?.phone,
        profile_img: userData?.profile_img,
      },
    ];
    setForm({
      ...form,
      type: value,
      participants:
        form?.type == EventTypeEnum.CALLREMINDER && form?.hasComon
          ? participants
          : form?.participants,
    });
  }

  return (
    <div className="flex justify-between items-center">
      <FormControl>
        <RadioGroup
          value={form?.type}
          onChange={handleChange}
          sx={{ display: "grid", gridTemplateColumns: "auto auto auto" }}
        >
          <FormControlLabel
            value={EventTypeEnum.REMINDER}
            control={
              <Radio
                size="small"
                sx={{
                  ".css-hyxlzm": {
                    color: getCalendarColor(form?.type, "dark"),
                  },
                }}
              />
            }
            label={
              <div className="text-sm">
                {capitalizeFirstLetter(EventTypeEnum.REMINDER, t)}
              </div>
            }
          />
          <FormControlLabel
            value={EventTypeEnum.APPOINTMENT}
            control={
              <Radio
                size="small"
                sx={{
                  ".css-hyxlzm": {
                    color: getCalendarColor(form?.type, "dark"),
                  },
                }}
              />
            }
            label={
              <div className="text-sm" style={{ textTransform: "capitalize" }}>
                {capitalizeFirstLetter(EventTypeEnum.APPOINTMENT, t)}
              </div>
            }
          />
          <FormControlLabel
            value={EventTypeEnum.CALLREMINDER}
            control={
              <Radio
                size="small"
                sx={{
                  ".css-hyxlzm": {
                    color: getCalendarColor(form?.type, "dark"),
                  },
                }}
              />
            }
            label={
              <div className="text-sm" style={{ textTransform: "capitalize" }}>
                {t("call_reminder")}
              </div>
            }
          />
        </RadioGroup>
      </FormControl>

      <IconButton onClick={closeComposeDialog}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default AddEventType;
