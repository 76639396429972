import { useMutation } from "@apollo/client";
import { useState } from "react";
import { UPLOAD_FILE } from "../../../Graphql/Mutations";
import { useSelector } from "react-redux";

const TaskRecordInput = ({
  setAttachmentId,
  isExecutable,
  currentTask,
  setLoading,
}: any) => {
  const [audioRecordedFile, setAudioRecordedFile] = useState("");
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const { currentOrganization } = useSelector(
    (state: any) => state?.organization
  );
  const addAudioElement = (blob: any) => {
    setLoading(true);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    setAudioRecordedFile(url);

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new File([arrayBuffer], "audiofile.mp3", {
          type: "audio/mp3",
        });

        uploadFile({
          variables: {
            file: file,
            input: {
              orgId: currentOrganization?._id,
              attachments: false
            },
          },
        })
          .then((res) => setAttachmentId(res?.data?.uploadFile?._id))
          .catch((error) =>
            console.log(`Error occurred at Goal File upload => ${error}`)
          )
          .finally(() => setLoading(false));
      });
  };

  const handleCancelAudio = () => {
    setAudioRecordedFile("");
  };

  return (
    <>
      {currentTask?.mediaType === "RECORD" && isExecutable && (
        <div className="flex gap-3">
          {audioRecordedFile !== "" ? (
            <>
              <audio src={audioRecordedFile} controls></audio>

              <button
                className="text-red-600 text-sm"
                onClick={handleCancelAudio}
              >
                Cancel
              </button>
            </>
          ) : (
            <></>
            // <AudioRecorder onRecordingComplete={addAudioElement} />
          )}
        </div>
      )}
    </>
  );
};

export default TaskRecordInput;
