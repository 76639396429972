export enum ChatAction {
  ADDEDUSER = "ADDEDUSER",
  CREATEDROOM = "CREATEDROOM",
  CHANGEDDESCRIPTION = "CHANGEDDESCRIPTION",
  CHANGEDNAME = "CHANGEDNAME",
  LEFTROOM = "LEFTROOM",
  CHANGEDPICTURE = "CHANGEDPICTURE",
  CHATDISAPPEAR = "CHATDISAPPEAR",
  REMOVEDUSER = "REMOVEDUSER",
  TEXT='TEXT',
  POLL='POLL',
  AUDIO='AUDIO',
  VIDEO='VIDEO',
  DOCUMENT="DOCUMENT",
  IMAGE='IMAGE',
  APPLICATION="APPLICATION",
  CONTACT='CONTACT',
  LINK="LINK"
}
