import {
  Avatar,
  Box,
  CircularProgress,
  Drawer,
  Tooltip,
  Badge,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import "../../App.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import Task from "../SideTab/Task";
import SettingsIcon from "@mui/icons-material/Settings";
import Call from "../SideTab/Call";
import ChatPage from "../ChatPage/ChatPage";
import SettingHome from "../SettingDialog/SettingHome";
import FolderIcon from "../../Assets/Images/FolderIcon.png";
import ChatTab from "./ChatTab";
import setting from "../../Assets/Images/setting.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppPath, DefaultImageUrl, taskFlowUrl } from "../../Constant/Constant";
import FolderDrawer from "./FolderDrawer";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CreateRoom from "./CreateRoom";
import Popover from "@mui/material/Popover";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { emit, socketDisconnect, socketListen } from "../../Socket";
import { authCllient } from "../../Graphql/authClient";
import { LOGOUT, UNSUBSCRIBE_FROM_TOPIC } from "../../Graphql/Queries";
import { resetUser, setScrollBottom } from "../../Redux/UserSlice";
import {
  appendOldChats,
  resetChat,
  setActiveCallData,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomData,
  setInitiatedCallData,
  setIsCallMinimized,
  setIsRoomLoading,
  setJoinedFromOtherDevice,
  setLeftCallData,
  setTotoalChatsCount,
  updateCallStatus,
} from "../../Redux/ChatSlice";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { useMutation } from "@apollo/client";
import { CALL_END, CREATE_ROOM, LEFT_CALL } from "../../Graphql/Mutations";
import ReactLoading from "react-loading";
import Organizations from "./Organizations";
import { useOrganizations } from "../../hooks/useOrganizations";
import SignalWifiStatusbarConnectedNoInternet4OutlinedIcon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Outlined";
import { FaEnvelopeOpenText, FaTasks } from "react-icons/fa";
import ProfileDrawer from "./ProfileDrawer";
import TaskPage from "../Task/TaskPage";
import ReportPage from "../Report/ReportPage";
import {
  resetOrg,
  setCurrentOrganization,
  setLoadingState,
  setShowTaskPage,
  setTaskData,
} from "../../Redux/OrganizationSlice";
import parse from "html-react-parser";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { FiMaximize2 } from "react-icons/fi";
import { RoomsDataType } from "../../Types/Types";
import { client_call } from "../..";
import GroupAvatar from "../../Assets/Images/Avatar.png";
import { store } from "../../Redux/store";
import OrganizationListView from "./OrganizationListView";
import { useTranslation } from "react-i18next";
import FadeMenu from "../../coreComponent/FadeMenu";
import SettingDrawer from "./Settings/SettingDrawer";
import GroupNavigationModal from "./GroupNavigationModal";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Headings from "../RightTab/Headings";
import CalendarApp from "../../Components/Calendar/CalendarApp";
// import DatabaseIcon from "../../Assets/Images/my-database.png";
import { BsDatabase } from "react-icons/bs";
import DatabaseDrawer from "../RightTab/database/DatabaseDrawer";

function Home({ time, setCallEnd }: any) {
  const [headerTab, setHeaderTab] = useState(2);
  const [openModel, setOpenModel] = React.useState(false);
  const {
    userData,
    isContactsLoading,
    formattedContact,
    userContacts,
    noInternetConnection,
    comonUsers,
  } = useSelector((state: any) => state.user);
  const {
    rooms,
    isRoomsLoading,
    isCallMinimized,
    formattedParticipantsOfActiveRoom,
    activeCallData,
    initiatedCall,
  } = useSelector((state: any) => state.chat);
  const {
    showTaskPage,
    organizations,
    currentNotifyData,
    notificationArray,
    taskData,
    currentOrganization,
    invites,
  } = useSelector((state: any) => state.organization);
  const { showCalendarPage } = useSelector((state: any) => state?.events);
  const [headingShow, setHeadingShow] = useState(false);
  const { getMyAssignment, getMyReports } = useOrganizations();
  const [loader, setLoader] = useState(false);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [roomUpdated, setRoomsUpdated] = useState<any>([]);
  const [language, setLanguage] = useState<any>("");
  const [disable, setDisable] = useState(false);
  const [endCallInput] = useMutation(CALL_END);
  const [folderDrawerShow, setFolderDrawerShow] = useState(false);
  const [createGroupShow, setCreateGroupShow] = useState(false);
  const [type, setType] = useState("group");
  const [profilePageShow, setProfilePageShow] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuAnchorElOrg, setMenuAnchorElOrg] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const [showSettingModal, setShowSettingsModal] = useState(false);
  const openOrg = Boolean(menuAnchorElOrg);
  const menuId = openMenu ? "simple-popover" : undefined;
  const notificationToken = localStorage.getItem("notification_token");
  const [tabButton, setTabButton] = useState<Number>(1);
  const [showOrganization, setShowOrganization] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [noneInitiatedRooms, setNoneInitiatedRooms] = useState([]);
  const [isRoomCreating, setRoomCreating] = useState(false);
  const [createRoom, { data }] = useMutation(CREATE_ROOM);
  const [roomsData, setRoomsData] = useState([]);
  const [showReportPage, setShowReportPage] = useState(false);
  const [showChatPage, setShowChatPage] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disableLeftButton, setDisableLeftButton] = useState(false);
  const [showGroupNavigation, setShowGroupNavigation] = useState(false);
  const [showDatabase, setShowDatabase] = useState(false);
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");

  const onGoingCallRoomDetail = rooms?.find((item: RoomsDataType) =>
    activeCallData?.data?.roomId?._id
      ? item?._id === activeCallData?.data?.roomId?._id
      : item?._id === activeCallData?.data?.roomId
  );

  const onGoingCallIndividual = onGoingCallRoomDetail?.participants?.find(
    (item: any) => item?.user_id !== userData?._id
  );

  useEffect(() => {
    const comon_Users: any = Object.values(formattedContact);
    const individualRoom: any = rooms?.filter(
      (one: any) => one?.type === "individual"
    );
    const result = [];

    for (const user of comon_Users) {
      const isExist = individualRoom.find((one: any) => {
        const existInparticipant = one.participants.find(
          (p: any) => p.user_id === user?.userId?._id
        );
        return existInparticipant ? true : false;
      });
      if (!isExist) {
        result.push(user);
      }
    }
    setNoneInitiatedRooms(result);
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (rooms.length > 0) {
      let temp: any = [];
      rooms?.forEach((item: any) => {
        const element = { ...item };
        const userInfo = element?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        );
        const participantContactDetail = formattedContact[userInfo?.user_id];
        if (element.type === "individual") {
          element["name"] = participantContactDetail?.firstName
            ? participantContactDetail?.firstName.toLowerCase() +
              " " +
              participantContactDetail?.lastName.toLowerCase()
            : `${userInfo?.phone}`;
        }
        temp.push(element);
      });
      setRoomsUpdated(temp);
    }
  }, [rooms]);

  useEffect(() => {
    if (!authToken) {
      navigate(AppPath.login, {
        state: {
          tokenParam: tokenParam || null,
        },
      });
    }
    // eslint-disable-next-line
  }, [authToken]);

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const removeWebDevice = async () => {
    let deviceId = localStorage.getItem("deviceId");
    await authCllient
      .query({
        query: LOGOUT,
        variables: {
          input: {
            plateform: "WEB",
            token: deviceId,
          },
        },
      })
      .then((res) => {
        localStorage.removeItem("deviceId");
        // console.log(res);
      })
      .catch((error) => console.log(error));
  };

  const handleLogout = async () => {
    setLoader(true);
    setCallEnd(true);
    socketDisconnect();
    if (activeCallData !== "") {
      endCallInput({
        variables: {
          endCallInput: {
            callId: activeCallData?.data?.callId,
            userId: userData._id,
          },
        },
      }).then(async (res) => {
        dispatch(setJoinedFromOtherDevice(false));
        await client_call.leave();
        dispatch(updateCallStatus(false));
        dispatch(setInitiatedCallData(""));
        dispatch(setActiveCallData(""));
        dispatch(setLeftCallData(""));
      });
    }

    setTimeout(() => {
      dispatch(resetChat(store.getState().chat));
      dispatch(resetOrg(store.getState().organization));
      dispatch(resetUser(store.getState().user));
    }, 1000);

    localStorage.removeItem("isMasterAdmin");
    localStorage.removeItem("refresh");
    localStorage.removeItem("expireAt");
    localStorage.removeItem("subscribed");

    await removeWebDevice();

    if (notificationToken) {
      await authCllient
        .query({
          query: UNSUBSCRIBE_FROM_TOPIC,
          variables: {
            token: `${notificationToken}`,
          },
        })
        .then((res) => {
          localStorage.removeItem("token");
          localStorage.removeItem("currentOrganization");
          localStorage.removeItem("notification_token");
          setLoader(false);
          navigate(AppPath.login);
          // console.log(res);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoader(false);
        });
    } else {
      navigate(AppPath.login);
      localStorage.removeItem("token");
      localStorage.removeItem("currentOrganization");
      localStorage.removeItem("notification_token");
      setLoader(false);
    }
  };

  const handleSearchRooms = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchQuery(e.target.value);
      const data: any = [];
      roomUpdated?.forEach((item: any) => {
        if (
          item?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
          // item?.name?.split(' ')[0]?.startsWith(e.target.value.toLowerCase()) || item?.name?.split(' ')[1]?.startsWith(e.target.value.toLowerCase())
        ) {
          data.push(item);
        }
      });

      noneInitiatedRooms?.forEach((item: any) => {
        if (
          item?.firstName
            ?.toLowerCase()
            .startsWith(e.target.value.toLowerCase()) ||
          item?.lastName
            ?.toLowerCase()
            .startsWith(e.target.value.toLowerCase()) ||
          item?.phone?.startsWith(e.target.value)
        ) {
          data.push(item);
        }
      });

      setSearchData(data);
    } else {
      setSearchQuery("");
      setSearchData([]);
    }
  };
  const setCurrentOrg = async (org: any) => {
    localStorage.setItem("currentOrganization", JSON.stringify(org));
    dispatch(setCurrentOrganization(org));
    getMyAssignment(org._id, 0, 0).then(() => {
      getMyReports(0, 0);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        localStorage.getItem("currentOrganization") !== undefined &&
        localStorage.getItem("currentOrganization") !== "undefined"
      ) {
        if (
          JSON.parse(localStorage.getItem("currentOrganization"))?._id !==
          undefined
        ) {
          setCurrentOrg(
            JSON.parse(localStorage.getItem("currentOrganization"))
          );
        } else if (organizations?.length > 0) {
          setCurrentOrg(organizations[0]);
        }
      }
    };
    if (!taskData) {
      fetchData();
    }
  }, [organizations]);

  const handleRoomClick = (item: any) => {
    if (item?._id) {
      setSearchQuery("");
      setHeaderTab(2);
      dispatch(setScrollBottom(true));
      dispatch(setActiveRoomData(item));
      //   emit("getChatsByRoomId", { roomId: item?._id, skip: 0, limit: 50 });
      dispatch(setActiveRoomChats([]));
      dispatch(appendOldChats([]));
      dispatch(setTotoalChatsCount(50));
      dispatch(setActiveChatSkip(0));
      emit("getUserMediaByRoomId", { roomId: item?._id, type: "media" });
    } else {
      setRoomCreating(true);
      createRoom({
        variables: {
          input: {
            type: "individual",
            users: [item?.userId?._id],
            name: null,
            profile_img: null,
            localId: "0",
          },
        },
      }).then((res) => {
        emit("GetAllRooms");
        setTimeout(() => {
          socketListen("message", (data: any) => {
            if (data.type === "GetAllRooms") {
              const filteredData = data?.msg?.rooms.filter(
                (item: any) => item?.organization === ""
              );
              setRoomsData(filteredData);
            }
          });
        }, 1000);
      });
    }
  };
  useEffect(() => {
    const createdRoomData = roomsData?.find(
      (item: any) => item?._id === data?.createRoom?.roomId
    );
    if (createdRoomData) {
      setSearchQuery("");
      setHeaderTab(2);
      dispatch(setScrollBottom(true));
      dispatch(setActiveRoomData(createdRoomData));
      emit("getChatsByRoomId", {
        roomId: createdRoomData?._id,
        skip: 0,
        limit: 50,
      });
      dispatch(setActiveRoomChats([]));
      dispatch(appendOldChats([]));
      dispatch(setTotoalChatsCount(50));
      dispatch(setActiveChatSkip(0));
      emit("getUserMediaByRoomId", {
        roomId: createdRoomData?._id,
        type: "media",
      });
      setRoomCreating(false);
    }
    // eslint-disable-next-line
  }, [roomsData?.length]);

  const handleClickChatTab = () => {
    dispatch(setIsRoomLoading(false));
    dispatch(setActiveRoomData({}));
    dispatch(setActiveRoomChats([]));
    setHeaderTab(2);
    dispatch(setShowTaskPage(false));
    setShowReportPage(false);
    setShowChatPage(true);
  };

  const handleMaximize = () => {
    dispatch(setIsCallMinimized(false));
  };

  const handleLaveCall = async () => {
    setCallEnd(true);
    if (
      activeCallData?.data?.roomType === "group" ||
      activeCallData?.data?.roomType === "contact_group"
    ) {
      authCllient
        .mutate({
          mutation: LEFT_CALL,
          variables: {
            input: {
              _id: activeCallData?.data?.callId ?? activeCallData?.data?._id,
            },
          },
        })
        .then((res) => {
          setTimeout(() => {
            dispatch(updateCallStatus(false));
            dispatch(setIsCallMinimized(false));
            dispatch(setJoinedFromOtherDevice(false));
          }, 3000);
        })
        .catch((error) => console.log("error at left call", error))
        .finally(() => setDisableLeftButton(false));
    } else {
      authCllient
        .mutate({
          mutation: CALL_END,
          variables: {
            endCallInput: {
              callId: activeCallData?.data?.callId,
              userId: userData?._id,
            },
          },
        })
        .then((res) => {
          setTimeout(() => {
            dispatch(updateCallStatus(false));
            dispatch(setIsCallMinimized(false));
            dispatch(setJoinedFromOtherDevice(false));
          }, 3000);
        })
        .catch((error) => console.log("error at end call", error))
        .finally(() => setDisableLeftButton(false));
    }
  };

  const contactCallRoomPicture: any = (participants: any) => {
    let picture = "";

    if (activeCallData?.data?.roomType === "contact") {
      const otherUser = participants?.find(
        (item: any) => item?.userId?._id !== userData?._id
      );
      picture = DefaultImageUrl + otherUser?.userId?.profile_img;
    } else if (activeCallData?.data?.roomType === "individual") {
      const roomData = rooms?.find(
        (item: RoomsDataType) => item?._id === activeCallData?.data?.roomId
      );
      const participant = participants?.find((item: any) =>
        item?.userId
          ? item?.userId?._id !== userData?._id
          : item?.user_id !== userData?._id
      );
      picture =
        DefaultImageUrl +
        formattedContact[
          participant.userId ? participant.userId._id : participant?.user_id
        ]?.profile_img;
    } else {
      picture = GroupAvatar;
    }

    return picture;
  };

  const contactCallRoomName: any = (participants: any) => {
    let roomName = "";

    if (activeCallData?.data?.roomType === "group") {
      const roomData = rooms?.find((item: RoomsDataType) =>
        !activeCallData?.data?.roomId?._id
          ? item?._id === activeCallData?.data?.roomId
          : item?._id === activeCallData?.data?.roomId?._id
      );
      roomName = roomData?.name;
    } else if (activeCallData?.data?.roomType === "individual") {
      const roomData = rooms?.find(
        (item: RoomsDataType) => item?._id === activeCallData?.data?.roomId
      );
      const participant = participants?.find((item: any) =>
        item?.userId
          ? item?.userId?._id !== userData?._id
          : item?.user_id !== userData?._id
      );
      const contactName =
        formattedContact[
          participant.userId ? participant.userId._id : participant?.user_id
        ] === undefined
          ? participant.userId.phone
          : formattedContact[
              participant.userId ? participant.userId._id : participant?.user_id
            ]?.firstName +
            " " +
            formattedContact[
              participant.userId ? participant.userId._id : participant?.user_id
            ]?.lastName;

      roomName = contactName ? contactName : participant?.phone;
    } else if (activeCallData?.data?.roomType === "contact_group") {
      const otherUsers = activeCallData?.data?.callParticipants?.filter(
        (item: any) => item?.userId?._id !== userData?._id
      );

      const contactNameOne = formattedContact[otherUsers[0]?.userId?._id]
        ? formattedContact[otherUsers[0]?.userId?._id]?.firstName +
          " " +
          formattedContact[otherUsers[0]?.userId?._id]?.lastName
        : otherUsers[0]?.userId?.phone;

      const contactNameTwo = formattedContact[otherUsers[1]?.userId?._id]
        ? formattedContact[otherUsers[1]?.userId?._id]?.firstName +
          " " +
          formattedContact[otherUsers[1]?.userId?._id]?.lastName
        : otherUsers[0]?.userId?.phone;

      if (otherUsers?.length === 2) {
        roomName = contactNameOne + ", " + contactNameTwo;
      } else {
        roomName = contactNameOne + ", " + contactNameTwo + " & others";
      }
    }

    return roomName;
  };

  /*  const openTaskPage=async()=>{
     try {
       const targetURL="https://comon-task.peclick.com/" 
       //const targetURL = 'https://fe-pr-preview-pb2r7hxayq-ez.a.run.app/'; // Replace with your target URL
       const clientList = await Window.clients.matchAll({ type: 'window', includeUncontrolled: true });
 
       // Find the focused client or the first available client with the target URL
       const focusedClient = clientList.find(
         (client) => client.focused && client.url === targetURL
       ) || clientList.find((client) => client.url === targetURL);
 
       if (focusedClient) {
         // Do something with the focused or first available client
         console.log('Focused client or first available client:', focusedClient);
       } else {
         console.log(`No window clients found with URL ${targetURL}`);
       }
     } catch (error) {
       console.error('Error finding window clients:', error);
     }
   } */
  useMemo(() => {
    if (currentNotifyData && taskData) {
      switch (currentNotifyData?.type) {
        case "REMINDER":
          {
            setHeaderTab(1);
            setTabButton(1);
          }
          break;
        case "TASK_COMPLETE":
          {
            setHeaderTab(1);
            setTabButton(2);
          }
          break;
        // no default
      }
    }
  }, [taskData, currentNotifyData]);

  const renderLastMessage = (
    item: any,
    userInfo: any,
    first: string,
    last: string
  ) => {
    const message = item?.last_msg[0];
    const sender = item?.participants?.find(
      (el: any) => el?.user_id === message?.sender
    );
    const senderContactDetail = formattedContact[sender?.user_id];

    let lastMessage: any;

    if (item?.type === "group" || item?.type === "broadcast") {
      if (message?.type === "createdRoom") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message
                    ?.replace("created group", t("created-group"))
                    .replace(/\s+/g, " ")
                    .trim()
                    .slice(0, 22) + "..."
                : message?.message
                    .replace("created group", t("created-group"))
                    .replace(/\s+/g, " ")
                    .trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "addedUser" ||
        message?.type === "removedUser"
      ) {
        const jsonMsg = JSON.parse(message?.message);

        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + " "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="">
              {jsonMsg?.msg
                .replace("added", t("added"))
                .replace("removed", t("removed"))
                .replace(/\s+/g, " ")
                .trim()}
            </span>
            <span className="ml-1">
              {jsonMsg?.pid === userData?._id
                ? t("you")
                : formattedContact[jsonMsg?.pid]
                ? formattedContact[jsonMsg?.pid]?.firstName +
                  " " +
                  formattedContact[jsonMsg?.pid]?.lastName
                : formattedParticipantsOfActiveRoom[jsonMsg?.pid]?.phone}
            </span>
          </div>
        );
      } else if (
        message?.type === "changedDescription" ||
        message?.type === "changedName" ||
        message?.type === "changedPicture" ||
        message?.type === "leftRoom" ||
        message?.type === "chatDisappear"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message
                    ?.replace(
                      "changed the name of group",
                      t("name-change-group")
                    )
                    .replace(
                      "changed the description of group",
                      t("description-change-group")
                    )
                    .replace(
                      "changed the picture of the group",
                      t("picture-change-group")
                    )
                    .replace(
                      "turned on disappearing messages. All new messages",
                      t("chat-disapper-1")
                    )
                    .replace(
                      "turned off disappearing messages",
                      t("turn-off-disapper")
                    )
                    .replace("left the group", t("left-group"))
                    .replace(
                      "changed the name of broadcast",
                      t("broad-name-change")
                    )
                    .replace(
                      "changed the description of broadcast",
                      t("broad-desc-change")
                    )
                    .replace(
                      "changed the picture of the broadcast",
                      t("broad-image-change")
                    )
                    .replace(/\s+/g, " ")
                    .trim()
                    .slice(0, 22) + "..."
                : message?.message
                    .replace(
                      "changed the name of group",
                      t("name-change-group")
                    )
                    .replace(
                      "changed the description of group",
                      t("description-change-group")
                    )
                    .replace(
                      "changed the picture of the group",
                      t("picture-change-group")
                    )
                    .replace(
                      "turned on disappearing messages. All new messages",
                      t("chat-disapper-1")
                    )
                    .replace(
                      "turned off disappearing messages",
                      t("turn-off-disapper")
                    )
                    .replace("left the group", t("left-group"))
                    .replace(/\s+/g, " ")
                    .trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">{`${t("shared_file")} ${message?.type
              .replace("IMAGE", t("image"))
              .replace("VIDEO", t("video"))
              .replace("AUDIO", t("audio"))
              .replace("DOCUMENT", t("document"))} `}</span>
          </div>
        );
      } else if (message.type === "APPLICATION") {
        lastMessage = (
          <div>
            {message.message ? (
              <div>{message?.message}</div>
            ) : (
              <>
                <span className="capitalize">
                  {message?.sender === userData?._id
                    ? t("you") + ": "
                    : senderContactDetail
                    ? senderContactDetail?.firstName +
                      " " +
                      senderContactDetail?.lastName
                    : sender?.phone}
                </span>
                <span className="ml-1">{t("shared_file")}</span>
              </>
            )}
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="">{`${t("shared_contact")}`}</span>
          </div>
        );
      } else if (message?.type === "text" || message?.type === "Link") {
        const data = message?.message
          .replace("You deleted this message", t("you-message-deleted"))
          .replace("This message was deleted", t("this-message-deleted"))
          .replace("You are assigned", t("you_are_assigned"))
          .replace("please accept the invitation to join", t("accept-invite"))
          .replace("to start execution", t("start-execution"))
          .replace("by", t("by"));

        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (
            match: any,
            p1: any,
            p2: any,
            p3: any,
            p4: any,
            p5: any,
            p6: any,
            p7: any,
            p8: any
          ) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return `<div style="">` + p5 + "</div>";
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find(
                (el) => el?.user_id == p8
              );
              const self = userData?._id == participant?.user_id;
              const formatPart = formattedContact?.[participant?.user_id];
              const mentionedName = self
                ? `${t("you")} `
                : formatPart?.firstName
                ? formatPart?.firstName + " " + formatPart?.lastName
                : participant
                ? participant?.phone
                : p7;
              return (
                '<span style="color: green;">' + "@" + mentionedName + "</span>"
              );
            }

            return match;
          }
        );
        lastMessage = (
          <div>
            <span>{parse(formattedText)}</span>
          </div>
        );
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className=""> {t("started")} </span>
            <span>
              {message?.type === "audioCall"
                ? t("audio_call")
                : t("video_call")}
            </span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        );
      }
    } else {
      if (message.type === "invited") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";
        lastMessage =
          message?.sender === userData?._id
            ? t("accept-1") +
              " " +
              first +
              " " +
              last +
              " " +
              t("accept-2") +
              " " +
              data?.orgName +
              " "
            : first + " " + last + " " + t("accept-3") + " " + data?.orgName;
      }
      if (message.type === "declined") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";
        //data.msg.slice(0,28)+'...'
        lastMessage =
          message?.sender === userData?._id
            ? t("you") +
              " " +
              t("msg-1") +
              " " +
              data?.orgName +
              " " +
              t("msg-2") +
              "..."
            : first +
              " " +
              last +
              " " +
              t("msg-5") +
              " " +
              data?.orgName +
              " " +
              t("msg-2") +
              "... ";
      }
      if (message.type === "taskAssigned") {
        const data =
          message?.message !== undefined ? JSON.parse(message?.message) : "";
        //data.msg.slice(0,28)+'...'
        lastMessage =
          message?.sender === userData?._id
            ? t("task-assigned") +
              " " +
              data?.scenarioName +
              " " +
              t("to") +
              " " +
              first +
              " " +
              last
            : first +
              " " +
              last +
              " " +
              t("assigned-you") +
              " " +
              data?.scenarioName;
      } else if (message?.type === "text" || message?.type === "Link") {
        const data =
          /* message?.message?.length > 30
            ? message?.message?.replace("You deleted this message",t("you-message-deleted")).replace("This message was deleted",t("this-message-deleted")).replace('You are assigned',t('you_are_assigned')).replace("please accept the invitation to join",t('accept-invite')).replace('to start execution',t('start-execution')).replace('by',t('by')).slice(0, 30) + "..."
            : */
          message?.message
            .replace("You deleted this message", t("you-message-deleted"))
            .replace("This message was deleted", t("this-message-deleted"))
            .replace("You are assigned", t("you_are_assigned"))
            .replace("please accept the invitation to join", t("accept-invite"))
            .replace("to start execution", t("start-execution"))
            .replace("by", t("by"));
        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (
            match: any,
            p1: any,
            p2: any,
            p3: any,
            p4: any,
            p5: any,
            p6: any,
            p7: any,
            p8: any
          ) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return `<div style="">` + p5 + "</div>";
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find(
                (el) => el?.user_id == p8
              );
              const self = userData?._id == participant?.user_id;
              const formatPart = formattedContact?.[participant?.user_id];
              const mentionedName = self
                ? `${t("you")} `
                : formatPart?.firstName
                ? formatPart?.firstName + " " + formatPart?.lastName
                : participant
                ? participant?.phone
                : p7;
              return (
                '<span style="color: green;">' + "@" + mentionedName + "</span>"
              );
            }
            return match;
          }
        );
        lastMessage = (
          <div>
            <span>{parse(formattedText)}</span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        );
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "TEXT" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="ml-1">{`${t("shared_file")} ${message?.type
              .replace("IMAGE", t("image"))
              .replace("VIDEO", t("video"))
              .replace("AUDIO", t("audio"))
              .replace("DOCUMENT", t("document"))} `}</span>
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="">{t("shared_contact")}</span>
          </div>
        );
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                : sender?.phone}
            </span>
            <span className="mx-1"> {t("started")} </span>
            <span>
              {message?.type === "audioCall"
                ? t("audio_call")
                : t("video_call")}
            </span>
          </div>
        );
      }
    }

    return lastMessage;
  };

  return loader ? (
    <div className="flex justify-center mx-auto my-auto">
      <CircularProgress />
    </div>
  ) : !authToken ? (
    <></>
  ) : (
    <div className=" relative h-full w-full" id="parent-div">
      <Drawer
        anchor={"left"}
        open={showOrganization}
        onClose={() => setShowOrganization(false)}
      >
        <Organizations setShowOrganization={setShowOrganization} />
      </Drawer>

      <Drawer
        anchor={"left"}
        open={folderDrawerShow}
        onClose={() => setFolderDrawerShow(false)}
      >
        <FolderDrawer setFolderDrawerShow={setFolderDrawerShow} />
      </Drawer>
      <Drawer
        anchor={"left"}
        open={headingShow}
        onClose={() => setHeadingShow(false)}
      >
        <Headings setHeadingShow={setHeadingShow} />
      </Drawer>
      <Drawer
        anchor={"left"}
        open={showSettingModal}
        onClose={() => setShowSettingsModal(false)}
      >
        <SettingDrawer
          handleLogout={handleLogout}
          setShowOrganization={setShowOrganization}
          setShowSettingsModal={setShowSettingsModal}
        />
      </Drawer>

      <Drawer
        anchor={"left"}
        open={createGroupShow}
        onClose={() => setCreateGroupShow(false)}
      >
        <CreateRoom type={type} setShow={setCreateGroupShow} />
      </Drawer>

      <Drawer
        anchor={"left"}
        open={profilePageShow}
        onClose={() => setProfilePageShow(false)}
      >
        <ProfileDrawer setProfilePageShow={setProfilePageShow} />
      </Drawer>

      <Popover
        open={openMenu}
        id={menuId}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box className="w-48">
          <div className="p-4 ">
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                setShowSettingsModal(true);
                setMenuAnchorEl(null);
              }}
            >
              <SettingsIcon
                fontSize="small"
                style={{ color: "#33ccff" }}
                className="mr-1"
              />{" "}
              {t("settings")}
            </div>
            <hr className="my-2" />
            <div className="cursor-pointer items-center" onClick={handleLogout}>
              <ExitToAppIcon
                fontSize="small"
                style={{ color: "#33ccff" }}
                className="mr-1"
              />{" "}
              {t("logout")}
            </div>
          </div>
        </Box>
      </Popover>

      {!isRoomsLoading && !isContactsLoading ? (
        <div className="h-full">
          {headerTab == 4 || showCalendarPage ? (
            <CalendarApp setTabValue={setHeaderTab} setTaskTab={setTabButton} />
          ) : (
            // here added h-full
            <div className="flex h-full">
              <div className="w-[45%] bg-white dark:bg-black">
                <div className=" border-r-2 resWidth">
                  <div className="bg-[#F3F9FC] px-2 py-2 border-b dark:bg-black">
                    <div className="flex justify-between pt-1">
                      <div className="flex items-center">
                        <div
                          className="h-[46px] w-[46px] cursor-pointer"
                          onClick={() => setProfilePageShow(true)}
                        >
                          {userData?.profile_img ? (
                            <img
                              src={DefaultImageUrl + userData.profile_img}
                              className="h-full w-full rounded-full"
                              alt="profile"
                            />
                          ) : (
                            <Avatar sx={{ width: "45px", height: "45px" }} />
                          )}
                        </div>
                        <div className="pl-3">
                          <div className="lightBlack dark:text-white">
                            <span>{userData?.firstName}</span>
                            <span className="ml-1">{userData?.lastName}</span>
                          </div>
                          <div className="textGray text-sm  dark:text-white">
                            {userData?.phone}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-2">
                        <div>
                          <FadeMenu
                            language={language}
                            setLanguage={setLanguage}
                          />
                        </div>
                        <Tooltip title={t("headings")}>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setHeadingShow(true);
                            }}
                          >
                            <BsReverseLayoutTextWindowReverse />
                          </div>
                        </Tooltip>
                        <Tooltip title={disable ? "" : t("create_task")}>
                          <div
                            onClick={() => {
                              // setDisable(true);
                              if (!disable) {
                                window.open(
                                  taskFlowUrl + `?token=${authToken}`
                                );
                              }
                            }}
                            className={`border  rounded flex  justify-center px-1 py-1  text-center items-center h-fit ${
                              disable
                                ? "bg-gray-300 cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            <FaTasks className="mx-2" />
                          </div>
                        </Tooltip>

                        <Tooltip title={t("invites")}>
                          <div
                            className="cursor-pointer mr-2 relative"
                            onClick={() => setShowOrganization(true)}
                          >
                            <Badge
                              badgeContent={invites?.length}
                              color="primary"
                            >
                              <FaEnvelopeOpenText size={20} />
                            </Badge>
                          </div>
                        </Tooltip>
                        <Tooltip title={t("create_folder")}>
                          <div
                            className="cursor-pointer h-[18px] w-[18px]"
                            onClick={() => setFolderDrawerShow(true)}
                          >
                            <img
                              src={FolderIcon}
                              alt="folder"
                              className="h-[18px] w-[18px]"
                            />
                          </div>
                        </Tooltip>
                        <div
                          className="cursor-pointer"
                          onClick={() => setShowSettingsModal(true)}
                        >
                          <MoreVertIcon />
                        </div>
                      </div>
                    </div>

                    {headerTab !== 3 && (
                      <div className="flex gap-2 items-center mt-4">
                        <div
                          className="w-full rounded flex items-center px-2 bg-white"
                          style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
                        >
                          <SearchIcon sx={{ color: "#333333" }} />
                          <input
                            type="text"
                            placeholder={t("searchPlaceholder")}
                            className="w-full py-2 px-2 focus:outline-none text-sm"
                            value={searchQuery}
                            onChange={handleSearchRooms}
                          />
                        </div>

                        <Tooltip title={t("mydatabase")}>
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowDatabase(true)}
                          >
                            <BsDatabase size={20} />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <OrganizationListView
                    headerTab={headerTab}
                    setMenuAnchorElOrg={setMenuAnchorElOrg}
                    menuAnchorElOrg={menuAnchorElOrg}
                    openOrg={openOrg}
                    setCurrentOrg={setCurrentOrg}
                  />
                  {searchQuery === "" ? (
                    <>
                      <div
                        onClick={() => {
                          setMenuAnchorElOrg(false);
                        }}
                        className="flex justify-between px-5 py-2 border-b"
                        style={{ boxShadow: "5px 2px 23px #e9e9e9" }}
                      >
                        <div
                          className="flex flex-col items-center cursor-pointer"
                          onClick={() => {
                            setHeaderTab(1);
                            dispatch(setActiveRoomData({}));
                            dispatch(setActiveRoomChats([]));
                            dispatch(setIsRoomLoading(false));
                          }}
                        >
                          <div
                            className={
                              headerTab === 1
                                ? "primary"
                                : "lightBlack dark:text-white"
                            }
                          >
                            <FormatListBulletedOutlinedIcon
                              sx={{ fontSize: "23px" }}
                            />
                          </div>
                          <div
                            className={
                              headerTab === 1
                                ? "text-sm pt-[2px] primary"
                                : "text-sm pt-[2px] textGray dark:text-white"
                            }
                          >
                            {t("tasks")}
                          </div>
                        </div>

                        <div
                          className="flex flex-col items-center cursor-pointer"
                          onClick={handleClickChatTab}
                        >
                          <div
                            className={
                              headerTab === 2
                                ? "primary"
                                : "lightBlack dark:text-white"
                            }
                          >
                            <ChatOutlinedIcon sx={{ fontSize: "23px" }} />
                          </div>
                          <div
                            className={
                              headerTab === 2
                                ? "text-sm pt-[2px] primary"
                                : "text-sm pt-[2px] textGray dark:text-white"
                            }
                          >
                            {t("chats")}
                          </div>
                        </div>

                        <div
                          className="flex flex-col items-center cursor-pointer"
                          onClick={() => {
                            setHeaderTab(3);
                            dispatch(setIsRoomLoading(false));
                            dispatch(setActiveRoomData({}));
                          }}
                        >
                          <div
                            className={
                              headerTab === 3
                                ? "primary"
                                : "lightBlack dark:text-white"
                            }
                          >
                            <CallOutlinedIcon sx={{ fontSize: "23px" }} />
                          </div>
                          <div
                            className={
                              headerTab === 3
                                ? "text-sm pt-[2px] primary"
                                : "text-sm pt-[2px] textGray dark:text-white"
                            }
                          >
                            {t("calls")}
                          </div>
                        </div>

                        <div
                          className="flex flex-col items-center cursor-pointer"
                          onClick={() => {
                            setType("group");
                            setShowGroupNavigation(true);
                          }}
                        >
                          <div className={"lightBlack dark:text-white"}>
                            <GroupAddIcon sx={{ fontSize: "23px" }} />
                          </div>
                          <div className="text-sm pt-[2px] textGray">
                            {t("create_group")}
                          </div>
                        </div>
                        <div
                          className="flex flex-col items-center cursor-pointer"
                          onClick={() => {
                            setHeaderTab(4);
                            setShowReportPage(false);
                            dispatch(setActiveRoomData({}));
                            dispatch(setShowTaskPage(false));
                            dispatch(setIsRoomLoading(false));
                            // setCreateGroupShow(true); setType('broadcast')
                          }}
                        >
                          <div className={"lightBlack dark:text-white"}>
                            <CalendarMonthOutlinedIcon
                              sx={{ fontSize: "23px" }}
                            />
                          </div>
                          <div className="text-sm pt-[2px] textGray">
                            {t("calendar")}
                          </div>
                        </div>
                      </div>

                      {isCallMinimized && (
                        <div className="w-ful bg-[#f3f9fc] p-5">
                          <div className="flex items-center justify-between">
                            <div className="w-[50px] min-w-[50px] h-[50px] rounded-full">
                              {activeCallData?.data?.roomType ===
                                "contact_group" ||
                              activeCallData?.data?.roomType === "contact" ? (
                                <img
                                  src={contactCallRoomPicture(
                                    activeCallData?.data?.callParticipants
                                  )}
                                  alt=""
                                  className="h-full w-full rounded-full"
                                />
                              ) : onGoingCallRoomDetail?.type === "group" ? (
                                <img
                                  src={
                                    DefaultImageUrl +
                                    onGoingCallRoomDetail?.profile_img
                                  }
                                  alt=""
                                  className="h-full w-full rounded-full"
                                />
                              ) : (
                                <img
                                  src={
                                    DefaultImageUrl +
                                    onGoingCallIndividual?.profile_img
                                  }
                                  alt=""
                                  className="h-full w-full rounded-full"
                                />
                              )}
                            </div>

                            <div className="w-full px-4">
                              <div className="flex  gap-2">
                                {contactCallRoomName(
                                  activeCallData?.data?.callParticipants
                                )}
                                <div> {time}</div>
                              </div>
                              <div className="textGray text-sm">
                                {t("ongoing_call")}
                              </div>
                            </div>

                            <div className="flex gap-2">
                              <div
                                className="text-white bg-[#33ccff] flex justify-center items-center
                              h-[30px] w-[30px] min-w-[30px] rounded-full cursor-pointer"
                                onClick={handleMaximize}
                              >
                                <FiMaximize2 />
                              </div>

                              <button
                                className="text-white bg-red-500 flex justify-center items-center
                              h-[30px] w-[30px] min-w-[30px] rounded-full cursor-pointer disabled:opacity-50"
                                disabled={disableLeftButton ? true : false}
                                onClick={handleLaveCall}
                              >
                                <CallEndIcon sx={{ fontSize: 18 }} />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {noInternetConnection && (
                        <div className="w-ful bg-[antiquewhite] p-5 flex gap-3">
                          <div className="p-2 bg-white w-fit h-fit rounded-full">
                            <SignalWifiStatusbarConnectedNoInternet4OutlinedIcon
                              sx={{ color: "#eed973" }}
                            />
                          </div>
                          <div>
                            <div>{t("computer_not_connected")}</div>
                            <div className="text-sm">
                              {t("computer_connection_activeOrnot")}
                            </div>
                          </div>
                        </div>
                      )}

                      {headerTab === 1 && (
                        <div className="text-[#000000b8]">
                          <Task
                            tabButton={tabButton}
                            setTabButton={setTabButton}
                            setShowReportPage={setShowReportPage}
                            setShowChatPage={setShowChatPage}
                          />
                        </div>
                      )}

                      {(headerTab === 2 || headerTab == 4) && (
                        <div>
                          <ChatTab setHeaderTab={setHeaderTab} />
                        </div>
                      )}

                      {headerTab === 3 && <Call />}
                    </>
                  ) : (
                    <div
                      className="px-5 py-2 h-[82vh] overflow-y-scroll"
                      onClick={() => {
                        setMenuAnchorElOrg(false);
                      }}
                    >
                      {!isRoomCreating ? (
                        searchData?.length > 0 ? (
                          searchData?.map((item: any, index: number) => {
                            const individualRoom =
                              item?.type !== "group" &&
                              item?.type !== "broadcast" &&
                              item?.participants?.find(
                                (one: any) => one?.user_id !== userData?._id
                              );
                            const userInfo = item?.participants?.find(
                              (one: any) => one?.user_id !== userData?._id
                            );

                            const userContactInfo = userContacts?.find(
                              (one: any) =>
                                one?.userId?._id === userInfo?.user_id
                            );

                            const userContactDetail =
                              formattedContact[individualRoom?.user_id];

                            return (
                              <div
                                className="my-6 flex gap-2 cursor-pointer"
                                key={index}
                                onClick={() => handleRoomClick(item)}
                              >
                                {item?.userId ? (
                                  <div className="flex">
                                    <div className="h-[45px] w-[45px]">
                                      {item?.userId?.profile_img !== "" ? (
                                        <img
                                          src={
                                            DefaultImageUrl +
                                            item?.userId.profile_img
                                          }
                                          alt=""
                                          className="h-[45px] w-[45px] rounded-full"
                                        />
                                      ) : (
                                        <Avatar
                                          sx={{ width: 45, height: 45 }}
                                        />
                                      )}
                                    </div>
                                    <div className=" pl-3">
                                      {item?.firstName !== "" ? (
                                        <div>
                                          {item?.firstName +
                                            " " +
                                            item?.lastName}
                                        </div>
                                      ) : (
                                        <div>{item?.phone}</div>
                                      )}
                                      <div className="textGray text-xs">
                                        {item?.phone}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="flex">
                                      <div className="h-[45px] w-[45px]">
                                        {item?.type === "group" ||
                                        item?.type === "broadcast" ? (
                                          <img
                                            src={
                                              DefaultImageUrl +
                                              item?.profile_img
                                            }
                                            alt=""
                                            className="h-[45px] w-[45px] rounded-full"
                                          />
                                        ) : individualRoom?.profile_img !==
                                          "" ? (
                                          <img
                                            src={
                                              DefaultImageUrl +
                                              individualRoom?.profile_img
                                            }
                                            alt=""
                                            className="h-[45px] w-[45px] rounded-full"
                                          />
                                        ) : (
                                          <Avatar
                                            sx={{ width: 45, height: 45 }}
                                          />
                                        )}
                                      </div>
                                      <div className="block pl-3">
                                        <div>
                                          {item?.type === "group" ||
                                          item?.type === "broadcast"
                                            ? item?.name
                                            : userContactDetail &&
                                              userContactDetail?.firstName !==
                                                ""
                                            ? userContactDetail?.firstName +
                                              " " +
                                              userContactDetail?.lastName
                                            : item?.name}
                                        </div>
                                        <div className="textGray text-xs line-clamp-2 max-w-[400px]">
                                          {renderLastMessage(
                                            item,
                                            userInfo,
                                            userContactInfo?.firstName ??
                                              userInfo?.phone,
                                            userContactInfo?.lastName ?? ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="textGray text-center">
                            {t("no_results_found")}
                          </div>
                        )
                      ) : (
                        <div className="w-full flex justify-center">
                          <ReactLoading
                            type="spin"
                            width={30}
                            height={30}
                            color="#33ccff"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {showTaskPage ? (
                <div className="hidden bg-white lg:block w-full">
                  <TaskPage tabButton={tabButton} setTabButton={setTabButton} />
                </div>
              ) : (
                !showReportPage && (
                  <div
                    className="hidden bg-white lg:block w-full"
                    onClick={() => {
                      setMenuAnchorElOrg(false);
                    }}
                  >
                    <ChatPage tabValue={headerTab} setTabValue={setHeaderTab} />
                  </div>
                )
              )}

              {showReportPage && (
                <div
                  className="hidden bg-white lg:block w-full"
                  onClick={() => {
                    setMenuAnchorElOrg(false);
                  }}
                >
                  <ReportPage />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className=" h-full bg-white flex justify-center items-center"
          onClick={() => {
            setMenuAnchorElOrg(false);
          }}
        >
          <div>
            <div className="flex justify-center">
              <img
                alt="loading"
                src="https://fe-pr-preview-pb2r7hxayq-ez.a.run.app/logo-loading.svg"
                className="h-36 w-36"
              />
            </div>
            <div className="flex text-xl mt-2 text-slate-700 px-4 mx-7 justify-center">
              {t("loading_data")}
            </div>
          </div>
        </div>
      )}
      <div>
        <SettingHome open={openModel} onClose={() => setOpenModel(false)} />
      </div>

      {showGroupNavigation && (
        <GroupNavigationModal
          show={showGroupNavigation}
          setShow={setShowGroupNavigation}
          setCreateGroupShow={setCreateGroupShow}
          setType={setType}
          type={type}
          t={t}
        />
      )}

      {showDatabase && (
        <DatabaseDrawer show={showDatabase} setShow={setShowDatabase} />
      )}
    </div>
  );
}

export default Home;
