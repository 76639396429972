import dayjs from "dayjs";
import { IViewRecordProps, TField } from "./databasetypes";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { useTranslation } from "react-i18next";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import getAttachmentName from "../../../utils/getAttachmentName";
import { Chip, Tooltip } from "@mui/material";
import { DefaultImageUrl } from "../../../Constant/Constant";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useState } from "react";
import FilePreview from "./FilePreview";
import DeleteRecord from "./DeleteRecord";

const ViewRecord = ({
  data,
  recordId,
  recordTitle,
  setIsRefresh,
  closeRecordModal,
  setModalMode,
}: IViewRecordProps) => {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState({ file: null, show: false });
  const [deleteRecord, setDeleteRecord] = useState({ data: null, show: false });

  const showFileName = (name: string): string => {
    if (name?.length > 30) {
      return name?.slice(0, 20) + "..." + name?.slice(-4);
    }
    return name
  };

  const handleViewAttachment = (file: any) => {
    const types = ["AUDIO", "VIDEO", "PHOTO", "IMAGE"];
    if (types.includes(file?.type)) {
      setFilePreview({ file, show: true });
    } else {
      const source = DefaultImageUrl + file?.url;
      window.open(source);
    }
  };

  const handleDeleteRecord = () => {
    setDeleteRecord({
      data: { _id: recordId, title: recordTitle },
      show: true,
    });
  };

  return (
    <div>
      <div className="grid gap-4">
        {data?.map((record: TField, index: number) => {
          return (
            !record?.required && (
              <div key={index} className="bg-white rounded-md p-4">
                <div className="font-semibold capitalize text-gray-800">
                  {record?.label} :
                </div>

                {(record?.type == "text" || record?.type == "number") && (
                  <div className="mt-2 text-gray-700">{record?.value}</div>
                )}

                {record?.type == "date" && (
                  <div className="mt-2 flex gap-2 items-center">
                    <div className="flex gap-2 items-center">
                      <WatchLaterOutlinedIcon className="text-gray-600" />
                      <div>
                        {dayjs(record?.value).format("DD MMM YYYY hh:mm A")}
                      </div>
                    </div>

                    {record?.on_calender && (
                      <div className="flex gap-2 items-center">
                        |{" "}
                        <CalendarMonthOutlinedIcon className="text-gray-600" />
                        <div>{t("addedoncalendar")}</div>
                      </div>
                    )}

                    {record?.remind_at?.Count && (
                      <div className="flex gap-2 items-center">
                        |{" "}
                        <NotificationsActiveOutlinedIcon className="text-gray-600" />
                        <div>{t("reminderbefore")}</div>
                        <div>
                          <span>{record?.remind_at?.Count}</span>
                          <span className="ml-1">
                            {t(record?.remind_at?.Unit?.toLowerCase())}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {record?.type == "attachment" && (
                  <div className="mt-2">
                    {record?.attachments?.length > 0 ? (
                      <div className="flex flex-wrap gap-3">
                        {record?.attachments?.map((file: any, key: number) => {
                          return (
                            <Tooltip title={getAttachmentName(file?.url)}>
                              <Chip
                                key={key}
                                onClick={() => handleViewAttachment(file)}
                                label={
                                  <div>
                                    <AttachmentIcon className="text-gray-400" />
                                    <span className="ml-1">
                                      {showFileName(
                                        getAttachmentName(file?.url)
                                      )}
                                    </span>
                                  </div>
                                }
                                variant="outlined"
                              />
                            </Tooltip>
                          );
                        })}
                      </div>
                    ) : (
                      <div>{t("noattachments")} !</div>
                    )}
                  </div>
                )}
              </div>
            )
          );
        })}
      </div>

      <div className="flex gap-4 items-center justify-end mt-3">
        <button
          type="button"
          className="text-white px-6 py-2 rounded-md text-sm bg-[#33CCFF]"
          onClick={() => setModalMode("edit")}
        >
          {t("edit")}
        </button>
        <button
          type="button"
          className="text-white px-6 py-2 rounded-md text-sm bg-red-600"
          onClick={handleDeleteRecord}
        >
          {t("delete")}
        </button>
      </div>

      {filePreview?.show && (
        <FilePreview
          filePreview={filePreview}
          setFilePreview={setFilePreview}
        />
      )}

      {deleteRecord.show && (
        <DeleteRecord
          deleteRecord={deleteRecord}
          setIsRefresh={setIsRefresh}
          closeRecordModal={closeRecordModal}
          setDeleteRecord={setDeleteRecord}
        />
      )}
    </div>
  );
};

export default ViewRecord;
