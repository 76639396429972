import { Avatar, CircularProgress, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CALENDER_SOCKET_EVENTS,
  DefaultImageUrl,
} from "../../Constant/Constant";
import settingHeaderImg from "../../Assets/Images/settingHeader.png";
import ForwardIcon from "../../Assets/Images/ForwardIcon.png";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import SearchIcon from "@mui/icons-material/Search";
import chatImg from "../../Assets/Images/FlatIconImages/chat.png";
import {
  appendOldChats,
  appendPreviousChats,
  setActiveCallData,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomData,
  setCurrentPage,
  setFocusedData,
  setInitiatedCallData,
  setIsCallMinimized,
  setMemberAddedInCall,
  setTotalPage,
  updateCallStatus,
  updateCreateCall,
} from "../../Redux/ChatSlice";
import { CALL_STATUS, CREATE_CALL } from "../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import React, { useEffect, useMemo, useState, useTransition } from "react";
import parse from "html-react-parser";
import CancelIcon from "@mui/icons-material/Cancel";
import PinIcon from "../../Assets/Images/PinIcon.png";
import moment from "moment";
import { ParticipantsDataTypes, PinnedChatsDataTypes } from "../../Types/Types";
import { emit, socketListen } from "../../Socket";
import Toast from "../../coreComponent/Toast";
import RoomDetailDrawer from "../RightTab/RightRoomDrawer/RoomDetailDrawer";
import ShareContactModal from "./ShareContactModal";
import { useTranslation } from "react-i18next";
import FadeMenu from "../../coreComponent/FadeMenu";
import { getSession } from "../../utils/session";
import { authCllient, refreshTokenCllient } from "../../Graphql/authClient";
import {
  GET_APPOINTMENT_BY_ROOM_ID,
  GET_CHATS_BY_INDEX_ID,
  GET_SCHEDULE_BY_ROOM_ID,
} from "../../Graphql/Queries";
import CalendarApp from "../../Components/Calendar/CalendarApp";
export default function ChatHeadContainer({
  tabValue,
  setTabValue,
  showMediaPreview,
  setShowMediaPreview,
  selectedMedia,
  setSelectedMedia,
  mediaPreviewData,
  setMediaPreviewData,
}) {
  const {
    pinnedChats,
    onlineRooms,
    activeRoomChats,
    activeChatsLimit,
    activeRoomData,
    userOnlineStatus,
    isParticipantsInCall,
    callisRunning,
    joinedFromOtherDevice,
    activeCallData,
    onGoingCallData,
  } = useSelector((state: any) => state.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const isRoomBlocked = userData?.blockedRooms?.some(
    (item: any) => item?.room_Id === activeRoomData?._id
  );
  const [shareContactModalShow, setShareContactModal] = useState<any>(false);
  const [roomRightDrawer, setRoomRightDrawer] = useState<boolean>(false);
  const [queue, setQueue] = useState<any>("");
  const currentParticipantsLength = activeRoomData?.participants?.filter(
    (item: ParticipantsDataTypes) => item?.left_at === 0
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [toastShow, setToastShow] = useState(false);
  const [roomType, setRoomType] = useState<any>("");
  const [searchTabShow, setSearchTabShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const onGoingCallRoomData = onGoingCallData.find(
    (el: any) => el.roomId?._id === activeRoomData?._id
  );
  const userInfo =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        )
      : activeRoomData?.participants?.length === 1
      ? activeRoomData?.participants[0]
      : null;
  const userInfoForMe =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
          (one: any) => one?.user_id === userData?._id
        )
      : activeRoomData?.participants?.length === 1
      ? activeRoomData?.participants[0]
      : null;
  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userInfo?.user_id
    );
  const isRoomLeft = userInfoForMe?.left_at !== 0;

  const isUserBlockedYou = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );
  const [backgroundImgLoading, setBackgroundImgLoading] = useState();
  const [createCall] = useMutation(CREATE_CALL);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const showLastSeen: any = () => {
    let time: any;

    if (
      activeRoomData?.type !== "group" &&
      !onlineRooms.includes(activeRoomData?._id)
    ) {
      const timeStamp = moment(userOnlineStatus?.msg?.time).format("HH:mm ");
      const gettingDate = moment(userOnlineStatus?.msg?.time).format(
        "DD.MM.YYYY"
      );

      const todayDate = moment(new Date()).format("DD.MM.YYYY");

      const yesterdayDate = moment(new Date())
        .subtract(1, "day")
        .format("DD.MM.YYYY");

      if (gettingDate === todayDate) {
        time = `${t("lastSeen")} ` + timeStamp;
      } else if (gettingDate === yesterdayDate) {
        time = t("last_seen_yesterday");
      } else {
        time = gettingDate;
      }
    } else {
      time = t("online");
    }

    return (
      <span>
        <span>
          <span className="ml-1">{time}</span>
        </span>
      </span>
    );
  };

  const joinOnGoingCall = () => {
    setLoad(true);
    if (
      activeCallData?.data?.channelName === onGoingCallRoomData?.channelName
    ) {
      dispatch(setIsCallMinimized(false));
      dispatch(updateCallStatus(true));
    } else {
      if (onGoingCallRoomData?.callId || onGoingCallRoomData?._id) {
        changeCallStatus({
          variables: {
            changeCallStatus: {
              callId: onGoingCallRoomData?.callId ?? onGoingCallRoomData?._id,
              status: "accepted",
              userId: userData._id,
            },
          },
        })
          .then((res) => {
            if (res?.data) {
              const data = { data: onGoingCallRoomData };
              dispatch(setActiveCallData(data));
              dispatch(setIsCallMinimized(false));
              dispatch(updateCallStatus(true));
            }
          })
          .finally(() => {
            setLoad(false);
          });
      }
    }
  };
  // useMemo(() => {
  //     if (activeRoomData?._id) {
  //         emit("GetRoomById", { roomId: activeRoomData?._id });
  //         socketListen("message", (data: any) => {
  //             if (data.type === "GetRoomById") {
  //                 if (data?.msg?.room?._id === activeRoomData?._id) {
  //                     dispatch(setActiveRoomData({ ...data?.msg?.room, topics: data?.msg?.topics, contactData: data?.msg?.contactData }))
  //                 }
  //             }
  //         });
  //     }
  // }, [activeRoomData?._id])

  useEffect(() => {
    if (queue !== "") {
      const isExist = activeRoomChats?.find(
        (item: any) => item?.index === queue
      );
      if (isExist) {
        /* document
                    .getElementById(`message-index-${queue}`)
                    .scrollIntoView({ block: "center", behavior: "smooth" });
                setQueue(null); */
      }
    }
  }, [activeRoomChats]);
  const createCalls = (type: string) => {
    setDisabledCallButton(true);
    dispatch(updateCreateCall(true));
    let participants = [
      String(
        activeRoomData?.participants.filter(
          (item: any) => item.user_id !== userData._id
        )[0].user_id
      ),
    ];
    const member = activeRoomData?.participants
      .filter((item: any) => item.user_id !== userData._id)
      .map(({ user_id }: any) => user_id);
    if (activeRoomData?.type === "group") {
      dispatch(setMemberAddedInCall(member));
    } else {
      dispatch(setMemberAddedInCall(participants));
    }

    createCall({
      variables: {
        input: {
          origin: String(userData._id),
          participants: participants,
          channelName: String(new Date().getTime()),
          type: type,
          roomId: activeRoomData._id,
          roomType: activeRoomData.type,
        },
      },
    })
      .then((res) => {
        handleChangeCallStatus(res?.data?.createNewCall?.call?._id);
        dispatch(setInitiatedCallData(res.data?.createNewCall?.call));
      })
      .catch((error) => console.log("error at create call", error))
      .finally(() => setDisabledCallButton(false));
  };

  const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirectOnMessage = (message: PinnedChatsDataTypes) => {
    if (message?.__v) {
      const totalChats = activeRoomData?.totalChats;
      const skip = totalChats - message?.__v - 25;
      const value = skip < 0 ? 0 : skip;

      if (
        message?.__v < activeRoomChats[activeRoomChats?.length - 1]?.index &&
        message?.__v > activeRoomChats[0]?.index
      ) {
        dispatch(setFocusedData({ status: true, message_id: message?.__v }));
        document
          .getElementById(`message-index-${message?.__v}`)
          .scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.backgroundColor = "#87d3e2f5";
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.paddingBottom = "10px";
        document.getElementById(
          `message-index-${message?.__v}`
        ).style.transition = "background-color " + 2 + "s ease-in-out";

        setTimeout(() => {
          document.getElementById(
            `message-index-${message?.__v}`
          ).style.backgroundColor = "";
          document.getElementById(
            `message-index-${message?.__v}`
          ).style.paddingBottom = "0px";
          document.getElementById(
            `message-index-${message?.__v}`
          ).style.transition = "none";
        }, 1600);
      } else {
        const skip =
          activeChatsLimit * (Math.floor(message?.__v / activeChatsLimit) + 1);
        dispatch(setActiveChatSkip(skip));
        dispatch(
          setCurrentPage(Math.floor(message?.__v / activeChatsLimit) + 1)
        );
        // dispatch(setTotalPage(Math.ceil(activeRoomData?.totalChats / activeChatsLimit)))
        const { token } = getSession();
        refreshTokenCllient(token)
          .query({
            query: GET_CHATS_BY_INDEX_ID,
            variables: {
              input: {
                roomId: activeRoomData?._id, // roomId
                total: activeRoomData?.totalChats, // total chats
                index: message?.__v,
                limit: activeChatsLimit, // custom limit
              },
            },
          })
          .then((res) => {
            dispatch(appendOldChats([]));
            dispatch(setActiveRoomChats([]));
            if (res.data.getChatsByIndex.length > 0) {
              setRoomRightDrawer(false);
              dispatch(setActiveRoomChats(res?.data?.getChatsByIndex));
            }
            setTimeout(() => {
              if (
                message.__v !== null &&
                document.getElementById(`message-index-${message?.__v}`)
              ) {
                /*   document
                                    .getElementById(`message-index-${message?.__v}`)
                                    .scrollIntoView({ block: "center", behavior: "auto" }) */
                document
                  .getElementById(`message-index-${message?.__v}`)
                  .scrollIntoView({ block: "center", behavior: "smooth" });
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.backgroundColor = "#e0e0e0";
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.paddingBottom = "10px";
                document.getElementById(
                  `message-index-${message?.__v}`
                ).style.transition = "background-color " + 2 + "s ease-in-out";

                setTimeout(() => {
                  document.getElementById(
                    `message-index-${message?.__v}`
                  ).style.backgroundColor = "";
                  document.getElementById(
                    `message-index-${message?.__v}`
                  ).style.paddingBottom = "0px";
                  document.getElementById(
                    `message-index-${message?.__v}`
                  ).style.transition = "none";
                  //dispatch(setFocusedData({ status: false, __v:null }))
                }, 2800);
              }
            }, 2000);
          })
          .catch((error) => {
            console.log(`Cannot Query scenario by id => ${error}`);
          });

        dispatch(setFocusedData({ status: true, message_id: message?.__v }));
        setQueue(message?.__v);
      }
    }
  };
  const handleUnpinChat = (item: any) => {
    emit("unpinChat", { roomId: activeRoomData?._id, cid: item?._id });
    socketListen("message", (data: any) => {
      if (data.type === "unpinChat") {
        setToastShow(true);
        setToastMessage(t("chat_unpin_message"));
      }
    });
  };
  const FormattedMessage = (message: any) => {
    const regex =
      /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

    const formattedText = message?.replace(
      regex,
      (
        match: any,
        p1: any,
        p2: any,
        p3: any,
        p4: any,
        p5: any,
        p6: any,
        p7: any,
        p8: any
      ) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: 800;color:black; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        } else if (p5) {
          return (
            `<a href=${p5} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
            p5 +
            "</a>"
          );
        } else if (p8) {
          const participant = activeRoomData?.participants?.find(
            (part) => part?.user_id == p8
          );
          const self = userData?._id == participant?.user_id;
          const formatPart = formattedContact?.[participant?.user_id];
          const mentionedName = self
            ? `${t("you")} `
            : formatPart?.firstName
            ? formatPart?.firstName + " " + formatPart?.lastName
            : participant
            ? participant?.phone
            : p7;
          return (
            '<span style="color: green;">' + "@" + mentionedName + "</span>"
          );
        }

        return match;
      }
    );
    return formattedText;
  };

  return (
    <>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />
      {activeRoomData && Object.keys(activeRoomData).length !== 0 ? (
        <div className="h-[60px] absolute flex justify-between left-0 right-0 items-center border-b py-2 px-6 top-0  bg-white z-10">
          <Drawer
            anchor={"right"}
            open={roomRightDrawer}
            onClose={() => setRoomRightDrawer(false)}
          >
            <RoomDetailDrawer
              setBackgroundImgLoading={setBackgroundImgLoading}
              showRoomType={roomType}
              showDrawer={roomRightDrawer}
              searchTabShow={searchTabShow}
              setSearchTabShow={setSearchTabShow}
              setRoomRightDrawer={setRoomRightDrawer}
              setShowMediaPreview={setShowMediaPreview}
              setSelectedMedia={setSelectedMedia}
              setShareContactModal={setShareContactModal}
            />
          </Drawer>

          {shareContactModalShow && (
            <ShareContactModal
              shareContactModalShow={shareContactModalShow}
              setShareContactModal={setShareContactModal}
            />
          )}
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setSearchTabShow(false);
              setRoomType(activeRoomData?.type);
              setRoomRightDrawer(true);
            }}
          >
            {(activeRoomData?.type === "group" ||
              activeRoomData?.type === "broadcast") &&
              (activeRoomData?.profile_img === "" ? (
                <div>
                  <Avatar />
                </div>
              ) : (
                <div>
                  <img
                    src={DefaultImageUrl + activeRoomData?.profile_img}
                    alt="profile"
                    className="h-[40px] w-[40px] rounded-full"
                  />
                </div>
              ))}

            {activeRoomData?.type === "individual" &&
              (userProfileImg.profile_img === "" ||
              isUserBlockedYou ||
              isRoomBlocked ? (
                <div>
                  <Avatar />
                </div>
              ) : (
                <div className="relative">
                  {activeRoomData?.blocked === false &&
                  activeRoomData?.type === "individual" &&
                  !isUserBlockedYou &&
                  onlineRooms.includes(activeRoomData?._id) ? (
                    <>
                      <div
                        className={`h-[15px] w-[15px] ${
                          onlineRooms.includes(activeRoomData?._id)
                            ? "border-white bg-green-600 border-2 "
                            : " "
                        }  rounded-full absolute right-[-8px] bottom-[-1px]`}
                      ></div>
                      <img
                        src={DefaultImageUrl + userProfileImg?.profile_img}
                        alt="profile"
                        className="h-[40px] w-[40px] rounded-full"
                      />
                    </>
                  ) : (
                    <img
                      src={DefaultImageUrl + userProfileImg?.profile_img}
                      alt="profile"
                      className="h-[40px] w-[40px] rounded-full"
                    />
                  )}
                </div>
              ))}
            <div className="pl-3">
              <div className="font-semibold lightBlack">
                {activeRoomData?.type === "group" ||
                activeRoomData?.type === "broadcast" ? (
                  activeRoomData?.name
                ) : formattedContact[userInfo?.user_id] ? (
                  <span>
                    {formattedContact[userInfo?.user_id]?.firstName +
                      " " +
                      formattedContact[userInfo?.user_id]?.lastName}
                  </span>
                ) : (
                  <span>{userInfo?.phone}</span>
                )}
              </div>

              {!activeRoomData?.blocked &&
                activeRoomData?.type === "individual" &&
                !isUserBlockedYou &&
                showLastSeen()}

              {(activeRoomData?.type === "group" ||
                activeRoomData?.type === "broadcast") && (
                <div className="text-sm textGray">
                  <span>
                    {activeRoomData?.type === "broadcast"
                      ? currentParticipantsLength?.length - 1
                      : currentParticipantsLength?.length}
                  </span>
                  <span className="ml-1"> {t("participant")} </span>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-center">
              <SearchIcon
                className="mr-3  active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full  cursor-pointer"
                sx={{ height: 28, width: 28 }}
                onClick={() => {
                  setRoomRightDrawer(true);
                  setSearchTabShow(true);
                }}
              />
              {activeRoomData?.type !== "broadcast" &&
                (activeRoomData.type !== "group" &&
                activeRoomData?.participants?.length !== 1 &&
                !isRoomBlocked ? (
                  <div>
                    {isParticipantsInCall?.includes(activeRoomData?._id) ? (
                      activeRoomData?.type === "group" ||
                      activeRoomData?.type === "broadcast" ? (
                        <button
                          className="flex  items-center mx-4 bg-[#33ccff] text-white w-fit py-2
                                        px-4 text-center text-sm rounded-md cursor-pointer disabled:opacity-60"
                          onClick={joinOnGoingCall}
                          disabled={load ? load : callisRunning ? true : false}
                        >
                          <CallOutlinedIcon className="mr-2" />
                          {callisRunning ? t("in_call") : t("join_call")}
                        </button>
                      ) : (
                        ""
                      )
                    ) : (
                      <React.Fragment>
                        {disableCallButton ? (
                          <div className="mr-4">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          <div className="flex gap-2 mr-3">
                            <button
                              disabled={disableCallButton ? true : false}
                              onClick={() => {
                                if (
                                  callisRunning === false &&
                                  !joinedFromOtherDevice
                                ) {
                                  createCalls("audio");
                                } else {
                                  alert(t("call_validation"));
                                }
                              }}
                              // className=" cursor-pointer active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full"
                            >
                              <CallOutlinedIcon />
                            </button>
                            <button
                              // className="px-5 cursor-pointer active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full"
                              disabled={disableCallButton ? true : false}
                              onClick={() => {
                                if (
                                  callisRunning === false &&
                                  !joinedFromOtherDevice
                                ) {
                                  createCalls("video");
                                } else {
                                  alert(t("call_validation"));
                                }
                              }}
                            >
                              <VideoCallOutlinedIcon />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  !isRoomLeft &&
                  activeRoomData?.participants?.length !== 1 && (
                    <div>
                      {isParticipantsInCall?.includes(activeRoomData?._id) ? (
                        activeRoomData?.type === "group" ? (
                          <button
                            className="flex items-center mx-4 bg-[#33ccff] text-white w-fit py-2
                                            px-4 text-center text-sm rounded-md cursor-pointer disabled:opacity-60"
                            onClick={joinOnGoingCall}
                            disabled={callisRunning ? true : false}
                          >
                            <CallOutlinedIcon className="mr-2" />
                            {callisRunning ? t("in_call") : t("join_call")}
                          </button>
                        ) : (
                          ""
                        )
                      ) : (
                        <React.Fragment>
                          {disableCallButton ? (
                            <div className="mr-4">
                              <CircularProgress size={24} />
                            </div>
                          ) : (
                            <div className="flex gap-2 mr-3">
                              <button
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                  if (
                                    callisRunning === false &&
                                    !joinedFromOtherDevice
                                  ) {
                                    createCalls("audio");
                                  } else {
                                    alert(t("call_validation"));
                                  }
                                }}
                                // className=" cursor-pointer active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full"
                              >
                                <CallOutlinedIcon />
                              </button>
                              <button
                                // className="cursor-pointer active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full"
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                  if (
                                    callisRunning === false &&
                                    !joinedFromOtherDevice
                                  ) {
                                    createCalls("video");
                                  } else {
                                    alert(t("call_validation"));
                                  }
                                }}
                              >
                                <VideoCallOutlinedIcon />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )
                ))}

              <div>
                <img
                  src={settingHeaderImg}
                  className="w-[24px] mr-4 cursor-pointer active:bg-[#d9d4d4e0] active:h-[35px] active:p-[5px] active:w-[35px] active:rounded-full"
                  onClick={() => {
                    setSearchTabShow(false);
                    setRoomType(activeRoomData?.type);
                    setRoomRightDrawer(true);
                  }}
                  alt="sd"
                />
              </div>
            </div>
          </div>
          {pinnedChats?.length > 0 && (
            <div className="h-[60px] left-0 absolute top-16 w-full -mt-[4px] z-[900] flex items-center  gap-2 bg-white">
              {pinnedChats?.map((item: PinnedChatsDataTypes, index: number) => {
                const pinnedDate = moment(item?.created_at).format(
                  "DD.MM.YYYY"
                );
                return (
                  <div
                    className="flex bg-[#e4f5fa] p-2 py-1 gap-2 border-2  border-white"
                    key={index}
                  >
                    <div>
                      <img
                        src={PinIcon}
                        alt="pinned"
                        className="h-[32px] min-w-[32px] w-[32px]"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleRedirectOnMessage(item)}
                    >
                      {item.type === "text" ? (
                        <div className="line-clamp-1">
                          {parse(FormattedMessage(item?.message))}
                        </div>
                      ) : (
                        <div>{item.type.toUpperCase()}</div>
                      )}
                      <div className="text-sm textGray">
                        {t("attached")} {pinnedDate}
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleUnpinChat(item)}
                    >
                      <CancelIcon fontSize="small" color="disabled" />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        // tabValue === 4 ? (
        //     <CalendarApp setTabValue={setTabValue} />
        // ) :
        <div className="md:flex items-center justify-center h-full">
          <div className="flex justify-center">
            <img src={chatImg} className="w-52" alt="sd" />
          </div>
          <div className="pl-6">
            <div className="text-5xl font-normal">{t("welcome")}</div>
            <div className="text-5xl font-semibold pt-2">{t("comon_web")}</div>
          </div>
        </div>
      )}
    </>
  );
}
