const StoreDraft = async (data: any) => {
    try {
        await localStorage.setItem('@DraftData', data)
        // console.log('Draft Saved')
    } catch (error) {
        console.log(error, 'error draft saved');
    }
}

const FetchDraft = async () => {
    try {
        const value = await localStorage.getItem('@DraftData');
        if (value !== null) {
            // console.log('Draft retrieved')
            return value
        }
    } catch (error) {
        console.log(error, 'draft fetchFailed');
    }
}

export { FetchDraft, StoreDraft }