import { Backdrop } from "@mui/material";
import CloseIcon from "../../Assets/Images/closeBorder.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useEffect, useState } from "react";

interface ImagePreviewGalleryProps {
  showMediaPreview: boolean;
  mediaData: any;
  setSelectedMedia: (value: any) => void;
  setShowMediaPreview: (value: boolean) => void;
  selectedMedia: any;
}

const ImagePreviewGallery = ({
  showMediaPreview,
  mediaData,
  setSelectedMedia,
  selectedMedia,
  setShowMediaPreview,
}: ImagePreviewGalleryProps) => {
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePrevButton, setDisablePrevButton] = useState(false);

  useEffect(() => {
    const handleKeyDown = (ev) => {
      if (ev?.key == "Escape") {
        setShowMediaPreview(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleNextImage = () => {
    const currIndex = mediaData.findIndex((item: any) =>
      item?.type !== "video"
        ? item?.src === selectedMedia?.src
        : item?.sources[0]?.src === selectedMedia?.src
    );

    if (mediaData[currIndex + 2]) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }

    if (mediaData[currIndex + 1]?.src) {
      setDisablePrevButton(false);
      setSelectedMedia({ src: mediaData[currIndex + 1]?.src, type: "image" });
    } else {
      setSelectedMedia({
        src: mediaData[currIndex + 1]?.sources[0].src,
        type: "video",
      });
    }
  };

  const handlePrevImage = () => {
    const currIndex = mediaData.findIndex((item: any) =>
      item?.type !== "video"
        ? item?.src === selectedMedia?.src
        : item?.sources[0]?.src === selectedMedia?.src
    );

    if (mediaData[currIndex - 2]) {
      setDisablePrevButton(false);
    } else {
      setDisablePrevButton(true);
    }

    if (mediaData[currIndex - 1]?.src) {
      setDisableNextButton(false);
      setSelectedMedia({ src: mediaData[currIndex - 1]?.src, type: "image" });
    } else {
      setSelectedMedia({
        src: mediaData[currIndex - 1]?.sources[0].src,
        type: "video",
      });
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "grid",
        placeItems: "center",
      }}
      open={showMediaPreview}
    >
      <div
        className="absolute top-[20px] right-[30px] cursor-pointer"
        onClick={() => setShowMediaPreview(false)}
      >
        <img src={CloseIcon} alt="" className="h-[30px] w-[30px]" />
      </div>

      {selectedMedia.type !== "audeo" && (
        <div className="text-white absolute top-[35%] left-6">
          <button
            className="disabled:opacity-25 w-fit p-1 bg-white rounded-full"
            onClick={() => handlePrevImage()}
            disabled={disablePrevButton}
          >
            <ArrowLeftIcon color="info" fontSize="large" />
          </button>
        </div>
      )}
      {selectedMedia.type !== "audeo" && (
        <div className="text-white absolute top-[35%] right-6">
          <button
            className="disabled:opacity-25 w-fit p-1 bg-white rounded-full"
            onClick={() => handleNextImage()}
            disabled={disableNextButton}
          >
            <ArrowRightIcon color="info" fontSize="large" />
          </button>
        </div>
      )}

      <div className="h-[600px] max-w-[60%] flex items-center">
        {selectedMedia.type === "video" ? (
          <video src={selectedMedia.src} className="w-full h-full" controls />
        ) : selectedMedia.type === "audeo" ? (
          <audio
            src={selectedMedia?.src}
            controls
            onLoadedMetadata={() => console.log()}
          ></audio>
        ) : (
          <img src={selectedMedia.src} className="w-full h-full object-contain" />
        )}
      </div>

      <div className="text-white flex gap-4 overflow-x-scroll w-full px-10">
        {selectedMedia.type !== "audeo" &&
          mediaData.map((element: any, index: number) => {
            if (element.src) {
              return (
                  <div
                    key={index}
                    className="min-w-[100px] w-[100px] h-[100px]"
                  >
                    <img
                      src={element.src}
                      className={`w-full object-cover h-full cursor-pointer rounded-md ${
                        element.src === selectedMedia.src
                          ? "border-2 border-white"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedMedia({ src: element.src, type: "image" });
                      }}
                    />
                  </div>
              );
            } else {
              return (
                <div key={index} className="w-[170px] h-[100px]">
                  <video
                    src={element.sources[0].src}
                    className={`h-full w-full cursor-pointer rounded-md ${
                      element.sources[0].src === selectedMedia.src
                        ? "border-2 border-white"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedMedia({
                        src: element.sources[0].src,
                        type: "video",
                      });
                    }}
                  ></video>
                </div>
              );
            }
          })}
      </div>
    </Backdrop>
  );
};

export default ImagePreviewGallery;
