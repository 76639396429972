import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Switch from "@mui/material/Switch";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function AccountSetting() {
  const [checked, setChecked] = React.useState(true);
  const [accountTab, setAccountTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div className={accountTab ? "hidden" : "block"}>
        <div
          className="flex justify-between py-2 px-4 border-b cursor-pointer"
          onClick={() => setAccountTab(1)}
        >
          <div>Privacy</div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div
          className="flex justify-between py-2 px-4 border-b  cursor-pointer"
          onClick={() => setAccountTab(2)}
        >
          <div>Two-Step verification</div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div
          className="flex justify-between py-2 px-4 border-b  cursor-pointer"
          onClick={() => setAccountTab(3)}
        >
          <div>Change Number</div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div
          className="flex justify-between py-2 px-4 border-b  cursor-pointer"
          onClick={() => setAccountTab(4)}
        >
          <div>Delete My Account</div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>

      <div>
        {accountTab === 1 && (
          <div className="overflow-scroll overflow-x-hidden h-[440px]">
            <div className="flex pl-2 border-b py-2 cursor-pointer" onClick={() => setAccountTab(0)}>
              <div>
                <ArrowBackIosIcon sx={{fontSize:"17px"}} />
              </div>
              <div className="">Back</div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>Last Seen</div>
              <div className="flex">
                <div className="pr-3 textGray text-[15px]">Nobody</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>Profile Photo</div>
              <div className="flex">
                <div className="pr-3 textGray text-[15px]">My Contact</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>Status</div>
              <div className="flex">
                <div className="pr-3 textGray text-[15px]">2 Excluded</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>Groups</div>
              <div className="flex">
                <div className="pr-3 textGray">Everyone</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>Story</div>
              <div className="flex">
                <div className="pr-3 textGray">My Contact</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>
                <div>Live Location</div>
                <div className="text-sm textGray">
                  List of contacts you are sharing your live location
                </div>
              </div>
              <div className="flex">
                <div className="pr-3 textGray">None</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div className="w-[70%]">
                <div>Blocked</div>
                <div className="text-sm textGray">
                  List of contacts you are sharing your live location
                </div>
              </div>
              <div className="flex">
                <div className="pr-3 textGray">7 Contacts</div>
                <div>
                  <ArrowForwardIosIcon className="lightBlack" />
                </div>
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>
                <div>Read Receipts</div>
                <div className="text-sm textGray">
                  List of contacts you are sharing your live locationList of
                  contacts you are sharing your live location
                </div>
              </div>
              <div>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className="flex justify-between py-2 px-4 border-b">
              <div>
                <div>Screen Lock</div>
                <div className="text-sm textGray">
                  List of contacts you are sharing your live locationList of
                  contacts you are sharing your live location
                </div>
              </div>
              <div>
                <ArrowForwardIosIcon className="lightBlack" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountSetting;
