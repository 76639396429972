import { useFieldArray, useForm } from "react-hook-form";
import { IPollForm, IProps } from "./polls";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { defaultValues, pollSchema } from "./data";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import generateShortUUID from "../../../utils/generateShortUUID";
import PollFields from "./PollFields";
import { useDispatch, useSelector } from "react-redux";
import { setTotoalChatsCount } from "../../../Redux/ChatSlice";
import { emit } from "../../../Socket";

const PollModal = ({ show, setShow }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeRoomData, totalReceivedChatsCount } = useSelector(
    (state: any) => state?.chat
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(pollSchema),
  });

  const {
    fields: pollFields,
    append: appendPoll,
    remove: removePoll,
  }: any = useFieldArray({
    control,
    name: "polls",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleAddNewPoll = () => {
    appendPoll({
      _id: generateShortUUID(),
      title: "",
      isMultiAnswerAllow: false,
      options: [
        { _id: generateShortUUID(), name: "", value: [] },
        { _id: generateShortUUID(), name: "", value: [] },
      ],
    });

    setTimeout(() => {
      const submitButton = document.getElementById("submit-button");
      if (submitButton) {
        submitButton.focus();
      }
    }, 0);
  };

  const onSubmit = async (_data: any) => {
    const payload = {
      data: {
        roomId: activeRoomData?._id,
        type: "poll",
        fileURL: null,
        isForwarded: false,
        message: JSON.stringify(_data?.polls),
        _id: generateShortUUID(),
        fontStyle: "",
        thumbnail: "",
      },
      reply_msg: null,
    };

    emit("sendChat", payload);
    dispatch(setTotoalChatsCount(totalReceivedChatsCount + 1));
    setShow(false);
  };

  return (
    <Dialog
      open={show}
      sx={{
        ".MuiDialog-paper": {
          minWidth: "50%",
          backgroundColor: "#F3F9FC",
        },
      }}
    >
      <DialogContent
        sx={{
          maxHeight: "70%",
          overflowY: "auto",
          scrollbarWidth: "thin",
          padding: "0px 24px",
        }}
      >
        <div className="flex items-center justify-between py-[10px] sticky top-0 bg-[#F3F9FC]">
          <div className="font-semibold text-lg capitalize">
            {t("createnewpoll")}
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="p-3 mt-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            {pollFields?.map((pollField: IPollForm, pollIndex: number) => {
              return (
                <PollFields
                  key={pollField?._id}
                  control={control}
                  errors={errors}
                  pollField={pollField}
                  pollIndex={pollIndex}
                  removePoll={removePoll}
                />
              );
            })}

            {pollFields?.length < 5 && (
              <div className="text-right mb-3">
                <button
                  type="button"
                  onClick={handleAddNewPoll}
                  className="text-[#33CCFF] font-semibold outline-none capitalize"
                >
                  + {t("addqtn")}
                </button>
              </div>
            )}

            <button
              type="submit"
              id="submit-button"
              className="bg-[#33CCFF] w-full outline-none py-2 rounded-md text-white"
            >
              {t("submit")}
            </button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PollModal;
