import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

interface PropTypes {
  show: boolean;
  onStop: () => void;
  confirmType: string;
  onStart: () => void;
  setShow: (value: boolean) => void;
}

const ScreenShareConfirm: FC<PropTypes> = ({
  show,
  onStop,
  onStart,
  setShow,
  confirmType,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = () => {
    if (confirmType == "start") {
      onStart();
    } else {
      onStop();
    }
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          minWidth: "30%",
        },
      }}
    >
      <DialogContent>
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg">
            {confirmType == "start"
              ? t("startscreenshare")
              : t("stopscreenshare")}
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div>
          {confirmType == "start" ? (
            <Alert severity="info">{t("startscreenconfirm")}</Alert>
          ) : confirmType == "permission" ? (
            <Alert icon={<HttpsOutlinedIcon />} severity="warning">
              {t("screensharepermission")}
            </Alert>
          ) : (
            <Alert severity="warning">{t("stopscreenconfirm")}</Alert>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        {confirmType == "permission" ? (
          <button
            onClick={handleClose}
            className="bg-[crimson] flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
          >
            {t("ok")}
          </button>
        ) : (
          <React.Fragment>
            <button
              onClick={handleClose}
              className="bg-gray-400 flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
            >
              {t("cancel")}
            </button>

            <button
              onClick={handleSubmit}
              className="bg-[crimson] flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
            >
              {t("yes")}
            </button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ScreenShareConfirm;
