import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete from 'use-places-autocomplete';

type PropsType = {
    onAddressSelect: (data: any) => void
    setAddress: (data: any) => void
    address: any
}

function PlacesAutocomplete(props: PropsType) {
    const { t } = useTranslation()
    const { onAddressSelect, setAddress, address } = props
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete()

    function renderSuggestions() {
        return data?.map((suggestion: any) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
                description,
            } = suggestion;

            return (
                <div style={{ margin: 10 }}>
                    <li
                        key={place_id}
                        style={{ cursor: "pointer", paddingBottom: 5 }}
                        onClick={() => {
                            setAddress(description);
                            setValue(description, false);
                            clearSuggestions();
                            onAddressSelect && onAddressSelect(description);
                        }}
                    >
                        <b>{main_text}</b> <span>{secondary_text}</span>
                    </li>
                    <hr />
                </div>
            )
        })
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
        setAddress(e.target.value)
    }

    return (
        <div>
            <input
                value={value || address}
                className={''}
                disabled={!ready}
                style={{
                    width: "100%",
                    borderRadius: 4,
                    padding: '12px',
                    border: '1px solid',
                    zIndex: 10000
                }}
                onChange={handleChange}
                placeholder={t("search_location")}
            />

            {status === 'OK' && (
                <ul style={{
                    border: '1px solid #e0dcdc',
                    borderRadius: 4,
                    background: "#80808012",
                    padding: "10px 0",
                    paddingLeft: 28
                }}>
                    {renderSuggestions()}
                </ul>
            )}
        </div>
    )
}

export default PlacesAutocomplete