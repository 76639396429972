import { DefaultImageUrl } from "../../../Constant/Constant";

const ReportQuestionAudio = ({ data }: any) => {

  return (
    <>
      {data?.attachment?.type === "AUDIO" && (
        <div className="mt-3">
          <audio
            src={DefaultImageUrl + data?.attachment?.filename}
            controls
          ></audio>
        </div>
      )}
    </>
  );
};

export default ReportQuestionAudio;
