import { Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { TFunction } from "react-i18next"

type PropTypes = {
    show: boolean
    type: string
    setShow: (value: boolean) => void
    setCreateGroupShow: (value: boolean) => void
    setType: (value: string) => void
    t: TFunction
}

function GroupNavigationModal(props: PropTypes) {
    const { t, show, setShow, type, setType, setCreateGroupShow } = props

    function handleNext() {
        setCreateGroupShow(true)
        setShow(false)
    }

    return (
        <Dialog
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className="">
                    {t("group_broadcast_title")}
                </div>
                <div className="mt-1">
                    <FormControl>
                        <RadioGroup
                            defaultValue="group"
                            name="controlled-radio-buttons-group"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value)}
                            sx={{ display: "flex", flexDirection: "row", }}
                        >
                            <FormControlLabel
                                value="group"
                                control={<Radio />}
                                label={t("group")}
                            />
                            <FormControlLabel
                                value="broadcast"
                                control={<Radio />}
                                label={t("broadcast")}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="font-medium mt-1">
                    {type === 'group' ? t('room-type-message-1') : t('room-type-message-2')}
                </div>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => setShow(false)}
                    className="bg-gray-200 p-2 rounded-md"
                >
                    {t("cancel")}
                </button>
                <button
                    onClick={handleNext}
                    className="bg-primary p-2 rounded-md text-white disabled:opacity-25"
                >
                    {t("next")}
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default GroupNavigationModal