import { Backdrop } from "@mui/material";
import CloseIcon from "../../Assets/Images/closeBorder.png";

interface SingleImageGalleryProps {
  imgSrc: string;
  show: boolean;
  setShow: (value: boolean) => void;
}

const SingleImagePreview = ({
  imgSrc,
  show,
  setShow,
}: SingleImageGalleryProps) => {
  window.addEventListener(
    "keyup",
    (event) => {
      if (event.key === "Escape") {
        setShow(false);
      }
    },
    true
  );

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
    >
      <div className="w-full flex justify-center">
        <div
          className="absolute top-[20px] right-[30px] cursor-pointer"
          onClick={() => setShow(false)}
        >
          <img src={CloseIcon} alt="" className="h-[30px] w-[30px]" />
        </div>

        <div className="h-[600px] max-w-[60%]">
          <img src={imgSrc} className="w-full h-full bg-white object-contain" />
        </div>
      </div>
    </Backdrop>
  );
};

export default SingleImagePreview;
