import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { ActiveRoomChatsType } from "../../Types/Types";
import AudioFilePreview from "./AudioFilePreview";
import ContactFilePreview from "./ContactFilePreview";
import DocumentFilesPreview from "./DocumentFilesPreview";
import ImagePreview from "./ImagePreview";
import SystemMessage from "./SystemMessage";
import TextPreview from "./TextPreview";
import VideoFilePreview from "./VideFilePreview";

import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CALENDER_SOCKET_EVENTS,
  DefaultImageUrl,
} from "../../Constant/Constant";
import {
  GET_APPOINTMENT_BY_ROOM_ID,
  GET_CHATS_BY_ROOM_ID,
  GET_SCHEDULE_BY_ROOM_ID,
} from "../../Graphql/Queries";
import { refreshTokenCllient } from "../../Graphql/authClient";
import {
  appendNextChats,
  appendPreviousChats,
  setActiveChatSkip,
  setActiveRoomsChats,
  setCurrentPage,
  setFetchData,
  setIsRoomLoading,
  setNewMessageCount,
} from "../../Redux/ChatSlice";
import { store } from "../../Redux/store";
import { emit, socketListen } from "../../Socket";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ADD_MSG_TO_TOPIC, CREATE_TOPIC } from "../../Graphql/Mutations";
import { GET_CHILD_TOPIC_BY_ID, GET_MY_TOPICS } from "../../Graphql/Queries";
import { authCllient } from "../../Graphql/authClient";
import { setTopicsData } from "../../Redux/UserSlice";
import Toast from "../../coreComponent/Toast";
import { getSession } from "../../utils/session";
import ImagePreviewGallery from "./ImagePreviewGallery";
import InfoDrawerContainer from "./InfoDrawerContainer";
import ParentChatPage from "./ParentChatPage";
import PollMessage from "./PollMessage";
import PollDetails from "./polls/PollDetails";

export default function ChatContainer({
  messageArray,
  setMessageArray,
  selectedMedia,
  showMediaPreview,
  setShowMediaPreview,
  setSelectedMedia,
  mediaPreviewData,
  setMediaPreviewData,
  selectedMessages,
  setSelectedMessages,
  setIsChangeMessageFooter,
  setShowMessageCheckboxes,
  showMessageCheckboxes,
  setReplyFooterShow,
  setReplyMessage,
}) {
  const {
    activeRoomChats,
    activeRoomData,
    fetchData,
    focusData,
    totalPage,
    activeChatsLimit,
    currentPage,
    newMessageCount,
  } = useSelector((state: any) => state.chat);
  const { userData, isTabActive }: any = store.getState().user;
  const [showArrow, setshowArrow] = useState(false);
  const [newMessageArray, setNewMessageArray] = useState<any>([]);
  const ref = useRef<any>(null);
  const [contactMessage, setContactMessage] = useState<any>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setloading] = useState(true);
  const [childLoad, setChildLoad] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectOldTopic, setSelectedOldTopic] = useState("");
  const [selectedHeading, setSelectedHeading] = useState<any>({});
  const [selectedHeadingId, setSelectedHeadingId] = useState<any>("");
  const [showHeadingModal, setShowHeadingModal] = useState(false);
  const { token } = getSession();
  const { topicsData } = useSelector((state: any) => state.user);
  const [navHis, setNavHis] = useState<any>([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [headingError, setHeadingError] = useState("");
  const [bottomLoading, setBottomloading] = useState(false);
  const [dataLoad, stopDataLoad] = useState(false);
  const [messageInfoDrawerShow, setMessageInfoDrawerShow] = useState(false);
  const [messageInfoItem, setMessageInfoItem] = useState<any>("");
  const [showPollDrawer, setShowPollDrawer] = useState(false);
  const [pollDrawerData, setPollDrawerData] = useState([]);

  const groupMessagesByDate = (messages: any) => {
    const groupedMessages = [];
    let currentDate = null;

    messages.forEach((message: any) => {
      const tempMsg = { ...message };

      tempMsg.message =
        message?.type == "poll"
          ? message?.message
            ? JSON.parse(message?.message)
            : []
          : message?.message;

      const messageDate = new Date(message.created_at).toDateString();

      if (currentDate !== messageDate) {
        groupedMessages.push({
          date: messageDate,
          messages: [tempMsg],
        });
        currentDate = messageDate;
      } else {
        const existingMessages =
          groupedMessages?.[groupedMessages?.length - 1]?.messages;
        
          const isDuplicate = existingMessages?.some(
          (msg) => msg?._id === tempMsg?._id
        );

        if (!isDuplicate) {
          existingMessages.push(tempMsg);
        }

        // groupedMessages[groupedMessages.length - 1].messages.push(tempMsg);
      }
    });
    return groupedMessages;
  };
  const handleScrollBottom = () => {
    setshowArrow(false);
    var height = document.querySelector("#scrollableDiv").scrollHeight;
    document.querySelector("#scrollableDiv").scroll(0, height);
    dispatch(setNewMessageCount(0));
    if (
      activeRoomChats[activeRoomChats.length - 1]?.index !==
      activeRoomData.totalChats
    ) {
      dispatch(setCurrentPage(0));
      setloading(true);
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_ROOM_ID,
          variables: {
            input: {
              roomId: activeRoomData?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index: activeRoomData?.totalChats + 1,
              limit: activeChatsLimit, // custom limit
              scrollType: "LATEST", // "UP"
            },
          },
        })
        .then((res) => {
          if (res.data.getChatsByRoomId.length > 0) {
            dispatch(setActiveRoomsChats(res?.data?.getChatsByRoomId));
          }
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        })
        .finally(() => {
          setloading(false);
        });
    }
  };

  // useEffect(() => {
  // setshowArrow(false);
  // if (newMessageCount > 0) {
  //   setshowArrow(true);
  // } else {
  //   setshowArrow(false);
  // }
  // }, [newMessageCount]);

  useEffect(() => {
    if (messageInfoItem && messageInfoItem?.type === "contact") {
      const msg = JSON.parse(messageInfoItem?.message);
      setContactMessage(msg);
    }
  }, [messageInfoItem]);

  const sortedArray = [...activeRoomChats].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  const handleScroll = (event: any) => {
    // Check if the user has scrolled down, and show the up arrow accordingly

    // here changed < 0 to < -130
    if (event.target.scrollTop < -130) {
      setshowArrow(true);
    } else {
      setshowArrow(false);
      dispatch(setNewMessageCount(0));
    }
    const target = event.target;

    // Check if user has scrolled to the top
    if (
      loading === false &&
      (target.scrollTop === 0 || target.scrollTop === 1) &&
      activeRoomChats[activeRoomChats.length - 1]?.index !==
        activeRoomData.totalChats
    ) {
      fetchDownData();
      setshowArrow(false);
      dispatch(setNewMessageCount(0));
    }

    // Check if user has scrolled to the bottom
    if (
      target.scrollHeight + target.scrollTop + 1 === target.clientHeight &&
      currentPage + 1 !== totalPage
    ) {
      setshowArrow(true);
      // Load next page data
    }
  };

  const fetchDownData = () => {
    setBottomloading(true);
    const page = currentPage - 1;
    const skip = activeRoomChats.length;
    const newPage = Math.floor(totalPage - (skip / activeChatsLimit + 1));
    dispatch(setActiveChatSkip(skip));
    dispatch(setCurrentPage(newPage));
    if (
      activeRoomChats[activeRoomChats.length - 1]?.index !==
        activeRoomData.totalChats &&
      activeRoomChats.filter(
        (el: any) => el.index === activeRoomData.totalChats
      ).length === 0
    ) {
      if (activeRoomChats[activeRoomChats.length - 1]?.index) {
        refreshTokenCllient(token)
          .query({
            query: GET_CHATS_BY_ROOM_ID,
            variables: {
              input: {
                roomId: activeRoomData?._id, // roomId
                total: activeRoomData?.totalChats, // total chats
                index: activeRoomChats[activeRoomChats.length - 1]?.index,
                limit: activeChatsLimit, // custom limit
                scrollType: "BOTTOM", // "UP"
              },
            },
          })
          .then((res) => {
            if (res.data.getChatsByRoomId.length > 0) {
              dispatch(appendPreviousChats(res?.data?.getChatsByRoomId));
            }
          })
          .catch((error) => {
            console.log(`Cannot Query scenario by id => ${error}`);
          })
          .finally(() => {
            setBottomloading(false);
          });
      } else {
        setBottomloading(false);
      }
    }
  };

  const fetchChatData = async (type: any) => {
    // Your fetch logic hereconst

    const page = currentPage + 1;
    const skip = activeChatsLimit * page;
    const newPage = Math.floor(totalPage - (skip / activeChatsLimit - 1));
    /* && currentPage  !== totalPage */
    if (activeRoomData?.totalChats !== activeRoomChats?.length) {
      dispatch(setIsRoomLoading(true));
      dispatch(setActiveChatSkip(skip));
      dispatch(setCurrentPage(newPage));
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_ROOM_ID,
          variables: {
            input: {
              roomId: activeRoomData?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index:
                activeRoomChats.length > 0
                  ? activeRoomChats[0]?.index
                  : activeRoomData?.totalChats + 1,
              limit: activeChatsLimit, // custom limit
              scrollType: activeRoomChats.length > 0 ? "UP" : "LATEST", // "UP"
            },
          },
        })
        .then((res) => {
          dispatch(setFetchData(false));
          if (res.data.getChatsByRoomId.length > 0) {
            setloading(false);
            if (fetchData) {
              dispatch(setActiveRoomsChats(res?.data?.getChatsByRoomId));
            } else {
              dispatch(appendNextChats(res?.data?.getChatsByRoomId));
            }
          } else {
            stopDataLoad(true);
          }
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        })
        .finally(() => {
          setloading(false);
          dispatch(setIsRoomLoading(false));
        });
    }
  };

  const loadMoreData = () => {
    fetchChatData("");
  };

  /* useEffect(() => {
        console.log('totalPage Useeffect******',totalPage)
        
       
    }, [totalPage]) */

  useEffect(() => {
    const mediaData = [];
    activeRoomChats?.forEach((item: ActiveRoomChatsType) => {
      if (item?.type === "IMAGE" || item?.type === "VIDEO") {
        mediaData.push(item);
      }
    });
    let formattedMediaArray: any = [];
    mediaData?.forEach((item: any) => {
      if (item?.type === "IMAGE") {
        formattedMediaArray.push({ src: DefaultImageUrl + item?.fileURL });
      } else {
        formattedMediaArray.push({
          type: "video",
          sources: [
            { src: DefaultImageUrl + item?.fileURL, type: "video/mp4" },
          ],
        });
      }
    });

    setMediaPreviewData(formattedMediaArray);

    setNewMessageArray(groupMessagesByDate(sortedArray));
  }, [activeRoomChats]);

  useMemo(() => {
    if (activeRoomData?._id !== undefined) {
      setloading(true);
      fetchChatData("first");
    }
  }, [activeRoomData?._id]);

  useEffect(() => {
    setData(topicsData);
    if (activeRoomData?._id !== undefined) {
      dispatch(setCurrentPage(0));

      // fetchChatData();
      if (activeRoomData?.totalChats === 0) {
        setloading(false);
      }
      emit("getPinChats", { roomId: activeRoomData?._id });
      emit("getFavouriteChats", { roomId: activeRoomData?._id });
      if (isTabActive) {
        emit("setChatReadBy", {
          roomId: activeRoomData?._id,
        });
      }
    }
  }, [activeRoomData]);

  useEffect(() => {
    if (isTabActive && activeRoomData?._id) {
      emit("setChatReadBy", {
        roomId: activeRoomData?._id,
      });
    }
  }, [isTabActive]);

  const createHeading = async () => {
    if (selectOldTopic === "" && heading === "") {
      setHeadingError(t("heading-error"));
    } else if (topicsData.filter((el: any) => el === heading).length > 0) {
      setHeadingError(t("already-exist-heading-error"));
    } else {
      setCreateLoading(true);
      if (selectOldTopic == "" && navHis?.length == 0) {
        await authCllient
          .mutate({
            mutation: CREATE_TOPIC,
            variables: { input: { name: heading, parent: null } },
          })
          .then(async (res) => {
            setShowHeadingModal(true);
            setCreateModal(false);
            setCreateLoading(false);
            setHeading("");
            getTopicsData();
            setNavHis([]);
            setSelectedOldTopic("");
          })
          .catch((err) => {
            const errormsg = JSON.parse(err.message).message;
            console.log(`Error occured at Accept Invitation ${err}`, errormsg);
            setHeadingError(errormsg);
            setCreateLoading(false);
          })
          .finally(() => {});
      } else {
        await authCllient
          .mutate({
            mutation: CREATE_TOPIC,
            variables: {
              input: {
                name: heading,
                parent:
                  navHis.length === 0 ? null : navHis[navHis.length - 1]?.id,
              },
            },
          })
          .then(async (res) => {
            setShowHeadingModal(true);
            setCreateModal(false);
            setCreateLoading(false);
            setHeading("");
            getTopicsData();
            setNavHis([]);
            setSelectedOldTopic("");
          })
          .catch((err) => {
            const errormsg = JSON.parse(err.message).message;
            console.log(`Error occured at Accept Invitation ${err}`, errormsg);
            setHeadingError(errormsg);
            setCreateLoading(false);
          })
          .finally(() => {});
      }
    }
  };

  const addToTopic = async () => {
    if (selectOldTopic == "" && navHis?.length == 0) {
      setHeadingError(t("heading-error"));
    } else {
      setCreateLoading(true);
      await authCllient
        .mutate({
          mutation: ADD_MSG_TO_TOPIC,
          variables: {
            input: {
              topicId: navHis?.[navHis?.length - 1]?.id || selectOldTopic,
              chatId: selectedHeadingId,
              roomId: activeRoomData?._id,
            },
          },
        })
        .then((res) => {
          console.log(res, "res====");
          getTopicsData();
          setCreateLoading(false);
          setSelectedOldTopic("");
          setNavHis([]);
          setHeadingError("");
          setShowHeadingModal(false);
          setSelectedHeadingId("");
        });
    }
  };

  const getTopicsData = () => {
    refreshTokenCllient(token)
      .query({
        query: GET_MY_TOPICS,
      })
      .then((res: any) => {
        dispatch(setTopicsData(res?.data?.getMyTopics));
        setData(res?.data?.getMyTopics);
      });
  };
  const getChildTopicsData = (id: any) => {
    setChildLoad(true);
    refreshTokenCllient(token)
      .query({
        query: GET_CHILD_TOPIC_BY_ID,
        variables: {
          input: {
            _id: id,
          },
        },
      })
      .then((res: any) => {
        setChildLoad(false);
        setData(res?.data?.getChildTopics);
      });
  };

  const handleBackEvent = () => {
    const navItem = navHis.slice(0, navHis.length - 1);
    console.log(navItem, "00");
    setNavHis(navItem);
    if (navItem.length > 0) {
      getChildTopicsData(navItem[navItem.length - 1]?.id);
    } else {
      setData(topicsData);
    }
  };

  return (
    <>
      <Toast message={toastMessage} open={toastShow} setShow={setToastShow} />
      <Dialog
        open={createModal}
        onClose={() => {
          setCreateModal(false);
          setHeading("");
          setShowHeadingModal(true);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        onKeyUp={(e: any) => {
          const ENTER = 13;
          console.log(e.keyCode);

          if (e.keyCode === ENTER) {
            if (!createLoading) {
              createHeading();
            }
          }
        }}
      >
        <DialogTitle className=" pt-5 pb-4 bg-[#E0FAFF]">
          {t("create-heading")}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 !important" }}>
          <DialogContentText>
            <div className="my-4 px-2 text-center w-full block">
              <TextField
                type="text"
                name="selected_heading"
                placeholder={t("heading")}
                value={heading}
                className={`mr-2 cursor-pointer rounded-md w-[75%] px-4 py-2 border ${
                  headingError !== "" ? "border-red-500" : ""
                } `}
                onChange={(e: any) => {
                  setHeadingError("");
                  setHeading(e.target.value);
                }}
              />
              <div className="text-red-500 mt-1 text-sm">
                {headingError !== ""
                  ? headingError.includes("exist")
                    ? `${heading} ${headingError}`
                    : headingError
                  : ""}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="text-center">
          <div
            className="bg-primary text-white cursor-pointer rounded-md p-3 py-1"
            onClick={() => {
              setHeadingError("");
              setCreateModal(false);
              setHeading("");
              setShowHeadingModal(true);
            }}
          >
            {t("cancel")}
          </div>
          {heading?.trim()?.length > 0 && (
            <div
              className="bg-primary text-white cursor-pointer rounded-md p-3 py-1"
              onClick={() => {
                if (!createLoading) {
                  createHeading();
                }
              }}
            >
              {createLoading ? (
                <CircularProgress
                  style={{ height: 16, width: 16, color: "white" }}
                />
              ) : (
                t("add")
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={showHeadingModal}
        onClose={() => {
          setChildLoad(false);
          setShowHeadingModal(false);
          setHeadingError("");
          setCreateLoading(false);
          setSelectedHeadingId("");
          setNavHis([]);
          setSelectedOldTopic("");
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className=" pt-5 pb-4 bg-[#E0FAFF]">
          {t("add-to-topic")}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 !important" }}>
          <DialogContentText>
            <div className="mt-3 mb-1 mx-8 flex ">
              {navHis.length > 0 &&
                navHis.map((el: any, index: any) => {
                  return (
                    <div key={index}>
                      {index === 0 && (
                        <BsChevronLeft
                          className="mt-[2px] mr-1 cursor-pointer"
                          onClick={() => {
                            handleBackEvent();
                          }}
                          style={{ height: 14, width: 14 }}
                        />
                      )}
                      <div className="text-gray-400 text-sm mr-1">
                        {el?.name} {index !== navHis.length - 1 && "/"}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="mt-3 mb-2">
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder={t("search")}
                className=" p-2 rounded-md my-2 w-[90%] flex mx-auto justify-center border"
              />
            </div>

            <div className="block text-center w-[90%] mx-auto my-4">
              {childLoad ? (
                <div className="flex mx-auto justify-center">
                  <CircularProgress
                    style={{ height: 18, width: 18, color: "#33ccff" }}
                  />
                </div>
              ) : data.length === 0 ? (
                <div className="text-sm text-gray-400 text-center my-6">
                  {t("no_headings")}
                </div>
              ) : (
                data
                  .filter((el: any) =>
                    el.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedOldTopic(item?._id);
                        }}
                        onDoubleClick={() => {
                          setSelectedOldTopic("");
                          getChildTopicsData(item?._id);
                          setNavHis([
                            ...navHis,
                            { name: item?.name, id: item?._id },
                          ]);
                        }}
                      >
                        <div
                          className={`${
                            selectOldTopic === item?._id
                              ? "bg-[#33ccff36]"
                              : "hover:bg-gray-200"
                          }  py-1 px-1 flex items-center rounded-md cursor-pointer`}
                        >
                          {item?.name}
                        </div>
                        <hr className="my-1 " />
                      </div>
                    );
                  })
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="text-center flex justify-center mx-auto">
          <div
            className="border-[#33ccff] border text-[#33ccff] cursor-pointer rounded-md p-3 py-1"
            onClick={() => {
              setCreateLoading(false);
              setHeadingError("");
              setChildLoad(false);
              setNavHis([]);
              setSelectedOldTopic("");
              setShowHeadingModal(false);
              setSelectedHeadingId("");
            }}
          >
            {t("cancel")}
          </div>
          <div
            className="border-[#33ccff] border text-[#33ccff] cursor-pointer rounded-md p-3 py-1"
            onClick={() => {
              setShowHeadingModal(false);
              setCreateModal(true);
            }}
          >
            {t("add-sub-topic")}
          </div>
          <div
            className={`${
              selectOldTopic || navHis?.length > 0
                ? "bg-primary"
                : "bg-gray-200"
            }  text-white cursor-pointer rounded-md p-3 py-1`}
            onClick={() => {
              if (
                !createLoading &&
                (selectOldTopic !== "" || navHis?.length > 0)
              ) {
                addToTopic();
              }
            }}
          >
            {createLoading ? (
              <CircularProgress
                style={{ height: 16, width: 16, color: "white" }}
              />
            ) : (
              t("add-here")
            )}
          </div>
        </DialogActions>
      </Dialog>
      {/* {useMemo(() => {
        return (
          <>
            {activeRoomData !== undefined &&
              Object.keys(activeRoomData).length !== 0 &&
              showArrow && (
                <div
                  className="bg-primary shadow-lg h-[40px] w-[40px] flex items-center justify-center rounded-full absolute z-[20] right-[22px] bottom-[135px] cursor-pointer"
                  onClick={handleScrollBottom}
                >
                  <Badge badgeContent={newMessageCount} color="secondary">
                    <KeyboardArrowDownIcon sx={{ color: "white" }} />
                  </Badge>
                </div>
              )}
          </>
        );
      }, [newMessageCount, showArrow])} */}
      {activeRoomData?._id && showArrow && (
        <div
          className="bg-primary shadow-lg h-[40px] w-[40px] flex items-center justify-center rounded-full absolute z-[20] right-[22px] bottom-[135px] cursor-pointer"
          onClick={handleScrollBottom}
        >
          <Badge badgeContent={newMessageCount} color="secondary">
            <KeyboardArrowDownIcon sx={{ color: "white" }} />
          </Badge>
        </div>
      )}
      <InfoDrawerContainer
        messageInfoDrawerShow={messageInfoDrawerShow}
        setMessageInfoDrawerShow={setMessageInfoDrawerShow}
        messageInfoItem={messageInfoItem}
        contactMessage={contactMessage}
      />
      {activeRoomData?._id !== undefined ? (
        loading ? (
          <div className="mb-2 items-center flex h-full flex-col justify-center">
            <ReactLoading
              type="spokes"
              width={30}
              height={30}
              color="#33ccff"
            />
            {/* <div className="my-3">Chat syncing is currently underway.</div> */}
          </div>
        ) : (
          <>
            <ImagePreviewGallery
              showMediaPreview={showMediaPreview}
              mediaData={mediaPreviewData}
              setSelectedMedia={setSelectedMedia}
              selectedMedia={selectedMedia}
              setShowMediaPreview={setShowMediaPreview}
            />
            <ParentChatPage />
            <div
              id="scrollableDiv"
              className="overflow-y-scroll top-0 overflow-x-hidden absolute pb-32 h-full min-h-0 pt-16"
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {/*Put the scroll bar always on the bottom*/}

              <InfiniteScroll
                dataLength={activeRoomChats?.length}
                onScroll={handleScroll}
                next={loadMoreData}
                style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                inverse={
                  activeRoomData?.totalChats !== activeRoomChats?.length &&
                  dataLoad === false
                    ? true
                    : false
                } //
                hasMore={true}
                loader={
                  activeRoomData?.totalChats !== activeRoomChats?.length &&
                  dataLoad === false ? (
                    <div className="mb-2 mt-[1.5rem] flex justify-center">
                      <ReactLoading
                        type="spin"
                        width={30}
                        height={30}
                        color="#33ccff"
                      />
                    </div>
                  ) : (
                    ""
                  )
                }
                endMessage=""
                scrollableTarget="scrollableDiv"
              >
                {loading && (
                  <div className="w-full flex justify-center">
                    <ReactLoading
                      type="spin"
                      width={30}
                      height={30}
                      color="#33ccff"
                    />
                  </div>
                )}
                {newMessageArray.map((ele: any, key: number) => {
                  return (
                    <React.Fragment key={key}>
                      {ele?.messages?.map(
                        (item: ActiveRoomChatsType, index: number) => {
                          const nextMessage = ele?.messages?.[index + 1];
                          const showDate =
                            !nextMessage || // if there's no next message, show the date
                            moment(item?.created_at)?.format("HH:mm") !==
                              moment(nextMessage?.created_at)?.format(
                                "HH:mm"
                              ) || // if the time is different
                            item?.sender !== nextMessage?.sender; // if the sender is different

                          const showDateforoutgoing =
                            item?.sender === userData?._id &&
                            moment(sortedArray[index - 1]?.created_at).format(
                              "HH:mm"
                            ) === moment(item.created_at).format("HH:mm") &&
                            sortedArray.length - 1 === index
                              ? true
                              : index === sortedArray.length - 1
                              ? true
                              : item?.sender !== sortedArray[index + 1]?.sender
                              ? true
                              : item?.sender ===
                                  sortedArray[index + 1]?.sender &&
                                moment(
                                  sortedArray[index + 1]?.created_at
                                ).format("HH:mm") !==
                                  moment(item.created_at).format("HH:mm")
                              ? true
                              : false;
                          const profileShown =
                            item?.sender === userData?._id &&
                            moment(sortedArray[index - 1]?.created_at).format(
                              "HH:mm"
                            ) === moment(item.created_at).format("HH:mm") &&
                            sortedArray.length - 1 === index
                              ? true
                              : index === sortedArray.length - 1
                              ? true
                              : item?.sender !== sortedArray[index + 1]?.sender
                              ? true
                              : false;

                          const deletedMsg = [
                            "This message was deleted",
                            "You deleted this message",
                          ];

                          // const pollData =
                          //   item?.type == "poll"
                          //     ? deletedMsg?.includes(item?.message)
                          //       ? []
                          //       : JSON.parse(item?.message)
                          //     : [];

                          return (
                            <div
                              className={`flex flex-col md:ps-6  pe-4 w-full  pt-4 md:pt-2 relative ${
                                index === sortedArray.length - 1 ? "mb-3" : ""
                              }`}
                              key={index}
                              ref={ref}
                              id={`message-index-${item?.index}`}
                            >
                              <div>
                                {/* <div>{item.index}</div> */}
                                <div className="message">
                                  {(item?.type === "text" ||
                                    item?.type === "Link") && (
                                    <TextPreview
                                      profileShown={profileShown}
                                      showDate={showDate}
                                      showDateforoutgoing={showDateforoutgoing}
                                      item={item}
                                      isGroup={false}
                                      setData={setData}
                                      setSelectedHeading={setSelectedHeading}
                                      setSelectedHeadingId={
                                        setSelectedHeadingId
                                      }
                                      setShowHeadingModal={setShowHeadingModal}
                                      searchText={""}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setReplyFooterShow={setReplyFooterShow}
                                      setReplyMessage={setReplyMessage}
                                      setSelectedMessages={setSelectedMessages}
                                      setMessageInfoItem={setMessageInfoItem}
                                      setMessageInfoDrawerShow={
                                        setMessageInfoDrawerShow
                                      }
                                    />
                                  )}

                                  {item?.type == "poll" && (
                                    <PollMessage
                                      key={item?._id}
                                      pollData={item?.message}
                                      item={item}
                                      messageId={item?._id}
                                      senderId={item?.sender}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setShowPollDrawer={setShowPollDrawer}
                                      setPollDrawerData={setPollDrawerData}
                                      newMessageArray={newMessageArray}
                                      setNewMessageArray={setNewMessageArray}
                                      date={ele?.date}
                                    />
                                  )}

                                  {item?.type === "IMAGE" && (
                                    <>
                                      <ImagePreview
                                        profileShown={profileShown}
                                        showDate={showDate}
                                        showDateforoutgoing={
                                          showDateforoutgoing
                                        }
                                        item={item}
                                        setMessages={setSelectedMessages}
                                        messages={selectedMessages}
                                        setChangeFooter={
                                          setIsChangeMessageFooter
                                        }
                                        setData={setData}
                                        setSelectedHeading={setSelectedHeading}
                                        setSelectedHeadingId={
                                          setSelectedHeadingId
                                        }
                                        setShowHeadingModal={
                                          setShowHeadingModal
                                        }
                                        setShowCheckbox={
                                          setShowMessageCheckboxes
                                        }
                                        showCheckbox={showMessageCheckboxes}
                                        setReplyFooterShow={setReplyFooterShow}
                                        setReplyMessage={setReplyMessage}
                                        setMessageInfoItem={setMessageInfoItem}
                                        setMessageInfoDrawerShow={
                                          setMessageInfoDrawerShow
                                        }
                                        setShowMediaPreview={
                                          setShowMediaPreview
                                        }
                                        setSelectedMedia={setSelectedMedia}
                                      />
                                    </>
                                  )}

                                  {(item?.type === "addedUser" ||
                                    item?.type === "createdRoom" ||
                                    item?.type === "changedName" ||
                                    item?.type === "leftRoom" ||
                                    item?.type === "changedPicture" ||
                                    item?.type === "changedDescription" ||
                                    item?.type === "chatDisappear" ||
                                    item?.type === "removedUser" ||
                                    item?.type === "audioCall" ||
                                    item?.type === "videoCall" ||
                                    item?.type === "invited" ||
                                    item?.type === "declined" ||
                                    item?.type === "taskAssigned") && (
                                    <SystemMessage
                                      item={item}
                                      messageArray={messageArray}
                                      setMessageArray={setMessageArray}
                                    />
                                  )}

                                  {(item?.type === "TEXT" ||
                                    item?.type === "PDF" ||
                                    item?.type === "DOC" ||
                                    item?.type === "DOCX" ||
                                    item?.type === "ZIP" ||
                                    item?.type === "XLS" ||
                                    item?.type === "XLSX" ||
                                    item?.type === "PPT" ||
                                    item?.type === "PPTX" ||
                                    item?.type === "APPLICATION" ||
                                    item?.type === "DOCUMENT") && (
                                    <DocumentFilesPreview
                                      profileShown={profileShown}
                                      showDate={showDate}
                                      showDateforoutgoing={showDateforoutgoing}
                                      item={item}
                                      setData={setData}
                                      setSelectedHeading={setSelectedHeading}
                                      setSelectedHeadingId={
                                        setSelectedHeadingId
                                      }
                                      setShowHeadingModal={setShowHeadingModal}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setReplyFooterShow={setReplyFooterShow}
                                      setReplyMessage={setReplyMessage}
                                      setMessageInfoItem={setMessageInfoItem}
                                      setMessageInfoDrawerShow={
                                        setMessageInfoDrawerShow
                                      }
                                    />
                                  )}

                                  {item?.type === "AUDIO" && (
                                    <AudioFilePreview
                                      profileShown={profileShown}
                                      showDate={showDate}
                                      showDateforoutgoing={showDateforoutgoing}
                                      item={item}
                                      setData={setData}
                                      setSelectedHeading={setSelectedHeading}
                                      setSelectedHeadingId={
                                        setSelectedHeadingId
                                      }
                                      setShowHeadingModal={setShowHeadingModal}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setReplyFooterShow={setReplyFooterShow}
                                      setReplyMessage={setReplyMessage}
                                      setMessageInfoItem={setMessageInfoItem}
                                      setMessageInfoDrawerShow={
                                        setMessageInfoDrawerShow
                                      }
                                    />
                                  )}

                                  {item?.type === "VIDEO" && (
                                    <VideoFilePreview
                                      profileShown={profileShown}
                                      showDate={showDate}
                                      showDateforoutgoing={showDateforoutgoing}
                                      item={item}
                                      setData={setData}
                                      setSelectedHeading={setSelectedHeading}
                                      setSelectedHeadingId={
                                        setSelectedHeadingId
                                      }
                                      setShowHeadingModal={setShowHeadingModal}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setReplyFooterShow={setReplyFooterShow}
                                      setReplyMessage={setReplyMessage}
                                      setMessageInfoItem={setMessageInfoItem}
                                      setMessageInfoDrawerShow={
                                        setMessageInfoDrawerShow
                                      }
                                    />
                                  )}

                                  {item?.type === "contact" && (
                                    <ContactFilePreview
                                      profileShown={profileShown}
                                      showDate={showDate}
                                      showDateforoutgoing={showDateforoutgoing}
                                      item={item}
                                      setMessages={setSelectedMessages}
                                      messages={selectedMessages}
                                      setChangeFooter={setIsChangeMessageFooter}
                                      setShowCheckbox={setShowMessageCheckboxes}
                                      showCheckbox={showMessageCheckboxes}
                                      setReplyFooterShow={setReplyFooterShow}
                                      setReplyMessage={setReplyMessage}
                                      setMessageInfoItem={setMessageInfoItem}
                                      setMessageInfoDrawerShow={
                                        setMessageInfoDrawerShow
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                      {ele.date && (
                        <div className="textSideBorder">
                          <span
                            className={`px-2 rounded text-center text-xs w-fit flex mx-auto my-2  bg-[#E0FAFF] text-black `}
                            id="date-label"
                          >
                            {moment(ele.date).format("DD.MM.YYYY") ===
                            moment().format("DD.MM.YYYY")
                              ? t("today")
                              : moment(ele.date).format("DD.MM.YYYY") ===
                                moment()
                                  .subtract(1, "days")
                                  .format("DD.MM.YYYY")
                              ? t("yesterday")
                              : moment(ele.date).format("DD.MM.YYYY")}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}

                {bottomLoading && (
                  <div className=" flex justify-center absolute bottom-20 left-0 right-0 w-full">
                    <ReactLoading
                      type="spin"
                      width={30}
                      height={30}
                      color="#33ccff"
                    />
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </>
        )
      ) : (
        ""
      )}

      {showPollDrawer && (
        <PollDetails
          show={showPollDrawer}
          setShow={setShowPollDrawer}
          polls={pollDrawerData}
        />
      )}
    </>
  );
}
