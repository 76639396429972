import { useTranslation } from "react-i18next";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const viewOptions = [
    {
        label: "Month",
        value: "dayGridMonth"
    },
    {
        label: "Week",
        value: "timeGridWeek"
    },
    {
        label: "Day",
        value: "timeGridDay"
    },
    // {
    //     label: "Year",
    //     value: "multiMonthYear"
    // }
]

function CalendarViewMenu(props: any) {
    const { t } = useTranslation()
    const { calendarApi, setViewMode, viewMode } = props

    return (
        <FormControl>
            <RadioGroup
                value={viewMode}
                name="controlled-radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row", }}
                onChange={(e) => {
                    setViewMode(e.target.value)
                    calendarApi()?.changeView(e.target.value);
                }}
            >
                {viewOptions.map((item, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            value={item?.value}
                            control={<Radio size="small" />}
                            label={
                                <div className="font-medium text-sm">
                                    {t(item?.label)}
                                </div>
                            }
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}

export default CalendarViewMenu