import moment from "moment";
import { DefaultImageUrl } from "../../Constant/Constant";
import { Avatar, Drawer } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArticleIcon from "@mui/icons-material/Article";
import ReadIcon from "../../Assets/Images/ReadIcon.png";
import { useSelector } from "react-redux";
import DeliverdIcon from "../../Assets/Images/DeliverdIcon.png";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export default function InfoDrawerContainer({ messageInfoDrawerShow, setMessageInfoDrawerShow, messageInfoItem, contactMessage }) {
  const { userData, formattedParticipantsOfActiveRoom, formattedContact } = useSelector((state: any) => state.user);
  const { activeRoomData } = useSelector((state: any) => state.chat)
  const { t } = useTranslation()

  const FormattedMessage = () => {
    const regex =
      /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

      const formattedText = messageInfoItem?.message?.replace(
        regex,
        (
          match: any,
          p1: any,
          p2: any,
          p3: any,
          p4: any,
          p5: any,
          p6: any,
          p7: any,
          p8: any
        ) => {
          if (p1) {
            return (
              '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
              p1 +
              "</span>"
            );
          } else if (p2) {
            return (
              '<span style="font-weight: 800;color:black; margin-left: 5px; margin-right: 5px;">' +
              p2 +
              "</span>"
            );
          } else if (p3) {
            return (
              '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
              p3 +
              "</span>"
            );
          } else if (p4) {
            return (
              '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
              p4 +
              "</span>"
            );
          } else if (p5) {
            return `<div style="">` + p5 + "</div>";
          } else if (p6) {
            return (
              `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
              p6 +
              "</a>"
            );
          } else if (p8) {
            const participant = activeRoomData?.participants?.find(
              (part) => part?.user_id == p8
            );
            const self = userData?._id == participant?.user_id;
            const formatPart = formattedContact?.[participant?.user_id];
            const mentionedName = self
              ? `${t("you")} `
              : formatPart?.firstName
              ? formatPart?.firstName + " " + formatPart?.lastName
              : participant
              ? participant?.phone
              : p7;
            return (
              '<span style="color: green;">' + "@" + mentionedName + "</span>"
            );
          }
  
          return match;
        }
      );
      return formattedText;
  }

  return (
    <>
      <Drawer
        anchor={"right"}
        open={messageInfoDrawerShow}
        onClose={() => setMessageInfoDrawerShow(false)}
      >
        <div className="w-[400px]">
          <div className="bg-gray-200 p-4">
            <span
              className="cursor-pointer"
              onClick={() => setMessageInfoDrawerShow(false)}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
            </span>
            <span className="ml-1 text-lg"> {t("message_info")} </span>
          </div>
          <div className="mt-3 px-4">
            <div className="textGray textSideBorder">
              {moment(messageInfoItem?.created_at).format("DD.MM.YYYY")}
            </div>
            <div className="mt-4 flex justify-end gap-3">
              <span className="text-sm textGray">
                {moment(messageInfoItem?.created_at).format("HH:mm")}
              </span>

              {messageInfoItem?.type === "text" && (
                <span className="bg-[#e0faff] rounded-md p-2">
                  {/* {messageInfoItem?.message} */}
                  {parse(FormattedMessage())}
                </span>
              )}

              {messageInfoItem?.type === "IMAGE" && (
                <div>
                  <img
                    src={DefaultImageUrl + messageInfoItem?.fileURL}
                    alt="asas"
                    className="h-[200px] w-[200px] rounded-md"
                  />
                </div>
              )}

              {messageInfoItem?.type !== "text" &&
                messageInfoItem?.type !== "IMAGE" &&
                messageInfoItem?.type !== "contact" && (
                  <div className="flex gap-2">
                    <div>
                      <ArticleIcon color="disabled" />
                    </div>
                    <div
                      className="textGray"
                      
                    >
                      {messageInfoItem?.fileURL}
                    </div>
                  </div>
                )}

              {messageInfoItem?.type === "contact" && (
                <div className="rounded-md min-h-[130px] w-[300px] bg-primary text-white">
                  <div className="px-4 py-4 flex gap-3 items-center border-b">
                    <div className="h-[45px] w-[45px] min-w-[45px]">
                      <img
                        src={DefaultImageUrl + contactMessage?.profile_img}
                        alt=""
                        className="rounded-full h-full w-full"
                      />
                    </div>
                    <div className="text-lg font-semibold">
                      <span> {contactMessage?.firstName} </span>
                      <span className="ml-1"> {contactMessage?.lastName} </span>
                      <div className="text-base font-normal">
                        {contactMessage?.phone}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6 bg-[#DCF6FF] py-2 px-4 flex items-center gap-2">
            <span>
              <img src={ReadIcon} alt="read" className="h-[12px]" />
            </span>
            <span>{t("read")}</span>
          </div>
          {messageInfoItem?.read_by?.length > 0 ? (
            messageInfoItem?.read_by?.map((item: any, index: number) => {
              const userInfo = activeRoomData?.participants.find((el: any) => el.user_id === item?.user_id);
              const userContactDetail = formattedContact[item?.user_id];
              {console.log(item)}
              return (
                <div className="mt-3 px-4 flex gap-3" key={index}>
                  <div>
                    {userInfo?.profile_img !== "" ? (
                      <img
                        src={DefaultImageUrl + userInfo?.profile_img}
                        alt="profile"
                        className="h-[40px] w-[40px] rounded-full"
                      />
                    ) : (
                      <Avatar sx={{ height: 40, width: 40 }} />
                    )}
                  </div>
                  <div>
                    {userContactDetail &&
                      (userContactDetail?.firstName !== "" ||
                        userContactDetail?.lastName !== "") ? (
                      <div>
                        <span className="mr-1">
                          {userContactDetail?.firstName}
                        </span>
                        <span>{userContactDetail?.lastName}</span>
                      </div>
                    ) : (
                      <div>
                        <span className="mr-1">{userInfo?.phone}</span>
                      </div>
                    )}
                    <div className="text-sm textGray">
                      {moment(item?.read_at).format("DD.MM.YYYY, HH:mm")}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-3 px-4">__</div>
          )}
        </div>

        <div className="mt-6 bg-[#DCF6FF] py-2 px-4 flex items-center gap-2">
          <span>
            <img src={DeliverdIcon} alt="read" className="h-[12px]" />
          </span>
          <span>{t("delivered")}</span>
        </div>
        {messageInfoItem?.delivered_to?.length > 0 ? (
          messageInfoItem?.delivered_to?.map((item: any, index: number) => {
            const userInfo = activeRoomData?.participants.find((el: any) => el.user_id === item?.user_id);
            const userContactDetail = formattedContact[item?.user_id];
      
            return (
              <div className="mt-3 px-4 flex gap-3" key={index}>
                <div>
                  {userInfo?.profile_img !== "" ? (
                    <img
                      src={DefaultImageUrl + userInfo?.profile_img}
                      alt="profile"
                      className="h-[40px] w-[40px] rounded-full"
                    />
                  ) : (
                    <Avatar sx={{ height: 40, width: 40 }} />
                  )}
                </div>
                <div>
                  {userContactDetail &&
                    (userContactDetail?.firstName !== "" ||
                      userContactDetail?.lastName !== "") ? (
                    <div>
                      <span className="mr-1">
                        {userContactDetail?.firstName}
                      </span>
                      <span>{userContactDetail?.lastName}</span>
                    </div>
                  ) : (
                    <div>
                      <span className="mr-1">{userInfo?.phone}</span>
                    </div>
                  )}
                  <div className="text-sm textGray">
                    
                    {moment(item?.delivered_at).format("DD.MM.YYYY, HH:mm")}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt-3 px-4">__</div>
        )}
      </Drawer>
    </>
  )
}