import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { ReportTasksDataTypes } from "../../Types/Types";
import { DefaultImageUrl, googleMapCapture } from "../../Constant/Constant";
import DocumentIcon from "../../Assets/Images/DocumentFileIcon.png";
import moment from "moment";
import { useEffect, useState } from "react";
import SingleImagePreview from "../../Components/Gallery/SignleImagePreview";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { setTaskData } from "../../Redux/OrganizationSlice";
const ReportPage = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isReportsLoading, currentReport } = useSelector(
    (state: any) => state.organization
  );
  const { userData } = useSelector((state: any) => state.user);
  useEffect(() => {
    dispatch(setTaskData(false));
  }, []);
  const [visible, setVisible] = useState(false);
  return isReportsLoading ? (
    <div className="h-[100vh] flex justify-center items-center">
      <ReactLoading
        type="spinningBubbles"
        color="#33ccff"
        height={100}
        width={100}
      />
    </div>
  ) : (
    <div className="bg-white w-full h-[100vh] text-[#000000b8]">
      <SingleImagePreview
        show={openImage}
        setShow={setOpenImage}
        imgSrc={imageSrc}
      />

      <div className="bg-white py-2 px-4 border-b h-[80px]">
        <div className="text-gray-700 text-[22px]">
          {currentReport?.assignment?.scenario?.name}
        </div>
        {currentReport?.assignment?.scenario?.description && (
          <div className="textGray">
            {currentReport?.assignment?.scenario?.description}
          </div>
        )}
      </div>

      <div className="my-6 pb-6 mx-8 h-[80vh] overflow-y-auto">
        {currentReport?.tasksData?.length > 0 ? (
          <Stepper orientation="vertical" className="max-w-[70%]">
            {currentReport?.tasksData?.map(
              (item: ReportTasksDataTypes, index: number) => {
                const executedTime = moment(item?.taskCompleteTime).format(
                  "DD MMMM YYYY HH:mm"
                );
                const geolocation =
                  item.lat && item.long
                    ? { lat: item.lat, lng: item.long }
                    : null;
                const src =
                  item.lat !== null &&
                  item.long !== null &&
                  item.lat !== "" &&
                  item.long !== ""
                    ? googleMapCapture({
                        marker: geolocation,
                        zoom: 18,
                        size: {
                          width: 900,
                          height: 700,
                        },
                      })
                    : "";
                return (
                  <Step active key={index}>
                    <StepLabel>
                      <div>{item?.label}</div>
                    </StepLabel>
                    <StepContent>
                      <div>{item?.content}</div>
                      {item?.memberId?.user?._id !== userData?._id ? (
                        <div>
                          {item?.memberId?.user?.firstName !== ""
                            ? item?.memberId?.user?.firstName +
                              " " +
                              item?.memberId?.user?.lastName
                            : item?.memberId?.user?.phone}
                        </div>
                      ) : (
                        <div>{"You"}</div>
                      )}
                      <div className="flex">
                        <div className="mt-2 bg-[#E0FAFF] p-3 pb-0 rounded-md">
                          <div className="mb-2 ">
                            {item?.result && (
                              <div className="border-b mb-3">
                                {item?.result}
                              </div>
                            )}
                            {item?.resultAttachment?.type === "IMAGE" && (
                              <div
                                className="h-[200px] w-[200px] min-w-[200px] cursor-pointer"
                                onClick={() => {
                                  setImageSrc(
                                    DefaultImageUrl +
                                      item?.resultAttachment?.filename
                                  );
                                  setOpenImage(true);
                                }}
                              >
                                <img
                                  src={
                                    DefaultImageUrl +
                                    item?.resultAttachment?.filename
                                  }
                                  alt=""
                                  className="h-full w-full rounded-md object-contain"
                                />
                              </div>
                            )}

                            {item?.resultAttachment?.type === "VIDEO" && (
                              <div className="w-[200px] min-w-[200px]">
                                <video
                                  src={
                                    DefaultImageUrl +
                                    item?.resultAttachment?.filename
                                  }
                                  className="w-full h-full"
                                  controls
                                />
                              </div>
                            )}

                            {(item?.resultAttachment?.type === "APPLICATION" ||
                              item?.resultAttachment?.type === "DOCUMENT") && (
                              <div className="flex items-center gap-3">
                                <img
                                  src={DocumentIcon}
                                  alt=""
                                  className="h-[30px] w-[30px]"
                                />
                                <div
                                  style={{ overflowWrap: "anywhere" }}
                                  className="text-sm"
                                >
                                  {item?.resultAttachment?.filename
                                    .split("/")
                                    .pop()}
                                </div>
                              </div>
                            )}

                            {item?.resultAttachment?.type === "AUDIO" && (
                              <div className="flex items-center gap-3">
                                <audio
                                  src={
                                    DefaultImageUrl +
                                    item?.resultAttachment?.filename
                                  }
                                  controls
                                ></audio>
                              </div>
                            )}

                            {item?.signatureAttachment !== "" && (
                              <div
                                className="h-[80px]  my-2 w-full cursor-pointer"
                                onClick={() => {
                                  setImageSrc(
                                    `data:image/png;base64,${item?.signatureAttachment}`
                                  );
                                  setOpenImage(true);
                                }}
                              >
                                <img
                                  src={`data:image/png;base64,${item?.signatureAttachment}`}
                                  className="h-full bg-white  w-full rounded-md object-contain"
                                />
                              </div>
                            )}
                          </div>
                          {item.lat !== null && item.long !== null &&item.lat !== "" && item.long !== "" && (
                            <div >
                              {t("approx-distance")}:{" "}
                              {(item.distance * 1609.344).toFixed(2)}
                              {item.distanceUnit}
                            </div>
                          )}
                          {item?.resultAttachment?.type && (
                            <hr className="text-gray-500 my-1 bg-gray-500 " />
                          )}
                          <div className="text-sm mb-2">
                            {t("executed_at")} {executedTime}
                          </div>
                        </div>
                        {src !== "" && (
                          <Image
                            // onClick={clickme}
                            width={"100px"}
                            style={{ borderRadius: "20px", paddingLeft: "8px" }}
                            height={"80px"}
                            src={src}
                            alt="map"
                          />
                        )}
                        <div>
                          <Image
                            width={0}
                            src={src}
                            alt="map"
                            style={{ display: "none" }}
                            preview={{
                              visible,
                              src: src!,
                              onVisibleChange: setVisible,
                            }}
                          />
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              }
            )}
          </Stepper>
        ) : (
          <div className="text-2xl">{t("no_task_completed")}</div>
        )}
      </div>
    </div>
  );
};

export default ReportPage;
