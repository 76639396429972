import { Snackbar } from "@mui/material";
import { ToastComponentTypes } from "../Types/Types";
import { useEffect } from "react";

const Toast = ({ open, message, setShow }: ToastComponentTypes) => {
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 2000);
  }, [open, setShow]);

  return (
    <Snackbar
      open={open}
      message={message}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      ContentProps={{
        sx: {
          background: "#F3F9FC",
          color: "black",
        },
      }}
    />
  );
};

export default Toast;
