import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface TaskRestartButtonPropTypes {
  loading: boolean;
  currentTask: any;
  handleCompleteReport: () => void;
}

const TaskRestartButton = ({
  loading,
  currentTask,
  handleCompleteReport,
}: TaskRestartButtonPropTypes) => {
  const {t}=useTranslation();

  return (
    !currentTask?.edges?.length && (
      <div className="text-center">
        <button
          className="bg-[#33ccff] outline-none py-2 px-5 rounded-md w-full 
              text-white"
          onClick={handleCompleteReport}
          disabled={loading ? true : false}
        >
          {loading ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            t("complete_task")
          )}
        </button>
      </div>
    )
  );
};

export default TaskRestartButton;
