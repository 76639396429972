import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffIcon from "@mui/icons-material/MicOff";

export default function CallAudioScreen({ totalJoinedUsers, muteStatusRef }) {
    const { activeCallData } = useSelector((state: any) => state.chat);
    const { userData, formattedContact } = useSelector((state: any) => state.user);

    return (
        <>
            {Object.keys(totalJoinedUsers)?.length > 0 &&
                Object.keys(totalJoinedUsers).map((item, index) => {
                    const filterData=activeCallData?.data?.callParticipants.filter(
                        (ele) => ele?.uid == item.toString().slice(0, 6)
                    );
                    let temp =filterData.length>0&&filterData[0]?.userId;
                    let name =
                        JSON.stringify(formattedContact) === "{}"
                            ? temp?.phone
                            : formattedContact[temp?._id]?.firstName !==
                                undefined
                                ? formattedContact[temp?._id]?.firstName +
                                " " +
                                formattedContact[temp?._id]?.lastName
                                : temp?.phone;
                    let profilePhoto =
                        DefaultImageUrl + temp?.profile_img;
                       
                       return (
                            <div
                                key={item}
                                id="remote-playerlist"
                                className={`border border-white bg-[#b4e8ff] bg-condition ${temp?.profile_img?.includes("default")
                                    ? ""
                                    : "bg-cover"
                                    }`}
                                style={{
                                    overflowY:
                                        Object.keys(totalJoinedUsers).length > 4
                                            ? "scroll"
                                            : "hidden",
                                    height:
                                        activeCallData?.data?.roomType ===
                                            "group" ||
                                            activeCallData?.data?.roomType ===
                                            "contact_group"||activeCallData?.data?.roomType ===
                                            "individual"
                                            ?Object.keys(totalJoinedUsers).length<=2?"100vh": "40vh"
                                            : "100%",
                                }}
                            >
                                <div className="relative">
                                    <div
                                        id={`player-wrapper-${item}`}
                                        key={index}
                                        className={`bg-no-repeat bg-center ${temp?.profile_img?.includes("default")
                                            ? ""
                                            : ""
                                            }`}
                                        style={{
                                            backgroundImage: `url('${profilePhoto}')`,
                                            backgroundSize:Object.keys(totalJoinedUsers).length<=2?"":"20%"
                                        }}
                                    >
                                        <div
                                            id={`player-${item}`}
                                            className="player "
                                            style={{
                                                height: Object.keys(totalJoinedUsers).length<=2?"83vh":"40vh",
                                            }}
                                        >
                                            <div className="absolute bg-[#f4f4f4] flex top-[7px] left-[0px] px-2 py-1 rounded-tr-md rounded-br-md">
                                                <div className="flex ">
                                                    <div
                                                        id={`remote-audio-mute-${item}`}
                                                        className=""
                                                        // style={{ display: "none" }}
                                                        style={{ display: muteStatusRef?.current?.[item] ? "block" : "none" }}
                                                    >
                                                        <MicOffIcon
                                                            className="mic-icon"
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        id={`remote-audio-unmute-${item}`}
                                                        className=""
                                                        // style={{
                                                        //     display: "block",
                                                        // }}
                                                        style={{ display: !muteStatusRef?.current?.[item] ? "block" : "none" }}
                                                    >
                                                        <MicNoneIcon
                                                            className="mic-icon"
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        <h4 className={`text-black `}>
                                                            {name}
                                                        </h4>
                                                    }
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    );
                })}
        </>
    )
}