import { Backdrop, IconButton } from "@mui/material";
import { DefaultImageUrl } from "../../../Constant/Constant";
import CloseIcon from "../../../Assets/Images/closeBorder.png";
import { useEffect } from "react";

interface IFilePreview {
  filePreview: {
    show: boolean;
    file: any;
  };
  setFilePreview: (params: { show: boolean; file: any }) => void;
}

const FilePreview = ({ filePreview, setFilePreview }: IFilePreview) => {
  const { show, file } = filePreview || {};
  const source = DefaultImageUrl + file?.url;

  const handleClose = () => {
    setFilePreview({ file: null, show: false });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === "Escape") {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, []);

  return (
    <Backdrop open={show} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <div className="absolute top-[20px] right-[30px]">
        <IconButton onClick={handleClose}>
          <img src={CloseIcon} alt="" className="h-[30px] w-[30px]" />
        </IconButton>
      </div>

      <div className="flex justify-center items-center">
        {(file?.type == "PHOTO" || file?.type == "IMAGE") && (
          <div className="h-full max-w-[80%]">
            <img
              src={source}
              alt={file?.name}
              className="w-full h-full max-h-[700px] rounded-lg"
            />
          </div>
        )}

        {file?.type == "AUDIO" && <audio controls src={source} />}

        {file?.type == "VIDEO" && (
          <div className="max-h-[90%] h-full max-w-[80%]">
            <video controls autoPlay src={source}></video>
          </div>
        )}
      </div>
    </Backdrop>
  );
};

export default FilePreview;
