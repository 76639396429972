import ArticleIcon from "@mui/icons-material/Article";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarIcon from "@mui/icons-material/Star";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Avatar, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsHeadphones } from "react-icons/bs";
import { IoIosShareAlt } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import AudioIcon from "../../Assets/Images/AudioIcon.png";
import FileIcon from "../../Assets/Images/FileIcon2.png";
import camera from "../../Assets/Images/camera.png";
import { DefaultImageUrl, MessageActions } from "../../Constant/Constant";
import { GET_CHATS_BY_INDEX_ID } from "../../Graphql/Queries";
import { refreshTokenCllient } from "../../Graphql/authClient";
import {
  appendOldChats,
  appendPreviousChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setCurrentPage,
  setFocusedData,
} from "../../Redux/ChatSlice";
import { emit, socketListen } from "../../Socket";
import { MessagesActionDataTypes } from "../../Types/Types";
import Toast from "../../coreComponent/Toast";
import { getSession } from "../../utils/session";
import MessageInfo from "./MessageInfo";
import dayjs from "dayjs";
import messageSenderInfo from "../../utils/messageSenderInfo";
const TextMessagePreview = ({
  showDate,
  searchText,
  item,
  setData,
  setSelectedHeading,
  setSelectedHeadingId,
  setShowHeadingModal,
  profileShown,
  setMessages,
  messages,
  setChangeFooter,
  setShowCheckbox,
  showCheckbox,
  textref,
  setRef,
  setReplyFooterShow,
  setReplyMessage,
  setMessageInfoItem,
  setMessageInfoDrawerShow,
  showDateforoutgoing,
}: any) => {
  const myId = useSelector((state: any) => state.user.userData?._id);
  const { topicsData } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const anchorRef = useRef<HTMLDivElement>(null);
  const [showArrow, setshowArrow] = useState({ status: false, id: null });
  const audioRef = useRef<any>(null);
  const [duration, setDuration] = useState(0);
  const MessageAction = MessageActions(t);
  const [showMessageActions, setShowMessageActions] = useState({
    status: false,
    id: "",
  });
  const [activeFav, setActiveFav] = useState<any>({});
  const {
    activeChatsLimit,
    formattedParticipantsOfActiveRoom,
    activeRoomChats,
    activeRoomData,
    pinnedChats,
  } = useSelector((state: any) => state.chat);

  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  const { userData, comonUsers, userContacts, formattedContact } = useSelector(
    (state: any) => state.user
  );

  const messageSender = messageSenderInfo(item.sender, activeRoomData?.participants)

  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userData?._id
    );
  const isRoomLeft = userProfileImg?.left_at !== 0;
  const deleted =
    item?.deleted?.filter((element: any) => element.user_id === userData?._id)
      ?.length > 0
      ? false
      : true;
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const replyMessage =
    item?.reply_msg !== "" &&
    item?.reply_msg !== undefined &&
    item?.reply_msg?.type === "contact" &&
    item?.reply_msg?.type !== undefined &&
    item?.reply_msg?.message !== ""
      ? JSON.parse(item?.reply_msg?.message)
      : item.message;
  const userDetails = userContacts?.find(
    (item: any) => item?.userId?._id === senderInfo?.user_id
  );

  const isEveryoneCanPinMessage =
    activeRoomData?.type === "group" &&
    activeRoomData?.access?.some(
      (item: any) => item?.type === "pinMessage" && item?.permit === "common"
    );

  const canYouPinMessage =
    (!isEveryoneCanPinMessage &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
        "admin") ||
    isEveryoneCanPinMessage;

  const isUserBlocked = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );

  let messageTime =
    item?.created_at !== null
      ? moment(item?.created_at).format("HH:mm")
      : moment(new Date()).format("HH:mm");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function validURL(str: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function renderText(message: string, isLast: boolean, customStyle = {}) {
    return (
      <span
        style={{
          color: "black",
          fontFamily: "Lato",
          fontWeight: "normal",
          letterSpacing: "0.3px",
          ...customStyle,
        }}
      >
        {highlightText(message)}
        {isLast ? "" : " "}
      </span>
    );
  }

  function otherTypeOfText(message: string, isLast: boolean) {
    if (validURL(message)) {
      return <a href={message}>{renderText(message, isLast)}</a>;
    } else if (message.startsWith("```") && message.endsWith("```")) {
      return renderText(message.substring(3, message.length - 3), isLast, {
        fontFamily: "space-mono",
      });
    } else if (message.startsWith("~") && message.endsWith("~")) {
      return renderText(message.substring(1, message.length - 1), isLast, {
        textDecorationLine: "line-through",
      });
    } else if (message.startsWith("_") && message.endsWith("_")) {
      return renderText(message.substring(1, message.length - 1), isLast, {
        fontStyle: "italic",
      });
    } else if (message.startsWith("*") && message.endsWith("*")) {
      return renderText(message.substring(1, message.length - 1), isLast, {
        fontWeight: "bold",
      });
    } else if (message.includes("\n")) {
      return renderText(message, isLast, {
        whiteSpace: "pre-line",
      });
    } else {
      return renderText(message, isLast);
    }
  }

  function replaceParticipantIdWithThereName(message: any) {
    let newText = message.split(" ");

    const result: any = [];

    newText.forEach((one: any, index: any) => {
      const isLast = index === newText.length - 1;
      if (one[0] === "@") {
        const uId = one.substring(1);
        if (formattedParticipantsOfActiveRoom[uId]) {
          result.push(
            <span
              style={{
                backgroundColor: item?.sender === myId ? "blue" : "red",
                padding: 4,
                borderRadius: 3,
                marginRight: isLast ? 0 : 2,
                marginBottom: 2,
              }}
            >
              <span
                style={{
                  color: item?.sender === myId ? "red" : "blue",
                }}
              >
                {highlightText(
                  `@${formattedParticipantsOfActiveRoom[uId].firstName} ${formattedParticipantsOfActiveRoom[uId].lastName}`
                )}
              </span>
            </span>
          );
        } else {
          result.push(otherTypeOfText(one, isLast));
        }
      } else {
        result.push(otherTypeOfText(message, isLast));
      }
    });

    return (
      <div style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {otherTypeOfText(message, true)}
      </div>
    );
  }

  function highlightText(message: string) {
    if (searchText?.length < 1) {
      return message;
    } else {
      const searchIndex = message
        .toLocaleLowerCase()
        .search(searchText.toLocaleLowerCase());
      if (searchIndex === -1) {
        return message;
      } else {
        const first = message.substring(0, searchIndex);
        const middle = message.substring(
          searchIndex,
          searchIndex + searchText.length
        );
        const last = message.substring(searchIndex + searchText.length);
        return (
          <div>
            {first}
            <span style={{ backgroundColor: "rgba(17, 90, 187, 0.3)" }}>
              {middle}
            </span>
            {last}
          </div>
        );
      }
    }
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowMessageActions({ status: false, id: "" });
    } else if (event.key === "Escape") {
      setShowMessageActions({ status: false, id: "" });
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    /* setshowArrow({ status: false, id: null });*/
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  const handleMessageAction = (action: string, item: any) => {
    setShowMessageActions({ status: false, id: "" });
    if (action === "forward" || action === "delete") {
      handleMessageSelect(true, item);
      setChangeFooter(true);
      setShowCheckbox(true);
    } else {
      setChangeFooter(false);
      setShowCheckbox(false);
    }
    if (action === "heading") {
      setData(topicsData);
      setSelectedHeading(item?.message);
      setSelectedHeadingId(item?._id);
      setShowHeadingModal(true);
    }

    if (action === "favourite") {
      emit("addChatsToFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    } else if (action === "unFavourite") {
      emit("removeChatsFromFavourite", {
        roomId: activeRoomData?._id,
        cid: [item?._id],
      });
    }

    if (action === "reply") {
      setTimeout(()=>{
        document.getElementById("message-input").focus()
      },0)
      setReplyMessage(item);
      setReplyFooterShow(true);
    }

    if (action === "pin") {
      if (canYouPinMessage) {
        if (pinnedChats?.length < 3) {
          emit("pinChat", { roomId: activeRoomData?._id, cid: [item?._id] });

          socketListen("message", (data: any) => {
            if (data.type === "pinChat") {
              setToastShow(true);
              setToastMessage(t("chat_pinned"));
            }
          });
          socketListen("error", (data: any) => {
            console.log("error message = ", data);
            setToastMessage(t("chat_pin_validation"));
            setToastShow(true);
          });
        } else {
          setToastMessage(t("chat_pin_validation"));
          setToastShow(true);
        }
      } else {
        setToastMessage(t("chat_pin_admin"));
        setToastShow(true);
      }
    }

    if (action === "info" && item?.sender === userData?._id) {
      setMessageInfoItem(item);
      setMessageInfoDrawerShow(true);
    }

    if (action === "copy") {
      navigator.clipboard.writeText(item?.message);
      setToastShow(true);
      setToastMessage(t("copy_success"));
    }
  };

  const handleMessageSelect = (e: any, item: any) => {
    let temp = [...messages.msgId];
    let tempMsgId = [...messages.senderId];
    let tempTime = [...messages.time];

    if (e) {
      temp.push(item?._id);
      tempMsgId.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      temp = temp.filter((elem: any) => elem !== item?._id);
      tempMsgId = tempMsgId.filter((elem: any) => elem !== item?.sender);
      tempTime = tempTime.filter((elem: any) => elem !== item?.created_at);
    }
    setMessages({ msgId: temp, senderId: tempMsgId, time: tempTime });
  };

  const FormattedMessage = (message) => {
    const regex =
      /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

    const formattedText = message?.replace(
      regex,
      (
        match: any,
        p1: any,
        p2: any,
        p3: any,
        p4: any,
        p5: any,
        p6: any,
        p7: any,
        p8: any
      ) => {
        if (p1) {
          return (
            '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
            p1 +
            "</span>"
          );
        } else if (p2) {
          return (
            '<span style="font-weight: 800;color:black; margin-left: 5px; margin-right: 5px;">' +
            p2 +
            "</span>"
          );
        } else if (p3) {
          return (
            '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
            p3 +
            "</span>"
          );
        } else if (p4) {
          return (
            '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
            p4 +
            "</span>"
          );
        } else if (p5) {
          return `<div style="">` + p5 + "</div>";
        } else if (p6) {
          return (
            `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
            p6 +
            "</a>"
          );
        } else if (p8) {
          const participant = activeRoomData?.participants?.find(
            (part) => part?.user_id == p8
          );
          const self = userData?._id == participant?.user_id;
          const formatPart = formattedContact?.[participant?.user_id];
          const mentionedName = self
            ? `${t("you")} `
            : formatPart?.firstName
            ? formatPart?.firstName + " " + formatPart?.lastName
            : participant
            ? participant?.phone
            : p7;
          return (
            '<span style="color: green;">' + "@" + mentionedName + "</span>"
          );
        }

        return match;
      }
    );
    return formattedText;
  };
  const handleRedirectOnMessage = (message: any) => {
    const totalChats = activeRoomData.totalChats;
    const messagenew = activeRoomChats.find(
      (el: any) => el._id === message?.cid
    );

    const skip = totalChats - Number(message.index) - 25;

    const value = skip < 0 ? 0 : skip;
    const max = Number(message.index) + 25 + value;
    console.log(message);
    if (
      Number(message.index) <
        activeRoomChats[activeRoomChats?.length - 1]?.index &&
      Number(message.index) >= activeRoomChats[0]?.index
    ) {
      dispatch(setFocusedData({ status: true, message_id: message?.index }));

      document
        .getElementById(`message-index-${message?.index}`)
        .scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      document.getElementById(
        `message-index-${message?.index}`
      ).style.backgroundColor = "#87d3e2f5";
      document.getElementById(
        `message-index-${message?.index}`
      ).style.paddingBottom = "10px";
      document.getElementById(
        `message-index-${message?.index}`
      ).style.transition = "background-color " + 2 + "s ease-in-out";

      setTimeout(() => {
        document.getElementById(
          `message-index-${message?.index}`
        ).style.backgroundColor = "";
        document.getElementById(
          `message-index-${message?.index}`
        ).style.paddingBottom = "0px";
        document.getElementById(
          `message-index-${message?.index}`
        ).style.transition = "none";
      }, 1600);
    } else {
      const skip =
        activeChatsLimit *
        (Math.floor(Number(message.index) / activeChatsLimit) + 1);
      dispatch(setActiveChatSkip(skip));
      dispatch(
        setCurrentPage(Math.floor(Number(message.index) / activeChatsLimit) + 1)
      );
      //dispatch(setTotalPage(Math.ceil(activeRoomData?.totalChats / activeChatsLimit)))
      const { token } = getSession();
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_INDEX_ID,
          variables: {
            input: {
              roomId: activeRoomData?._id, // roomId
              total: activeRoomData?.totalChats, // total chats
              index: Number(message?.index),
              limit: activeChatsLimit, // custom limit
            },
          },
        })
        .then((res) => {
          dispatch(appendOldChats([]));
          dispatch(setActiveRoomChats([]));
          if (res.data.getChatsByIndex.length > 0) {
            dispatch(appendPreviousChats(res?.data?.getChatsByIndex));
          }
          setTimeout(() => {
            if (
              message.__v !== null &&
              document.getElementById(`message-index-${message?.index}`)
            ) {
              console.log(message, "message*************");
              /*  document
                 .getElementById(`message-index-${message?.index}`)
                 .scrollIntoView({ block: "center", behavior: "auto" }) */
              document
                .getElementById(`message-index-${message?.index}`)
                .scrollIntoView({ block: "center", behavior: "smooth" });
              document.getElementById(
                `message-index-${message?.index}`
              ).style.backgroundColor = "#e0e0e0";
              document.getElementById(
                `message-index-${message?.index}`
              ).style.paddingBottom = "10px";
              document.getElementById(
                `message-index-${message?.index}`
              ).style.transition = "background-color " + 2 + "s ease-in-out";

              setTimeout(() => {
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.backgroundColor = "";
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.paddingBottom = "0px";
                document.getElementById(
                  `message-index-${message?.index}`
                ).style.transition = "none";
                //dispatch(setFocusedData({ status: false, __v:null }))
              }, 2800);
            }
          }, 2000);
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        });

      dispatch(setFocusedData({ status: true, message_id: message?.index }));
    }
  };

  const userObject = activeRoomData?.participants?.find(
    (el: any) => el.user_id === item?.reply_msg?.sender
  );

  return (
    <div>
      {item?.sender === userData?._id ? (
        <>
          <div
            className=" items-center"
            onMouseEnter={() => {
              setshowArrow({ status: true, id: item._id });
              setAnchorEl(null);
            }}
            onMouseLeave={() => {
              setshowArrow({ status: false, id: null });
              setAnchorEl(null);
            }}
          >
            <Toast
              open={toastShow}
              message={toastMessage}
              setShow={setToastShow}
            />

            {showCheckbox && deleted && (
              <div className="h-[20px] w-[20px]">
                <input
                  type="checkbox"
                  name="message"
                  checked={messages?.msgId.includes(item._id)}
                  className="mr-2 cursor-pointer h-[20px] w-[20px]"
                  onChange={(e: any) =>
                    handleMessageSelect(e.target.checked, item)
                  }
                />
              </div>
            )}

            <div
              className={"ml-auto max-w-[80%] w-fit"}
            >
              <div
                className={`flex relative ${showDateforoutgoing ? "mb-1" : ""}`}
              >
                <div className="flex justify-center items-start ">
                  <div
                    className="w-8 h-6"
                    style={{ color: "#33CCFF", fontSize: "1.3rem" }}
                  >
                    {deleted && (
                      <div
                        className=""
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setActiveFav(item);
                        }}
                        ref={anchorRef}
                      >
                        <MoreVertIcon
                          className="cursor-pointer -pr-4"
                          sx={{
                            visibility:
                              showArrow.status && showArrow.id === item?._id
                                ? "visible"
                                : "hidden",
                            color: "#33CCFF",
                            fontSize: "1.8rem",
                          }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {MessageAction.filter(
                            (elem: MessagesActionDataTypes) =>
                              activeRoomData?.type === "individual"
                                ? elem?.action !== "pin"
                                : elem
                          ).map(
                            (el: MessagesActionDataTypes, index: number) => {
                              const pinchat = pinnedChats.filter(
                                (els: any) => els._id === item?._id
                              );

                              return pinchat.length > 0 &&
                                el?.action === "pin" ? (
                                ""
                              ) : activeFav?.favourite_by?.length > 0 &&
                                el?.action === "favourite" ? (
                                ""
                              ) : activeFav?.favourite_by?.length === 0 &&
                                el?.action === "unFavourite" ? (
                                ""
                              ) : isRoomLeft &&
                                (el?.action === "forward" ||
                                  el?.action === "reply" ||
                                  el?.action === "delete") ? (
                                ""
                              ) : activeRoomData?.type == "broadcast" &&
                                el?.action == "heading" ? (
                                ""
                              ) : (
                                <MenuItem
                                  onClick={() => {
                                    if (el.action === "heading") {
                                      setData(topicsData);
                                    }
                                    handleMessageAction(el.action, item);
                                    setshowArrow({ status: false, id: null });
                                    setAnchorEl(null);
                                  }}
                                  key={index}
                                  sx={{ fontSize: "15px" }}
                                >
                                  {el.ui}
                                </MenuItem>
                              );
                            }
                          )}
                        </Menu>
                      </div>
                    )}
                  </div>
                  <div
                    className="relative group flex  text-[15px] w-full pr-3
                    py-[6px] rounded-t-xl rounded-bl-xl bg-[#E0FAFF]"
                    style={{
                      paddingLeft:
                        item?.favourite_by?.length > 0 ? "4px" : "12px",
                    }}
                    onContextMenu={(e) => {
                      if (
                        !item?.message?.includes("You deleted this message")
                      ) {
                        e.preventDefault();
                        setAnchorEl(e.currentTarget)
                        setShowMessageActions({ status: true, id: item._id });
                        setActiveFav(item);
                      }
                    }}
                  >
                    <div className="">
                      {item?.reply_msg?.cid !== null &&
                      item?.reply_msg?.cid !== "null"
                        ? !item?.message.includes("You deleted this message") &&
                          // item?.deleted?.length === 0 &&
                          item?.reply_msg && (
                            <div
                              className="bg-[#b9ecf6] px-2 border-l-4 border-gray-500 text-sm rounded-md textGray cursor-pointer"
                              onClick={() => {
                                handleRedirectOnMessage(item?.reply_msg);
                              }}
                            >
                              <div className="lightBlack pb-1">
                              {item?.reply_msg?.sender === userData?._id
                                ? t("you")
                                : formattedContact?.[item?.reply_msg?.sender]
                                ? formattedContact?.[item?.reply_msg?.sender]
                                    ?.firstName + " " + 
                                  formattedContact?.[item?.reply_msg?.sender]
                                    ?.lastName
                                : userObject?.phone}
                              </div>
                              {item?.reply_msg?.type === "contact" && (
                                <span className={`font-medium textGray`}>
                                  <>
                                    <div className="px-4 py-4 flex gap-3 items-center border-b">
                                      <div className="h-[45px] w-[45px] min-w-[45px]">
                                        <img
                                          src={
                                            DefaultImageUrl +
                                            replyMessage?.profile_img
                                          }
                                          alt=""
                                          className="rounded-full h-full w-full"
                                        />
                                      </div>
                                      <div className="text-lg font-semibold">
                                        <span> {replyMessage?.firstName} </span>
                                        <span className="ml-1">
                                          {" "}
                                          {replyMessage?.lastName}{" "}
                                        </span>
                                        <div className="text-base font-normal">
                                          {replyMessage?.phone}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </span>
                              )}
                              {item?.reply_msg?.type === "AUDIO" && (
                                <span className={`font-medium textGray`}>
                                  <>
                                    <div className=" py-4 flex gap-3 items-center border-b">
                                      <div className="flex items-center gap-2">
                                        <div className="w-full  relative group">
                                          <span className="flex">
                                            {" "}
                                            <BsHeadphones
                                              style={{
                                                color: "#33ccff",
                                                height: 20,
                                                width: 20,
                                              }}
                                              className="me-2"
                                            />
                                            {t("audio_message")}
                                          </span>
                                          <audio
                                            className="hidden"
                                            ref={audioRef}
                                            src={
                                              DefaultImageUrl +
                                              item?.reply_msg?.file_URL
                                            }
                                            controls
                                          ></audio>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-gray-400 ">
                                      {item?.reply_msg?.message !== ""
                                        ? item?.reply_msg?.message?.length > 100
                                          ? item?.reply_msg?.message.slice(
                                              0,
                                              170
                                            ) + "..."
                                          : item?.reply_msg?.message
                                        : ""}
                                    </div>
                                  </>
                                </span>
                              )}
                              {item?.reply_msg?.type === "VIDEO" && (
                                <span className={`font-medium textGray`}>
                                  <>
                                    <div className=" py-4 flex gap-3 items-center border-b">
                                      <div className="flex items-center gap-2">
                                        <div className="w-full  relative group flex">
                                          <video
                                            ref={audioRef}
                                            src={
                                              DefaultImageUrl +
                                              item?.reply_msg?.file_URL
                                            }
                                            className=" w-14 -mt-[16px] ms-1 h-14 object-fill"
                                          ></video>
                                          <span className="flex">
                                            <VideocamIcon
                                              style={{ color: "#33ccff" }}
                                              sx={{ height: 20, width: 20 }}
                                            />
                                            {t("video")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-gray-400 ">
                                      {item?.reply_msg?.message !== ""
                                        ? item?.reply_msg?.message?.length > 100
                                          ? item?.reply_msg?.message.slice(
                                              0,
                                              170
                                            ) + "..."
                                          : item?.reply_msg?.message
                                        : ""}
                                    </div>
                                  </>
                                </span>
                              )}

                              {item?.reply_msg?.type === "text" && (
                                <span className={` font-medium textGray`}>
                                  {parse(
                                    FormattedMessage(
                                      item?.reply_msg?.message?.length > 130
                                        ? item?.reply_msg?.message?.slice(
                                            0,
                                            130
                                          ) + "..."
                                        : item?.reply_msg?.message
                                    )
                                  )}
                                </span>
                              )}

                              {item?.reply_msg?.type === "IMAGE" && (
                                <>
                                  <div className="flex">
                                    <span className="">
                                      <img
                                        src={
                                          DefaultImageUrl +
                                          item?.reply_msg?.file_URL
                                        }
                                        alt="img"
                                        className="h-[35px] w-[35px] rounded-sm pb-1"
                                      />
                                    </span>

                                    <div className="flex items-center">
                                      <span>
                                        <img
                                          src={camera}
                                          className="w-4 ml-2"
                                        />
                                      </span>
                                      <span className="pl-1">{t("photo")}</span>
                                    </div>
                                  </div>
                                  <div className="text-gray-400 ">
                                    {item?.reply_msg?.message !== ""
                                      ? item?.reply_msg?.message?.length > 100
                                        ? item?.reply_msg?.message.slice(
                                            0,
                                            170
                                          ) + "..."
                                        : item?.reply_msg?.message
                                      : ""}
                                  </div>
                                </>
                              )}

                              {item?.reply_msg?.type !== "text" &&
                                item?.reply_msg?.type !== "IMAGE" &&
                                item?.reply_msg?.type !== "contact" &&
                                item?.reply_msg?.type !== "AUDIO" &&
                                item?.reply_msg?.type !== "VIDEO" && (
                                  <>
                                    <div className="flex py-1">
                                      <span className="lightBlack font-medium pr-2">
                                        <ArticleIcon className="primary" />
                                      </span>
                                      <span className="">
                                        {item?.reply_msg?.type !== "IMAGE" &&
                                        item?.reply_msg?.type === "APPLICATION"
                                          ? item?.reply_msg?.file_URL
                                              ?.split(
                                                item?.reply_msg?.sender +
                                                  "/" +
                                                  item?.roomId +
                                                  "/"
                                              )
                                              .pop()
                                          : item?.reply_msg?.file_URL
                                              ?.split(
                                                item?.roomId +
                                                  "/" +
                                                  item?.reply_msg?.sender +
                                                  "/"
                                              )
                                              .pop()}
                                      </span>
                                    </div>
                                    <div className="text-gray-400">
                                      {item?.reply_msg?.message !== ""
                                        ? item?.reply_msg?.message?.length > 100
                                          ? item?.reply_msg?.message.slice(
                                              0,
                                              170
                                            ) + "..."
                                          : item?.reply_msg?.message
                                        : ""}
                                    </div>
                                  </>
                                )}
                            </div>
                          )
                        : ""}

                      <div className={` `}>
                        <div>
                          {
                            <div
                              className={`flex relative h-5 ${
                                item?.isForwarded
                                  ? "justify-between"
                                  : "justify-end"
                              }`}
                            >
                              {item?.isForwarded && (
                                <div className="flex">
                                  <IoIosShareAlt className="mt-1 me-1 ms-2" />
                                  <div className="text-sm">
                                    {t("forwarded")}
                                  </div>
                                </div>
                              )}
                              {showDateforoutgoing && (
                                <div className="textGray ms-4 text-right flex pt-1 ps-2 justify-end text-xs pr-2">
                                  {messageTime}
                                </div>
                              )}
                            </div>
                          }
                          {!deleted ? (
                            <i className=" text-[14px]">
                              {parse(
                                FormattedMessage(
                                  item?.message
                                    .replace(
                                      "You deleted this message",
                                      t("you-message-deleted")
                                    )
                                    .replace(
                                      "You are assigned",
                                      t("you_are_assigned")
                                    )
                                    .replace(
                                      "please accept the invitation to join",
                                      t("accept-invite")
                                    )
                                    .replace(
                                      "to start execution",
                                      t("start-execution")
                                    )
                                    .replace("by", t("by"))
                                )
                              )}
                            </i>
                          ) : (
                            <div
                              style={{
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap",
                              }}
                              className={`  ${
                                item?.message.includes(
                                  "This message was deleted"
                                )
                                  ? "text-[14px]"
                                  : ""
                              } text-[#515151]`}
                            >
                              {" "}
                              {parse(
                                FormattedMessage(
                                  item?.message
                                    .replace(
                                      "You deleted this message",
                                      t("you-message-deleted")
                                    )
                                    .replace(
                                      "You are assigned",
                                      t("you_are_assigned")
                                    )
                                    .replace(
                                      "please accept the invitation to join",
                                      t("accept-invite")
                                    )
                                    .replace(
                                      "to start execution",
                                      t("start-execution")
                                    )
                                    .replace("by", t("by"))
                                )
                              )}
                            </div>
                          )}
                          {item?.favourite_by?.length > 0 && (
                            <StarIcon
                              sx={{ fontSize: 14 }}
                              className="text-black mt-1 me-1"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {item.isSent && item.read_by.length > 0 && item.receipts ? (
                  <></>
                ) : (
                  <div className="flex items-end ">
                    {item.isSent && item.delivered_to.length > 0 ? (
                      <DoneAllIcon
                        className="text-gray-400"
                        sx={{ width: 18, height: 18 }}
                      />
                    ) : (
                      <DoneIcon
                        className="text-gray-400"
                        sx={{ width: 18, height: 18 }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {item?.receipts &&
              activeRoomChats[activeRoomChats.length - 1]?._id === item?._id &&
              item.isSent &&
              item.read_by.length > 0 && (
                <div
                  className={` ml-auto  mb-2 ${
                    activeRoomData?.type === "group" ? "w-12" : "w-fit ml-[5px]"
                  } flex gap-1 items-end self-end justify-end`}
                >
                  {<MessageInfo item={item} />}
                </div>
              )}
          </div>
        </>
      ) : (
        <div
          className="flex w-full"
          onMouseEnter={() => {
            setshowArrow({ status: true, id: item._id });
            setAnchorEl(null);
          }}
          onMouseLeave={() => {
            setshowArrow({ status: false, id: null });
            setAnchorEl(null);
          }}
        >
          <Toast
            open={toastShow}
            message={toastMessage}
            setShow={setToastShow}
          />
          {showCheckbox && deleted && (
            <div className="h-[20px] w-[20px] mr-4">
              <input
                type="checkbox"
                checked={messages?.msgId.includes(item?._id)}
                name="message"
                className="mr-2 cursor-pointer h-[20px] w-[20px]"
                onChange={(e: any) =>
                  handleMessageSelect(e.target.checked, item)
                }
              />
            </div>
          )}
          <div className="flex w-full justify-center items-center mr-auto">
            <div className="flex w-full justify-start items-start">
              <div
                className={"flex max-w-[80%]"}
              >
                <div className="h-[35px] min-w-[35px] max-w-[35px]">
                  {/* {showDate &&
                    (senderInfo?.profile_img !== "" && !isUserBlocked ? (
                      <img
                        src={DefaultImageUrl + senderInfo?.profile_img}
                        alt="asas"
                        className="rounded-full h-full w-full"
                      />
                    ) : (
                      <div>
                        <Avatar sx={{ width: "35px", height: "35px" }} />
                      </div>
                    ))} */}
                    {showDate &&
                    (messageSender?.profile !== "" && !isUserBlocked ? (
                      <img
                        src={DefaultImageUrl + messageSender?.profile}
                        alt="asas"
                        className="rounded-full h-full w-full"
                      />
                    ) : (
                      <div>
                        <Avatar sx={{ width: "35px", height: "35px" }} />
                      </div>
                    ))}
                </div>

                <div
                  className="relative mx-2 pl-3 py-[6px] bg-[#F4F4F4] rounded-tr-xl rounded-b-xl flex group"
                  style={{
                    paddingRight:
                      item?.favourite_by?.length > 0 ? "4px" : "12px",
                  }}
                  onContextMenu={(e) => {
                    if (!item?.message?.includes("This message was deleted")) {
                      e.preventDefault();
                      setAnchorEl(e.currentTarget)
                      setShowMessageActions({ status: true, id: item._id });
                      setActiveFav(item);
                    }
                  }}
                >
                  <div className="flex flex-col relative">
                    <div className="flex justify-between gap-6">
                      <div className=" mb-2 text-sm flex">
                        {showDate && messageSender?.name}
                        {/* {showDate &&
                          (userDetails &&
                          (userDetails?.firstName !== "" ||
                            userDetails?.lastName !== "")
                            ? userDetails?.firstName +
                              " " +
                              userDetails?.lastName
                            : senderInfo?.phone)} */}
                        {item?.isForwarded && (
                          <div className="flex items-center ml-1">
                            <IoIosShareAlt />
                            <div className="text-sm textGray px-[5px]">
                              {t("forward")}
                            </div>
                          </div>
                        )}
                      </div>

                      {showDate && (
                        <div className="textGray text-xs pr-2 pt-1">
                          {messageTime}
                        </div>
                      )}
                    </div>
                    {item?.reply_msg?.cid !== null &&
                    item?.reply_msg?.cid !== "null"
                      ? !item?.message.includes("This message was deleted") &&
                        item?.reply_msg && (
                          <div
                            className="bg-[#e0e0e0] px-3 py-1 text-sm textGray border-l-4 border-gray-500 cursor-pointer rounded-md"
                            onClick={() => {
                              handleRedirectOnMessage(item?.reply_msg);
                            }}
                          >
                            <div className="primary pb-[2px]">
                              {item?.reply_msg?.sender === userData?._id
                                ? t("you")
                                : formattedContact?.[item?.reply_msg?.sender]
                                ? formattedContact?.[item?.reply_msg?.sender]
                                    ?.firstName + " " + 
                                  formattedContact?.[item?.reply_msg?.sender]
                                    ?.lastName
                                : userObject?.phone}
                            </div>
                            {item?.reply_msg?.type === "contact" && (
                              <span className={`font-medium textGray`}>
                                <>
                                  <div className="px-4 py-4 flex gap-3 items-center border-b">
                                    <div className="h-[45px] w-[45px] min-w-[45px]">
                                      <img
                                        src={
                                          DefaultImageUrl +
                                          replyMessage?.profile_img
                                        }
                                        alt=""
                                        className="rounded-full h-full w-full"
                                      />
                                    </div>
                                    <div className="text-lg font-semibold">
                                      <span> {replyMessage?.firstName} </span>
                                      <span className="ml-1">
                                        {" "}
                                        {replyMessage?.lastName}{" "}
                                      </span>
                                      <div className="text-base font-normal">
                                        {replyMessage?.phone}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </span>
                            )}
                            {item?.reply_msg?.type === "VIDEO" && (
                              <span className={`font-medium textGray`}>
                                <>
                                  <div className="px-4 py-4 flex gap-3 items-center border-b">
                                    <div className="flex items-center gap-2">
                                      <div className="w-full  relative group flex">
                                        <span className="flex">
                                          <VideocamIcon
                                            sx={{ height: 20, width: 20 }}
                                          />
                                          {t("video")}
                                        </span>
                                        <video
                                          ref={audioRef}
                                          src={
                                            DefaultImageUrl +
                                            item?.reply_msg?.file_URL
                                          }
                                          className=" w-14 -mt-[16px] ms-1 h-14"
                                        ></video>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </span>
                            )}
                            {item?.reply_msg?.type === "AUDIO" && (
                              <span className={`font-medium textGray`}>
                                <>
                                  <div className="px-4 py-4 flex gap-3 items-center border-b">
                                    <div className="flex items-center gap-2">
                                      <div className="w-full  relative group">
                                        <span className="flex">
                                          {" "}
                                          <img
                                            src={AudioIcon}
                                            alt="sdf"
                                            className="h-4 w-4 mr-2 mt-1"
                                          />
                                          {t("audio_message")}
                                        </span>
                                        <audio
                                          className="hidden"
                                          src={
                                            DefaultImageUrl +
                                            item?.reply_msg?.file_URL
                                          }
                                          controls
                                        ></audio>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </span>
                            )}
                            {item?.reply_msg?.type === "text" && (
                              <span
                                className={` lightBlack ${
                                  !deleted ? "italic" : ""
                                }`}
                              >
                                {/*  {item?.reply_msg?.message?.length > 130
                            ? item?.reply_msg?.message?.slice(0, 130) + "..."
                            : item?.reply_msg?.message} */}
                                {parse(
                                  FormattedMessage(
                                    item?.reply_msg?.message?.length > 130
                                      ? item?.reply_msg?.message?.slice(
                                          0,
                                          130
                                        ) + "..."
                                      : item?.reply_msg?.message
                                  )
                                )}
                              </span>
                            )}
                            {item?.reply_msg?.type === "IMAGE" && (
                              <div className="flex">
                                <span className="mt-2">
                                  <img
                                    src={
                                      DefaultImageUrl +
                                      item?.reply_msg?.file_URL
                                    }
                                    alt="img"
                                    className="h-[35px] w-[35px] rounded-sm pb-1"
                                  />
                                </span>

                                <div className="flex items-center">
                                  <span>
                                    <img src={camera} className="w-4 ml-2" />
                                  </span>
                                  <span className="pl-1">{t("photo")}</span>
                                </div>
                              </div>
                            )}

                            {item?.reply_msg?.type !== "IMAGE" &&
                              item?.reply_msg?.type !== "text" &&
                              item?.reply_msg?.type !== "contact" &&
                              item?.reply_msg?.type !== "AUDIO" &&
                              item?.reply_msg?.type !== "VIDEO" && (
                                <div className="flex">
                                  <span>
                                    {item?.reply_msg?.file_URL !== undefined
                                      ? item?.reply_msg?.file_URL
                                          ?.split(
                                            item?.roomId +
                                              "/" +
                                              item?.reply_msg?.sender +
                                              "/"
                                          )
                                          .pop()
                                      : ""}
                                  </span>

                                  <div className="flex items-center">
                                    <span>
                                      <img
                                        src={FileIcon}
                                        className="w-4 ml-2"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        )
                      : ""}

                    <div
                      ref={anchorRef}
                      className={`grid items-end justify-between ${
                        item.message.includes("This message was deleted") &&
                        item.deleted.length > 0
                          ? "italic"
                          : ""
                      }`}
                    >
                      <div
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                        className={` ${
                          item?.message.includes("This message was deleted") &&
                          item.deleted.length > 0
                            ? "text-[14px]"
                            : ""
                        } text-[#515151]`}
                      >
                        {parse(
                          FormattedMessage(
                            item?.message
                              .replace(
                                "This message was deleted",
                                t("this-message-deleted")
                              )
                              .replace(
                                "You are assigned",
                                t("you_are_assigned")
                              )
                              .replace(
                                "please accept the invitation to join",
                                t("accept-invite")
                              )
                              .replace(
                                "to start execution",
                                t("start-execution")
                              )
                              .replace("by", t("by"))
                          )
                        )}
                      </div>

                      {item?.favourite_by?.length > 0 && (
                        <StarIcon
                          sx={{ fontSize: 14 }}
                          color="disabled"
                          className="text-white ml-1"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-8 h-6"
                style={{ color: "#33CCFF", fontSize: "1.3rem" }}
              >
                {showArrow.status &&
                  showArrow.id === item?._id &&
                  !item?.message?.includes("This message was deleted") && (
                    <div
                      className=""
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setActiveFav(item);
                      }}
                      ref={anchorRef}
                    >
                      <MoreVertIcon
                        className="-ml-2 cursor-pointer"
                        sx={{ color: "#33CCFF", fontSize: "1.8rem" }}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      />
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => {
                          setAnchorEl(null);
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {MessageAction.filter((elem: MessagesActionDataTypes) =>
                          activeRoomData?.type === "individual"
                            ? elem?.action !== "pin" && elem?.action !== "info"
                            : elem?.action !== "info" && elem
                        ).map((el: MessagesActionDataTypes, index: number) => {
                          const pinchat = pinnedChats.filter(
                            (els: any) => els._id === item?._id
                          );
                          return pinchat.length > 0 && el?.action === "pin" ? (
                            ""
                          ) : activeFav?.favourite_by?.length > 0 &&
                            el?.action === "favourite" ? (
                            ""
                          ) : activeFav?.favourite_by?.length === 0 &&
                            el?.action === "unFavourite" ? (
                            ""
                          ) : isRoomLeft &&
                            (el?.action === "forward" ||
                              el?.action === "reply" ||
                              el?.action === "delete") ? (
                            ""
                          ) : activeRoomData?.type == "broadcast" &&
                            el?.action == "heading" ? (
                            ""
                          ) : (
                            <MenuItem
                              onClick={() => {
                                if (el.action === "heading") {
                                  setData(topicsData);
                                }
                                handleMessageAction(el.action, item);
                                setshowArrow({ status: false, id: null });
                                setAnchorEl(null);
                              }}
                              key={index}
                              sx={{ fontSize: "15px" }}
                            >
                              {el.ui}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextMessagePreview;
