
import { ApolloClient,ApolloProvider as Provider, InMemoryCache,ApolloLink } from "@apollo/client";
import { GraphQlUrl } from "../Constant/Constant";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import authLink from "./authLink";
import { Navigate } from "react-router-dom";
import { UNSUBSCRIBE_FROM_TOPIC } from "./Queries";
const uploadLink = createUploadLink({
  uri: GraphQlUrl,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    }
});

const skipErrors = ["organizations", "getMyInvites"];
const removeSessionItem = (key:any) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};
const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      const skip = skipErrors.includes(operation.operationName);
      if (!skip) {
        if (message.includes("code")) {
          const res = JSON.parse(message);
          console.log(`errors.${res.code}`,res.message)
         
        } else if (message.includes("Unauthorized")) {
        
          console.log("errors.unauthorized", message)
          localStorage.clear();
          
        return < Navigate to='/login'/>;
        } else {
          // notification.error({
          //   key: "network",
          //   message: i18n?.t("errors.network"),
          // });
        }

      }
    });
  if (networkError) {
    // notification.error({ key: "noworkError", message: i18n?.t("errors.network") });
  }
});
export const authCllient = new ApolloClient({
  link: errorLink.concat(authLink.concat(uploadLink as unknown as ApolloLink)),
  cache: new InMemoryCache(),
  defaultOptions:{
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
}
});
const removeSession = async(cleanSettings?: boolean) => {
  if (typeof localStorage !== "undefined"&&localStorage.getItem('notification_token')) {
    // Auth Session
    
    await authCllient
    .query({
      query: UNSUBSCRIBE_FROM_TOPIC,
      variables: {
        token: `${localStorage.getItem('notification_token')}`,
      },
    })
    .then((res) => {
      // console.log(res);
      removeSessionItem('token');
      removeSessionItem('notification_token');
      removeSessionItem('refresh');
      removeSessionItem('expireAt');
    })
    .catch((error) => console.log(error));
   
    
  }
};


export const mutationClient = (token:string) => new ApolloClient({
  link: createUploadLink({
    uri: GraphQlUrl,
    headers: {
      authorization: `Bearer ${token}`,
    },
  }),
  cache: new InMemoryCache(),
});

export const refreshTokenCllient = (token: string) => new ApolloClient({
  link: createUploadLink({
    uri: GraphQlUrl,
    headers: {
      authorization: `Bearer ${token}`,
    },
  }),
  cache: new InMemoryCache(),
});


export const ApolloProvider = ({ children }: any) => {
  return <Provider client={authCllient}>{children}</Provider>;
};
