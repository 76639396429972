import { useCallback, useEffect, useRef, useState } from "react";
import user from "../../Assets/Images/Avatar.png";
import media from "../../Assets/Images/MediaImage.png";
import earth from "../../Assets/Images/Earth.png";
import camera from "../../Assets/Images/camera.png";
import { Avatar, Dialog, DialogContent, TextField } from "@mui/material";
import ButtonWhite from "../../coreComponent/ButtonWhite";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { RoomsDataType } from "../../Types/Types";
import { DefaultImageUrl } from "../../Constant/Constant";
import { emit, socketListen } from "../../Socket";
import { useMutation } from "@apollo/client";
import { CREATE_BROADCAST_ROOM, UPLOAD_CHAT_FILE, UPLOAD_CHUNK_FILE } from "../../Graphql/Mutations";
import Webcam from "react-webcam";
import ReactLoading from "react-loading";
import { CREATE_ROOM } from "../../Graphql/Mutations";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import { setScrollBottom } from "../../Redux/UserSlice";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import broad from '../../Assets/broadcast.png';
import {
  appendOldChats,
  setActiveChatSkip,
  setActiveRoomChats,
  setActiveRoomData,
  setTotoalChatsCount,
} from "../../Redux/ChatSlice";
import {
  useBatchFinishListener,
  useBatchProgressListener,
  useBatchStartListener,
} from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
};

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

function CreateRoom({ setShow, type }: any) {
  const [roomShow, setRoomShow] = useState(false);
  const [imageCropperModal, setImageCropperModal] = useState<any>(false);
  const [zoom, setZoom] = useState(1);
  const [removeImage, setRemoveImage] = useState(false);
  const [cropImageUrl, setCropImageUrl] = useState<any>(false);
  const [crop, setCrop] = useState<any>({ x: 400, y: 400 });
  const [blobImageUrl, setBlobImageUrl] = useState<any>("");
  const [rotation, setRotation] = useState(0);
  const [searchBoxShow, setSearchBoxShow] = useState<boolean>(false);
  const [roomName, setRoomName] = useState("");
  const [roomNameError, setRoomNameError] = useState("");
  const [roomImg, setRoomImg] = useState<any>("");
  const [image, setImage] = useState(defaultSrc);
  const { rooms } = useSelector((state: any) => state.chat);
  const { userContacts, comonUsers, blockedMember, userData } = useSelector((state: any) => state.user);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedRoomsError, setSelectedRoomsError] = useState("");
  const [fileUpload, { data }] = useMutation(UPLOAD_CHAT_FILE);
  const [uploadChunks] = useMutation(UPLOAD_CHUNK_FILE);
  const [showWebCam, setShowWebCam] = useState(false);
  const webcamRef = useRef<any>(null);
  const [webcamImage, setWebcamImage] = useState("");
  const [webcamObjFile, setWebcameObjFile] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [createRoom] = useMutation(CREATE_ROOM);
  const [createBroadcastRoom] = useMutation(CREATE_BROADCAST_ROOM)
  const [roomResponseId, setRoomResponseId] = useState("");
  const [roomsData, setRoomsData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [searchQuery,setSearchQuery]=useState("")
  const captureWebcamImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamImage(imageSrc);
    setRoomImg("");

    fetch(imageSrc)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new File([arrayBuffer], "webcam2.jpeg", {
          type: "image/jpeg",
        });
        setWebcameObjFile(file);
      });
  }, [webcamRef]);

  useEffect(() => {
    if (webcamObjFile !== "") {
      fileUpload({
        variables: {
          file: webcamObjFile,
          thumbnail: null,
          input: { roomId: "sfsf3424", _id: userData?._id },
        },
      });
    }
  }, [webcamObjFile]);

  const handleContinue = () => {
    if (roomName === "") {
      setRoomNameError("Please enter Room Name");
    } else {
      setRoomShow(true);
      setRoomNameError("");
    }
  };

  const handleRoomImgChange = () => {
    if (blobImageUrl) {
      setWebcamImage("");
      setShowWebCam(false);
      setWebcameObjFile("");
      setRoomImg(blobImageUrl);
      fileUpload({
        variables: {
          file: blobImageUrl === undefined ? roomImg : blobImageUrl,
          thumbnail: null,
          input: { roomId: "sfsf3424", _id: userData?._id },
        },
      }).then((res) => {
        setImageCropperModal(false);
        setCropImageUrl(false);
      });
    }
  };

  const handleSelectRooms = (e: any) => {
    let temp: any = [...selectedRooms];

    if (e.target.checked) {
      temp.push(e.target.name);
      setSelectedRooms(temp);
    } else {
      const removed = temp.filter((one: any) => one !== e.target.name);
      setSelectedRooms(removed);
    }
  };

  const handleCreateRoom = () => {
    setIsLoading(true);
    let fileurl: any;
    if (data) {
      fileurl = data?.UploadChatFile?.data?.filename;
    }

    if (selectedRooms?.length === 0) {
      setSelectedRoomsError("Please Select Participants");
      setIsLoading(false);
    } else {
      setSelectedRoomsError("");
      if (type === 'group') {
        console.log( {
          input: {
            users: selectedRooms,
            type: "group",
            name: roomName?.trim(),
            profile_img: fileurl ?? "",
            localId: "1",
          },
        })
        createRoom({
          variables: {
            input: {
              users: selectedRooms,
              type: "group",
              name: roomName?.trim(),
              profile_img: fileurl ?? "",
              localId: "1",
            },
          },
        }).then((res) => {
          emit("GetAllRooms");
          setShow(false)
          setTimeout(() => {
            setRoomResponseId(res?.data?.createRoom?.roomId);
            socketListen("message", (data: any) => {
              if (data.type === "GetAllRooms") {

                const filteredData = data?.msg?.rooms.filter((item: any) => item?.organization === "")
                setRoomsData(filteredData);
              }
            });
          }, 1000);
        });
      } else {
        createBroadcastRoom({
          variables: {
            input: {
              users: selectedRooms,
              type: "broadcast",
              name: roomName?.trim(),
              profile_img: fileurl ?? "",
              localId: "1",
            },
          },
        }).then((res) => {
          emit("GetAllRooms");
          setShow(false)
          setTimeout(() => {
            setRoomResponseId(res?.data?.createRoom?.roomId);
            socketListen("message", (data: any) => {
              if (data.type === "GetAllRooms") {

                const filteredData = data?.msg?.rooms.filter((item: any) => item?.organization === "")
                setRoomsData(filteredData);
              }
            });
          }, 1000);
        });
      }
    }
  };

  useEffect(() => {
    const createdRoomData = roomsData?.find(
      (item: any) => item?._id === roomResponseId
    );

    if (createdRoomData) {
      setRoomImg("");
      setSelectedRooms([]);
      setRoomName("");
      setShow(false);
      setIsLoading(false);

      dispatch(setScrollBottom(true));
      dispatch(setActiveRoomData(createdRoomData));
      /*  emit("getChatsByRoomId", {
         roomId: createdRoomData?._id,
         skip: 0,
         limit: 50,
       }); */
      dispatch(setActiveRoomChats([]));
      dispatch(appendOldChats([]));
      dispatch(setTotoalChatsCount(50));
      dispatch(setActiveChatSkip(0));
      emit("getUserMediaByRoomId", {
        roomId: createdRoomData?._id,
        type: "media",
      });
    }
  }, [roomsData?.length > 0]);

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const [documentUpload, setDocumentUpload] = useState<any>({});
  const [level, setLevel] = useState([]);

  const LogProgress: any = (props: any) => {
    // useItemProgressListener((item) => {
    //   setDocumentUpload({
    //     ...documentUpload,
    //     time: item.completed,
    //     type: item.file.type,
    //     index: props.index,
    //   });
    //   // console.log(
    //   //   `>>>>>item ${item.path}  path${item.file} (hook) File ${item.file.name} completed: ${item.completed}`,
    //   //   item
    //   // );
    // });

    // useChunkFinishListener((chunk) => {
    //   let tempArray: any = [...level];
    //   if (chunk?.item?.file?.type.includes("video")) {
    //     tempArray[props.parent]["childrens"][props.index].video =
    //       chunk?.uploadData?.response?.data?.path;
    //   } else {
    //     tempArray[props.parent]["childrens"][props.index].document =
    //       chunk?.uploadData?.response?.data?.path;
    //   }
    //   setLevel(tempArray);
    //   setDocumentUpload({
    //     ...documentUpload,
    //     name: chunk?.item?.file.name,
    //     path: chunk?.uploadData?.response?.data?.path,
    //   });
    // });
    // return null;

    useBatchStartListener((batch) => {
      console.log("start = ", batch);
    });

    useBatchProgressListener((batch) => {
      console.log("progress = ", batch);
    });

    useBatchFinishListener((batch) => {
      console.log("finished= ", batch);
    });
  };

  function getCroppedImg(imageSrc, pixelCrop) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        const dataUrl = canvas.toDataURL("image/jpeg", 1);
        resolve(dataUrl);
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      image.src = imageSrc;
    });
  }

  const onZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const onZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleSearchRooms = (e: any) => {
    if (e.target.value) {
      setSearchQuery(e.target.value);
     } else {
      setSearchQuery("");
     }
  };


  return (
    <div>
      <Dialog
        open={imageCropperModal}
        onClose={() => {
          setImageCropperModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className=" p-0 bg-white">
            <div className="relative  p-0">
              <div style={{ width: "450px", height: "400px" }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={7 / 7}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onCropComplete={(croppedArea, croppedAreaPixels) => {
                    getCroppedImg(image, croppedAreaPixels).then(
                      (croppedImage: any) => {
                        setCropImageUrl(true);
                        fetch(croppedImage)
                          .then((res) => res.arrayBuffer())
                          .then((arrayBuffer) => {
                            const file = new File(
                              [arrayBuffer],
                              "webcam2.jpeg",
                              {
                                type: "image/jpeg",
                              }
                            );
                            setBlobImageUrl(file);
                          });
                      }
                    );
                  }}
                  onZoomChange={setZoom}
                  onMediaLoaded={(mediaSize) => {
                  }}
                />
              </div>
              <br style={{ clear: "both" }} />
            </div>
            <div className="my-auto px-2"></div>
          </div>
          <div className="flex justify-center gap-3 mt-3 py-3">
            {" "}
            <button
              onClick={onZoomIn}
              className="p-1 my-2 border bg-[#33ccff] rounded-md "
            >
              <AddIcon />
            </button>
            <button
              onClick={onZoomOut}
              className="p-1 my-2 border bg-[#33ccff] rounded-md"
            >
              <RemoveIcon />
            </button>
            <button
              className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
              onClick={() => {
                if (rotation === 360) {
                  setRotation(0);
                } else {
                  setRotation(rotation + 90);
                }
              }}
            >
              <Rotate90DegreesCcwIcon /> Flip
            </button>
            <button
              onClick={handleRoomImgChange}
              className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
            >
              <CloudUploadIcon />
              <span className="mx-1"> {t("upload")} </span>
            </button>
            {webcamImage !== "" && (
              <button
                onClick={() => {
                  setShowWebCam(true);
                  if (webcamImage === "") {
                    captureWebcamImage();
                  } else {
                    setWebcamImage("");
                  }
                }}
                className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
              >
                <AddAPhotoIcon />
                <span className="mx-1"> {t("retake")} </span>
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <div className="w-[400px]">
        {roomShow ? (
          <div className="">
            <div className="flex justify-between pl-2 border-b py-2 bg-gray-100 sticky top-0">
              <div
                className="flex cursor-pointer"
                onClick={() => setRoomShow(false)}
              >
                <div>
                  <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
                </div>
                <div className="">{t("back")}</div>
              </div>
              {selectedRooms.length > 1 && <div className="flex mr-2">
                {isLoading ? (
                  <ReactLoading
                    type="spin"
                    height={20}
                    width={20}
                    color="#33ccff"
                  />
                ) : (
                  <div
                    className="cursor-pointer mr-2"
                    onClick={handleCreateRoom}
                  >
                    <CheckIcon />
                  </div>
                )}
              </div>}
            </div>

            {selectedRoomsError && (
              <div className="text-red-600 text-sm px-2 mt-2">
                {selectedRoomsError}
              </div>
            )}

            {searchBoxShow && (
              <div className="px-4 pt-2">
                <div className="px-2 py-[2px] border-2 rounded flex">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full focus:outline-none"
                  />
                  <div className="lightBlack">
                    <SearchIcon />
                  </div>
                </div>
              </div>
            )}

            <div className="pt-2 px-3">
              <div
                className="my-3  rounded flex items-center px-2 border mb-4 bg-white"
                style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
              >
                <SearchIcon sx={{ color: "#333333" }} />
                <input
                  type="text"
                  placeholder={t("search")}
                  className="w-full py-2 px-2 focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={handleSearchRooms}
                />
              </div>
              {comonUsers.map((el:any)=>({...el,name:el?.firstName!==""?el.firstName?.toLowerCase()+" "+el?.lastName.toLowerCase():`${el?.phone.replace('+','')}`})).sort((a: any, b: any) => {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name > b.name) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).filter((el:any)=>el?.name.split(' ')[0]?.startsWith(searchQuery.toLowerCase())||el?.name.split(' ')[1]?.startsWith(searchQuery.toLowerCase()))?.map((item: any, index: number) => {
                const userProfile =
                  item?.type !== "group" &&
                  item?.participants?.find(
                    (one: any) => one?.user_id !== userData?._id
                  );
                const userContactInfo =
                  item?.type !== "group" &&
                  userContacts?.find(
                    (one: any) => one?.userId?._id === userProfile?.user_id
                  );
                if (blockedMember.includes(item?.userId?._id)) {
                  return null
                }
                return item?.userId?._id != userData?._id && (
                  (
                    <div
                      className="flex items-center border-b py-2"
                      key={index}
                    >
                      <div className="flex justify-between items-center w-full">
                        <div className="flex gap-2">
                          <div>
                            {item?.userId.profile_img ? (
                              <img
                                src={DefaultImageUrl + item?.userId.profile_img}
                                alt="profile"
                                className="h-[50px] w-[50px] rounded-full"
                              />
                            ) : (
                              <Avatar sx={{ height: 50, width: 50 }} />
                            )}
                          </div>
                              <div className='pl-3'>
                          <div className=" flex items-center">
                            {item &&
                              (item?.firstName !== "" ||
                                item?.lastName !== "") ? (
                              <div className="flex gap-1">
                                <div className="lightBlack">
                                  {item?.firstName}
                                </div>
                                <div className="lightBlack">
                                  {item?.lastName}
                                </div>
                              </div>
                            ) : (
                              <div className="lightBlack">
                                {item?.phone}
                              </div>
                            )}
                          </div>
                          <div className="pl-3 textGray text-xs">{item?.phone}</div>
                          </div>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name={item.userId?._id}
                            className="cursor-pointer"
                            id={item.userId?._id}
                            checked={selectedRooms.includes(item.userId?._id)}
                            onChange={(e) => handleSelectRooms(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            <div className="bg-white flex py-4 px-2 shadow-md">
              <div
                className="flex cursor-pointer"
                onClick={() => setShow(false)}
              >
                <div>
                  <ArrowBackIosIcon sx={{ fontSize: "17px" }} />
                </div>
                <div className="">{t("back")}</div>
              </div>
              <div className={type==="group"?"mx-24":"mx-14"}>{type==="group"?t("create_group"):t("create-broadcast")+" "+t("group")}</div>
              
            </div>
          <div className="px-3">
            
            <div className="flex justify-center pt-5">
              {webcamImage === "" ? (
                roomImg === "" ? (
                  <img src={type === 'broadcast' ? broad : user} className="w-44" alt="as" />
                ) : (
                  <img
                    src={URL.createObjectURL(roomImg)}
                    className="w-44 h-44 rounded-full"
                    alt="as"
                  />
                )
              ) : (
                <img
                  src={webcamImage}
                  className="w-44 h-44 rounded-full"
                  alt="as"
                />
              )}
            </div>
            <div className="pt-4">
              <TextField
                id="standard-basic"
                label="Name"
                variant="standard"
                sx={{ width: "100%" }}
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
            </div>
            <div className="text-xs textGray mt-2 px-1 flex gap-1">{type==='broadcast'?t('room-type-message-2'):t('room-type-message-1')}</div>
            {roomNameError && (
              <div className="mt-2 text-red-600 text-sm">{roomNameError}</div>
            )}

            <div className="flex justify-between px-8 py-6">
              <div
                className="cursor-pointer"
                onClick={() => setShowWebCam(true)}
              >
                <img src={camera} className="w-14" alt="as" />
              </div>

              {/* <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              id="gallery"
              className="hidden"
              onChange={handleRoomImgChange}
            /> */}
              <input
                type="file"
                accept="image/jpeg, image/jpg, image/png"
                id="imageUploadBtn"
                className="hidden"
                onChange={handleImageChange}
              />
              <div
                onClick={() => {
                  setImageCropperModal(true);
                }}
              >
                <label htmlFor="imageUploadBtn">
                  <div className="cursor-pointer">
                    <img src={media} className="w-14" alt="asd" />
                  </div>
                </label>
              </div>
              {/* <div className="cursor-pointer">
                <img src={earth} className="w-[51px]" alt="" />
              </div> */}
            </div>
            {roomName?.trim()?.length > 0 && (
              <div className="py-3" onClick={handleContinue}>
                <ButtonWhite>
                  <span>{t("continue")}</span>
                </ButtonWhite>
              </div>
            )}

            {showWebCam && (
              <div className="mt-4">
                {webcamImage === "" ? (
                  <Webcam
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    ref={webcamRef}
                    mirrored={false}
                  />
                ) : (
                  <img
                    src={webcamImage}
                    alt="sdf"
                    className="w-full h-[300px]"
                  />
                )}

                <div className="mt-2 flex gap-3">
                  <button
                    className="text-sm text-red-600 border border-red-600 p-2"
                    onClick={() => {
                      setShowWebCam(false);
                      setWebcamImage("");
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="text-sm primary border-[#33CCFF] border p-2"
                    onClick={() => {
                      if (webcamImage === "") {
                        captureWebcamImage();
                      } else {
                        setWebcamImage("");
                      }
                    }}
                  >
                    {webcamImage === "" ? "Capture" : "Re-Capture"}
                  </button>
                </div>
              </div>
            )}
          </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateRoom;
