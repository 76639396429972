import { ChangeEvent, useState } from "react";
import { TaskEdgesTypes } from "../../../Types/Types";
import { useGetLocation } from "../../../hooks/useGetLocation";
import { getSession } from "../../../utils/session";
import { mutationClient } from "../../../Graphql/authClient";
import { UPLOAD_FILE } from "../../../Graphql/Mutations";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskCheckboxInput = ({
  currentTask,
  isExecutable,
  setCheckedValues,
  checkedValues,
  result,
  setResult,
  location, media, setTaskSubmitError, setAttachmentId, setLoading
}: any) => {
  const { t } = useTranslation();
  const {locationData}=useSelector((state: any) => state.chat);
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, elem: any, data: any) => {

    const isChecked = e.target.checked;
    if (data.location) {
      if (locationData?.lat==="") {
        getCurrentLocation();
        alert(t('location_permission'))
      } else {
        if (isChecked) {
          const temp = checkedValues;
          temp.push(elem);
          if (location.includes(elem.label) || location.includes('all')) {
            getCurrentLocation();
          }
          setResult(temp.join(','))
          setCheckedValues(temp);
        } else {
          const updated = checkedValues?.filter((item: string) => item !== elem);
          setResult(updated.join(','))
          setCheckedValues(updated);
        }
      }
    }
    else {
      if (isChecked) {
        const temp = checkedValues;
        temp.push(elem);
        if (location.includes(elem.label) || location.includes('all')) {
          getCurrentLocation();
        }
        setResult(temp.join(','))
        setCheckedValues(temp);
      } else {
        const updated = checkedValues?.filter((item: string) => item !== elem);
        setResult(updated.join(','))
        setCheckedValues(updated);
      }
    }
  };

  const { getCurrentLocation } = useGetLocation();

  return (
    <>
      {currentTask?.type === "CHECKBOX" &&
        isExecutable &&
        currentTask?.edges?.map((item: TaskEdgesTypes, index: number) => {
          return item?.options?.map((elem: any, index: number) => {
            return (
              item?.type !== "TIMEOUT" && (

                <label key={index} className="my-2 flex cursor-pointer">
                  <input
                    type={"checkbox"}
                    name="checkbox"
                    className="h-[20px] w-[20px] cursor-pointer"
                    value={elem.label}
                    onChange={(e: any) => {
                      handleCheckboxChange(e, elem.label, elem);
                      
                    }}
                  />
                  <span className="ml-1">{elem?.label}</span>
                </label>
              )
            );
          });
        })}
    </>
  );
};

export default TaskCheckboxInput;
