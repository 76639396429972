import dayjs from "dayjs"
import { store } from "../../../../Redux/store"

function formatReminderData(data: any) {
    const { userData }: any = store.getState()?.user
    const activeRoomData = store.getState()?.chat?.activeRoomData
    const roomParticipants = activeRoomData?.participants?.filter(el => el?.user_id != userData?._id)
    const start = dayjs(data?.date).set('hours', dayjs(data?.time).hour()).set('minute', dayjs(data?.time).minute()).set('second', dayjs(data?.time).second());
    const end = dayjs(data?.date).set('hours', dayjs(data?.endTime).hour()).set('minute', dayjs(data?.endTime).minute()).set('second', dayjs(data?.endTime).second());

    const obj = {
        jsEvent: {
            pageY: 185,
            pageX: 777,
        },
        start: start,
        end: end,
        event: {
            allDay: false,
            title: data?.title,
            extendedProps: {
                _id: data?._id,
                parent_id: data?.parent_id,
                roomId: data?.roomId,
                roomParticipants: roomParticipants?.length > 0 ? roomParticipants : [],
                type: data?.type,
                description: data?.description,
                frequency: data?.recursive,
                days: data?.daylyParams?.dayOfWeeks || [],
                noOfWeeks: data?.daylyParams?.everyWeek,
                repeatEveryMonth: data?.monthlyParams?.twicePerMonth ?? true,
                months: data?.monthlyParams?.months,
                notifications: data?.approvalReminderTime?.length
                    ? data?.approvalReminderTime?.map(el => ({ Unit: el?.Unit, Count: el?.Count }))
                    : [],
                participants: data?.participants,
                attachments: data?.attachment?.length
                    ? data?.attachment?.map(el => {
                        const { __typename, ...rest } = el
                        return rest
                    })
                    : [],
                location: data?.location,
                isAllDay: data?.isAllDay,
                monthDateType: data?.monthlyParams?.onWeek ? "week" : "day",
                onDay: data?.monthlyParams?.onDay ?? 1,
                onWeek: data?.monthlyParams?.onWeek?.dayOfWeeks?.[0] ?? "MON",
                everyWeek: data?.monthlyParams?.onWeek?.everyWeek ?? 1,
                hasComon: data?.hasComon,
                isConfirmationNeeded: data?.isConfirmationNeeded,
                roomType: activeRoomData?.type,
                time: dayjs(data?.time),
                endTimes: dayjs(data?.endTime),
                from: data?.startDate,
                to: data?.endDate,
            }
        },
    }

    return obj
}

export default formatReminderData