import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type DialogBox = {
  open: boolean;
  handleClose: () => void;
  title:any,
  actions:any,
  content: React.ReactNode;
};

function DialogBox({ open,title,actions, content, handleClose }: DialogBox) {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="flex justify-center w-full">
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        
      >
        <DialogTitle className="bg-[#E0FAFF]">
          <div>{title}</div>
        </DialogTitle>
        <DialogContent  className="mt-6">
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
         {actions}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogBox;
