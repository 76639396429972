
const incoming = {
  rejected: {
    msg: "MISSED",
    audioIcon: require("../../Assets/Images/CallIncommingmissed.png"),
    videoIcon: require("../../Assets/Images/VideoIncomingMissed.png"),
  },
  accepted: {
    msg: "INCOMING",
    audioIcon: require("../../Assets/Images/CallIncommingAccept.png"),
    videoIcon: require("../../Assets/Images/VideoIncommingAccept.png"),
  },
  missed: {
    msg: "MISSED",
    audioIcon: require("../../Assets/Images/CallIncommingmissed.png"),
    videoIcon: require("../../Assets/Images/VideoIncomingMissed.png"),
  },
};
const outgoing = {
  rejected: {
    msg: "MISSED",
    audioIcon: require("../../Assets/Images/CallOutGoingMissed.png"),
    videoIcon: require("../../Assets/Images/VideoOutGoingMissed.png"),
  },
  accepted: {
    msg: "OUTGOING",
    audioIcon: require("../../Assets/Images/CallOutGoingAccepted.png"),
    videoIcon: require("../../Assets/Images/VideoOutGoingAccepted.png"),
  },
  missed: {
    msg: "MISSED",
    audioIcon: require("../../Assets/Images/CallOutGoingMissed.png"),
    videoIcon: require("../../Assets/Images/VideoOutGoingMissed.png"),
  },
};

export { incoming, outgoing };
