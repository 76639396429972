import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "10px",
  backgroundColor: "#fffefe"
};

function EventConfirmation({ show, setShow, onSubmit, message }) {
  const { t } = useTranslation();

  function handleClose() {
    setShow(false);
  }

  function handleSubmit(type: "this" | "all") {
    onSubmit(type);
    setShow(false);
  }

  return (
    show && (
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center text-lg capitalize">{message}</div>

          <div className="grid gap-2 mt-3">
            <button
              onClick={() => handleSubmit("this")}
              className="text-gray-700 outline-none hover:text-sky-500"
            >
              {t("this_event")}
            </button>
            <button
              onClick={() => handleSubmit("all")}
              className="text-gray-700 outline-none hover:text-sky-500"
            >
              {t("all_event")}
            </button>
            <button onClick={handleClose} className="text-red-700 outline-none">
              {t("cancel")}
            </button>
          </div>
        </Box>
      </Modal>
    )
  );
}

export default EventConfirmation;
