import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import { BsCheck2All } from "react-icons/bs";

const MessageInfo = ({ item }: any) => {
  const { formattedParticipantsOfActiveRoom} = useSelector(
    (state: any) => state.chat
  );
  const {userData}=useSelector((state:any)=>state.user)
  
  const {
    activeRoomData
  } = useSelector((state: any) => state.chat);
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];
  const isUserBlocked =activeRoomData?.participants?.find((el: any) => el?.left_at !== 0&&el?.user_id === userData?._id);

  const getReadByUserProfile = (id: string) => {
    return `${DefaultImageUrl}${formattedParticipantsOfActiveRoom[id]?.profile_img}`;
  };

  return item?.read_by?.length > 0
    ? item?.read_by?.slice(0, 2).map((r_item: any) => (
      r_item?.user_id&& <div key={r_item.user_id}>
          {senderInfo?.profile_img !== "" &&!isUserBlocked? (
            <img
              src={getReadByUserProfile(r_item?.user_id)}
              alt="as"
              className="w-[15px] h-[15px] rounded-full"
            />
          ) : (
            <Avatar
              sx={{
                width: "15px",
                height: "15px",
              }}
            />
          )}
        </div>
      ))
    : item?.delivered_to?.length > 0 && (
        <div className="-mt-1">
          <BsCheck2All className="text-gray-500" />
        </div>
      );
};

export default MessageInfo;
