import dayjs from "dayjs";
import { TFunction } from "i18next";

function determineDateText(date, t: TFunction): string {
    const current = dayjs().startOf('day')
    const tomorrow = current.add(1, 'day').startOf('day')
    const yesterday = current.subtract(1, "day")
    const inputDate = dayjs(date).startOf('day')

    let text = ""

    if (dayjs(inputDate).isSame(current)) {
        text = t("today")
    } else if (dayjs(inputDate).isSame(tomorrow)) {
        text = t("tomorrow")
    } else if (dayjs(inputDate).isSame(yesterday)) {
        text = t("yesterday")
    } else {
        text = dayjs(inputDate).format("DD MMM YYYY")
    }

    return text
}

export default determineDateText