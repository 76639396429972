export enum ReminderParticipantRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export enum ParticipantAcceptStatus {
  REJECT = "REJECT",
  PENDING = "PENDING",
  ACCEPT = "ACCEPT",
  PAUSE = "PAUSE",
}

export enum RecurrentTypes {
  ONCE = "ONCE",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  HALFYEARLY = "HALFYEARLY",
  ANNUALLY = "ANNUALLY",
  PERIODICAL = "PERIODICAL",
  ANYTIME = "ANYTIME",
}

export enum DayOfWeek {
  MON = "Monday",
  TUE = "Tuesday",
  WED = "Wednesday",
  THU = "Thursday",
  FRI = "Friday",
  SAT = "Saturday",
  SUN = "Sunday",
}

export enum Month {
  JAN = "January",
  FEB = "February",
  MAR = "March",
  APR = "April",
  MAY = "May",
  JUN = "June",
  JUL = "July",
  AUG = "August",
  SEP = "September",
  OCT = "October",
  NOV = "November",
  DEC = "December",
}

export enum ApprovalReminderTimeTypes {
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
  WEEK = "WEEK",
}

export enum EventTypeEnum {
  REMINDER = "REMINDER",
  SCHEDULE = "SCHEDULE",
  APPOINTMENT = "APPOINTMENT",
  CALLREMINDER = "CALLREMINDER",
  Record_Reminder = "Record_Reminder",
}

export enum MediaType {
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  RECORD = "RECORD",
  DOCUMENT = "DOCUMENT",
}

export const DaysNum = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 0,
};
