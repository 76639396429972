import { useDispatch, useSelector } from "react-redux";
import { useOrganizations } from "../../hooks/useOrganizations";
import { useEffect } from "react";
import { setCurrentAssignment, setCurrentOrganization, setShowTaskPage } from "../../Redux/OrganizationSlice";


export default function SetCurrentOrganizationData() {
    const { getMyAssignment, getReportById, getScenarioById } = useOrganizations();
    const { organizations, assignments } = useSelector(
        (state: any) => state.organization
    );
    const { userData } = useSelector(
        (state: any) => state.user
    );
    const authToken = localStorage.getItem("token");
    const dispatch = useDispatch();
    useEffect(() => {
        setCurrentOrganizations();
        // eslint-disable-next-line
    }, [authToken, window.location.pathname.includes("_"), organizations?.length > 0 && assignments?.length > 0]);

    const setCurrentOrganizations = async () => {
        if (
            window.location.pathname.includes("_") &&
            organizations.length > 0 &&
            assignments.length > 0 && userData
        ) {
            let data = window.location.pathname.replace("/", "").split("_");
            if (authToken && data.length > 0) {
                const currentOrg = organizations.filter(
                    (item: any) => item._id === data[0]
                );
                if (currentOrg.length > 0) {
                    localStorage.setItem("currentOrganization", JSON.stringify(currentOrg[0]));
                    console.log('current Organization', organizations)
                    getMyAssignment(data[0], 0, 0);

                }
            }

            if (authToken) {
                const currentOrg = organizations.filter(
                    (item: any) => item._id === data[0]
                );
                if (currentOrg.length > 0) {
                    localStorage.setItem("currentOrganization", JSON.stringify(currentOrg[0]));
                    dispatch(setCurrentOrganization(currentOrg[0]));
                }
                let matched = assignments.filter((item: any) => item._id === data[1]);
                if (matched.length > 0) {
                    const member = matched[0].members.filter(
                        (item: any) => item.member.user._id === userData._id
                    );
                    console.log(assignments, matched, "notification data", data, member);
                    getScenarioById(matched[0]?.scenario?._id, '');
                    if (member[0]?.activeReportId) {
                        getReportById(member[0]?.activeReportId);
                    }
                    dispatch(setCurrentAssignment(matched[0]));
                }
                // dispatch(setScenarioLoading(true));
                dispatch(setShowTaskPage(true));
            }
        } else {

        }
    }
    return (
        <></>
    )
}