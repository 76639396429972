import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import {  comonDownloadAppUrl } from "./Constant/Constant";
import AgoraRTC from "agora-rtc-sdk-ng";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "./Graphql/authClient";
import AuthProvider from "./AuthProvider";
import * as serviceWorker from './serviceWorker';
import { setActiveRoomData } from "./Redux/ChatSlice";
import './i18n';


export const client_call = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

  if (window.innerWidth < 1000) {
    window.open(comonDownloadAppUrl, "_self");
  }

  /* if (process.env.REACT_APP_DISABLE_CONSOLE_LOG) {
    console.log = console.warn = console.error = () => {};
  }
   */
  
 
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ApolloProvider>
        <AuthProvider authToken={localStorage.getItem("token")}>
        <App />
        </AuthProvider>
      </ApolloProvider>
    </Provider>
  </BrowserRouter>
);


serviceWorker.register();
