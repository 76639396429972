import {
  CALL_LIST,
  GET_CHATS_BY_ROOM_ID,
  GET_LANGUAGES,
  GET_MY_CONTACTS,
  GET_MY_TOPICS,
} from "./Graphql/Queries";
import {
  setComonUsers,
  setIsContactsLoading,
  setLanguages,
  setNoInternetConnection,
  setTopicsData,
  setUserContacts,
} from "./Redux/UserSlice";
import {
  setActiveRoomData,
  setActiveRoomsChats,
  setCallHistory,
  setIsRoomsLoading,
} from "./Redux/ChatSlice";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ForgotPassword from "./Authentication/ForgotPassword";
import Login from "./Authentication/Login";
import ResetPassword from "./Authentication/ResetPassword";
import ChatPage from "./Pages/ChatPage/ChatPage";
import Home from "./Pages/HomePage/Home";
import { AppPath } from "./Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import { socketService } from "./Socket";
import { authCllient, refreshTokenCllient } from "./Graphql/authClient";
import ShowNotificationBar from "./Authentication/ShowNotificationBar";
import { useOrganizations } from "./hooks/useOrganizations";
import { getSession } from "./utils/session";
import { setCurrentNoti, setTaskData } from "./Redux/OrganizationSlice";
import SetCurrentOrganizationData from "./Pages/Task/SetCurrentOrganizationData";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = getSession();
  const [time, setTime] = useState();
  const [callEnd, setCallEnd] = useState(false);
  const [final_data, setFinalData] = useState({});
  const authToken = localStorage.getItem("token");
  const { updateOrganizationsList, updateInvites } = useOrganizations();
  const { load } = useSelector((state: any) => state.organization);
  const { rooms, activeRoomData, activeChatsLimit } = useSelector(
    (state: any) => state.chat
  );

  useEffect(() => {
    dispatch(setIsRoomsLoading(true));
    if (authToken !== null) {
      socketService(dispatch);
      fetchMyContacts();
      updateOrganizationsList();
      updateInvites();
      FetchCallHistory();
      getTopicsData();
      getAllLanguages()
    }

    navigator.serviceWorker.addEventListener("message", (event) => {
      navigator.serviceWorker.removeEventListener("message", function () {});
      if (!event.data.action) {
        return;
      }
      if (event?.data.action === "data") {
        setFinalData(event?.data?.url?.data);
      }
    });
  }, []);

  const getTopicsData = () => {
    refreshTokenCllient(token)
      .query({
        query: GET_MY_TOPICS,
      })
      .then((res: any) => {
        dispatch(setTopicsData(res?.data?.getMyTopics));
      });
  };

  const getAllLanguages = async () => {
    try {
      const response = await authCllient.query({query: GET_LANGUAGES})
      if (response?.data?.getLanguageList) {
        dispatch(setLanguages(response?.data?.getLanguageList))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const fetchMyContacts = () => {
    dispatch(setIsContactsLoading(true));

    authCllient
      .query({
        query: GET_MY_CONTACTS,
      })
      .then((res) => {
        const contacts = res?.data?.getMyContacts?.contacts?.length
          ? res?.data?.getMyContacts?.contacts
          : [];

        dispatch(setUserContacts(contacts));

        let temp = [...contacts];
        const comonUsers = temp.filter(
          (item: any) => item?.userId !== null && item
        );
        dispatch(setComonUsers(comonUsers));
        dispatch(setIsContactsLoading(false));
      })
      .catch((error) => console.log(`Error occured at Contact Fetch ${error}`));
  };

  window.addEventListener("offline", (e) => {
    dispatch(setNoInternetConnection(true));
  });

  window.addEventListener("online", (e) => {
    dispatch(setNoInternetConnection(false));
  });

  const FetchCallHistory = () => {
    /*    const {
         fetchCallHistory,
       } = useSelector((state: any) => state.chat);
       useEffect(() => { */
    authCllient
      .query({
        query: CALL_LIST,
        variables: {
          input: {
            skip: 0,
            limit: 25,
            callStatus: "all",
          },
        },
      })
      .then((res) => {
        dispatch(setCallHistory(res?.data?.getMyCallList));
      })
      .catch((error) => console.log("error at fetch call history", error));
    // eslint-disable-next-line
    //}, []);
  };

  const fetchChatData = async (roomId: any, total: any) => {
    // Your fetch logic hereconst
    const { token } = getSession();
    if (activeRoomData && activeRoomData?.totalChats + 1 !== null) {
      refreshTokenCllient(token)
        .query({
          query: GET_CHATS_BY_ROOM_ID,
          variables: {
            input: {
              roomId: roomId, // roomId
              total: total, // total chats
              index: total + 5,
              limit: activeChatsLimit, // custom limit
              scrollType: "LATEST", // "UP"
            },
          },
        })
        .then((res) => {
          if (res.data.getChatsByRoomId.length > 0) {
            dispatch(setActiveRoomsChats(res?.data?.getChatsByRoomId));
          }
        })
        .catch((error) => {
          console.log(`Cannot Query scenario by id => ${error}`);
        });
    }
  };

  useEffect(() => {
    if (window.location.pathname) {
      const roomId = window.location.pathname.split("/").pop();
      if (
        roomId !== undefined &&
        rooms.filter((el: any) => el._id === roomId).length > 0
      ) {
        const dataOfRoom = rooms.filter((el: any) => el._id === roomId)[0];
        let tempActiveRoomData = { ...dataOfRoom };
        tempActiveRoomData["totalChats"] += 1;
        dispatch(setActiveRoomsChats([]));
        console.log("(99999((((");
        dispatch(setActiveRoomData(tempActiveRoomData));
        setTimeout(() => {
          fetchChatData(roomId, dataOfRoom?.totalChats);
        }, 2000);
      }
      setTimeout(() => {
        if (rooms.filter((el: any) => el._id === roomId).length > 0) {
          navigate("/");
          setTimeout(() => {
            if (document.querySelector("#scrollableDiv") !== null) {
              var height =
                document.querySelector("#scrollableDiv").scrollHeight;
              document.querySelector("#scrollableDiv").scroll(0, height - 100);
            }
          }, 3000);
        }
      }, 1500);
    }
  }, [window.location.pathname, rooms.length > 0]);

  useEffect(() => {
    if (JSON.stringify(final_data) !== "{}") {
      console.log(final_data);
      dispatch(setTaskData(true));
      dispatch(setCurrentNoti(final_data));
    }
  }, [final_data]);

  return (
    <React.Fragment>
      {load.status && (
        <div className="bg-[#00000094] absolute left-0 right-0 bottom-0 top-0 z-50 text-center text-white flex items-center justify-center text-3xl">
          {load.text}
        </div>
      )}

      <div className="relative h-full flex items-center w-full">
        <SetCurrentOrganizationData />
        <ShowNotificationBar
          time={time}
          setTime={setTime}
          setCallEnd={setCallEnd}
          callEnd={callEnd}
        />

        <React.Fragment>
          <Routes>
            <Route path={AppPath.login} element={<Login />} />
            <Route path={AppPath.forgetPassword} element={<ForgotPassword />} />
            <Route path={AppPath.resetPassword} element={<ResetPassword />} />
            <Route path={AppPath.chatPage} element={<ChatPage />} />
            <Route
              path={AppPath.home}
              element={
                <Home time={time} setCallEnd={setCallEnd} setTime={setTime} />
              }
            />
          </Routes>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

export default App;
