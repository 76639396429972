import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Avatar from "../../../../Assets/Images/userAvatar.png";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  RecurrentTypes,
  ReminderParticipantRole,
} from "../../../../Components/Calendar/types/enum";
import {
  Button,
  IconButton,
  LinearProgress,
  Pagination,
  Snackbar,
} from "@mui/material";
import { CALENDER_SOCKET_EVENTS, colors } from "../../../../Constant/Constant";
import { authCllient } from "../../../../Graphql/authClient";
import { UPDATE_REMINDER_STATUS } from "../../../../Graphql/Mutations";
import { setActiveRoomReminders } from "../../../../Redux/ChatSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import formatReminderData from "./formatReminderData";
import { openEditEventDialog } from "../../../../Redux/EventSlice";
import DateFilter from "./DateFilter";
import { GET_APPOINTMENT_BY_ROOM_ID } from "../../../../Graphql/Queries";
import { socketListen } from "../../../../Socket";
import determineDateText from "../../../../utils/determineDateText";

type PropTypes = {
  setShow: (value: boolean) => void;
  setRoomRightDrawer: (value: boolean) => void;
};

enum APIStatus {
  Loading = "loading",
  Idle = "idl",
  Error = "error",
  Success = "success",
}

function ReminderDrawer(props: PropTypes) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setShow } = props;
  const { userData } = useSelector((state: any) => state?.user);
  const { activeRoomReminders, activeRoomData } = useSelector(
    (state: any) => state?.chat
  );
  const [alert, setAlert] = useState({ show: false, msg: "" });
  const [apiStatus, setApiStatus] = useState(APIStatus.Idle);
  const [loading, setloading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [filterDates, setFilterDates] = useState({ from: null, to: null });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pageRef = useRef(null);

  async function getRemindersByRoomId() {
    setloading(true);
    try {
      const response = await authCllient.query({
        query: GET_APPOINTMENT_BY_ROOM_ID,
        variables: {
          input: {
            roomId: activeRoomData?._id,
            pageNo: page,
            limit: 10,
            ...(filterDates?.from &&
              filterDates?.to && {
                From: filterDates?.from,
                To: filterDates?.to,
              }),
          },
        },
      });

      if (response?.data?.getReminderRange) {
        setTotalPage(response?.data?.getReminderRange?.[0]?.totalPage);
        dispatch(setActiveRoomReminders(response?.data?.getReminderRange));
      }
    } catch (err) {
      console.log(`Error at get reminders: ${err}`);
    } finally {
      setloading(false);
    }
  }

  useEffect(() => {
    getRemindersByRoomId();
  }, [page]);

  useEffect(() => {
    if (isRefresh) {
      getRemindersByRoomId();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  socketListen("message", (data: any) => {
    if (CALENDER_SOCKET_EVENTS?.includes(data?.type)) {
      setIsRefresh(true);
    }
  });

  function updateData(_id: string, participants: any) {
    const updated = activeRoomReminders?.map((item) => {
      if (item?._id == _id) {
        return {
          ...item,
          participants,
        };
      } else {
        return item;
      }
    });
    dispatch(setActiveRoomReminders(updated));
  }

  async function handleChangeStatus(ApprovalStatus: string, data: any) {
    setApiStatus(APIStatus.Loading);
    try {
      const response = await authCllient.mutate({
        mutation: UPDATE_REMINDER_STATUS,
        variables: {
          input: {
            _id: data?._id,
            ApprovalStatus,
          },
        },
      });

      if (response?.data?.updateReminderApprovalStatus) {
        updateData(
          data?._id,
          response?.data?.updateReminderApprovalStatus?.participants
        );
        setApiStatus(APIStatus.Success);
        const message =
          ApprovalStatus == ParticipantAcceptStatus.ACCEPT
            ? "invitation_accept_msg"
            : "invitation_reject_msg";
        setAlert({
          msg: message,
          show: true,
        });
      } else {
        setApiStatus(APIStatus.Error);
      }
    } catch (err) {
      setApiStatus(APIStatus.Error);
    }
  }

  function handleAction(reminder: any) {
    const formatted = formatReminderData(reminder);
    dispatch(openEditEventDialog(formatted));
  }

  function handlePageChange(ev, value) {
    setPage(value);
    pageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div ref={pageRef}>
      <Snackbar
        open={alert.show}
        message={t(alert.msg)}
        autoHideDuration={3000}
        onClose={() => setAlert({ show: false, msg: "" })}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />

      <div className="sticky top-0 z-10">
        <div className="flex justify-between items-center py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
          <div
            className="flex  items-center cursor-pointer"
            onClick={() => setShow(false)}
          >
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
            <div className=" font-bold capitalize">{t("reminders")}</div>
          </div>
        </div>
        {loading && <LinearProgress color="primary" sx={{ height: "2px" }} />}
      </div>

      <DateFilter
        filterDates={filterDates}
        setFilterDates={setFilterDates}
        setIsRefresh={setIsRefresh}
        loading={loading}
      />

      {activeRoomReminders?.length > 0 ? (
        <div className="grid gap-2 mt-2">
          {activeRoomReminders?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className="px-2 font-semibold text-sm text-gray-700 mb-3">
                  {determineDateText(item?.date, t)}
                </div>

                {item?.reminders?.map((reminder: any, key: number) => {
                  const self = reminder?.participants?.find(
                    (el) => el?._id == userData?._id
                  );

                  return (
                    <div
                      key={key}
                      className={clsx(
                        "p-5 border-b",
                        self?.role == ReminderParticipantRole.USER
                          ? "cursor-pointer"
                          : ""
                      )}
                      onClick={() => {
                        if (self?.role == ReminderParticipantRole.USER) {
                          handleAction(reminder);
                        }
                      }}
                    >
                      <div className="flex justify-between">
                        <div>
                          <div className="flex gap-5">
                            <div>
                              <img
                                src={Avatar}
                                alt="avatar"
                                className="h-[50px] w-[50px]"
                              />
                            </div>

                            <div>
                              <div className="font-semibold">
                                {reminder?.title}
                              </div>

                              <div className="text-sm text-gray-500">
                                {dayjs(reminder?.startDate).format("HH:mm")}
                              </div>

                              <div className="flex gap-3 mt-2">
                                <div
                                  className={clsx(
                                    "text-[12px] text-white px-4 py-1 w-fit rounded-md"
                                  )}
                                  style={{
                                    backgroundColor:
                                      reminder?.type ==
                                      EventTypeEnum.APPOINTMENT
                                        ? colors.appointment
                                        : reminder?.type ==
                                          EventTypeEnum.REMINDER
                                        ? colors.reminder
                                        : colors.callReminder,
                                  }}
                                >
                                  {t(reminder?.recursive)}
                                </div>

                                <div>
                                  <GroupIcon
                                    sx={{
                                      color:
                                        reminder?.type ==
                                        EventTypeEnum.APPOINTMENT
                                          ? colors.appointment
                                          : reminder?.type ==
                                            EventTypeEnum.REMINDER
                                          ? colors.reminder
                                          : colors.callReminder,
                                    }}
                                  />
                                  <span className="ml-1">
                                    {reminder?.participants?.length}
                                  </span>
                                </div>

                                {reminder?.approvalReminderTime?.length > 0 && (
                                  <div>
                                    {self?.accepted ==
                                    ParticipantAcceptStatus.ACCEPT ? (
                                      <NotificationsIcon
                                        sx={{
                                          color:
                                            reminder?.type ==
                                            EventTypeEnum.APPOINTMENT
                                              ? colors.appointment
                                              : reminder?.type ==
                                                EventTypeEnum.REMINDER
                                              ? colors.reminder
                                              : colors.callReminder,
                                        }}
                                      />
                                    ) : (
                                      <NotificationsOffIcon
                                        sx={{
                                          color:
                                            reminder?.type ==
                                            EventTypeEnum.APPOINTMENT
                                              ? colors.appointment
                                              : reminder?.type ==
                                                EventTypeEnum.REMINDER
                                              ? colors.reminder
                                              : colors.callReminder,
                                        }}
                                      />
                                    )}

                                    <span className="ml-1">
                                      {reminder?.approvalReminderTime?.length}
                                    </span>
                                  </div>
                                )}

                                {reminder?.attachment?.length > 0 && (
                                  <div>
                                    <AttachFileIcon
                                      sx={{
                                        color:
                                          reminder?.type ==
                                          EventTypeEnum.APPOINTMENT
                                            ? colors.appointment
                                            : reminder?.type ==
                                              EventTypeEnum.REMINDER
                                            ? colors.reminder
                                            : colors.callReminder,
                                      }}
                                    />
                                    <span className="ml-1">
                                      {reminder?.attachment?.length}
                                    </span>
                                  </div>
                                )}

                                {reminder?.location?.address && (
                                  <div>
                                    <LocationOnIcon
                                      sx={{
                                        color:
                                          reminder?.type ==
                                          EventTypeEnum.APPOINTMENT
                                            ? colors.appointment
                                            : reminder?.type ==
                                              EventTypeEnum.REMINDER
                                            ? colors.reminder
                                            : colors.callReminder,
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {self?.accepted ==
                            ParticipantAcceptStatus.PENDING && (
                            <div className="mt-4 flex gap-5">
                              <Button
                                size="small"
                                color="success"
                                variant="outlined"
                                sx={{ textTransform: "capitalize" }}
                                disabled={Boolean(
                                  apiStatus == APIStatus.Loading
                                )}
                                onClick={(
                                  event: MouseEvent<HTMLButtonElement>
                                ) => {
                                  event?.stopPropagation();
                                  handleChangeStatus(
                                    ParticipantAcceptStatus.ACCEPT,
                                    reminder
                                  );
                                }}
                              >
                                {t("accept")}
                              </Button>
                              <Button
                                size="small"
                                color="error"
                                variant="outlined"
                                sx={{ textTransform: "capitalize" }}
                                disabled={Boolean(
                                  apiStatus == APIStatus.Loading
                                )}
                                onClick={(
                                  event: MouseEvent<HTMLButtonElement>
                                ) => {
                                  event?.stopPropagation();
                                  handleChangeStatus(
                                    ParticipantAcceptStatus.REJECT,
                                    reminder
                                  );
                                }}
                              >
                                {t("reject")}
                              </Button>
                            </div>
                          )}

                          {apiStatus == APIStatus.Error && (
                            <div className="text-center text-sm text-red-600">
                              {t("something_went_wrong")}
                            </div>
                          )}
                        </div>

                        {self?.role == ReminderParticipantRole.ADMIN && (
                          <IconButton
                            className="h-fit"
                            onClick={(ev) => handleAction(reminder)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="flex justify-center mt-3 pb-5">
            <Pagination
              page={page}
              color="primary"
              count={totalPage}
              variant="outlined"
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">{t("no_reminders")}</div>
      )}
    </div>
  );
}

export default ReminderDrawer;
