import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { useEffect, useMemo, useRef, useState } from "react";
import { LinearProgress, Snackbar, styled } from "@mui/material";
import {
  getAllEvents,
  openEditEventDialog,
  openNewEventDialog,
  updateEvent,
} from "../../Redux/EventSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import CalendarAppHeader from "./header/CalendarAppHeader";
import CalendarAppEventContent from "./CalendarAppEventContent";
import { EventType } from "./types/types";
import EventDialog from "./dialogs/event/EventDialog";
import { authCllient } from "../../Graphql/authClient";
import {
  GET_APPOINTMENTS_REMINDERS,
  GET_ASSIGNMENTS_FOR_CALENDAR,
} from "../../Graphql/Queries";
import {
  EventTypeEnum,
  ParticipantAcceptStatus,
  ReminderParticipantRole,
} from "./types/enum";
import { socketListen } from "../../Socket";
import {
  CALENDER_SOCKET_EVENTS,
  SCHEDULE_SOCKET_EVENT,
} from "../../Constant/Constant";
import formatAssignmentData from "./utils/formatAssignmentData";
import AssignmentDialog from "./dialogs/assignment/AssignmentDialog";
import { useTranslation } from "react-i18next";
import { DRAG_AND_DROP_EVENT } from "../../Graphql/Mutations";
import formatScheduleData from "./utils/formatScheduleData";
import ScheduleModal from "../../Pages/ChatPage/schedule/ScheduleModal";
import listPlugin from "@fullcalendar/list";
import RecordModal from "../../Pages/RightTab/database/RecordModal";

type TModalMode = "edit" | "view";

interface IProps {
  setTabValue: (value: number) => void;
  setTaskTab: (value: number) => void;
}

const Root = styled("div")(({ theme }) => ({
  "& a": {
    color: `${theme.palette.text.primary}!important`,
    textDecoration: "none!important",
  },
  "& .fc-media-screen, & .css-11h3ioz": {
    minHeight: "100%",
    width: "100%",
    height: "calc(100vh - 80px)",
    overflow: "auto",
  },
  "& .fc-scrollgrid, & .fc-theme-standard td, & .fc-theme-standard th": {
    borderColor: `${theme.palette.divider}!important`,
  },
  "& .fc-scrollgrid-section > td": {
    border: 0,
  },
  "& .fc-daygrid-day": {
    "&:last-child": {
      borderRight: 0,
    },
  },
  "& .fc-col-header-cell": {
    borderWidth: "0 1px 0 1px",
    padding: "8px 0 0 0",
    "& .fc-col-header-cell-cushion": {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: 12,
      textTransform: "uppercase",
    },
  },
  "& .fc-view ": {
    "& > .fc-scrollgrid": {
      border: 0,
      // height: '78vh!important',
      overflow: "scroll",
    },
  },
  "& .fc-daygrid-day.fc-day-today": {
    backgroundColor: "transparent!important",
    "& .fc-daygrid-day-number": {
      borderRadius: "100%",
      backgroundColor: `${theme.palette.primary.main}!important`,
      color: `${theme.palette.secondary.contrastText}!important`,
    },
  },
  "& .fc-daygrid-day-top": {
    justifyContent: "center",

    "& .fc-daygrid-day-number": {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: 12,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: 26,
      height: 26,
      margin: "4px 0",
      borderRadius: "50%",
      float: "none",
      lineHeight: 1,
    },
  },
  "& .fc-h-event": {
    background: "initial",
  },
  "& .css-pa4bhq, .fc-event": {
    margin: "0 !important",
  },
  "& .fc-event": {
    border: 0,
    padding: "0 ",
    fontSize: 12,
    margin: "0 6px 4px 6px!important",
  },
  "& .fc-timegrid-event-harness-inset .fc-timegrid-event, & .fc-timegrid-event.fc-event-mirror, & .fc-timegrid-more-link":
    {
      boxShadow: "none",
    },
  ".fc .fc-popover": {
    zIndex: "1 !important",
  },
  "& .fc .fc-cell-shaded, .fc .fc-day-disabled": {
    background: "transparent",
  },
}));

function CalendarApp(props: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const { setTabValue, setTaskTab } = props;
  const { rooms } = useSelector((state: any) => state?.chat);
  const { userData } = useSelector((state: any) => state?.user);
  const [assignments, setAssignments] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState("dayGridMonth");
  const [toast, setToast] = useState({ show: false, msg: "" });
  const [scheduleData, setScheduleData] = useState(null);
  const [showSchedule, setShowSchedule] = useState(false);
  const [recordModal, setRecordModal] = useState({ data: null, show: false });
  const [recordModalMode, setRecordModalMode] = useState<TModalMode>("edit");
  const [assignmentModal, setAssignmentModal] = useState({
    data: null,
    show: false,
  });
  const events: EventType[] = useSelector(
    (state: any) => state?.events?.events
  );

  socketListen("message", (ev: any) => {
    if (
      SCHEDULE_SOCKET_EVENT?.includes(ev?.type) ||
      CALENDER_SOCKET_EVENTS?.includes(ev?.type)
    ) {
      setIsRefresh(true);
    }
  });

  function getRoomsParticipants(roomId: string) {
    const find = rooms?.find((item) => item?._id == roomId);
    const participants = find?.participants?.filter(
      (item) => item?.user_id != userData?._id
    );
    return participants;
  }

  async function getRemindersAndAppointments() {
    setLoading(true);
    const tempEvents = [];

    await authCllient
      .query({
        query: GET_APPOINTMENTS_REMINDERS,
      })
      .then((res) => {
        if (res?.data?.getAppointmentForCalendar) {
          const tempAppointments = [];
          res?.data?.getAppointmentForCalendar?.forEach((item) => {
            item?.participants?.forEach((el) => {
              if (
                el?._id == userData?._id &&
                (el?.accepted == ParticipantAcceptStatus.ACCEPT ||
                  el?.accepted == ParticipantAcceptStatus.PAUSE)
              ) {
                tempAppointments.push(item);
              }
            });
          });

          tempAppointments?.forEach((item) => {
            const roomParticipants = getRoomsParticipants(item?.roomId);
            const start = dayjs(item?.date)
              .set("hours", dayjs(item?.time)?.hour())
              .set("minute", dayjs(item?.time)?.minute())
              .set("second", dayjs(item?.time)?.second());
            const end = dayjs(item?.date)
              .set("hours", dayjs(item?.endTime)?.hour())
              .set("minute", dayjs(item?.endTime)?.minute())
              .set("second", dayjs(item?.endTime)?.second());

            tempEvents.push({
              _id: item?._id,
              parent_id: item?.parent_id,
              title: item?.title,
              roomId: item?.roomId,
              roomParticipants: roomParticipants?.length
                ? roomParticipants
                : [],
              type: item?.type,
              frequency: item?.recursive,
              days: item?.daylyParams?.dayOfWeeks,
              noOfWeeks: item?.daylyParams?.everyWeek,
              repeatEveryMonth: item?.monthlyParams?.twicePerMonth ?? true,
              months: item?.monthlyParams?.months,
              notifications: item?.approvalReminderTime?.length
                ? item?.approvalReminderTime?.map((el) => ({
                    Unit: el?.Unit,
                    Count: el?.Count,
                  }))
                : [],
              participants: item?.participants,
              attachments: item?.attachment?.length
                ? item?.attachment?.map((el) => {
                    const { __typename, ...rest } = el;
                    return rest;
                  })
                : [],
              location: item?.location,
              description: item?.description,
              isAllDay: item?.isAllDay,
              monthDateType: item?.monthlyParams?.onWeek ? "week" : "day",
              onDay: item?.monthlyParams?.onDay ?? 1,
              onWeek: item?.monthlyParams?.onWeek?.dayOfWeeks?.[0] ?? "MON",
              everyWeek: item?.monthlyParams?.onWeek?.everyWeek ?? 1,
              hasComon: item?.hasComon,
              isConfirmationNeeded: item?.isConfirmationNeeded,
              roomType: item?.roomType,
              start: start,
              end: end,
              time: dayjs(item?.time),
              endTimes: dayjs(item?.endTime),
              from: item?.startDate,
              to: item?.endDate,
              isApprovalNeeded: item?.isApprovalNeeded,
              message: item?.message,
            });
          });

          // const formatted = formatCalendarData(tempEvents)
          dispatch(getAllEvents(tempEvents));
        }
      })
      .catch((error) => console.log(`Error at fetching calendar -> ${error}`))
      .finally(() => setLoading(false));
  }

  async function getAssignmentsForCalendar() {
    try {
      setLoading(true);

      const response = await authCllient.query({
        query: GET_ASSIGNMENTS_FOR_CALENDAR,
      });

      if (response?.data?.getAssignmentForCalendar) {
        const formatted = formatAssignmentData(
          response?.data?.getAssignmentForCalendar
        );
        setAssignments(formatted);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRemindersAndAppointments();
    getAssignmentsForCalendar();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      getRemindersAndAppointments();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const handleDates = (rangeInfo: any) => {
    setCurrentDate(rangeInfo);
  };

  function handleDateSelect(selectInfo: any) {
    const tempObj = { ...selectInfo };
    tempObj.roomType = "";
    dispatch(openNewEventDialog(tempObj));
  }

  async function handleDropEvent(info: any) {
    const { event, revert } = info;
    const newDate = dayjs(event?.start);
    const { extendedProps } = event;
    const currentDate = new Date()?.setHours(0, 0, 0, 0);
    const self = extendedProps?.participants?.find(
      (el: any) => el?._id == userData?._id
    );

    if (event?.start?.getTime() < currentDate) {
      setToast({ msg: t("event_cant_drop"), show: true });
    } else {
      if (
        extendedProps?.type == "ASSIGNMENT" ||
        extendedProps?.type == EventTypeEnum.Record_Reminder
      ) {
        setToast({ msg: t("assignment_cant_drop"), show: true });
      } else if (self?.role != ReminderParticipantRole.ADMIN) {
        setToast({ msg: t("only_admin_can_update_event"), show: true });
      } else {
        const { _id, type, participants, time } = extendedProps || {};

        let date: any = "";
        if (viewMode == "dayGridMonth") {
          date = dayjs(newDate)
            ?.set("hours", time?.get("hours"))
            ?.set("minutes", time?.get("minutes"));
        } else {
          date = dayjs(newDate)
            ?.set("hours", newDate?.get("hours"))
            ?.set("minutes", newDate?.get("minutes"));
        }

        const updated = events?.map((event) => {
          if (event?._id == _id) {
            return {
              ...event,
              date: date,
              start: date,
              end: date,
            };
          }
          return event;
        });

        dispatch(updateEvent(updated));

        const payload = {
          _id,
          type,
          date: date,
          title: event?.title?.trim(),
          startTimeInMs: dayjs(newDate)?.unix(),
          time: viewMode == "dayGridMonth" ? time : newDate?.toISOString(),
          participants: participants?.map((item) => {
            const { __typename, profile_img, left_at, ...rest } = item;
            return rest;
          }),
          thisOccurrence: true,
          allOccurrence: false,
          parent_id: extendedProps?.parent_id,
        };

        try {
          const response = await authCllient.mutate({
            mutation: DRAG_AND_DROP_EVENT,
            variables: {
              input: payload,
            },
          });
          if (!response?.data) {
            setToast({ msg: t("something_went_wrong"), show: true });
          } else {
            setToast({ msg: t("event_dropped"), show: true });
          }
        } catch (error) {
          setToast({ msg: t("something_went_wrong"), show: true });
        }
      }
    }

    revert();
  }

  function handleEventClick(clickInfo: any) {
    const extendedProps = clickInfo?.event?.extendedProps;
    if (extendedProps?.type == EventTypeEnum.SCHEDULE) {
      const data = formatScheduleData({
        ...extendedProps,
        start: clickInfo?.event?.start,
      });
      setScheduleData(data);
      setShowSchedule(true);
    } else if (extendedProps?.type == "ASSIGNMENT") {
      setAssignmentModal({
        data: {
          ...extendedProps,
          title: clickInfo?.event?.title,
          start: clickInfo?.event?.start,
        },
        show: true,
      });
    } else if (extendedProps?.type == EventTypeEnum.Record_Reminder) {
      setRecordModalMode("view");
      setRecordModal({
        data: { recordId: extendedProps?.parent_id },
        show: true,
      });
    } else {
      const temp = { ...clickInfo };
      const filtered = events?.filter(
        (item) => item?._id == extendedProps?._id
      );
      temp.start = filtered?.[0]?.start;
      temp.end = filtered?.[filtered?.length - 1]?.end;
      dispatch(openEditEventDialog(temp));
    }
  }

  return (
    <Root>
      <div className="sticky top-0 bg-white" style={{ zIndex: 9 }}>
        <CalendarAppHeader
          viewMode={viewMode}
          isRefresh={isRefresh}
          calendarRef={calendarRef}
          currentDate={currentDate}
          setViewMode={setViewMode}
          setTabValue={setTabValue}
          setIsRefresh={setIsRefresh}
        />

        {loading && <LinearProgress sx={{ height: "2px" }} />}
      </div>

      <div className="relative">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            multiMonthPlugin,
            listPlugin,
          ]}
          editable
          selectable
          selectMirror
          nowIndicator
          allDaySlot
          allDayText={t("all_day")}
          initialView="dayGridMonth"
          weekNumberCalculation="ISO"
          ref={calendarRef}
          dayMaxEvents={3}
          headerToolbar={false}
          eventOverlap={false}
          slotEventOverlap={false}
          showNonCurrentDates={false}
          fixedWeekCount={false}
          datesSet={handleDates}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventDrop={handleDropEvent}
          selectAllow={(selectInfo) => {
            return (
              selectInfo?.start?.getTime() >= new Date()?.setHours(0, 0, 0, 0)
            );
          }}
          eventContent={(eventInfo: any) => (
            <CalendarAppEventContent eventInfo={eventInfo} />
          )}
          events={useMemo(() => {
            if (events?.length > 0 || assignments?.length > 0) {
              return [...assignments, ...events]?.map((event: EventType) => ({
                ...event,
                start: new Date(event?.start),
                end: new Date(event?.end),
                allDay: event?.isAllDay,
              }));
            }
            return [];
          }, [events, assignments])}
        />
      </div>

      <EventDialog setIsRefresh={setIsRefresh} />

      {assignmentModal?.show && (
        <AssignmentDialog
          show={assignmentModal?.show}
          assignment={assignmentModal?.data}
          setAssignmentModal={setAssignmentModal}
          setTabValue={setTabValue}
          setTaskTab={setTaskTab}
        />
      )}

      {showSchedule && (
        <ScheduleModal
          modalType="edit"
          show={showSchedule}
          scheduleData={scheduleData?.message}
          setShow={setShowSchedule}
          editScheduleData={scheduleData}
          setToastShow={setToast}
          fromCalendar={true}
        />
      )}

      {recordModal.show && (
        <RecordModal
          globalId=""
          fromCalendar={true}
          currentDatabase={null}
          show={recordModal.show}
          setShow={setRecordModal}
          setIsRefresh={setIsRefresh}
          modalMode={recordModalMode}
          setModalMode={setRecordModalMode}
          recordId={recordModal?.data?.recordId}
        />
      )}

      <Snackbar
        open={toast.show}
        message={t(toast.msg)}
        autoHideDuration={3000}
        onClose={() => setToast({ show: false, msg: "" })}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Root>
  );
}

export default CalendarApp;
