import { useTranslation } from "react-i18next";
import { DayOfWeek, EventTypeEnum, Month } from "../Calendar/types/enum";
import React, { ChangeEvent } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { getCalendarColor } from "../Calendar/utils/calendarColors";

const weekNumbers = [
  { label: "First", value: 1 },
  { label: "Second", value: 2 },
  { label: "Third", value: 3 },
  { label: "Forth", value: 4 },
  { label: "Last", value: 5 },
];

const weekDays = [
  { label: DayOfWeek.MON, value: "MON" },
  { label: DayOfWeek.TUE, value: "TUE" },
  { label: DayOfWeek.WED, value: "WED" },
  { label: DayOfWeek.THU, value: "THU" },
  { label: DayOfWeek.FRI, value: "FRI" },
  { label: DayOfWeek.SAT, value: "SAT" },
  { label: DayOfWeek.SUN, value: "SUN" },
];

interface PropType {
  form: any;
  setForm: (value: any) => void;
  formType: string;
}

function MonthlyRecurrent(props: PropType) {
  const { t } = useTranslation();
  const { form, setForm, formType } = props;

  function handleSelectMonths(
    event: React.ChangeEvent<HTMLInputElement>,
    month: string
  ) {
    const checked = event?.target?.checked;
    if (checked) {
      const temp = [...form?.months];
      temp.push(month);
      setForm({ ...form, months: temp });
    } else {
      const updated = form?.months?.filter((item) => item != month);
      setForm({ ...form, months: updated });
    }
  }

  function handleMonthDateTypeChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const value = event?.target?.value;
    setForm({ ...form, monthDateType: value });
  }

  function handleDateChange(value) {
    const newV = parseInt(value, 10);
    if (newV < 1) {
      setForm({ ...form, onDay: 1 });
    } else if (newV > 31) {
      setForm({ ...form, onDay: 31 });
    } else {
      setForm({ ...form, onDay: newV });
    }
  }

  return (
    <div className="mt-1">
      <div className="flex flex-wrap">
        {Object.entries(Month)?.map(([key, value]) => {
          const isSelected = form?.months?.find((item) => item == key);
          const monthIndex = Object?.keys(Month)?.indexOf(key);

          const startMonth =
            formType == EventTypeEnum.REMINDER
              ? form?.start?.month()
              : form?.startDate?.month();

          const startYear =
            formType == EventTypeEnum.REMINDER
              ? form?.start?.year()
              : form?.startDate?.year();

          const endMonth =
            formType == EventTypeEnum.REMINDER
              ? form?.end?.month()
              : form?.endDate?.month();

          const endYear =
            formType == EventTypeEnum.REMINDER
              ? form?.end?.year()
              : form?.endDate?.year();

          let isDisabled: boolean;
          if (startYear === endYear) {
            isDisabled = monthIndex < startMonth || monthIndex > endMonth;
          } else {
            isDisabled =
              monthIndex < startMonth &&
              monthIndex > endMonth &&
              endYear - startYear > 1;
          }

          return (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => handleSelectMonths(e, key)}
                  disabled={
                    Boolean(form?.repeatEveryMonth) || Boolean(isDisabled)
                  }
                  checked={
                    Boolean(isSelected == key) ||
                    Boolean(form?.repeatEveryMonth)
                  }
                  sx={{
                    ".MuiSvgIcon-root": {
                      color: getCalendarColor(form?.type, "dark"),
                    },
                  }}
                />
              }
              label={<div className="text-sm">{t(value)}</div>}
            />
          );
        })}

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={Boolean(form?.repeatEveryMonth)}
              onChange={(e) => {
                setForm({
                  ...form,
                  repeatEveryMonth: e.target.checked,
                  months: [],
                });
              }}
              sx={{
                ".MuiSvgIcon-root": {
                  color: getCalendarColor(form?.type, "dark"),
                },
              }}
            />
          }
          label={<div className="text-sm">{t("select_all_months")}</div>}
        />
      </div>

      <div className="flex w-full gap-5 mt-2">
        <FormControl sx={{ marginTop: "-7px" }}>
          <RadioGroup
            value={form?.monthDateType}
            name="controlled-radio-buttons-group"
            onChange={handleMonthDateTypeChange}
            sx={{ display: "grid", gap: 1 }}
          >
            <div className="flex gap-5 items-center">
              <FormControlLabel
                value={"day"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      ".css-hyxlzm": {
                        color: getCalendarColor(form?.type, "dark"),
                      },
                    }}
                  />
                }
                label={
                  <div className="text-sm">
                    {t("on") + " " + t("day")?.toLowerCase()}
                  </div>
                }
              />

              {form?.monthDateType == "day" && (
                <div>
                  <input
                    type="number"
                    value={form?.onDay}
                    onChange={(ev) => handleDateChange(ev?.target?.value)}
                    className="p-2 max-w-[100px] text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none"
                  />
                </div>
              )}
            </div>

            <div className="flex gap-5 items-center">
              <FormControlLabel
                value={"week"}
                control={
                  <Radio
                    size="small"
                    sx={{
                      ".css-hyxlzm": {
                        color: getCalendarColor(form?.type, "dark"),
                      },
                    }}
                  />
                }
                label={
                  <div className="text-sm">{t("on") + " " + t("the")}</div>
                }
              />

              {form?.monthDateType == "week" && (
                <div className="flex items-center gap-5">
                  <select
                    value={form?.everyWeek}
                    onChange={(ev) =>
                      setForm({
                        ...form,
                        everyWeek: parseInt(ev?.target?.value),
                      })
                    }
                    className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {weekNumbers.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item?.value}>
                          {t(item?.label)}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    value={form?.onWeek}
                    onChange={(ev) =>
                      setForm({ ...form, onWeek: ev?.target?.value })
                    }
                    className="bg-gray-50 outline-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {weekDays.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item?.value}>
                          {t(item?.label)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default MonthlyRecurrent;
