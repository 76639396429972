import { DefaultImageUrl } from "../../../Constant/Constant";

const ReportQuestionImage = ({ data }: any) => {
  return (
    <>
      {(data?.attachment?.type === "IMAGE" ||
        data?.attachment?.type === "PHOTO") && (
        <div className="h-[250px] w-full min-w-[300px] mt-3">
          <img
            src={DefaultImageUrl + data?.attachment?.filename}
            alt=""
            className="h-full w-full object-contain rounded-md"
          />
        </div>
      )}
    </>
  );
};

export default ReportQuestionImage;
