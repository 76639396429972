import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { Avatar, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DefaultImageUrl } from "../../Constant/Constant";
import call from "../../Assets/Images/call.png";
import videoCall from "../../Assets/Images/videoCall.png";
import { setActiveRoomData, setInitiatedCallData, updateCreateCall } from "../../Redux/ChatSlice";
import { authCllient } from "../../Graphql/authClient";
import CloseIcon from '@mui/icons-material/Close';
import { ParticipantsDataTypes } from "../../Types/Types";
import moment from "moment";
import { CALL_STATUS, CREATE_CALL, UPDATE_CONTACT_PROFILE } from "../../Graphql/Mutations";
import { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
export default function ContactDetails({ setShowContactDetails, setRoomRightDrawer }) {
    const {
        activeRoomData,
        formattedParticipantsOfActiveRoom,
        favouriteChats,
        callisRunning,
        joinedFromOtherDevice,
        userOnlineStatus
    } = useSelector((state: any) => state.chat)
    const dispatch = useDispatch();
    const [form, setForm] = useState<any>({ ...activeRoomData?.contactData });
    const [load, setLoad] = useState(false);
    const [additionalField, setAdditionalField] = useState(activeRoomData?.contactData===null||activeRoomData?.contactData?.additional===""?[]: activeRoomData?.contactData?.additional ? JSON.parse(activeRoomData?.contactData?.additional) : "");
    const { userData, userContacts, formattedContact } = useSelector((state: any) => state.user)
    const { t } = useTranslation();
    const roomDetails = activeRoomData?.participants.length === 1 ?
        activeRoomData?.participants[0]
        : activeRoomData?.participants?.find(
            (item: any) => item?.user_id !== userData?._id
        );
    const [disableCallButton, setDisabledCallButton] = useState(false);

    const isRoomBlocked = activeRoomData?.participants?.some((item: any) =>
        item?.user_id === userData?._id && item.left_at !== 0 ? true : false
    );

    const otherUserData = activeRoomData?.participants?.find(
        (one: any) => one?.user_id !== userData?._id
    );
    const contactDetails = formattedContact?.[otherUserData?.user_id]

    const isUserBlockedYou =
        activeRoomData?.type !== "group" && otherUserData?.left_at !== 0
            ? true
            : false;
    const userContactDetails = userContacts?.find(
        (item: any) => item?.userId?._id === roomDetails?.user_id
    );
    const handleCallInitiate = (type: string) => {
        const participants = [];
        activeRoomData?.participants?.map((item: ParticipantsDataTypes) => {
            if (item?.user_id !== userData?._id) {
                participants.push(item?.user_id);
            }
        });
        dispatch(updateCreateCall(true));
        setDisabledCallButton(true);

        authCllient
            .mutate({
                mutation: CREATE_CALL,
                variables: {
                    input: {
                        origin: String(userData._id),
                        participants: participants,
                        channelName: String(new Date().getTime()),
                        type: type,
                        roomId: activeRoomData._id,
                        roomType: activeRoomData.type,
                    },
                },
            })
            .then((res) => {
                setRoomRightDrawer(false);
                handleChangeCallStatus(res?.data?.createNewCall?.call?._id)
                dispatch(setInitiatedCallData(res?.data?.createNewCall?.call));
            })
            .catch((error) => console.log("error at Create call", error))
            .finally(() => setDisabledCallButton(false));
    };

    const handleChangeCallStatus = async (callId: string) => {
        const payload = {
          callId,
          status: "accepted",
          userId: userData._id,
        };
        try {
          const response = await authCllient.mutate({
            mutation: CALL_STATUS,
            variables: {
              changeCallStatus: payload,
            },
          });
          if (response?.data?.changeCallStatus) {
            console.log(response?.data?.changeCallStatus);
          }
        } catch (error) {
          console.log(error);
        }
      };

    const showLastSeen: any = () => {
        let time: any;

        if (
            activeRoomData?.type !== "group" &&
            userOnlineStatus?.msg?.roomId !== activeRoomData?._id
        ) {
            const timeStamp = moment(userOnlineStatus?.msg?.time).format("HH:mm ");
            const gettingDate = moment(userOnlineStatus?.msg?.time).format(
                "DD.MM.YYYY"
            );

            const todayDate = moment(new Date()).format("DD.MM.YYYY");

            const yesterdayDate = moment(new Date())
                .subtract(1, "day")
                .format("DD.MM.YYYY");

            if (gettingDate === todayDate) {
                time = `${t('lastSeen')} ` + timeStamp;
            } else if (gettingDate === yesterdayDate) {
                time = t("last_seen_yesterday");
            } else {
                time = gettingDate;
            }
        } else {
            time = t("online");
        }

        return (
            <span>
                <span>
                    <span className="ml-1">{time}</span>
                </span>
            </span>
        );
    };
    const updateContactInfo = () => {
        setLoad(true);
        const additional = additionalField.filter((el: any) => el.label !== "");
        authCllient
            .mutate({
                mutation: UPDATE_CONTACT_PROFILE,
                variables: {
                    input: {
                        phone: form.phone,
                        firstName: form.firstName,
                        lastName: form.lastName,
                        email: form.email,
                        dob: form.dob,
                        country: form.country,
                        region: form.region,
                        city: form.city,
                        street: form.street,
                        prefix: form.prefix,
                        suffix: form.suffix,
                        gender: form.gender,
                        address: form.address,
                        website: form.website,
                        additional: JSON.stringify(additional)
                    },
                },
            })
            .then((res) => {
                console.log(res, res?.data?.UpdateContactProfile);
                dispatch(setActiveRoomData({ ...activeRoomData, "contactData": { ...activeRoomData?.contactData, ...res?.data?.updateContactProfile } }))
            })
            .catch((error) => console.log("error at Create call", error))
            .finally(() => { setShowContactDetails(false); setLoad(false); })

    }
    const addExtraAdditionalField = () => {
        const newData = { label: "", value: "" };
        setAdditionalField([...additionalField, newData])
    }

    const removeExtraAdditionalField = (data: any) => {
        const final = additionalField?.filter((el: any) => el.label !== data);
        setAdditionalField(final);
    }
    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setForm({ ...form, dob: dateString })
      };

      useEffect(()=>{
        if(document.querySelector('#parent-container')){
        document.querySelector('#parent-container').scrollIntoView()}
      },[])
    return (
        <div className="h-[100vh]   relative">
            <div className=" bg-white w-[31rem] border-b-2  fixed top-0 z-[999999]">
                <div
                    className="flex items-center w-full justify-between bg-[#F3F9FC] border-b   cursor-pointer"
                    onClick={() => setShowContactDetails(false)}
                >
                    <div className="flex items-center">
                        <div className="py-2 flex">
                            <ArrowBackIosNewIcon />
                        </div>
                        <div className="pl-1">{t("back")}</div>
                    </div>
                    <div className="flex p-2">
                        {activeRoomData?.participants.length !== 1 && !isRoomBlocked && <>
                            <button
                                className="px-2 cursor-pointer"
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                    if (!callisRunning && !joinedFromOtherDevice) {
                                        handleCallInitiate("audio");
                                    } else {
                                        alert(
                                            t('call_validation')
                                        );
                                    }
                                }}
                            >
                                <img src={call} alt="" className="w-8 h-8" />
                            </button>
                            <button
                                className="cursor-pointer"
                                disabled={disableCallButton ? true : false}
                                onClick={() => {
                                    if (!callisRunning && !joinedFromOtherDevice) {
                                        handleCallInitiate("video");
                                    } else {
                                        alert(
                                            t("call_validation")
                                        );
                                    }
                                }}
                            >
                                <img src={videoCall} alt="" className="w-8 h-8" />
                            </button>
                        </>}
                    </div>
                </div>
                </div>
                <div className="relative mt-10  text-center px-4 py-4">
                    <div className="flex justify-center   m-auto px-3 py-2">
                        {!isRoomBlocked && !isUserBlockedYou ? (
                            <img
                                src={
                                    roomDetails?.type === "group"
                                        ? DefaultImageUrl + activeRoomData?.profile_img
                                        : DefaultImageUrl + roomDetails?.profile_img
                                }
                                className="w-[230px] h-[230px] rounded-full object-contain"
                                alt={t("profile")}
                            />
                        ) : (
                            <Avatar sx={{ width: "70%", height: "100%" }} />
                        )}
                    </div>
                    <div>{showLastSeen()}</div>
                    <div>{roomDetails?.phone}</div>
                </div>

            <Divider />
           
         {contactDetails?.firstName && (
            <div className="px-6 py-6 bg-[#f4f4f4] flex  flex-col  gap-1 items-start justify-center" id='parent-container'>

                <div className="w-full">
                    <div>{t('first_name')}</div>
                    <div className="pb-2 border pt-2 my-1 min-h-[40px] rounded-md px-2">{userContactDetails === undefined
                        ? roomDetails?.phone
                        : userContactDetails?.firstName
                    }</div>
                </div>
                <div className="w-full">
                    <div>{t('last_name')}</div>
                    <div className="pb-2 border pt-2 my-1 min-h-[40px] rounded-md px-2">{userContactDetails === undefined
                        ? roomDetails?.phone
                        :
                        userContactDetails?.lastName}</div>
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('salutation')}</div>
                    <TextField
                        value={form?.prefix}
                        name="prefix"
                        onChange={(e) => { setForm({ ...form, prefix: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                    <div className="textGray mt-2 flex  gap-1 text-xs">{t('salutation-msg')}</div>
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t("alias")}</div>
                    <TextField
                        value={form?.suffix}
                        name="alias"
                        onChange={(e) => { setForm({ ...form, suffix: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                    <div className="textGray mt-2 flex gap-1 text-xs">{t('alias-msg')}</div>
                </div>

                <div className="w-full my-1">
                    <div className="mb-1">{t("email")}</div>
                    <TextField
                        value={form?.email}
                        name="email"
                        onChange={(e) => { setForm({ ...form, email: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>

                <div className="w-full my-1">
                    <div className="mb-1">{t('dob')}</div>
                   {/*  <TextField
                        value={form?.dob}
                        name="dob"
                        onChange={(e) => { setForm({ ...form, dob: e.target.value }) }}
                        
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    /> */}
                    <DatePicker onChange={onChange}  style={{
                                height: '45px',
                            }} value={form?.dob!==null?dayjs(form?.dob):null} className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full" />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('gender')}</div>

                    <FormControl fullWidth>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{ height: "45px" }}
                            value={form.gender}
                            onChange={(e) => { setForm({ ...form, gender: e.target.value }) }}
                        >
                            <MenuItem value={'male'}>{t("male")}</MenuItem>
                            <MenuItem value={'female'}>{t("female")}</MenuItem>
                            <MenuItem value={'other'}>{t("other")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('website')}</div>
                    <TextField
                        value={form?.website}
                        name="website"
                        onChange={(e) => { setForm({ ...form, website: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('country')}</div>
                    <TextField
                        value={form?.country}
                        name="country"
                        onChange={(e) => { setForm({ ...form, country: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t("region")}</div>
                    <TextField
                        value={form?.region}
                        name="region"

                        onChange={(e) => { setForm({ ...form, region: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('city')}</div>
                    <TextField
                        value={form?.city}
                        name="city"
                        onChange={(e) => { setForm({ ...form, city: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('street')}</div>
                    <TextField
                        value={form?.street}
                        onChange={(e) => { setForm({ ...form, street: e.target.value }) }}
                        name="street"
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                <div className="w-full my-1">
                    <div className="mb-1">{t('full_address')}</div>
                    <TextField
                        value={form?.address}
                        name="address"
                        onChange={(e) => { setForm({ ...form, address: e.target.value }) }}
                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                        inputProps={{
                            style: {
                                height: '9px',
                            },
                        }}
                    />
                </div>
                {additionalField.length > 0 &&
                    <div className="w-full ">

                        {additionalField.map((el: any) => (
                            <div className="w-full bg-gray-200 rounded-md px-4 py-2 my-2 shadow-sm">
                                <div className="flex ml-auto justify-end cursor-pointer" onClick={() => { removeExtraAdditionalField(el.label) }}><CloseIcon sx={{ height: 16, width: 16 }} /></div>
                                <div className="mb-1">{t('custom_field')}</div>
                                <TextField
                                    value={el.label}
                                    name="address"
                                    onChange={(e) => { setAdditionalField([...additionalField.filter((es: any) => es.label !== el.label), { label: e.target.value, value: el.value }]) }}
                                    className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                                    inputProps={{
                                        style: {
                                            height: '9px',
                                        },
                                    }}
                                />
                                <div className="my-2">
                                    <div className="mb-1">{t('custom_label')}</div>
                                    <TextField
                                        value={el.value}
                                        name="address"
                                        onChange={(e) => { setAdditionalField([...additionalField.filter((es: any) => es.label !== el.label), { label: el.label, value: e.target.value }]) }}
                                        className="pb-2 border pt-2 my-1 h-[40px] z-10 rounded-md px-2 w-full"
                                        inputProps={{
                                            style: {
                                                height: '9px',
                                            },
                                        }}
                                    /></div>
                            </div>
                        ))}
                    </div>
                }
                <div className='w-full items-center flex justify-between'>
                    <div className=' px-4 text-base mt-4 py-2 rounded-md text-white cursor-pointer  bg-[#33CCFF]' onClick={() => { if (!load) { updateContactInfo() } }}>
                        {load ? <CircularProgress style={{ height: 15, width: 15, color: "white" }} /> : t("submit")}
                    </div>
                    <div className="border-2 text-center rounded-md px-4 py-2 cursor-pointer border-blue-400 w-fit  mt-4" onClick={() => { addExtraAdditionalField() }}>{t('add-additional-field')}</div>

                </div>
            </div>
         )}
        </div>
    );
}