import dayjs from "dayjs";
import { useState } from "react";
import { DaysNum, RecurrentTypes } from "../Components/Calendar/types/enum";
import weekOfYear from "dayjs/plugin/weekOfYear"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isToday from "dayjs/plugin/isToday"

dayjs.extend(weekOfYear)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)

interface recurrentList {
    occurrencesDate: string;
}

interface onceRecurrence {
    startDate: string
}

interface dailyRecurrence {
    startDate: string
    endDate: string
}

interface weeklyRecurrence extends dailyRecurrence {
    weekNumber: number;
    daysOfWeek: string[];
}

interface weeklySingleDayRecurrence extends dailyRecurrence {
    weekNumber: number;
    dayOfWeek: number[];
};

interface monthlyRecurrence extends dailyRecurrence {
    day: number;
    weekNumber: number;
    dayOfWeek: number;
    isMonthDay: boolean;
}

interface monthDayConfig {
    startDate: string;
    endDate: string;
    day: number;
}

interface monthWeekConfig {
    startDate: string;
    endDate: string;
    weekNumber: number;
    dayOfWeek: number;
}

interface monthDateConfig {
    monthISO: string;
    weekNumber: number;
    dayOfWeek: number;
};

interface calculateRecurrenceType {
    type: RecurrentTypes.ONCE | RecurrentTypes.DAILY | RecurrentTypes.WEEKLY | RecurrentTypes.MONTHLY;
    startDate: string;
    endDate: string;
    day?: number;
    weekNumber?: number;
    weekRepeatNumber?: number;
    daysOfWeek?: string[];
    dayOfWeek?: number;
    isMonthDay?: boolean;
}

function shortDaysFromStart(date: string, listOfDays: string[]) {
    let weekDayArrange = {} as { [x: string]: string }

    for (let i = 0; i < 7; i++) {
        let index = dayjs(date).add(i + 1, "day")
        weekDayArrange[index?.format("ddd")?.toUpperCase()] = index?.toISOString()
    }

    [...(listOfDays || [])]?.sort((a, b) => {
        return weekDayArrange?.[a] > weekDayArrange?.[b] ? 1 : -1;
    })
    return listOfDays
}

function getDateOfOccurrenceOfWeek(config: weeklySingleDayRecurrence) {
    let startPoint = dayjs(config?.startDate)
    const startWeekCount = startPoint?.week()
    const weekly = []
    let occurrenceCount = 0

    while (startPoint?.isSameOrBefore(config?.endDate)) {
        if (config?.dayOfWeek?.includes(startPoint?.day())) {
            occurrenceCount++;
            let currentWeek = startPoint?.week()

            if ((currentWeek - startWeekCount) % config?.weekNumber == 0) {
                weekly?.push(startPoint?.toISOString());
            }
        }

        startPoint = startPoint?.add(1, "day");
    }

    const validate = weekly?.filter((occurrenceDate) => {
        const isAfterStart = dayjs(occurrenceDate)?.isSameOrAfter(config?.startDate);
        const isBeforeEnd = dayjs(occurrenceDate)?.isSameOrBefore(config?.endDate);
        if (isAfterStart && isBeforeEnd) {
            return true;
        } else {
            return false;
        }
    });

    return validate;
}

function getDateOfOccurrenceOfMonth(config: monthDateConfig) {
    // Parse the ISO string to get the month
    const month = dayjs(config?.monthISO);

    // Initialize a counter for the occurrences of the specified day of the week
    let occurrenceCount = 0;
    let currentDate = month?.startOf("month");

    // Iterate through each day of the month
    while (currentDate?.month() === month?.month()) {
        // Check if the current day matches the specified day of the week
        if (currentDate?.day() === config?.dayOfWeek) {
            // Increment the occurrence count
            occurrenceCount++;

            // If the occurrence count matches the desired week number, return the date
            if (occurrenceCount == config?.weekNumber) {
                return currentDate?.toISOString();
            }
        }

        // Move to the next day
        currentDate = currentDate?.add(1, "day");
    }

    // If the desired occurrence is not found and the specified week number is less than 5,
    // return the date of the 4th occurrence
    if (occurrenceCount != 0 && config?.weekNumber == 5) {
        return getDateOfOccurrenceOfMonth({
            monthISO: config?.monthISO,
            weekNumber: 4,
            dayOfWeek: config?.dayOfWeek,
        });
    }
}

export default function useRecurrence() {
    const [recurrentList, setRecurrentList] = useState<recurrentList[]>([]);

    function getOnceRecurrence(config: onceRecurrence) {
        const date = dayjs(config?.startDate)?.toISOString();
        setRecurrentList([{ occurrencesDate: date }]);
        return [{ occurrencesDate: date }]
    }

    function getDailyRecurrence(config: dailyRecurrence) {
        const diff = dayjs(config?.endDate)?.diff(config?.startDate, "day", true) + 1
        const list = []

        for (let i = 0; i < diff; i++) {
            const date = dayjs(config?.startDate)?.add(i, "days")?.toISOString()
            list.push({
                occurrencesDate: date
            })
        }
        setRecurrentList(list)
    }

    function getWeeklyRecurrence(config: weeklyRecurrence) {
        const weekDay = config?.daysOfWeek
        const weekNumber = config?.weekNumber

        if (weekDay && weekNumber) {
            const w = shortDaysFromStart(config?.startDate, weekDay)?.map(v => DaysNum?.[v])
            const v: any = getDateOfOccurrenceOfWeek({
                startDate: config?.startDate,
                endDate: config?.endDate,
                weekNumber,
                dayOfWeek: w
            })

            const recurrence = [] as recurrentList[]
            v.sort((a, b) => dayjs(b).isBefore(dayjs(a)))

            v?.forEach(date => {
                recurrence.push({ occurrencesDate: date })
            })

            setRecurrentList(recurrence);
            return recurrence
        } else {
            return []
        }
    }

    function calculateMonthDayRecurrence(config: monthDayConfig) {
        // Get the start and end dates
        const start = dayjs(config?.startDate)
        const end = dayjs(config?.endDate)
        const hour = dayjs(start).get("hours")
        const minute = dayjs(start).get("minutes")
        const dayNumber = config?.day ?? 1;

        // Array to store the list of available months
        const availableMonths = [];

        // Validate if the start day is less than or equal to the specified day number
        if (start?.date() <= dayNumber) {
            // Format the start month to get its name
            const occurrencesDate = start?.set("date", dayNumber).set("hours", hour).set("minutes", minute)?.toISOString();
            // Add the start month to the list of available months
            availableMonths.push({ occurrencesDate });
        }

        // Loop through each month between start and end
        let currentDate = start?.add(1, "month")?.startOf("month");
        while (currentDate?.isBefore(end?.endOf("month")) || currentDate?.isSame(end?.endOf("month"))) {
            // Validate if the start day is less than or equal to the specified day number
            const validStart = currentDate?.startOf("month").date() <= dayNumber;

            // Validate if the end day is greater than or equal to the specified day number
            const validEnd = currentDate?.endOf("month").date() >= dayNumber;

            // Check if both start and end days are valid
            if (validStart && validEnd) {
                let occurrencesDate = currentDate
                    ?.set("date", dayNumber)
                    ?.set("hours", hour)
                    ?.set("minutes", minute)
                    ?.toISOString();

                // Add the month to the list of available months
                availableMonths.push({ occurrencesDate });
            }

            // Move to the next month
            currentDate = currentDate.add(1, "month").startOf("month");
        }

        // If the last month's day is less than the specified day number, remove it from the list
        if (end?.date() < dayNumber && availableMonths?.length > 0) {
            availableMonths?.pop();
        }

        // Return the list of available months
        // let value = _.uniqBy(availableMonths, (v) => v.month);
        // console.log(value)
        setRecurrentList(availableMonths);
        return availableMonths
    }

    function calculateMonthWeekOccurrences(config: monthWeekConfig) {
        const startDate = dayjs(config?.startDate)
        const endDate = dayjs(config?.endDate)
        const hour = dayjs(startDate).get("hours");
        const minute = dayjs(startDate).get("minutes");
        const weekNumber = config?.weekNumber;
        const dayOfWeek = config?.dayOfWeek;

        // Calculate the number of months between the start and end dates
        const monthDiff = endDate?.diff(startDate, "month") + 1;

        // Initialize an array to store the occurrence dates
        const occurrenceDates = [];

        // Iterate through each month and calculate the occurrence date
        for (let i = 0; i < monthDiff; i++) {
            const currentMonth = startDate?.add(i, "month")?.toISOString();

            const occurrencesDate = getDateOfOccurrenceOfMonth({
                monthISO: currentMonth,
                weekNumber,
                dayOfWeek,
            });

            // Check if occurrenceDate is defined before adding it to the array
            if (occurrencesDate !== undefined) {
                occurrenceDates.push({ occurrencesDate: dayjs(occurrencesDate).set("hours", hour).set("minutes", minute) });
            }
        }

        const validate = occurrenceDates?.filter(item => {
            const isToday = dayjs(item?.occurrencesDate)?.isToday();
            if (isToday) {
                const isAfterCurrentTime = dayjs(item?.occurrencesDate)?.isAfter(dayjs(), "minutes");
                return isAfterCurrentTime;
            } else {
                const isAfterStart = dayjs(item?.occurrencesDate).isSameOrAfter(startDate);
                const isBeforeEnd = dayjs(item?.occurrencesDate).isSameOrBefore(endDate);
                if (isAfterStart && isBeforeEnd) {
                    return true;
                } else {
                    return false;
                }
            }
        });

        setRecurrentList(validate);
        return validate;
    }

    function getMonthlyRecurrence(config: monthlyRecurrence) {
        if (config?.isMonthDay) {
            return calculateMonthDayRecurrence({
                startDate: config?.startDate,
                endDate: config?.endDate,
                day: config?.day,
            })
        } else {
            return calculateMonthWeekOccurrences({
                startDate: config?.startDate,
                endDate: config?.endDate,
                weekNumber: config?.weekNumber,
                dayOfWeek: config?.dayOfWeek,
            })
        }
    }

    function calculateRecurrence(config: calculateRecurrenceType) {
        switch (config?.type) {
            case RecurrentTypes.ONCE:
                return getOnceRecurrence({
                    startDate: config?.startDate
                });

            case RecurrentTypes.DAILY:
                return getDailyRecurrence({
                    startDate: config?.startDate,
                    endDate: config?.endDate
                });

            case RecurrentTypes.WEEKLY:
                return getWeeklyRecurrence({
                    startDate: config?.startDate,
                    endDate: config?.endDate,
                    weekNumber: config?.weekRepeatNumber,
                    daysOfWeek: config?.daysOfWeek,
                })

            case RecurrentTypes.MONTHLY:
                return getMonthlyRecurrence({
                    startDate: config?.startDate,
                    endDate: config?.endDate,
                    day: config?.day ?? 1,
                    weekNumber: config?.weekNumber ?? 1,
                    dayOfWeek: config?.dayOfWeek ?? 1,
                    isMonthDay: config?.isMonthDay ?? true,
                })

            default:
                return []
        }
    }

    return {
        recurrentList,
        calculateRecurrence
    }
}