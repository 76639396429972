import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICategoryProps, TStatus } from "./databasetypes";
import { authCllient } from "../../../Graphql/authClient";
import { CREATE_FOLDER, UPDATE_FOLDER } from "../../../Graphql/Mutations";
import TextInput from "../../../Components/inputs/TextInput";

const CategoryModal = ({
  show,
  setShow,
  currentDatabase,
  globalId,
  setIsRefresh,
  editFolder,
  setEditFolder,
}: ICategoryProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<TStatus>("idl");
  const [name, setName] = useState<string>(() => {
    return editFolder?.name ? editFolder?.name : "";
  });

  const handleClose = () => {
    setName("");
    setEditFolder(null);
    setShow(false);
  };

  const handleSubmit = async (ev: FormEvent) => {
    ev?.preventDefault();

    if (name == "") {
      return;
    }

    const payload = {
      name: name?.trim(),
      ...(editFolder?._id && { _id: editFolder?._id }),
      ...(!editFolder?._id && {
        parent: currentDatabase?._id ?? globalId,
        records: [],
      }),
    };

    setStatus("loading");
    try {
      const response = await authCllient.mutate({
        mutation: editFolder?._id ? UPDATE_FOLDER : CREATE_FOLDER,
        variables: {
          input: payload,
        },
      });

      if (response?.data) {
        setIsRefresh(true);
        handleClose();
      }
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <Dialog
      open={show}
      sx={{
        ".MuiDialog-paper": {
          minWidth: "50%",
          minHeight: 200,
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div className="absolute w-full px-4 top-[10px]">
          <div className="flex items-center justify-between">
            <div className="block mb-2 text-lg font-semibold text-gray-900 dark:text-white">
              {t("createcategory")}
            </div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="mt-3 w-full">
          <div>
            <TextInput
              type="text"
              value={name}
              autoFocus={true}
              placeholder={t("enternmeofcategory")}
              disabled={Boolean(status == "loading")}
              onChange={(ev) => setName(ev.target.value)}
            />

            {status == "error" && (
              <div className="text-red-600 text-sm mt-2">
                {t("something_went_wrong")}
              </div>
            )}
          </div>

          {name?.trim()?.length > 0 && (
            <div className="mt-5">
              <button
                type="submit"
                disabled={Boolean(status == "loading")}
                className="bg-[#33CCFF] flex justify-center items-center outline-none w-full px-3 py-1 rounded-md text-white"
              >
                {status == "loading" ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  t("submit")
                )}
              </button>
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryModal;
