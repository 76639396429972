import {
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { preDefinedFields } from "./data";
import { IPredefinedProps, TField } from "./databasetypes";

const PredefinedFields = (props: IPredefinedProps) => {
  const { t } = useTranslation();
  const { show, setShow, selectedFields, setSelectedFields } = props;

  const handleCheckbox = (item: TField, checked: boolean) => {
    if (checked) {
      const temp: TField[] = [...selectedFields];
      temp.push({ ...item, checked: true });
      setSelectedFields(temp);
    } else {
      item.checked = false;
      const updated: TField[] = selectedFields?.filter(
        (el) => el?.key != item?.key
      );
      setSelectedFields(updated);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
      sx={{
        ".MuiDialog-paper": {
          minWidth: 600,
          backgroundColor: "#F3F9FC",
        },
      }}
    >
      <DialogContent>
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg">{t("addfields")}</div>
          <IconButton onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="mt-2">
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {preDefinedFields.map((item: TField, index: number) => {
              const find = selectedFields?.find((el) => el?.key == item?.key);

              return (
                <div key={index}>
                  <ListItem>
                    <label
                      htmlFor={`checkbox-${item.label}`}
                      className="text-sm cursor-pointer flex items-center justify-between w-full"
                    >
                      {t(item.label)}

                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            sx={{
                              "&.Mui-checked": {
                                color: "#33CCFF",
                              },
                            }}
                            id={`checkbox-${item.label}`}
                            checked={Boolean(find?.checked)}
                            onChange={(ev) =>
                              handleCheckbox(item, ev.target.checked)
                            }
                          />
                        }
                        label=""
                      />
                    </label>
                  </ListItem>
                  {index != preDefinedFields.length - 1 && <Divider />}
                </div>
              );
            })}
          </List>
        </div>

        <div className="mt-3 text-center">
          <button
            onClick={() => setShow(false)}
            className="bg-[#33CCFF] w-full outline-none px-6 py-2 rounded-md text-white"
          >
            {t("submit")}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PredefinedFields;
