import { colors, DefaultImageUrl } from "../../../../Constant/Constant";
import ArticleIcon from "@mui/icons-material/Article";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { MessageType, TSchedule } from "../../../ChatPage/schedule/types";
import { InvitationsType } from "../../../../Components/Calendar/types/types";
import clsx from "clsx";
import { Chip } from "@mui/material";

type PropTypes = {
  messageObj: MessageType;
  schedule: any;
};

function ScheduleMessage({ messageObj, schedule }: PropTypes) {
  const { t } = useTranslation();

  function renderMessage(message: string): string {
    const pattern = /@\[(.*?)\]\([^)]+\)/g;

    const formattedMessage = message
      ?.replace(pattern, (match, captureGroup) => {
        return captureGroup + " ";
      })
      ?.replace(/\([^)]+\)/g, "");

    return formattedMessage;
  }

  function handleClickOnDoc(url: string) {
    window.open(DefaultImageUrl + url);
  }

  return (
    <div className="w-full">
      {messageObj?.type == "IMAGE" && (
        <div className="flex justify-between w-max mb-2">
          <img
            src={DefaultImageUrl + messageObj?.fileURL}
            alt=""
            className="h-[200px] w-[200px] rounded-md"
          />
        </div>
      )}

      {messageObj?.type != "IMAGE" && messageObj?.type != "text" && (
        <div
          onClick={() => handleClickOnDoc(messageObj?.fileURL)}
          className="flex justify-between cursor-pointer mb-2"
        >
          <ArticleIcon color="primary" fontSize="large" />
          <span className="ml-2 text-sm">
            {messageObj?.fileURL}
          </span>
        </div>
      )}

      {messageObj?.message && (
        <div
          className={
            "bg-[#E0FAFF] text-black text-sm p-2 rounded-b-md rounded-r-md flex justify-between w-fit"
          }
        >
          {renderMessage(messageObj?.message)}
        </div>
      )}

      <div className="mt-2 flex gap-2 items-center">
        <AccessAlarmIcon fontSize="small" />
        <div className="text-sm">
          <span>{t("schedule_at")}</span>
          <span>{dayjs(schedule?.startDate).format("DD MMMM YYYY")} | </span>
          {!dayjs(schedule?.endDate)?.isSame(dayjs(schedule?.startDate)) && (
            <span> - {dayjs(schedule?.endDate).format("DD MMMM YYYY")} | </span>
          )}
          <span>{dayjs(schedule?.time).format("hh:mm A")}</span>
        </div>
      </div>

      <div className="flex gap-5 mt-3">
        <div
          className={clsx(
            "text-[12px] text-white px-4 py-1 h-fit w-fit rounded-md"
          )}
          style={{
            backgroundColor: colors.assignment,
          }}
        >
          {t(schedule?.recursive)}
        </div>

        {schedule?.monthlyParams?.months?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {schedule?.monthlyParams?.months?.map(
              (item: string, key: number) => {
                return <Chip key={key} variant="outlined" label={item} />;
              }
            )}
          </div>
        )}

        {schedule?.daylyParams?.dayOfWeeks?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {schedule?.daylyParams?.dayOfWeeks?.map(
              (item: string, key: number) => {
                return <Chip key={key} variant="outlined" label={item} />;
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ScheduleMessage;
