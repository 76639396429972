import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { IDeleteRecordProps, TStatus } from "./databasetypes";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { authCllient } from "../../../Graphql/authClient";
import { DELETE_RECORD } from "../../../Graphql/Mutations";

const DeleteRecord = ({
  deleteRecord,
  setDeleteRecord,
  setIsRefresh,
  closeRecordModal,
}: IDeleteRecordProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<TStatus>("idl");

  const handleClose = () => {
    setDeleteRecord({ data: null, show: false });
  };

  const handleDelete = async () => {
    setStatus("loading");
    try {
      const response = await authCllient.mutate({
        mutation: DELETE_RECORD,
        variables: {
          input: {
            _id: deleteRecord?.data?._id,
          },
        },
      });

      if (response?.data) {
        if (setIsRefresh) {
          setIsRefresh(true);
        }
        handleClose();
        if (closeRecordModal) {
          closeRecordModal();
        }
      }
    } catch (error) {
      setStatus("error");
      console.log(error);
    }
  };

  return (
    <Dialog
      open={deleteRecord.show}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          minWidth: 500,
        },
      }}
    >
      <DialogContent>
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg">{t("deleteconfirmation")}</div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="mt-3">
          <Alert severity="error">
            {t("deletefolderdesc")} <b>{deleteRecord?.data?.title}</b>?
          </Alert>
        </div>

        {status == "error" && (
          <div className="mt-2 text-red-600 text-center">
            {t("something_went_wrong")}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        {status != "loading" && (
          <button
            onClick={handleClose}
            className="bg-gray-400 flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
          >
            {t("cancel")}
          </button>
        )}

        <button
          onClick={handleDelete}
          disabled={Boolean(status == "loading")}
          className="bg-red-600 flex justify-center items-center outline-none w-max px-3 py-1 rounded-md text-white"
        >
          {status == "loading" ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            t("delete")
          )}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecord;
