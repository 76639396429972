import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PropType } from "./types";
import {
  LocalizationProvider,
  TimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  EventTypeEnum,
  RecurrentTypes,
} from "../../../Components/Calendar/types/enum";
import { authCllient } from "../../../Graphql/authClient";
import {
  CREATE_SCHEDULE_MSG,
  DELETE_REMINDER,
  UPDATE_SCHEDULE_MSG,
} from "../../../Graphql/Mutations";
import { useDispatch, useSelector } from "react-redux";
import { setSchedules } from "../../../Redux/ChatSlice";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ScheduleMessages from "./ScheduleMessages";
import SelectReminder from "./SelectReminder";
import AddCustomNotification from "../../../Components/Calendar/dialogs/event/fields/AddCustomNotification";
import CloseIcon from "@mui/icons-material/Close";
import RecurrentFrequency from "../../../Components/Recurrents/RecurrentFrequency";
import EventConfirmation from "../../../Components/Calendar/dialogs/EventConfirmation";
import RoomDetail from "./RoomDetail";

const defaultValues = {
  _id: "",
  startDate: dayjs(),
  endDate: dayjs(),
  type: EventTypeEnum.SCHEDULE,
  isApprovalNeeded: false,
  approvalReminderTime: null,
  frequency: RecurrentTypes.ONCE,
  noOfWeeks: 1,
  repeatEveryMonth: true,
  days: [],
  months: [],
  onDay: 1,
  onWeek: "MON",
  everyWeek: 1,
  monthDateType: "day",
  date: null,
  parent_id: "",
};

function ScheduleModal(props: PropType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState(null);
  const [showCustom, setShowCustom] = useState(false);
  const { schedules } = useSelector((state: any) => state?.chat);
  const {
    show,
    setShow,
    scheduleData,
    setToastMessage,
    setToastShow,
    setMessage,
    handleResetFileSend,
    roomType,
    fromCalendar,
    modalType,
    editScheduleData,
  } = props;
  const [status, setStatus] = useState<"idl" | "loading" | "error" | "success">(
    "idl"
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const isEditable =
    modalType == "edit"
      ? dayjs(form?.date).isSameOrAfter(dayjs())
        ? true
        : false
      : true;

  useEffect(() => {
    if (modalType == "edit") {
      setForm({ ...editScheduleData, date: editScheduleData?.startDate });
    } else {
      setForm({ ...defaultValues, time: dayjs().add(30, "minute") });
    }
  }, [show]);

  function handleDateChange(type: string, date: any) {
    const tempForm: any = { ...form };
    const newErrors = { ...errors };
    tempForm[type] = date;

    if (tempForm?.frequency != RecurrentTypes.ONCE) {
      if (type == "startDate") {
        if (date > form?.endDate) {
          newErrors.start = "start_date_error";
        } else {
          delete newErrors.startDate;
          delete newErrors.endDate;
        }
      } else if (type == "endDate") {
        if (date < form?.startDate) {
          newErrors.startDate = "end_date_error";
        } else {
          delete newErrors.endDate;
          delete newErrors.startDate;
        }
      }
    }

    const difference =
      dayjs(tempForm?.endDate).diff(tempForm?.startDate, "day") + 1;

    if (form?.frequency == RecurrentTypes.WEEKLY) {
      if (difference < 7) {
        newErrors.startDate = "week_diff_error";
        newErrors.endDate = "week_diff_error";
      } else {
        delete newErrors.endDate;
        delete newErrors.startDate;
      }
    } else if (form?.frequency == RecurrentTypes.MONTHLY) {
      if (difference < 30) {
        newErrors.startDate = "month_diff_error";
        newErrors.endDate = "month_diff_error";
      } else {
        delete newErrors.endDate;
        delete newErrors.startDate;
      }
    }

    setForm({
      ...form,
      days: [],
      months: [],
      [type]: dayjs(date),
      endDate:
        form?.frequency == RecurrentTypes.ONCE
          ? tempForm.startDate
          : tempForm?.endDate,
    });
    setErrors(newErrors);
  }

  function handleCheckbox(event: ChangeEvent<HTMLInputElement>) {
    const checked = event?.target?.checked;
    setForm({
      ...form,
      isApprovalNeeded: checked,
    });
  }

  function handleAddCustom(data: any) {
    setForm({ ...form, approvalReminderTime: data });
  }

  async function handleSubmit(updateType?: string) {
    const formattedStart = form?.startDate
      ?.set("hours", form?.time?.hour())
      ?.set("minutes", form?.time?.minute());
    const formattedEnd = form?.endDate
      ?.set("hours", form?.time?.hour())
      ?.set("minutes", form?.time?.minute());

    const payload = {
      _id: form?._id,
      type: form?.type,
      time: dayjs(form?.time)?.toISOString(),
      isApprovalNeeded: form?.isApprovalNeeded,
      approvalReminderTime: form?.isApprovalNeeded
        ? [form?.approvalReminderTime]
        : [],
      recursive: form?.frequency,
      message: scheduleData,
      roomId: scheduleData?.roomId,
      roomType,
      ...(form?.frequency == RecurrentTypes.WEEKLY && {
        daylyParams: {
          dayOfWeeks: form?.days,
          everyWeek: form?.noOfWeeks,
        },
      }),
      ...(form?.frequency == RecurrentTypes.MONTHLY && {
        monthlyParams: {
          months: form?.repeatEveryMonth ? [] : form?.months,
          twicePerMonth: form?.repeatEveryMonth,
          onDay: form?.monthDateType == "day" ? form?.onDay : undefined,
          onWeek:
            form?.monthDateType == "week"
              ? {
                  dayOfWeeks: [form?.onWeek],
                  everyWeek: form?.everyWeek,
                }
              : undefined,
        },
      }),
      ...(!form?._id && {
        startDate: formattedStart?.toISOString(),
        endDate: formattedEnd?.toISOString(),
      }),
      ...(form?._id &&
        updateType == "this" && {
          date: dayjs(
            form?.date
              ?.set("hours", form?.time?.hour())
              ?.set("minutes", form?.time?.minute())
              ?.set("seconds", form?.time?.second())
          ).toISOString(),
        }),
      ...(form?._id && {
        thisOccurrence: updateType == "this" ? true : false,
        allOccurrence: updateType == "all" ? true : false,
        parent_id: form?.parent_id,
      }),
    };

    setStatus("loading");
    if (modalType == "create") {
      try {
        const response = await authCllient.mutate({
          mutation: CREATE_SCHEDULE_MSG,
          variables: {
            input: payload,
          },
        });
        if (!response?.data?.createSchedule) {
          setStatus("error");
        } else {
          // const tempSchedules = [...schedules]
          // tempSchedules.push(response?.data?.createSchedule)
          // dispatch(setSchedules(tempSchedules))

          setStatus("success");
          setToastMessage(t("schedule_msg_sccuess"));
          setToastShow(true);
          setMessage("");
          handleResetFileSend();
          setShow(false);
        }
      } catch (err) {
        setStatus("error");
        console.log(err);
      }
    } else {
      try {
        const response = await authCllient.mutate({
          mutation: UPDATE_SCHEDULE_MSG,
          variables: {
            input: payload,
          },
        });

        if (!response?.data?.updateSchedule) {
          setStatus("error");
        } else {
          const updatedSchedules = schedules?.map((item) => {
            if (item?._id == form?._id) {
              return response?.data?.updateSchedule;
            } else {
              return item;
            }
          });

          dispatch(setSchedules(updatedSchedules));
          setStatus("success");
          setToastShow({ msg: t("schedule_update_msg"), show: true });
          setShow(false);
        }
      } catch (err) {
        setStatus("error");
        console.log(err);
      }
    }
  }

  async function handleDelete(deleteType: string) {
    try {
      setStatus("loading");
      const response = await authCllient.mutate({
        mutation: DELETE_REMINDER,
        variables: {
          input: {
            _id: deleteType == "all" ? form?.parent_id : form?._id,
            thisOccurrence: deleteType == "this" ? true : false,
            allOccurrence: deleteType == "all" ? true : false,
          },
        },
      });

      if (!response?.data?.deleteReminder) {
        setStatus("error");
      } else {
        setStatus("success");
        setToastShow({ msg: "schedule_delete_msg", show: true });
        setShow(false);
      }
    } catch (err) {
      setStatus("error");
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={show}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "700px",
            width: "700px",
            height: "auto",
          },
        }}
      >
        <DialogContent>
          <div className="flex justify-between">
            <div className="text-left text-[20px] font-semibold">
              {t("schedule_msg")}
            </div>

            <div>
              <IconButton onClick={() => setShow(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {fromCalendar && <RoomDetail data={form} />}

          <ScheduleMessages scheduleData={scheduleData} />

          <div className="grid grid-cols-3 gap-5">
            {form?._id ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    disablePast
                    label={t("date")}
                    value={form?.date}
                    onChange={(date: any) => setForm({ ...form, date })}
                    slotProps={{
                      textField: {
                        error: Boolean(errors?.date),
                        helperText: t(errors?.date),
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      disablePast
                      label={t("start_date")}
                      format="DD MMM YYYY"
                      value={form?.startDate}
                      onChange={(date: any) =>
                        handleDateChange("startDate", date)
                      }
                      slotProps={{
                        textField: {
                          error: Boolean(errors?.startDate),
                          helperText: t(errors?.startDate),
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label={t("end_date")}
                      value={form?.endDate}
                      format="DD MMM YYYY"
                      minDate={dayjs(form?.startDate)}
                      disabled={Boolean(form?.frequency == RecurrentTypes.ONCE)}
                      onChange={(date: any) =>
                        handleDateChange("endDate", date)
                      }
                      slotProps={{
                        textField: {
                          error: Boolean(errors?.endDate),
                          helperText: t(errors?.endDate),
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </React.Fragment>
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label={t("time")}
                  value={form?.time}
                  timeSteps={{ hours: 1, minutes: 1 }}
                  onChange={(date: any) => handleDateChange("time", date)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

          {modalType == "create" && (
            <RecurrentFrequency
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
              formType={EventTypeEnum.SCHEDULE}
            />
          )}

          <div className="mt-2">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={handleCheckbox}
                    checked={Boolean(form?.isApprovalNeeded)}
                  />
                }
                label={
                  <div className="text-sm">{t("schedule_approval_msg")}</div>
                }
              />
            </FormGroup>

            {form?.isApprovalNeeded && (
              <div className="mt-1">
                <div className="flex items-center gap-2">
                  <NotificationsActiveOutlinedIcon className="text-gray-600" />

                  <div className="lowercase">
                    {form?.approvalReminderTime ? (
                      <div>
                        <span>{form?.approvalReminderTime?.Count}</span>
                        <span className="ml-1">
                          {form?.approvalReminderTime?.Unit}
                        </span>
                        <span className="ml-1">{t("before")}</span>
                      </div>
                    ) : (
                      t("select_approval_time")
                    )}
                  </div>
                </div>

                <SelectReminder
                  form={form}
                  setForm={setForm}
                  setShowCustom={setShowCustom}
                />
              </div>
            )}
          </div>

          <div className="grid gap-2 mt-4">
            {isEditable && (
              <Button
                fullWidth
                color="success"
                variant="outlined"
                onClick={() => {
                  if (!form?._id) {
                    handleSubmit("");
                  } else {
                    if (
                      dayjs(form?.date).isSame(form?.startDate) &&
                      form?.frequency != RecurrentTypes.ONCE
                    ) {
                      setConfirmationType("edit");
                      setShowConfirmation(true);
                    } else {
                      handleSubmit("this");
                    }
                  }
                }}
                sx={{ fontWeight: 600 }}
                disabled={
                  Boolean(status == "loading") ||
                  Boolean(errors && Object?.keys(errors)?.length) ||
                  (!form?.repeatEveryMonth && form?.months?.length == 0) ||
                  (form?.frequency == RecurrentTypes.WEEKLY &&
                    form?.days?.length == 0) ||
                  Boolean(form?.isApprovalNeeded && !form?.approvalReminderTime)
                }
              >
                {status == "loading" ? t("please_wait") : t("schedule")}
              </Button>
            )}

            {modalType == "edit" && (
              <Button
                color="error"
                variant="outlined"
                onClick={() => {
                  if (form?.frequency != RecurrentTypes.ONCE) {
                    setConfirmationType("delete");
                    setShowConfirmation(true);
                  } else {
                    handleDelete("this");
                  }
                }}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  width: "100%",
                }}
                disabled={Boolean(status == "loading")}
              >
                {status == "loading" ? t("please_wait") : t("delete")}
              </Button>
            )}
          </div>

          {status == "error" && (
            <div className="mt-2 text-center text-red-600 text-sm">
              {t("something_went_wrong")}
            </div>
          )}

          {showCustom && (
            <AddCustomNotification
              show={showCustom}
              setShow={setShowCustom}
              onSubmit={handleAddCustom}
              date={form?.startDate}
              time={form?.time}
            />
          )}
        </DialogContent>
      </Dialog>

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={confirmationType == "edit" ? handleSubmit : handleDelete}
          message={
            confirmationType == "edit"
              ? t("update_recurring_event")
              : t("delete_recurring_event")
          }
        />
      )}
    </React.Fragment>
  );
}

export default ScheduleModal;
