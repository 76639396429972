import { FormPropsType } from "../../../types/types";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useState } from "react";
import PlacesAutocomplete from "../../../../SearchLocation/PlacesAutocomplete";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { getCalendarColor } from "../../../utils/calendarColors";

function EventLocations(props: FormPropsType) {
  const { form, setForm } = props;
  const [location, setLocation] = useState(form?.location?.address || "");

  async function handleSelectLocation(addr: any) {
    try {
      const results = await getGeocode({ address: addr });
      const { lat, lng } = await getLatLng(results[0]);

      const mapUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

      const addressComponents = results?.[0]?.address_components;
      const countryComponent = addressComponents?.find((component) =>
        component?.types?.includes("country")
      );

      const countryOffset = countryComponent?.short_name || "";

      setForm({
        ...form,
        location: {
          address: addr,
          latitude: String(lat),
          longitude: String(lng),
          countryOffset,
          mapUrl,
        },
      });
    } catch (error) {
      console.error("Error fetching geocode data", error);
    }
  }

  return (
    <div className="flex gap-3 mt-3 mb-3">
      <LocationOnOutlinedIcon
        fontSize="medium"
        sx={{
          color: getCalendarColor(form?.type, "dark"),
        }}
      />

      <div className="w-full">
        <PlacesAutocomplete
          address={location}
          setAddress={setLocation}
          onAddressSelect={(addr: any) => handleSelectLocation(addr)}
        />
      </div>
    </div>
  );
}

export default EventLocations;
