import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SendMsg from "../../../Assets/Images/SendMsg.png";
import call from "../../../Assets/Images/call.png";
import videoCall from "../../../Assets/Images/videoCall.png";
import MediaImg from "../../../Assets/Images/MediaImg.png";
import search from "../../../Assets/Images/search.png";
import mute from "../../../Assets/Images/mute.png";
import color from "../../../Assets/Images/color.png";
import download from "../../../Assets/Images/download.png";
import heading from "../../../Assets/Images/storage.png";
import reminder from "../../../Assets/Images/notification.png";

import watch from "../../../Assets/Images/watch.png";
import { BsCheck2Circle } from "react-icons/bs";
import ScheduleIcon from "../../../Assets/Images/schedule_drawer.png"
import Encryption from "../../../Assets/Images/Encryption.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CALENDER_SOCKET_EVENTS, DefaultImageUrl, MuteActions } from "../../../Constant/Constant";
import DialogBox from "../../../coreComponent/DialogBox";
import checkImg from "../../../Assets/Images/True.png";
import { emit, socketListen } from "../../../Socket";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import BrightnesSun from "../../../Assets/Images/BrightnesSun.png";
import BrightnesMoon from "../../../Assets/Images/BrightnesMoon.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useMutation } from "@apollo/client";
import {
  CALL_STATUS,
  CREATE_CALL,
  DELETE_BROADCAST_ROOM,
  UPDATE_ROOM_READ_RECEIPT,
  UPLOAD_CHAT_FILE,
} from "../../../Graphql/Mutations";
import favouriteImg from "../../../Assets/Images/favouriteImg.png";
import FavouriteMessage from "../../SettingDialog/FavouriteMessage";
import EditGroup from "../EditGroup";
import GroupSettings from "../GroupSettings";
import AddParticipants from "../AddParticipants";
import AddParticipantsIcon from "../../../Assets/Images/AddParticipantsIcon.png";
import { ParticipantsDataTypes } from "../../../Types/Types";
import ArrowDownIcon from "../../../Assets/Images/ArrowDownIcon.png";
import SharedMedia from "../SharedMedia";
import GroupInComon from "../GroupInComon";
import Toast from "../../../coreComponent/Toast";
import SearchChatTab from "../SearchChatTab";
import { authCllient } from "../../../Graphql/authClient";
import {
  setActiveRoomData,
  setInitiatedCallData,
  setMemberAddedInCall,
  updateCallStatus,
  updateCreateCall,
} from "../../../Redux/ChatSlice";
import { useNavigate } from "react-router-dom";
import contact from "../../../Assets/Images/userAvatar.png";
import { useTranslation } from "react-i18next";
import ContactDetails from "../../SettingDialog/ContactDetails";
import ReminderDrawer from "./reminder/ReminderDrawer";
import ScheduleDrawer from "./schedule/ScheduleDrawer";

function RoomDetailDrawer({
  showRoomType,
  setRoomRightDrawer,
  setBackgroundImgLoading,
  setShowMediaPreview,
  setSelectedMedia,
  searchTabShow,
  setSearchTabShow,
  setShareContactModal,
}: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    activeRoomData,
    formattedParticipantsOfActiveRoom,
    favouriteChats,
    callisRunning,
    activeRoomChats,
    joinedFromOtherDevice,
    activeRoomReminders,
    schedules
  } = useSelector((state: any) => state.chat);
  const [changeCallStatus] = useMutation(CALL_STATUS);
  const [muteModel, setMuteModel] = useState<boolean>(false);
  const [disearingModel, setDisearingModel] = useState<boolean>(false);
  const [headingShow, setHeadingShow] = useState(false);
  const [remindersShow, setRemindersShow] = useState(false)
  const [scheduleShow, setScheduleShow] = useState(false)
  const [imgWallpaper, setImgWallpaper] = useState<any>("");
  const [previewWallpaper, setPreviewWallpaper] = useState<any>("");
  const [clearChatModel, setClearChatModel] = useState<boolean>(false);
  const [blockModel, setBlockModel] = useState<boolean>(false);
  const [reportModel, setReportModel] = useState<boolean>(false);
  const [favouriteListPage, setFavouriteListPage] = useState<any>(false);
  const [showContacDetailsList, setShowContactDetails] = useState<any>(false);
  const [editGroup, setEditGroup] = useState<any>(false);
  const [leaveGroupModalShow, setLeaveGroupModalShow] = useState(false);
  const [readReceipants, setReadReceipantsModel] = useState(false);
  const [deleteRoomModal, setDeleteRoomModal] = useState(false);
  const [groupSettingsShow, setGroupSettingsShow] = useState(false);
  const [deleteBroadcast] = useMutation(DELETE_BROADCAST_ROOM);
  const [groupInComonShow, setGroupInComonShow] = useState(false);
  const [opacityValue, setOpacityValue] = useState(
    activeRoomData?.participants[0].wallpaper.opacity
  );
  const [reasonType, setReasonType] = useState({ value: "", reason: "" });
  const [reasonError, setreasonError] = useState(false);
  const [expandParticipants, setExpandParticipants] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { t } = useTranslation();

  const [soundButton, setSoundButton] = useState(false);
  const [imageOpacity, setImageopacity] = useState("");
  const [soundModel, setSoundModel] = useState(false);
  const [unmuteModalShow, setUnmuteModalShow] = useState(false);
  const [addParticipantsDrawerShow, setAddParticipantsDrawerShow] =
    useState(false);
  const [mediaDrawerShow, setMediaDrawerShow] = useState(false);
  const [drawerWallpaperPage, setDrawerWallpaperPage] =
    useState<boolean>(false);
  const [disableCallButton, setDisabledCallButton] = useState(false);
  const { userData, userContacts, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const [readReceipts, setReadReceipts] = useState(false);
  const isMySelf = activeRoomData?.participants?.length == 1 &&
    activeRoomData?.participants?.[0]?.user_id == userData?._id

  const MuteAction = MuteActions(t);
  const [fileUpload, { data, loading }] = useMutation(UPLOAD_CHAT_FILE);
  
  useEffect(() => {
    if (
      activeRoomData?.receipts?.length === 0 ||
      activeRoomData?.receipts?.find((el: any) => el?.user_id === userData?._id)
        ?.receipt
    ) {
      setReadReceipts(true);
    }
    var RoomBackgroundImage = activeRoomData?.participants?.filter(
      (item: any) => item.user_id === userData?._id
    );
    setPreviewWallpaper(
      `${DefaultImageUrl + RoomBackgroundImage[0]?.wallpaper?.fileName}`
    );
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  setBackgroundImgLoading(loading);

  useEffect(() => { }, [userData]);

  const roomDetails =
    showRoomType === "individual"
      ? activeRoomData?.participants.length === 1
        ? activeRoomData?.participants[0]
        : activeRoomData?.participants?.find(
          (item: any) => item?.user_id !== userData?._id
        )
      : activeRoomData;

  const otherUserData = activeRoomData?.participants?.find(
    (one: any) => one?.user_id !== userData?._id
  );
  const meUserData = activeRoomData?.participants?.find(
    (one: any) => one?.user_id === userData?._id
  );

  const isRoomBlocked =
    userData?.blockedRooms?.length > 0
      ? userData?.blockedRooms?.some((item: any) =>
        item?.room_Id === activeRoomData?._id ? true : false
      )
      : false;
  const isUserBlockedYou = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );

  const isGroupLeft =
    formattedParticipantsOfActiveRoom[userData?._id]?.left_at !== 0;

  const isEveryoneCanEditInfo =
    activeRoomData?.type === "group" ||
    (activeRoomData?.type === "broadcast" &&
      activeRoomData?.access?.some(
        (item: any) => item?.type === "editInfo" && item?.permit === "common"
      ));
  const userInfo =
    activeRoomData?.participants?.length > 1
      ? activeRoomData?.participants?.find(
        (one: any) => one?.user_id !== userData?._id
      )
      : activeRoomData?.participants?.length === 1
        ? activeRoomData?.participants[0]
        : null;
  const userProfileImg =
    activeRoomData?.participants?.length > 0 &&
    activeRoomData?.participants?.find(
      (item: any) => item?.user_id === userInfo?.user_id
    );
  const isRoomLeft = userProfileImg?.left_at !== 0;
  const isEveyOneCanMessage =
    activeRoomData?.type === "group" ||
    (activeRoomData?.type === "broadcast" &&
      activeRoomData?.access?.some(
        (item: any) => item?.type === "sendMessage" && item?.permit === "common"
      ));

  const canYouEditInfo =
    (!isEveryoneCanEditInfo &&
      formattedParticipantsOfActiveRoom[userData?._id]?.user_type ===
      "admin") ||
    isEveryoneCanEditInfo;

  const isGroupAdmin =
    formattedParticipantsOfActiveRoom[userData?._id]?.user_type === "admin";
  const userContactDetails = userContacts?.find(
    (item: any) => item?.userId?._id === roomDetails?.user_id
  );

  const currentParticipantsLength = activeRoomData?.participants?.filter(
    (item: ParticipantsDataTypes) => item?.left_at === 0
  );

  const handleRoomMute = (expire_at: string) => {
    emit("muteRoom", {
      roomId: activeRoomData?._id,
      created_at: activeRoomData?.created_at,
      expired_at: expire_at,
    });
    setMuteModel(false);
  };

  const handleUnmuteRoom = () => {
    emit("unmuteRoom", {
      roomId: activeRoomData?._id,
    });
    setUnmuteModalShow(false);
  };

  const handleDisAppearingMessage = (type: string) => {
    emit("setChatDisappeared", {
      roomId: activeRoomData?._id,
      type: type,
    });
    setDisearingModel(false);
  };

  const handleReceiptsMessage = (type: boolean) => {
    authCllient
      .mutate({
        mutation: UPDATE_ROOM_READ_RECEIPT,
        variables: {
          input: {
            _id: activeRoomData._id,
            action: type,
          },
        },
      })
      .then((res) => {
        setToastMessage(res?.data?.data?.updateChatroomReadReceipts?.message);
        emit("GetRoomById", { roomId: activeRoomData?._id });
        setRoomRightDrawer(false);
      })
      .catch((error) => console.log("error at Create call", error))
      .finally(() => setReadReceipantsModel(false));
  };

  const handleOpacityValue: any = (value: any) => {
    setOpacityValue(value);
  };

  const handleWallpaperUpload = (e: any) => {
    if (e.target.files[0]) {
      const file = new File(
        [e.target.files[0]],
        `${Math.floor(
          Math.random() * (10000 * 10000)
        )}.${e.target.files[0]?.name.split(".").pop()}`
      );
      setImgWallpaper(file);
      if (file) {
        fileUpload({
          variables: {
            file: file,
            thumnail: null,
            input: {
              roomId: activeRoomData?._id,
              _id: userData?._id,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      emit("changeRoomWallpaper", {
        fileName: data?.UploadChatFile?.data?.filename,
        roomId: activeRoomData?._id,
        opacityValue: activeRoomData?.participants[0].wallpaper.opacity,
      });
    }
  }, [data, opacityValue]);

  const handleResetWallpaper = () => {
    emit("changeRoomWallpaper", {
      fileName: "",
      roomId: activeRoomData?._id,
      opacity: 0,
    });
  };

  const handleBlockContact = () => {
    emit("blockRoom", {
      roomId: activeRoomData?._id,
    });
    setRoomRightDrawer(false);
    emit("GetRoomById", { roomId: activeRoomData?._id });
    setBlockModel(false);
    setReportModel(false);
    setReasonType({ value: "", reason: "" });
    setreasonError(false);
  };

  const handleUnBlockRoom = () => {
    emit("unblockRoom", {
      roomId: activeRoomData?._id,
    });
    emit("GetRoomById", { roomId: activeRoomData?._id });
    setRoomRightDrawer(false);
    emit("sendStatusToAUser", {
      type: "online",
      user_id: userData?._id,
    });
  };

  const handleClearChat = () => {
    emit("clearAllChats", {
      roomId: activeRoomData?._id,
    });
  };

  const handleReportRoom = () => {
    if (reasonType?.value !== "") {
      emit("reportRoom", {
        roomId: activeRoomData?._id,
        reason: reasonType?.reason,
      });
      handleBlockContact();
      setReportModel(false);
      setRoomRightDrawer(false);
      setReasonType({ value: "", reason: "" });
      setreasonError(false);
    } else {
      setreasonError(true);
    }
  };

  const handleExportChat = () => {
    emit("exportChatsByRoomId", {
      roomId: activeRoomData?._id,
      region: "Asia/Kolkata",
      attach: true,
    });
  };

  useEffect(() => {
    if (imgWallpaper !== "" && imgWallpaper !== undefined) {
      let previewUrl = URL.createObjectURL(imgWallpaper);
      setPreviewWallpaper(previewUrl);
    }
  }, [imgWallpaper]);

  const handleLeaveGroup = () => {
    emit("removeUserFromRoom", {
      roomId: roomDetails?._id,
      pid: userData?._id,
    });
    setLeaveGroupModalShow(false);
    setRoomRightDrawer(false);
  };

  const handleDeleteRoom = () => {
    emit("deleteRoom", { roomId: activeRoomData?._id });
    dispatch(setActiveRoomData({}))
  };
  const handleDeleteBroadcastRoom = () => {
    dispatch(setActiveRoomData({}))
    deleteBroadcast({
      variables: {
        input: {
          _id: activeRoomData?._id,
        },
      },
    }).then((res) => {
      emit("GetAllRooms");
      setRoomRightDrawer(false);
    });
  };

  const handleFocusOnTextInput = () => {
    const element = document.getElementById("message-input").focus();
    setRoomRightDrawer(false);
  };

  const handleCallInitiate = (type: string) => {
    const participants = [];
    activeRoomData?.participants?.map((item: ParticipantsDataTypes) => {
      if (item?.user_id !== userData?._id) {
        participants.push(item?.user_id);
      }
    });
    dispatch(updateCreateCall(true));
    setDisabledCallButton(true);
    dispatch(setMemberAddedInCall(participants));

    authCllient
      .mutate({
        mutation: CREATE_CALL,
        variables: {
          input: {
            origin: String(userData._id),
            participants: participants,
            channelName: String(new Date().getTime()),
            type: type,
            roomId: activeRoomData._id,
            roomType: activeRoomData.type,
          },
        },
      })
      .then((res) => {
        setRoomRightDrawer(false);
        handleChangeCallStatus(res?.data?.createNewCall?.call?._id)
        dispatch(setInitiatedCallData(res?.data?.createNewCall?.call));
      })
      .catch((error) => console.log("error at Create call", error))
      .finally(() => setDisabledCallButton(false));
  };

  const handleChangeCallStatus = async (callId: string) => {
    const payload = {
      callId,
      status: "accepted",
      userId: userData._id,
    };
    try {
      const response = await authCllient.mutate({
        mutation: CALL_STATUS,
        variables: {
          changeCallStatus: payload,
        },
      });
      if (response?.data?.changeCallStatus) {
        console.log(response?.data?.changeCallStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toast message={toastMessage} open={toastShow} setShow={setToastShow} />

      <DialogBox
        open={deleteRoomModal}
        title={
          roomDetails?.type === "group"
            ? t("confirmation")
            : t("delete_broadcast")
        }
        actions={
          <div className="flex justify-center pt-2">
            <div
              className="border px-4 py-1 rounded cursor-pointer bg-red-400 text-white"
              onClick={() => {
                if (roomDetails?.type === "group") {
                  handleDeleteRoom();
                } else {
                  handleDeleteBroadcastRoom();
                }
              }}
            >
              {t("delete")}
            </div>
            <div
              className="border px-4 py-1 rounded ml-2 cursor-pointer"
              onClick={() => setDeleteRoomModal(false)}
            >
              {t("cancel")}
            </div>
          </div>
        }
        content={
          <>
            <div className="text-[16px] px-6 lightBlack pb-2">
              {roomDetails?.type === "broadcast"
                ? t("delete_broadcast_message")
                : t("delete_msg")}
            </div>
          </>
        }
        handleClose={() => setDeleteRoomModal(false)}
      />

      <DialogBox
        title={t("mute_chat_room")}
        actions={
          <div
            className="py-2 bg-primary px-2 text-center text-white rounded cursor-pointer "
            onClick={() => setMuteModel(false)}
          >
            {t("cancel")}
          </div>
        }
        open={muteModel}
        content={
          <div className="text-center pt-4">
            {MuteAction.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="pt-2 pb-4 px-40 cursor-pointer lightBlack hover:bg-gray-100"
                    onClick={() => handleRoomMute(item.action)}
                  >
                    {item?.label}
                  </div>
                  <hr />
                </>
              );
            })}
          </div>
        }
        handleClose={() => setMuteModel(false)}
      />

      {/*  <DialogBox
        open={disearingModel}
        content={disapearingMsgDialog()}
        handleClose={() => setDisearingModel(false)}
      /> */}

      <DialogBox
        title={<div className="text-center pb-2">{t("sound_msg")}</div>}
        actions={
          <div
            className="py-2 bg-primary text-white px-2 text-center cursor-pointer lightBlack"
            onClick={() => setSoundModel(false)}
          >
            {t("cancel")}
          </div>
        }
        open={soundModel}
        content={
          <>
            <div
              className="cursor-pointer lightBlack flex items-center justify-between pt-6 pb-4 px-20  h-[56px]"
              onClick={() => console.log("On")}
            >
              <div className="">{t("on")}</div>
              {activeRoomData?.disappearedOnBy?.length > 0 && (
                <div>
                  <img src={checkImg} className="w-6" alt="check" />
                </div>
              )}
            </div>
            <div
              className="cursor-pointer lightBlack flex items-center justify-between pt-2 pb-8 px-20 h-[56px]"
              onClick={() => console.log("Off")}
            >
              <div className="">{t("off")}</div>
              <div>
                {activeRoomData?.disappearedOnBy?.length === 0 && (
                  <div>
                    <img src={checkImg} className="w-6" alt="check" />
                  </div>
                )}
              </div>
            </div>
          </>
        }
        handleClose={() => setSoundModel(false)}
      />

      <DialogBox
        open={clearChatModel}
        title={t("delete_messages")}
        actions={
          <>
            <div
              className="text-white bg-red-500 py-2 rounded px-6  cursor-pointer"
              onClick={() => {
                handleClearChat();
                setClearChatModel(false);
                setRoomRightDrawer(false);
              }}
            >
              {t("clear_chat")}
            </div>
            <div
              className="py-2 bg-primary text-white px-6 rounded text-center cursor-pointer"
              onClick={() => setClearChatModel(false)}
            >
              {t("cancel")}
            </div>
          </>
        }
        content={
          <div className="text-[16px]rounded px-6 pt-6 lightBlack pb-2">
            {t("clear_message")}
          </div>
        }
        handleClose={() => setClearChatModel(false)}
      />

      <DialogBox
        open={blockModel}
        title={t("block_user")}
        actions={
          <>
            <div
              className="text-white bg-red-500 py-2  px-6 rounded cursor-pointer"
              onClick={() => handleBlockContact()}
            >
              {t("block")}
            </div>
            <div
              className="py-2 bg-primary px-6 rounded text-center cursor-pointer"
              onClick={() => setBlockModel(false)}
            >
              {t("cancel")}
            </div>
          </>
        }
        content={
          <div className=" py-5 w-full">
            <div className="flex textGray w-full">{t("block_messages")}</div>
          </div>
        }
        handleClose={() => setBlockModel(false)}
      />

      <DialogBox
        open={reportModel}
        title={t("report_block")}
        actions={
          <div className="flex justify-between pb-4 px-10 w-full">
            <div
              className="py-2  flex items-center rounded-md cursor-pointer w-fit px-4 bg-red-500 text-white"
              onClick={() => {
                handleReportRoom();
              }}
            >
              {t("report_block")}
            </div>
            {/* <div
              className="py-2  flex items-center px-4 rounded-md cursor-pointer bg-primary text-white"
              onClick={() => handleBlockContact()}
            >
              {t("block")}
            </div> */}
            <div
              className="py-2 bg-primary px-4 flex items-center rounded-md text-white text-center cursor-pointer "
              onClick={() => {
                setReasonType({ value: "", reason: "" });
                setreasonError(false);
                setReportModel(false);
              }}
            >
              {t("cancel")}
            </div>{" "}
          </div>
        }
        content={
          <div className=" py-5 w-full">
            <div className="flex textGray w-full">
              {roomDetails?.type === "group" ||
                roomDetails?.type === "broadcast"
                ? roomDetails?.name + " "
                : userContactDetails === undefined
                  ? roomDetails?.phone + " "
                  : userContactDetails?.firstName +
                  " " +
                  userContactDetails?.lastName +
                  " "}
              {t("block_messages1")}
            </div>
            <div className="textGray mb-2 mt-2 text-sm">{t("reason")}</div>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={(e) => {
                setreasonError(false);
                setReasonType({
                  reason: e.target.value === "Other" ? "" : e.target.value,
                  value: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value={t("reason-1")}
                control={<Radio />}
                label={t("reason-1")}
              />
              <FormControlLabel
                value={t("reason-2")}
                control={<Radio />}
                label={t("reason-2")}
              />
              <FormControlLabel
                value={t("reason-3")}
                control={<Radio />}
                label={t("reason-3")}
              />
              <FormControlLabel
                value={t("reason-4")}
                control={<Radio />}
                label={t("reason-4")}
              />
              <FormControlLabel
                value={t("reason-5")}
                control={<Radio />}
                label={t("reason-5")}
              />
            </RadioGroup>
            {reasonType.value === "Other" && (
              <div className="m-4">
                <TextField
                  multiline
                  rows={4}
                  value={reasonType?.reason}
                  placeholder={t("reason")}
                  className="w-full"
                  type="text"
                  onChange={(e) =>
                    setReasonType({ ...reasonType, reason: e.target.value })
                  }
                />
              </div>
            )}
            {reasonError && (
              <div className="text-red-500 text-sm">{t("reason-error")}</div>
            )}
            <div className="textGray text-sm">{t("report-message-2")}</div>
          </div>
        }
        handleClose={() => {
          setReasonType({ value: "", reason: "" });
          setreasonError(false);
          setReportModel(false);
        }}
      />
      <DialogBox
        open={unmuteModalShow}
        content={
          <div className="textGray pt-5 pb-4 px-20">{t("unmute_room")}.</div>
        }
        title={
          <div className="text-center textGray pt-5 pb-4">
            {t("unmute_chat")}
          </div>
        }
        actions={
          <div className="mt-3 justify-center gap-4 flex mb-2">
            <button
              className="p-2 bg-gray-400 text-white w-[100px] rounded-md"
              onClick={handleUnmuteRoom}
            >
              {t("unmute")}
            </button>
            <button
              className="p-2 bg-red-500 text-white w-[100px] rounded-md"
              onClick={() => setUnmuteModalShow(false)}
            >
              {t("cancel")}
            </button>
          </div>
        }
        handleClose={() => setUnmuteModalShow(false)}
      />

      <Dialog
        open={disearingModel}
        onClose={() => {
          setDisearingModel(false);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className=" pt-5 pb-4 bg-[#E0FAFF]">
          {t("disappearing_message")}
          <h6>
            <div className="py-2 text-sm  cursor-pointer ">
              {t("disappearing_msg_detail")}
            </div>
          </h6>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 !important" }}>
          <DialogContentText>
            <div className="">
              <div
                className="cursor-pointer lightBlack flex items-center justify-between pt-2 pb-4 px-20  h-[56px]"
                onClick={() => handleDisAppearingMessage("On")}
              >
                <div className="">{t("on")}</div>
                {activeRoomData?.disappearedOnBy?.length > 0 && (
                  <div>
                    <img src={checkImg} className="w-6" alt="check" />
                  </div>
                )}
              </div>
              <div
                className="cursor-pointer lightBlack flex items-center justify-between pt-2 pb-4 px-20 h-[56px]"
                onClick={() => handleDisAppearingMessage("Off")}
              >
                <div className="">{t("off")}</div>
                <div>
                  {activeRoomData?.disappearedOnBy?.length === 0 && (
                    <div>
                      <img src={checkImg} className="w-6" alt="check" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="text-center">
          <div
            className="bg-primary text-white cursor-pointer rounded-md p-3 py-1"
            onClick={() => setDisearingModel(false)}
          >
            {t("cancel")}
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={leaveGroupModalShow}
        onClose={() => {
          setLeaveGroupModalShow(false);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <div className="bg-[#E0FAFF]">
          <h1>{t("leave_group")}</h1>
        </div>
        <DialogContent>
          <div className="text-left textGray pt-5 ">{t("left_group_msg")}</div>
        </DialogContent>
        <DialogActions>
          <div className="mt-2 justify-center gap-4 flex mb-2">
            <button
              className="p-2 bg-red-500 text-white w-[100px] rounded-md"
              onClick={handleLeaveGroup}
            >
              {t("yes")}
            </button>
            <button
              className="p-2 bg-gray-400 text-white w-[100px] rounded-md"
              onClick={() => setLeaveGroupModalShow(false)}
            >
              {t("no")}
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <div className="relative w-[500px]">
        {editGroup ? (
          <EditGroup setEditGroup={setEditGroup} />
        ) : (
          <div>
            {favouriteListPage ? (
              <FavouriteMessage
                setFavouriteListPage={setFavouriteListPage}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : groupSettingsShow ? (
              <GroupSettings setGroupSettingsShow={setGroupSettingsShow} />
            ) : remindersShow ? (
              <ReminderDrawer
                setShow={setRemindersShow}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : scheduleShow ? (
              <ScheduleDrawer
                setShow={setScheduleShow}
              />
            ) : addParticipantsDrawerShow ? (
              <AddParticipants
                setAddParticipantsDrawerShow={setAddParticipantsDrawerShow}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : mediaDrawerShow ? (
              <SharedMedia
                setMediaDrawerShow={setMediaDrawerShow}
                setShowMediaPreview={setShowMediaPreview}
                setSelectedMedia={setSelectedMedia}
              />
            ) : groupInComonShow ? (
              <GroupInComon
                setGroupInComonShow={setGroupInComonShow}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : searchTabShow ? (
              <SearchChatTab
                setSearchTabShow={setSearchTabShow}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : showContacDetailsList ? (
              <ContactDetails
                setShowContactDetails={setShowContactDetails}
                setRoomRightDrawer={setRoomRightDrawer}
              />
            ) : (
              <>
                {!isRoomBlocked && !isUserBlockedYou && drawerWallpaperPage ? (
                  <div className="flex flex-col items-center">
                    <div
                      className="cursor-pointer border-b w-full py-2 px-2 flex items-center h-[60px] bg-[#F3F9FC]"
                      onClick={() => setDrawerWallpaperPage(false)}
                    >
                      <div>
                        <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
                      </div>
                      <div className="pl-1">{t("back")}</div>
                    </div>

                    <label htmlFor="gallery" className="w-full">
                      <div className="py-4 flex justify-between px-2 w-full cursor-pointer">
                        <div>{t("choose_wallpaper")}</div>
                        <div>
                          <ArrowForwardIosIcon sx={{ fontSize: "21px" }} />
                        </div>
                      </div>
                    </label>

                    <input
                      id="gallery"
                      className="hidden"
                      type="file"
                      onChange={handleWallpaperUpload}
                      accept="image/jpeg, image/jpg, image/png"
                    />

                    <div className="w-[300px] h-[400px] flex justify-center bg-black">
                      {previewWallpaper && (
                        <img
                          src={previewWallpaper}
                          alt="wallpaper"
                          style={{ opacity: opacityValue + "%" }}
                        />
                      )}
                    </div>

                    <div className="flex items-center py-5">
                      <div>
                        <img src={BrightnesMoon} alt="sun" className="w-4" />
                      </div>
                      <Box width={300} sx={{ padding: "0 18px" }}>
                        <Slider
                          defaultValue={opacityValue}
                          getAriaValueText={handleOpacityValue}
                          aria-label="Default"
                          onChange={(event: any) => {
                            setOpacityValue(event.target.value);
                          }}
                          valueLabelDisplay="auto"
                          sx={{ color: "gray" }}
                          step={10}
                          min={0}
                          max={100}
                        />
                      </Box>
                      <div>
                        <img src={BrightnesSun} alt="sun" className="w-6" />
                      </div>
                    </div>
                    <div
                      className="bg-primary mb-2 py-2 px-4 rounded text-white cursor-pointer"
                      onClick={() => {
                        handleResetWallpaper();
                        setPreviewWallpaper("");
                      }}
                    >
                      {t("reset_wallpaper")}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="absolute w-full top-1">
                      {(roomDetails?.type === "group" ||
                        roomDetails?.type === "broadcast") &&
                        canYouEditInfo &&
                        !isRoomLeft && (
                          <div
                            className="text-white cursor-pointer flex justify-end pr-5"
                            onClick={() => setEditGroup(true)}
                          >
                            <EditIcon
                              className="bg-gray-400 rounded-full p-2"
                              sx={{ fontSize: "35px" }}
                            />
                          </div>
                        )}
                    </div>
                    <div className="flex justify-center h-[300px] w-[90%] m-auto px-3 py-2">
                      {roomDetails?.type === "group" ? (
                        <img
                          src={
                            roomDetails?.type === "group" ||
                              roomDetails?.type === "broadcast"
                              ? DefaultImageUrl + activeRoomData?.profile_img
                              : DefaultImageUrl + roomDetails?.profile_img
                          }
                          className="w-[250px] mt-4 rounded-full h-[250px] object-contain"
                          alt={t("profile")}
                        />
                      ) : !isUserBlockedYou ? (
                        <img
                          src={
                            roomDetails?.type === "group"
                              ? DefaultImageUrl + activeRoomData?.profile_img
                              : DefaultImageUrl + roomDetails?.profile_img
                          }
                          className="w-full h-full object-contain"
                          alt={t("profile")}
                        />
                      ) : (
                        <Avatar sx={{ width: "250px", height: "250px" }} />
                      )}
                    </div>
                    <div
                      className={`flex items-center ${roomDetails?.type !== "broadcast"
                          ? "justify-between pt-3"
                          : "justify-center text-center"
                        }   px-3`}
                    >
                      <div>
                        <div>
                          <div className="lightBlack text-lg">
                            {roomDetails?.type === "group" ||
                              roomDetails?.type === "broadcast"
                              ? roomDetails?.name
                              : userContactDetails === undefined
                                ? roomDetails?.phone
                                : userContactDetails?.firstName +
                                " " +
                                userContactDetails?.lastName}
                          </div>
                          <div className="textGray text-sm">
                            {roomDetails?.type !== "group" &&
                              roomDetails?.type !== "broadcast" &&
                              roomDetails?.phone}
                          </div>
                        </div>
                        <div className="pt-1">
                          <div className="lightBlack text-[14px]">
                            {activeRoomData?.bio?.status &&
                              activeRoomData?.bio?.status}
                          </div>
                          <div className="textGray text-[14px]">
                            {moment(activeRoomData?.created_at).format(
                              "DD MMM YYYY"
                            )}
                          </div>
                        </div>
                      </div>
                      {roomDetails?.type !== "broadcast" && !isRoomLeft && (
                        <div className="flex">
                          {isEveyOneCanMessage && (
                            <div
                              onClick={handleFocusOnTextInput}
                              className="cursor-pointer"
                            >
                              <img src={SendMsg} alt="" className="w-8 h-8" />
                            </div>
                          )}
                          {activeRoomData?.participants.length !== 1 &&
                            isRoomBlocked === false && (
                              <>
                                <button
                                  className="px-2 cursor-pointer"
                                  disabled={disableCallButton ? true : false}
                                  onClick={() => {
                                    if (
                                      !callisRunning &&
                                      !joinedFromOtherDevice
                                    ) {
                                      handleCallInitiate("audio");
                                    } else {
                                      alert(t("call_validation"));
                                    }
                                  }}
                                >
                                  <img src={call} alt="" className="w-8 h-8" />
                                </button>
                                <button
                                  className="cursor-pointer"
                                  disabled={disableCallButton ? true : false}
                                  onClick={() => {
                                    if (
                                      !callisRunning &&
                                      !joinedFromOtherDevice
                                    ) {
                                      handleCallInitiate("video");
                                    } else {
                                      alert(t("call_validation"));
                                    }
                                  }}
                                >
                                  <img
                                    src={videoCall}
                                    alt=""
                                    className="w-8 h-8"
                                  />
                                </button>
                              </>
                            )}
                        </div>
                      )}
                    </div>

                    <div className="pt-4">
                      <div
                        className="flex justify-between cursor-pointer items-center py-3 border-y px-3"
                        onClick={() => setMediaDrawerShow(true)}
                      >
                        <div className="flex items-center cursor-pointer">
                          <div className="">
                            <img src={MediaImg} alt="" />
                          </div>
                          <div className="lightBlack pl-2">
                            {t("media_link_docs")}
                          </div>
                        </div>

                        <div className="flex lightBlack">
                          <div className="textGray pr-1">
                            {
                              activeRoomChats?.filter(
                                (item: any) =>
                                  item.type === "AUDIO" ||
                                  item.type === "IMAGE" ||
                                  item.type === "VIDEO" ||
                                  item.type === "APPLICATION" ||
                                  item.type === "DOCUMENT"
                              ).length
                            }
                          </div>
                          <div>
                            <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                          </div>
                        </div>
                      </div>

                      <div
                        className="flex justify-between items-center border-b py-3 px-3 cursor-pointer"
                        onClick={() => setSearchTabShow(true)}
                      >
                        <div className="flex items-center">
                          <div>
                            <img src={search} alt="" />
                          </div>
                          <div className="lightBlack pl-2">
                            {t("chat_search")}
                          </div>
                        </div>

                        <div className="flex lightBlack">
                          <div>
                            <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                          </div>
                        </div>
                      </div>

                      {!isMySelf && (
                      <React.Fragment>
                        {roomDetails?.type !== "broadcast" &&
                          !isRoomBlocked &&
                          !isUserBlockedYou && (
                            <div
                              className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                              onClick={() => {
                                activeRoomData?.mutedBy?.length === 0
                                  ? setMuteModel(true)
                                  : setUnmuteModalShow(true);
                              }}
                            >
                              <div className="flex items-center">
                                <div>
                                  <img src={mute} alt="" />
                                </div>
                                <div className="lightBlack pl-2">{t("mute")}</div>
                              </div>
  
                              <div className="flex lightBlack">
                                <div className="textGray pr-1">
                                  {activeRoomData?.mutedBy?.length > 0
                                    ? t("yes")
                                    : t("no")}
                                </div>
                                <div>
                                  <ArrowForwardIosIcon
                                    sx={{ fontSize: "22px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}

                      {!isRoomBlocked && roomDetails?.type === "group" && (
                        <div
                          className="flex  justify-between items-center border-b py-2 px-3 cursor-pointer"
                          onClick={() => setDrawerWallpaperPage(true)}
                        >
                          <div className="flex items-center">
                            <div>
                              <img src={color} alt="" />
                            </div>
                            <div className="lightBlack pl-2">
                              {t("wallpapers")}
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <div>
                              <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                            </div>
                          </div>
                        </div>
                      )}
                      {activeRoomData?.type !== "broadcast" &&
                        roomDetails?.type !== "group" &&
                        !isUserBlockedYou && (
                          <div
                            className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                            onClick={() => setDrawerWallpaperPage(true)}
                          >
                            <div className="flex items-center">
                              <div>
                                <img src={color} alt="" />
                              </div>
                              <div className="lightBlack pl-2">
                                {t("wallpapers")}
                              </div>
                            </div>

                            <div className="flex lightBlack">
                              <div>
                                <ArrowForwardIosIcon
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {roomDetails?.type === "group" && isGroupAdmin && (
                        <div
                          className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                          onClick={() => setGroupSettingsShow(true)}
                        >
                          <div className="flex items-center">
                            <div>
                              <img src={download} alt="" />
                            </div>
                            <div className="lightBlack pl-2">
                              {t("group_settings")}
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <div>
                              <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                            </div>
                          </div>
                        </div>
                      )}

                      {!isMySelf && (
                        <React.Fragment>
                        {roomDetails?.type != "broadcast" && (
                        <div
                          className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                          onClick={() => setRemindersShow(true)}
                        >
                          <div className="flex items-center">
                            <div>
                              <img src={reminder} alt="" />
                            </div>
                            <div className="lightBlack pl-2">
                              {t("reminders")}
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <div>
                              {/* {activeRoomReminders?.length} */}
                              <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                        onClick={() => setScheduleShow(true)}
                      >
                        <div className="flex items-center">
                          <div>
                            <img src={ScheduleIcon} alt="" className="w-[33px]" />
                          </div>
                          <div className="lightBlack pl-2">
                            {t("schedule_msg")}
                          </div>
                        </div>

                        <div className="flex lightBlack">
                          <div>
                            {/* {schedules?.length} */}
                            <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                          </div>
                        </div>
                      </div>

                      {roomDetails?.type !== "group" && (
                        <div
                          className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                          onClick={() => setGroupInComonShow(true)}
                        >
                          <div className="flex items-center">
                            <div>
                              <img src={download} alt="" />
                            </div>
                            <div className="lightBlack pl-2">
                              {t("groups_common")}
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <div>
                              <ArrowForwardIosIcon
                                sx={{ fontSize: "22px" }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      </React.Fragment>
                      )}

                      {roomDetails?.type !== "broadcast" &&
                        !isRoomBlocked &&
                        !isUserBlockedYou && (
                          <div
                            className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                            onClick={() => setDisearingModel(true)}
                          >
                            <div className="flex items-center">
                              <div>
                                <img
                                  src={watch}
                                  alt="true"
                                  className="w-[20]"
                                />
                              </div>
                              <div className="lightBlack pl-2">
                                {t("disappearing_messages")}
                              </div>
                            </div>

                            <div className="flex lightBlack">
                              <div className="textGray pr-1">
                                {activeRoomData?.disappearedOnBy?.length > 0
                                  ? t("on")
                                  : t("off")}
                              </div>
                              <div>
                                <ArrowForwardIosIcon
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      <div
                        className="cursor-pointer flex justify-between items-center border-b py-2 px-3"
                        onClick={() => setFavouriteListPage(true)}
                      >
                        <div className="flex items-center">
                          <div>
                            <img
                              src={favouriteImg}
                              className="w-[31px]"
                              alt=""
                            />
                          </div>
                          <div className="lightBlack pl-2">
                            {t("favourite_messages")}
                          </div>
                        </div>

                        <div className="flex lightBlack">
                          <div className="textGray pr-1">
                            {favouriteChats?.length}
                          </div>
                          <div>
                            <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                          </div>
                        </div>
                      </div>

                      {(roomDetails?.type === "group" ||
                        roomDetails?.type === "broadcast") && (
                          <>
                            <div className="px-3 pt-6 pb-2">
                              {roomDetails?.type === "broadcast"
                                ? currentParticipantsLength?.length - 1
                                : currentParticipantsLength?.length}{" "}
                              {t("participants")}
                            </div>

                            {isGroupAdmin && (
                              <div className="flex justify-between items-center py-2 px-3">
                                <div
                                  className="flex flex-row cursor-pointer"
                                  onClick={() =>
                                    setAddParticipantsDrawerShow(true)
                                  }
                                >
                                  <div>
                                    <img
                                      src={AddParticipantsIcon}
                                      alt=""
                                      className="h-[30px] w-[30px]"
                                    />
                                  </div>
                                  <div className="pl-2">
                                    <div className="lightBlack">
                                      {t("add_participants")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                    </div>

                    {(activeRoomData?.type === "group" ||
                      activeRoomData?.type === "broadcast") && (
                        <div className="px-3 mt-3">
                          {activeRoomData?.participants
                            ?.slice(
                              0,
                              expandParticipants
                                ? activeRoomData?.participants?.length
                                : 4
                            )
                            .map((item: ParticipantsDataTypes, index: number) => {
                              const userContactDetails =
                                formattedContact[item?.user_id];
                              if (
                                item?.user_id === userData?._id &&
                                roomDetails?.type === "broadcast"
                              ) {
                                return null;
                              }
                              return (
                                item?.left_at === 0 && (
                                  <div
                                    className="flex justify-between mb-4"
                                    key={index}
                                  >
                                    <div>
                                      <div className="flex gap-3">
                                        <div>
                                          {item?.profile_img !== "" ? (
                                            <img
                                              src={
                                                DefaultImageUrl +
                                                item?.profile_img
                                              }
                                              alt=""
                                              className="h-[40px] w-[40px] rounded-full"
                                            />
                                          ) : (
                                            <Avatar
                                              sx={{ height: 40, width: 40 }}
                                            />
                                          )}
                                        </div>
                                        <div>
                                          {item?.user_id === userData?._id ? (
                                            <div>{t("you")}</div>
                                          ) : userContactDetails &&
                                            (userContactDetails?.firstName !==
                                              "" ||
                                              userContactDetails?.lastName !==
                                              "") ? (
                                            <div>
                                              <span>
                                                {userContactDetails?.firstName}
                                              </span>
                                              <span className="ml-1">
                                                {userContactDetails?.lastName}
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="text-xs">
                                              {item?.phone}
                                            </div>
                                          )}
                                          <div className="textGray">
                                            {userContactDetails?.userId?.bio
                                              ?.status?.length > 20
                                              ? userContactDetails?.userId?.bio?.status?.slice(
                                                0,
                                                29
                                              ) + "..."
                                              : userContactDetails?.userId?.bio
                                                ?.status}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        activeRoomData?.type !== "broadcast" &&
                                          item?.user_type === "admin"
                                          ? "w-fit h-fit px-2 py-1 bg-green-200 border-green-500 text-sm border-2 rounded-md"
                                          : ""
                                      }
                                    >
                                      {activeRoomData?.type !== "broadcast" &&
                                        item?.user_type === "admin" &&
                                        t("admin")}
                                    </div>
                                  </div>
                                )
                              );
                            })}

                          {activeRoomData?.type === "broadcast"
                            ? activeRoomData?.participants?.length > 4 &&
                            !expandParticipants && (
                              <div
                                className="flex items-center gap-3 cursor-pointer"
                                onClick={() => setExpandParticipants(true)}
                              >
                                <div>
                                  <img
                                    src={ArrowDownIcon}
                                    alt=""
                                    className="h-[10px]"
                                  />
                                </div>

                                <div>{t("view_more")}</div>
                              </div>
                            )
                            : activeRoomData?.participants?.length > 4 &&
                            !expandParticipants && (
                              <div
                                className="flex items-center gap-3 cursor-pointer"
                                onClick={() => setExpandParticipants(true)}
                              >
                                <div>
                                  <img
                                    src={ArrowDownIcon}
                                    alt=""
                                    className="h-[10px]"
                                  />
                                </div>

                                <div>{t("view_more")}</div>
                              </div>
                            )}

                          {expandParticipants && (
                            <div
                              className="flex items-center gap-3 cursor-pointer"
                              onClick={() => setExpandParticipants(false)}
                            >
                              <div>
                                <img
                                  src={ArrowDownIcon}
                                  alt=""
                                  className="h-[10px] rotate-180"
                                />
                              </div>

                              <div>{t("show_less")}</div>
                            </div>
                          )}
                        </div>
                      )}
                    
                  {!isMySelf && (
                      <React.Fragment>
                        {roomDetails?.type !== "group" &&
                      roomDetails?.type !== "broadcast" && (
                        <div
                          className="flex justify-between cursor-pointer items-center border-b py-2 px-3"
                          onClick={() => {
                            setShowContactDetails(true);
                          }}
                        >
                          <div className="flex flex-row items-center">
                            <div>
                              <img src={contact} alt="" className="w-[36px]" />
                            </div>
                            <div className="pl-2">
                              <div className="lightBlack">
                                {t("contact-details")}
                              </div>
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <div>
                              <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                            </div>
                          </div>
                        </div>
                      )}
                    {roomDetails?.type !== "broadcast" &&
                      !isRoomBlocked &&
                      !isUserBlockedYou && (
                        <div
                          className="flex justify-between items-center border-b py-2 px-3 cursor-pointer"
                          onClick={() => setReadReceipantsModel(true)}
                        >
                          <div>
                            <div className="flex items-start">
                              <div>
                                <BsCheck2Circle
                                  className="w-[28px] h-[28px] p-1 bg-[#33ccff] rounded-full font-bold text-white"
                                  style={{ color: "white" }}
                                />
                              </div>
                              <div className="lightBlack pl-2">
                                {t("read_receipants")}
                                <div className="pb-1 text-sm textGray  cursor-pointer ">
                                  {t("read_receipants_message")}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex lightBlack">
                            <Switch
                              checked={readReceipts}
                              onChange={(e) => {
                                handleReceiptsMessage(e.target.checked);
                                setReadReceipts(e.target.checked);
                              }}
                            />
                            {/* <div className="textGray pr-1">
                          {activeRoomData?.receipts?.length ===0||activeRoomData?.receipts?.find((el:any)=>el?.user_id===userData?._id)?.receipt
                            ? t("on")
                            : t("off")}
                        </div>
                        <div>
                          <ArrowForwardIosIcon sx={{ fontSize: "22px" }} />
                        </div> */}
                          </div>
                        </div>
                      )}
                      </React.Fragment>
                    )}

                    <div className="px-[18px] py-4">
                      {!isMySelf && (
                        <React.Fragment>
                          {roomDetails?.type !== "group" &&
                            roomDetails?.type !== "broadcast" && (
                              <div
                                className="lightBlack  cursor-pointer"
                                onClick={() => {
                                  setShareContactModal(true);
                                  setRoomRightDrawer(false);
                                }}
                              >
                                {t("share_contact")}
                              </div>
                            )}
                        </React.Fragment>
                      )}

                      <div
                        className="py-3 lightBlack  cursor-pointer"
                        onClick={handleExportChat}
                      >
                        {t("export_chat")}
                      </div>
                      {roomDetails?.type !== "broadcast" && (
                        <div
                          className="textRed cursor-pointer"
                          onClick={() => setClearChatModel(true)}
                        >
                          {t("clear_chat")}
                        </div>
                      )}

                      {!isMySelf && (
                        <React.Fragment>
                          {roomDetails?.type === "broadcast" && (
                            <div
                              className="py-2 flex justify-center mx-auto rounded-md textRed  border border-red-300 mb-2 text-center  cursor-pointer"
                              onClick={() => setDeleteRoomModal(true)}
                            >
                              {t("delete_broadcast")}
                            </div>
                          )}
                          {roomDetails?.type === "group" &&
                            roomDetails?.type !== "broadcast" &&
                            (isGroupLeft ? (
                              <div
                                className="pt-3 textRed cursor-pointer"
                                onClick={() => setDeleteRoomModal(true)}
                              >
                                {t("delete_group")}
                              </div>
                            ) : (
                              <div
                                className="pt-3 textRed cursor-pointer"
                                onClick={() => setLeaveGroupModalShow(true)}
                              >
                                {t("leave_group")}
                              </div>
                            ))}

                          {roomDetails?.type !== "group" &&
                            roomDetails?.type !== "broadcast" && (
                              <>
                                {isRoomBlocked ? (
                                  <div
                                    className="py-3 textRed cursor-pointer"
                                    onClick={() => handleUnBlockRoom()}
                                  >
                                    {t("unblock_contact")}
                                  </div>
                                ) : (
                                  <div
                                    className="py-3 textRed cursor-pointer"
                                    onClick={() => {
                                      setBlockModel(true);
                                    }}
                                  >
                                    {t("block_contact")}
                                  </div>
                                )}
                                {!isRoomBlocked && (
                                  <div
                                    className="textRed  cursor-pointer"
                                    onClick={() => setReportModel(true)}
                                  >
                                    {t("report_contact")}
                                  </div>
                                )}
                              </>
                            )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default RoomDetailDrawer;
