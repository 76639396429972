import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import CameraIcon from "../../Assets/Images/camera.png";
import Recapture from "../../Assets/Images/ChatMsgPoper/recapture.svg";
import TextFileImg from "../../Assets/Images/doc-icon.png";

const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
};

export default function SelectedMediaForUploadContainer({ selectedFiles, webcamImage, webcamRef, setWebcamImage, setWebcameObjFile, fileUploadError }) {
    const { t } = useTranslation()
    const captureWebcamImage = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setWebcamImage(imageSrc);

        fetch(imageSrc)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
                const no = Math.floor(Math.random() * (10000 * 10000))
                const file = new File(
                    [arrayBuffer],
                    `${no}.jpg`,
                    {
                        type: "image/jpeg",
                    }
                );
                setWebcameObjFile(file);
            });
    }, [webcamRef]);

    return (
        <>
            <div className="bg-white absolute  min-w-[100%] max-w-[100%] rounded-md flex justify-center items-center h-[88%] top-0 mt-14" style={{ zIndex: "120" }}>
                {selectedFiles?.length === 0 ? (
                    <div className="w-[60%] relative flex justify-center h-full">
                        {webcamImage === "" ? (
                            <Webcam
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                ref={webcamRef}
                                mirrored={false}
                                className="w-[100%]"
                            />
                        ) : (
                            <img src={webcamImage} alt="asd" className="w-[100%]" />
                        )}
                        <div className="mt-2  gap-4 flex items-center justify-center absolute  bottom-20 mx-auto">
                            <button
                                className={`border ${webcamImage === ""
                                    ? "border-blue-500"
                                    : "border-red-500 text-red-600"
                                    } p-2 rounded-lg bg-white`}
                                onClick={() => {
                                    if (webcamImage === "") {
                                        captureWebcamImage();
                                    } else {
                                        setWebcamImage("");
                                    }
                                }}
                            >
                                {webcamImage === "" ? (
                                    <div className="flex">
                                        <img
                                            src={CameraIcon}
                                            alt="as"
                                            className="h-[30px] w-[30px]"
                                        />
                                        <div className="mt-1 ms-2">{t("capture_image")}</div>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <img
                                            src={Recapture}
                                            alt="as"
                                            className="h-[30px] w-[30px]"
                                        />
                                        <div className="mt-1 ms-2">{t("re_capture")}</div>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                ) : (
                    selectedFiles.map((item: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className=" w-[80%] h-[70%] flex justify-center items-center flex-col"
                            >
                                {item?.type === "image/png" ||
                                    item?.type === "image/jpeg" ||
                                    item?.type === "image/jpg" ? (
                                    <img
                                        src={URL.createObjectURL(item)}
                                        className="h-full w-full object-contain rounded-md max-h-[250px]"
                                        alt="preview"
                                    />
                                ) : item?.type === "video/mp4" ||
                                    item?.type?.includes("webm") ? (
                                    <video
                                        controls
                                        src={URL.createObjectURL(item)}
                                        key={index}
                                        className="h-full"
                                    ></video>
                                ) : item?.type?.includes("audio") ? (
                                    <audio controls src={URL.createObjectURL(item)}></audio>
                                ) : (
                                    <img
                                        src={TextFileImg}
                                        className="h-[170px] w-[170px] rounded-md object-contain"
                                        alt="preview"
                                    />
                                )}

                                <div className="text-gray-800 w-[170px] text-sm mt-2 text-center">
                                    {item?.name}
                                </div>

                                {fileUploadError !== "" && (
                                    <div className="my-6 text-red-600 text-sm">
                                        {fileUploadError}
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>
        </>
    )
}