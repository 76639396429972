enum MediaType {
    PHOTO = "PHOTO",
    VIDEO = "VIDEO",
    AUDIO = "AUDIO",
    RECORD = "RECORD",
    DOCUMENT = "DOCUMENT",
}

function checkFileType(fileExtension: string): string {
    switch (true) {
        case fileExtension?.endsWith("jpeg"):
        case fileExtension?.endsWith("jpg"):
        case fileExtension?.endsWith("png"):
            return MediaType.PHOTO;
        case fileExtension?.endsWith("mp4"):
        case fileExtension?.endsWith("mov"):
            return MediaType.VIDEO;
        case fileExtension?.endsWith("mp3"):
        case fileExtension?.endsWith("wav"):
            return MediaType.AUDIO;
        default:
            return MediaType.DOCUMENT;
    }
}

export default checkFileType