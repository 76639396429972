import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  userContacts: [],
  comonUsers: [],
  formattedContact: {},
  isScrollBottom: true,
  noInternetConnection: false,
  isContactsLoading: false,
  blockedMember: [],
  topicsData:[],
  isTabActive: true,
  languages: []
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem('id',action.payload?._id)
      state.blockedMember = action.payload?.blockedRooms.map(({ pid }: any) => (pid))
    },

    setUserContacts: (state, action) => {
      let tempContact: any = {};

      action.payload.forEach((one: any) => {
        if (one?.userId?._id) {
          tempContact = { ...tempContact, [one.userId._id]: one };
        }
      });

      state.formattedContact = tempContact;

      state.userContacts = action.payload;
    },

    setComonUsers: (state, action) => {
      state.comonUsers = action.payload.sort((a:any, b:any) => {
        if (a.firstName < b.firstName) {
          return -1;
        } else if (a.firstName > b.firstName) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    setTopicsData:(state,action)=>{
      state.topicsData=action.payload;
    },
    setScrollBottom: (state, action) => {
      state.isScrollBottom = action.payload;
    },

    setNoInternetConnection: (state, action) => {
      state.noInternetConnection = action.payload;
    },

    setIsContactsLoading: (state, action) => {
      state.isContactsLoading = action.payload
    },

    setActiveTab:  (state, action) => {
      state.isTabActive = action.payload
    },

    setLanguages: (state, action) => {
      state.languages = action.payload
    },

    resetUser: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setUserData,
  setTopicsData,
  setUserContacts,
  setComonUsers,
  setScrollBottom,
  setNoInternetConnection,
  setIsContactsLoading,
  resetUser,
  setActiveTab,
  setLanguages
} = UserSlice.actions;
export default UserSlice.reducer;
