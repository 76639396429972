import { Box } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import Buttons from "../../coreComponent/Buttons";
import ButtonWhite from "../../coreComponent/ButtonWhite";
import burger from "../../Assets/Images/hamburgur.png";
import moreIcon from "../../Assets/Images/more.png";
import EditFolderPage from "./EditFolderPage";
import SearchIcon from "@mui/icons-material/Search";
import parse from "html-react-parser";
import { Avatar } from "@mui/material";
import True from "../../Assets/Images/right.png";
import { useDispatch, useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import { emit, socketListen } from "../../Socket";
import {
  FoldersDataTypes,
  ParticipantsDataTypes,
  RoomsDataType,
} from "../../Types/Types";
import Toast from "../../coreComponent/Toast";
import CloseIcon from "@mui/icons-material/Close";
import { setUserData } from "../../Redux/UserSlice";
import { useTranslation } from "react-i18next";

const FolderDrawer = ({ setFolderDrawerShow }: any) => {
  const [newFolder, setNewFolder] = useState(0);
  const [editPage, setEditPage] = useState(0);
  const [selectGroup, setSelectGroup] = useState(0);
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const { rooms, formattedParticipantsOfActiveRoom } = useSelector((state: any) => state.chat);
  const { userData, userContacts, formattedContact } = useSelector(
    (state: any) => state.user
  );
  const [editFolderData, setEditFolderData] = useState<any>();
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);
  const [listItem, setListItem] = useState([]);
  const [createFolderError, setCreateFolderError] = useState("");
  const [filteredRooms, setFilteredRooms] = useState(rooms);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('')

  socketListen("error", (data: any) => {
    if (data.type === "createFolder") {
      setCreateFolderError(data?.msg);
    }
  });

  socketListen("message", (data: any) => {
    if (data.type === "createFolder") {
      setToastMessage(t("folder-created"));
      setToastShow(true);
      setSelectGroup(0);
      setNewFolder(0);
    } else if (data.type === "deleteFolder") {
      setToastMessage(t("folder-deleted"));
      setToastShow(true);
      setAnchorElMenu(null);
    } else if (data.type === "reArrangeFolder") {
      setToastMessage(data?.msg);
      setToastShow(true);
    } else if (data.type === "deleteRoomFromFolder") {
      let tempUserdata = { ...userData };
      const folder = tempUserdata.folders.map((item) => {
        if (item?._id === data?.msg[0]?._id) {
          return {
            ...item,
            roomId: data?.msg[0]?.roomId,
          };
        }
        return item;
      });
      tempUserdata.folders = folder;
      dispatch(setUserData(tempUserdata));

      let tempFolderData = { ...editFolderData };
      tempFolderData.roomId = data?.msg[0]?.roomId;
      setEditFolderData(tempFolderData);
      setToastShow(true);
      setToastMessage(t("room-deleted-folder"));
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const handleRearrangeFolders = (e: any) => {
    const copyListItems = [...listItem];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    // setListItem(copyListItems);
    emit("reArrangeFolder", { folders: copyListItems });
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  };

  const handleSelectedRoomChange = (e: any) => {
    let temp: any = [...selectedRooms];
    if (e.target.checked) {
      temp.push(e.target.value);
      setSelectedRooms(temp);
    } else {
      let removedArray = temp.filter((one: any) => one !== e.target.value);
      setSelectedRooms(removedArray);
    }
  };

  const handleAddRooms = () => {
    if (folderName === "") {
      setFolderNameError(t("please_enter_folder_name"));
    } else {
      setFolderNameError("");
      setSelectGroup(1);
    }
  };

  const handleCreateRoom = () => {
    if (folderName && selectedRooms.length) {
      emit("createFolder", { folderName: folderName?.trim(), rooms: selectedRooms });
      setCreateFolderError("");
      setSelectedRooms([]);
    }
  };

  const handleFolderDelete = () => {
    emit("deleteFolder", { folderId: editFolderData?._id });
  };

  const handleSearchRooms = (query: string) => {
    setSearchQuery(query);
    /*  if (query) {
       const filtered = [];
 
       filter?.map((item: RoomsDataType) => {
         if (item?.type === "group") {
           if (item?.name?.toLowerCase()?.split(" ")[0]?.startsWith(query.toLowerCase()) || item?.name?.toLowerCase()?.split(" ")[1]?.startsWith(query.toLowerCase())) {
             filtered.push(item);
           }
         } else {
           let temp = item?.participants?.find(
             (element: ParticipantsDataTypes) =>
               element?.user_id !== userData?._id
           );
 
           const user = formattedContact[temp?.user_id];
           if (
             user?.firstName?.toLowerCase().startsWith(query.toLowerCase()) ||
             user?.lastName?.toLowerCase().startsWith(query.toLowerCase()) ||
             temp.phone?.toString().startsWith(query)
           ) {
             filtered.push(item);
           }
         }
       });
       console.log(filtered)
       setFilteredRooms(filtered);
     } else {
       setFilteredRooms(rooms);
     } */
  };


  const renderLastMessage = (item: any, userInfo: any, first: string, last: string) => {
    const message = item?.last_msg[0];
    const sender = item?.participants?.find(
      (el: any) => el?.user_id === message?.sender
    );
    const senderContactDetail = formattedContact[sender?.user_id];

    let lastMessage: any;

    if (item?.type === "group" || item?.type === 'broadcast') {
      if (message?.type === "createdRoom") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message?.replace("created group", t('created-group')).replace(/\s+/g, ' ').trim().slice(0, 22) + "..."
                : message?.message.replace("created group", t('created-group')).replace(/\s+/g, ' ').trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "addedUser" ||
        message?.type === "removedUser"
      ) {
        const jsonMsg = JSON.parse(message?.message);

        lastMessage = (
          <div >
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + " "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="ml-1">{jsonMsg?.msg.replace('added', t('added')).replace('removed', t('removed')).replace(/\s+/g, ' ').trim()}</span>
            <span className="ml-1">
              {jsonMsg?.pid === userData?._id
                ? t("you")
                : formattedContact[jsonMsg?.pid]
                  ? formattedContact[jsonMsg?.pid]?.firstName +
                  " " +
                  formattedContact[jsonMsg?.pid]?.lastName
                  : formattedParticipantsOfActiveRoom[jsonMsg?.pid]?.phone}
            </span>
          </div>
        );
      } else if (
        message?.type === "changedDescription" ||
        message?.type === "changedName" ||
        message?.type === "changedPicture" ||
        message?.type === "leftRoom" ||
        message?.type === "chatDisappear"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="ml-1">
              {message?.message?.length > 22
                ? message?.message?.replace('changed the name of group', t('name-change-group')).replace('changed the description of group', t('description-change-group')).replace("changed the picture of the group", t('picture-change-group')).replace("turned on disappearing messages. All new messages", t("chat-disapper-1")).replace("turned off disappearing messages", t("turn-off-disapper")).replace("left the group", t("left-group")).replace('changed the name of broadcast', t('broad-name-change')).replace('changed the description of broadcast', t('broad-desc-change')).replace("changed the picture of the broadcast", t('broad-image-change')).replace(/\s+/g, ' ').trim().slice(0, 22) + "..."
                : message?.message.replace('changed the name of group', t('name-change-group')).replace('changed the description of group', t('description-change-group')).replace("changed the picture of the group", t('picture-change-group')).replace("turned on disappearing messages. All new messages", t("chat-disapper-1")).replace("turned off disappearing messages", t("turn-off-disapper")).replace("left the group", t("left-group")).replace(/\s+/g, ' ').trim()}
            </span>
          </div>
        );
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="">{`${t('shared_file')} ${message?.type.replace("IMAGE", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document'))} `}</span>
          </div>
        );
      } else if (message.type === "APPLICATION") {
        lastMessage = (
          <div>
            {message.message ? <div>{message?.message}</div> :
              <>
                <span className="capitalize">
                  {message?.sender === userData?._id
                    ? t("you") + ": "
                    : senderContactDetail
                      ? senderContactDetail?.firstName +
                      " " +
                      senderContactDetail?.lastName
                      : sender?.phone}
                </span>
                <span className="ml-1">{t("shared_file")}</span>
              </>
            }
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="">{`${t('shared_contact')}`}</span>
          </div>
        );
      } else if (message?.type === "text" || message?.type === "Link") {
        const data =
          /* message?.message?.length > 30
            ? message?.message
            : */
          message?.message.replace("You deleted this message", t("you-message-deleted")).replace("This message was deleted", t("this-message-deleted")).replace('You are assigned', t('you_are_assigned')).replace("please accept the invitation to join", t('accept-invite')).replace('to start execution', t('start-execution')).replace('by', t('by'));

        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (match: any, p1: any, p2: any, p3: any, p4: any, p5: any, p6: any, p7: any, p8: any) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return (
                `<div style="">` +
                p5 +
                "</div>"
              );
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find(part => part?.user_id == p8)
              const self = userData?._id == participant?.user_id
              const formatPart = formattedContact?.[participant?.user_id]
              const mentionedName = self 
              ? `${t("you")} `
              : formatPart?.firstName
              ? formatPart?.firstName + " " + formatPart?.lastName
              : participant
              ? participant?.phone
              : p7;
              return '<span style="color: green;">' + "@" + mentionedName + "</span>"
            }

            return match;
          }
        );
        const newmessage = parse(formattedText);
        lastMessage = message?.sender === userData?._id
          ? t("you") + ": " + newmessage : "" + newmessage
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className=""> {t("started")} </span>
            <span>
              {message?.type === "audioCall" ? t("audio_call") : t("video_call")}
            </span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        )
      }
    } else {
      if (message.type === "invited") {
        const data = message?.message !== undefined ? JSON.parse(message?.message) : ""
        lastMessage =
          message?.sender === userData?._id ?
            t('accept-1') + " " + first + " " + last + " " + t("accept-2") + " " + data?.orgName + ' '
            :
            first + " " + last + " " + t('accept-3') + " " + data?.orgName
      }
      if (message.type === "declined") {
        const data = message?.message !== undefined ? JSON.parse(message?.message) : ""
        //data.msg.slice(0,28)+'...'
        lastMessage = message?.sender === userData?._id ?
          t("you") + " " + t('msg-1') + " " + data?.orgName + ' ' + t('msg-2') + "..."
          :
          first + " " + last + " " + t('msg-5') + " " + data?.orgName + ' ' + t('msg-2') + "... "
      }
      if (message.type === "taskAssigned") {
        const data = message?.message !== undefined ? JSON.parse(message?.message) : ""
        //data.msg.slice(0,28)+'...'
        lastMessage = message?.sender === userData?._id ?
          t('task-assigned') + " " + data?.scenarioName + ' ' + t('to') + " " + first + " " + last
          :
          first + " " + last + " " + t('assigned-you') + " " + data?.scenarioName
      }
      else if (message?.type === "text" || message?.type === "Link") {
        const data =
          /* message?.message?.length > 30
            ? message?.message?.replace("You deleted this message",t("you-message-deleted")).replace("This message was deleted",t("this-message-deleted")).replace('You are assigned',t('you_are_assigned')).replace("please accept the invitation to join",t('accept-invite')).replace('to start execution',t('start-execution')).replace('by',t('by')).slice(0, 30) + "..."
            : */
          message?.message.replace("You deleted this message", t("you-message-deleted")).replace("This message was deleted", t("this-message-deleted")).replace('You are assigned', t('you_are_assigned')).replace("please accept the invitation to join", t('accept-invite')).replace('to start execution', t('start-execution')).replace('by', t('by'));
        const regex =
          /_(.*?)_|\*(.*?)\*|~(.*?)~|```(.*?)```|<(.*?)>|(https?:\/\/[^\s]+)|@\[([^\]]+)\]\(([a-zA-Z0-9]{24})\)/g;

        const formattedText = data?.replace(
          regex,
          (match: any, p1: any, p2: any, p3: any, p4: any, p5: any, p6: any, p7: any, p8: any) => {
            if (p1) {
              return (
                '<span style="font-style: italic; margin-left: 5px; margin-right: 5px;">' +
                p1 +
                "</span>"
              );
            } else if (p2) {
              return (
                '<span style="font-weight: bold; margin-left: 5px; margin-right: 5px;">' +
                p2 +
                "</span>"
              );
            } else if (p3) {
              return (
                '<span style="text-decoration: line-through; margin-left: 5px; margin-right: 5px;">' +
                p3 +
                "</span>"
              );
            } else if (p4) {
              return (
                '<span style="font-family: monospace; margin-left: 5px; margin-right: 5px;">' +
                p4 +
                "</span>"
              );
            } else if (p5) {
              return (
                `<div style="">` +
                p5 +
                "</div>"
              );
            } else if (p6) {
              return (
                `<a href=${p6} target="_blank" style="text-decoration: underline; margin-left: 5px; margin-right: 5px; overflow-wrap: anywhere;">` +
                p6 +
                "</a>"
              );
            } else if (p8) {
              const participant = item?.participants?.find((part) => part?.user_id == p8);
              const self = userData?._id == participant?.user_id
              const formatPart = formattedContact?.[participant?.user_id];
              const mentionedName = self 
              ? `${t("you")} `
              : formatPart?.firstName
              ? formatPart?.firstName + " " + formatPart?.lastName
              : participant
              ? participant?.phone
              : p7;
              return '<span style="color: green;">' + "@" + mentionedName + "</span>";
            }
            return match;
          }
        );
        lastMessage = (
          <div>
            <span>
                {parse(formattedText)}
            </span>
          </div>
        );
      } else if (message?.type == "poll") {
        lastMessage = (
          <div>
            <span>{t("pollcreated")}</span>
          </div>
        )
      } else if (
        message?.type === "IMAGE" ||
        message?.type === "VIDEO" ||
        message?.type === "AUDIO" ||
        message?.type === "TEXT" ||
        message?.type === "PDF" ||
        message?.type === "ZIP" ||
        message?.type === "PPT" ||
        message?.type === "PPTX" ||
        message?.type === "DOC" ||
        message?.type === "DOCX" ||
        message?.type === "XLSX" ||
        message?.type === "XLS" ||
        message?.type === "DOCUMENT"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="">{`${t('shared_file')} ${message?.type.replace("IMAGE", t('image')).replace("VIDEO", t('video')).replace('AUDIO', t('audio')).replace('DOCUMENT', t('document'))} `}</span>
          </div>
        );
      } else if (message?.type === "contact") {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="">{t("shared_contact")}</span>
          </div>
        );
      } else if (
        message?.type === "audioCall" ||
        message?.type === "videoCall"
      ) {
        lastMessage = (
          <div>
            <span className="capitalize">
              {message?.sender === userData?._id
                ? t("you") + ": "
                : senderContactDetail
                  ? senderContactDetail?.firstName +
                  " " +
                  senderContactDetail?.lastName
                  : sender?.phone}
            </span>
            <span className="mx-1"> {t("started")} </span>
            <span>
              {message?.type === "audioCall" ? t("audio_call") : t("video_call")}
            </span>
          </div>
        );
      }
    }

    return lastMessage;
  };
  useEffect(() => {
    if (filteredRooms.length > 0) {
      let temp: any = [];
      filteredRooms?.forEach((item: any) => {
        const element = { ...item }
        const userInfo = element?.participants?.find(
          (one: any) => one?.user_id !== userData?._id
        );
        const participantContactDetail =
          formattedContact[userInfo?.user_id];
        if (element.type === 'individual') {
          element['name'] = participantContactDetail?.firstName ? participantContactDetail?.firstName.toLowerCase() + " " + participantContactDetail?.lastName.toLowerCase() : `${userInfo?.phone}`
        }
        else {
          element['name'] = element.name.toLowerCase()
        }
        if (element.name !== "") {
          temp.push(element)
        }
      });
      setFilteredRooms(temp)
    }
  }, [filteredRooms?.length])
  
  return (
    <div>
      <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />

      <Box sx={{ width: 400 }} role="presentation" onClick={toggleDrawer(true)}>
        <div>
          {newFolder === 0 ? (
            <div>
              {editPage === 0 ? (
                <div>
                  <div
                    className="flex items-center justify-end py-[8px] border-b px-2 cursor-pointer"
                    onClick={() => setFolderDrawerShow(false)}
                  >
                    <CloseIcon />
                  </div>
                  {userData?.folders?.map(
                    (item: FoldersDataTypes, index: any) => {
                      return (
                        <div
                          className="flex justify-between items-center px-3 w-full border-b py-2"
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={handleRearrangeFolders}
                          draggable
                          key={index}
                        >
                          <div className="flex items-center w-full">
                            <div className="pr-2">
                              <img
                                src={burger}
                                alt="more"
                                className="w-[16px]"
                              />
                            </div>
                            <div className="capitalize">{item?.name}</div>
                          </div>
                          <div
                            onClick={(event: any) => {
                              handleClick(event);
                              setEditFolderData(item);
                            }}
                            className="cursor-pointer"
                          >
                            <img
                              src={moreIcon}
                              alt="more"
                              className="h-[18px]"
                            />
                          </div>

                          <div>
                            <div className="cursor-pointer">
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElMenu}
                                open={openMenu}
                                onClose={() => setAnchorElMenu(null)}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setAnchorElMenu(null);
                                    setEditPage(1);
                                  }}
                                  sx={{ padding: "5px 40px 5px 20px" }}
                                >
                                  {t("edit")}
                                </MenuItem>
                                <MenuItem
                                  onClick={handleFolderDelete}
                                  sx={{ padding: "5px 40px 5px 20px" }}
                                >
                                  {t("delete")}
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                  <div className="py-4 px-2" onClick={() => setNewFolder(1)}>
                    <Buttons>
                      <span>{t("create_folder")}</span>
                    </Buttons>
                  </div>
                </div>
              ) : (
                <div>
                  <EditFolderPage
                    folderData={editFolderData}
                    setEditPage={setEditPage}
                  />
                </div>
              )}
            </div>
          ) : (
            selectGroup !== 1 && (
              <div>
                <div
                  className="flex items-center py-[8px] border-b px-2 cursor-pointer"
                  onClick={() => setNewFolder(0)}
                >
                  <div>
                    <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
                  </div>
                  <div className="pt-[1px]">{t("back")}</div>
                </div>

                <div className="px-2 pt-3">
                  <div className="lightBlack">{t("folder_name")}</div>
                  <input
                    type="text"
                    name="folderName"
                    className="focus:outline-none border rounded w-full py-1 px-2"
                    onChange={(e) => setFolderName(e.target.value)}
                  />
                </div>
                {folderNameError && (
                  <div className="text-red-600 text-sm px-2 mt-2">
                    {folderNameError}
                  </div>
                )}

                {folderName?.trim()?.length > 0 && (
                  <div className="pt-4 pb-1 lightBlack px-2">{t("selects_chats")}</div>
                )}
                {folderName?.trim()?.length > 0 && (
                  <div
                  className="px-2"
                  onClick={() => {
                    handleAddRooms();
                    setCreateFolderError("");
                  }}
                >
                  <ButtonWhite>
                    <span>{t("add_room")}</span>
                  </ButtonWhite>
                </div>
                )}
                <div className="text-sm mt-2 textGray px-2">
                  {t("select_chat_desc")}
                </div>
              </div>
            )
          )}

          {selectGroup === 1 && (
            <div className="w-full">
              <div
                className="flex justify-between items-center bg-white py-[8px] 
                 border-b px-2 sticky top-0"
              >
                <div
                  className="flex cursor-pointer"
                  onClick={() => setSelectGroup(0)}
                >
                  <div>
                    <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
                  </div>
                  <div className="pt-[1px]">{t("back")}</div>
                </div>

                <div className="flex items-center">
                  <div className="cursor-pointer" onClick={handleCreateRoom}>
                    <img src={True} alt="sd" />
                  </div>

                </div>
              </div>

              {showSearch && (

                <div
                  className="my-3 rounded flex border mx-2 mb-3 items-center px-2 bg-white"
                  style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
                >
                  <SearchIcon sx={{ color: "#333333" }} />
                  <input
                    type="text"
                    placeholder={t("searchPlaceholder")}
                    className="w-full py-2 px-2 focus:outline-none text-sm"
                    value={searchQuery}
                    onChange={(e) => handleSearchRooms(e.target.value)}
                  />
                </div>
              )}

              {createFolderError && (
                <div className="mt-2 text-red-600 text-sm px-3">
                  {createFolderError}
                </div>
              )}

              <div className="px-3 pt-1">
                {filteredRooms?.length > 0 ? (
                  filteredRooms?.sort((a: any, b: any) => {
                    if (a.name < b.name) {
                      return -1;
                    } else if (a.name > b.name) {
                      return 1;
                    } else {
                      return 0;
                    }

                  }).filter((el: any) => el?.name?.split(' ')[0]?.startsWith(searchQuery.toLowerCase()) || el?.name?.split(' ')[1]?.startsWith(searchQuery.toLowerCase())).map((item: any, index: number) => {
                    const userInfo = item?.participants?.find(
                      (one: any) => one?.user_id !== userData?._id
                    );

                    let roomsData = item?.participants?.find(
                      (one: any) => one?.user_id !== userData?._id
                    );

                    const userContactInfo = userContacts?.find(
                      (one: any) => one?.userId?._id === userInfo?.user_id
                    );
                    const participantContactDetail =
                      formattedContact[userInfo?.user_id];
                    const roomProfileImg =
                      item?.type === "group" || item?.type === "broadcast"
                        ? item?.profile_img
                        : userInfo?.profile_img;

                    return (
                      roomsData && (
                        <div
                          className="flex justify-between items-center border-b py-2"
                          key={index}
                        >
                          <div className="flex items-center">
                            <div className="h-[45px] w-[45px]">
                              {roomProfileImg ? (
                                <div className="h-[45px] w-[45px]">
                                  <img
                                    src={DefaultImageUrl + roomProfileImg}
                                    alt="profile"
                                    className="h-[45px] w-[45px] rounded-full"
                                  />
                                </div>
                              ) : (
                                <div>
                                  <Avatar />
                                </div>
                              )}
                            </div>
                            <div className="px-3">
                              {item?.type === "group" || item?.type === "broadcast" ? (
                                <div className="lightBlack capitalize">{item?.name}</div>
                              ) : (
                                <div className="lightBlack">
                                  {participantContactDetail && participantContactDetail?.firstName !== "" ? (
                                    <span>
                                      {participantContactDetail?.firstName +
                                        " " +
                                        participantContactDetail?.lastName}
                                    </span>
                                  ) : (
                                    <span className="">{userInfo?.phone}</span>
                                  )}
                                </div>
                              )}
                              <div className="textGray text-xs">   {renderLastMessage(item, userInfo, userContactInfo?.firstName ?? userInfo?.phone, userContactInfo?.lastName ?? "")}</div>
                            </div>

                          </div>
                          <div>
                            <input
                              type="checkbox"
                              name="room"
                              className="cursor-pointer outline-none"
                              value={item?._id}
                              onChange={handleSelectedRoomChange}
                              id={item._id}
                              checked={selectedRooms.includes(item._id)}
                            />
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="text-center">{t("no_rooms_found")}</div>
                )}
              </div>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default FolderDrawer;
