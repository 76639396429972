import dayjs from "dayjs";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IReocordProps } from "./databasetypes";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { IconButton, MenuItem, MenuList, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const Records = ({ record, menuClick, onViewRecord }: IReocordProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const popover_id = openPopover ? "simple-popover" : undefined;

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleMenuClicked = (type: string) => {
    menuClick(record, type);
    handleClosePopover();
  };

  const handleRecordClick = () => {
    onViewRecord(record);
  };

  return (
    <div className="flex items-start justify-between">
      <div onClick={handleRecordClick} className="flex gap-1 cursor-pointer">
        <div>
          <DescriptionOutlinedIcon sx={{ color: "#33CCFF", fontSize: 40 }} />
        </div>

        <div className="w-full">
          <div className="text-gray-800 text-lg font-medium line-clamp-2">
            {record?.title}
          </div>
          <div className="text-gray-500 text-xs mt-1">
            {t("createdat")}{" "}
            {dayjs(record?.createdAt).format("MMM DD.YYYY hh:mm a")}
          </div>
          {record?.updatedAt && record?.createdAt !== record?.updatedAt && (
            <div className="text-gray-500 text-xs mt-1">
              {t("updatedat")}{" "}
              {dayjs(record?.updatedAt).format("MMM DD.YYYY hh:mm A")}
            </div>
          )}
        </div>
      </div>

      <IconButton onClick={(ev) => handleOpenPopover(ev)}>
        <MoreVertIcon />
      </IconButton>

      <Popover
        id={popover_id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem onClick={() => handleMenuClicked("edit")}>
            <ContentCopyIcon fontSize={"small"} sx={{ color: "#33CCFF" }} />
            <span className="ml-1">{t("edit")}</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuClicked("delete")}>
            <DeleteOutlinedIcon fontSize={"small"} sx={{ color: "#33CCFF" }} />
            <span className="ml-1">{t("delete")}</span>
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
};

export default Records;
