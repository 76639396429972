import dayjs from "dayjs";

function formatScheduleData(data) {
    const start = dayjs(data?.start).set('hours', dayjs(data?.time).hour()).set('minute', dayjs(data?.time).minute()).set('second', dayjs(data?.time).second());
    const { __typename, ...restMsg } = data?.message?.[0]

    const obj = {
        _id: data?._id,
        parent_id: data?.parent_id,
        roomId: data?.roomId,
        roomType: data?.roomType,
        startDate: start,
        endDate: start,
        from: data?.startDate,
        to: data?.endDate,
        time: dayjs(data?.time),
        type: data?.type,
        isApprovalNeeded: data?.isApprovalNeeded,
        message: restMsg,
        approvalReminderTime: data?.notifications?.length ? {
            Count: data?.notifications?.[0]?.Count,
            Unit: data?.notifications?.[0]?.Unit,
        } : [],
        frequency: data?.frequency,
        days: data?.days,
        noOfWeeks: data?.noOfWeeks,
        repeatEveryMonth: data?.repeatEveryMonth,
        months: data?.months,
        onDay: data?.onDay ?? 1,
        onWeek: data?.onWeek,
        everyWeek: data?.everyWeek,
        monthDateType: data?.monthDateType,
    }

    return obj
}

export default formatScheduleData