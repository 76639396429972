import { Popover, Typography } from "@mui/material"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MouseEvent, useState } from "react";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

function DateSelect({ currentDate, calendarApi, setViewMode }: any) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    function handleDateChange(date: Date) {
        setViewMode("timeGridDay")
        calendarApi()?.changeView("timeGridDay", new Date(date))
        handlePopoverClose()
    }

    return (
        <div>
            <Typography
                onClick={handlePopoverOpen}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                className="flex gap-3 text-2xl font-semibold tracking-tight whitespace-nowrap mx-16 cursor-pointer"
            >
                <CalendarMonthOutlinedIcon />
                <span>{currentDate?.view?.title}</span>
            </Typography>

            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        onChange={handleDateChange}
                    />
                </LocalizationProvider>
            </Popover>
        </div>
    )
}

export default DateSelect