import { ChangeEvent, useEffect, useState } from "react"
import { CustomNotificationPropsType } from "../../../types/types"
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ApprovalReminderTimeTypes } from "../../../types/enum";
import { useTranslation } from "react-i18next";
import capitalize from "../../../../../utils/capitalize";
import dayjs from "dayjs";
import convertToMinutes from "../../../../../utils/convertToMinutes";

function AddCustomNotification(props: CustomNotificationPropsType) {
    const { t } = useTranslation()
    const { show, setShow, onSubmit, date, time } = props
    const [numbers, setNumbers] = useState(1)
    const [type, setType] = useState<any>(ApprovalReminderTimeTypes["MINUTE"])
    const currentDate = dayjs()
    const selectedDateTime = dayjs(`${dayjs(date).format("YYYY-MM-DD")}T${dayjs(time).format("HH:mm:ss")}`)
    const [remainingTime, setRemainingTime] = useState<number>(0);

    function handleClose() {
        setShow(false)
        setNumbers(1)
        setType(ApprovalReminderTimeTypes["MINUTE"])
    }

    function handleChange(ev: "inc" | "dec") {
        if (ev == "inc") {
            const nextCount = numbers + 1;
            const nextDuration = convertToMinutes(nextCount, type);
            // if (nextDuration <= remainingTime) {
                setNumbers(nextCount);
            // }
        } else {
            setNumbers(prev => Math.max(prev - 1, 1));
        }
    }

    function handleSubmit() {
        onSubmit({ Count: Number(numbers), Unit: type })
        handleClose()
    }

    useEffect(() => {
        const remainingDuration = selectedDateTime?.diff(currentDate, "minute")
        setRemainingTime(remainingDuration);
    }, [numbers, type, selectedDateTime, currentDate]);

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
            }}
        >
            <DialogContent>
                <div className="font-semibold mb-5 text-[18px]">
                    {t("custom_Notification")}
                </div>
                <div className="flex justify-center items-center gap-5">
                    <button
                        type="button"
                        disabled={Boolean(numbers == 1)}
                        className="outline-none border border-[#5baaf9] disabled:border-gray-200 disabled:text-gray-200 text-[#5baaf9] py-1 px-4 font-medium rounded-md"
                        onClick={() => handleChange("dec")}
                    >
                        -
                    </button>
                    <div className="text-sm font-medium"> {numbers} </div>
                    <button
                        type="button"
                        className="outline-none border border-[#5baaf9] text-sm disabled:border-gray-200 disabled:text-gray-200 text-[#5baaf9] py-1 px-4 font-medium rounded-md"
                        onClick={() => handleChange("inc")}
                    >
                        +
                    </button>
                </div>

                <div className="flex flex-wrap gap-5 mt-3 justify-center">
                    {Object.entries(ApprovalReminderTimeTypes)?.map(([key, value]) => {
                        const duration = convertToMinutes(numbers, value);
                        const disabled = duration > remainingTime;

                        return (
                            <div key={key}>
                                <FormControl>
                                    <RadioGroup
                                        value={type}
                                        name="controlled-radio-buttons-group"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setType(e.target.value as ApprovalReminderTimeTypes)}
                                        sx={{ display: "flex", flexDirection: "row", }}
                                    >
                                        <FormControlLabel
                                            value={value}
                                            label={
                                                <div className="text-sm">
                                                    {capitalize(t(value?.toLowerCase()))}
                                                </div>
                                            }
                                            control={
                                                <Radio
                                                    // disabled={Boolean(disabled)}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        )
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                    sx={{ fontWeight: 600 }}
                >
                    {t("cancel")}
                </Button>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ fontWeight: 600 }}
                >
                    {t("submit")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCustomNotification