import { DefaultImageUrl } from "../../../Constant/Constant";
import DocumentIcon from "../../../Assets/Images/DocumentFileIcon.png";

const TaskQuestionDocument = ({ currentTask,currentOrganization }: any) => {
  const handleDownloadDocument = () => {
    window.open(
      DefaultImageUrl + currentTask?.attachment?.attachment?.filename
    );
  };

  return (
    <>
      {currentTask?.attachment?.type === "DOCUMENT" && (
        <div className="mt-3">
          <div className="flex">
            <img src={DocumentIcon} alt="" className="h-[30px] w-[30px]" />
            <div
              className="ml-2 text-sm textGray cursor-pointer"
              style={{ overflowWrap: "anywhere" }}
              onClick={handleDownloadDocument}
            >
              {currentTask?.attachment?.attachment?.filename.split(currentOrganization?._id+'/').pop()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskQuestionDocument;
