import { Chip, Dialog, DialogContent, IconButton } from "@mui/material";
import { TAssignment } from "../../types/types";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import React, { useState } from "react";
import clsx from "clsx";
import { colors } from "../../../../Constant/Constant";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOrganization } from "../../../../Redux/OrganizationSlice";
import { useOrganizations } from "../../../../hooks/useOrganizations";
import GroupIcon from "@mui/icons-material/Group";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

type TProps = {
  show: boolean;
  assignment: TAssignment;
  setAssignmentModal: ({ data, show }: any) => void;
  setTabValue: (value: number) => void;
  setTaskTab: (value: number) => void;
};

function AssignmentDialog(props: TProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { getMyAssignment } = useOrganizations();
  const { currentOrganization, organizations } = useSelector(
    (state: any) => state?.organization
  );
  const { show, setAssignmentModal, assignment, setTabValue, setTaskTab } =
    props;
  const isSameDate = dayjs(assignment?.start).isSame(dayjs(), "day");
  const isSameTime = dayjs().valueOf() >= assignment?.startTimeInMs;
  const isExecutable =
    assignment?.recurrent != "ANYTIME" || !assignment?.completeTime;
  const findOrg = organizations?.find(
    (item) => item?._id == assignment?.organizationId
  );

  function closeComposeDialog() {
    setAssignmentModal({ data: null, show: false });
  }

  function handleNavigateOnTask() {
    if (currentOrganization?._id == assignment?.organizationId) {
      setTabValue(1);
      setTaskTab(1);
    } else {
      if (findOrg?._id) {
        setloading(true);
        dispatch(setCurrentOrganization(findOrg));
        localStorage.setItem("currentOrganization", JSON.stringify(findOrg));
        getMyAssignment(findOrg?._id, 0, 0).finally(() => {
          setloading(false);
          setTabValue(1);
          setTaskTab(1);
        });
      } else {
        alert(t("orgnotfound"));
      }
    }
  }

  return (
    <Dialog open={show} fullWidth maxWidth="sm" onClose={closeComposeDialog}>
      <DialogContent>
        <div className="flex justify-between">
          <div className="text-left text-[24px] font-semibold">
            {assignment?.title}
          </div>

          <div>
            <IconButton onClick={closeComposeDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className="text-gray-600">
          {t("organization")}: {findOrg?.name}
        </div>

        {assignment?.scenario?.description && (
          <div className="mt-2 ">{assignment?.scenario?.description}</div>
        )}

        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-600 mt-2">
            <span className="mr-1">
              {dayjs(assignment?.start).format("DD MMMM YYYY")}
            </span>
            {assignment?.recurrent == "ANYTIME" ? (
              <span>| {t("anytime")}</span>
            ) : (
              <span>
                | {dayjs(assignment?.startTimeInMs).format("hh:mm A")}
              </span>
            )}
          </div>

          {isSameDate && isSameTime && isExecutable && (
            <button
              disabled={Boolean(loading)}
              onClick={handleNavigateOnTask}
              style={{
                backgroundColor: colors.callReminder,
              }}
              className="outline-none text-white px-4 py-1 h-fit w-fit rounded-md font-semibold"
            >
              {loading ? t("please_wait") : t("execute")}
            </button>
          )}
        </div>

        <div className="mt-3">
          <div className="flex gap-4 items-center">
            <div
              className={clsx(
                "text-[12px] text-white px-4 py-1 h-fit w-fit rounded-md font-semibold"
              )}
              style={{
                backgroundColor: colors.assignment,
              }}
            >
              {t(assignment?.recurrent)}
            </div>

            <div className="flex gap-3 items-center">
              <div className="flex items-center gap-1 mt-2">
                <GroupIcon sx={{ color: colors.assignment }} />
                <span className="font-semibold">
                  {assignment?.members?.length}
                </span>
              </div>
              <div className="flex items-center gap-1 mt-2">
                <FormatListBulletedIcon sx={{ color: colors.assignment }} />
                <span className="font-semibold">
                  {assignment?.scenario?.tasks?.length - 2}
                </span>
              </div>
            </div>
          </div>

          {assignment?.daylyParams?.dayOfWeeks?.length > 0 && (
            <div className="flex flex-wrap gap-3 mt-4">
              {assignment?.daylyParams?.dayOfWeeks?.map(
                (item: string, key: number) => {
                  return <Chip key={key} variant="outlined" label={item} />;
                }
              )}
            </div>
          )}

          {assignment?.montlyParams?.months?.length > 0 && (
            <div className="flex flex-wrap gap-3 mt-4">
              {assignment?.montlyParams?.months?.map(
                (item: string, key: number) => {
                  return <Chip key={key} variant="outlined" label={item} />;
                }
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AssignmentDialog;
