import { useState } from "react";
import CurrentOrganizations from "../../Components/Organizations/CurrentOrganizations";
import Invites from "../../Components/Organizations/Invites";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { authCllient } from "../../Graphql/authClient";
import { GET_MY_INVITES } from "../../Graphql/Queries";
import { useTranslation } from "react-i18next";

import icon from '../../Assets/invite.png'
import { Typography } from "@mui/material";
interface OrganizationsProps {
  setShowOrganization: (value: boolean) => void;
}

function Organizations({ setShowOrganization }: OrganizationsProps) {
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // const handleClickInviteTab = () => {
  //   setLoading(true);

  //   authCllient
  //     .query({
  //       query: GET_MY_INVITES,
  //     })
  //     .then((res) => {
  //     })
  //     .catch((err) => console.log(`Error occured at Get Invitations ${err}`))
  //     .finally(() => {
  //       setTab(2);
  //       setLoading(false);
  //     });
  // };

  return (
    <div className="w-[450px] text-[#000000b8]">
      <div className="cursor-pointer border-b w-full py-2 px-2 flex gap-12 items-center h-[60px] bg-[#F3F9FC]">
        <div className="flex gap-2" onClick={() => setShowOrganization(false)}>
          <div>
            <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
          </div>
          <div className="pl-1">{t("back")}</div>
        </div>

       
      </div>

      {/* <div className="flex text-sm pt-4 border-b">
        <div
          className={
            tab === 1
              ? "border-botom-primary text-center px-7 w-1/2 pb-2 cursor-pointer"
              : "text-center px-7 w-1/2 pb-2 cursor-pointer"
          }
          onClick={() => setTab(1)}
        >
          Organizations
        </div>
        <div
          className={
            tab === 2
              ? "border-botom-primary text-center px-7 w-1/2 pb-2 cursor-pointer"
              : "text-center px-7 w-1/2 pb-2 cursor-pointer"
          }
          onClick={() => setTab(2)}
        >
          Invites
        </div>
      </div> */}

      {/* {tab === 1 ? (
        <CurrentOrganizations onSetDrawer={setShowOrganization} />
      ) : ( */}
      <Typography variant="h5" className="w-full text-center flex mx-auto my-6 pt-4 justify-center"> {t("invites")}</Typography>
        <Invites onSetDrawer={setShowOrganization} loading={loading} />
      {/* )} */}
    </div>
  );
}

export default Organizations;
