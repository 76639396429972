import { useTranslation } from "react-i18next";
import { InvitationsType } from "../types/types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../../Constant/Constant";
import UserPic from "../../../Assets/Images/user.png";
import ScheduleMessage from "../../../Pages/RightTab/RightRoomDrawer/schedule/ScheduleMessage";
import { ParticipantAcceptStatus, RecurrentTypes } from "../types/enum";
import { authCllient } from "../../../Graphql/authClient";
import {
  UPDATE_PARENT_REMINDER_STATUS,
  UPDATE_REMINDER_STATUS,
} from "../../../Graphql/Mutations";
import { CircularProgress } from "@mui/material";
import EventConfirmation from "../dialogs/EventConfirmation";

type PropTypes = {
  setAlert: (value: any) => void;
  setIsRefresh: (value: boolean) => void;
  invitation: InvitationsType;
};

function ScheduleApproval(props: PropTypes) {
  const { t } = useTranslation();
  const { setAlert, invitation, setIsRefresh } = props;
  const { rooms } = useSelector((state: any) => state?.chat);
  const { userData, formattedContact } = useSelector(
    (state: any) => state?.user
  );
  const [loading, setloading] = useState(false);
  const roomDetails = rooms?.find(
    (room) => room?._id == invitation?.message?.[0]?.roomId
  );
  const otherUser = roomDetails?.participants?.find(
    (item) => item?._id != userData?._id
  );
  const formattedUser = formattedContact?.[otherUser?.user_id];
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  function clickOnAcceptReject(status: string) {
    setSelectedStatus(status);
    setShowConfirmation(true);
  }

  async function handleApproveReject(
    type: string,
    isOnce: boolean,
    status: string
  ) {
    const payload = {
      _id: type == "this" ? invitation?._id : invitation?.parent_id,
      ApprovalStatus: isOnce ? status : selectedStatus,
    };

    try {
      setloading(true);
      const response = await authCllient.mutate({
        mutation:
          type == "this"
            ? UPDATE_REMINDER_STATUS
            : UPDATE_PARENT_REMINDER_STATUS,
        variables: {
          input: payload,
        },
      });

      setIsRefresh(true);
      let msg = "";
      if (!response?.data) {
        msg = "something_went_wrong";
      } else {
        msg =
          (selectedStatus || status) == ParticipantAcceptStatus.ACCEPT
            ? "approved_success"
            : "unapproved_success";
      }
      setAlert({ msg, show: true });
    } catch (err) {
      setAlert({ msg: "something_went_wrong", show: true });
      console.log(err);
    } finally {
      setloading(false);
    }
  }

  return (
    <div>
      <div>
        <span>{t("schedule_approval_des")}</span>
        {roomDetails?.type == "group" ? (
          <span className="font-semibold">{roomDetails?.name}</span>
        ) : (
          <span className="font-semibold">
            {formattedUser?.firstName ? (
              <span>{formattedUser?.firstName}</span>
            ) : (
              <span>{otherUser?.phone}</span>
            )}
          </span>
        )}
      </div>

      <div className="flex gap-2 my-4">
        <div className="h-[35px] min-w-[35px] w-[35px]">
          {userData?.profile_img ? (
            <img
              src={DefaultImageUrl + userData?.profile_img}
              alt="as"
              className="h-full w-full rounded-full"
            />
          ) : (
            <img src={UserPic} className="h-full w-full rounded-full" />
          )}
        </div>

        <ScheduleMessage
          messageObj={invitation?.message?.[0]}
          schedule={invitation}
        />
      </div>

      {loading ? (
        <div className="mt-3 flex justify-center">
          <CircularProgress size={20} />
        </div>
      ) : (
        <div className="mt-3 flex gap-3">
          <button
            disabled={Boolean(loading)}
            onClick={() => {
              if (invitation?.recursive == RecurrentTypes.ONCE) {
                handleApproveReject(
                  "this",
                  true,
                  ParticipantAcceptStatus.ACCEPT
                );
              } else {
                clickOnAcceptReject(ParticipantAcceptStatus.ACCEPT);
              }
            }}
            className="outline-none text-sm border border-green-700 text-green-700 rounded-md py-1 px-2"
          >
            {t("approve")}
          </button>
          <button
            disabled={Boolean(loading)}
            onClick={() => {
              if (invitation?.recursive == RecurrentTypes.ONCE) {
                handleApproveReject(
                  "this",
                  true,
                  ParticipantAcceptStatus.REJECT
                );
              } else {
                clickOnAcceptReject(ParticipantAcceptStatus.REJECT);
              }
            }}
            className="outline-none text-sm border border-red-700 text-red-700 rounded-md py-1 px-2"
          >
            {t("reject")}
          </button>
        </div>
      )}

      {showConfirmation && (
        <EventConfirmation
          show={showConfirmation}
          setShow={setShowConfirmation}
          onSubmit={handleApproveReject}
          message={t("update_approval_status")}
        />
      )}
    </div>
  );
}

export default ScheduleApproval;
