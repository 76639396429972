import TextIcon from "../../../Assets/Images/text-icon.png";
import NumberIcon from "../../../Assets/Images/number-icon.png";
import FileUploadIcon from "../../../Assets/Images/file-upload-icon.png";
import DateIcon from "../../../Assets/Images/calendar-icon.png";
import { ApprovalReminderTimeTypes } from "../../../Components/Calendar/types/enum";

export const initialData = [
  {
    id: 1,
    name: "Warranty Period Warranty Period Warranty Period",
    desc: "You edited, June 20, 2024",
    type: "folder",
  },
  {
    id: 2,
    name: "Question Paper",
    desc: "You edited, June 20, 2024",
    type: "folder",
  },
  {
    id: 3,
    name: "Untitled Document",
    desc: "You edited, June 20, 2024",
    type: "file",
  },
  {
    id: 4,
    name: "Untitled Document",
    desc: "You edited, June 20, 2024",
    type: "file",
  },
  {
    id: 5,
    name: "Question Paper",
    desc: "You edited, June 20, 2024",
    type: "folder",
  },
];

export const folderStructure = {
  1: [
    {
      id: 3,
      name: "File1.txt",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 4,
      name: "File2.txt",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 5,
      name: "Sub folder",
      type: "folder",
      desc: "You edited, June 20, 2024",
    },
  ],
  2: [
    {
      id: 6,
      name: "Vacation.jpg",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 7,
      name: "Family.png",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 8,
      name: "Events",
      type: "folder",
      desc: "You edited, June 20, 2024",
    },
  ],
  3: [
    {
      id: 9,
      name: "File3.txt",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 10,
      name: "Sub Sub folder",
      type: "folder",
      desc: "You edited, June 20, 2024",
    },
  ],
  4: [
    {
      id: 11,
      name: "Birthday.jpg",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 12,
      name: "Wedding.png",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
  ],
  5: [
    {
      id: 13,
      name: "File4.txt",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
    {
      id: 14,
      name: "File5.txt",
      type: "file",
      desc: "You edited, June 20, 2024",
    },
  ],
};

export const preDefinedFields = [
  {
    key: "firstName",
    label: "firstname",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "lastName",
    label: "lastname",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "email",
    label: "email",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "mobile",
    label: "mobile",
    value: "",
    type: "number",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "landLine",
    label: "landline",
    value: "",
    type: "number",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "address",
    label: "address",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "company",
    label: "company",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
  {
    key: "comment",
    label: "comment",
    value: "",
    type: "text",
    checked: false,
    required: false,
    isCustom: false,
  },
];

export const customFieldTypes = [
  {
    icon: TextIcon,
    label: "text",
    value: "text",
  },
  {
    icon: NumberIcon,
    label: "number",
    value: "number",
  },
  {
    icon: DateIcon,
    label: "date",
    value: "date",
  },
  {
    icon: FileUploadIcon,
    label: "attachment",
    value: "attachment",
  },
];

export const reminderOptions = [
  { Count: 5, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 10, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 15, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 30, Unit: ApprovalReminderTimeTypes.MINUTE },
  { Count: 1, Unit: ApprovalReminderTimeTypes.HOUR },
  { Count: 1, Unit: ApprovalReminderTimeTypes.DAY },
];
