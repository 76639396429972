import { gql } from "@apollo/client";
import {
  ASSIGNMENT_FRAGMENT,
  INVITE_FRAGMENT,
  MEMBER_FRAGMENT,
  ORGANIZATION_FRAGMENT,
} from "./Queries";
import {
  APPOINTMENT_FRAGMENT,
  FOLDER_FRAGMENT,
  RECORD_FRAGMENT,
  CHAT_FRAGMENT,
  REMINDER_FRAGMENT,
  SCHEDULE_FRAGMENT,
} from "./Fragments";

export const UPLOAD_CHAT_FILE = gql`
  mutation UploadChatFile(
    $file: Upload!
    $thumbnail: Upload
    $input: UploadChatFileInput!
  ) {
    UploadChatFile(file: $file, thumbnail: $thumbnail, input: $input) {
      data {
        filename
        type
      }
      thumbnail {
        filename
        type
      }
    }
  }
`;

export const CREATE_ROOM = gql`
  mutation createRoom($input: createRoomInput!) {
    createRoom(input: $input) {
      roomId
      localId
      isAlreadyExists
      success
      message
    }
  }
`;

export const CREATE_BROADCAST_ROOM = gql`
  mutation createBroadcastRoom($input: createRoomInput!) {
    createBroadcastRoom(input: $input) {
      roomId
      localId
      isAlreadyExists
      success
      message
    }
  }
`;

export const DELETE_BROADCAST_ROOM = gql`
  mutation deleteBroadcastRoom($input: IdDto!) {
    deleteBroadcastRoom(input: $input) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation passwordReset($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      token
      refresh
      mode
      isMasterAdmin
      expiredAt
    }
  }
`;
export const UPDATE_TOPIC = gql`
  mutation updateTopic($input: updateTopicInputDto!) {
    updateTopic(input: $input) {
      _id
      name
      userId
      parent
      subTopics {
        _id
        name
      }
    }
  }
`;
export const DELETE_TOPIC = gql`
  mutation deleteTopic($input: IdDto!) {
    deleteTopic(input: $input) {
      success
      message
    }
  }
`;
export const DELETE_MSG_FROM_TOPIC = gql`
  mutation removeMsgsFromTopic($input: RemoveMsgsFromTopicInputDto!) {
    removeMsgsFromTopic(input: $input) {
      success
      message
    }
  }
`;
export const CREATE_TOPIC = gql`
  mutation createTopic($input: createTopicInputDto!) {
    createTopic(input: $input) {
      _id
      name
      userId
      parent
      subTopics {
        _id
        name
      }
      chats {
        roomId {
          _id
          name
          type
        }
        chatId
        sender {
          _id
          firstName
          lastName
          profile_img
        }
        message {
          type
          index
          duration
          message
          fileURL
          thumbnail
          isForwarded
          fontStyle
          isSent
          created_at
          updated_at
        }
        toUser {
          _id
          firstName
          lastName
          profile_img
        }
      }
    }
  }
`;
export const ADD_MSG_TO_TOPIC = gql`
  mutation addMsgToTopic($input: AddMsgToTopicInputDto!) {
    addMsgToTopic(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_GLOBAL_READ_RECEIPT = gql`
  mutation updateGlobalReadReceipts($input: ActionDto!) {
    updateGlobalReadReceipts(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_ROOM_READ_RECEIPT = gql`
  mutation updateChatroomReadReceipts($input: IdAndActionDto!) {
    updateChatroomReadReceipts(input: $input) {
      success
      message
    }
  }
`;
export const ACCEPT_INVITE = gql`
  mutation accept($input: responseInviteDtoInput!) {
    accept(input: $input) {
      ...OrganizationDetails
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const UPDATE_CONTACT_PROFILE = gql`
  mutation updateContactProfile($input: UpdateUserDetailsDto!) {
    updateContactProfile(input: $input) {
      localId
      phone
      firstName
      lastName
      email
      dob
      country
      region
      city
      street
      prefix
      suffix
      gender
      address
      website
      additional
    }
  }
`;

export const DECLINE_INVITE = gql`
  mutation decline($input: responseInviteDtoInput!) {
    decline(input: $input) {
      ...InviteDetails
    }
  }
  ${INVITE_FRAGMENT}
`;

export const JOIN_CALL = gql`
  mutation getRtmToken($createRtmToken: CreateRtmTokenDTO!) {
    getRtmToken(createRtmToken: $createRtmToken) {
      token
    }
  }
`;

export const CALL_USERS = gql`
  mutation changeCallStatus($changeCallStatus: CallStatusChangeDTO!) {
    changeCallStatus(changeCallStatus: $changeCallStatus) {
      callId
      status
    }
  }
`;

export const CALL_STATUS = gql`
  mutation changeCallStatus($changeCallStatus: CallStatusChangeDTO!) {
    changeCallStatus(changeCallStatus: $changeCallStatus) {
      callId
      status
      sharer_uid
      Screen_status
    }
  }
`;

export const CALL_END = gql`
  mutation endCall($endCallInput: EndCallInputDTO!) {
    endCall(endCallInput: $endCallInput) {
      time
      status
    }
  }
`;

export const LEFT_CALL = gql`
  mutation leftCall($input: IdDto!) {
    leftCall(input: $input) {
      time
      status
    }
  }
`;
export const UPLOAD_CHUNK_FILE = gql`
  mutation uploadChunks($file: Upload!) {
    uploadChunks(file: $file) {
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      firstName
      lastName
      profile_img
      bio {
        status
      }
    }
  }
`;

export const UPDATE_USER_BIO = gql`
  mutation setBio($description: String!) {
    setBio(description: $description) {
      _id
      phone
      firstName
      lastName
      profile_img
      bio {
        status
        time
      }
      status
      lastSeen
    }
  }
`;

export const START_REPORT = gql`
  mutation startReport($input: StartReportDto!) {
    startReport(input: $input) {
      _id
      organizationId
      startTime
      completeTime
      lastActionTime
      member {
        _id
        user {
          _id
          firstName
          lastName
        }
        role
      }
      tasksData {
        type
        label
        content
        edgeId
        result
        targetTaskId
        taskStartTime
        taskCompleteTime
        lat
        long
        attachment {
          _id
          organizationId
          userId
          filename
          type
          url
        }
      }
    }
  }
`;

export const ADD_TASK_RESULT = gql`
  mutation addTaskResult($input: AddTaskResultDto!) {
    addTaskResult(input: $input) {
      _id
      organizationId
      assignment {
        _id
        start
        end
        startTimeInMs
        daylyParams {
          dayOfWeeks
          everyWeek
        }
        montlyParams {
          months
          twicePerMonth
        }
        periodical
        members {
          member {
            _id
            user {
              _id
              email
              phone
              firstName
              lastName
              iso_code
              phoneConfirmed
              emailConfirmed
              profile_img
              bio {
                status
                time
              }
              status
              lastSeen
              folders {
                _id
                name
                roomId
              }
              # expoPushToken
            }
            organizationId
            role
          }
          memberRole
        }
        scenario {
          _id
          type
          name
          description
          isValid
          isAssigned
          parentId
          childId
          organizationId
          tasks {
            _id
            label
            content
            type
            subType
            assignTo {
              _id
              user {
                _id
                email
                phone
                firstName
                lastName
                iso_code
                phoneConfirmed
                emailConfirmed
                profile_img
                bio {
                  status
                  time
                }
                status
                lastSeen
                folders {
                  _id
                  name
                  roomId
                }
                # expoPushToken
              }
              organizationId
              role
            }
            position {
              x
              y
            }
            width
            timeout
            remindEvery
            saveUserLocation
            mediaType
            mediaQuality
            mediaDuration
            attachment {
              attachment {
                _id
                organizationId
                userId
                filename
                type
                url
              }
              type
            }
            member {
              _id
              user {
                _id
                email
                phone
                firstName
                lastName
                iso_code
                phoneConfirmed
                emailConfirmed
                profile_img
                bio {
                  status
                  time
                }
                status
                lastSeen
                folders {
                  _id
                  name
                  roomId
                }
                # expoPushToken
              }
              organizationId
              role
            }
            edges {
              _id
              type
              label
              order
              targetTaskID
              options {
                label
                location
                media
              }
            }
          }
        }
        reportsCount
        activeReportId
      }
      member {
        _id
        user {
          _id
          email
          phone
          firstName
          lastName
          iso_code
          phoneConfirmed
          emailConfirmed
          profile_img
          bio {
            status
            time
          }
          status
          lastSeen
          folders {
            _id
            name
            roomId
          }
          # expoPushToken
        }
        organizationId
        role
      }
      startTime
      completeTime
      lastActionTime
      scenario
      tasksData {
        type
        label
        content
        edgeId
        result
        resultExp
        memberId {
          _id
          user {
            _id
            firstName
            lastName
            phone
          }
          role
        }
        taskStartTime
        taskCompleteTime
        targetTaskId
        taskId
        lat
        long
        isApproved
        attachment {
          _id
          organizationId
          userId
          filename
          type
          url
        }
        resultAttachment {
          _id
          organizationId
          userId
          filename
          type
          url
        }
      }
    }
  }
`;

export const START_TASK = gql`
  mutation startTask($input: StartTaskDto!) {
    startTask(input: $input) {
      _id
    }
  }
`;

export const STORAGE_ITEM_FRAGMENT = gql`
  fragment StorageItemDetails on StorageItem {
    _id
    organizationId
    userId
    filename
    type
    url
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $input: UploadFileInput!) {
    uploadFile(file: $file, input: $input) {
      ...StorageItemDetails
    }
  }
  ${STORAGE_ITEM_FRAGMENT}
`;

export const NEW_UPLOAD_FILE = gql`
  mutation uploadFileRecord($file: Upload!, $input: UploadFileInput!) {
    uploadFileRecord(file: $file, input: $input) {
      ...StorageItemDetails
    }
  }
  ${STORAGE_ITEM_FRAGMENT}
`;

export const COMPLETE_REPORT = gql`
  mutation completeReport($input: CompleteReportsInputDto!) {
    completeReport(input: $input) {
      _id
      organizationId
      assignment {
        ...AssignmentDetails
      }
      member {
        ...MemberDetails
      }
      startTime
      completeTime
      lastActionTime
      scenario
      tasksData {
        type
        label
        content
        edgeId
        targetTaskId
        result
        taskStartTime
        taskCompleteTime
        lat
        long
        attachment {
          ...StorageItemDetails
        }
        resultAttachment {
          _id
          organizationId
          userId
          filename
          type
          url
        }
      }
    }
  }
  ${ASSIGNMENT_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${STORAGE_ITEM_FRAGMENT}
`;
export const CREATE_CALL = gql`
  mutation createNewCall($input: CreateCallDTO!) {
    createNewCall(input: $input) {
      call {
        _id
        origin {
          _id
        }
        type
        channelName
        roomId {
          _id
        }

        callParticipants {
          uid
          userId {
            firstName
            _id
          }
          callStatus
          uid
        }
        duration
        callEndedAt
        roomType
        categoryId
        callStatus
        callStartedAt
      }
      token
    }
  }
`;

export const SET_NOTIFICATION_SEEN = gql`
  mutation setNotificationSeen($input: NotificationSeen!) {
    setNotificationSeen(input: $input) {
      success
      message
    }
  }
`;

export const CHECK_TIME_VALIDATION = gql`
  mutation checkIsValidDate($input: currentTimeInput!) {
    checkIsValidDate(input: $input) {
      isCorrectTime
    }
  }
`;

export const ADD_PARTICIPANTS = gql`
  mutation addParticipants($input: AddParticipantsDTO!) {
    addParticipants(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_REMINDER = gql`
  mutation createReminder($input: ReminderInput!) {
    createReminder(input: $input) {
      ...reminderFeilds
    }
  }
  ${REMINDER_FRAGMENT}
`;

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($input: AppointmentInput!) {
    createAppointment(input: $input) {
      ...AppointmentFields
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const UPDATE_REMINDER = gql`
  mutation updateReminder($input: ReminderInput!) {
    updateReminder(input: $input) {
      type
    }
  }
`;

export const DRAG_AND_DROP_EVENT = gql`
  mutation updateDragDrop($input: ReminderInput!) {
    updateDragDrop(input: $input) {
      type
    }
  }
`;

export const DELETE_REMINDER = gql`
  mutation deleteReminder($input: deleteReminderInput!) {
    deleteReminder(input: $input) {
      type
    }
  }
`;

export const UPDATE_REMINDER_STATUS = gql`
  mutation updateReminderApprovalStatus($input: UpdateAprovalStatusInput!) {
    updateReminderApprovalStatus(input: $input) {
      participants {
        _id
        accepted
        role
        firstName
        lastName
        phone
        profile_img
        left_at
      }
    }
  }
`;

export const UPDATE_PARENT_REMINDER_STATUS = gql`
  mutation updateReminderApprovalParent($input: UpdateAprovalStatusInput!) {
    updateReminderApprovalParent(input: $input) {
      participants {
        _id
        accepted
        role
        firstName
        lastName
        phone
        profile_img
        left_at
      }
    }
  }
`;

export const RESEND_REMINDER = gql`
  mutation ResendReminder($input: IdDto!) {
    ResendReminder(input: $input) {
      type
      title
      participants {
        _id
        accepted
        role
        firstName
        lastName
        phone
        profile_img
        left_at
      }
      startDate
      isAllDay
      endTime
      endDate
      roomId
      time
      recursive
      description
      approvalReminderTime {
        _id
        Count
        Unit
      }
      startTimeInMs
      daylyParams {
        dayOfWeeks
        everyWeek
      }
      monthlyParams {
        months
        twicePerMonth
      }
    }
  }
`;

export const CREATE_SCHEDULE_MSG = gql`
  mutation createSchedule($input: scheduleInput!) {
    createSchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${SCHEDULE_FRAGMENT}
`;

export const UPDATE_SCHEDULE_MSG = gql`
  mutation updateSchedule($input: scheduleInput!) {
    updateSchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${SCHEDULE_FRAGMENT}
`;

export const DELETE_SCHEDULE_MSG = gql`
  mutation deleteSchedule($input: deleteReminderInput!) {
    deleteSchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${SCHEDULE_FRAGMENT}
`;

export const CREATE_FOLDER = gql`
  mutation createUserFolder($input: createuserFolderInputDto!) {
    createUserFolder(input: $input) {
      ...folderFields
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const UPDATE_FOLDER = gql`
  mutation updateChildFolderName($input: updateuserFolderInputDto!) {
    updateChildFolderName(input: $input) {
      ...folderFields
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const DELETE_FOLDER = gql`
  mutation deleteUserFolder($input: IdDto!) {
    deleteUserFolder(input: $input) {
      success
    }
  }
`;

export const CREATE_RECORD = gql`
  mutation createUserRecord($input: CreateRecordInput!) {
    createUserRecord(input: $input) {
      ...recordFields
    }
  }
  ${RECORD_FRAGMENT}
`;

export const DELETE_RECORD = gql`
  mutation deleteRecord($input: IdDto!) {
    deleteRecord(input: $input) {
      success
    }
  }
`;

export const GET_RECORD_BY_ID = gql`
  mutation getRecordById($input: IdDto!) {
    getRecordById(input: $input) {
      ...recordFields
    }
  }
  ${RECORD_FRAGMENT}
`;

export const UPDATE_RECORD = gql`
  mutation updateUserRecord($input: CreateRecordInput!) {
    updateUserRecord(input: $input) {
      success
    }
  }
`;
export const SEND_CHAT = gql`
  mutation sendChat($input: sendChatInput!) {
    sendChat(input: $input) {
      ...chatFields
    }
  }
  ${CHAT_FRAGMENT}
`;

export const UPDATE_CHAT = gql`
  mutation updateChat($input: udpateChatInput!) {
    updateChat(input: $input) {
      success
    }
  }
`;

export const NOTIFICATION_LOGS = gql`
  mutation NotificationApi($input: notificationAPiInput!) {
    NotificationApi(input: $input) {
      success
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateUserLanguage($input: updateUserLanguageInput!) {
    updateUserLanguage(input: $input) {
      _id
    }
  }
`;

export const FORCE_LGOUT = gql`
  mutation logoutDevicesWeb {
    logoutDevicesWeb {
      success
    }
  }
`;
