import { Avatar, MenuItem, Popover } from "@mui/material";
import clsx from "clsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DeletePoll from "./polls/DeletePoll";
import PollMessageFields from "./PollMessageFields";
import { DefaultImageUrl } from "../../Constant/Constant";
import dayjs from "dayjs";

interface IProps {
  senderId: string;
  pollData: any;
  messageId: string;
  item: any;
  messages: {
    msgId: Array<string>;
    senderId: Array<string>;
    time: any;
  };
  showCheckbox: boolean;
  setMessages: (value: {
    msgId: Array<string>;
    senderId: Array<string>;
    time: any;
  }) => void;
  setChangeFooter: (value: boolean) => void;
  setShowCheckbox: (value: boolean) => void;
  setShowPollDrawer: (value: boolean) => void;
  setPollDrawerData: (value: any) => void;
  newMessageArray: any;
  setNewMessageArray: any;
  date: any
}

const PollMessage = ({
  senderId,
  pollData,
  messageId,
  item,
  messages,
  showCheckbox,
  setMessages,
  setShowCheckbox,
  setChangeFooter,
  setPollDrawerData,
  setShowPollDrawer,
  newMessageArray,
  setNewMessageArray,
  date
}: IProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ poll: null, show: false });
  const { userData } = useSelector((state: any) => state?.user);
  const { activeRoomData, formattedParticipantsOfActiveRoom } = useSelector(
    (state: any) => state?.chat
  );
  const isUserBlocked = activeRoomData?.participants?.find(
    (el: any) => el?.left_at !== 0 && el?.user_id === userData?._id
  );
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;
  const senderInfo = formattedParticipantsOfActiveRoom[item.sender];

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action: string) => {
    if (action == "delete") {
      handleMessageSelect(true);
      setChangeFooter(true);
      setShowCheckbox(true);
    }
    handleClosePopover();
  };

  const handleMessageSelect = (checked: boolean) => {
    const tempIds = [...messages?.msgId];
    const tempSender = [...messages?.senderId];
    const tempTime = [...messages?.time];

    if (checked) {
      tempIds.push(item?._id);
      tempSender.push(item?.sender);
      tempTime.push(item?.created_at);
    } else {
      const index = tempIds.indexOf(item?._id);
      if (index > -1) {
        tempIds.splice(index, 1);
        tempSender.splice(index, 1);
        tempTime.splice(index, 1);
      }
    }

    setMessages({ msgId: tempIds, senderId: tempSender, time: tempTime });
  };

  const viewPollDetails = () => {
    setPollDrawerData(pollData);
    setShowPollDrawer(true);
  };

  return (
    <div key={messageId}>
      <div className="w-full flex gap-4 justify-between">
        {showCheckbox && (
          <div className="h-[20px] w-[20px]">
            <input
              type="checkbox"
              name="message"
              className="mr-2 cursor-pointer h-[20px] w-[20px]"
              checked={Boolean(messages?.msgId?.includes(item?._id))}
              onChange={(ev) => handleMessageSelect(ev.target.checked)}
            />
          </div>
        )}

        <div
          className={clsx(
            "w-full flex gap-2",
            userData?._id == senderId ? "justify-end" : "justify-start"
          )}
        >
          {senderId != userData?._id && (
            <div className="h-[35px] min-w-[35px] max-w-[35px]">
              {senderInfo?.profile_img !== "" && !isUserBlocked ? (
                <img
                  src={DefaultImageUrl + senderInfo?.profile_img}
                  className="rounded-full h-full w-full"
                />
              ) : (
                <Avatar sx={{ width: "35px", height: "35px" }} />
              )}
            </div>
          )}

          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }}
            className="py-4 px-6 bg-[#e0faff] rounded-md w-[400px] relative"
          >
            <PollMessageFields
              polls={pollData}
              messageId={messageId}
              newMessageArray={newMessageArray}
              setNewMessageArray={setNewMessageArray}
              date={date}
            />

            <div
              className={clsx(
                "mt-2 text-xs textGray",
                senderId == userData?._id ? "text-right" : "text-left"
              )}
            >
              {dayjs(item?.created_at).format("HH:mm")}
            </div>

            <div
              onClick={viewPollDetails}
              className="text-center cursor-pointer text-[#308487] border border-[#308487] rounded-md p-[5px] mt-2"
            >
              {t("viewresult")}
            </div>

            <MoreVertIcon
              onClick={handleIconClick}
              className="cursor-pointer absolute top-2 right-2"
            />

            <Popover
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleActionClick("delete")}>
                {t("delete")}
              </MenuItem>
            </Popover>

            {deleteModal && (
              <DeletePoll
                show={deleteModal.show}
                poll={deleteModal?.poll}
                setShow={setDeleteModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollMessage;
