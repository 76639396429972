import { useTranslation } from "react-i18next";
import { FormPropsType } from "../../../types/types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ChangeEvent } from "react";
import Notes from "../../../../../coreComponent/Note";
import { getCalendarColor } from "../../../utils/calendarColors";

function NotifyParticipants(props: FormPropsType) {
  const { t } = useTranslation();
  const { form, setForm } = props;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setForm({ ...form, isConfirmationNeeded: checked ? true : null });
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setForm({ ...form, isConfirmationNeeded: value == "true" ? true : false });
  };

  return (
    <div>
      <div className="w-fit">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={handleCheckboxChange}
                checked={Boolean(form?.isConfirmationNeeded != null)}
                sx={{
                  ".MuiSvgIcon-root": {
                    color: getCalendarColor(form?.type, "dark"),
                  },
                }}
              />
            }
            label={<div className="text-sm">{t("notify_participants")}</div>}
          />
        </FormGroup>
      </div>

      {form?.isConfirmationNeeded != null && (
        <div className="-mt-[7px]">
          <FormControl>
            <RadioGroup
              value={form?.isConfirmationNeeded}
              onChange={handleRadioChange}
              name="controlled-radio-buttons-group"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{
                      ".css-hyxlzm": {
                        color: getCalendarColor(form?.type, "dark"),
                      },
                    }}
                  />
                }
                label={<div className="text-sm">{t("with_confirmation")}</div>}
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{
                      ".css-hyxlzm": {
                        color: getCalendarColor(form?.type, "dark"),
                      },
                    }}
                  />
                }
                label={
                  <div className="text-sm">{t("without_confirmation")}</div>
                }
              />
            </RadioGroup>
          </FormControl>

          <Notes
            note={
              form?.isConfirmationNeeded
                ? t("confirm_notify_msg")
                : t("without_confirm_notify_msg")
            }
          />
        </div>
      )}
    </div>
  );
}

export default NotifyParticipants;
