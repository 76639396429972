import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { DefaultImageUrl } from "../../Constant/Constant";
import { useMutation } from "@apollo/client";
import { UPDATE_USER, UPLOAD_CHAT_FILE } from "../../Graphql/Mutations";
import { emit, socketListen } from "../../Socket";
import Toast from "../../coreComponent/Toast";
import { CircularProgress, Dialog, DialogContent, Popover } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Webcam from "react-webcam";
import Cropper from "react-easy-crop";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { FiDelete } from "react-icons/fi";
import { BsFillTrashFill } from "react-icons/bs";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
};

function EditGroup({ setEditGroup }: any) {
  const [updateUser] = useMutation(UPDATE_USER);
  const [image, setImage] = useState(defaultSrc);
  const { userData } = useSelector((state: any) => state.user);
  const { activeRoomData } = useSelector((state: any) => state.chat);
  const [groupName, setGroupName] = useState(activeRoomData?.name);
  const [groupImage, setGroupImage] = useState<any>("");
  const [fileUpload, { data, error }] = useMutation(UPLOAD_CHAT_FILE);
  const [showToast, setShowToast] = useState(false);
  const [showNameButton, setShowNameButton] = useState(false);
  const [showDescButton, setShowDescButton] = useState(false);
  const [previewImg, setPreviewImg] = useState<any>("");
  const [imageCropperModal, setImageCropperModal] = useState<any>(false);
  const [cropImageUrl, setCropImageUrl] = useState<any>(false);
  const [showWebCam, setShowWebCam] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [removeImage, setRemoveImage] = useState(false);
  const [crop, setCrop] = useState<any>({ x: 400, y: 400 });
  const webcamRef = useRef<any>(null);
  const [webcamImage, setWebcamImage] = useState("");
  const [blobImageUrl, setBlobImageUrl] = useState<any>("");
  const [webcamObjFile, setWebcameObjFile] = useState<any>("");
  const [rotation, setRotation] = useState(0);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const [groupDescription, setGroupDescription] = useState(
    activeRoomData?.bio?.status
  );
  const { t } = useTranslation()

  const backFunc = () => {
    setEditGroup(false);
  };

  socketListen("message", (data: any) => {
    if (
      data.type === "setRoomPicture" ||
      data.type === "updateRoomName" ||
      data.type === "setRoomDescription"
    ) {
      setShowToast(true);
      setTimeout(() => {
        setImageLoading(false)
      }, 2000)

    }
  });


  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleChangeGroupName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 26) {
      setGroupName(e.target.value);
    }
    if (e.target.value && e.target.value !== activeRoomData?.name) {
      setShowNameButton(true);
    } else {
      setShowNameButton(false);
    }
  };

  const handleChangeGroupDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupDescription(e.target.value);
    if (e.target.value && e.target.value !== activeRoomData?.bio?.status) {
      setShowDescButton(true);
    } else {
      setShowDescButton(false);
    }
  };

  const handleUpdateGroupName = () => {
    emit("setRoomName", { roomId: activeRoomData?._id,roomType:activeRoomData?.type, newName: groupName });
    setShowNameButton(false);
  };

  const handleUpdateGroupDesc = () => {
    emit("setRoomDescription", {
      roomId: activeRoomData?._id,
      roomType:activeRoomData?.type,
      desc: groupDescription,
    });
   
    setShowDescButton(false);
  };

  if (error) {
    console.log("Error when group image uploading");
  }

  const captureWebcamImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamImage(imageSrc);
    setImage(imageSrc);
    setShowWebCam(false);
    fetch(imageSrc)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new File([arrayBuffer], `${Math.floor(Math.random() * (10000 * 10000))}.jpeg`, {
          type: "image/jpeg",
        });
        // setWebcameObjFile(file);
      });
  }, [webcamRef]);

  function getCroppedImg(imageSrc, pixelCrop) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        const dataUrl = canvas.toDataURL("image/jpeg", 1);
        resolve(dataUrl);
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      image.src = imageSrc;
    });
  }

  const handleUpdateImage = () => {
    fileUpload({
      variables: {
        file: blobImageUrl,
        thumbnail: null,
        input: {
          roomId: activeRoomData?._id,
          _id: userData?._id,
        },
      },
    }).then((response) => {
      setImageLoading(true);
    
      emit("setRoomPicture", {
        roomId: activeRoomData?._id,
        roomType:activeRoomData?.type,
        imageURl: response.data?.UploadChatFile?.data?.filename,
      });
      setImageCropperModal(false);
      setCropImageUrl(false);
      setImageLoading(false);
      setAnchorEl(null);

      setTimeout(() => {
        setGroupImage(response.data?.UploadChatFile?.data?.filename);
      }, 3000);
    });
  };

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  };

  const onZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const onZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  return (
    <div>
      <Dialog
        open={imageCropperModal}
        onClose={() => {
          setImageCropperModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setImageCropperModal(false);
            setCropImageUrl(false);
            setImageLoading(false);
            setAnchorEl(null);
            setImage("");
            setBlobImageUrl("")
          }}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {showWebCam ? (
          <DialogContent>
            <div className="mt-4 h-[500px]">
              <Webcam
                style={{ margin: "auto" }}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                ref={webcamRef}
                mirrored={false}
              />
              <div className="mt-5 flex justify-center gap-3">
                <button
                  className="p-2 my-2 gap-2 border w-40 bg-red-600 rounded-md "
                  onClick={() => {
                    setShowWebCam(false);
                    setWebcamImage("");
                    setImageCropperModal(false);
                  }}
                >
                  {t("cancel")}
                </button>
                <button
                  className="p-2 my-2 gap-2 border w-40 bg-[#33ccff] rounded-md "
                  onClick={() => {
                    if (webcamImage === "") {
                      captureWebcamImage();
                    } else {
                      setWebcamImage("");
                    }
                  }}
                >
                  {t("capture")}
                </button>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <div className=" p-0 bg-white">
              <div className="relative  p-0 mt-6">
                <div style={{ width: "450px", height: "400px" }}>
                  {image !== "" &&
                   <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={7 / 7}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                      getCroppedImg(image, croppedAreaPixels).then(
                        (croppedImage: any) => {
                          setCropImageUrl(true);
                          fetch(croppedImage)
                            .then((res) => res.arrayBuffer())
                            .then((arrayBuffer) => {
                              // const url = window.URL.createObjectURL(blob);
                              const file = new File(
                                [arrayBuffer],
                                `${Math.floor(Math.random() * (10000 * 10000))}.jpeg`,
                                {
                                  type: "image/jpeg",
                                }
                              );
                              setBlobImageUrl(file);
                            });
                        }
                      );
                    }}
                    onZoomChange={setZoom}
                    onMediaLoaded={(mediaSize) => {
                      // setZoom(500 / mediaSize.naturalHeight);
                    }}
                  />}
                </div>
                <br style={{ clear: "both" }} />
              </div>
              <div className="my-auto px-2"></div>
            </div>
            <div className="flex justify-between w-full gap-3 mt-3 py-3">
              <div className="flex gap-3">
                {image !== "" && <>
                  <button
                    onClick={onZoomIn}
                    className="p-1 my-2 border bg-[#33ccff] rounded-md "
                  >
                    <AddIcon />
                  </button>
                  <button
                    onClick={onZoomOut}
                    className="p-1 my-2 border bg-[#33ccff] rounded-md"
                  >
                    <RemoveIcon />
                  </button>
                  <button
                    className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
                    onClick={() => {
                      if (rotation === 360) {
                        setRotation(0);
                      } else {
                        setRotation(rotation + 90);
                      }
                    }}
                  >
                    <Rotate90DegreesCcwIcon /> {t("flip")}
                  </button>
                </>}
                {image === "" && <div>
                  <label htmlFor="image">
                    <div
                      className="bg-primary rounded-md p-4 my-2 cursor-pointer"
                      style={{
                        boxShadow:
                          "0px 2px 13px 5px #0000001a",
                      }}
                    >
                      {t("select_image")}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      id="image"
                      className="hidden absolute top-0 h-20  items-center"
                      onChange={(e: any) =>
                        setImage(URL.createObjectURL(e.target.files[0]))
                      }
                    />
                  </label>

                </div>}
                <button
                  onClick={() => { setImage(''); setBlobImageUrl(""); setCrop({ x: 0, y: 0 }); setZoom(1) }}
                  // disabled={cropImageUrl}
                  className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md flex justify-center items-center "
                >
                  <BsFillTrashFill />
                  <span className="mx-1"> {t("remove")} </span>
                </button>
              </div>
              <button
                onClick={handleUpdateImage}
                disabled={blobImageUrl === "" ? true : false}
                className="p-1 my-2 gap-2 border w-32 disabled:opacity-80 bg-[#33ccff] rounded-md "
              >
                <CloudUploadIcon />
                <span className="mx-1"> {t("upload")} </span>
              </button>

              {webcamImage !== "" && (
                <button
                  onClick={() => {
                    setShowWebCam(true);
                    if (webcamImage === "") {
                      captureWebcamImage();
                    } else {
                      setWebcamImage("");
                    }
                  }}
                  className="p-1 my-2 gap-2 border w-32 bg-[#33ccff] rounded-md "
                >
                  <AddAPhotoIcon />
                  <span className="mx-1"> {t("retake")} </span>
                </button>
              )}
            </div>
          </DialogContent>
        )}
      </Dialog>
      <div className="overflow-x-hidden">
        <Toast
          open={showToast}
          message={t('room_details_updated')}
          setShow={setShowToast}
        />

        <div
          className="flex items-center py-2 border-b px-2 cursor-pointer h-[60px]"
          onClick={() => backFunc()}
        >
          <div>
            <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
          </div>
          <div className="pt-[1px]">{t("back")}</div>
        </div>
        <div className="mt-4 px-4 w-full">
          {!showWebCam && !removeImage && (
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              id="imageUploadBtn"
              className="hidden"
              onChange={handleImageChange}
            />
          )}
          <div onClick={handleClick} className="flex justify-center relative">
            {imageLoading ? <div className="flex justify-center my-16"><CircularProgress /></div> : <img
              src={DefaultImageUrl + activeRoomData?.profile_img}
              alt=""
              className="h-[200px] w-[200px] rounded-full"
            />}
            {/* {groupImage !== "" && groupImage !== undefined ? (
              <img
                src={DefaultImageUrl+groupImage}
                alt=""
                className="h-[200px] w-[200px] rounded-full"
              />
            ) : (
              <img
                src={DefaultImageUrl + activeRoomData?.profile_img}
                alt=""
                className="h-[200px] w-[200px] rounded-full"
              />
            )} */}
            <label
              htmlFor="gallery"
              className="absolute bottom-[-5px] right-[30%] bg-primary rounded-full p-2 text-white cursor-pointer border border-white"
            >
              <EditIcon sx={{ fontSize: 25 }} />
            </label>
          </div>
          <div>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{ cursor: "pointer" }}
            >
              <label >
                <div
                  style={{ padding: "8px 13px" }}
                  className="hover:bg-gray-200"
                  onClick={() => {
                    setShowWebCam(true);
                    setImageCropperModal(true);
                  }}
                >
                  {t("take_photo")}
                </div>
              </label>
              <label htmlFor="imageUploadBtn">
                <div
                  style={{ padding: "8px 13px" }}
                  className="hover:bg-gray-200"
                  onClick={() => {
                    setWebcamImage("");
                    setImage("");
                    setShowWebCam(false);
                    setImageCropperModal(true);
                  }}
                >
                  {t("upload_photo")}
                </div>
              </label>

            </Popover>
          </div>

          <div className="mt-5">
            <label> {activeRoomData?.type==="broadcast"?t('broadcast_name'):t("group_name")} </label>
            <input
              placeholder={activeRoomData?.type==="broadcast"?t('broadcast_name'):t("group_name")}
              className="border-2 outline-none p-2 w-full mt-1"
              value={groupName}
              onChange={handleChangeGroupName}
            />
          </div>

          {showNameButton && (
            <div className="mt-4">
              <button
                className="p-2 bg-white border border-[#33CCFF] outline-none w-full primary disabled:opacity-25"
                onClick={handleUpdateGroupName}
              >
                {t("change_name")}
              </button>
            </div>
          )}

          <div className="mt-3">
            <label> {activeRoomData?.type==="broadcast"?t("broadcast_description"):t("group_description")} </label>
            <input
              placeholder={activeRoomData?.type==="broadcast"?t("broadcast_description"):t("group_description")}
              className="border-2 outline-none p-2 w-full mt-1"
              value={groupDescription}
              onChange={handleChangeGroupDesc}
            />
          </div>

          {showDescButton && (
            <div className="mt-4">
              <button
                className="p-2 bg-white border border-[#33CCFF] outline-none w-full primary disabled:opacity-25"
                onClick={handleUpdateGroupDesc}
              >
                {t("change_description")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditGroup;
