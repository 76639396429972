import { LinearProgress } from "@mui/material";

interface IProps {
  totalVotes: number;
  percent: number;
}

const VoteProgress = ({ totalVotes, percent }: IProps) => {
  return (
    <div className="flex gap-2 items-center">
      <LinearProgress
        value={percent}
        className="w-full"
        variant="determinate"
      />
      <div className="text-gray-700">{totalVotes}</div>
    </div>
  );
};

export default VoteProgress;
