import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RightIcon from "../../Assets/Images/right.png";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { ComonUsersDataTypes, ParticipantsDataTypes } from "../../Types/Types";
import { Avatar, Tooltip } from "@mui/material";
import { DefaultImageUrl } from "../../Constant/Constant";
import { emit, socketListen } from "../../Socket";
import Toast from "../../coreComponent/Toast";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const AddParticipants = ({ setAddParticipantsDrawerShow, setRoomRightDrawer }: any) => {
  const [addMemberListShow, setAddMemberListShow] = useState(false);
  const { activeRoomData } = useSelector((state: any) => state.chat);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [searchQuery,setSearchQuery]=useState("");
  const { userData, formattedContact, comonUsers } = useSelector(
    (state: any) => state.user
  );
  const { t } = useTranslation();

  socketListen("message", (data: any) => {
    if (data.type === "removeUserFromRoom") {
      setToastMessage(t("user_removed"));
      setToastShow(true);
    } else if (data.type === "joinRoom") {
      setSelectedUsers([]);
      setToastMessage(t("user_added"));
      setToastShow(true);
    }
  });

  const handleSelectUsers = (e: ChangeEvent<HTMLInputElement>) => {
    let temp = [...selectedUsers];

    if (e.target.checked) {
      temp.push(e.target.value);
      setSelectedUsers(temp);
    } else {
      const filtered = temp.filter((item: string) => item !== e.target.value);
      setSelectedUsers(filtered);
    }
  };

  const backFunc = () => {
    setAddParticipantsDrawerShow(false);
  };

  const handleRemoveParticipant = (item: ParticipantsDataTypes) => {
    emit("removeUserFromRoom", {
      roomId: activeRoomData?._id,
      pid: item?.user_id,
    });
    setRoomRightDrawer(false)
  };

  const handleAddUserToGroup = () => {
    emit("joinRoom", {
      roomId: activeRoomData?._id,
      user_type: "common",
      users: selectedUsers,
    });
    setRoomRightDrawer(false)
  };

  return (
    <>
      {addMemberListShow ?
        <div className="flex justify-between items-center py-2 border-b px-2 w-[500px] h-[60px] fixed top-0  bg-white">
          <div
            className="flex cursor-pointer"
            onClick={() => {
              setAddMemberListShow(false);
              setSelectedUsers([]);
            }}
          >
            <div>
              <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
            </div>
            <div className="pt-[1px]">{t("back")}</div>
          </div>
          {selectedUsers?.length > 0 && (
            <div className="cursor-pointer" onClick={handleAddUserToGroup}>
              <img src={RightIcon} alt="" />
            </div>
          )}
        </div>
        :
        <div className="flex py-2 border-b px-2 h-[60px] fixed top-0 w-full bg-white">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => backFunc()}
          >
            <div>
              <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
            </div>
            <div className="pt-[1px]">{t("back")}</div>
          </div>
        </div>}
      <div className="overflow-x-hidden h-fit mt-14 overflow-y-scroll">
        <Toast open={toastShow} message={toastMessage} setShow={setToastShow} />

        {addMemberListShow ? (
          <>


            <div className="mt-5 px-4 w-full">
            <div
                className="my-3 rounded flex  border mb-4 items-center px-2 bg-white"
                style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
              >
                <SearchIcon sx={{ color: "#333333" }} />
                <input
                  type="text"
                  placeholder={t("search")}
                  className="w-full py-2 px-2 focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={(e)=>setSearchQuery(e.target.value)}
                />
              </div>
              {comonUsers.map((el:any)=>({...el,name:el?.firstName!==""?el.firstName?.toLowerCase()+" "+el?.lastName.toLowerCase():`${el?.userId?.phone.replace('+','')}`})).sort((a: any, b: any) => {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name > b.name) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).filter((el:any)=>el?.name.split(' ')[0]?.startsWith(searchQuery.toLowerCase())||el?.name.split(' ')[1]?.startsWith(searchQuery.toLowerCase()))?.map((item: ComonUsersDataTypes, index: number) => {
                const isAlreadyAdded = activeRoomData?.participants?.some(
                  (elem: any) =>
                    elem?.user_id === item?.userId?._id && elem?.left_at === 0
                );

                const isBlockedUser = userData?.blockedRooms?.some(
                  (elem: any) => elem?.pid ===item?.userId?._id
                );

                console.log(item,'0000')

                return (
                  !isAlreadyAdded && (
                    <div
                      className="flex items-center justify-between mb-4"
                      key={index}
                    >
                      <div className="flex gap-3">
                        <div>
                          {item?.userId?.profile_img !== "" &&
                            !isBlockedUser 
                             ? (
                            <img
                              src={DefaultImageUrl + item?.userId?.profile_img}
                              alt=""
                              className="h-[45px] w-[45px] rounded-full"
                            />
                          ) : (
                            <Avatar sx={{ height: 45, width: 45 }} />
                          )}
                        </div>
                        <div>
                          {item?.firstName!==""?<div
                            className={
                              isBlockedUser ? "italic" : ""
                            }
                          >
                            <span>{item?.firstName}</span>
                            <span className="ml-1">{item?.lastName}</span>
                          </div>:<div className={
                              isBlockedUser  ? "italic" : ""
                            }>
                            {item?.userId?.phone}
                            </div>}
                          {isBlockedUser ? (
                            <div className="textGray text-sm italic">
                              {t("unblock_user_add_group")}
                            </div>
                          ) :   (
                            <div className="textGray">{item?.userId?.phone}</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="outline-none h-[17px] w-[17px] cursor-pointer"
                          disabled={isBlockedUser  ? true : false}
                          value={item?.userId?._id}
                          onChange={handleSelectUsers}
                          id={item?.userId?._id}
                              checked={selectedUsers.includes(item?.userId?._id)}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </>
        ) : (
          <>


            <div className="mt-4 px-4 w-full">
              <div className="flex items-center justify-between">
                <div>{activeRoomData?.type === "broadcast" ? t("broadcast_member") : t("members_group")}</div>
                <div>
                  <button
                    className="bg-green-500 rounded-md text-white px-4 py-1 "
                    onClick={() => setAddMemberListShow(true)}
                  >
                    <AddIcon color="inherit" /> {t("add_members_btn")}
                  </button>
                </div>
              </div>
              <div
                className="my-3 rounded flex  border mb-2 items-center px-2 bg-white"
                style={{ boxShadow: "0px 1px 5px #e9e9e9" }}
              >
                <SearchIcon sx={{ color: "#333333" }} />
                <input
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  className="w-full py-2 px-2 focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={(e)=>setSearchQuery(e.target.value)}
                />
              </div>
              <div className="mt-5">
                {activeRoomData?.participants?.map((el: any) => ({ ...el, name: formattedContact[el?.user_id]?.firstName? formattedContact[el?.user_id]?.firstName.toLowerCase() + " " + formattedContact[el?.user_id]?.lastName.toLowerCase():`${el?.phone}` })).sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  } else if (a.name > b.name) {
                    return 1;
                  } else {
                    return 0;
                  }

                }).filter((item:any)=>item.name.split(" ")[0]?.startsWith(searchQuery.toLowerCase())||item.name.split(" ")[1]?.startsWith(searchQuery.toLowerCase())).map(
                  (item: ParticipantsDataTypes, index: number) => {
                    const userContactDetails = formattedContact[item?.user_id];

                    return (
                      item?.user_id !== userData?._id &&
                      item?.left_at === 0 && (
                        <div
                          className="flex items-center justify-between mb-4"
                          key={index}
                        >
                          <div className="flex gap-3">
                            <div>
                              {item?.profile_img !== "" ? (
                                <img
                                  src={DefaultImageUrl + item?.profile_img}
                                  alt=""
                                  className="h-[45px] w-[45px] rounded-full"
                                />
                              ) : (
                                <Avatar sx={{ height: 45, width: 45 }} />
                              )}
                            </div>
                            <div>
                              {userContactDetails &&
                                (userContactDetails?.firstName !== "" ||
                                  userContactDetails?.lastName !== "") ? (
                                <div>
                                  <span>{userContactDetails?.firstName}</span>
                                  <span className="ml-1">
                                    {userContactDetails?.lastName}
                                  </span>
                                </div>
                              ) : (
                                <div>{item?.phone}</div>
                              )}
                              <div className="textGray">{item?.phone}</div>
                            </div>
                          </div>
                          <div>
                            <button
                              className="rounded-full bg-red-500 flex items-center justify-center"
                              onClick={() => handleRemoveParticipant(item)}
                            >
                              <Tooltip title={t("remove")}>
                                <CloseIcon
                                  sx={{
                                    fontSize: "20px",
                                    padding: "3px",
                                    color: "white",
                                  }}
                                />
                              </Tooltip>
                            </button>
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddParticipants;
