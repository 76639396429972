import { Drawer, IconButton, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Folders from "./Folders";
import React, { useEffect, useState } from "react";
import CategoryModal from "./CategoryModal";
import BreadCrumbs from "./BreadCrumbs";
import RecordModal from "./RecordModal";
import { IDatabase, IFolder, IProps, IRecords } from "./databasetypes";
import { authCllient } from "../../../Graphql/authClient";
import { GET_MY_FOLDERS } from "../../../Graphql/Queries";
import DeleteFolder from "./DeleteFolder";
import Records from "./Records";
import DeleteRecord from "./DeleteRecord";
import CloseIcon from "@mui/icons-material/Close";

type TModalMode = "edit" | "view";

const DatabaseDrawer = ({ show, setShow }: IProps) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const [loading, setloading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [database, setDatabase] = useState<IDatabase>(null);
  const [gloabalFolderId, seGlobalFolderId] = useState("");
  const [currentDatabase, setCurrentDatabase] = useState(null);
  const [openRecordModal, setOpenRecordModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [editFolder, setEditFolder] = useState(null);
  const [deleteFolder, setDeleteFolder] = useState({ show: false, data: null });
  const [deleteRecord, setDeleteRecord] = useState({ show: false, data: null });
  const [recordModalMode, setRecordModalMode] = useState<TModalMode>("edit");
  const [editRecordId, setEditRecordId] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchDatabase(currentDatabase?._id);
  }, [currentDatabase]);

  useEffect(() => {
    if (isRefresh) {
      fetchDatabase(currentDatabase?._id);
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const fetchDatabase = async (_id: string) => {
    setloading(true);
    setIsError(false);
    try {
      const response = await authCllient.query({
        query: GET_MY_FOLDERS,
        variables: {
          input: {
            _id: _id ?? null,
          },
        },
      });

      const responseD = response?.data?.getMyFolders;
      if (responseD) {
        setDatabase(responseD);
        seGlobalFolderId(responseD?._id);
      } else {
        setIsError(true);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setloading(false);
    }
  };

  const handleOpenFolder = (folder: IFolder) => {
    setHistory([...history, folder]);
    setCurrentDatabase(folder);
  };

  const handleFolderMenu = (folder: IFolder, type: string) => {
    if (type == "edit") {
      setEditFolder(folder);
      setOpenCategoryModal(true);
    } else {
      setDeleteFolder({ data: folder, show: true });
    }
  };

  const handleRecordClick = (record: IRecords, type: String) => {
    if (type == "edit") {
      setRecordModalMode("edit");
      setEditRecordId(record?._id);
      setOpenRecordModal(true);
    } else {
      setDeleteRecord({ data: record, show: true });
    }
  };

  const onViewRecord = (record: IRecords) => {
    setRecordModalMode("view");
    setEditRecordId(record?._id);
    setOpenRecordModal(true);
  };

  const handleBreadcrumbClick = (folder: IFolder, index: number) => {
    setHistory(history.slice(0, index + 1));
    setCurrentDatabase(folder);
  };

  return (
    <Drawer open={show} sx={{ "& .MuiDrawer-paper": { width: 450 } }}>
      <div className="sticky top-0 z-10">
        <div className="flex w-full items-center justify-between cursor-pointer py-2 border-b px-2 h-[60px] bg-[#F3F9FC]">
          <div className=" font-bold capitalize">{t("mydatabase")}</div>
          <div>
            <IconButton onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {loading && <LinearProgress color="primary" sx={{ height: "2px" }} />}

        <div className="px-4 pt-2">
          <BreadCrumbs history={history} onClick={handleBreadcrumbClick} />
        </div>
      </div>

      {!isError && (
        <React.Fragment>
          <div className="px-4 py-4 h-full overflow-auto">
            {database?.subFolders?.length > 0 ||
            database?.records?.length > 0 ? (
              <div className="grid gap-5">
                {database?.subFolders?.map((item: IFolder, index: number) => {
                  return (
                    <Folders
                      key={index}
                      folder={item}
                      onDoubleClick={handleOpenFolder}
                      menuClick={handleFolderMenu}
                    />
                  );
                })}

                {database?.records?.map((item: IRecords, index: number) => {
                  return (
                    <Records
                      key={index}
                      record={item}
                      menuClick={handleRecordClick}
                      onViewRecord={onViewRecord}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="text-xl mt-4 text-center">{t("norecordcategoryfound")}</div>
            )}
          </div>

          <div className="sticky bottom-3 pt-4 bg-white flex justify-center gap-6 px-4">
            <button
              disabled={Boolean(loading)}
              onClick={() => setOpenCategoryModal(true)}
              className="disabled:bg-gray-200 bg-[#33CCFF] outline-none w-max px-6 py-2 rounded-md text-white"
            >
              {t("newcategory")}
            </button>

            {currentDatabase?._id && (
              <button
                disabled={Boolean(loading)}
                onClick={() => setOpenRecordModal(true)}
                className="disabled:bg-gray-200 bg-[#33CCFF] outline-none w-max px-6 py-2 rounded-md text-white"
              >
                {t("newrecord")}
              </button>
            )}
          </div>
        </React.Fragment>
      )}

      {isError && (
        <div className="mt-4 text-red-600 text-lg text-center">
          {t("something_went_wrong")}
        </div>
      )}

      {openCategoryModal && (
        <CategoryModal
          show={openCategoryModal}
          setShow={setOpenCategoryModal}
          currentDatabase={currentDatabase}
          globalId={gloabalFolderId}
          setIsRefresh={setIsRefresh}
          editFolder={editFolder}
          setEditFolder={setEditFolder}
        />
      )}

      {openRecordModal && (
        <RecordModal
          show={openRecordModal}
          setShow={setOpenRecordModal}
          currentDatabase={currentDatabase}
          globalId={gloabalFolderId}
          setIsRefresh={setIsRefresh}
          recordId={editRecordId}
          setRecordId={setEditRecordId}
          modalMode={recordModalMode}
          setModalMode={setRecordModalMode}
        />
      )}

      {deleteFolder.show && (
        <DeleteFolder
          setIsRefresh={setIsRefresh}
          deleteFolder={deleteFolder}
          setDeleteFolder={setDeleteFolder}
        />
      )}

      {deleteRecord.show && (
        <DeleteRecord
          setIsRefresh={setIsRefresh}
          deleteRecord={deleteRecord}
          setDeleteRecord={setDeleteRecord}
        />
      )}
    </Drawer>
  );
};

export default DatabaseDrawer;
