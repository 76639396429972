import { useTranslation } from "react-i18next";
import { reminderOptions } from "./data";
import { TField } from "./databasetypes";
import { useState } from "react";
import AddCustomNotification from "../../../Components/Calendar/dialogs/event/fields/AddCustomNotification";
import { Menu, MenuItem } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

interface IProps {
  selected: {
    Count: number;
    Unit: string;
  };
  fieldKey: string;
  fields: Array<TField>;
  setFields: (value: Array<TField>) => void;
}

const RecordReminder = ({ fields, setFields, selected, fieldKey }: IProps) => {
  const { t } = useTranslation();
  const [showCustom, setShowCustom] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSelect = (option: { Unit: string; Count: number }) => {
    const updated = fields?.map((field) => {
      return field?.key == fieldKey
        ? {
            ...field,
            remind_at: option,
          }
        : field;
    });
    setFields(updated);
    handleCloseAnchor();
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex flex-wrap gap-3 mt-1">
      <div className="flex gap-2">
        <button
          type="button"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className="bg-[#33CCFF] text-sm outline-none px-3 py-2 rounded-md text-white"
        >
          {selected?.Count ? (
            <div className="flex gap-2 items-center font-semibold lowercase">
              <AccessAlarmIcon className="text-white" />
              <div>
                <span>{selected?.Count}</span>
                <span className="ml-1">{t(selected?.Unit?.toLowerCase())}</span>
                <span className="ml-1">{t("before")}</span>
              </div>
            </div>
          ) : (
            t("selectreminder")
          )}
        </button>
      </div>

      <Menu
        id="view-select-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseAnchor}
        MenuListProps={{
          "aria-labelledby": "view-select-button",
        }}
      >
        {reminderOptions.map(
          (option: { Count: number; Unit: string }, index: number) => {
            const label: string = `${option?.Count} ${t(
              option?.Unit?.toLowerCase()
            )} ${t("before")}`;

            return (
              option != selected && (
                <MenuItem key={index} onClick={() => handleSelect(option)}>
                  <div className={"lowercase"}>{label}</div>
                </MenuItem>
              )
            );
          }
        )}

        <MenuItem
          onClick={() => {
            setShowCustom(true);
            handleCloseAnchor();
          }}
        >
          <div> {t("custom")} </div>
        </MenuItem>
      </Menu>

      {showCustom && (
        <AddCustomNotification
          show={showCustom}
          setShow={setShowCustom}
          onSubmit={handleSelect}
        />
      )}
    </div>
  );
};

export default RecordReminder;
