import { CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IFileUpload {
  onChange: any;
  label?: string;
  loading?: boolean;
  id: string;
}

const FileInput = ({ onChange, label, loading, id }: IFileUpload) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center w-full">
      <label
        htmlFor={id}
        className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        <div className="flex flex-col items-center justify-center py-[10px]">
          {loading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <React.Fragment>
              <svg
                className="w-8 h-8 mb-[10px] text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                {label || t("clicktoupload")}
              </p>
            </React.Fragment>
          )}
        </div>
        <input
          id={id}
          type="file"
          className="hidden"
          onChange={onChange}
          disabled={Boolean(loading)}
        />
      </label>
    </div>
  );
};

export default FileInput;
